import { FunctionComponent, memo, useMemo, useState } from 'react';
import { IDashboardWidget } from '../../../types/IDashboardWidget';
import { LineGraphWidgetConfiguration } from './LineGraphWidgetConfiguration';
import DashboardWidgetConfigurationButton from '../../dashboard-widget/DashboardWidgetConfigurationButton';
import DashboardWidgetConfigurationDrawer from '../../dashboard-widget/DashboardWidgetConfigurationDrawer';
import DashboardWidgetContent from '../../dashboard-widget/DashboardWidgetContent';
import DashboardWidgetProvider from '../../dashboard-widget/DashboardWidgetContextProvider';
import LineGraphWidgetConfigurationForm from './LineGraphWidgetConfigurationForm';
import { ReactECharts } from '@/components/chart/ReactEcharts';
import { getColorByIndex, getConfigForMetric } from '@/components/metrics/MetricsConfig';
import { CommonMetricField } from '@/components/metrics/types/MetricField';
import useFormatting from '@/hooks/useFormatting';
import useUtils from '@/hooks/useUtils';
import { AlDate, TIMELINE_DATE_FORMAT } from '@/lib/date/AlDate';
import { getMetricValues, LineGraphWidgetDataDTO, WidgetDataDTO } from '@/modules/dashboards/api/dashboard/data/dashboard-data.contracts';
import { WIDGET_WIDTH_IN_PX } from '@/modules/dashboards/configuration/dashboard-dimensions';
import { useDashboardContextValue } from '@/modules/dashboards/contexts/DashboardContextProvider';
import { isBaseWidgetConfigurationEqual } from '@/modules/dashboards/lib/areWidgetsEqual';
import { EntityType } from '@/modules/dashboards/types/EntityType';
import { GroupByTime } from '@/modules/dashboards/types/GroupByTime';
import {
  BarSeriesOption,
  EChartsOption,
  LineSeriesOption,
  TooltipComponentFormatterCallbackParams,
  TooltipComponentOption,
  YAXisComponentOption,
} from 'echarts';
import { isArray, isEmpty } from 'lodash-es';
import DashboardWidgetFilterIndicator from '../../dashboard-widget/DashboardWidgetFilterIndicator';
import DashboardWidgetRichTitle from '../../dashboard-widget/DashboardWidgetRichtTitle';
import DashboardWidgetTopBar from '../../dashboard-widget/DashboardWidgetTopBar';
interface LineGraphWidgetProps extends IDashboardWidget<LineGraphWidgetConfiguration> {
  data?: WidgetDataDTO;
  isFetchingDataForWidget?: boolean;
  configurationId: string;
  layout: ReactGridLayout.Layout;
}

const LineGraphWidget: FunctionComponent<LineGraphWidgetProps> = ({ configuration, id, data, isFetchingDataForWidget, layout }) => {
  const dashboardCurrency = useDashboardContextValue((context) => context.dashboard?.dto.currency);
  const { extractPlainText } = useUtils();
  const { getLongFormatterForMetricField, getShortFormatterForMetricField } = useFormatting();

  // Calculate the topMargin based on the amount of data
  function calculateTopMargin(data: LineGraphWidgetDataDTO): number {
    const spaceBetweenNames = 12;
    const characterWidth = 7;
    let margin = 50;
    let totalLength = 0;
    let names: string[] = [];

    if (data.yAxisGrouped && Object.keys(data.yAxisGrouped).length > 0) {
      // Use group names
      names = Object.keys(data.yAxisGrouped);
    } else {
      // Use metric titles
      names = configuration.selectedMetrics.map((metric) => getConfigForMetric(metric)?.title ?? '');
    }

    totalLength = names.reduce((acc, name) => acc + name.length, 0) * characterWidth + names.length * spaceBetweenNames;

    const widgetLineWidth = layout.w * (WIDGET_WIDTH_IN_PX + 5);

    if (totalLength / widgetLineWidth > 1) margin = 75;
    if (totalLength / widgetLineWidth > 2) margin = 100;
    if (totalLength / widgetLineWidth > 3) margin = 125;

    return margin;
  }

  function getEchartsOptions(dashboardData: LineGraphWidgetDataDTO, widgetConfiguration: LineGraphWidgetConfiguration): EChartsOption {
    const series: (LineSeriesOption | BarSeriesOption)[] = [];
    const yAxis: YAXisComponentOption[] = [];

    const xAxisHasDates = configuration.groupByTime === GroupByTime.DAY;

    const xAxisData = dashboardData.xAxis;

    // Map to store yAxis indices per metric
    const yAxisIndexMap: { [metric: string]: number } = {};

    // Build yAxis components for selected metrics
    widgetConfiguration.selectedMetrics.forEach((metric, index) => {
      yAxisIndexMap[metric] = index;

      const metricConfig = getConfigForMetric(metric);
      if (!metricConfig) {
        return;
      }

      const color = getColorByIndex(index);

      // Define yAxis for this metric
      yAxis.push({
        name: metricConfig?.title,
        type: 'value',
        position: index % 2 === 0 ? 'left' : 'right', // Alternate positions
        offset: index > 1 ? 60 * Math.floor(index / 2) : 0,
        nameTextStyle: {
          fontWeight: 'bold',
          fontFamily: "-apple-system, 'system-ui', 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
        axisLabel: {
          fontWeight: 'bold',
          fontFamily: "-apple-system, 'system-ui', 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
          formatter: (value: number) =>
            getShortFormatterForMetricField(metric)(value, {
              customCurrencyCode: dashboardCurrency,
            }),
        },
        axisPointer: {
          label: {
            show: true,
            formatter: (params) =>
              getShortFormatterForMetricField(metric)(params.value as number, {
                customCurrencyCode: dashboardCurrency,
              }),
          },
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: color,
          },
        },
      });
    });

    if (dashboardData.yAxisGrouped && Object.keys(dashboardData.yAxisGrouped).length > 0) {
      // Process grouped data
      // Assign colors to groups
      const groupNames = Object.keys(dashboardData.yAxisGrouped);
      const groupColorMap: { [groupName: string]: string } = {};

      groupNames.forEach((groupName, index) => {
        const color = getColorByIndex(index);
        groupColorMap[groupName] = color;
      });

      // Build series
      groupNames.forEach((groupName) => {
        const groupMetrics = dashboardData.yAxisGrouped[groupName];

        widgetConfiguration.selectedMetrics.forEach((metric) => {
          const metricConfig = getConfigForMetric(metric);
          if (!metricConfig) {
            return;
          }

          const color = groupColorMap[groupName]; // Use group color

          const seriesName = `${groupName}`;

          // Access the correct values array for the metric
          const values = getMetricValues(groupMetrics, metric);

          const yAxisIndex = yAxisIndexMap[metric];

          const seriesItem: LineSeriesOption = {
            id: `${metric}__${groupName}`,
            name: seriesName,
            type: 'line',
            yAxisIndex: yAxisIndex,
            data: values,
            color: color,
            symbol: 'emptyCircle',
            symbolSize: 6,
            emphasis: {
              focus: 'none',
            },
            showSymbol: true,
          };

          if (seriesItem) {
            series.push(seriesItem);
          }
        });
      });
    } else {
      // Process ungrouped data
      widgetConfiguration.selectedMetrics.forEach((metric, index) => {
        const metricConfig = getConfigForMetric(metric);
        if (!metricConfig) {
          return;
        }

        const color = getColorByIndex(index);

        const seriesName = metricConfig.title;

        // Access the correct values array for the metric

        const values = getMetricValues(dashboardData.yAxis, metric);

        const yAxisIndex = yAxisIndexMap[metric];

        const seriesItem: LineSeriesOption = {
          id: metric,
          name: seriesName,
          type: 'line',
          yAxisIndex: yAxisIndex,
          data: values,
          color: color,
          symbol: 'emptyCircle',
          symbolSize: 6,
          emphasis: {
            focus: 'none',
          },
          showSymbol: true,
        };

        if (seriesItem) {
          series.push(seriesItem);
        }
      });
    }

    const options: EChartsOption = {
      // Add the legend property here

      legend: {
        show: !isEmpty(dashboardData.yAxisGrouped),
        type: 'plain', // Use 'plain' or 'scroll' depending on the number of items
        orient: 'horizontal', // 'vertical' if you want the legend on the side
        top: 0, // Position from the top
        left: 'center', // Center the legend horizontally
        textStyle: {
          fontWeight: 'normal',
          fontFamily: "-apple-system, 'system-ui', 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
      },
      xAxis: {
        type: 'category',
        data: xAxisData,
        boundaryGap: true,
        axisTick: {
          alignWithLabel: true,
        },
        axisPointer: {
          label: {
            show: xAxisHasDates,
          },
        },
        axisLabel: {
          formatter: function (value: string) {
            if (xAxisHasDates) {
              return AlDate.parse(value).toFormat(TIMELINE_DATE_FORMAT);
            }
            return value;
          },
          fontWeight: 'bolder',
          fontFamily: "-apple-system, 'system-ui', 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
        splitLine: {
          show: false,
        },
      },
      yAxis: yAxis,
      series: series,
      grid: {
        left: widgetConfiguration.selectedMetrics.length >= 3 ? 125 : 65,
        right: widgetConfiguration.selectedMetrics.length >= 4 ? 125 : 65,
        top: !isEmpty(dashboardData.yAxisGrouped) ? calculateTopMargin(dashboardData) : 32,
        bottom: 32,
      },
      tooltip: {
        trigger: 'axis',
        appendToBody: true,
        axisPointer: {
          type: 'cross',
        },
        formatter: function (params: TooltipComponentFormatterCallbackParams): string {
          if (isArray(params)) {
            let result = `<b>${params[0].name}</b>` + '<br/>';

            params.forEach((param) => {
              let paramMetric: string | undefined = '';
              if (param.seriesId?.includes('__')) {
                paramMetric = param.seriesId.split('__')[0];
              } else {
                paramMetric = param.seriesId;
              }
              if (paramMetric) {
                const formattedValue = getLongFormatterForMetricField(paramMetric as CommonMetricField)(param.value as number, {
                  customCurrencyCode: dashboardCurrency,
                });
                result += `${param.marker}${param.seriesName}: ${formattedValue}<br/>`;
              }
            });
            return result;
          } else {
            return '';
          }
        },
      } as TooltipComponentOption,
    };

    return options;
  }

  const echartsOptions = useMemo(() => {
    if (data) {
      return getEchartsOptions(data as LineGraphWidgetDataDTO, configuration);
    }
    return {};
  }, [data, configuration, layout.w]);

  const hasFilters =
    (!isEmpty(configuration.campaignFilters) &&
      (configuration.entityType === EntityType.CAMPAIGN || configuration.entityType === EntityType.PROFILE)) ||
    (!isEmpty(configuration.targetFilters) && configuration.entityType === EntityType.TARGET);

  const [isMouseOnWidget, setIsMouseOnWidget] = useState(false);

  return (
    <DashboardWidgetProvider widgetId={id ?? 'unknown'} isLoading={isFetchingDataForWidget} isConfigured={configuration.isConfigured}>
      <DashboardWidgetContent onMouseChange={setIsMouseOnWidget}>
        <DashboardWidgetTopBar isMouseOnWidget={isMouseOnWidget}>
          <DashboardWidgetRichTitle configuration={configuration}></DashboardWidgetRichTitle>
          <DashboardWidgetFilterIndicator visible={hasFilters && !isMouseOnWidget} widgetId={id} />
        </DashboardWidgetTopBar>
        <DashboardWidgetConfigurationButton isMouseOnWidget={isMouseOnWidget}></DashboardWidgetConfigurationButton>

        <div className="h-full w-full flex-1 flex">
          {data && (
            <ReactECharts
              settings={{
                notMerge: true,
              }}
              option={echartsOptions}
            />
          )}
        </div>
      </DashboardWidgetContent>

      <DashboardWidgetConfigurationDrawer widgetName={extractPlainText(configuration.title)}>
        <LineGraphWidgetConfigurationForm configuration={configuration} id={id} />
      </DashboardWidgetConfigurationDrawer>
    </DashboardWidgetProvider>
  );
};

export default memo(LineGraphWidget, isBaseWidgetConfigurationEqual);
