import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Divider, Menu, MenuItem, ToggleButton, Tooltip, Typography } from '@mui/material';
import type { GridApi } from 'ag-grid-enterprise';
import { MutableRefObject, useState, MouseEvent as ReactMouseEvent } from 'react';

interface ExportGridButtonProps<T> {
  gridApiRef: MutableRefObject<GridApi<T> | null | undefined>;
}

const ExportGridButton = <T,>({ gridApiRef }: ExportGridButtonProps<T>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: ReactMouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const exportData = (allColumns: boolean, onlyVisibleRows: boolean) => {
    if (!gridApiRef.current) return;

    const gridApi = gridApiRef.current;

    gridApi.exportDataAsExcel({
      allColumns,
      onlySelected: false,
      shouldRowBeSkipped: (params) => {
        if (onlyVisibleRows) {
          return !params.node.displayed;
        }
        return false;
      },
    });

    handleClose();
  };

  return (
    <div>
      <Tooltip title={'Export Table'} placement="left">
        <ToggleButton value={''} onClick={handleClick} className="border-slate-300 bg-white pb-[8px] pt-[7px]">
          <FileDownloadOutlinedIcon sx={{ fontSize: 15 }} />
        </ToggleButton>
      </Tooltip>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {/* Explanation Text */}
        <MenuItem disabled className="opacity-100">
          <Typography variant="caption" color="textPrimary" className="flex max-w-96 items-start text-wrap text-gray-600">
            Download table as Excel file.
          </Typography>
        </MenuItem>
        <Divider className="my-2" />

        {/* Export Options */}
        <MenuItem onClick={() => exportData(false, false)}>All Rows</MenuItem>
        <MenuItem onClick={() => exportData(false, true)}>Visible Rows</MenuItem>
      </Menu>
    </div>
  );
};

export default ExportGridButton;
