import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { isNil } from 'lodash-es';
import { FunctionComponent, SyntheticEvent, useContext, useEffect, useMemo, useState } from 'react';
import { PendingFiltersContext } from '../context/PendingFiltersContext';
import { AlFilterModel } from '../models/AlFilterModel';
import { AlMultiSelectOptionModel } from '../models/AlMultiSelectOptionModel';
import { FilterErrorCode } from '../types/FilterErrorCodes';

interface FilterSelectProps {
  filter: AlFilterModel;
  label: string;
  filterErrorCodeSet: Set<FilterErrorCode> | undefined;
}

export const FilterSelect: FunctionComponent<FilterSelectProps> = ({ filter, label, filterErrorCodeSet }) => {
  const { setFilterValue } = useContext(PendingFiltersContext);
  const [options, setOptions] = useState<AlMultiSelectOptionModel[]>([]);

  useEffect(() => {
    filter.getOptions().then((options) => {
      setOptions(options);
    });
  }, [filter]);

  const selectedValue = useMemo(() => {
    const selectedOptionId =
      filter.conditions && !isNil(filter?.conditions?.[0]) && Array.isArray(filter?.conditions[0].values)
        ? filter.conditions[0].values[0]
        : null;
    return options.find((o) => o.id == selectedOptionId);
  }, [options, filter]);

  function handleChange(event: SyntheticEvent<Element, Event>, newValue: AlMultiSelectOptionModel | null) {
    const selectOptionId = newValue?.id;

    filter.conditions = [
      {
        values: selectOptionId ? [selectOptionId as string] : [],
        operator: filter.defaultConditionOperators[0],
      },
    ];

    setFilterValue(filter);
  }

  const firstConditionErrorMessage =
    filterErrorCodeSet?.has(FilterErrorCode.EMPTY_1ST_CONDITION) || filterErrorCodeSet?.has(FilterErrorCode.NO_CONDITIONS)
      ? 'This field is required'
      : undefined;

  return (
    <div className="flex w-full flex-row gap-2">
      {options.length > 0 && (
        <Autocomplete
          className="w-full flex-1"
          id={'select-' + filter.key}
          value={selectedValue}
          getOptionLabel={(option) => option.name} // Do not truncate for accurate searching
          onChange={handleChange}
          options={options}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              placeholder={label}
              slotProps={{
                inputLabel: { shrink: true },
              }}
              error={!isNil(firstConditionErrorMessage)}
              helperText={firstConditionErrorMessage}
            />
          )}
        />
      )}
    </div>
  );
};
