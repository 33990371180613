import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import AlGrid, { DEFAULT_GRID_OPTIONS } from '@/components/grid/AlGrid';
import { ITextCellRendererParams, TextCellRenderer } from '@/components/grid/cells/TextCellRenderer';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import useAggregators from '@/components/grid/hooks/useAggregators';
import useColDefsFunctions from '@/components/grid/hooks/useColDefsFunctions';
import useColumnTypes from '@/components/grid/hooks/useColumnTypes';
import useComparisonMissing from '@/components/grid/hooks/useComparisonMissing';
import useDynamicHeight, { EXPANDED_VISIBLE_ABOVE_PX_ON_SCROLL_DOWN } from '@/components/grid/hooks/useDynamicHeight';
import useMetricColumnAggregates from '@/components/grid/hooks/useMetricColumnAggregates';
import useToggles from '@/components/grid/hooks/useToggles';
import { AlColDef, CELL_CLASS_CONTENTS_RIGHT, DEFAULT_GRID_OPTIONS_ROW_GROUPS, ExpandedGridContext } from '@/components/grid/types';
import { getMetricColumns } from '@/components/metrics/MetricsConfig';
import { MetricModel } from '@/components/metrics/models/MetricModel';
import { AdLabsColorVariant } from '@/config/theme/color.type';
import { useDataGroups } from '@/hooks/useDataGroups';
import useFormatting from '@/hooks/useFormatting';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { useHelperComponents } from '@/hooks/useHelperComponents';
import { useTranslation } from '@/lib';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { UserSettingKey } from '@/modules/users';
import { MetricsGraphTablePageContext } from '@/types/context-shared';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MotionPhotosOffIcon from '@mui/icons-material/MotionPhotosOff';
import { Card, useTheme } from '@mui/material';
import * as Sentry from '@sentry/react';
import type { BodyScrollEvent, CellClickedEvent, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-community';
import type { ColDef, GridApi, GridOptions, ICellRendererParams, ValueGetterParams } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import { Dispatch, FunctionComponent, SetStateAction, useCallback, useEffect, useMemo, useRef } from 'react';
import { useLayoutContext } from '../../../../contexts/LayoutContext';
import { BidStrategyType, CampaignsWithTimeline, CostType, MultiAdGroupsEnabledType } from '../../api/campaign/campaign-contracts';
import { CampaignModel, SelectedCampaignDTO } from '../../api/campaign/models/CampaignModel';
import useCampaignGroupIdColumnData from '../../hooks/useCampaignGroupIdColumnData';
import { generateCampaignTableColumnState } from './campaign-table.default-column-state';
import { EnabledPausedErrorState } from '../../types/EnabledPausedErrorState';

interface CampaignTableProps {
  withTimeline: CampaignsWithTimeline | undefined;
  selectedCampaigns: SelectedCampaignDTO[];
  setSelectedCampaigns: Dispatch<SetStateAction<SelectedCampaignDTO[]>>;
  isLoading: boolean;
  campaignLoadingErrorMessage: string;
  isCampaignLoadingError: boolean;
  pageVariables: MetricsGraphTablePageContext;
  noTopBorderRadius?: boolean;
  onGridReadyCallback?: (params: GridReadyEvent) => void;
  isExpanded?: boolean;
}

const CampaignTable: FunctionComponent<CampaignTableProps> = ({
  withTimeline,
  selectedCampaigns,
  setSelectedCampaigns,
  isLoading,
  campaignLoadingErrorMessage,
  isCampaignLoadingError,
  pageVariables,
  noTopBorderRadius = false,
  onGridReadyCallback,
  isExpanded,
}) => {
  const theme = useTheme();

  const { setColumnStateGridApi, handleColumnStateChange, applyStateToDefinitions, setIsAutoSaveEnabled } = useGridColumnState(
    UserSettingKey.CAMPAIGN_TABLE_COLUMN_STATE,
    generateCampaignTableColumnState(),
  );

  const rowData = withTimeline?.campaigns ?? [];
  const isComparisonDataMissing = withTimeline?.isComparisonDataMissing ?? false;

  const gridApiRef = useRef<GridApi<CampaignModel>>();
  const gridContextRef = useRef<ExpandedGridContext>();

  const { onSourceScroll } = useLayoutContext();
  const { heightCssValue, onGridReadyCallback: onGridReadyForDynamicHeight } = useDynamicHeight({});
  const { t } = useTranslation();

  const { getExternalLinkComponent } = useHelperComponents();

  // TODO: check if this can be directly used in opts
  const {
    metricFieldWithChangePercentage,
    optGroupNameColumnType,
    checkboxColumnType,
    dataGroupColumnType,
    bidStrategyColumnType,
    salesPreviousDaysType,
    spendPreviousDaysType,
    lastOptimizedType,
    enabledPausedArchivedStateColumnType,
  } = useColumnTypes();

  const { getCampaignAdTypeCellRendererParams } = useColDefsFunctions();

  // Additional data sources
  const { onGridReadyForMetricColumnAggregates, metricColumnAggregates } = useMetricColumnAggregates({
    gridApiRef,
    gridContextRef,
    metricColumnAggregates: withTimeline?.metrics ? MetricModel.arrayToMetricAggregates(withTimeline.metrics) : undefined,
  });

  const { campaignGroupIdToCampaignGroupMap, onGridReadyForCampaignGroupIdColumnData } = useCampaignGroupIdColumnData({
    gridApiRef,
    gridContextRef,
  });

  const { onGridReadyForComparisonMissing } = useComparisonMissing({
    gridApiRef,
    gridContextRef,
    isComparisonDataMissing,
  });

  const { gridToggles } = pageVariables;
  const { gridToggleParams, activeMetricComparator } = useToggles<CampaignModel>({
    gridApiRef,
    gridContextRef,
    gridToggles,
  });

  // Formatters
  const { formatCurrency, formatDateStringTimeNoHours } = useFormatting();

  const {
    campaignNameAggFunc,
    enabledPausedArchivedStateAggFunc,
    lastOptimizedAggFunc,
    stringToCountAggFunc,
    metricDataWithPreviousDaysAggFunc,
    portfolioAggFunc,
    metricsDataAggFunc,
    dataGroupAggFunc,
  } = useAggregators();

  const { dataGroups, dataItemToInfoMap, groupIdToItemSetMap, isDataGroupsLoading } = useDataGroups([DataGroupType.CAMPAIGN]);

  const columnDefs: ColDef<CampaignModel>[] = useMemo(() => {
    if (isDataGroupsLoading) {
      return [];
    }

    const colDefs: AlColDef<CampaignModel>[] = [
      {
        colId: ColumnId.CHECKBOX,
        pinned: 'left',
        lockPosition: 'left',
        type: 'checkboxColumnType',
      },
      {
        colId: ColumnId.ID,
        headerName: 'Campaign ID',
        field: 'id',
      },
      {
        colId: ColumnId.CAMPAIGN_AD_TYPE,
        headerName: 'Campaign Ad Type',
        field: 'adType',
        width: 100,
        enableRowGroup: true,
        aggFunc: 'stringToCountAggFunc',
        cellRenderer: TextCellRenderer,
        cellRendererParams: getCampaignAdTypeCellRendererParams,
      },
      {
        colId: ColumnId.CAMPAIGN_NAME,
        headerName: 'Campaign',
        field: 'name',
        resizable: true,
        width: 150,
        pinned: 'left',
        aggFunc: 'campaignNameAggFunc',
        cellRenderer: TextCellRenderer,
        cellRendererParams: (params: ICellRendererParams<CampaignModel>): ITextCellRendererParams => {
          let textColor = undefined;
          let tooltip;

          if (params.data?.hasOptRule) {
            textColor = 'orange' as AdLabsColorVariant;
            tooltip = 'Bid optimization not supported for campaigns with Optimization rule';
          }

          if (params.data?.costType == CostType.VCPM) {
            textColor = 'orange' as AdLabsColorVariant;
            tooltip = (
              <span>
                Bid optimization not supported for vCPM cost types.
                {getExternalLinkComponent(
                  'https://help.adlabs.app/en/articles/14-why-doesn-t-adlabs-optimize-vcpm-campaigns',
                  'Learn more',
                  false,
                )}
              </span>
            );
          }

          if (params.data?.bidStrategy == BidStrategyType.AUTO_FOR_SALES) {
            textColor = 'orange' as AdLabsColorVariant;
            tooltip = 'AdLabs works best with SP Fixed Bids or Dynamic Down only, use bulk Actions to change this setting for best results. ';
          }

          // if (params.data?.bidOptimization == AmazonBrandsBidOptimization.AUTO) {
          //   textColor = 'orange' as AdLabsColorVariant;
          //   tooltip =
          //     'Adlabs works best with Manual setting for SB Bid Optimization. Auto campaigns do not allow placement editing. Use bulk Actions to change this setting for best results. ';
          // }

          if (params.data?.multiAdGroupsEnabled == MultiAdGroupsEnabledType.FALSE) {
            textColor = 'error' as AdLabsColorVariant;
            tooltip = (
              <span>
                Amazon will deprecate SB legacy campaigns (older than Oct 2022). To see stats, duplicate campaign with the Copy function in
                Amazon Ads Console.
                {getExternalLinkComponent(
                  'https://help.adlabs.app/en/articles/28-missing-sponsored-brands-data-legacy-sb-deprecation',
                  'Learn more',
                  false,
                )}
              </span>
            );
          }

          if (params.data?.hasEnded) {
            textColor = 'orange' as AdLabsColorVariant;
            tooltip = 'Cannot update ended campaigns';
          }

          let icon = undefined;
          let iconTooltip = undefined;
          if (params.data?.scheduleState == EnabledPausedErrorState.ENABLED) {
            iconTooltip = 'This campaign is day parted';
            icon = (
              <AccessTimeIcon
                style={{
                  fontSize: '16px',
                  color: theme.palette.info.main,
                }}
              />
            );
          } else if (params.data?.scheduleState == EnabledPausedErrorState.PAUSED) {
            iconTooltip = 'Campaign day parting is paused';
            icon = (
              <MotionPhotosOffIcon
                style={{
                  fontSize: '16px',
                  opacity: '0.5',
                  color: theme.palette.slate.main,
                }}
              />
            );
          }

          return {
            iconTooltip,
            icon,
            textLabel: params.value,
            textColor: textColor,
            tooltip: tooltip,
          };
        },
      },
      {
        colId: ColumnId.STATE,
        headerName: 'State',
        field: 'state',
        type: 'enabledPausedArchivedStateColumnType',
      },
      {
        colId: ColumnId.PORTFOLIO_NAME,
        headerName: 'Portfolio',
        field: 'portfolioName',
        enableRowGroup: true,
        aggFunc: 'portfolioAggFunc',
        cellRenderer: TextCellRenderer,
        width: 100,
      },
      {
        colId: ColumnId.SCHEDULE_NAME,
        field: 'scheduleName',
        headerName: 'Dayparting Schedule',
        enableRowGroup: true,
        aggFunc: 'stringToCountAggFunc',
        cellRenderer: TextCellRenderer,
      },
      {
        colId: ColumnId.GROUP_NAME,
        field: 'groupName',
        type: 'optGroupNameColumnType',
      },
      {
        colId: ColumnId.CAMPAIGN_LAST_OPTIMIZED,
        field: 'lastOptimizedAt',
        type: 'lastOptimizedType',
      },
      {
        colId: ColumnId.SALES_PREVIOUS_DAYS,
        field: 'sales',
        type: 'salesPreviousDaysType',
      },
      {
        colId: ColumnId.SPEND_PREVIOUS_DAYS,
        field: 'spend',
        type: 'spendPreviousDaysType',
      },
      {
        colId: ColumnId.IMPRESSIONS,
        headerName: 'Impressions',
        field: 'impressions',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.CLICKS,
        headerName: 'Clicks',
        field: 'clicks',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.ORDERS,
        headerName: 'Orders',
        field: 'orders',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.UNITS,
        headerName: 'Units',
        field: 'units',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.CTR,
        headerName: 'CTR',
        field: 'ctr',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.CVR,
        headerName: 'CVR',
        field: 'cvr',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.CPC,
        headerName: 'CPC',
        field: 'cpc',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.SPEND,
        headerName: 'Spend',
        field: 'spend',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.SALES,
        headerName: 'Sales',
        field: 'sales',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.ACOS,
        headerName: 'ACOS',
        field: 'acos',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.ACTC,
        headerName: 'aCTC',
        field: 'actc',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.ROAS,
        headerName: 'ROAS',
        field: 'roas',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.RPC,
        headerName: 'RPC',
        field: 'rpc',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.CPA,
        headerName: 'CPA',
        field: 'cpa',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.AOV,
        headerName: 'AOV',
        field: 'aov',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.CPM,
        headerName: 'CPM',
        field: 'cpm',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.START_DATE,
        headerName: 'Start Date',
        field: 'startDate',
        width: 135,
        valueFormatter: (params) => formatDateStringTimeNoHours(params.value),
      },
      {
        colId: ColumnId.END_DATE,
        headerName: 'End Date',
        field: 'endDate',
        width: 135,
        valueFormatter: (params) => formatDateStringTimeNoHours(params.value),
      },
      {
        colId: ColumnId.TARGETING_TYPE,
        headerName: 'Targeting Type',
        field: 'targetingType',
        width: 135,
        enableRowGroup: true,
        aggFunc: 'stringToCountAggFunc',
        cellRenderer: TextCellRenderer,
        cellRendererParams: (): ITextCellRendererParams => {
          return {
            valueToString: (key: string) => t(`enums.targeting_type.${key}`),
          };
        },
      },
      {
        colId: ColumnId.CREATIVE_TYPE,
        headerName: 'Creative Type',
        field: 'creativeType',
        width: 100,
        enableRowGroup: true,
        aggFunc: 'stringToCountAggFunc',
        cellRenderer: TextCellRenderer,
        cellRendererParams: (): ITextCellRendererParams => {
          return {
            valueToString: (key: string) => t(`enums.creative_type.${key}`),
          };
        },
      },
      {
        colId: ColumnId.COST_TYPE,
        headerName: 'Cost Type',
        field: 'costType',
        width: 100,
        enableRowGroup: true,
        aggFunc: 'stringToCountAggFunc',
        cellRenderer: TextCellRenderer,
        cellRendererParams: (): ITextCellRendererParams => {
          return {
            valueToString: (key: string) => t(`enums.cost_type.${key}`),
          };
        },
      },
      {
        colId: ColumnId.BID_OPTIMIZATION,
        headerName: 'SB Bid Opt.',
        field: 'bidOptimization',
        width: 80,
        enableRowGroup: true,
        aggFunc: 'stringToCountAggFunc',
        cellRenderer: TextCellRenderer,
        cellRendererParams: (): ITextCellRendererParams => {
          return {
            //valueToString: (key: string) => t(`enums.cost_type.${key}`),
          };
        },
      },
      {
        colId: ColumnId.BUDGET_AMOUNT,
        headerName: 'Budget',
        field: 'budgetAmount',
        type: 'numericColumn',
        cellClass: CELL_CLASS_CONTENTS_RIGHT,
        width: 120,
        aggFunc: 'sum',
        valueFormatter: (params) => formatCurrency(params.value, { customCurrencyCode: params.context?.activeProfileCurrencyCode }),
      },
      {
        colId: ColumnId.BID_STRATEGY,
        headerName: 'Bid Strategy',
        field: 'bidStrategy',
        type: 'bidStrategyColumnType',
      },
      {
        colId: ColumnId.MULTI_AD_GROUPS_ENABLED,
        headerName: 'SB Version',
        field: 'multiAdGroupsEnabled',
        width: 95,
        enableRowGroup: true,
        aggFunc: 'stringToCountAggFunc',
        cellRenderer: TextCellRenderer,
        cellRendererParams: (): ITextCellRendererParams => {
          return {
            valueToString: (key: string) => t(`enums.multi_ad_groups_enabled.${key}`),
          };
        },
      },
    ];

    dataGroups
      .filter((group) => group.type === DataGroupType.CAMPAIGN)
      .forEach((group) => {
        colDefs.push({
          colId: `data_group_${group.id}`,
          headerName: group.name,
          headerClass: 'accented-header data-group-campaign data-group-base',
          toolPanelClass: 'data-group-base data-group-campaign',
          headerTooltip: t('campaign_data_group'),
          valueGetter: (params: ValueGetterParams<CampaignModel>) => {
            const itemId = params.data?.campaignDataItemIds.find((itemId) => groupIdToItemSetMap.get(group.id)?.has(itemId));
            return itemId ? dataItemToInfoMap.get(itemId)?.name : undefined;
          },
          type: 'dataGroupColumnType',
        });
      });

    // TODO: if metric col (or type is metric??), add comparator here
    applyStateToDefinitions(colDefs);
    return colDefs;
  }, [groupIdToItemSetMap, isDataGroupsLoading]);

  // Needed to keep the selection up to date when opt or Tags change on the campaign and data is refetched
  useEffect(() => {
    if (!gridApiRef.current || !rowData || rowData.length == 0 || selectedCampaigns.length == 0) return;
    updateSelectedCampaigns(gridApiRef.current);
  }, [rowData]);

  const updateSelectedCampaigns = (api: GridApi<CampaignModel>) => {
    if (!api || api.isDestroyed()) {
      console.warn('[CampaignTable.tsx] Grid API is not available or has been destroyed.');
      return;
    }

    try {
      const selectedRows = api.getSelectedRows();
      const selectedCampaignsDTO = selectedRows.map(
        (row): SelectedCampaignDTO => ({
          id: row.id,
          name: row.name,
          groupId: row.groupId,
          scheduleId: row.scheduleId,
          adType: row.adType,
          state: row.state,
          costType: row.costType,
          bidStrategy: row.bidStrategy,
          bidOptimization: row.bidOptimization,
          multiAdGroupsEnabled: row.multiAdGroupsEnabled,
          hasOptRule: row.hasOptRule,
          endDate: row.endDate,
          lastOptimizedAt: row.lastOptimizedAt,
          dataGroupType: DataGroupType.CAMPAIGN,
          dataItemIds: row.campaignDataItemIds,
          budgetAmount: row.budgetAmount,
        }),
      );
      setSelectedCampaigns(selectedCampaignsDTO);
    } catch (error) {
      console.error('Error updating selected campaigns:', error);
    }
  };

  const handleRowSelection = (event: SelectionChangedEvent<CampaignModel>) => {
    updateSelectedCampaigns(event.api);
  };

  const onCellClicked = useCallback((params: CellClickedEvent<CampaignModel>) => {
    if (params.column.getColId() === ColumnId.CHECKBOX) {
      const node = params.node;
      node.setSelected(!node.isSelected());
    }
  }, []);

  const customGridOptions: GridOptions<CampaignModel> = useMemo(() => {
    // While values might be undefined, default values are needed so grid knows which fields to expect
    const defaultCampaignGridContext: ExpandedGridContext = {
      metricColumnAggregates,
      campaignGroupIdToCampaignGroupMap: campaignGroupIdToCampaignGroupMap,
      isComparisonDataMissing,
      ...gridToggleParams,
    };
    // TODO: refactor: check which fields can be moved to AlGrid and which to hooks, add another set of defaults just for grouping tables
    return {
      ...DEFAULT_GRID_OPTIONS,
      ...DEFAULT_GRID_OPTIONS_ROW_GROUPS,
      sideBar: false,
      getRowId: (params) => params.data.id.toString(),
      onSelectionChanged: handleRowSelection,
      onCellClicked: onCellClicked,
      context: defaultCampaignGridContext,
      maintainColumnOrder: true,
      onBodyScroll: (event: BodyScrollEvent) => onSourceScroll(event.top),
      onColumnMoved: handleColumnStateChange,
      onColumnVisible: handleColumnStateChange,
      onColumnResized: handleColumnStateChange,
      onColumnRowGroupChanged: handleColumnStateChange,
      onSortChanged: handleColumnStateChange,
      onColumnPinned: handleColumnStateChange,
      columnTypes: {
        metricFieldWithChangePercentage,
        optGroupNameColumnType,
        checkboxColumnType,
        dataGroupColumnType,
        bidStrategyColumnType,
        salesPreviousDaysType,
        spendPreviousDaysType,
        lastOptimizedType,
        enabledPausedArchivedStateColumnType,
      },
      // Defining all aggFuncs here not in colDefs (where using string) so to prevent "stateItem.aggFunc must be a string" warning (col state is stored as a simple JSON)
      aggFuncs: {
        campaignNameAggFunc,
        enabledPausedArchivedStateAggFunc,
        metricsDataAggFunc,
        lastOptimizedAggFunc,
        stringToCountAggFunc,
        metricDataWithPreviousDaysAggFunc,
        portfolioAggFunc,
        dataGroupAggFunc,
      },
    } as GridOptions<CampaignModel>;
  }, []);

  function onGridReady(params: GridReadyEvent) {
    setColumnStateGridApi(params.api);
    gridApiRef.current = params.api;
    onGridReadyCallback?.(params);
    onGridReadyForDynamicHeight(params);

    // Context
    gridContextRef.current = params.context;

    // Need this because for initial context set the external data sources might have not been set yet
    if (gridContextRef.current) {
      onGridReadyForMetricColumnAggregates();
      onGridReadyForCampaignGroupIdColumnData();
      onGridReadyForComparisonMissing();
      // Not needed for comparison unit as it isn't fetched and always exists

      gridApiRef.current.refreshHeader();
      gridApiRef.current.refreshCells({ columns: [...getMetricColumns(), ColumnId.GROUP_NAME], force: true });
    } else {
      // Should not happen as gridApiRef was just set
      Sentry.captureMessage(`CampaignTable: gridContextRef is undefined though it was just set`, 'info');
    }
  }

  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  return (
    <>
      {isCampaignLoadingError ? (
        <Card className="flex-grow rounded-xl py-0">
          <ErrorLoadingDataAlert details={campaignLoadingErrorMessage} />
        </Card>
      ) : (
        <div style={{ height: isExpanded ? `calc(100vh - ${EXPANDED_VISIBLE_ABOVE_PX_ON_SCROLL_DOWN}px)` : heightCssValue }}>
          <AlGrid
            colDefs={!isNil(dataGroups) ? columnDefs : []}
            rowData={rowData}
            gridOptions={customGridOptions}
            isLoading={isLoading}
            onGridReadyCallback={onGridReady}
            noTopBorderRadius={noTopBorderRadius}
            fitToResizeEnabled={false}
            addExtraBottomPadding={true}
          />
        </div>
      )}
    </>
  );
};

export default CampaignTable;
