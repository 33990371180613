import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useUserContext, userService } from '@/modules/users';
import { HookEvent } from '@/modules/users/api/users/users.service';
import { Routes } from '@/router/router-paths';
import { Alert, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { isEmpty, isNil } from 'lodash-es';
import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import RegistrationWizardPage from '../components/RegistrationWizardPage';
import RegistrationWizardPageTitle from '../components/RegistrationWizardPageTitle';
import { useRegistrationContext } from '../contexts/registration/RegistrationContext';
import { AccountType, SpendType } from '../types/wizard_step_state.enum';

const MIN_AVG_SPEND_LENGTH = 1;

const RegistrationStepAboutYouPage: FunctionComponent = () => {
  const {
    state,
    teamId: registrationTeamID,
    userId: registrationUserID,
    accountType,
    setAccountType,
    averageAdSpend,
    setAverageAdSpend,
    hearAboutAdlabs,
    setHearAboutAdlabs,
  } = useRegistrationContext();

  const { user, setUser } = useUserContext();
  const { setActiveTeam } = useActiveTeamContext();
  const navigate = useNavigate();

  useEffect(() => {
    setAccountType('' as AccountType);
    setAverageAdSpend('' as SpendType);
    setHearAboutAdlabs('');
  }, []);

  useEffect(() => {
    if (!user && registrationUserID) {
      fetchAndSetUser(registrationUserID);
    } else if (user && registrationTeamID) {
      setActiveTeam({ teamId: registrationTeamID });
    }
  }, [user, registrationTeamID]);

  async function fetchAndSetUser(userId: number) {
    const userResponse = await userService.getUserById(userId);
    if (userResponse.isSuccess) {
      setUser(userResponse.payload);
    }
  }

  const [isContinueEnabled, setIsContinueEnabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleAccountTypeChange = (event: SelectChangeEvent) => {
    setAccountType(event.target.value as AccountType);
  };

  const handleAverageAdSpendChange = (event: SelectChangeEvent) => {
    setAverageAdSpend(event.target.value as SpendType);
  };

  const handleHearAboutAdlabsChange = (event: SelectChangeEvent) => {
    setHearAboutAdlabs(event.target.value);
  };

  useEffect(() => {
    if (!isEmpty(accountType) && !isEmpty(averageAdSpend) && averageAdSpend.length >= MIN_AVG_SPEND_LENGTH && !isSubmitting) {
      setIsContinueEnabled(true);
    } else {
      setIsContinueEnabled(false);
    }
  }, [accountType, averageAdSpend, hearAboutAdlabs]);

  function onContinueClicked() {
    setIsSubmitting(true);
    setIsError(false);

    if (isNil(accountType)) {
      setIsError(true);
      setErrorMessage('Account type not set');
      return;
    }

    try {
      const userID = user?.id ?? state.user?.userId;
      if (userID) {
        userService.updateUserWithMetadata(userID, {
          category: accountType,
          average_ad_spend: averageAdSpend,
          heard_about_adlabs: hearAboutAdlabs,
        });
      }

      userService.sendNewUserDataToHook({
        event: HookEvent.ABOUT_YOU,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        firebaseId: user?.firebaseId ?? state.user?.firebaseId ?? '',
        name: user?.name ?? '' + (user?.surname ? ' ' + user?.surname : ''),
        email: user?.email ?? state.user?.email ?? '',
        accountType,
        averageAdSpend: averageAdSpend.toString(),
        hearAboutAdlabs,
      });

      navigate(Routes.REGISTER_ALMOST_THERE);

      return;
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <RegistrationWizardPage>
      <div className="flex flex-col items-center pt-10">
        <RegistrationWizardPageTitle>About you</RegistrationWizardPageTitle>
        <div className="mt-10 w-96">
          <div className="flex flex-col gap-y-3">
            <div className="mt-4 text-center font-semibold text-slate-600">Tell us about yourself</div>
            <FormControl fullWidth>
              <InputLabel id="account-type-label">Who are you?</InputLabel>
              <Select
                labelId="account-type-label"
                label={'Who are you?'}
                id={'account-type'}
                value={accountType}
                onChange={handleAccountTypeChange}
              >
                {[AccountType.Agency, AccountType.Brand, AccountType.Freelancer].map((option) => {
                  return (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="average-ad-spend-label">Average monthly Ad Spend?</InputLabel>
              <Select
                labelId="average-ad-spend-label"
                label={'Average monthly Ad Spend?'}
                id={'average-ad-spend'}
                value={averageAdSpend}
                onChange={handleAverageAdSpendChange}
              >
                {[
                  SpendType.LessThanTenThousand,
                  SpendType.TenToTwentyThousand,
                  SpendType.TwentyToFiftyThousand,
                  SpendType.FiftyToOneHundredFiftyThousand,
                  SpendType.MoreThanOneHundredFiftyThousand,
                ].map((option) => {
                  return (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="hear-about-adlabs-label">How did you hear about AdLabs?</InputLabel>
              <Select
                labelId="hear-about-adlabs-label"
                label={'How did you hear about AdLabs?'}
                id={'hear-about-adlabs'}
                value={hearAboutAdlabs}
                onChange={handleHearAboutAdlabsChange}
              >
                {['That Amazon Ads Podcast', 'LinkedIn', 'Referral / Word of Mouth', 'PPC Ninja', 'Seller Central Utilities', 'Other'].map(
                  (option) => {
                    return (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    );
                  },
                )}
              </Select>
            </FormControl>

            {isError && (
              <Alert severity="error" className="mt-4">
                Something went wrong. The message was: {errorMessage}
              </Alert>
            )}
            <Button
              variant="contained"
              size="large"
              color="primary"
              className="mt-6"
              onClick={onContinueClicked}
              disabled={!isContinueEnabled}
              loading={isSubmitting}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </RegistrationWizardPage>
  );
};

export default RegistrationStepAboutYouPage;
