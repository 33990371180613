import { QueryClient } from '@tanstack/react-query';

export function invalidateDaypartingQuery(queryClient: QueryClient, activeProfileId: string | undefined) {
  queryClient.invalidateQueries({ queryKey: ['dayparting', activeProfileId] });
}

export function invalidateGetAllDaypartingSchedulesQuery(queryClient: QueryClient, activeProfileId: string | undefined) {
  if (!activeProfileId) return;
  queryClient.invalidateQueries({ queryKey: createGetAllDaypartingSchedulesQueryKey(activeProfileId) });
}

export function createGetAllDaypartingSchedulesQueryKey(activeProfileId: string | undefined) {
  return ['dayparting', 'getAllSchedules', activeProfileId];
}

export function createDaypartingFilteredCampaignsForSelectionQueryKey(activeProfileId: string | undefined) {
  return ['dayparting', 'filtered_campaigns_for_selection', activeProfileId];
}
