import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { filterFactory } from '@/components/filter-builder/models/FilterFactory';
import useAlFetchCache from '@/modules/al-fetch-cache/useAlFetchCache';
import CampaignFilterListEditor from '@/modules/campaigns/components/CampaignFilterListEditor';
import PlacementFilterListEditor from '@/modules/campaigns/components/PlacementFilterListEditor';
import ProductFilterListEditor from '@/modules/campaigns/components/ProductFilterListEditor';
import SearchTermFilterListEditor from '@/modules/campaigns/components/SearchTermFilterListEditor';
import TargetingFilterListEditor from '@/modules/campaigns/components/TargetingFilterListEditor';
import { useDashboardContextValue } from '@/modules/dashboards/contexts/DashboardContextProvider';
import { EntityType } from '@/modules/dashboards/types/EntityType';
import { cloneDeep } from 'lodash-es';
import { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { MetricWidgetConfiguration } from './MetricWidgetConfiguration';

interface MetricWidgetFiltersProps {
  configuration: MetricWidgetConfiguration;
  setConfiguration: Dispatch<SetStateAction<MetricWidgetConfiguration>>;
}

const MetricWidgetFilters: FunctionComponent<MetricWidgetFiltersProps> = ({ configuration, setConfiguration }) => {
  const dashboardProfiles = useDashboardContextValue((context) => context.dashboard?.teamProfiles);
  const { fetchCache } = useAlFetchCache();

  const onCampaignFiltersChanged = (newFilters: AlFilterModel[]) => {
    setConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.campaignFilters = newFilters.map((filter) => filter.toDTO());
      return newConfig;
    });
  };

  const onPlacementFiltersChanged = (newFilters: AlFilterModel[]) => {
    setConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.placementFilters = newFilters.map((filter) => filter.toDTO());
      return newConfig;
    });
  };

  const onTargetingFiltersChanged = (newFilters: AlFilterModel[]) => {
    setConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.targetFilters = newFilters.map((filter) => filter.toDTO());
      return newConfig;
    });
  };

  const onProductFiltersChanged = (newFilters: AlFilterModel[]) => {
    setConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.productFilters = newFilters.map((filter) => filter.toDTO());
      return newConfig;
    });
  };

  const onSearchTermFiltersChanged = (newFilters: AlFilterModel[]) => {
    setConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.searchTermFilters = newFilters.map((filter) => filter.toDTO());
      return newConfig;
    });
  };

  return (
    <>
      {configuration.entityType != EntityType.PROFILE && (
        <div className="mt-4">
          <div className="text-sm">{'Filters'}</div>
        </div>
      )}

      {configuration.entityType === EntityType.CAMPAIGN && (
        <CampaignFilterListEditor
          teamProfiles={dashboardProfiles ?? []}
          filters={
            configuration.campaignFilters
              ? filterFactory.createFilterModelsFromDTOs(configuration.campaignFilters, fetchCache, {
                  teamProfiles: dashboardProfiles,
                })
              : []
          }
          onFiltersChange={onCampaignFiltersChanged}
        />
      )}

      {configuration.entityType === EntityType.TARGET && (
        <TargetingFilterListEditor
          teamProfiles={dashboardProfiles ?? []}
          filters={
            configuration.targetFilters
              ? filterFactory.createFilterModelsFromDTOs(configuration.targetFilters, fetchCache, {
                  teamProfiles: dashboardProfiles,
                })
              : []
          }
          onFiltersChange={onTargetingFiltersChanged}
        />
      )}

      {configuration.entityType === EntityType.PLACEMENT && (
        <PlacementFilterListEditor
          teamProfiles={dashboardProfiles ?? []}
          filters={
            configuration.placementFilters
              ? filterFactory.createFilterModelsFromDTOs(configuration.placementFilters, fetchCache, {
                  teamProfiles: dashboardProfiles,
                })
              : []
          }
          onFiltersChange={onPlacementFiltersChanged}
        />
      )}

      {configuration.entityType === EntityType.PRODUCT && (
        <ProductFilterListEditor
          teamProfiles={dashboardProfiles ?? []}
          filters={
            configuration.productFilters
              ? filterFactory.createFilterModelsFromDTOs(configuration.productFilters, fetchCache, {
                  teamProfiles: dashboardProfiles,
                })
              : []
          }
          onFiltersChange={onProductFiltersChanged}
        />
      )}

      {configuration.entityType === EntityType.SEARCH_TERM && (
        <SearchTermFilterListEditor
          teamProfiles={dashboardProfiles ?? []}
          filters={
            configuration.searchTermFilters
              ? filterFactory.createFilterModelsFromDTOs(configuration.searchTermFilters, fetchCache, {
                  teamProfiles: dashboardProfiles,
                })
              : []
          }
          onFiltersChange={onSearchTermFiltersChanged}
        />
      )}
    </>
  );
};

export default MetricWidgetFilters;
