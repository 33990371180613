import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import FilterListEditor from '@/modules/campaigns/components/FilterListEditor';
import { TeamProfile } from '@/modules/dashboards/types/TeamProfile';
import { Skeleton } from '@mui/material';
import { FunctionComponent } from 'react';
import useMultiProfileSearchTermFilters from '../hooks/useMultiProfileSearchTermFilters';

interface SearchTermFilterListEditorProps {
  filters: AlFilterModel[];
  onFiltersChange: (filters: AlFilterModel[]) => void;
  teamProfiles: TeamProfile[];
}

const SearchTermFilterListEditor: FunctionComponent<SearchTermFilterListEditorProps> = ({ filters, onFiltersChange, teamProfiles }) => {
  const { availableSearchTermFilterBuilderFilters, isLoading, error } = useMultiProfileSearchTermFilters(teamProfiles);

  if (isLoading) {
    return <Skeleton variant="rounded" height={100} />;
  }

  if (error) {
    return <div>Error loading filters: {error.message}</div>;
  }

  return <FilterListEditor availableFilters={availableSearchTermFilterBuilderFilters} filters={filters} onFiltersChange={onFiltersChange} />;
};

export default SearchTermFilterListEditor;
