import { DateFilterModel, FilterCondition } from '@/components/filter-builder/models/AlFilterModel';
import { Popover } from '@mui/material';
import { Dispatch, FunctionComponent, RefObject, SetStateAction } from 'react';
import WeekRangePicker from './WeekRangePicker';
import { DateTime } from 'luxon';
import { DayStyleConfig } from '../../types/DayStyleConfig';

interface WeekRangePickerPopoverProps {
  buttonRef: RefObject<HTMLButtonElement>;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onSubmit: (filters: DateFilterModel[]) => void;
  defaultDateRange: FilterCondition[] | undefined;
  defaultComparisonDateRange: FilterCondition[] | undefined;
  title: string;
  disablePaywall?: boolean;
  hideComparison?: boolean;
  minDateString?: string;
  getDayStyleConfig: (day: DateTime<boolean>) => DayStyleConfig;
}

export const WeekRangePickerPopover: FunctionComponent<WeekRangePickerPopoverProps> = ({
  buttonRef,
  isOpen,
  setIsOpen,
  onSubmit,
  defaultDateRange,
  defaultComparisonDateRange,
  title,
  disablePaywall,
  hideComparison = false,
  minDateString,
  getDayStyleConfig,
}) => {
  const handlePopoverClose = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Popover
      id="week-range-popover"
      open={isOpen}
      anchorEl={buttonRef.current}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <WeekRangePicker
        title={title}
        setIsOpen={setIsOpen}
        onSubmit={onSubmit}
        defaultDateRange={defaultDateRange}
        defaultComparisonDateRange={defaultComparisonDateRange}
        disablePaywall={disablePaywall}
        hideComparison={hideComparison}
        minDateString={minDateString}
        getDayStyleConfig={getDayStyleConfig}
      />
    </Popover>
  );
};
