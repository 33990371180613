import { FilterBuilder } from '@/components/filter-builder/FilterBuilder';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { toastService } from '@/services/toast.service';
import { ContextKey } from '@/types/context-shared';
import { Dialog } from '@mui/material';
import { isNil } from 'lodash-es';
import { Dispatch, FunctionComponent, RefObject, SetStateAction, useEffect, useState } from 'react';
import { splitFiltersToApplicableAndDiscarded } from './hooks/useFilters';
import { AlFilterModel } from './models/AlFilterModel';
import { FilterPresetModel } from './models/FilterPresetModel';
import { FilterKey } from './types/FilterKey';

interface FilterBuilderDialogProps {
  buttonRef: RefObject<HTMLButtonElement>;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  availableFilters: AlFilterModel[];
  defaultFilterKey: FilterKey;
  appliedFilters: AlFilterModel[];
  onFiltersChanged: (filters: AlFilterModel[]) => void;
  contextKey?: ContextKey;
}

export const FilterBuilderDialog: FunctionComponent<FilterBuilderDialogProps> = ({
  isOpen,
  setIsOpen,
  availableFilters,
  defaultFilterKey,
  appliedFilters,
  onFiltersChanged,
  contextKey,
}) => {
  const { activeProfileIdChange, activeProfile } = useActiveTeamContext();

  const defaultFilters = availableFilters.filter((f) => f.key === defaultFilterKey);
  // Remove non-filter builder filters (e.g. date filters)
  const appliedFilterBuilderFilters = appliedFilters.filter((f) => f.isFilterBuilderFilter);
  const filterBuilderFiltersOnOpen = appliedFilterBuilderFilters.length > 0 ? appliedFilterBuilderFilters : defaultFilters;

  function onClose() {
    setIsOpen(false);
  }

  function notifyAboutDiscardedFilters(filters: AlFilterModel[]) {
    const discardedFiltersNames = filters.map((filter) => filter.shortName).join(', ');

    toastService.info(`Not applying filters with empty fields: ${discardedFiltersNames}`);
  }

  function onApply(filterBuilderFilters: AlFilterModel[], selectedPreset: FilterPresetModel | 'new' | null) {
    const { applicableFilters, discardedFilters } = splitFiltersToApplicableAndDiscarded(filterBuilderFilters);

    // Add non-filter builder filters (e.g. date filters)
    onFiltersChanged([...applicableFilters, ...appliedFilters.filter((f) => !f.isFilterBuilderFilter)]);
    if (discardedFilters.length > 0) notifyAboutDiscardedFilters(discardedFilters);

    setSelectedPreset(selectedPreset);
    onClose();
  }

  // FILTER PRESETS
  // Persistent state over open/closing the modal
  const [selectedPreset, setSelectedPreset] = useState<FilterPresetModel | 'new' | null>(null);
  useEffect(() => {
    if (activeProfileIdChange && activeProfileIdChange.hasChanged && activeProfile?.id) {
      setSelectedPreset(null);
    }
  }, [activeProfileIdChange]);

  // When user clears all from FilterChipRow, reset selected preset
  useEffect(() => {
    if (isNil(selectedPreset) || selectedPreset == 'new') return;

    if (appliedFilters.filter((f) => f.isFilterBuilderFilter).length == 0) {
      setSelectedPreset(null);
    }
  }, [appliedFilters]);
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth={contextKey ? 'lg' : 'md'}
      fullWidth
      slotProps={{
        paper: {
          sx: {
            maxHeight: 500, // keep in sync with FilterBuilder's isFilterAreaOverflowing
          },
        },
      }}
    >
      <FilterBuilder
        onApply={onApply}
        filterBuilderFiltersOnOpen={filterBuilderFiltersOnOpen}
        availableFilters={availableFilters}
        onCancel={onClose}
        onClose={onClose}
        contextKey={contextKey}
        selectedPreset={selectedPreset}
        dialogOpen={isOpen}
      />
    </Dialog>
  );
};
