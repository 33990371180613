import { EnabledPausedArchivedState } from '@/modules/optimizer/types/EnabledPausedArchivedState';
import { SearchTermHarvestedType, SearchTermTargetedType } from '@/modules/search-terms/api/search-terms-contracts';
import { BooleanType } from '@/types/boolean.types';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PauseCircleOutlineRoundedIcon from '@mui/icons-material/PauseCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';

import React from 'react';

export interface IconCellRendererConfig<T> {
  state: T;
  tooltip: string | React.JSX.Element | undefined;
  icon: React.JSX.Element;
  styleClasses: string;
}

const defaultClasses = 'rounded-full bg-opacity-10 flex items-center justify-center w-5 h-5';
export const enabledPausedArchivedStateIconConfig: Record<EnabledPausedArchivedState, IconCellRendererConfig<EnabledPausedArchivedState>> = {
  [EnabledPausedArchivedState.PAUSED]: {
    state: EnabledPausedArchivedState.PAUSED,
    tooltip: 'Paused',
    icon: <PauseCircleOutlineRoundedIcon className="text-orange-400" fontSize={'medium'} />,
    styleClasses: `${defaultClasses} border-orange-400 bg-orange-400`,
  },
  [EnabledPausedArchivedState.ARCHIVED]: {
    state: EnabledPausedArchivedState.ARCHIVED,
    tooltip: 'Archived',
    icon: <RemoveCircleOutlineRoundedIcon color={'disabled'} fontSize={'medium'} />,
    styleClasses: `${defaultClasses} border-slate-600 bg-slate-600`,
  },
  [EnabledPausedArchivedState.ENABLED]: {
    state: EnabledPausedArchivedState.ENABLED,
    tooltip: 'Enabled',
    icon: <CheckCircleOutlineRoundedIcon color={'success'} fontSize={'medium'} />,
    styleClasses: `${defaultClasses} border-green-600 bg-green-600`,
  },
};

export const booleanStateIconConfig: Record<BooleanType, IconCellRendererConfig<BooleanType>> = {
  [BooleanType.TRUE]: {
    state: BooleanType.TRUE,
    tooltip: 'Yes',
    icon: <CheckCircleOutlineRoundedIcon color={'success'} fontSize={'medium'} />,
    styleClasses: `${defaultClasses} border-green-600 bg-green-600`,
  },
  [BooleanType.FALSE]: {
    state: BooleanType.FALSE,
    tooltip: 'No',
    icon: <RemoveCircleOutlineRoundedIcon color={'disabled'} fontSize={'medium'} />,
    styleClasses: `${defaultClasses} border-slate-600 bg-slate-600`,
  },
};

export const harvestedStateIconConfig: Record<SearchTermHarvestedType, IconCellRendererConfig<SearchTermHarvestedType>> = {
  [SearchTermHarvestedType.HARVESTED]: {
    state: SearchTermHarvestedType.HARVESTED,
    tooltip: 'Harvested',
    icon: <CheckCircleOutlineRoundedIcon color={'success'} fontSize={'medium'} />,
    styleClasses: `${defaultClasses} border-green-600 bg-green-600`,
  },
  [SearchTermHarvestedType.NOT_HARVESTED]: {
    state: SearchTermHarvestedType.NOT_HARVESTED,
    tooltip: 'Not Harvested',
    icon: <ErrorOutlineIcon color={'info'} fontSize={'medium'} />,
    styleClasses: `${defaultClasses} border-slate-600 bg-slate-600`,
  },
  [SearchTermHarvestedType.NOT_MAPPED]: {
    state: SearchTermHarvestedType.NOT_MAPPED,
    tooltip: 'Not Mapped',
    icon: <RemoveCircleOutlineRoundedIcon color={'disabled'} fontSize={'medium'} />,
    styleClasses: `${defaultClasses} border-slate-600 bg-slate-600`,
  },
};

export const targetedStateIconConfig: Record<SearchTermTargetedType, IconCellRendererConfig<SearchTermTargetedType>> = {
  [SearchTermTargetedType.TARGETED]: {
    state: SearchTermTargetedType.TARGETED,
    tooltip: 'Targeted',
    icon: <CheckCircleOutlineRoundedIcon color={'success'} fontSize={'medium'} />,
    styleClasses: `${defaultClasses} border-green-600 bg-green-600`,
  },
  [SearchTermTargetedType.NOT_TARGETED]: {
    state: SearchTermTargetedType.NOT_TARGETED,
    tooltip: 'Not Targeted',
    icon: <RemoveCircleOutlineRoundedIcon color={'disabled'} fontSize={'medium'} />,
    styleClasses: `${defaultClasses} border-slate-600 bg-slate-600`,
  },
};
