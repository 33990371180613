import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import DateRangeButton from '@/modules/application/components/date-range-picker/DateRangeButton';
import { FunctionComponent } from 'react';
import { useDashboardQueries } from '../../hooks/useDashboardQueries';

interface DashboardDateRangeButtonProps {
  filters: AlFilterModel[];
  setFilterValues: (filters: AlFilterModel[]) => void;
}

const DashboardDateRangeButton: FunctionComponent<DashboardDateRangeButtonProps> = ({ filters, setFilterValues }) => {
  const { dashboardData } = useDashboardQueries();

  return (
    <DateRangeButton
      title="Select Dates"
      filters={filters}
      setFilterValues={setFilterValues}
      tooltip="Date range to use"
      comparisonRangeWarning={
        dashboardData && dashboardData.dto.comparison_missing ? 'Comparison data is missing for the selected date range' : undefined
      }
    />
  );
};

export default DashboardDateRangeButton;
