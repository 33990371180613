import { isNil } from 'lodash-es';
import { MatchType } from '../components/optimization/api/optimization-contracts';

export type ExistingMatchType = MatchType.EXACT | MatchType.PHRASE | MatchType.BROAD | MatchType.INDIVIDUAL | MatchType.EXPANDED;

export interface ExistingMatchTypeWithStateCount {
  matchType: ExistingMatchType;
  enabled: number;
  paused: number;
}

const ExistingMatchTypeOrder = {
  [MatchType.EXACT]: 0,
  [MatchType.PHRASE]: 1,
  [MatchType.BROAD]: 2,
  [MatchType.INDIVIDUAL]: 3,
  [MatchType.EXPANDED]: 4,
};

export const getSortedExistingMatchTypes = (matchTypes: ExistingMatchType[]): ExistingMatchType[] => {
  if (isNil(matchTypes)) {
    return [];
  }

  return matchTypes.sort((a, b) => {
    const aOrder = ExistingMatchTypeOrder[a] ?? 999;
    const bOrder = ExistingMatchTypeOrder[b] ?? 999;
    return aOrder - bOrder;
  });
};
