import { createProductFiltersForMultipleProfiles } from '@/components/filter-builder/models/AlFilterModel';
import useAlFetchCache from '@/modules/al-fetch-cache/useAlFetchCache';
import { TeamProfile } from '@/modules/dashboards/types/TeamProfile';
import { createGetAllGroupsForMultipleTeamsQueryKey, dataGroupsService } from '@/modules/data-groups/api/data-groups-service';
import { useUserContext } from '@/modules/users';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';

const useMultiProfileProductFilters = (teamProfiles: TeamProfile[]) => {
  const { fetchCache } = useAlFetchCache();

  const {
    data: dataGroups,
    isLoading: isDataGroupsLoading,
    error,
  } = useQuery({
    queryKey: createGetAllGroupsForMultipleTeamsQueryKey(teamProfiles.map((profile) => profile.teamId)),
    queryFn: async () => {
      const results = await Promise.all(
        teamProfiles.map((profile) => dataGroupsService.getAllGroupsForProfile(profile.teamId, profile.profileId)),
      );
      return results.flatMap((result) => result.payload);
    },
    enabled: !isEmpty(teamProfiles),
  });

  const { isProfileSeller, isProfileVendor } = useUserContext();
  const isSeller = teamProfiles.some((profile) => isProfileSeller(profile.profileId));
  const isVendor = teamProfiles.some((profile) => isProfileVendor(profile.profileId));
  const availableProductFilters = createProductFiltersForMultipleProfiles(teamProfiles, dataGroups || [], fetchCache, isSeller, isVendor);

  const availableProductFilterBuilderFilters = availableProductFilters.filter((filter) => filter.isFilterBuilderFilter);

  return {
    availableProductFilters,
    availableProductFilterBuilderFilters,
    isLoading: isDataGroupsLoading,
    error,
  };
};

export default useMultiProfileProductFilters;
