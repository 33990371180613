import {
  getConfigForMetric,
  getMetricAggDependencies,
  getMetricConfigByColId,
  isAggAvg,
  isCalculatedMetric,
} from '@/components/metrics/MetricsConfig';
import { MetricData, MetricDataWithPreviousDays } from '@/components/metrics/types/MetricData';
import { MetricField } from '@/components/metrics/types/MetricField';
import useFormatting from '@/hooks/useFormatting';
import { MatchType } from '@/modules/optimizer/components/optimization/api/optimization-contracts';
import { BiddingEntity } from '@/modules/optimizer/components/optimization/models/OptimizationModel';
import { EnabledPausedArchivedState } from '@/modules/optimizer/types/EnabledPausedArchivedState';
import { SearchTermHarvestedType, SearchTermTargetedType } from '@/modules/search-terms/api/search-terms-contracts';
import { BooleanType } from '@/types/boolean.types';
import type { GridApi, IAggFuncParams, IRowNode } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import { ColumnId } from '../columns/columns.enum';
import { NONE_LABEL, StringToCount, collectLeafNodeValues, countEnumValues, makeRowNodesUniqueByField, safeDivide } from '../helpers';
import useColDefsFunctions from './useColDefsFunctions';

function useAggregators() {
  const { formatDateStringTimeNoSeconds } = useFormatting();
  const { getCalculatedMetricAggDataForMetricData } = useColDefsFunctions();

  function campaignNameAggFunc(params: IAggFuncParams) {
    const count = collectLeafNodeValues(params).length;
    return `${count} campaign${count === 1 ? '' : 's'}`;
  }

  function targetsAggFunc(params: IAggFuncParams) {
    const count = collectLeafNodeValues(params).length;
    return `${count} target${count === 1 ? '' : 's'}`;
  }

  function adGroupsAggFunc(params: IAggFuncParams) {
    const count = collectLeafNodeValues(params).length;
    return `${count} ad group${count === 1 ? '' : 's'}`;
  }

  function portfolioAggFunc(params: IAggFuncParams) {
    const count = collectLeafNodeValues(params).length;
    return `${count} portfolio${count === 1 ? '' : 's'}`;
  }

  function asinAggFunc(params: IAggFuncParams) {
    // Unique ASINs
    const count = new Set(collectLeafNodeValues(params)).size;
    return `${count} ASIN${count === 1 ? '' : 's'}`;
  }

  function searchQueryAggFunc(params: IAggFuncParams) {
    const count = new Set(collectLeafNodeValues(params)).size;
    return `${count} search ${count === 1 ? 'query' : 'queries'}`;
  }

  function parentAsinAggFunc(params: IAggFuncParams) {
    // Unique Parent ASINs
    const count = new Set(collectLeafNodeValues(params)).size;
    return `${count} Parent ASIN${count === 1 ? '' : 's'}`;
  }

  function teamNameFilteredGridAggFunc(params: IAggFuncParams) {
    const count = getFilteredLeafNodeCountForParent(params.rowNode, params.api);

    return `${count} team${count === 1 ? '' : 's'}`;
  }

  function profileNameFilteredGridAggFunc(params: IAggFuncParams) {
    const count = getFilteredLeafNodeCountForParent(params.rowNode, params.api);

    return `${count} profile${count === 1 ? '' : 's'}`;
  }

  function groupNameAggFunc(params: IAggFuncParams) {
    const unqValues = new Set(collectLeafNodeValues(params).filter((str) => str !== ''));
    const count = unqValues.size;

    return count === 1 ? '1 opt group' : `${count} opt groups`;
  }

  function enabledPausedArchivedStateAggFunc(params: IAggFuncParams) {
    return countEnumValues(EnabledPausedArchivedState, collectLeafNodeValues(params));
  }

  function booleanAggFunc(params: IAggFuncParams) {
    return countEnumValues(BooleanType, collectLeafNodeValues(params));
  }

  function harvestedAggFunc(params: IAggFuncParams) {
    return countEnumValues(SearchTermHarvestedType, collectLeafNodeValues(params));
  }

  function targetedAggFunc(params: IAggFuncParams) {
    return countEnumValues(SearchTermTargetedType, collectLeafNodeValues(params));
  }

  function matchTypeAggFunc(params: IAggFuncParams) {
    return countEnumValues(MatchType, collectLeafNodeValues(params));
  }

  function metricsDataAggFunc<T extends object>(params: IAggFuncParams, uniqueByField?: keyof T): MetricData {
    if (!params.colDef?.colId) return [0, 0];

    const columnId = params.colDef?.colId as ColumnId;
    const metricField = columnId ? getMetricConfigByColId(columnId)?.key : null;

    if (!metricField) {
      return [0, 0];
    }

    if (isCalculatedMetric(metricField)) {
      const deps = getMetricAggDependencies(metricField);
      if (!deps) return [0, 0];

      const allLeafChildren = params.rowNode.allLeafChildren ?? [];
      const rowNodes = uniqueByField ? makeRowNodesUniqueByField(allLeafChildren, uniqueByField) : allLeafChildren;

      const depsAggData = createSumAggDataForFields(deps, rowNodes, params.api);
      return getCalculatedMetricAggDataForMetricData(metricField, depsAggData);
    }

    const metricsArray = (() => {
      if (isNil(uniqueByField)) {
        return params.values as MetricData[];
      }

      const allLeafChildren = params.rowNode.allLeafChildren ?? [];
      const rowNodes = makeRowNodesUniqueByField(allLeafChildren, uniqueByField);
      return rowNodes.map((rowNode) => params.api.getCellValue({ rowNode, colKey: columnId }));
    })();

    if (!params.values) return [0, 0];

    const sums = metricsArray.reduce(
      (accumulator: MetricData, current: MetricData) => {
        if (current == null) {
          // This can happen in an intermediary state when colDefs have changed (other metrics), but data yet isn't (data is null)
          // Happens when changing between seller-vendor. When data arrives and is not null, the aggregation is run again and
          // correct values are shown
          return accumulator;
        }
        return [accumulator[0] + current[0], accumulator[1] + current[1]];
      },
      [0, 0],
    );

    if (isAggAvg(metricField)) {
      return [safeDivide(sums[0], metricsArray.length), safeDivide(sums[1], metricsArray.length)];
    }

    return sums;
  }

  function metricDataWithPreviousDaysAggFunc(params: IAggFuncParams): number[] {
    if (!params.values) return [];

    return (params.values as MetricDataWithPreviousDays[]).reduce((acc: number[], array) => {
      if (!array) return acc;
      array.forEach((metricValue, index) => {
        if (acc[index] !== undefined) {
          acc[index] += metricValue;
        } else {
          acc[index] = metricValue;
        }
      });
      return acc;
    }, []);
  }

  function stringCountingAggFunc(params: IAggFuncParams) {
    const counts = collectLeafNodeValues<string>(params).reduce<Record<string, { value: string; count: number }>>((acc, value) => {
      const stringValue = value;

      if (!acc[stringValue]) {
        acc[stringValue] = { value: stringValue, count: 0 };
      }
      acc[stringValue].count += 1;
      return acc;
    }, {});

    return Object.entries(counts)
      .filter(([, valueCount]) => valueCount.count > 0)
      .map(([, valueCount]) => `${valueCount.value == '' ? NONE_LABEL : valueCount.value}: ${valueCount.count}`)
      .join(' | ');
  }

  function lastOptimizedAggFunc(params: IAggFuncParams) {
    const unqValues = new Set(collectLeafNodeValues<string>(params));
    const unqCount = unqValues.size;
    if (unqValues.size === 1) {
      const optTime = unqValues.keys().next().value;
      if (!optTime) return 'Never optimized any';
      return 'All optimized at ' + formatDateStringTimeNoSeconds(optTime);
    }
    return `${unqCount} different optimization times`;
  }

  function stringToCountAggFunc(params: IAggFuncParams): StringToCount {
    const aggregation: StringToCount = {};

    for (const str of collectLeafNodeValues<string>(params)) {
      if (aggregation[str]) {
        aggregation[str] += 1;
      } else {
        aggregation[str] = 1;
      }
    }

    return aggregation;
  }

  function optimizationBiddingEntityAggFunc(params: IAggFuncParams): StringToCount {
    const aggregation: StringToCount = {};

    for (let str of collectLeafNodeValues<string>(params)) {
      // HARD CODED SPECIAL CASES
      if (str === BiddingEntity.OTHER) str = BiddingEntity.PLACEMENT_REST_OF_SEARCH;
      if (str === BiddingEntity.BRANDS_PLACEMENT_TOP) str = BiddingEntity.PLACEMENT_TOP;
      if (str === BiddingEntity.DETAIL_PAGE) str = BiddingEntity.PLACEMENT_PRODUCT_PAGE;

      if (aggregation[str]) {
        aggregation[str] += 1;
      } else {
        aggregation[str] = 1;
      }
    }

    return aggregation;
  }

  function dataGroupAggFunc(params: IAggFuncParams): StringToCount {
    const aggregation: StringToCount = {};

    const colId = params.colDef.colId;
    const api = params.api;

    if (!colId || !params.rowNode.allLeafChildren || !api) return aggregation;

    // No need for optional chaining since allLeafChildren is guaranteed to exist.
    const cellValues = params.rowNode.allLeafChildren.map((rowNode) => api.getCellValue({ rowNode, colKey: colId }));

    for (const str of cellValues) {
      if (!str) continue; // Don't count unassigned values.
      aggregation[str] = (aggregation[str] || 0) + 1;
    }
    return aggregation;
  }

  return {
    campaignNameAggFunc,
    enabledPausedArchivedStateAggFunc,
    groupNameAggFunc,
    stringCountingAggFunc,
    lastOptimizedAggFunc,
    stringToCountAggFunc,
    optimizationBiddingEntityAggFunc,
    metricDataWithPreviousDaysAggFunc,
    targetsAggFunc,
    adGroupsAggFunc,
    portfolioAggFunc,
    booleanAggFunc,
    harvestedAggFunc,
    targetedAggFunc,
    matchTypeAggFunc,
    metricsDataAggFunc,
    teamNameFilteredGridAggFunc,
    profileNameFilteredGridAggFunc,
    dataGroupAggFunc,
    asinAggFunc,
    parentAsinAggFunc,
    searchQueryAggFunc,
  };
}

export default useAggregators;

function createSumAggDataForFields(fields: MetricField[], rowNodes: IRowNode[], api: GridApi): Record<string, MetricData> {
  const aggData: Record<string, MetricData> = {};

  // Init so at least zero values and key is present
  for (const field of fields) {
    aggData[field] = [0, 0];
  }

  for (const rowNode of rowNodes) {
    for (const field of fields) {
      const colId = getConfigForMetric(field).colId;

      const data = api.getCellValue({ rowNode, colKey: colId });
      // This can happen in an intermediary state when colDefs have changed (other metrics), but data yet isn't (data is null)
      // Happens when changing between seller-vendor. When data arrives and is not null, the aggregation is run again and
      // correct values are shown
      if (isNil(data)) {
        continue;
      }
      aggData[field][0] += data[0];
      aggData[field][1] += data[1];
    }
  }

  return aggData;
}

export const getAllFilteredLeafRowNodes = (gridApi: GridApi): IRowNode[] => {
  const filteredNodes: IRowNode[] = [];

  gridApi.forEachNodeAfterFilter((node) => {
    if (node.group) return;
    filteredNodes.push(node);
  });

  return filteredNodes;
};

const getFilteredLeafNodeCountForParent = (rowNode: IRowNode, gridApi: GridApi): number => {
  const allLeafNodesIds = new Set((rowNode.allLeafChildren ?? []).map((node) => node.id));
  let count = 0;
  gridApi.forEachNodeAfterFilter((node) => {
    if (allLeafNodesIds.has(node.id)) {
      count += 1;
    }
  });

  return count;
};
