import { AlFilterModel, createQueryKeyFromFilters } from '@/components/filter-builder/models/AlFilterModel';
import { UserModel } from '@/modules/users';
import { QueryClient } from '@tanstack/react-query';

const _PROFILE_STATS_QUERY_KEY = 'profile-stats';
export function createProfileStatsQueryKey(filters: AlFilterModel[], user: UserModel | undefined) {
  return [
    _PROFILE_STATS_QUERY_KEY,
    createQueryKeyFromFilters(filters),
    user?.teams.map((t) => t.name), // If team name is changed we need to refresh
    user?.teams.map((t) => t.profiles.map((p) => p.id)).flat(), // If a profile is added or removed we need to refresh
  ];
}

export function invalidateAll_profileStatsQueryKeys(queryClient: QueryClient) {
  const profileStatsQueryKeys_withoutFilters = [_PROFILE_STATS_QUERY_KEY];
  queryClient.invalidateQueries({
    predicate: (query) => profileStatsQueryKeys_withoutFilters.every((keyPart, index) => query.queryKey[index] === keyPart),
  });
}
