export interface JobActionDTO {
  id: string;
  type: ActionType;
  entity_type: ActionEntityType;
  success: number;
  failed: number;
  created_at: string;
  flow_type: FlowType;
  job_id: string;
  created_by: string;
}

export enum FlowType {
  NEGATIVE_TARGETING = 'NEGATIVE_TARGETING',
  HARVESTING = 'HARVESTING',
  CAMPAIGN_OPTIMIZER = 'CAMPAIGN_OPTIMIZER',
  TARGETING = 'TARGETING',
  PLACEMENT = 'PLACEMENT',
  SEARCH_TERMS = 'SEARCH_TERMS',
  CAMPAIGN_MAPPING = 'CAMPAIGN_MAPPING',
  OPTIMIZATION_GROUPS = 'OPTIMIZATION_GROUPS',
  OPTIMIZATION_GROUPS_IMPORT = 'OPTIMIZATION_GROUPS_IMPORT',
  DATA_GROUPS = 'DATA_GROUPS',
}

export enum ActionType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export enum ActionEntityType {
  NEGATIVE = 'NEGATIVE',
  TARGET = 'TARGET',
  CAMPAIGN = 'CAMPAIGN',
  AD_GROUP = 'AD_GROUP',
  CAMPAIGN_MAP = 'CAMPAIGN_MAP',
  OPTIMIZATION_GROUP = 'OPTIMIZATION_GROUP',
  DATA_GROUP = 'DATA_GROUP',
}
