import { VideoUrl } from '@/config/urls';
import useFormatting from '@/hooks/useFormatting';
import { DataGroupsPopover } from '@/modules/data-groups/components/DataGroupsPopover';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { PaywallModal } from '@/modules/plans/components/PaywallModal';
import { CurrentAndMaxCount } from '@/modules/search-query-performance/api/search-query-performance-contracts';
import { SearchQueryPerformancePopover } from '@/modules/search-query-performance/components/SearchQueryPerformancePopover';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { Routes } from '@/router/router-paths';
import { toastService } from '@/services/toast.service';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import { Button, Checkbox, Divider, Tooltip } from '@mui/material';
import type { GridApi } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import { FunctionComponent, MutableRefObject, useRef, useState } from 'react';
import OppositeModeOverlayBar from '../../../components/OppositeModeOverlayBar';
import { SelectedProduct } from '../models/ProductModel';

interface ProductsActionsBarProps {
  selectedProducts: SelectedProduct[];
  setSelectedProducts: (selectedProducts: SelectedProduct[]) => void;
  totalProductsCount: number;
  onDiscardClicked: () => void;
  productsTableGridApiRef: MutableRefObject<GridApi | null>;
  sqpCurrentAndMaxCount: CurrentAndMaxCount;
}

const ProductsSelectionActionsBar: FunctionComponent<ProductsActionsBarProps> = ({
  selectedProducts,
  setSelectedProducts,
  totalProductsCount,
  onDiscardClicked,
  productsTableGridApiRef,
  sqpCurrentAndMaxCount,
}) => {
  const { activeTeam, activeProfile } = useActiveTeamContext();

  const { formatWithThousandsSeparator } = useFormatting();

  // Paywall
  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const onClosePaywallModal = () => {
    setIsPaywallModalOpen(false);
  };

  // Tags
  const dataGroupsButtonRef = useRef<HTMLButtonElement | null>(null);
  const [isDataGroupsPopoverOpen, setIsDataGroupsPopoverOpen] = useState(false);
  const onDataGroupsClicked = () => {
    if (isNil(activeTeam)) {
      toastService.error('Active team is not set');
      return;
    }

    if (!activeTeam.subscriptionPlan.canAssignDataGroups) {
      setIsPaywallModalOpen(true);
      return;
    }

    setIsDataGroupsPopoverOpen(true);
  };

  // SQP
  const sqpButtonRef = useRef<HTMLButtonElement | null>(null);
  const [isSqpPopoverOpen, setIsSqpPopoverOpen] = useState(false);
  const onSqpsClicked = () => {
    if (isNil(activeTeam)) {
      toastService.error('Active team is not set');
      return;
    }

    if (!activeTeam.subscriptionPlan.canUseSearchQueryPerformancePage) {
      setIsPaywallModalOpen(true);
      return;
    }

    setIsSqpPopoverOpen(true);
  };

  // HANDLE SELECTION
  function onDeselectAll() {
    setSelectedProducts([]);
    if (productsTableGridApiRef.current) {
      productsTableGridApiRef.current.deselectAll();
    }
  }

  function onSelectAll() {
    if (productsTableGridApiRef.current) {
      productsTableGridApiRef.current.forEachNodeAfterFilter((node) => {
        node.setSelected(true);
      });
    }
  }

  return (
    <>
      <OppositeModeOverlayBar visible={selectedProducts.length > 0}>
        <div className="flex flex-row items-center gap-4 px-1.5">
          <Tooltip title="Deselect all">
            <div onClick={onDiscardClicked} className="flex flex-row items-center text-sm font-bold hover:cursor-pointer">
              <Checkbox checked={true} name="deselectCheckbox" color="secondary" />
              {formatWithThousandsSeparator(selectedProducts.length)} of {formatWithThousandsSeparator(totalProductsCount)}
            </div>
          </Tooltip>

          <Divider className="my-2" orientation="vertical" flexItem />

          <div className="flex flex-row items-center gap-4 whitespace-nowrap">
            <Button
              ref={dataGroupsButtonRef}
              size="small"
              className="whitespace-nowrap"
              onClick={onDataGroupsClicked}
              variant={isDataGroupsPopoverOpen ? 'contained' : 'outlined'}
              startIcon={<AccountTreeIcon />}
            >
              Tags
            </Button>
            {activeProfile?.isSeller && (
              <Button
                ref={sqpButtonRef}
                size="small"
                className="whitespace-nowrap"
                onClick={onSqpsClicked}
                variant={isSqpPopoverOpen ? 'contained' : 'outlined'}
                startIcon={<QueryStatsOutlinedIcon />}
              >
                SQP Assignment
              </Button>
            )}

            <Divider className="my-2" orientation="vertical" flexItem />
            <Button
              className="whitespace-nowrap"
              onClick={selectedProducts.length == totalProductsCount ? onDeselectAll : onSelectAll}
              variant="text"
            >
              {selectedProducts.length == totalProductsCount ? 'Deselect All' : 'Select All'}
            </Button>
          </div>
        </div>
      </OppositeModeOverlayBar>

      <DataGroupsPopover
        buttonRef={dataGroupsButtonRef}
        isOpen={isDataGroupsPopoverOpen}
        setIsOpen={setIsDataGroupsPopoverOpen}
        selectedItems={selectedProducts}
        dataGroupType={DataGroupType.PRODUCT}
      />

      {activeProfile && (
        <SearchQueryPerformancePopover
          buttonRef={sqpButtonRef}
          isOpen={isSqpPopoverOpen}
          setIsOpen={setIsSqpPopoverOpen}
          selectedItems={selectedProducts}
          currentAndMaxCount={sqpCurrentAndMaxCount}
        />
      )}
      <PaywallModal
        isOpen={isPaywallModalOpen}
        onClose={onClosePaywallModal}
        returnURLPath={Routes.OPTIMIZER}
        headerText="Upgrade to Pro to Access Tags"
        videoUrl={VideoUrl.DATA_GROUPS}
      ></PaywallModal>
    </>
  );
};

export default ProductsSelectionActionsBar;
