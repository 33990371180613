import { AlDate, DAY_WITH_MONTH_FORMAT, MONTH_FORMAT, MONTH_YEAR_FORMAT } from '@/lib/date/AlDate';
import { TimelineModel } from '@/modules/optimizer/components/timeline/models/TimelineModel';
import {
  CommonMetricField,
  MetricField,
  ProfileStatsSellerMetricField,
  SearchQueryPerformanceField,
  SellerMetricField,
  VendorMetricField,
} from '../metrics/types/MetricField';
import { TimeUnit } from './types';

// weekStart: 0 (Sunday) to 6 (Saturday)
function getWeekStartDate(dateString: string, weekStart: number = 0): string {
  const date = AlDate.parse(dateString);
  if (!date.isValid()) {
    console.error('Invalid date string:', dateString);
    return '';
  }

  // Adjust day according to the weekStart
  const dayNum = (date.weekday + 7 - weekStart) % 7;

  // Subtract the dayNum from the date to get the week start date
  const startDate = date.subtractDays(dayNum);

  return startDate.toFormat(DAY_WITH_MONTH_FORMAT);
}

// Get month name for aggregation (conditionally add year if needed)
function getMonthKey(dateString: string, includeYear: boolean): string {
  const date = AlDate.parse(dateString);
  if (!date.isValid()) {
    console.error('Invalid date string:', dateString);
    return 'Invalid Date';
  }

  if (includeYear) {
    // Get short month name and year (e.g., "Jan '25")
    return date.toFormat(MONTH_YEAR_FORMAT);
  } else {
    // Get short month name (e.g., "Jan")
    return date.toFormat(MONTH_FORMAT);
  }
}

// Determines if a given week period is partial
function isWeekPeriodPartial(periodKey: string, periodDayCount: Map<string, number>): boolean {
  const dayCount = periodDayCount.get(periodKey);
  return dayCount !== undefined && dayCount < 7;
}

// Determines if a given month period is partial
function isMonthPeriodPartial(periodKey: string, periodDayCount: Map<string, number>): boolean {
  const dayCount = periodDayCount.get(periodKey);
  if (dayCount === undefined) return false;

  // Extract month and year from the periodKey
  const match = periodKey.match(/(\w{3}) ?'?(20\d{2})?/); // Matches "Jan" or "Jan '24"
  if (!match) return false;

  const [, monthName, yearStr] = match;
  const year = yearStr ? parseInt(`20${yearStr.slice(-2)}`) : AlDate.now().year; // Default to current year if missing
  const monthIndex = new Date(`${monthName} 1, ${year}`).getMonth() + 1;

  // Get the total days in this specific month and year
  const totalDaysInMonth = AlDate.daysInMonth(year, monthIndex);

  return totalDaysInMonth ? dayCount < totalDaysInMonth : false;
}

export function aggregateByPeriod(data: TimelineModel, aggregateBy: TimeUnit): TimelineModel {
  const initialData = new Map<string, { [key in MetricField]?: number }>();
  const periodDayCount = new Map<string, number>();

  // Determine if we need to include the year suffix
  const earliestDate = AlDate.parse(data.xAxisData[0]);
  const latestDate = AlDate.parse(data.xAxisData[data.xAxisData.length - 1]);

  if (!earliestDate.isValid() || !latestDate.isValid()) {
    console.error('Invalid date range in xAxisData');
    return data;
  }

  const monthsDifference = latestDate.diff(earliestDate, 'months');
  const includeYear = monthsDifference > 12; // More than 12 months

  // Initialize data for weekly or monthly aggregation
  data.xAxisData.forEach((date) => {
    const periodKey = aggregateBy === TimeUnit.WEEK ? getWeekStartDate(date) : getMonthKey(date, includeYear);

    if (!initialData.has(periodKey)) {
      initialData.set(periodKey, {});
      periodDayCount.set(periodKey, 0);
    }

    periodDayCount.set(periodKey, (periodDayCount.get(periodKey) || 0) + 1);
  });

  // Sum up direct metrics weekly or monthly
  data.yAxisData.forEach((metric) => {
    // TODO: fix later
    if (metric.key == ('seller_metrics' as CommonMetricField) || metric.key == ('vendor_metrics' as CommonMetricField)) {
      return;
    }

    if (!metric.values) {
      // Seller/vendor metrics can be null if not connected
      return;
    }

    metric.values.forEach((value, index) => {
      const periodKey =
        aggregateBy === TimeUnit.WEEK ? getWeekStartDate(data.xAxisData[index]) : getMonthKey(data.xAxisData[index], includeYear);
      const metrics = initialData.get(periodKey);
      if (metrics) {
        metrics[metric.key] = (metrics[metric.key] || 0) + value;
      }
    });
  });

  // Create new aggregated data with updated keys (add Partial where needed)
  const aggregatedData = new Map<string, { [key in MetricField]?: number }>();
  initialData.forEach((metrics, periodKey) => {
    const isPartial =
      aggregateBy === TimeUnit.WEEK ? isWeekPeriodPartial(periodKey, periodDayCount) : isMonthPeriodPartial(periodKey, periodDayCount);

    const newKey = `${periodKey}${isPartial ? ' (Partial)' : ''}`;
    aggregatedData.set(newKey, metrics);
  });

  // TODO: find a way to use aggFunc from MetricsConfig

  // Calculate metrics like CTR, ACOS, ROAS, etc.
  aggregatedData.forEach((metrics) => {
    // Calculating Click-Through Rate (CTR)
    if (metrics[CommonMetricField.CLICKS] !== undefined && metrics[CommonMetricField.IMPRESSIONS] !== undefined) {
      metrics[CommonMetricField.CTR] = metrics[CommonMetricField.CLICKS] / metrics[CommonMetricField.IMPRESSIONS];
    }

    // Calculating Advertising Cost of Sales (ACOS)
    if (metrics[CommonMetricField.SPEND] !== undefined && metrics[CommonMetricField.SALES]) {
      metrics[CommonMetricField.ACOS] = metrics[CommonMetricField.SPEND] / metrics[CommonMetricField.SALES];
    }

    // Calculating aCTC
    if (metrics[CommonMetricField.CLICKS] !== undefined && metrics[CommonMetricField.ORDERS]) {
      metrics[CommonMetricField.ACTC] = metrics[CommonMetricField.CLICKS] / metrics[CommonMetricField.ORDERS];
    }

    // Calculating Return on Advertising Spend (ROAS)
    if (metrics[CommonMetricField.SALES] !== undefined && metrics[CommonMetricField.SPEND]) {
      metrics[CommonMetricField.ROAS] = metrics[CommonMetricField.SALES] / metrics[CommonMetricField.SPEND];
    }

    // Calculating Revenue Per Click (RPC)
    if (metrics[CommonMetricField.SALES] !== undefined && metrics[CommonMetricField.CLICKS]) {
      metrics[CommonMetricField.RPC] = metrics[CommonMetricField.SALES] / metrics[CommonMetricField.CLICKS];
    }

    // Calculating Cost Per Acquisition (CPA)
    if (metrics[CommonMetricField.SPEND] !== undefined && metrics[CommonMetricField.ORDERS]) {
      metrics[CommonMetricField.CPA] = metrics[CommonMetricField.SPEND] / metrics[CommonMetricField.ORDERS];
    }

    // Calculating Average Order Value (AOV)
    if (metrics[CommonMetricField.SALES] !== undefined && metrics[CommonMetricField.ORDERS]) {
      metrics[CommonMetricField.AOV] = metrics[CommonMetricField.SALES] / metrics[CommonMetricField.ORDERS];
    }

    // Calculating Cost Per Click (CPC)
    if (metrics[CommonMetricField.SPEND] !== undefined && metrics[CommonMetricField.CLICKS]) {
      metrics[CommonMetricField.CPC] = metrics[CommonMetricField.SPEND] / metrics[CommonMetricField.CLICKS];
    }

    // Calculating Cost Per Mille (CPM)
    if (metrics[CommonMetricField.SPEND] !== undefined && metrics[CommonMetricField.IMPRESSIONS]) {
      metrics[CommonMetricField.CPM] = (metrics[CommonMetricField.SPEND] / metrics[CommonMetricField.IMPRESSIONS]) * 1000;
    }

    // Calculating Conversion Rate (CVR)
    if (metrics[CommonMetricField.ORDERS] !== undefined && metrics[CommonMetricField.CLICKS]) {
      metrics[CommonMetricField.CVR] = metrics[CommonMetricField.ORDERS] / metrics[CommonMetricField.CLICKS];
    }

    // SELLER
    if (metrics[SellerMetricField.SELLER_SALES] && metrics[CommonMetricField.SPEND]) {
      metrics[SellerMetricField.SELLER_ACOS] = metrics[CommonMetricField.SPEND] / metrics[SellerMetricField.SELLER_SALES];
    }

    // if (metrics[SellerMetricField.SELLER_CLICKS] && metrics[SellerMetricField.SELLER_ORDERS]) {
    //   metrics[SellerMetricField.SELLER_ACTC] = metrics[SellerMetricField.SELLER_CLICKS] / metrics[SellerMetricField.SELLER_ORDERS];
    // }

    if (metrics[SellerMetricField.SELLER_SALES] && metrics[SellerMetricField.SELLER_UNITS]) {
      metrics[SellerMetricField.SELLER_ASP] = metrics[SellerMetricField.SELLER_SALES] / metrics[SellerMetricField.SELLER_UNITS];
    }

    if (metrics[SellerMetricField.SELLER_ORDERS] && metrics[SellerMetricField.SELLER_CLICKS]) {
      metrics[SellerMetricField.SELLER_CVR] = metrics[SellerMetricField.SELLER_ORDERS] / metrics[SellerMetricField.SELLER_CLICKS];
    }

    if (metrics[SellerMetricField.SELLER_UNITS] && metrics[SellerMetricField.SELLER_CLICKS]) {
      metrics[SellerMetricField.SELLER_UNIT_SESS] = metrics[SellerMetricField.SELLER_UNITS] / metrics[SellerMetricField.SELLER_CLICKS];
    }

    if (metrics[CommonMetricField.SALES] && metrics[SellerMetricField.SELLER_SALES]) {
      metrics[SellerMetricField.SELLER_AD_SALES_OF_TOTAL] = metrics[CommonMetricField.SALES] / metrics[SellerMetricField.SELLER_SALES];
    }

    if (metrics[SellerMetricField.SELLER_SALES] && metrics[SellerMetricField.SELLER_ORDERS]) {
      metrics[SellerMetricField.SELLER_AOV] = metrics[SellerMetricField.SELLER_SALES] / metrics[SellerMetricField.SELLER_ORDERS];
    }

    if (metrics[CommonMetricField.SPEND] && metrics[SellerMetricField.SELLER_ORDERS]) {
      metrics[SellerMetricField.SELLER_CPA] = metrics[CommonMetricField.SPEND] / metrics[SellerMetricField.SELLER_ORDERS];
    }

    if (metrics[SellerMetricField.SELLER_SALES] && metrics[CommonMetricField.SPEND]) {
      metrics[SellerMetricField.SELLER_ROAS] = metrics[SellerMetricField.SELLER_SALES] / metrics[CommonMetricField.SPEND];
    }

    if (metrics[SellerMetricField.SELLER_UNITS] && metrics[SellerMetricField.SELLER_VIEWS]) {
      metrics[SellerMetricField.SELLER_UNIT_VIEW] = metrics[SellerMetricField.SELLER_UNITS] / metrics[SellerMetricField.SELLER_VIEWS];
    }

    if (metrics[ProfileStatsSellerMetricField.SELLER_UNITS_REFUNDED] && metrics[SellerMetricField.SELLER_UNITS]) {
      metrics[ProfileStatsSellerMetricField.SELLER_UNITS_REFUND_RATE] =
        metrics[ProfileStatsSellerMetricField.SELLER_UNITS_REFUNDED] / metrics[SellerMetricField.SELLER_UNITS];
    }

    // Vendor
    if (metrics[VendorMetricField.VENDOR_SALES] && metrics[CommonMetricField.SPEND]) {
      metrics[VendorMetricField.VENDOR_ACOS] = metrics[CommonMetricField.SPEND] / metrics[VendorMetricField.VENDOR_SALES];
    }

    if (metrics[VendorMetricField.VENDOR_SHIPPED_COGS] && metrics[CommonMetricField.SPEND]) {
      metrics[VendorMetricField.VENDOR_ACOGS] = metrics[CommonMetricField.SPEND] / metrics[VendorMetricField.VENDOR_SHIPPED_COGS];
    }

    if (metrics[VendorMetricField.VENDOR_SALES] && metrics[VendorMetricField.VENDOR_UNITS]) {
      metrics[VendorMetricField.VENDOR_ASP] = metrics[VendorMetricField.VENDOR_SALES] / metrics[VendorMetricField.VENDOR_UNITS];
    }

    if (metrics[CommonMetricField.SALES] && metrics[VendorMetricField.VENDOR_SALES]) {
      metrics[VendorMetricField.VENDOR_AD_SALES_OF_TOTAL] = metrics[CommonMetricField.SALES] / metrics[VendorMetricField.VENDOR_SALES];
    }

    if (metrics[VendorMetricField.VENDOR_SALES] && metrics[CommonMetricField.SPEND]) {
      metrics[VendorMetricField.VENDOR_ROAS] = metrics[VendorMetricField.VENDOR_SALES] / metrics[CommonMetricField.SPEND];
    }

    if (metrics[VendorMetricField.VENDOR_UNITS] && metrics[VendorMetricField.VENDOR_VIEWS]) {
      metrics[VendorMetricField.VENDOR_UNIT_VIEW] = metrics[VendorMetricField.VENDOR_UNITS] / metrics[VendorMetricField.VENDOR_VIEWS];
    }

    if (metrics[VendorMetricField.VENDOR_CUSTOMER_RETURNS] && metrics[VendorMetricField.VENDOR_UNITS]) {
      metrics[VendorMetricField.VENDOR_RETURN_RATE] =
        metrics[VendorMetricField.VENDOR_CUSTOMER_RETURNS] / metrics[VendorMetricField.VENDOR_UNITS];
    }

    // Search Query Performance

    // ASIN Impression Share
    if (metrics[SearchQueryPerformanceField.ASIN_IMPRESSION_COUNT] && metrics[SearchQueryPerformanceField.TOTAL_QUERY_IMPRESSION_COUNT]) {
      metrics[SearchQueryPerformanceField.ASIN_IMPRESSION_SHARE] =
        metrics[SearchQueryPerformanceField.ASIN_IMPRESSION_COUNT] / metrics[SearchQueryPerformanceField.TOTAL_QUERY_IMPRESSION_COUNT];
    }

    // Total Click Rate
    if (metrics[SearchQueryPerformanceField.TOTAL_CLICK_COUNT] && metrics[SearchQueryPerformanceField.SEARCH_QUERY_VOLUME]) {
      metrics[SearchQueryPerformanceField.TOTAL_CLICK_RATE] =
        metrics[SearchQueryPerformanceField.TOTAL_CLICK_COUNT] / metrics[SearchQueryPerformanceField.SEARCH_QUERY_VOLUME];
    }

    // ASIN Click Share
    if (metrics[SearchQueryPerformanceField.ASIN_CLICK_COUNT] && metrics[SearchQueryPerformanceField.TOTAL_CLICK_COUNT]) {
      metrics[SearchQueryPerformanceField.ASIN_CLICK_SHARE] =
        metrics[SearchQueryPerformanceField.ASIN_CLICK_COUNT] / metrics[SearchQueryPerformanceField.TOTAL_CLICK_COUNT];
    }

    // Total Cart Add Rate
    if (metrics[SearchQueryPerformanceField.TOTAL_CART_ADD_COUNT] && metrics[SearchQueryPerformanceField.SEARCH_QUERY_VOLUME]) {
      metrics[SearchQueryPerformanceField.TOTAL_CART_ADD_RATE] =
        metrics[SearchQueryPerformanceField.TOTAL_CART_ADD_COUNT] / metrics[SearchQueryPerformanceField.SEARCH_QUERY_VOLUME];
    }

    // ASIN Cart Add Share
    if (metrics[SearchQueryPerformanceField.ASIN_CART_ADD_COUNT] && metrics[SearchQueryPerformanceField.TOTAL_CART_ADD_COUNT]) {
      metrics[SearchQueryPerformanceField.ASIN_CART_ADD_SHARE] =
        metrics[SearchQueryPerformanceField.ASIN_CART_ADD_COUNT] / metrics[SearchQueryPerformanceField.TOTAL_CART_ADD_COUNT];
    }

    // Total Purchase Rate
    if (metrics[SearchQueryPerformanceField.TOTAL_PURCHASE_COUNT] && metrics[SearchQueryPerformanceField.SEARCH_QUERY_VOLUME]) {
      metrics[SearchQueryPerformanceField.TOTAL_PURCHASE_RATE] =
        metrics[SearchQueryPerformanceField.TOTAL_PURCHASE_COUNT] / metrics[SearchQueryPerformanceField.SEARCH_QUERY_VOLUME];
    }

    // ASIN Purchase Share
    if (metrics[SearchQueryPerformanceField.ASIN_PURCHASE_COUNT] && metrics[SearchQueryPerformanceField.TOTAL_PURCHASE_COUNT]) {
      metrics[SearchQueryPerformanceField.ASIN_PURCHASE_SHARE] =
        metrics[SearchQueryPerformanceField.ASIN_PURCHASE_COUNT] / metrics[SearchQueryPerformanceField.TOTAL_PURCHASE_COUNT];
    }

    // Total CTR
    if (metrics[SearchQueryPerformanceField.TOTAL_CLICK_COUNT] && metrics[SearchQueryPerformanceField.TOTAL_QUERY_IMPRESSION_COUNT]) {
      metrics[SearchQueryPerformanceField.TOTAL_CTR] =
        metrics[SearchQueryPerformanceField.TOTAL_CLICK_COUNT] / metrics[SearchQueryPerformanceField.TOTAL_QUERY_IMPRESSION_COUNT];
    }

    // ASIN CTR
    if (metrics[SearchQueryPerformanceField.ASIN_CLICK_COUNT] && metrics[SearchQueryPerformanceField.ASIN_IMPRESSION_COUNT]) {
      metrics[SearchQueryPerformanceField.ASIN_CTR] =
        metrics[SearchQueryPerformanceField.ASIN_CLICK_COUNT] / metrics[SearchQueryPerformanceField.ASIN_IMPRESSION_COUNT];
    }

    // ASIN Conversion Rate
    if (metrics[SearchQueryPerformanceField.ASIN_PURCHASE_COUNT] && metrics[SearchQueryPerformanceField.ASIN_CLICK_COUNT]) {
      metrics[SearchQueryPerformanceField.ASIN_CONVERSION_RATE] =
        metrics[SearchQueryPerformanceField.ASIN_PURCHASE_COUNT] / metrics[SearchQueryPerformanceField.ASIN_CLICK_COUNT];
    }

    // Total Conversion Rate
    if (metrics[SearchQueryPerformanceField.TOTAL_PURCHASE_COUNT] && metrics[SearchQueryPerformanceField.TOTAL_CLICK_COUNT]) {
      metrics[SearchQueryPerformanceField.TOTAL_CONVERSION_RATE] =
        metrics[SearchQueryPerformanceField.TOTAL_PURCHASE_COUNT] / metrics[SearchQueryPerformanceField.TOTAL_CLICK_COUNT];
    }
  });

  return fromAggregatedData(aggregatedData);
}

function fromAggregatedData(aggregatedData: Map<string, { [key in CommonMetricField]?: number }>): TimelineModel {
  const xAxisData: string[] = [];
  const yAxisData: {
    key: CommonMetricField;
    values: number[];
  }[] = [];

  // Assuming aggregatedData is not empty, get the first entry's metrics
  const firstPeriodMetrics = aggregatedData.size > 0 ? Array.from(aggregatedData.values())[0] : {};

  // Initialize yAxisData with empty arrays for each metric in the order they appear in aggregatedData
  Object.keys(firstPeriodMetrics).forEach((metricKey) => {
    yAxisData.push({
      key: metricKey as CommonMetricField,
      values: [],
    });
  });

  // Populate xAxisData and accumulate period values in yAxisData
  aggregatedData.forEach((metrics, periodKey) => {
    xAxisData.push(`${periodKey}`);

    yAxisData.forEach((metricData) => {
      const metricValue = metrics[metricData.key as keyof typeof metrics] ?? 0;
      metricData.values.push(metricValue);
    });
  });

  return new TimelineModel({
    xAxisData: xAxisData,
    yAxisData: yAxisData,
  });
}
