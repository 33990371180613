import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import { Button, Tooltip } from '@mui/material';
import { FunctionComponent } from 'react';
import { removeDateRangeFilters } from '../hooks/useFilters';
import { AlFilterModel } from '../models/AlFilterModel';

interface SaveFiltersButtonProps {
  filters: AlFilterModel[];
  isExistingPresetModified: boolean;
  isExistingPresetSelected: boolean;
  onSaveAsNewClicked: () => void;
  updateFilters: () => void;
  hasPendingFiltersWithoutValuesOrNoFilters: boolean;
  notApplicableFilters: AlFilterModel[];
}

const SaveFiltersButton: FunctionComponent<SaveFiltersButtonProps> = ({
  filters: pendingFilters,
  isExistingPresetModified,
  isExistingPresetSelected,
  onSaveAsNewClicked,
  updateFilters,
  hasPendingFiltersWithoutValuesOrNoFilters,
  notApplicableFilters,
}) => {
  const filters = removeDateRangeFilters(pendingFilters);

  // Events
  function onSaveAsNew() {
    onSaveAsNewClicked();
  }

  const tooltip = (() => {
    if (filters.length === 0) {
      return 'No Filters to save';
    }

    if (notApplicableFilters.length > 0) {
      return (
        <>
          Some filters have missing values:
          <ul style={{ listStyle: 'disc', marginLeft: '1em' }}>
            {notApplicableFilters.map((f) => (
              <li key={f.key}>{f.shortName}</li>
            ))}
          </ul>
        </>
      );
    }

    if (isExistingPresetSelected && !isExistingPresetModified) {
      return 'No changes';
    }

    return undefined;
  })();

  return (
    <div className="flex flex-row mr-4">
      <Tooltip title={tooltip}>
        <span>
          {isExistingPresetSelected ? (
            <Button
              startIcon={<SaveOutlined />}
              variant="text"
              onClick={updateFilters}
              disabled={hasPendingFiltersWithoutValuesOrNoFilters || !isExistingPresetModified}
            >
              Update Filters
            </Button>
          ) : (
            <Button
              startIcon={<SaveAsOutlinedIcon />}
              variant="text"
              onClick={onSaveAsNew}
              disabled={hasPendingFiltersWithoutValuesOrNoFilters}
            >
              Save New
            </Button>
          )}
        </span>
      </Tooltip>

      {isExistingPresetSelected && (
        <Button
          className="ml-2"
          startIcon={<SaveAsOutlinedIcon />}
          variant="text"
          onClick={onSaveAsNew}
          disabled={hasPendingFiltersWithoutValuesOrNoFilters}
        >
          Save New
        </Button>
      )}
    </div>
  );
};

export default SaveFiltersButton;
