import { FunctionComponent } from 'react';
import { isNumber } from 'lodash-es';
import { useQueryClient } from '@tanstack/react-query';
import { daypartingScheduleService } from '../../api/dayparting.service';
import { DaypartingScheduleFormModel } from './DaypartingScheduleFormModel';
import DaypartingScheduleFormLogic from './DaypartingScheduleFormLogic';
import { DaypartingScheduleModel } from '../../types/DaypartingScheduleModel';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { ApiResponseCode } from '@/lib/api/api-response-code';
import { invalidateProfile_campaignsWithTimelineQueryKeys } from '@/modules/optimizer/api/campaign/campaign-service';
import { invalidateGetAllDaypartingSchedulesQuery } from '../../api/dayparting.cache';
import { toastService } from '@/services/toast.service';

interface DaypartingScheduleFormProps {
  schedule: DaypartingScheduleModel;
}

const DaypartingScheduleForm: FunctionComponent<DaypartingScheduleFormProps> = ({ schedule }) => {
  const queryClient = useQueryClient();
  const { activeProfile } = useActiveTeamContext();

  const handleSubmit = async (data: DaypartingScheduleFormModel) => {
    const response = await daypartingScheduleService.update(schedule.id, {
      days: data.days,
      name: data.name,
      schedule_id: schedule.id,
      state: data.state,
    });

    if (!response.isSuccess) {
      if (response.responseCode === ApiResponseCode.NAME_ALREADY_EXISTS) {
        toastService.error('A Dayparting Schedule with this name already exists');
        return false;
      } else {
        toastService.error('Error saving Dayparting Schedule');
        return false;
      }
    } else {
      // Save the campaigns as well, both removal and addition
      const campaignsToRemove = schedule.campaignIds.filter((id) => !data.campaignIds.includes(id));
      if (campaignsToRemove.length > 0) {
        const removeResponse = await daypartingScheduleService.deleteAssignment({
          campaign_ids: campaignsToRemove,
        });
        if (!removeResponse.isSuccess) {
          toastService.error('Error saving Dayparting Schedule');
          return false;
        }
      }

      const campaignsToAdd = data.campaignIds.filter((id) => !schedule.campaignIds.includes(id));
      if (campaignsToAdd.length > 0) {
        const assignResponse = await daypartingScheduleService.assign({
          assignments: [{ schedule_id: schedule.id, campaign_ids: campaignsToAdd }],
        });
        if (!assignResponse.isSuccess) {
          toastService.error('Error saving Dayparting Schedule');
          return false;
        }
      }

      toastService.success('Dayparting Schedule saved successfully');
      invalidateProfile_campaignsWithTimelineQueryKeys(queryClient, activeProfile?.id);
      invalidateGetAllDaypartingSchedulesQuery(queryClient, activeProfile?.id);
    }

    return true;
  };

  const initialValues: DaypartingScheduleFormModel = {
    name: schedule.name,
    state: schedule.state,
    days: schedule.daysWithPercentageChanges,
    campaignIds: schedule.campaignIds,
  };

  return (
    <DaypartingScheduleFormLogic initialValues={initialValues} onSubmit={handleSubmit} daypartingScheduleId={schedule ? schedule.id : 0} />
  );
};

export default DaypartingScheduleForm;
