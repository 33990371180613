export enum ColumnId {
  //Settings
  ID = 'id',
  CHECKBOX = 'checkbox',
  CAMPAIGN_ID = 'campaignId',
  CAMPAIGN_NAME = 'campaignName',
  CAMPAIGN_STATE = 'campaignState',
  TARGETING = 'targeting',
  BID = 'bid',
  NAME = 'name',
  STATE = 'state',
  MATCH = 'match',
  AD_GROUP = 'adGroup',
  AD_GROUP_STATE = 'adGroupState',
  CAMPAIGN_AD_TYPE = 'campaignAdType',
  CREATIVE_TYPE = 'CreativeType',
  COST_TYPE = 'costType',
  BID_OPTIMIZATION = 'bidOptimization',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  TARGETING_TYPE = 'targetingType',
  PORTFOLIO_NAME = 'portfolioName',
  ENTITY_TYPE = 'entityType',
  BUDGET_TYPE = 'budgetType',
  BUDGET_AMOUNT = 'budgetAmount',
  BID_STRATEGY = 'bidStrategy',
  MULTI_AD_GROUPS_ENABLED = 'multiAdGroupsEnabled',
  SERVING_STATUS = 'servingStatus',
  TAGS = 'tags',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',

  // Optimizer
  BIDDING_ENTITY = 'biddingEntity',
  OLD_VALUE = 'oldValue',
  NEW_VALUE = 'newValue',
  DELTA = 'delta',
  REASONS = 'reasons',

  // Placements
  PLACEMENT_TYPE = 'placementType',
  PLACEMENT_PERCENTAGE = 'placementPercentage',

  // Search Terms
  SEARCH_TERM = 'searchTerm',
  WORD_COUNT = 'wordCount',
  HARVESTED = 'harvested',
  NEGATIVES = 'negatives',
  EXISTING_MATCH_TYPES = 'existingMatchTypes',

  //AdLabs
  DATA_ITEMS = 'dataItems',
  LAST_OPTIMIZED = 'lastOptimized',

  //Products
  TITLE = 'TITLE',
  AVAILABILITY = 'AVAILABILITY',
  BASIS_PRICE = 'BASIS_PRICE',
  PRICE_TO_PAY = 'PRICE_TO_PAY',
  BEST_SELLER_RANK = 'BEST_SELLER_RANK',
  RANK_CHANGE = 'RANK_CHANGE',
  ASIN = 'ASIN',
  PARENT_ASIN = 'PARENT_ASIN',
  SKU = 'SKU',
  CATEGORY = 'CATEGORY',
  BRAND = 'BRAND',
  SQP_STATE = 'sqpState',

  // Ad Metrics
  IMPRESSIONS = 'impressions',
  CLICKS = 'clicks',
  ORDERS = 'orders',
  UNITS = 'units',
  CTR = 'ctr',
  CVR = 'cvr',
  CPC = 'cpc',
  SPEND = 'spend',
  SALES = 'sales',
  SAME_SKU_ORDERS = 'sso',
  SAME_SKU_SALES = 'sss',
  OTHER_SKU_SALES = 'oss',
  ACOS = 'acos',
  ACTC = 'actc',
  ROAS = 'roas',
  RPC = 'rpc',
  CPA = 'cpa',
  AOV = 'aov',
  CPM = 'cpm',
  SALES_PREVIOUS_DAYS = 'sales.previousDays',
  SPEND_PREVIOUS_DAYS = 'spend.previousDays',

  // Mapping related?
  CAMPAIGN_NAME_DESTINATION = 'campaignNameDestination',
  AD_GROUP_DESTINATION = 'adGroupDestination',
  CAMPAIGN_AD_TYPE_DESTINATION = 'campaignAdTypeDestination',

  // Other shit
  REPORTED_AT = 'reportedAt',
  PROFILE_NAME = 'profileName',
  PROFILE_ID = 'profileId',
  ACCOUNT_ID = 'accountId',
  BILLING_STATUS = 'billingStatus',
  MARKET = 'market',
  CURRENCY = 'currency',
  TIMEZONE = 'timezone',
  TYPE = 'type',
  ONBOARD_DATE = 'onboardDate',
  TEAM_ACCESS = 'teamAccess',
  DEBUG = 'debug',
  REPORT_INFO = 'reportInfo',
  DATA_STATUS = 'dataStatus',
  SELLING_PARTNER_DATA_STATUS = 'sellingPartnerDataStatus', // selling partner (portal) = seller central + vendor central
  PROCESS_LAST = 'processLast',
  MANUAL_UPDATE = 'manualUpdate',
  CAMPAIGN_LAST_OPTIMIZED = 'campaignLastOptimized',
  REMOVE_PROFILE = 'removeProfile',
  JOB_ID = 'jobId',
  REVERTED_JOB_ID = 'revertedJobId',
  NOTES = 'notes',
  VERSION = 'version',
  APPLIED_ENTITY_COUNT = 'appliedEntityCount',
  SUCCESS_ENTITY_COUNT = 'successEntityCount',
  FAILED_ENTITY_COUNT = 'failedEntityCount',
  TARGET_ACOS = 'tacos',
  SENTIMENT = 'sentiment',
  BID_CEILING = 'bidCeiling',
  REVERT_JOB = 'revertJob',
  RESTART_INITIAL_REPORTS = 'restartInitialReports',
  DELETE_ARCHIVED_TARGETS = 'deleteArchivedTargets',
  CREATE_SEARCH_QUERY_PERFORMANCE_REPORTS = 'createSearchQueryPerformanceReports',
  GROUP_ID = 'groupId',
  GROUP_NAME = 'groupName',
  GROUP_DESCRIPTION = 'groupDescription',
  GROUP_TACOS = 'groupTacos',
  GROUP_PRESET = 'groupPreset',
  GROUP_BID_CEILING = 'groupBidCeiling',
  GROUP_BID_FLOOR = 'groupBidFloor',
  GROUP_OPTIMIZATION_ENABLED = 'groupOptimizationEnabled',
  CAMPAIGN_GROUP_COUNT = 'campaignGroupCount',
  CAMPAIGN_GROUP_DELETE = 'campaignGroupDelete',
  ACTIONS = 'actions',
  ACTION = 'action',
  SYNC_STV = 'syncStv',
  TEAM_NAME = 'teamName',
  TEAM_PLAN = 'teamPlan',
  TEAM_ROLE = 'teamRole',
  TEAM_DELETE = 'teamDelete',
  TEAM_MEMBERS_COUNT = 'teamMembersCount',
  TEAM_IS_AUTHORIZED = 'teamIsAuthorized',
  TEAM_PROFILES_COUNT = 'teamProfilesCount',
  TEAM_OWNER = 'teamOwner',
  TEAM_MEMBERS = 'teamMembers',
  TEAM_PREFERRED_UPDATE_TIME = 'teamPreferredUpdateTime',
  FLOW_TYPE = 'flowType',
  ACTION_ENTITY_TYPE = 'actionEntityType',
  ENTITY_TYPE_DESTINATION = 'entityTypeDestination',
  ENTITY_ID = 'entityId',
  NEGATIVE_MATCH_TYPE = 'negativeMatchType',
  NEGATIVE_CAMPAIGN = 'negativeCampaign',
  NEGATIVE_AD_GROUP = 'negativeAdGroup',
  BID_METHOD = 'bidMethod',
  BID_METHOD_VALUE = 'bidMethodValue',
  AD_SPEND = 'adSpend',
  ESTIMATED_MONTHLY_AD_SPEND = 'estimatedMonthlyAdSpend',
  // ESTIMATED_COST = 'estimatedCost',
  BID_FLOOR = 'bidFloor',
  USERNAME = 'username',
  SOURCE_GROUP = 'SOURCE_GROUP',
  DESTINATION_GROUP = 'DESTINATION_GROUP',
  STARTING_BID_GROUP = 'STARTING_BID_GROUP',
  MATCH_TYPE_GROUP = 'MATCH_TYPE_GROUP',
  SELLER_CENTRAL_AUTHORIZED = 'SellerCentralAuthorized',
  WIDGETS = 'WIDGETS',
  PROFILES = 'PROFILES',
  COLLABORATORS = 'COLLABORATORS',
  OWNER = 'OWNER',
  AMAZON_AUTH = 'AMAZON_AUTH',

  // SELLER CENTRAL
  ACOTS = 'acots',
  //TOTAL_ACTC = 'totalActc',
  AD_SALES_OF_TOTAL = 'adSalesOfTotal',
  TOTAL_AOV = 'totalAov',
  ASP = 'asp',
  TOTAL_CLICKS = 'totalClicks',
  TOTAL_CPA = 'totalCpa',
  TOTAL_CVR = 'totalCvr',
  TOTAL_ORDERS = 'totalOrders',
  ORGANIC_SALES = 'organicSales',
  ORGANIC_TRAFFIC = 'organicTraffic',
  TOTAL_ROAS = 'totalRoas',
  TOTAL_SALES = 'totalSales',
  UPS = 'ups', // Units per session (Unit Session %)
  UNIT_VIEW = 'unitView', // Units per page view
  TOTAL_UNITS = 'totalUnits',
  TOTAL_VIEWS = 'totalViews',
  UNITS_REFUNDED = 'unitRefunded',
  UNITS_REFUND_RATE = 'unitRefundRate',

  // VENDOR CENTRAL
  VENDOR_ACOTS = 'vendor_acots',
  VENDOR_ACOGS = 'vendor_acogs',
  VENDOR_AD_SALES_OF_TOTAL = 'vendorAdSalesOfTotal',
  VENDOR_ASP = 'vendorAsp',
  CUSTOMER_RETURNS = 'customerReturns',
  VENDOR_TOTAL_VIEWS = 'vendorTotalViews',
  VENDOR_TOTAL_SALES = 'vendorTotalSales',
  VENDOR_TOTAL_UNITS = 'vendorTotalUnits',
  VENDOR_ORGANIC_SALES = 'vendorOrganicSales',
  VENDOR_UNIT_VIEW = 'vendorUnitView', // Units per page view
  VENDOR_TOTAL_ROAS = 'vendorTotalRoas',
  SHIPPED_COGS = 'shippedCogs',
  SHIPPED_REVENUE = 'shippedRevenue',
  SHIPPED_UNITS = 'shippedUnits',
  RETURN_RATE = 'returnRate',

  // Performance
  PERFORMANCE_ACOS = 'performanceAcos',
  PERFORMANCE_ACOTS = 'performanceAcots',
  PERFORMANCE_AD_SPEND = 'performanceAdSpend',
  PERFORMANCE_AD_SALES = 'performanceAdSales',
  PERFORMANCE_TOTAL_SALES = 'performanceTotalSales',

  // Organization
  ORGANIZATION_NAME = 'organizationName',

  // Dayparting
  ASSIGNED_COUNT = 'assignedCount',
  CREATED_BY = 'createdBy',
  SCHEDULE_ID = 'scheduleId',
  SCHEDULE_NAME = 'scheduleName',

  // SQP
  SEARCH_QUERY = 'searchQuery',
  SEARCH_QUERY_VOLUME = 'searchQueryVolume',
  TOTAL_QUERY_IMPRESSION_COUNT = 'totalQueryImpressionCount',
  ASIN_IMPRESSION_COUNT = 'asinImpressionCount',
  ASIN_IMPRESSION_SHARE = 'asinImpressionShare',
  TOTAL_CLICK_COUNT = 'totalClickCount',
  TOTAL_CLICK_RATE = 'totalClickRate',
  ASIN_CLICK_COUNT = 'asinClickCount',
  ASIN_CLICK_SHARE = 'asinClickShare',
  TOTAL_CART_ADD_COUNT = 'totalCartAddCount',
  TOTAL_CART_ADD_RATE = 'totalCartAddRate',
  ASIN_CART_ADD_COUNT = 'asinCartAddCount',
  ASIN_CART_ADD_SHARE = 'asinCartAddShare',
  TOTAL_PURCHASE_COUNT = 'totalPurchaseCount',
  TOTAL_PURCHASE_RATE = 'totalPurchaseRate',
  ASIN_PURCHASE_COUNT = 'asinPurchaseCount',
  ASIN_PURCHASE_SHARE = 'asinPurchaseShare',

  SEARCH_QUERY_SCORE = 'searchQueryScore',
  TOTAL_MEDIAN_CLICK_PRICE = 'totalMedianClickPrice',
  ASIN_MEDIAN_CLICK_PRICE = 'asinMedianClickPrice',
  TOTAL_SAME_DAY_SHIPPING_CLICK_COUNT = 'totalSameDayShippingClickCount',
  TOTAL_ONE_DAY_SHIPPING_CLICK_COUNT = 'totalOneDayShippingClickCount',
  TOTAL_TWO_DAY_SHIPPING_CLICK_COUNT = 'totalTwoDayShippingClickCount',
  TOTAL_MEDIAN_CART_ADD_PRICE = 'totalMedianCartAddPrice',
  ASIN_MEDIAN_CART_ADD_PRICE = 'asinMedianCartAddPrice',
  TOTAL_SAME_DAY_SHIPPING_CART_ADD_COUNT = 'totalSameDayShippingCartAddCount',
  TOTAL_ONE_DAY_SHIPPING_CART_ADD_COUNT = 'totalOneDayShippingCartAddCount',
  TOTAL_TWO_DAY_SHIPPING_CART_ADD_COUNT = 'totalTwoDayShippingCartAddCount',
  TOTAL_MEDIAN_PURCHASE_PRICE = 'totalMedianPurchasePrice',
  ASIN_MEDIAN_PURCHASE_PRICE = 'asinMedianPurchasePrice',
  TOTAL_SAME_DAY_SHIPPING_PURCHASE_COUNT = 'totalSameDayShippingPurchaseCount',
  TOTAL_ONE_DAY_SHIPPING_PURCHASE_COUNT = 'totalOneDayShippingPurchaseCount',
  TOTAL_TWO_DAY_SHIPPING_PURCHASE_COUNT = 'totalTwoDayShippingPurchaseCount',
  TOTAL_CTR = 'totalCTR',
  ASIN_CTR = 'asinCTR',
  ASIN_CONVERSION_RATE = 'asinConversionRate',
  TOTAL_CONVERSION_RATE = 'totalConversionRate',
}

export const AD_PERFORMANCE_COLUMNS = [ColumnId.PERFORMANCE_ACOS, ColumnId.PERFORMANCE_AD_SPEND, ColumnId.PERFORMANCE_AD_SALES];

// TODO: use enums in metrics config
export const SELLER_VENDOR_COLUMNS = [
  ColumnId.ACOTS,
  ColumnId.AD_SALES_OF_TOTAL,
  ColumnId.ASP,
  ColumnId.ORGANIC_SALES,
  ColumnId.TOTAL_ROAS,
  ColumnId.TOTAL_SALES,
  ColumnId.TOTAL_UNITS,
  ColumnId.TOTAL_VIEWS,
  ColumnId.UNIT_VIEW,
  ColumnId.PERFORMANCE_TOTAL_SALES,
  ColumnId.PERFORMANCE_ACOTS,
  ColumnId.UNITS_REFUNDED,
  ColumnId.UNITS_REFUND_RATE,
];
