import { MetricData } from '@/components/metrics/types/MetricData';
import { SearchTermTargetedType } from '@/modules/search-terms/api/search-terms-contracts';
import { TargetedFields } from '@/modules/search-terms/models/SearchTermModel';
import { DTO } from '@/types/dto-wrapper';
import { isNil } from 'lodash-es';
import { SearchQueryPerformanceDTO } from '../api/search-query-performance-contracts';
import { ExistingMatchTypeWithStateCount } from '@/modules/optimizer/types/ExistingMatchType';

export class SearchQueryPerformanceModel extends DTO<SearchQueryPerformanceDTO> implements TargetedFields {
  constructor(dto: SearchQueryPerformanceDTO) {
    super(dto);
  }

  public get id() {
    return `${this.dto.a} ${this.dto.sq}`;
  }

  public get title() {
    return this.dto.t;
  }

  public get imageUrl() {
    return this.dto.i;
  }

  public get asin(): string {
    return this.dto.a;
  }

  public get searchQuery(): string {
    return this.dto.sq;
  }

  public get existingTargetsState(): SearchTermTargetedType {
    if (!isNil(this.dto.ex) && this.dto.ex.length > 0) {
      return SearchTermTargetedType.TARGETED;
    }
    return SearchTermTargetedType.NOT_TARGETED;
  }

  public get existingTargetMatchTypes(): ExistingMatchTypeWithStateCount[] {
    return this.dto.ex
      .filter((ex) => ex.e > 0)
      .map((ex) => {
        return {
          matchType: ex.m,
          enabled: ex.e,
          paused: ex.p,
        };
      });
  }

  public get searchQueryScore(): MetricData {
    return this.dto.sqs;
  }

  public get searchQueryVolume(): MetricData {
    return this.dto.sqv;
  }

  public get totalQueryImpressionCount(): MetricData {
    return this.dto.tqic;
  }

  public get asinImpressionCount(): MetricData {
    return this.dto.aic;
  }

  public get asinImpressionShare(): MetricData {
    return this.dto.ais;
  }

  public get totalClickCount(): MetricData {
    return this.dto.tcc;
  }

  public get totalClickRate(): MetricData {
    return this.dto.tcr;
  }

  public get asinClickCount(): MetricData {
    return this.dto.acc;
  }

  public get asinClickShare(): MetricData {
    return this.dto.acs;
  }

  public get totalMedianClickPrice(): MetricData {
    return this.dto.tmcp;
  }

  public get asinMedianClickPrice(): MetricData {
    return this.dto.amcp;
  }

  public get totalSameDayShippingClickCount(): MetricData {
    return this.dto.tsdsc;
  }

  public get totalOneDayShippingClickCount(): MetricData {
    return this.dto.todsc;
  }

  public get totalTwoDayShippingClickCount(): MetricData {
    return this.dto.ttdsc;
  }

  public get totalCartAddCount(): MetricData {
    return this.dto.tcac;
  }

  public get totalCartAddRate(): MetricData {
    return this.dto.tcar;
  }

  public get asinCartAddCount(): MetricData {
    return this.dto.acac;
  }

  public get asinCartAddShare(): MetricData {
    return this.dto.acas;
  }

  public get totalMedianCartAddPrice(): MetricData {
    return this.dto.tmcap;
  }

  public get asinMedianCartAddPrice(): MetricData {
    return this.dto.amcap;
  }

  public get totalSameDayShippingCartAddCount(): MetricData {
    return this.dto.tsdscac;
  }

  public get totalOneDayShippingCartAddCount(): MetricData {
    return this.dto.todscac;
  }

  public get totalTwoDayShippingCartAddCount(): MetricData {
    return this.dto.ttdscac;
  }

  public get totalPurchaseCount(): MetricData {
    return this.dto.tpc;
  }

  public get totalPurchaseRate(): MetricData {
    return this.dto.tpr;
  }

  public get asinPurchaseCount(): MetricData {
    return this.dto.apc;
  }

  public get asinPurchaseShare(): MetricData {
    return this.dto.aps;
  }

  public get totalMedianPurchasePrice(): MetricData {
    return this.dto.tmpp;
  }

  public get asinMedianPurchasePrice(): MetricData {
    return this.dto.ampp;
  }

  public get totalSameDayShippingPurchaseCount(): MetricData {
    return this.dto.tsdspc;
  }

  public get totalOneDayShippingPurchaseCount(): MetricData {
    return this.dto.todspc;
  }

  public get totalTwoDayShippingPurchaseCount(): MetricData {
    return this.dto.ttdspc;
  }

  public get totalCTR(): MetricData {
    return this.dto.tctr;
  }

  public get asinCTR(): MetricData {
    return this.dto.actr;
  }

  public get asinConversionRate(): MetricData {
    return this.dto.acor;
  }

  public get totalConversionRate(): MetricData {
    return this.dto.tcor;
  }

  public get productDataItemIds(): number[] {
    return this.dto.dg ?? [];
  }

  public static fromResponse(dto: SearchQueryPerformanceDTO): SearchQueryPerformanceModel {
    return new SearchQueryPerformanceModel(dto);
  }

  public static fromResponseArray(dtos: SearchQueryPerformanceDTO[] | null): SearchQueryPerformanceModel[] {
    return dtos ? dtos.map(SearchQueryPerformanceModel.fromResponse) : [];
  }
}
