import { useState, FunctionComponent, PropsWithChildren } from 'react';
import { ExternalContext, ExternalContextType } from './ExternalContext';

export const ExternalProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [dashboardShareToken, setDashboardShareToken] = useState<string | null>(null);

  const contextValue: ExternalContextType = {
    dashboardShareToken,
    setDashboardShareToken,
  };

  return <ExternalContext.Provider value={contextValue}>{children}</ExternalContext.Provider>;
};
