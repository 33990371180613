import InfoMessage from '@/components/feedback/InfoMessage';
import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import AlSelect from '@/components/form/AlSelect';
import BudgetEditRow from '@/components/form/BudgetEditRow';
import { useDeleteConfirmation } from '@/components/modals/delete-confirmation-modal/useDeleteConfirmationModal';
import PopoverLikePopper from '@/components/PopoverLikePopper';
import { useTranslation } from '@/lib';
import useBudgetLimits from '@/modules/amazon-constants/hooks/useBudgetLimits';
import { BudgetUpdateType } from '@/modules/amazon-constants/types/BudgetUpdateType';
import { UpdateResponseDTO, UpdateResponseModal } from '@/modules/application/components/UpdateResponseModal';
import { FlowType } from '@/modules/log-viewing/api/logs-contracts';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { toastService } from '@/services/toast.service';
import { Button, DialogActions, DialogContent, DialogTitle, Divider, SelectChangeEvent } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { isEmpty, isNil } from 'lodash-es';
import { ChangeEvent, FunctionComponent, RefObject, useState } from 'react';
import { AmazonBrandsBidOptimization, BidStrategyType, CampaignUpdateDTO } from '../api/campaign/campaign-contracts';
import { campaignService, createCampaignsWithTimelineQueryKey } from '../api/campaign/campaign-service';
import { SelectedCampaignDTO } from '../api/campaign/models/CampaignModel';
import useCampaignWarnings from '../hooks/useCampaignWarnings';
import { EnabledPausedArchivedState, STATE_OPTIONS } from '../types/EnabledPausedArchivedState';
import { NO_CHANGE, NoChange } from '../types/no_change';

const ARCHIVE_COUNT_TO_SHOW_WARNING = 1;

interface CampaignBulkActionsPopoverProps {
  buttonRef: RefObject<HTMLButtonElement>;
  selectedCampaigns: SelectedCampaignDTO[];
  isOpen: boolean;
  onClose: () => void;
  filters: AlFilterModel[];
}

const CampaignBulkEditPopover: FunctionComponent<CampaignBulkActionsPopoverProps> = ({
  selectedCampaigns,
  isOpen,
  onClose,
  buttonRef,
  filters,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { activeProfile } = useActiveTeamContext();

  const { getNewBudgetAmountByUpdateType, getClampedBudgetAmountWithWarnings } = useBudgetLimits();
  const campaignsWithTimelineQueryKey = createCampaignsWithTimelineQueryKey(activeProfile?.id, filters);

  // Post update modal
  const [updateResponseModalOpen, setUpdateResponseModalOpen] = useState(false);
  const [updatedCampaignsResponse, setUpdateCampaignsResponse] = useState<UpdateResponseDTO>();

  const BID_STRATEGY_TYPE_OPTIONS = [
    { value: NO_CHANGE, label: 'No change' },
    { value: BidStrategyType.LEGACY_FOR_SALES, label: t(`enums.bid_strategy.${BidStrategyType.LEGACY_FOR_SALES}`) },
    { value: BidStrategyType.AUTO_FOR_SALES, label: t(`enums.bid_strategy.${BidStrategyType.AUTO_FOR_SALES}`) },
    { value: BidStrategyType.MANUAL, label: t(`enums.bid_strategy.${BidStrategyType.MANUAL}`) },
    //{ value: BidStrategyType.RULE_BASED, label: t(`enums.bid_strategy.${BidStrategyType.RULE_BASED}`) },
  ];

  const BID_OPTIMIZATION = [
    { value: NO_CHANGE, label: 'No change' },
    { value: AmazonBrandsBidOptimization.AUTO, label: 'Auto' },
    { value: AmazonBrandsBidOptimization.MANUAL, label: 'Manual' },
  ];

  const [campaignState, setCampaignState] = useState<EnabledPausedArchivedState | NoChange>(NO_CHANGE);
  const [campaignBiddingStrategy, setCampaignBiddingStrategy] = useState<BidStrategyType | NoChange>(NO_CHANGE);
  const [bidOptimization, setBidOptimization] = useState<AmazonBrandsBidOptimization | NoChange>(NO_CHANGE);
  const [budgetUpdateType, setBudgetUpdateType] = useState<BudgetUpdateType>(BudgetUpdateType.NO_CHANGE);
  const [budgetUpdateValue, setBudgetUpdateValue] = useState<string>(''); // can be budget amount or budget percentage
  const [isApplyInProgress, setIsApplyInProgress] = useState<boolean>(false);

  function hasCampaignEnded(campaign: SelectedCampaignDTO) {
    return !isNil(campaign.endDate) && new Date(campaign.endDate) < new Date();
  }

  const [pendingCampaignUpdates, setPendingCampaignUpdates] = useState<CampaignUpdateDTO[]>([]);

  const { ModalComponent: archivingManyCampaignsModalComponent, handleOpenModal: handleArchivingManyCampaignsModalHandleOpen } =
    useDeleteConfirmation({
      questionText: `We want to double confirm, are you sure you want to archive ${pendingCampaignUpdates.length} ${pendingCampaignUpdates.length == 1 ? 'campaign' : 'campaigns'}? This action cannot be undone.`,
      headerText: 'Archiving Campaigns',
      onDelete: () => {
        applyCampaignUpdates(pendingCampaignUpdates);
      },
      confirmButtonText: `Confirm Archiving ${pendingCampaignUpdates.length} Campaigns`,
    });

  function handleApplyChanges() {
    if (isNil(activeProfile)) {
      toastService.error('Active profile not set');
      return;
    }

    // Basic validation for budget
    if (budgetUpdateType !== BudgetUpdateType.NO_CHANGE) {
      if (isNil(budgetUpdateValue) || isEmpty(budgetUpdateValue)) {
        toastService.error('Budget value is required when updating Budget');
        return;
      } else if (parseFloat(budgetUpdateValue) <= 0) {
        toastService.error('Budget value must be greater than zero');
        return;
      }
    }

    setIsApplyInProgress(true);

    const filteredSelectedCampaigns = selectedCampaigns.filter(
      (campaign) => campaign.state !== EnabledPausedArchivedState.ARCHIVED && !hasCampaignEnded(campaign),
    );

    const budgetWarnings = new Set<string>();
    const campaignUpdates: CampaignUpdateDTO[] = filteredSelectedCampaigns.map((campaign) => {
      const update: CampaignUpdateDTO = {
        campaign_id: campaign.id,
      };

      if (campaignState !== NO_CHANGE) {
        update.state = campaignState as EnabledPausedArchivedState;
      }

      if (campaignBiddingStrategy !== NO_CHANGE) {
        update.dynamic_bid_strategy = campaignBiddingStrategy as BidStrategyType;
      }

      if (bidOptimization !== NO_CHANGE) {
        update.bid_optimization = bidOptimization as AmazonBrandsBidOptimization;
      }

      if (budgetUpdateType !== BudgetUpdateType.NO_CHANGE) {
        const budgetUpdateNumericValue = parseFloat(budgetUpdateValue);
        const newBudgetAmount = getNewBudgetAmountByUpdateType(budgetUpdateNumericValue, budgetUpdateType, campaign.budgetAmount);
        update.budget_amount = getClampedBudgetAmountWithWarnings(newBudgetAmount, campaign.adType, activeProfile.type, budgetWarnings);
      }

      return update as CampaignUpdateDTO;
    });

    if (budgetWarnings.size > 0) {
      const budgetWarningList = Array.from(budgetWarnings);
      budgetWarningList.forEach((w) =>
        toastService.warn(w, {
          autoCloseDelay: 5000,
        }),
      );
    }

    if (campaignState === EnabledPausedArchivedState.ARCHIVED && campaignUpdates.length >= ARCHIVE_COUNT_TO_SHOW_WARNING) {
      setPendingCampaignUpdates(campaignUpdates);
      handleArchivingManyCampaignsModalHandleOpen();
    } else {
      applyCampaignUpdates(campaignUpdates);
    }
    onClose();
    setIsApplyInProgress(false);
  }

  async function applyCampaignUpdates(campaignUpdates: CampaignUpdateDTO[]) {
    const response = await campaignService.updateCampaigns(campaignUpdates, FlowType.CAMPAIGN_OPTIMIZER);

    if (response.isSuccess) {
      setUpdateCampaignsResponse({ responseErrorMsg: null, payload: [response?.payload] });
    } else {
      setIsApplyInProgress(false);
      setUpdateCampaignsResponse({ responseErrorMsg: `Did not receive a response from server: ${response.message}`, payload: undefined });
    }
    setUpdateResponseModalOpen(true);

    // Reset all fields
    setCampaignState(NO_CHANGE);
    setCampaignBiddingStrategy(NO_CHANGE);

    queryClient.invalidateQueries({
      queryKey: campaignsWithTimelineQueryKey,
    });
    onClose();
    setIsApplyInProgress(false);
  }

  function handleCampaignStateChange(event: SelectChangeEvent<EnabledPausedArchivedState | NoChange>) {
    const value = event.target.value;

    if (value === NO_CHANGE) {
      setCampaignState(value);
    } else {
      setCampaignState(value as EnabledPausedArchivedState);
    }
  }

  function onBidStrategyTypeChange(event: SelectChangeEvent<BidStrategyType | NoChange>) {
    const value = event.target.value;

    if (value === NO_CHANGE) {
      setCampaignBiddingStrategy(value);
    } else {
      setCampaignBiddingStrategy(value as BidStrategyType);
    }
  }

  function handleBidOptimizationChange(event: SelectChangeEvent<AmazonBrandsBidOptimization | NoChange>) {
    const value = event.target.value;

    if (value === NO_CHANGE) {
      setBidOptimization(value);
    } else {
      setBidOptimization(value as AmazonBrandsBidOptimization);
    }
  }

  const {
    warnings,
    productCampaignsCount,
    brandCampaignsCount,
    endedArchivedCampaignsCount: selectedEndedArchivedCampaignsCount,
  } = useCampaignWarnings({
    selectedCampaigns,
    campaignState,
    campaignBiddingStrategy,
    bidOptimization,
  });

  function handleBudgetUpdateTypeChange(event: SelectChangeEvent<BudgetUpdateType>) {
    setBudgetUpdateType(event.target.value as BudgetUpdateType);
  }

  const handleBudgetUpdateValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    // Allow only numeric input with decimals
    // TODO: use function isValidNumberInput, also elsewhere
    if (!value || /^\d*\.?\d*$/.test(value)) {
      setBudgetUpdateValue(value);
    }
  };

  return (
    <>
      <PopoverLikePopper open={isOpen} anchorEl={buttonRef.current} onClose={onClose}>
        <DialogTitle id="edit-selection-modal-title">
          Edit {selectedCampaigns.length} {selectedCampaigns.length == 1 ? 'Campaign' : 'Campaigns'}
        </DialogTitle>

        <DialogContent className="flex min-w-[500px] flex-col gap-y-6">
          <AlSelect
            label={'Campaign State'}
            value={campaignState}
            options={STATE_OPTIONS}
            disabled={selectedCampaigns.length == selectedEndedArchivedCampaignsCount}
            onChange={handleCampaignStateChange}
            renderOption={(item) => item.label}
            valueExtractor={(item) => item.value}
          />
          <AlSelect
            label={'(SP) Campaign Bidding Strategy'}
            value={campaignBiddingStrategy}
            options={BID_STRATEGY_TYPE_OPTIONS}
            onChange={onBidStrategyTypeChange}
            renderOption={(item) => item.label}
            valueExtractor={(item) => item.value}
            helperWarningText={
              campaignBiddingStrategy === BidStrategyType.AUTO_FOR_SALES
                ? 'AdLabs works best with Fixed Bids or Dynamic Down only'
                : undefined
            }
            disabled={productCampaignsCount == 0 || selectedCampaigns.length == selectedEndedArchivedCampaignsCount} // bid strategy is only for sponsored products
          />
          <AlSelect
            label={'(SB) Amazon Bid Optimization'}
            value={bidOptimization}
            options={BID_OPTIMIZATION}
            onChange={handleBidOptimizationChange}
            renderOption={(item) => item.label}
            valueExtractor={(item) => item.value}
            disabled={brandCampaignsCount == 0 || selectedCampaigns.length == selectedEndedArchivedCampaignsCount}
            helperWarningText={
              bidOptimization === AmazonBrandsBidOptimization.AUTO ? 'Cannot modify placements if bid optimization turned on' : undefined
            }
          />

          <BudgetEditRow
            title={'Campaign Budget'}
            budgetUpdateType={budgetUpdateType}
            handleBudgetUpdateTypeChange={handleBudgetUpdateTypeChange}
            budgetUpdateValue={budgetUpdateValue}
            handleBudgetUpdateValueChange={handleBudgetUpdateValueChange}
            isBudgetEditAllowed={selectedCampaigns.length > selectedEndedArchivedCampaignsCount}
          />
        </DialogContent>

        <Divider />
        {warnings.length > 0 && (
          <div className="mx-5 mt-2 mb-3 flex flex-col">
            {warnings.map((warning, index) => (
              <InfoMessage key={index} content={warning} />
            ))}
          </div>
        )}

        <DialogActions>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            loading={isApplyInProgress}
            onClick={handleApplyChanges}
            disabled={
              selectedCampaigns.length == selectedEndedArchivedCampaignsCount ||
              (campaignState === NO_CHANGE &&
                campaignBiddingStrategy === NO_CHANGE &&
                bidOptimization === NO_CHANGE &&
                budgetUpdateType === BudgetUpdateType.NO_CHANGE)
            }
          >
            Apply Changes
          </Button>
        </DialogActions>
      </PopoverLikePopper>

      <UpdateResponseModal
        isOpen={updateResponseModalOpen}
        onClose={() => setUpdateResponseModalOpen(false)}
        updateResponse={updatedCampaignsResponse}
      />

      {archivingManyCampaignsModalComponent}
    </>
  );
};

export default CampaignBulkEditPopover;
