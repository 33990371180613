import { cloneDeep, isEqual } from 'lodash-es';
import { useMemo } from 'react';
import { AlDate } from '../../../lib/date/AlDate';
import {
  AlFilterModel,
  ComparisonDateFilterModel,
  DateAndComparisonDateFilter,
  DateFilterModel,
  LogicalOperatorType,
  OperatorType,
  getUpdatedFiltersValue,
} from '../models/AlFilterModel';
import { FilterErrorCode } from '../types/FilterErrorCodes';
import { FilterKey } from '../types/FilterKey';
import { StartEndDatePair } from '../types/StartEndDatePair';

interface UseFiltersProps {
  filters: AlFilterModel[];
  setFilters: (newFilters: AlFilterModel[] | ((prevFilters: AlFilterModel[]) => AlFilterModel[])) => void;
}

const useFilters = ({ filters, setFilters }: UseFiltersProps) => {
  const { dates, comparisonDates } = useMemo(() => {
    let localDates: AlDate[] = [];
    let localComparisonDates: AlDate[] = [];

    filters.forEach((filterModel) => {
      if (filterModel instanceof DateFilterModel) {
        localDates = extractDatesFromFilterModel(filterModel);
      } else if (filterModel instanceof ComparisonDateFilterModel) {
        localComparisonDates = extractDatesFromFilterModel(filterModel);
      }
    });

    return { dates: localDates, comparisonDates: localComparisonDates };
  }, [filters]);

  const setFilterValue = (filter: AlFilterModel) => {
    const newFilters = getUpdatedFiltersValue(filters, filter);
    setFilterValues(newFilters);
  };

  const setFilterValues = (newFilters: AlFilterModel[]) => {
    if (!newFilters || newFilters.length === 0) return;

    // TODO: ChatGPT review: getUpdatedFiltersValue(acc, newFilter) already clones and returns a new array with the newFilter merged.
    // Here, you're calling getUpdatedFiltersValue() multiple times in a reduce, which
    // will duplicate merge logic and produce unexpected filter sets (especially if filters with same keys are added).
    const updatedFilters = newFilters.reduce((acc, newFilter) => {
      return getUpdatedFiltersValue(acc, newFilter);
    }, cloneDeep(filters));

    if (isEqual(filters, updatedFilters)) return;

    setFilters(updatedFilters);
  };

  function onSetDates(dates: AlDate[], comparisonDates: AlDate[]) {
    const newFilters: AlFilterModel[] = [
      new DateFilterModel({
        logicalOperator: LogicalOperatorType.AND,
        conditions: [
          {
            values: [dates[0].toDefaultFormat()],
            operator: OperatorType.GREATER_THAN_OR_EQUAL,
          },
          {
            values: [dates[1].toDefaultFormat()],
            operator: OperatorType.LESS_THAN_OR_EQUAL,
          },
        ],
      }),
      new ComparisonDateFilterModel({
        logicalOperator: LogicalOperatorType.AND,
        conditions: [
          {
            values: [comparisonDates[0].toDefaultFormat()],
            operator: OperatorType.GREATER_THAN_OR_EQUAL,
          },
          {
            values: [comparisonDates[1].toDefaultFormat()],
            operator: OperatorType.LESS_THAN_OR_EQUAL,
          },
        ],
      }),
    ];

    setFilterValues(newFilters);
  }

  return { dates, comparisonDates, setFilterValues, onSetDates, setFilterValue };
};

export default useFilters;

function extractDatesFromFilterModel(filterModel: AlFilterModel): AlDate[] {
  if (filterModel instanceof DateFilterModel || filterModel instanceof ComparisonDateFilterModel) {
    if (filterModel.conditions) {
      return filterModel.conditions.map((condition) => {
        const parsed = AlDate.parse(condition.values[0].toString());
        if (!parsed.isValid()) {
          console.error('Date is not valid', condition.values[0].toString(), new Error().stack);
          return AlDate.now();
        }
        return parsed;
      });
    }
  }

  return [];
}

export function uniqueFiltersByKeys(filters: AlFilterModel[]): AlFilterModel[] {
  const uniqueFilters = new Map<string, AlFilterModel>();

  filters.forEach((filter) => {
    uniqueFilters.set(filter.key, filter); // If a key is duplicated, it will overwrite the previous one
  });

  return Array.from(uniqueFilters.values()); // Convert to an array of FilterModel
}

export function getStartEndDateFromFilters(filters: AlFilterModel[]): StartEndDatePair | undefined {
  const dateFilter = filters.find((f) => f.key === FilterKey.DATE);
  const startDate = dateFilter?.conditions?.[0]?.values?.[0] as string | undefined;
  const endDate = dateFilter?.conditions?.[1]?.values?.[0] as string | undefined;

  return startDate && endDate ? { startDate, endDate } : undefined;
}

export function containsDateAndComparisonDateFilter(filters: AlFilterModel[]): boolean {
  return filters.some((filter) => filter instanceof DateFilterModel) && filters.some((filter) => filter instanceof ComparisonDateFilterModel);
}

// TODO: add field to filter isDateRangeFilter
export function extractDateFiltersFromFilters(filters: AlFilterModel[]): DateAndComparisonDateFilter {
  return filters.filter((f) => f instanceof DateFilterModel || f instanceof ComparisonDateFilterModel);
}

export function removeDateRangeFilters(filters: AlFilterModel[]): AlFilterModel[] {
  return filters.filter((f) => !(f instanceof DateFilterModel || f instanceof ComparisonDateFilterModel));
}

export function splitFiltersToApplicableAndDiscarded(filters: AlFilterModel[]): {
  applicableFilters: AlFilterModel[];
  discardedFilters: AlFilterModel[];
} {
  const applicableFilters: AlFilterModel[] = [];
  const discardedFilters: AlFilterModel[] = [];

  for (const filter of filters) {
    if (filter.isValid()) {
      applicableFilters.push(filter);
    } else {
      discardedFilters.push(filter);
    }
  }

  return { applicableFilters, discardedFilters };
}

export function getFilterErrorCodes(filters: AlFilterModel[]): Record<FilterKey, Set<FilterErrorCode>> {
  const filterRecord: Record<FilterKey, Set<FilterErrorCode>> = filters.reduce(
    (acc, filter) => {
      const errorCodes = filter.getErrorCodes();
      if (errorCodes.size > 0) {
        acc[filter.key] = errorCodes;
      }
      return acc;
    },
    {} as Record<FilterKey, Set<FilterErrorCode>>,
  );

  return filterRecord;
}
