import { CampaignAdType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { EnabledPausedArchivedState } from '@/modules/optimizer/types/EnabledPausedArchivedState';
import { TargetEntityType } from '@/modules/targeting/api/targets-contracts';
import { DTO } from '@/types/dto-wrapper';
import { NegativeMatchType, NegativeTargetsAllDTO, NegativeTargetsDTO } from '../api/negative-targets-contracts';

export class NegativeTargetModel extends DTO<NegativeTargetsDTO> {
  constructor(data: NegativeTargetsDTO) {
    super(data);
  }

  public get id(): string {
    return this.dto.id;
  }

  // Not an enum, but regular text
  public get targeting(): string {
    return this.dto.targeting;
  }

  public get targetingType(): string {
    return this.dto.targeting_type;
  }

  public get entityType(): TargetEntityType {
    return this.dto.match_type === NegativeMatchType.CAMPAIGN_NEGATIVE_PRODUCT_TARGET ||
      this.dto.match_type === NegativeMatchType.AD_GROUP_NEGATIVE_PRODUCT_TARGET
      ? TargetEntityType.PRODUCT_TARGET
      : TargetEntityType.KEYWORD;
  }

  public get negativeMatchType(): NegativeMatchType {
    return this.dto.match_type;
  }

  public get targetState(): EnabledPausedArchivedState {
    return this.dto.target_state;
  }

  public get campaignName(): string {
    return this.dto.campaign_name;
  }

  public get campaignAdType(): CampaignAdType {
    return this.dto.campaign_ad_type;
  }

  public get campaignState(): EnabledPausedArchivedState {
    return this.dto.campaign_state;
  }

  public get adGroupName(): string {
    return this.dto.ad_group_name;
  }

  public get reportedAt(): string {
    return this.dto.reported_at;
  }

  public get createdAt(): string {
    return this.dto.created_at;
  }

  public static fromResponse(dto: NegativeTargetsDTO): NegativeTargetModel {
    return new NegativeTargetModel(dto);
  }

  public static fromResponseArray(dtos: NegativeTargetsDTO[] | null): NegativeTargetModel[] {
    return dtos ? dtos.map(NegativeTargetModel.fromResponse) : [];
  }
}

export class NegativeTargetsStatsModel {
  totalKeywords: number;
  totalTargets: number;
  sentKeywords: number;
  sentTargets: number;

  constructor(dto: NegativeTargetsAllDTO) {
    this.totalKeywords = dto.total_keywords;
    this.totalTargets = dto.total_targets;
    this.sentKeywords = dto.sent_keywords;
    this.sentTargets = dto.sent_targets;
  }

  public static fromResponse(dto: NegativeTargetsAllDTO): NegativeTargetsStatsModel {
    return new NegativeTargetsStatsModel(dto);
  }
}

export interface SelectedNegativeTarget {
  id: string;
  adType: CampaignAdType;
  entityType: TargetEntityType;
  isCampaign: boolean;
  targeting: string;
  adGroupName: string;
  campaignName: string;
  state: EnabledPausedArchivedState;
}

export interface NegativeTargetingResponse {
  negativeTargeting: NegativeTargetModel[];
  stats: NegativeTargetsStatsModel;
}
