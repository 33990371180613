import * as Sentry from '@sentry/react';
import { isEmpty } from 'lodash-es';

// https://advertising.amazon.com/API/docs/en-us/guides/account-management/authorization/profiles#profile-types
export enum ProfileType {
  VENDOR = 'vendor',
  SELLER = 'seller',
  AGENCY = 'agency',
}

export function getProfileType(type: string): ProfileType {
  switch (type.toLowerCase()) {
    case 'vendor':
      return ProfileType.VENDOR;
    case 'seller':
      return ProfileType.SELLER;
    case 'agency':
      return ProfileType.AGENCY;

    default:
      console.error(`Invalid profile type: ${isEmpty(type) ? '<empty>' : type}`);
      Sentry.captureMessage(`Invalid profile type: ${isEmpty(type) ? '<empty>' : type}`, 'error');
      return ProfileType.SELLER;
  }
}
