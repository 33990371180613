import { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography, Box } from '@mui/material';
import { DashboardLinkCreateDTO } from '../../api/dashboard-links/dashboard-link.contracts';
import { dashboardLinkService } from '../../api/dashboard-links/dashboard-link.service';
import { ContentCopyOutlined, RefreshOutlined } from '@mui/icons-material';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { DashboardLinkModel } from '../../types/DashboardLinkModel';
import { toastService } from '@/services/toast.service';
import LoadingBlock from '@/components/feedback/LoadingBlock';

interface DashboardShareModalProps {
  open: boolean;
  onClose: () => void;
  dashboardId: number;
}

export const DashboardShareModal = ({ open, onClose, dashboardId }: DashboardShareModalProps) => {
  const [loading, setLoading] = useState(false);
  const [sharedLink, setSharedLink] = useState<DashboardLinkModel | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { activeTeam } = useActiveTeamContext();

  useEffect(() => {
    if (open) {
      fetchExistingLink();
    }
  }, [open]);

  const fetchExistingLink = async () => {
    setLoading(true);
    setError(null);

    const response = await dashboardLinkService.getByDashboardId(dashboardId);
    if (response.isSuccess && response.payload.length > 0) {
      const link = response.payload[0];
      setSharedLink(link);
    } else {
      setSharedLink(null);
    }

    setLoading(false);
  };

  const onCreateLinkClicked = () => {
    createLink();
  };

  const onCopyUrlClicked = async () => {
    if (sharedLink) {
      try {
        await navigator.clipboard.writeText(sharedLink.shareableUrl);
        toastService.success('Copied to clipboard');
      } catch {
        toastService.error('Failed to copy link to clipboard');
      }
    }
  };

  const onDeleteLinkClicked = () => {
    deleteLink();
  };

  const onRegenerateLinkClicked = async () => {
    if (!sharedLink) {
      return;
    }

    setLoading(true);
    setError(null);

    // Delete existing first, then create new one
    const result = await deleteLink();

    if (result) {
      await createLink();
    }
  };

  const createLink = async () => {
    if (!activeTeam) {
      return;
    }

    setLoading(true);
    setError(null);

    const request: DashboardLinkCreateDTO = {
      dashboard_id: dashboardId,
      team_id: activeTeam?.id,
    };

    const response = await dashboardLinkService.create(request);
    if (response.isSuccess) {
      setSharedLink(response.payload);
    } else {
      setError('Failed to create link');
    }

    setLoading(false);
  };

  const deleteLink = async () => {
    if (!sharedLink) {
      return false;
    }

    const response = await dashboardLinkService.delete(sharedLink.id);
    if (response.isSuccess) {
      setSharedLink(null);
    } else {
      toastService.error('Failed to delete link');
      return false;
    }

    return true;
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Share Dashboard</DialogTitle>
      <DialogContent>
        {loading ? (
          <div className="flex h-24">
            <LoadingBlock />
          </div>
        ) : sharedLink ? (
          <>
            <Typography variant="body1" sx={{ mb: 4 }}>
              Share this link:
            </Typography>
            <div className="flex items-center gap-x-2 mb-3">
              <TextField fullWidth value={sharedLink.shareableUrl} slotProps={{ input: { readOnly: true } }} className="m-0" />

              <Button onClick={onCopyUrlClicked} variant="contained">
                <ContentCopyOutlined />
              </Button>
            </div>
            <Box display="flex" justifyContent="space-between">
              <Button variant="text" color="error" onClick={onDeleteLinkClicked}>
                Stop Sharing
              </Button>
              <Button variant="text" onClick={onRegenerateLinkClicked}>
                <RefreshOutlined className="mr-1" />
                Regenerate Link
              </Button>
            </Box>
          </>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center" my={2}>
            <Typography variant="body1" sx={{ mb: 8 }}>
              No link exists yet, generate one:
            </Typography>
            <Button variant="contained" onClick={onCreateLinkClicked} loading={loading}>
              Generate Link
            </Button>
          </Box>
        )}
        {error && <Typography color="error">{error}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
