import AlSelect from '@/components/form/AlSelect';
import { SelectChangeEvent, Switch, TextField } from '@mui/material';
import { cloneDeep } from 'lodash-es';
import { ChangeEvent, FunctionComponent, useContext, useEffect, useState } from 'react';
import { DashboardWidgetContext } from '../../dashboard-widget/DashboardWidgetContextProvider';
import { HorizontalDividerWidgetConfiguration, HorizontalDividerWidgetFontSize } from './HorizontalDividerWidgetConfiguration';

interface HorizontalDividerWidgetConfigurationFormProps {
  configuration: HorizontalDividerWidgetConfiguration;
  id: string;
}

const HorizontalDividerWidgetConfigurationForm: FunctionComponent<HorizontalDividerWidgetConfigurationFormProps> = ({ configuration }) => {
  const { setModifiedConfiguration } = useContext(DashboardWidgetContext);

  const [localConfiguration, setLocalConfiguration] = useState<HorizontalDividerWidgetConfiguration>(cloneDeep(configuration));

  useEffect(() => {
    localConfiguration.isConfigured = true;
    setModifiedConfiguration(cloneDeep(localConfiguration));
  }, [localConfiguration, setModifiedConfiguration]);

  const onTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLocalConfiguration((prev) => {
      if (!e.currentTarget) return prev;
      const newConfig = cloneDeep(prev);
      newConfig.title = e.currentTarget?.value || '';
      return newConfig;
    });
  };

  const onFontSizeChange = (event: SelectChangeEvent<HorizontalDividerWidgetFontSize>) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.fontSize = event.target.value as HorizontalDividerWidgetFontSize;
      return newConfig;
    });
  };

  const fontSizeOptions: { value: HorizontalDividerWidgetFontSize; label: string }[] = [
    { value: 'small', label: 'Small' },
    { value: 'medium', label: 'Medium' },
    { value: 'large', label: 'Large' },
    { value: 'xlarge', label: 'Extra Large' },
  ];

  const onFontIsBoldToggled = (e: ChangeEvent<HTMLInputElement>) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.fontIsBold = e.currentTarget.checked;
      return newConfig;
    });
  };

  const lineThicknessOptions: { value: number; label: string }[] = [
    { value: 1, label: 'Thin' },
    { value: 2, label: 'Medium' },
    { value: 4, label: 'Thick' },
  ];

  const onLineThicknessChange = (event: SelectChangeEvent<number>) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.lineThickness = event.target.value as number;
      return newConfig;
    });
  };

  return (
    <>
      <TextField label="Title" value={localConfiguration.title} onChange={onTitleChange} fullWidth />
      <AlSelect
        label="Font Size"
        value={localConfiguration.fontSize}
        valueExtractor={(option) => option.value}
        renderOption={(option) => option.label}
        options={fontSizeOptions}
        onChange={onFontSizeChange}
      />

      <div className="flex flex-col">
        <label htmlFor="fontIsBold" className="text-sm">
          Bold Text
        </label>
        <Switch
          checked={localConfiguration.fontIsBold}
          onChange={onFontIsBoldToggled}
          name="fontIsBold"
          slotProps={{ input: { 'aria-label': 'fontIsBold' } }}
        />
      </div>

      <AlSelect
        label="Line Thickness"
        value={localConfiguration.lineThickness}
        valueExtractor={(option) => option.value}
        renderOption={(option) => option.label}
        options={lineThicknessOptions}
        onChange={onLineThicknessChange}
      />
    </>
  );
};

export default HorizontalDividerWidgetConfigurationForm;
