import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';
import { filtersService } from '@/components/filter-builder/api/filters-service';
import { AlFilterModel, getDefaultCampaignMappingFilters } from '@/components/filter-builder/models/AlFilterModel';
import useAlFetchCache from '@/modules/al-fetch-cache/useAlFetchCache';
import useBidLimits from '@/modules/amazon-constants/hooks/useBidLimits';
import CampaignMappingFilterBar from '@/modules/campaign-mapping/components/CampaignMappingFilterBar';
import { CampaignMappingProvider } from '@/modules/campaign-mapping/contexts/CampaignMappingContext';
import useCampaignMapping from '@/modules/campaign-mapping/hooks/useCampaignMapping';
import useCampaignToAdGroupsMappingData from '@/modules/campaign-mapping/hooks/useCampaignToAdGroupsMappingData';
import { CampaignMappingModelWithValidation } from '@/modules/campaign-mapping/models/CampaignMappingModelWithValidation';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { toastService } from '@/services/toast.service';
import { NegativesBools } from '@/types/boolean.types';
import { ContextKey } from '@/types/context-shared';
import type { GridApi, GridReadyEvent, IRowNode } from 'ag-grid-community';
import { isEmpty, isNil } from 'lodash-es';
import { FunctionComponent, useMemo, useRef, useState } from 'react';
import { CampaignMappingImportDetails, MAX_ERROR_WARNING_SAMPLES } from '../../types/feedback';
import CampaignMappingImportPreviewActionBar from './CampaignMappingImportPreviewActionBar';
import CampaignMappingImportPreviewTable from './CampaignMappingImportPreviewTable';
import { CampaignMappingImportRow, FieldNames } from './CampaignMappingImportRow';

interface CampaignMappingPreviewProps {
  uploadedFile: File;
  uploadedFileParsed: CampaignMappingImportRow[];
  onCloseModal: () => void;
}

export const CampaignMappingPreview: FunctionComponent<CampaignMappingPreviewProps> = ({ uploadedFileParsed, onCloseModal }) => {
  const { activeProfile } = useActiveTeamContext();
  const { fetchCache } = useAlFetchCache();

  const { campaignToAdGroupsMap, getCampaignNameToCampaignIdMap } = useCampaignToAdGroupsMappingData();

  const { existingMappingKeys } = useCampaignMapping();

  const [filters, setFilters] = useState<AlFilterModel[]>(() => {
    // Setting via function to avoid unnecessary loading on re-render
    return filtersService.loadProfileFilters(
      ContextKey.CAMPAIGN_MAPPING,
      activeProfile?.id ?? '',
      getDefaultCampaignMappingFilters(),
      fetchCache,
    );
  });

  // GRID
  const { getClampedBidWithWarnings } = useBidLimits();

  const campaignMappingTableGridApiRef = useRef<GridApi<CampaignMappingModelWithValidation> | null>(null);

  const [importDetails, setImportDetails] = useState<CampaignMappingImportDetails>({
    rowCount: 0,
    errorCount: 0,
    warningCount: 0,
    errorMessageSamples: [],
    warningSamples: [],
    firstErrorRowId: null,
    firstWarningRowId: null,
  });

  // rowData is memoed, but something can be fetched again after a long time, don't show dupe removal message again
  const isNotifiedAboutDuplicatesRef = useRef(false);

  function importRowsToModels(importedRows: CampaignMappingImportRow[]) {
    const campaignNameToCampaignIdsMap = getCampaignNameToCampaignIdMap();
    const models = [];
    const uniqueRows = new Set();
    let duplicateCount = 0;

    const sdIdToNegatives: Record<string, NegativesBools[]> = {};
    for (const [index, importRow] of importedRows.entries()) {
      // Use a unique identifier for each row to check for duplicates
      const uniqueIdentifier =
        importRow[FieldNames.SourceCampaign] +
        importRow[FieldNames.SourceAdGroup] +
        importRow[FieldNames.DestinationCampaign] +
        importRow[FieldNames.DestinationAdGroup] +
        importRow[FieldNames.Exact] +
        importRow[FieldNames.Phrase] +
        importRow[FieldNames.Broad];

      if (uniqueRows.has(uniqueIdentifier)) {
        // Don't count empty
        if (importRow[FieldNames.SourceCampaign] !== '') {
          duplicateCount++;
        }
        continue; // Skip processing this row if it's a duplicate
      }

      uniqueRows.add(uniqueIdentifier);

      const cmm = new CampaignMappingModelWithValidation({
        rowId: index.toString(),
        importRow,
        campaignNameToCampaignIdsMap,
        campaignToAdGroupsMap,
        getClampedBidWithWarnings,
        existingMappingKeys,
      });

      // Store all negatives values so and apply them to all instances of the same source+destination
      if (
        [cmm.sourceCampaignName, cmm.sourceAdGroupName, cmm.destinationCampaignName, cmm.destinationAdGroupName].every(
          (value) => !isNil(value),
        )
      ) {
        // ts can't understand that values can't be null
        const sdNegativesIdentifier = createSourceDestinationIdentifiedForNegatives(
          cmm.sourceCampaignName as string,
          cmm.sourceAdGroupName as string,
          cmm.destinationCampaignName as string,
          cmm.destinationAdGroupName as string,
        );
        if (!sdIdToNegatives[sdNegativesIdentifier]) sdIdToNegatives[sdNegativesIdentifier] = [];
        sdIdToNegatives[sdNegativesIdentifier].push(cmm.getNegatives());
      }
      models.push(cmm);
    }

    for (const cmm of models) {
      if (
        [cmm.sourceCampaignName, cmm.sourceAdGroupName, cmm.destinationCampaignName, cmm.destinationAdGroupName].every(
          (value) => !isNil(value),
        )
      ) {
        // ts can't understand that values can't be null
        const sdNegativesIdentifier = createSourceDestinationIdentifiedForNegatives(
          cmm.sourceCampaignName as string,
          cmm.sourceAdGroupName as string,
          cmm.destinationCampaignName as string,
          cmm.destinationAdGroupName as string,
        );
        if (!sdIdToNegatives[sdNegativesIdentifier]) sdIdToNegatives[sdNegativesIdentifier] = [];

        cmm.applyNegativesIfAny(sdIdToNegatives[sdNegativesIdentifier]);
      }
    }

    setTimeout(() => {
      if (!isNotifiedAboutDuplicatesRef.current && duplicateCount > 0) {
        toastService.info(
          `${duplicateCount} duplicate${duplicateCount > 1 ? 's' : ''} row${duplicateCount > 1 ? 's' : ''} removed (source+destination+match)`,
        );
        isNotifiedAboutDuplicatesRef.current = true;
      }
    }, 0);

    return models;
  }
  // memo the conversion of the uploadedFileParsed to the format that the table expects
  const rowData = useMemo(() => {
    if (uploadedFileParsed.length === 0 || isEmpty(campaignToAdGroupsMap)) return [];
    const importedRows = uploadedFileParsed.slice(2); // remove headers

    const models = importRowsToModels(importedRows);

    // Init: Try to gather errors and warnings. On row data setting and on grid ready
    if (campaignMappingTableGridApiRef.current && importDetails.rowCount == 0) {
      updateImportDetails(campaignMappingTableGridApiRef.current);
    }
    return models;
  }, [uploadedFileParsed, campaignToAdGroupsMap, existingMappingKeys]);

  function onCampaignMappingTableGridReady(params: GridReadyEvent) {
    // Init: Try to gather errors and warnings. On row data setting and on grid ready
    if (campaignMappingTableGridApiRef.current && importDetails.rowCount == 0) {
      updateImportDetails(campaignMappingTableGridApiRef.current);
    }
    campaignMappingTableGridApiRef.current = params.api;
  }

  function getDefinedValues(obj: Partial<Record<keyof CampaignMappingModelWithValidation, string>>): string[] {
    const definedValues: string[] = [];

    // Loop over each property in the object
    Object.keys(obj).forEach((key) => {
      const value = obj[key as keyof CampaignMappingModelWithValidation];
      if (value !== undefined) {
        // Check if the value is defined
        definedValues.push(value);
      }
    });

    return definedValues;
  }

  function updateImportDetails(api: GridApi<CampaignMappingModelWithValidation>) {
    // TODO: use createImportDetails
    const importDetails: CampaignMappingImportDetails = {
      rowCount: 0,
      errorCount: 0,
      warningCount: 0,
      errorMessageSamples: [],
      warningSamples: [],
      firstErrorRowId: null,
      firstWarningRowId: null,
    };

    api.forEachNode((rowNode: IRowNode<CampaignMappingModelWithValidation>) => {
      importDetails.rowCount += 1;
      const rowErrors = rowNode.data?.validationErrors;
      if (rowErrors) {
        const definedRowErrors = getDefinedValues(rowErrors);
        importDetails.errorCount += definedRowErrors.length;

        if (importDetails.errorMessageSamples.length < MAX_ERROR_WARNING_SAMPLES) {
          importDetails.errorMessageSamples.push(...definedRowErrors);
        }

        if (definedRowErrors.length > 0 && importDetails.firstErrorRowId === null) {
          importDetails.firstErrorRowId = rowNode.data?.id ?? null;
        }
      }
      const rowWarnings = rowNode.data?.validationWarnings;
      if (rowWarnings) {
        const definedRowWarnings = getDefinedValues(rowWarnings);
        importDetails.warningCount += definedRowWarnings.length;

        if (importDetails.warningSamples.length < MAX_ERROR_WARNING_SAMPLES) {
          importDetails.warningSamples.push(...definedRowWarnings);
        }

        if (definedRowWarnings.length > 0 && importDetails.firstWarningRowId === null) {
          importDetails.firstWarningRowId = rowNode.data?.id ?? null;
        }
      }
    });

    importDetails.errorMessageSamples = [...new Set(importDetails.errorMessageSamples)]; // remove duplicates
    importDetails.warningSamples = [...new Set(importDetails.warningSamples)]; // remove duplicates

    setImportDetails(importDetails);
  }

  function onGridDataChanged(api: GridApi<CampaignMappingModelWithValidation>) {
    updateImportDetails(api);
  }

  return (
    <CampaignMappingProvider filters={filters} setFilters={setFilters}>
      <AlErrorBoundary>
        <CampaignMappingFilterBar onlyDownloadButton />
      </AlErrorBoundary>

      <AlErrorBoundary>
        <CampaignMappingImportPreviewTable
          rowData={rowData ?? []}
          externalFilters={filters}
          isLoading={false} // TODO: rm? attach loading?
          onGridDataChanged={onGridDataChanged}
          campaignMappingLoadingErrorMessage={''} // TODO: rm? attach error?
          isCampaignMappingLoadingError={false} // TODO: rm? attach error?
          onGridReadyCallback={onCampaignMappingTableGridReady}
          noTopBorderRadius={true}
        />
      </AlErrorBoundary>

      <AlErrorBoundary>
        <CampaignMappingImportPreviewActionBar
          importDetails={importDetails}
          campaignMappingTableGridApiRef={campaignMappingTableGridApiRef}
          onCloseModal={onCloseModal}
        />
      </AlErrorBoundary>
    </CampaignMappingProvider>
  );
};

function createSourceDestinationIdentifiedForNegatives(
  sourceCampaign: string,
  sourceAdGroup: string,
  destinationCampaign: string,
  destinationAdGroup: string,
) {
  return sourceCampaign + sourceAdGroup + destinationCampaign + destinationAdGroup;
}
