import { AlDate, AlDateRawType } from '@/lib/date/AlDate';
import { DateRange, PickersShortcutsItem } from '@mui/x-date-pickers-pro';

export const getWeekPickerShortcutsItems = (): PickersShortcutsItem<DateRange<AlDateRawType>>[] => {
  return [
    {
      label: 'Last week',
      getValue: (): DateRange<AlDateRawType> => {
        const now = AlDate.now();
        const lastWeekEnd = now.minus({ weeks: 1 }).endOf('week');
        const lastWeekStart = now.minus({ weeks: 1 }).startOf('week');
        return [lastWeekStart.toRaw(), lastWeekEnd.toRaw()];
      },
    },
    {
      label: 'Last 2 weeks',
      getValue: (): DateRange<AlDateRawType> => {
        const now = AlDate.now();
        const lastWeekEnd = now.minus({ weeks: 1 }).endOf('week');
        const twoWeeksAgoStart = now.minus({ weeks: 2 }).startOf('week');
        return [twoWeeksAgoStart.toRaw(), lastWeekEnd.toRaw()];
      },
    },
    {
      label: 'Last 4 weeks',
      getValue: (): DateRange<AlDateRawType> => {
        const now = AlDate.now();
        // Get the last full week (ending on Saturday)
        const lastWeekEnd = now.minus({ weeks: 1 }).endOf('week');
        const fourWeeksAgoStart = now.minus({ weeks: 4 }).startOf('week');
        return [fourWeeksAgoStart.toRaw(), lastWeekEnd.toRaw()];
      },
    },
    {
      label: 'Last 8 weeks',
      getValue: (): DateRange<AlDateRawType> => {
        const now = AlDate.now();
        const lastWeekEnd = now.minus({ weeks: 1 }).endOf('week');
        const eightWeeksAgoStart = now.minus({ weeks: 8 }).startOf('week');
        return [eightWeeksAgoStart.toRaw(), lastWeekEnd.toRaw()];
      },
    },
    {
      label: 'Last 12 weeks',
      getValue: (): DateRange<AlDateRawType> => {
        const now = AlDate.now();
        const lastWeekEnd = now.minus({ weeks: 1 }).endOf('week');
        const twelveWeeksAgoStart = now.minus({ weeks: 12 }).startOf('week');
        return [twelveWeeksAgoStart.toRaw(), lastWeekEnd.toRaw()];
      },
    },
  ];
};
