import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button, IconButton, Paper, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FunctionComponent } from 'react';
import { SellerCentralAuthorizationButton } from './SellerCentralAuthorizationButton';
import { VendorCentralAuthorizationButton } from './VendorCentralAuthorizationButton';

interface SellingPartnerSelectionDialogProps {
  open: boolean;
  onClose: () => void;
}

export const SellingPartnerSelectionDialog: FunctionComponent<SellingPartnerSelectionDialogProps> = ({ onClose, open }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open} maxWidth={'md'}>
        <DialogTitle>
          <div className="flex flex-row">
            Authorize Selling Partner
            <div className="flex flex-grow"></div>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent>
          <div className="max-w-2xl">
            By linking the Selling Partner account to your advertising profile, you gain access to valuable metrics like{' '}
            <strong>Total Sales</strong>, <strong>Organic Sales</strong>, <strong>TACOS</strong>, among many others.
            <div className="flex items-center mt-4 mb-2">
              <InfoIcon fontSize="small" className="mr-1.5 text-blue-600" />
              Make sure you are logged in Seller/Vendor Central before authorizing.
            </div>
            <div className="flex flex-row justify-between p-4 gap-4 min-h-56">
              <Paper
                elevation={3}
                className="flex-1 flex flex-col items-center justify-center p-4 rounded-lg hover:bg-gray-100 transition-colors gap-4"
              >
                <Typography variant="h5">Seller Central</Typography>

                <SellerCentralAuthorizationButton />
              </Paper>
              <Paper
                elevation={3}
                className="flex-1 flex flex-col items-center justify-center p-4 rounded-lg hover:bg-gray-100 transition-colors gap-4"
              >
                <Typography variant="h5">Vendor Central</Typography>
                <VendorCentralAuthorizationButton />
              </Paper>
            </div>
            <Button
              variant="text"
              href="https://help.adlabs.app/en/articles/38-seller-central-user-permissions-needed-to-use-adlabs"
              target="_blank"
              rel="noopener noreferrer"
              size="large"
              endIcon={<OpenInNewIcon />}
            >
              Learn More about Seller Central Permissions needed to use AdLabs
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
