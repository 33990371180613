import { ApiResponse } from '@/lib/api/api-response';
import { apiClient, apiTeamClient } from '@/lib/api/base-client';
import { PortalSessionDTO, PromotionCodeDTO } from './plan.contracts';
import { PlanType } from '@/modules/plans/types/SubscriptionPlan';

class PlanService {
  public basePath = 'plans';

  async getCheckoutLinkForPlan(plan: PlanType, returnURLPath: string): Promise<ApiResponse<PortalSessionDTO>> {
    return await apiTeamClient.post<PortalSessionDTO>(`${this.basePath}/create-checkout-session`, null, {
      plan: plan,
      return_url: `${window.location.origin + returnURLPath}`,
    });
  }

  async getBillingLinkForTeam(returnURLPath: string): Promise<ApiResponse<PortalSessionDTO>> {
    return await apiTeamClient.post<PortalSessionDTO>(`${this.basePath}/create-billing-session`, null, {
      return_url: `${window.location.origin + returnURLPath}`,
    });
  }

  async getBillingAddPaymentMethodLinkForTeam(returnURLPath: string): Promise<ApiResponse<PortalSessionDTO>> {
    return await apiTeamClient.post<PortalSessionDTO>(`${this.basePath}/create-add-payment-method-session`, null, {
      return_url: `${window.location.origin + returnURLPath}`,
    });
  }

  async getCancellationLinkForPlan(returnURLPath: string): Promise<ApiResponse<PortalSessionDTO>> {
    return await apiTeamClient.post<PortalSessionDTO>(`${this.basePath}/create-cancel-session`, null, {
      return_url: `${window.location.origin + returnURLPath}`,
    });
  }

  async cancelCancellation(): Promise<ApiResponse<null>> {
    return await apiTeamClient.post(`${this.basePath}/cancel-cancellation`, null, {});
  }

  async upgradeFromLegacyPlan(): Promise<ApiResponse<null>> {
    return await apiTeamClient.post(`${this.basePath}/upgrade-from-legacy-plan`, null, {});
  }

  async getPromotionCode(promotionCode: string): Promise<ApiResponse<PromotionCodeDTO>> {
    return await apiClient.get(`${this.basePath}/promotion-code/${promotionCode}`);
  }
}

export const planService = new PlanService();
