import {
  AlFilterModel,
  ComparisonDateFilterModel,
  DateFilterModel,
  LogicalOperatorType,
  OperatorType,
} from '@/components/filter-builder/models/AlFilterModel';
import { METRICS } from '@/components/metrics/MetricsConfig';
import { CommonMetricField } from '@/components/metrics/types/MetricField';
import { AlDate } from '@/lib/date/AlDate';
import { toastService } from '@/services/toast.service';
import { assign, debounce } from 'lodash-es';
import { Dispatch, SetStateAction, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';
import { dashboardService } from '../api/dashboard/dashboard.service';
import { MetricWidgetConfiguration } from '../components/widgets/metric-widget/MetricWidgetConfiguration';
import { DashboardModel } from '../types/DashboardModel';
import { DashboardWidgetType } from '../types/DashboardWidgetType';
import { IDashboardWidget } from '../types/IDashboardWidget';
import { IDashboardWidgetBaseConfiguration } from '../types/IDashboardWidgetBaseConfiguration';
import { externalDashboardService } from '@/modules/external/api/external-dashboard.service';
import { useNavigate } from 'react-router';
import { Routes } from '@/router/router-paths';

interface DashboardContextType {
  editingWidgetId?: string;
  setEditingWidgetId: (id: string) => void;
  isLoadingDashboard: boolean;
  // setIsLoadingDashboard: (isLoading: boolean) => void;
  widgets: IDashboardWidget<IDashboardWidgetBaseConfiguration>[];
  setWidgets: Dispatch<SetStateAction<IDashboardWidget<IDashboardWidgetBaseConfiguration>[]>>;
  updateWidgetConfiguration: <T extends IDashboardWidgetBaseConfiguration>(
    widgetToUpdateId: string,
    widgetConfigurationUpdate: Partial<T>,
    updateDashboard?: boolean,
  ) => Promise<void>;
  dashboard?: DashboardModel;
  removeWidget: (widgetIdToRemove: string) => void;
  dates: AlDate[];
  setDates: Dispatch<SetStateAction<AlDate[]>>;
  comparisonDates: AlDate[];
  setComparisonDates: Dispatch<SetStateAction<AlDate[]>>;
  dashboardDateFilters: AlFilterModel[];
  dragginWidgetId: string;
  setDragginWidgetId: (id: string) => void;
  saveDashboard: (newWidgets: IDashboardWidget<IDashboardWidgetBaseConfiguration>[]) => Promise<void>;
  addWidget: <T extends IDashboardWidgetBaseConfiguration>(widget: IDashboardWidget<T>, updateDashboard?: boolean) => Promise<void>;
  widgetIdsRequestingData: Set<string>;
  setWidgetIdsRequestingData: Dispatch<SetStateAction<Set<string>>>;
  updateWidgetLayout: (widgetToUpdateId: string, layoutToUpdate: ReactGridLayout.Layout, updateDashboard?: boolean) => Promise<void>;
  setDashboardVersion: Dispatch<SetStateAction<string>>;
  dashboardVersion: string;
  removeAllWidgets: () => void;
  duplicateWidget: (widgetId: string) => Promise<string>;
  isExternal: boolean;
}
export const DashboardContext = createContext<DashboardContextType | null>(null);

interface UseDashboardOptions {
  external?: boolean;
}

export function useDashboard(dashboardIdentifier?: string | number, options?: UseDashboardOptions) {
  const navigate = useNavigate();
  const [editingWidgetId, setEditingWidgetId] = useState<string>('');
  const [dragginWidgetId, setDragginWidgetId] = useState<string>('');
  const [widgets, setWidgets] = useState<IDashboardWidget<IDashboardWidgetBaseConfiguration>[]>([]);
  const [dashboard, setDashboard] = useState<DashboardModel>();
  const [isLoadingDashboard, setIsLoadingDashboard] = useState(true);
  const [dates, setDates] = useState<AlDate[]>([AlDate.now().subtractDays(30), AlDate.now().subtractDays(1)]);
  const [comparisonDates, setComparisonDates] = useState<AlDate[]>([AlDate.now().subtractDays(60), AlDate.now().subtractDays(31)]);
  const [widgetIdsRequestingData, setWidgetIdsRequestingData] = useState<Set<string>>(new Set<string>());
  const [dashboardVersion, setDashboardVersion] = useState('0');
  const isExternal = options?.external ?? false;

  async function updateWidgetLayout(widgetToUpdateId: string, layoutToUpdate: ReactGridLayout.Layout, updateDashboard = false) {
    if (!dashboard) return;

    // Create a new array where the object with the matching id is replaced
    const newWidgets = dashboard.dto.widgets.map((widget) => {
      if (widget.id === widgetToUpdateId) {
        widget.layout = assign(widget.layout, layoutToUpdate); // Replace the widget
        return widget;
      }
      return widget; // Leave the widget as-is
    });

    // Update the state with the new array
    setWidgets(newWidgets);
    dashboard.dto.widgets = newWidgets;

    if (updateDashboard) {
      return await saveDashboard(newWidgets);
    } else {
      debouncedSaveDashboard(newWidgets);
    }

    return;
  }

  async function addWidget<T extends IDashboardWidgetBaseConfiguration>(widget: IDashboardWidget<T>) {
    if (!dashboard) return;
    // For metric widgets, assign a metric field to the widget
    // If there are no more metrics available, assign the first one
    if (widget.type === DashboardWidgetType.MetricWidget) {
      // Get all metric fields on the dashboard
      const metricFieldsOnDashboard = widgets
        .filter((widget): widget is IDashboardWidget<MetricWidgetConfiguration> => widget.type === DashboardWidgetType.MetricWidget)
        .map((widget) => widget.configuration.metricField);
      // Filter out the metric fields that are already used
      const unusedMetrics = Object.values(CommonMetricField).filter((metric) => !metricFieldsOnDashboard.includes(metric));

      // Apply the first unused metric field to the widget
      const metricField = unusedMetrics[0] ?? CommonMetricField.ACOS;
      (widget as unknown as IDashboardWidget<MetricWidgetConfiguration>).configuration.metricField = metricField;
      const configuredMetric = METRICS[metricField];
      (widget as unknown as IDashboardWidget<MetricWidgetConfiguration>).configuration.title = configuredMetric?.title ?? metricField;
    }

    widget.layout.x = 0;
    widget.layout.y = getHighestWidgetYCoordinate();
    widget.layout.isDraggable = true;
    widget.id = v4();
    widget.layout.i = v4();

    const newWidgets = [...widgets, widget];
    setWidgets(newWidgets);
    dashboard.dto.widgets = newWidgets;
    await saveDashboard(newWidgets);
  }

  async function updateWidgetConfiguration<T extends IDashboardWidgetBaseConfiguration>(
    widgetToUpdateId: string,
    configurationUpdate: Partial<T>,
    updateDashboard = false,
  ) {
    if (!dashboard) return;
    // Create a new array where the object with the matching id is replaced
    const newWidgets = dashboard.dto.widgets.map((widget) => {
      if (widget.id === widgetToUpdateId) {
        widget.configuration = assign(widget.configuration, configurationUpdate); // Replace the widget

        widget.configuration.id = v4();
        widget.configuration.isConfigured = true;
        return widget;
      }
      return widget; // Leave the widget as-is
    });

    // Update the state with the new array
    setWidgets(newWidgets);
    dashboard.dto.widgets = newWidgets;

    if (updateDashboard) {
      return await saveDashboard(newWidgets);
    } else {
      debouncedSaveDashboard(newWidgets);
    }

    return;
  }

  function removeWidget(widgetIdToRemove: string) {
    if (!dashboard) return;

    // Remove widget from dashboard widgets
    const newWidgetCollection = dashboard?.dto.widgets.filter((w) => w.id !== widgetIdToRemove);

    // Update the state with the new array
    setWidgets(newWidgetCollection);
    dashboard.dto.widgets = newWidgetCollection;

    debouncedSaveDashboard(newWidgetCollection);
  }

  useEffect(() => {
    const fetchDashboard = async () => {
      setIsLoadingDashboard(true);
      if (options?.external) {
        const dashboardResponse = await externalDashboardService.getByToken(dashboardIdentifier as string);
        if (dashboardResponse.isSuccess) {
          setDashboard(dashboardResponse.payload);
          setWidgets(dashboardResponse.payload.dto.widgets);
        } else if (dashboardResponse.httpResponseCode === 404) {
          navigate(Routes.EXTERNAL_NOT_FOUND);
        }
      } else {
        const dashboardResponse = await dashboardService.getById(Number.parseInt(dashboardIdentifier ? dashboardIdentifier.toString() : '0'));
        if (dashboardResponse.isSuccess) {
          setDashboard(dashboardResponse.payload);
          setWidgets(dashboardResponse.payload.dto.widgets);
        }
      }
      setIsLoadingDashboard(false);
    };
    if (dashboardIdentifier) {
      fetchDashboard();
    }
  }, [dashboardIdentifier]);

  function getHighestWidgetYCoordinate() {
    return widgets.reduce((acc, widget) => {
      return widget.layout.y + widget.layout.h > acc ? widget.layout.y + widget.layout.h : acc;
    }, 0);
  }

  async function duplicateWidget(widgetId: string): Promise<string> {
    if (!dashboard) return '';

    const widgetToDuplicate = dashboard.dto.widgets.find((w) => w.id === widgetId);
    if (!widgetToDuplicate) return '';

    const newWidget: IDashboardWidget<IDashboardWidgetBaseConfiguration> = {
      ...widgetToDuplicate,
      id: v4(),
      layout: {
        ...widgetToDuplicate.layout,
        x: 0,
        y: getHighestWidgetYCoordinate(),
      },
      configuration: {
        ...widgetToDuplicate.configuration,
        id: v4(),
        isConfigured: false,
      },
    };

    const newWidgets = [...widgets, newWidget];
    setWidgets(newWidgets);
    dashboard.dto.widgets = newWidgets;

    await saveDashboard(newWidgets);

    setEditingWidgetId(newWidget.id);

    return newWidget.id;
  }

  const debouncedSaveDashboard = debounce(saveDashboard, 1000);

  async function saveDashboard(newWidgets: IDashboardWidget<IDashboardWidgetBaseConfiguration>[]) {
    if (dashboard) {
      const response = await dashboardService.update(dashboard.dto.id, {
        ...dashboard.dto,
        widgets: newWidgets,
      });
      if (!response.isSuccess) {
        toastService.error('Error saving dashboard');
      }
    }
  }

  const dashboardDateFilters = useMemo(() => {
    return [
      new DateFilterModel({
        logicalOperator: LogicalOperatorType.AND,
        conditions: [
          {
            values: [dates[0].toDefaultFormat()],
            operator: OperatorType.GREATER_THAN_OR_EQUAL,
          },
          {
            values: [dates[1].toDefaultFormat()],
            operator: OperatorType.LESS_THAN_OR_EQUAL,
          },
        ],
      }),
      new ComparisonDateFilterModel({
        logicalOperator: LogicalOperatorType.AND,
        conditions: [
          {
            values: [comparisonDates[0].toDefaultFormat()],
            operator: OperatorType.GREATER_THAN_OR_EQUAL,
          },
          {
            values: [comparisonDates[1].toDefaultFormat()],
            operator: OperatorType.LESS_THAN_OR_EQUAL,
          },
        ],
      }),
    ];
  }, [dates, comparisonDates]);

  function removeAllWidgets() {
    setWidgets([]);
    setDashboardVersion((prev) => `${parseInt(prev) + 1}`);
  }

  return {
    editingWidgetId,
    setEditingWidgetId,
    widgets,
    setWidgets,
    dashboard,
    setDashboard,
    isLoadingDashboard,
    removeWidget,
    updateWidgetConfiguration,
    dates,
    setDates,
    comparisonDates,
    setComparisonDates,
    dashboardDateFilters,
    dragginWidgetId,
    setDragginWidgetId,
    saveDashboard,
    addWidget,
    widgetIdsRequestingData,
    setWidgetIdsRequestingData,
    updateWidgetLayout,
    setDashboardVersion,
    dashboardVersion,
    removeAllWidgets,
    duplicateWidget,
    isExternal,
  };
}

const useDashboardContext = () => {
  const dashboardContext = useContext(DashboardContext);

  if (!dashboardContext) {
    throw new Error('useDashboard has to be used within <DashboardContext.Provider>');
  }

  return dashboardContext;
};

// Context Selector Hook
export const useDashboardContextValue = <T,>(selector: (context: DashboardContextType) => T): T => {
  const dashboardContext = useContext(DashboardContext);

  if (!dashboardContext) {
    throw new Error('useDashboardContextValue must be used within a DashboardContext.Provider');
  }

  return selector(dashboardContext);
};

// This is a conceptual example; you might need a library like `use-context-selector`
export const useWidgetById = (widgetId: string) => {
  const { widgets } = useDashboardContext();
  const widget = useMemo(() => widgets.find((w) => w.id === widgetId), [widgets, widgetId]);
  return widget;
};
