import { ReactNode } from 'react';
import { SavedFilterLevel } from '../api/filters-contracts';
import { FilterPresetModel } from '../models/FilterPresetModel';

export const ALL_PRESETS = 'ALL PRESETS';
export type SavedFilterLevelType = SavedFilterLevel | typeof ALL_PRESETS;

export interface WrappedSavedFilterPreset {
  preset: FilterPresetModel;
  disabled?: boolean;
  tooltip?: ReactNode;
}

export enum SaveFlowType {
  UPDATE,
  SAVE_AS_NEW,
}
