import { ColumnId } from '@/components/grid/columns/columns.enum';
import type { ColumnState } from 'ag-grid-enterprise';

export function generateCampaignTableColumnState(): ColumnState[] {
  return [
    { colId: ColumnId.CHECKBOX, hide: false, pinned: 'left' },
    { colId: ColumnId.CAMPAIGN_AD_TYPE, hide: false, pinned: 'left' },
    { colId: ColumnId.CAMPAIGN_NAME, hide: false, pinned: 'left' },
    { colId: ColumnId.STATE, hide: false, pinned: 'left' },
    { colId: ColumnId.PORTFOLIO_NAME, hide: false, pinned: false },
    { colId: ColumnId.SCHEDULE_NAME, hide: true, pinned: false },
    { colId: ColumnId.GROUP_NAME, hide: false, pinned: false },
    { colId: ColumnId.CAMPAIGN_LAST_OPTIMIZED, hide: false, pinned: false },
    { colId: ColumnId.SALES_PREVIOUS_DAYS, hide: false, pinned: false },
    { colId: ColumnId.SPEND_PREVIOUS_DAYS, hide: false, pinned: false },
    { colId: ColumnId.ACOS, hide: false, pinned: false },
    { colId: ColumnId.ACTC, hide: true, pinned: false },
    { colId: ColumnId.SPEND, hide: false, pinned: false },
    { colId: ColumnId.SALES, hide: false, pinned: false },
    { colId: ColumnId.IMPRESSIONS, hide: false, pinned: false },
    { colId: ColumnId.CLICKS, hide: false, pinned: false },
    { colId: ColumnId.ORDERS, hide: false, pinned: false },
    { colId: ColumnId.UNITS, hide: false, pinned: false },
    { colId: ColumnId.CTR, hide: false, pinned: false },
    { colId: ColumnId.CVR, hide: false, pinned: false },
    { colId: ColumnId.CPC, hide: false, pinned: false },
    { colId: ColumnId.ROAS, hide: true, pinned: false },
    { colId: ColumnId.RPC, hide: true, pinned: false },
    { colId: ColumnId.CPA, hide: true, pinned: false },
    { colId: ColumnId.AOV, hide: true, pinned: false },
    { colId: ColumnId.CPM, hide: true, pinned: false },
    { colId: ColumnId.START_DATE, hide: true, pinned: false },
    { colId: ColumnId.END_DATE, hide: true, pinned: false },
    { colId: ColumnId.TARGETING_TYPE, hide: true, pinned: false },
    { colId: ColumnId.CREATIVE_TYPE, hide: true, pinned: false },
    { colId: ColumnId.COST_TYPE, hide: true, pinned: false },
    { colId: ColumnId.BID_OPTIMIZATION, hide: true, pinned: false },
    { colId: ColumnId.BUDGET_AMOUNT, hide: true, pinned: false },
    { colId: ColumnId.BID_STRATEGY, hide: true, pinned: false },
    { colId: ColumnId.MULTI_AD_GROUPS_ENABLED, hide: true, pinned: false },
    { colId: ColumnId.ID, hide: true, pinned: 'left' },
  ];
}
