import { createContext } from 'react';

export interface ExternalContextType {
  dashboardShareToken: string | null;
  setDashboardShareToken: React.Dispatch<React.SetStateAction<string | null>>;
}

// Create the context with a default value.
export const ExternalContext = createContext<ExternalContextType>({
  dashboardShareToken: null,
  setDashboardShareToken: () => {},
});
