import EditableCellRenderer from '@/components/grid/cells/EditableCellRenderer';
import { IErrorWarningCellRendererParams } from '@/components/grid/cells/ErrorWarningWrapperCellRenderer';
import { isValidNumberInput } from '@/modules/application/utils';
import { getErrorWarningCellClass } from '@/types/colors.enum';
import type { CellClassParams, IRowNode } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import { CampaignMappingImportDetails, HasValidation } from '../types/feedback';

function getDefinedValues<T>(obj: Partial<Record<keyof T, string>>): string[] {
  const definedValues: string[] = [];

  // Loop over each property in the object
  Object.keys(obj).forEach((key) => {
    const value = obj[key as keyof T];
    if (value !== undefined) {
      // Check if the value is defined
      definedValues.push(value);
    }
  });

  return definedValues;
}

export function createImportDetails<T extends HasValidation<T>>(
  api: { forEachNode: (callback: (node: IRowNode<T>) => void) => void },
  maxErrorWarningSamples: number,
): CampaignMappingImportDetails {
  const importDetails: CampaignMappingImportDetails = {
    rowCount: 0,
    errorCount: 0,
    warningCount: 0,
    errorMessageSamples: [],
    warningSamples: [],
    firstErrorRowId: null,
    firstWarningRowId: null,
  };

  api.forEachNode((rowNode: IRowNode<T>) => {
    importDetails.rowCount += 1;
    const rowErrors = rowNode.data?.validationErrors;
    if (rowErrors) {
      const definedRowErrors = getDefinedValues(rowErrors);
      importDetails.errorCount += definedRowErrors.length;

      if (importDetails.errorMessageSamples.length < maxErrorWarningSamples) {
        importDetails.errorMessageSamples.push(...definedRowErrors);
      }

      if (definedRowErrors.length > 0 && importDetails.firstErrorRowId === null) {
        importDetails.firstErrorRowId = rowNode.data?.id ?? null;
      }
    }

    const rowWarnings = rowNode.data?.validationWarnings;
    if (rowWarnings) {
      const definedRowWarnings = getDefinedValues(rowWarnings);
      importDetails.warningCount += definedRowWarnings.length;

      if (importDetails.warningSamples.length < maxErrorWarningSamples) {
        importDetails.warningSamples.push(...definedRowWarnings);
      }

      if (definedRowWarnings.length > 0 && importDetails.firstWarningRowId === null) {
        importDetails.firstWarningRowId = rowNode.data?.id ?? null;
      }
    }
  });

  // Remove duplicate messages
  importDetails.errorMessageSamples = [...new Set(importDetails.errorMessageSamples)];
  importDetails.warningSamples = [...new Set(importDetails.warningSamples)];

  return importDetails;
}

export function getCellClass<T extends HasValidation<T>>(params: CellClassParams<T, unknown>): string {
  const field = params.colDef?.field as keyof T;
  const errors = params.data?.validationErrors;
  const warnings = params.data?.validationWarnings;
  const isError = errors && errors[field] != null;
  const isWarning = warnings && warnings[field] != null;

  return getErrorWarningCellClass(!!isError, !!isWarning);
}

export function getErrorWarningCellRendererParams<T extends HasValidation<T>>(params: IErrorWarningCellRendererParams<T>) {
  const field = params.colDef?.field as keyof T;

  return {
    CellRenderer: EditableCellRenderer,
    cellRendererParams: params,
    errorMessage: field ? params.data?.validationErrors?.[field] : undefined,
    warningMessage: field ? params.data?.validationWarnings?.[field] : undefined,
  };
}

// Validation
export function isValidNumberInputAndNotNil(value: string | undefined): boolean {
  return !isNil(value) && !isValidNumberInput(value);
}

/**
 * Returns true if `value` is one of the enum’s values.
 */
export function isEnumValue<T extends Record<string, string>>(enumObj: T, value: unknown): value is T[keyof T] {
  return typeof value === 'string' && Object.values(enumObj).includes(value as T[keyof T]);
}
