export enum Routes {
  BILLING = '/configuration/settings/billing',
  USER = '/configuration/settings/user',
  TEAMS = '/configuration/settings/teams',
  IMPORTER = '/configuration/data-management/import',
  OPTIMIZER = '/optimizer',
  DASHBOARDS = '/dashboards',
  PROFILES_STATS = '/profiles-stats',
  PROFILES = '/profiles',
  CAMPAIGN_GROUPS = '/campaign-groups',
  HISTORY = '/history',
  TARGETING = '/targeting',
  PLACEMENTS = '/placements',
  NEGATIVE_TARGETING = '/negative-targeting',
  SEARCH_TERMS = '/search-terms',
  DATA_GROUPS = '/data-groups',
  GETTING_STARTED = '/getting-started',
  CAMPAIGN_MAPPING = '/campaign-mapping',
  PRODUCTS = '/products',
  LOGS = '/logs',
  ADVERTISED_PRODUCTS = '/advertised-products',
  DAYPARTING = '/dayparting',
  DAYPARTING_CREATE = '/dayparting/details/create',
  DAYPARTING_DETAILS = '/dayparting/details',
  SEARCH_QUERY_PERFORMANCE = '/search-query-performance',

  REGISTER = '/register',
  REGISTER_CREATE_YOUR_ACCOUNT = `${REGISTER}/create-your-account`,
  REGISTER_ABOUT_YOU = `${REGISTER}/about-you`,
  REGISTER_ALMOST_THERE = `${REGISTER}/almost-there`,
  REGISTER_BOOK_CALL = `${REGISTER}/book-call`,
  REGISTER_CONNECT_AMAZON = `${REGISTER}/connect-amazon`,

  RESET_PASSWORD = '/reset-password',
  IMPERSONATE = '/impersonate',
  LOGIN = '/login',

  EXTERNAL_NOT_FOUND = '/external/not-found',
}
