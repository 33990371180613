import { useState } from 'react';
import { ButtonBase } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import { DashboardShareModal } from './DashboardShareModal';

interface DashboardShareButtonProps {
  dashboardId: number;
}

export const DashboardShareButton = ({ dashboardId }: DashboardShareButtonProps) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="flex h-full flex-1 items-center">
      <ButtonBase
        className="flex h-10 min-w-32 cursor-pointer justify-center rounded-md bg-white px-4 py-2 text-sm font-medium text-gray-850 hover:bg-primary-50 hover:text-gray-700 dark:text-gray-300 dark:hover:bg-gray-700"
        onClick={() => setOpen(true)}
      >
        <ShareIcon fontSize="small" className="pr-1" />
        Share
      </ButtonBase>

      <DashboardShareModal open={open} onClose={() => setOpen(false)} dashboardId={dashboardId} />
    </div>
  );
};
