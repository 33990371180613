import useVideoModal from '@/components/modals/video-modal/useVideoModal';
import VideoModal from '@/components/modals/video-modal/VideoModal';
import { VideoUrl } from '@/config/urls';
import useGlobalLoadingStateObserver from '@/hooks/useGlobalLoadingStateObserver';
import { useTranslation } from '@/lib/i18n/useTranslate';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import UpgradeSubscriptionButton from '@/modules/teams/components/UpgradeSubscriptionButton';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey } from '@/modules/users';
import { toastService } from '@/services/toast.service';
import { OndemandVideo } from '@mui/icons-material';
import { Button } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import { FunctionComponent } from 'react';
import { createGetAllGroupsQueryKey, dataGroupsService } from '../api/data-groups-service';
import DataGroupsActions from '../components/DataGroupsActions';
import DataGroupsTable from '../components/DataGroupsTable';

const DataGroupsPage: FunctionComponent = () => {
  const { t } = useTranslation();

  const { activeTeam, activeProfile } = useActiveTeamContext();

  const {
    data: rowData,
    isLoading,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: createGetAllGroupsQueryKey(activeTeam?.id),
    queryFn: async () => {
      const result = await dataGroupsService.getAllGroups();

      if (result.isSuccess) {
        return result.payload;
      } else {
        toastService.error(`Error loading tags ${result.message}`);
      }
    },
    enabled: !isNil(activeTeam) && !isNil(activeProfile),
  });

  useGlobalLoadingStateObserver('isFetching tags', isFetching);

  // TUTORIAL MODAL
  const { isVideoModalOpen, onVideoModalClose, openVideoModal } = useVideoModal(UserSettingKey.DATA_GROUPS_TUTORIAL_DISPLAY_COUNT);

  return (
    <PageLayout>
      <PageLayoutTopBar
        header={
          <>
            <div className="flex flex-row items-center gap-4">
              {t('data_groups')}
              <Button onClick={() => openVideoModal()} variant="contained" startIcon={<OndemandVideo />}>
                How to Use Tags
              </Button>
            </div>
            <VideoModal
              isOpen={isVideoModalOpen}
              onClose={onVideoModalClose}
              url={VideoUrl.DATA_GROUPS}
              title={'How to Use Tags in AdLabs'}
            />
          </>
        }
        actions={<div className="flex flex-row items-center gap-2">{!activeTeam?.hasProPlan && <UpgradeSubscriptionButton />}</div>}
      ></PageLayoutTopBar>

      <PageLayoutBody>
        <ErrorBoundary>
          <DataGroupsActions rowData={rowData} refetch={refetch} />
          <div className="flex flex-grow">
            <DataGroupsTable rowData={rowData} isLoading={isLoading} refetch={refetch} />
          </div>
        </ErrorBoundary>
      </PageLayoutBody>
    </PageLayout>
  );
};

export default DataGroupsPage;
