export enum VideoUrl {
  PAYWALL_DEFAULT = 'https://www.youtube.com/embed/qTxMzdTO2EA?si=SxbrVwbWREiwi1ZE',
  DATA_GROUPS = 'https://player.vimeo.com/video/928851041?badge=0&autopause=0&player_id=0&app_id=58479',
  TARGETING = 'https://player.vimeo.com/video/933158921?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  PLACEMENTS = 'https://player.vimeo.com/video/935356801?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  OPTIMIZATION_GROUPS = 'https://player.vimeo.com/video/933158822?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  TIME_MACHINE = 'https://player.vimeo.com/video/933160709?badge=0&autopause=0&player_id=0&app_id=58479',
  BID_OPTIMIZER_ADVANCED = 'https://www.youtube.com/embed/0HIsSJd3NhY?si=kStMqPCMzTf5QYRL',
  KW_HARVESTING = 'https://player.vimeo.com/video/1003049326?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  //TODO ADD DAYPARTING VIDEO URL
  DAYPARTING = 'https://player.vimeo.com/video/933158822?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&dayparting=true',
}
