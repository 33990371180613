import { Routes } from '@/router/router-paths';
import { ArrowForwardRounded, Check, DeleteSweep } from '@mui/icons-material';
import { Alert, Autocomplete, Button, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import * as Sentry from '@sentry/react';
import { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router';
import { CampaignAdType } from '../../api/campaign/campaign-contracts';
import { SelectedCampaignDTO } from '../../api/campaign/models/CampaignModel';
import { DaypartingScheduleModel } from '@/modules/dayparting/types/DaypartingScheduleModel';
import { DaypartingScheduleAssignmentDTO } from '@/modules/dayparting/api/dayparting.contracts';
import useCampaignDaypartingAssignmentWarnings from '../../hooks/useCampaignDaypartingAssignmentWarnings';
import InfoMessage from '@/components/feedback/InfoMessage';

interface UpdateDaypartingSchedulesProps {
  selectedCampaigns: SelectedCampaignDTO[];
  existingSchedules: DaypartingScheduleModel[];
  onClose: () => void;
  applyChanges: (changeSchedules: DaypartingScheduleAssignmentDTO[], isRemoval: boolean) => void;
  isApplyPending: boolean;
}

export const UpdateDaypartingSchedules: FunctionComponent<UpdateDaypartingSchedulesProps> = ({
  selectedCampaigns,
  existingSchedules,
  onClose,
  applyChanges,
  isApplyPending,
}) => {
  const [selecteddaypartingScheduleId, setSelecteddaypartingScheduleId] = useState<number | undefined>(undefined);
  const navigate = useNavigate();
  const { warnings, filterValidItems } = useCampaignDaypartingAssignmentWarnings({ selectedItems: selectedCampaigns });

  function onApplyClicked() {
    onClose();

    if (!selecteddaypartingScheduleId) {
      // Should not happen
      Sentry.captureMessage(
        `UpdateDaypartingSchedules: onApplyClicked when no schedules were selected. ${selecteddaypartingScheduleId}`,
        'info',
      );
      return;
    }

    const changeSchedulesForCampaigns: DaypartingScheduleAssignmentDTO = {
      campaign_ids: filterValidItems(selectedCampaigns).map((campaign) => campaign.id),
      schedule_id: selecteddaypartingScheduleId,
    };

    applyChanges([changeSchedulesForCampaigns], false);
  }

  function onManageDaypartingSchedulesClicked() {
    navigate(Routes.DAYPARTING);
  }

  function handleRemoveAll() {
    onClose();

    const changeSchedulesForCampaigns: DaypartingScheduleAssignmentDTO = {
      campaign_ids: selectedCampaigns.map((campaign) => campaign.id),
      schedule_id: 0,
    };

    applyChanges([changeSchedulesForCampaigns], true);
  }

  const getApplyButtonText = () => {
    if (selectedCampaigns.length == 0) {
      return 'No Campaigns Selected';
    } else if (!selecteddaypartingScheduleId) {
      return 'No Schedule Selected';
    }

    return `Assign Schedule to ${selectedCampaigns.length} Campaign` + (selectedCampaigns.length > 1 ? 's' : '');
  };

  const campaignsWithAssignedScheduleCount = selectedCampaigns.map((campaign) => campaign.scheduleId).filter((id) => id !== 0).length;

  const onlyTVSelected = selectedCampaigns.every((campaign) => campaign.adType == CampaignAdType.TV);

  const canSchedule = !onlyTVSelected;

  return (
    <>
      <DialogTitle className="text-md">Assign/Remove Dayparting Schedules</DialogTitle>
      <DialogContent>
        <>
          {selectedCampaigns.length > 0 && (
            <div className="mb-2 flex flex-row items-center justify-between font-bold">
              <div>{selectedCampaigns.length} Campaigns Selected</div>
              {campaignsWithAssignedScheduleCount > 0 && (
                <Button variant="text" startIcon={<DeleteSweep />} onClick={handleRemoveAll} color="error">
                  Remove Schedules from {campaignsWithAssignedScheduleCount} Campaigns
                </Button>
              )}
            </div>
          )}
        </>

        {onlyTVSelected && (
          <Alert severity="info" className="mb-2">
            Cannot assign schedules for Sponsored TV
          </Alert>
        )}

        {canSchedule && existingSchedules && existingSchedules.length > 0 && selectedCampaigns.length > 0 && (
          <Autocomplete
            id="select-schedule"
            disabled={!canSchedule}
            defaultValue={undefined}
            onChange={(event, newValue) => {
              setSelecteddaypartingScheduleId(newValue ? newValue.id : 0);
            }}
            options={existingSchedules}
            getOptionLabel={(option) => option.name} // Do not truncate for accurate searching
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select a Schedule to Assign"
                slotProps={{
                  inputLabel: { shrink: true },
                }}
              />
            )}
          />
        )}
        {canSchedule && (
          <div className="flex flex-row justify-between">
            <Button variant="text" endIcon={<ArrowForwardRounded fontSize="inherit" />} onClick={onManageDaypartingSchedulesClicked}>
              Manage Schedules
            </Button>
          </div>
        )}

        {warnings.length > 0 && (
          <>
            <div className="flex text-sm mt-2 font-medium">Some campaigns won&apos;t be assigned:</div>
            <div className="flex flex-col ">
              {warnings.map((warning, index) => (
                <InfoMessage key={index} content={warning} />
              ))}
            </div>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant={!canSchedule ? 'outlined' : 'text'}>
          Cancel
        </Button>

        {canSchedule && (
          <Button
            onClick={onApplyClicked}
            loading={isApplyPending}
            loadingPosition="start"
            startIcon={<Check />}
            variant="contained"
            disabled={selectedCampaigns.length == 0 || existingSchedules.length == 0 || !selecteddaypartingScheduleId}
          >
            <span>{getApplyButtonText()}</span>
          </Button>
        )}
      </DialogActions>
    </>
  );
};
