import { DTO } from '@/types/dto-wrapper';
import {
  DaypartingScheduleDayDTO,
  DaypartingScheduleDTO,
  DaypartingScheduleState,
  DaypartingScheduleTimeDTO,
  Weekday,
} from '../api/dayparting.contracts';

export class DaypartingScheduleModel extends DTO<DaypartingScheduleDTO> {
  constructor(dto: DaypartingScheduleDTO) {
    super(dto);
  }

  get id(): number {
    return this.dto.id;
  }

  get name(): string {
    return this.dto.name;
  }

  get state(): DaypartingScheduleState {
    return this.dto.state;
  }

  get createdBy(): string {
    return this.dto.created_by;
  }

  get assignCount(): number {
    return this.campaignIds.length;
  }
  get campaignIds(): string[] {
    return this.dto.campaign_ids;
  }

  get createdAt(): string {
    return this.dto.created_at;
  }

  get updatedAt(): string {
    return this.dto.updated_at;
  }

  /**
   * Get days with percentage changes (UI should interact with this)
   */
  get daysWithPercentageChanges(): DaypartingScheduleDayModel[] {
    return this.dto.days.map((day) => {
      return new DaypartingScheduleDayModel({
        ...day,
        changes: day.changes.map((change) => ({
          ...change,
          change: this.factorToPercentage(change.change),
        })),
      });
    });
  }

  /**
   * Convert days from percentage changes to factors
   */
  static convertDaysFromPercentageChanges(days: DaypartingScheduleDayModel[]): DaypartingScheduleDayDTO[] {
    return days.map((day) => {
      return {
        ...day.toDTO(),
        changes: day.changes.map((change) => ({
          ...change.toDTO(),
          change: this.percentageToFactor(change.change),
        })),
      };
    });
  }

  /**
   * Get days directly (Raw storage format)
   */
  get days(): DaypartingScheduleDayModel[] {
    return this.dto.days.map((day) => new DaypartingScheduleDayModel(day));
  }

  set days(updatedDays: DaypartingScheduleDayModel[]) {
    this.dto.days = updatedDays.map((day) => day.toDTO());
  }

  /**
   * Convert Factor to Percentage
   * Example: 0.01 → -99%, 1 → 0%, 2 → 100%, 2.5 → 150%, 4 → 300%
   *
   * Uses Math.round to prevent floating point errors
   */
  private factorToPercentage(factor: number): number {
    return Math.round((factor - 1) * 100);
  }

  /**
   * Convert Percentage to Factor
   * Example: -99% → 0.01, 0% → 1, 100% → 2, 150% → 2.5, 300% → 4
   *
   * Uses Math.round to keep 2 decimal places
   */
  private static percentageToFactor(percentage: number): number {
    return Math.round((1 + percentage / 100) * 100) / 100; // Keep 2 decimal places
  }

  toDTO(): DaypartingScheduleDTO {
    return {
      id: this.id,
      name: this.name,
      state: this.state,
      created_by: this.createdBy,
      campaign_ids: this.campaignIds,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
      days: this.days.map((d) => d.toDTO()),
    };
  }

  static generateDefaultDaypartingSchedule = (): DaypartingScheduleDayModel[] => {
    const hours = Array.from({ length: 24 }, (_, i) => `${i.toString().padStart(2, '0')}:00`);

    const days = Object.values(Weekday); // Get all values from the Weekday enum

    return days.map((day) => {
      const changes = hours.map((hour) => new DaypartingScheduleTimeModel({ time: hour, change: 0 }));
      return new DaypartingScheduleDayModel({ day, changes });
    });
  };
}

export class DaypartingScheduleDayModel extends DTO<DaypartingScheduleDayDTO> {
  constructor(dto: DaypartingScheduleDayDTO) {
    super(dto);
  }

  get day(): Weekday {
    return this.dto.day;
  }

  get changes(): DaypartingScheduleTimeModel[] {
    return this.dto.changes.map((time) => new DaypartingScheduleTimeModel(time));
  }

  set changes(updatedChanges: DaypartingScheduleTimeModel[]) {
    this.dto.changes = updatedChanges.map((change) => change.toDTO());
  }

  toDTO(): DaypartingScheduleDayDTO {
    return {
      day: this.day,
      changes: this.changes.map((c) => c.toDTO()),
    };
  }
}

export class DaypartingScheduleTimeModel extends DTO<DaypartingScheduleTimeDTO> {
  constructor(dto: DaypartingScheduleTimeDTO) {
    super(dto);
  }

  get time(): string {
    return this.dto.time;
  }

  get change(): number {
    return this.dto.change;
  }

  set change(newChange: number) {
    this.dto.change = newChange;
  }

  toDTO(): DaypartingScheduleTimeDTO {
    return {
      time: this.time,
      change: this.change,
    };
  }
}
