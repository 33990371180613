import { isEmpty } from 'lodash-es';

export enum ProfileState {
  ACTIVE = 'ACTIVE',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
}

export function getProfileState(state: string): ProfileState {
  switch (state) {
    case 'ACTIVE':
      return ProfileState.ACTIVE;
    case 'NOT_AUTHORIZED':
      return ProfileState.NOT_AUTHORIZED;
    default:
      console.error(`Invalid profile state : ${isEmpty(state) ? '<empty>' : state}`);
      return ProfileState.NOT_AUTHORIZED;
  }
}
