import { z } from 'zod';
import { DaypartingScheduleState, Weekday } from '../../api/dayparting.contracts';

const DaypartingScheduleTimeSchema = z.object({
  time: z.string(),
  change: z.number(),
});

const DaypartingScheduleDaySchema = z.object({
  day: z.nativeEnum(Weekday),
  changes: z.array(DaypartingScheduleTimeSchema),
});

export const DaypartingScheduleFormSchema = z.object({
  name: z.string().min(1, 'Name is required').max(255),
  state: z.nativeEnum(DaypartingScheduleState),
  days: z.array(DaypartingScheduleDaySchema),
  campaignIds: z.array(z.string()),
});

export const DaypartingScheduleCreateFormSchema = DaypartingScheduleFormSchema.extend({});

export type DaypartingScheduleFormModel = z.infer<typeof DaypartingScheduleFormSchema>;

export type DaypartingScheduleDayFormModel = z.infer<typeof DaypartingScheduleDaySchema>;
