import { ApiResponse } from '@/lib/api/api-response';
import { apiClient } from '@/lib/api/base-client';
import { DashboardReadDTO } from '@/modules/dashboards/api/dashboard/dashboard.contracts';
import { DashboardCalculatedDataDTO } from '@/modules/dashboards/api/dashboard/data/dashboard-data.contracts';
import { DashboardCalculatedDataModel } from '@/modules/dashboards/types/DashboardCalculatedDataModel';
import { DashboardModel } from '@/modules/dashboards/types/DashboardModel';
import { DashboardDateFiltersDTO } from './external-dashboard.contracts';

class ExternalDashboardService {
  public basePath = 'dashboards';

  async getByToken(token: string): Promise<ApiResponse<DashboardModel>> {
    try {
      const applicationResponse = await apiClient.get<DashboardReadDTO>(`${this.basePath}/token/${token}`);
      return applicationResponse.processPayload((payload) => {
        return new DashboardModel(payload);
      });
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getDataByToken(token: string, dates: DashboardDateFiltersDTO): Promise<ApiResponse<DashboardCalculatedDataModel>> {
    try {
      const applicationResponse = await apiClient.get<DashboardCalculatedDataDTO>(`${this.basePath}/token/${token}/data`, { ...dates });
      return applicationResponse.processPayload((payload) => {
        return new DashboardCalculatedDataModel(payload);
      });
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const externalDashboardService = new ExternalDashboardService();
