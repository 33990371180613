export enum UserSettingType {
  'STRING' = 'STRING',
  'NUMBER' = 'NUMBER',
  'BOOLEAN' = 'BOOLEAN',
  'JSON' = 'JSON',
}

// String can be either JSON or an actual String value
export type UserSettingValue = number | string | boolean | object;

export enum UserSettingKey {
  TEST_SETTING_ENABLED = 'TEST_SETTING_ENABLED',
  TEST_SETTING = 'TEST_SETTING',
  ACTIVE_PROFILE_ID = 'ACTIVE_PROFILE_ID',
  ACTIVE_TEAM_ID = 'ACTIVE_TEAM_ID',
  CAMPAIGN_TABLE_COLUMN_STATE = 'CAMPAIGN_TABLE_COLUMN_STATE',
  SELECTED_METRICS = 'SELECTED_METRICS',
  VISIBLE_METRICS = 'VISIBLE_METRICS',
  SELECTED_METRIC_COLORS = 'SELECTED_METRIC_COLORS',
  OPTIMIZATION_TABLE_COLUMN_STATE = 'OPTIMIZATION_TABLE_COLUMN_STATE',
  CAMPAIGN_GROUP_TABLE_COLUMN_STATE = 'CAMPAIGN_GROUP_TABLE_COLUMN_STATE',

  PROFILES_STATS_TABLE_COLUMN_STATE = 'PROFILES_STATS_TABLE_COLUMN_STATE',
  PROFILES_STATS_TABLE_SELLER_COLUMN_STATE = 'PROFILES_STATS_TABLE_SELLER_COLUMN_STATE',
  PROFILES_STATS_TABLE_VENDOR_COLUMN_STATE = 'PROFILES_STATS_TABLE_VENDOR_COLUMN_STATE',
  PROFILES_STATS_TABLE_SELLER_VENDOR_COLUMN_STATE = 'PROFILES_STATS_TABLE_SELLER_VENDOR_COLUMN_STATE',
  PROFILES_STATS_SELECTED_CURRENCY = 'PROFILES_STATS_SELECTED_CURRENCY',

  TEAMS_TABLE_COLUMN_STATE = 'TEAMS_TABLE_COLUMN_STATE',
  TARGETING_TABLE_COLUMN_STATE = 'TARGETING_TABLE_COLUMN_STATE',
  DATA_GROUPS_TABLE_COLUMN_STATE = 'DATA_GROUPS_TABLE_COLUMN_STATE',
  PLACEMENTS_TABLE_COLUMN_STATE = 'PLACEMENTS_TABLE_COLUMN_STATE',
  NEGATIVE_TARGETING_TABLE_COLUMN_STATE = 'NEGATIVE_TARGETING_TABLE_COLUMN_STATE',
  SEARCH_TERMS_TABLE_COLUMN_STATE = 'SEARCH_TERMS_TABLE_COLUMN_STATE',
  ADD_NEGATIVE_KEYWORDS_PREVIEW_TABLE_COLUMN_STATE = 'ADD_NEGATIVE_KEYWORDS_PREVIEW_TABLE_COLUMN_STATE',
  KEYWORD_HARVESTING_PREVIEW_COLUMN_STATE = 'KEYWORD_HARVESTING_PREVIEW_COLUMN_STATE',
  CAMPAIGN_MAPPING_TABLE_COLUMN_STATE = 'CAMPAIGN_MAPPING_TABLE_COLUMN_STATE',
  LOGS_TABLE_COLUMN_STATE = 'LOGS_TABLE_COLUMN_STATE',
  PROFILES_TABLE_COLUMN_STATE = 'PROFILES_TABLE_COLUMN_STATE',

  PRODUCTS_TABLE_COLUMN_STATE = 'PRODUCTS_TABLE_COLUMN_STATE',
  PRODUCTS_TABLE_SELLER_COLUMN_STATE = 'PRODUCTS_TABLE_SELLER_COLUMN_STATE',
  PRODUCTS_TABLE_VENDOR_COLUMN_STATE = 'PRODUCTS_TABLE_VENDOR_COLUMN_STATE',

  ADVERTISED_PRODUCTS_TABLE_COLUMN_STATE = 'ADVERTISED_PRODUCTS_TABLE_COLUMN_STATE',
  CAMPAIGN_MAPPING_TUTORIAL_DISPLAY_COUNT = 'CAMPAIGN_MAPPING_TUTORIAL_DISPLAY_COUNT',
  DATA_GROUPS_TUTORIAL_DISPLAY_COUNT = 'DATA_GROUPS_TUTORIAL_DISPLAY_COUNT',
  OPTIMIZATION_GROUPS_TUTORIAL_DISPLAY_COUNT = 'OPTIMIZATION_GROUPS_TUTORIAL_DISPLAY_COUNT',
  DAYPARTING_TUTORIAL_DISPLAY_COUNT = 'DAYPARTING_TUTORIAL_DISPLAY_COUNT',
  OPTIMIZATION_LOGS_TUTORIAL_DISPLAY_COUNT = 'OPTIMIZATION_LOGS_TUTORIAL_DISPLAY_COUNT',
  PLACEMENTS_TUTORIAL_DISPLAY_COUNT = 'PLACEMENTS_TUTORIAL_DISPLAY_COUNT',
  SEARCH_TERMS_TUTORIAL_DISPLAY_COUNT = 'SEARCH_TERMS_TUTORIAL_DISPLAY_COUNT',
  TARGETING_TUTORIAL_DISPLAY_COUNT = 'TARGETING_TUTORIAL_DISPLAY_COUNT',
  DASHBOARDS_TABLE_COLUMN_STATE = 'DASHBOARDS_TABLE_COLUMN_STATE',
  SEARCH_QUERY_PERFORMANCE_TABLE_COLUMN_STATE = 'SEARCH_QUERY_PERFORMANCE_TABLE_COLUMN_STATE',

  PRODUCTS_INFO_MESSAGE_DISPLAY_COUNT = 'PRODUCTS_INFO_MESSAGE_DISPLAY_COUNT',
  ADVERTISED_PRODUCTS_INFO_MESSAGE_DISPLAY_COUNT = 'ADVERTISED_PRODUCTS_INFO_MESSAGE_DISPLAY_COUNT',
  SELLING_PARTNER_NOT_CONNECTED_DISPLAY_COUNT = 'SELLING_PARTNER_NOT_CONNECTED_DISPLAY_COUNT',
  SQP_INFO_MESSAGE_DISPLAY_COUNT = 'SQP_INFO_MESSAGE_DISPLAY_COUNT',

  // VISIBLE/SELECTED/COLOR for metrics by context
  VISIBLE_METRICS_OPTIMIZER = 'VISIBLE_METRICS_OPTIMIZER',
  VISIBLE_METRICS_SEARCH_TERMS = 'VISIBLE_METRICS_SEARCH_TERMS',
  VISIBLE_METRICS_TARGETING = 'VISIBLE_METRICS_TARGETING',
  VISIBLE_METRICS_PLACEMENTS = 'VISIBLE_METRICS_PLACEMENTS',
  VISIBLE_METRICS_ADVERTISED_PRODUCTS = 'VISIBLE_METRICS_ADVERTISED_PRODUCTS',
  VISIBLE_METRICS_SEARCH_QUERY_PERFORMANCE = 'VISIBLE_METRICS_SEARCH_QUERY_PERFORMANCE',

  VISIBLE_METRICS_PROFILES_STATS = 'VISIBLE_METRICS_PROFILES_STATS',
  VISIBLE_METRICS_PROFILES_STATS_SELLER = 'VISIBLE_METRICS_PROFILES_STATS_SELLER',
  VISIBLE_METRICS_PROFILES_STATS_VENDOR = 'VISIBLE_METRICS_PROFILES_STATS_VENDOR',
  VISIBLE_METRICS_PROFILES_STATS_SELLER_VENDOR = 'VISIBLE_METRICS_PROFILES_STATS_SELLER_VENDOR',

  VISIBLE_METRICS_PRODUCTS = 'VISIBLE_METRICS_PRODUCTS',
  VISIBLE_METRICS_PRODUCTS_SELLER = 'VISIBLE_METRICS_PRODUCTS_SELLER',
  VISIBLE_METRICS_PRODUCTS_VENDOR = 'VISIBLE_METRICS_PRODUCTS_VENDOR',

  TARGETING_MANY_NO_STATS_MSG_CLOSED_AT = 'TARGETING_MANY_NO_STATS_MSG_CLOSED_AT',

  DAYPARTING_TABLE_COLUMN_STATE = 'DAYPARTING_TABLE_COLUMN_STATE',
}

export type SettingValue = number | string | boolean | object;

export interface UserSettingReadDTO {
  id: number;
  key: UserSettingKey;
  type: UserSettingType;
  value: UserSettingValue;
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export interface UserSettingUpdateDTO {
  key: UserSettingKey;
  type: UserSettingType;
  value: UserSettingValue;
}
