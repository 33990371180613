import { useEffect, useState } from 'react';
import { AlFilterModel } from '../models/AlFilterModel';
import { FilterErrorCode } from '../types/FilterErrorCodes';
import { FilterKey } from '../types/FilterKey';
import { getFilterErrorCodes } from './useFilters';

interface UseFilterErrorHandlingProps {
  pendingFilters: AlFilterModel[];
}

const useFilterErrorHandling = ({ pendingFilters }: UseFilterErrorHandlingProps) => {
  const [filterToErrorsRecord, setFilterToErrorsRecord] = useState<Record<FilterKey, Set<FilterErrorCode>>>(
    {} as Record<FilterKey, Set<FilterErrorCode>>,
  );
  const errorCount = Object.keys(filterToErrorsRecord).length;
  const hasErrors = errorCount !== 0;

  useEffect(() => {
    if (!hasErrors) return;
    // A validation check is made at least once

    const oldErrors = { ...filterToErrorsRecord }; // shallow clone

    // Check if the errored filter has been removed, if so, remove them from the old error record
    const pendingFilterKeySet = new Set(pendingFilters.map((f) => f.key));
    const oldErrorKeys = Object.keys(filterToErrorsRecord);
    oldErrorKeys.forEach((key) => {
      const typedKey = key as FilterKey;
      if (!pendingFilterKeySet.has(typedKey)) {
        delete oldErrors[typedKey];
      }
    });

    // Only set the new errors on filters that had errors, else when adding a new filter to pending, that seemingly immediately gets validated
    // Will still validate all, but pick out the relevant ones
    const newFilterToErrorRecord = getFilterErrorCodes(pendingFilters);
    const newErrorKeys = Object.keys(newFilterToErrorRecord);
    newErrorKeys.forEach((key) => {
      if (!(key in oldErrors)) {
        delete newFilterToErrorRecord[key as FilterKey];
      }
    });

    setFilterToErrorsRecord(newFilterToErrorRecord);
  }, [pendingFilters]);
  return {
    filterToErrorsRecord,
    setFilterToErrorsRecord,
    hasErrors,
    errorCount,
  };
};

export default useFilterErrorHandling;
