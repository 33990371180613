import AlGrid, { DEFAULT_GRID_OPTIONS } from '@/components/grid/AlGrid';
import { ErrorWarningWrapperCellRenderer } from '@/components/grid/cells/ErrorWarningWrapperCellRenderer';
import { ITextCellRendererParams, TextCellRenderer } from '@/components/grid/cells/TextCellRenderer';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import { AlColDef } from '@/components/grid/types';
import { useTranslation } from '@/lib/i18n/useTranslate';
import { getErrorMessage } from '@/modules/application/utils';
import useCampaignToAdGroupsMappingData from '@/modules/campaign-mapping/hooks/useCampaignToAdGroupsMappingData';
import { CampaignToCampaignDataWithCampaignMappingAdGroupDataType } from '@/modules/campaign-mapping/models/CampaignMappingModel';
import { CreateImportModel } from '@/modules/optimizer/api/campaign/models/CreateImportModel';
import { OptimizationPreset } from '@/modules/optimizer/components/optimization/OptimizerConfig';
import { toastService } from '@/services/toast.service';
import { EditNote } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import type {
  CellClassParams,
  CellValueChangedEvent,
  ColDef,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  ValueFormatterParams,
  ValueParserParams,
} from 'ag-grid-community';
import { isEmpty, isNil, round } from 'lodash-es';
import { FunctionComponent, useEffect, useMemo, useRef } from 'react';
import { getCellClass, getErrorWarningCellRendererParams } from '../../helpers';

const VISIBLE_ABOVE_PX_ON_SCROLL_DOWN = 140;

interface CampaignGroupsImportPreviewTableProps {
  rowData: CreateImportModel[];
  isLoading: boolean;
  onGridDataChanged: (api: GridApi<CreateImportModel>) => void;
  onGridReadyCallback: (params: GridReadyEvent) => void;
}

interface CampaignMappingGridContext {
  campaignToAdGroupsMap: CampaignToCampaignDataWithCampaignMappingAdGroupDataType;
}

const CampaignGroupsCreatePreviewTable: FunctionComponent<CampaignGroupsImportPreviewTableProps> = ({
  rowData: rowData,
  isLoading,
  onGridDataChanged,
  onGridReadyCallback,
}) => {
  const gridApiRef = useRef<GridApi<CreateImportModel>>();
  const gridContextRef = useRef<CampaignMappingGridContext>();

  const { t } = useTranslation();
  const theme = useTheme();

  const { campaignToAdGroupsMap } = useCampaignToAdGroupsMappingData();

  const columnDefs: ColDef<CreateImportModel>[] = useMemo(() => {
    const colDefs: AlColDef<CreateImportModel>[] = [
      {
        colId: ColumnId.GROUP_NAME,
        headerName: 'Optimization Group Name',
        field: 'name',
        width: 350,
        editable: false, // name needs to be fixed for assignment to work
        cellRenderer: TextCellRenderer,
        cellRendererParams: (params: ICellRendererParams<CreateImportModel>): ITextCellRendererParams => {
          return {
            iconTooltip: 'Modifying existing group',
            icon: params.data?.isExistingName ? <EditNote style={{ fontSize: '16px', color: theme.palette.info.main }} /> : undefined,
            textLabel: params.value,
          };
        },
      },
      {
        colId: ColumnId.TARGET_ACOS,
        headerName: 'Target ACOS',
        field: 'tacos',
        width: 100,
        cellClass: (params: CellClassParams<CreateImportModel>) => {
          return getCellClass(params) + (isEmpty(params.value) || isNil(params.value) ? ' text-gray-400' : '');
        },
        editable: true,
        valueFormatter: (params: ValueFormatterParams<CreateImportModel>) => {
          // Since it's all user inputted, dealing with whole numbers and expecting to convert it into fractions in a later step

          // Don't show % if invalid
          if (params.data?.validationErrors[(params.colDef?.field as keyof CreateImportModel) ?? '']) {
            return params.value;
          }

          return params.value ? params.value + '%' : 'Not Set';
        },
        cellRenderer: ErrorWarningWrapperCellRenderer,
        cellRendererParams: getErrorWarningCellRendererParams,
        valueParser: (params: ValueParserParams<CreateImportModel>) => {
          if (params.newValue == '' || isNil(params.newValue)) {
            return null;
          }

          try {
            const newValue = round(parseFloat(params.newValue), 2);

            if (isNaN(newValue)) {
              toastService.error('Invalid new value entered: ' + params.newValue);
              return params.oldValue;
            }

            return newValue;
          } catch (e: unknown) {
            toastService.error(getErrorMessage(e));
          }

          // Revert to old when invalid
          return params.oldValue;
        },
      },
      {
        colId: ColumnId.GROUP_PRESET,
        headerName: 'Prioritization',
        field: 'prioritization',
        cellClass: (params: CellClassParams<CreateImportModel>) => {
          return getCellClass(params) + (params.value == OptimizationPreset.NOT_SET ? ' text-gray-400' : '');
        },
        width: 200,
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: [
            OptimizationPreset.NOT_SET,
            OptimizationPreset.BALANCED,
            OptimizationPreset.REDUCE_ACOS,
            OptimizationPreset.INCREASE_SALES,
          ],
        },
        cellRenderer: ErrorWarningWrapperCellRenderer,
        cellRendererParams: getErrorWarningCellRendererParams,
        valueFormatter: (params: ValueFormatterParams<CreateImportModel>) => {
          const hasTranslation = Object.values(OptimizationPreset).includes(params.value as OptimizationPreset);
          return hasTranslation ? t(`enums.optimization_presets.${params.value}`) : params.value;
        },
      },
    ];

    return colDefs;
  }, []);

  useEffect(() => {
    if (!gridApiRef.current || !gridContextRef.current || Object.keys(campaignToAdGroupsMap).length === 0) return;
    gridContextRef.current.campaignToAdGroupsMap = campaignToAdGroupsMap;
    // No cell refreshing needed because default add new map row is anyway empty and so no data from map is used on load
  }, [campaignToAdGroupsMap]);

  function onGridDataChangedInternal(api: GridApi<CreateImportModel>) {
    onGridDataChanged(api);
  }

  const customGridOptions: GridOptions<CreateImportModel> = useMemo(() => {
    const defaultCampaignMappingGridContext: CampaignMappingGridContext = {
      campaignToAdGroupsMap,
    };
    return {
      ...DEFAULT_GRID_OPTIONS,
      context: defaultCampaignMappingGridContext,
      suppressMovableColumns: true,
      getRowId: (params) => params.data.id?.toString() ?? '',
      maintainColumnOrder: true,
      onCellValueChanged: (event: CellValueChangedEvent<CreateImportModel>) => onGridDataChangedInternal(event.api),
      onFirstDataRendered: (event: FirstDataRenderedEvent<CreateImportModel>) => onGridDataChangedInternal(event.api),
    };
  }, []);

  function onGridReady(params: GridReadyEvent) {
    gridApiRef.current = params.api;

    gridContextRef.current = params.context;
    onGridReadyCallback(params);
  }

  return (
    <>
      <div style={{ height: `calc(100vh - ${VISIBLE_ABOVE_PX_ON_SCROLL_DOWN}px)` }}>
        <AlGrid
          colDefs={columnDefs}
          rowData={rowData && !isEmpty(campaignToAdGroupsMap) ? rowData : []}
          gridOptions={customGridOptions}
          isLoading={isLoading}
          onGridReadyCallback={onGridReady}
          noTopBorderRadius={false}
          fitToResizeEnabled={false}
          addExtraBottomPadding={true} // TODO: standardize padding under the table so action bar appears always at the same height relative to the table bottom edge
        />
      </div>
    </>
  );
};

export default CampaignGroupsCreatePreviewTable;
