import { FormControl, FormHelperText, Typography } from '@mui/material';
import { isNil } from 'lodash-es';
import { FunctionComponent, useEffect, useState } from 'react';
import EditableText from '../form/EditableText';
import { SavedFilterLevel } from './api/filters-contracts';
import { FilterPresetModel } from './models/FilterPresetModel';

interface FilterBuilderTitleProps {
  selectedPreset: FilterPresetModel | 'new' | null;
  existingNames: string[];
  handleSave: (name: string, level: SavedFilterLevel, createNew: boolean) => Promise<void>;
  isExistingPresetModified: boolean;
  showSavedFilterComponents: boolean;
}

const FilterBuilderTitle: FunctionComponent<FilterBuilderTitleProps> = ({
  selectedPreset,
  existingNames,
  handleSave,
  isExistingPresetModified,
  showSavedFilterComponents,
}) => {
  // Title and in-place name editing
  const [nameErrorText, setNameErrorText] = useState<string | null>(null);
  function onNameChange(newName: string) {
    if (isNil(selectedPreset) || selectedPreset === 'new') return;

    const name = newName.trim();

    if (name === '') {
      return;
    }

    if (existingNames.includes(name) && name !== selectedPreset.name) {
      setNameErrorText('This name already exists');
      return;
    }

    if (name !== selectedPreset.name) {
      handleSave(name, selectedPreset.level, false);
    }
  }

  // Reset name error on selected preset change
  useEffect(() => {
    setNameErrorText(null);
  }, [isNil(selectedPreset) || selectedPreset === 'new' ? null : selectedPreset?.name]);

  const presetTitle =
    showSavedFilterComponents && selectedPreset !== 'new' && !isNil(selectedPreset) ? (
      <span className="flex flex-row items-end gap-1">
        <FormControl fullWidth error={!!nameErrorText}>
          <EditableText
            text={selectedPreset.name}
            onTextChange={onNameChange}
            className="normal-case underline underline-offset-2 decoration-dotted decoration-transparent hover:decoration-gray-600 focus:decoration-gray-600"
          />
          <FormHelperText className="ml-0 mt-0">{nameErrorText}</FormHelperText>
        </FormControl>
        {isExistingPresetModified && (
          <Typography gutterBottom variant="caption">
            (modified)
          </Typography>
        )}
      </span>
    ) : (
      <span className="mt-2">Filters</span>
    );

  return (
    <Typography variant="h6" className="mb-1 flex items-center justify-center">
      {presetTitle}
    </Typography>
  );
};

export default FilterBuilderTitle;
