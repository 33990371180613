import { ButtonBase, Radio } from '@mui/material';
import { FunctionComponent } from 'react';

interface TemplateButtonProps {
  title: string;
  description: string;
  isSelected: boolean;
  onClick: () => void;
}

const TemplateButton: FunctionComponent<TemplateButtonProps> = ({ title, description, isSelected, onClick }) => {
  return (
    <ButtonBase
      onClick={onClick}
      className={`flex h-full w-full flex-row items-start justify-start rounded-lg p-2 ${isSelected ? 'border-primary text-primary border-2 bg-primary-500 bg-opacity-20' : 'hover:text-primary active:bg-primary border border-transparent bg-gray-300 bg-opacity-30 text-black hover:bg-primary-500 hover:bg-opacity-10 active:bg-opacity-20'} transition-all`}
    >
      <div className="mb-1 flex h-full items-center text-2xl">
        <Radio checked={isSelected} />
      </div>
      <div className="ml-2 flex flex-col items-start self-center">
        <span className="mb-1 font-semibold">{title}</span>
        <div className="text-left text-xs">{description}</div>
      </div>
    </ButtonBase>
  );
};

export default TemplateButton;
