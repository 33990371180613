export enum PlanType {
  FREE_PLAN = 'FREE_PLAN',
  PRO_PLAN = 'PRO_PLAN',
}

export enum PlanPriceLookupKey {
  FREE_MONTHLY = 'free_monthly',
  PRO_MONTHLY = 'pro_monthly',
  PRO_AD_SPEND_DYNAMIC = 'pro_ad_spend_dynamic',
  PRO_AD_SPEND_FLAT_FEE = 'pro_ad_spend_flat_fee',
  PRO_PROFILE_BASED_80 = 'pro_profile_based_80',
  PRO_PROFILE_BASED_120 = 'pro_profile_based_120',
  PRO_PROFILE_BASED_250 = 'pro_profile_based_250',
  PRO_BRAND_BASED_40 = 'pro_brand_based_40',
  PRO_BRAND_BASED_60 = 'pro_brand_based_60',
  PRO_BRAND_BASED_250 = 'pro_brand_based_250',
  PRO_BRAND_BASED_300 = 'pro_brand_based_300',
  PRO_FIXED_PRICE = 'pro_fixed_price',
}

export interface PlanCapabilities {
  name: string;
  canScheduleUpdateTime: boolean;
  campaignSelectionLimit: number;
  dateRangeLengthLimitInDays: number;
  updateIntervalLimitMinutes: number;
  maxProfiles: number;
  /**
   * Can always click sync button, even if the profile was synced recently, and the plan restricts it.
   * This allows us to show the paywall when the user clicks sync.
   */
  canAlwaysClickSync: boolean;
  canOptimizeSBAndSDCampaigns: boolean;
  canAssignDataGroups: boolean;
  canUseTargetingPage: boolean;
  canUsePlacementsPage: boolean;
  canUseNegativeTargetingPage: boolean;
  canUseSearchTermsPage: boolean;
  canUseCampaignMappingPage: boolean;
  canUseProductsPage: boolean;
  canUseAdvertisedProductsPage: boolean;
  canAuthorizeVendorCentral: boolean;
  canAuthorizeSellerCentral: boolean;
  canUseDayparting: boolean;
  canUseSearchQueryPerformancePage: boolean;
}

export const FREE_PLAN_CAPABILITIES: PlanCapabilities = {
  name: 'Free plan',
  canScheduleUpdateTime: true, // free accounts can also change the update time
  campaignSelectionLimit: 99999999,
  dateRangeLengthLimitInDays: 30,
  updateIntervalLimitMinutes: 1440, // 24h -  Keep this in sync with API
  maxProfiles: 3,
  canAlwaysClickSync: true,
  canOptimizeSBAndSDCampaigns: false,
  canAssignDataGroups: false,
  canUseTargetingPage: false,
  canUsePlacementsPage: false,
  canUseNegativeTargetingPage: false,
  canUseSearchTermsPage: false,
  canUseCampaignMappingPage: false,
  canUseProductsPage: false,
  canUseAdvertisedProductsPage: false,
  canAuthorizeVendorCentral: false,
  canAuthorizeSellerCentral: false,
  canUseDayparting: false,
  canUseSearchQueryPerformancePage: false,
};

export const LEGACY_PRO_PLAN_CAPABILITIES: PlanCapabilities = {
  name: 'Pro plan',
  canScheduleUpdateTime: true,
  campaignSelectionLimit: 99999999,
  dateRangeLengthLimitInDays: 99999999,
  updateIntervalLimitMinutes: 60, // 1h - Keep this in sync with API
  maxProfiles: 99999999,
  canAlwaysClickSync: false,
  canOptimizeSBAndSDCampaigns: true,
  canAssignDataGroups: true,
  canUseTargetingPage: true,
  canUsePlacementsPage: true,
  canUseNegativeTargetingPage: false,
  canUseSearchTermsPage: false,
  canUseCampaignMappingPage: false,
  canUseProductsPage: false,
  canUseAdvertisedProductsPage: false,
  canAuthorizeVendorCentral: false,
  canAuthorizeSellerCentral: false,
  canUseDayparting: false,
  canUseSearchQueryPerformancePage: false,
};
const PRO_PROFILE_BASED_80_PRO_PLAN_CAPABILITIES: PlanCapabilities = {
  name: 'Pro plan',
  canScheduleUpdateTime: true,
  campaignSelectionLimit: 99999999,
  dateRangeLengthLimitInDays: 99999999,
  updateIntervalLimitMinutes: 60, // 1h - Keep this in sync with API
  maxProfiles: 99999999,
  canAlwaysClickSync: false,
  canOptimizeSBAndSDCampaigns: true,
  canAssignDataGroups: true,
  canUseTargetingPage: true,
  canUsePlacementsPage: true,
  canUseNegativeTargetingPage: true,
  canUseSearchTermsPage: true,
  canUseCampaignMappingPage: true,
  canUseProductsPage: true,
  canUseAdvertisedProductsPage: true,
  canAuthorizeVendorCentral: true,
  canAuthorizeSellerCentral: true,
  canUseDayparting: true,
  canUseSearchQueryPerformancePage: true,
};

const PRO_PLAN_CAPABILITIES: PlanCapabilities = {
  name: 'Pro plan',
  canScheduleUpdateTime: true,
  campaignSelectionLimit: 99999999,
  dateRangeLengthLimitInDays: 99999999,
  updateIntervalLimitMinutes: 60, // 1h - Keep this in sync with API
  maxProfiles: 99999999,
  canAlwaysClickSync: false,
  canOptimizeSBAndSDCampaigns: true,
  canAssignDataGroups: true,
  canUseTargetingPage: true,
  canUsePlacementsPage: true,
  canUseNegativeTargetingPage: true,
  canUseSearchTermsPage: true,
  canUseCampaignMappingPage: true,
  canUseProductsPage: true,
  canUseAdvertisedProductsPage: true,
  canAuthorizeVendorCentral: true,
  canAuthorizeSellerCentral: true,
  canUseDayparting: true,
  canUseSearchQueryPerformancePage: true,
};

export const ADLABS_PLAN_CAPABILITIES_MAP: Record<PlanType, PlanCapabilities> = {
  [PlanType.FREE_PLAN]: FREE_PLAN_CAPABILITIES,
  [PlanType.PRO_PLAN]: PRO_PLAN_CAPABILITIES,
};

export const ADLABS_PLAN_PRICE_LOOKUP_KEY_CAPABILITIES_MAP: Record<PlanPriceLookupKey, PlanCapabilities> = {
  [PlanPriceLookupKey.FREE_MONTHLY]: FREE_PLAN_CAPABILITIES,
  [PlanPriceLookupKey.PRO_MONTHLY]: LEGACY_PRO_PLAN_CAPABILITIES,
  [PlanPriceLookupKey.PRO_AD_SPEND_DYNAMIC]: PRO_PLAN_CAPABILITIES,
  [PlanPriceLookupKey.PRO_AD_SPEND_FLAT_FEE]: PRO_PLAN_CAPABILITIES,
  [PlanPriceLookupKey.PRO_PROFILE_BASED_80]: PRO_PROFILE_BASED_80_PRO_PLAN_CAPABILITIES,
  [PlanPriceLookupKey.PRO_PROFILE_BASED_120]: PRO_PLAN_CAPABILITIES,
  [PlanPriceLookupKey.PRO_PROFILE_BASED_250]: PRO_PLAN_CAPABILITIES,
  [PlanPriceLookupKey.PRO_BRAND_BASED_40]: PRO_PLAN_CAPABILITIES,
  [PlanPriceLookupKey.PRO_BRAND_BASED_60]: PRO_PLAN_CAPABILITIES,
  [PlanPriceLookupKey.PRO_BRAND_BASED_250]: PRO_PLAN_CAPABILITIES,
  [PlanPriceLookupKey.PRO_BRAND_BASED_300]: PRO_PLAN_CAPABILITIES,
  [PlanPriceLookupKey.PRO_FIXED_PRICE]: PRO_PLAN_CAPABILITIES,
};
