import { ColumnId } from '@/components/grid/columns/columns.enum';
import type { ColumnState } from 'ag-grid-enterprise';

export function generateSearchQueryPerformanceTableColumnState(): ColumnState[] {
  return [
    { colId: ColumnId.TITLE, hide: false, width: 390, pinned: 'left' },
    { colId: ColumnId.ASIN, hide: true, width: 116, pinned: 'left' },
    { colId: ColumnId.SEARCH_QUERY, hide: false, width: 237, pinned: 'left' },
    { colId: ColumnId.SEARCH_QUERY_SCORE, hide: false, width: 101 },
    { colId: ColumnId.EXISTING_MATCH_TYPES, hide: false, width: 110, pinned: 'left' },
    { colId: ColumnId.SEARCH_QUERY_VOLUME, hide: false, width: 112 },

    { colId: ColumnId.TOTAL_QUERY_IMPRESSION_COUNT, hide: false, width: 106 },
    { colId: ColumnId.ASIN_IMPRESSION_COUNT, hide: false, width: 130 },
    { colId: ColumnId.ASIN_IMPRESSION_SHARE, hide: false, width: 130 },

    { colId: ColumnId.TOTAL_CLICK_COUNT, hide: false, width: 92 },
    { colId: ColumnId.TOTAL_CLICK_RATE, hide: false, width: 106 },
    { colId: ColumnId.ASIN_CLICK_COUNT, hide: false, width: 92 },
    { colId: ColumnId.ASIN_CLICK_SHARE, hide: false, width: 92 },
    { colId: ColumnId.TOTAL_CTR, hide: false, width: 84 },
    { colId: ColumnId.ASIN_CTR, hide: false, width: 84 },

    { colId: ColumnId.ASIN_CONVERSION_RATE, hide: false, width: 119 },
    { colId: ColumnId.TOTAL_CONVERSION_RATE, hide: false, width: 117 },

    { colId: ColumnId.TOTAL_CART_ADD_COUNT, hide: true, width: 92 },
    { colId: ColumnId.TOTAL_CART_ADD_RATE, hide: true, width: 92 },
    { colId: ColumnId.ASIN_CART_ADD_COUNT, hide: true, width: 92 },
    { colId: ColumnId.ASIN_CART_ADD_SHARE, hide: true, width: 92 },

    { colId: ColumnId.ASIN_PURCHASE_COUNT, hide: false, width: 119 },
    { colId: ColumnId.ASIN_PURCHASE_SHARE, hide: false, width: 119 },
    { colId: ColumnId.TOTAL_PURCHASE_COUNT, hide: false, width: 117 },
    { colId: ColumnId.TOTAL_PURCHASE_RATE, hide: false, width: 117 },

    { colId: ColumnId.TOTAL_MEDIAN_CLICK_PRICE, hide: true, width: 83 },
    { colId: ColumnId.ASIN_MEDIAN_CLICK_PRICE, hide: true, width: 83 },
    { colId: ColumnId.TOTAL_SAME_DAY_SHIPPING_CLICK_COUNT, hide: true, width: 94 },
    { colId: ColumnId.TOTAL_ONE_DAY_SHIPPING_CLICK_COUNT, hide: true, width: 83 },
    { colId: ColumnId.TOTAL_TWO_DAY_SHIPPING_CLICK_COUNT, hide: true, width: 83 },
    { colId: ColumnId.TOTAL_MEDIAN_CART_ADD_PRICE, hide: true, width: 83 },
    { colId: ColumnId.ASIN_MEDIAN_CART_ADD_PRICE, hide: true, width: 83 },
    { colId: ColumnId.TOTAL_SAME_DAY_SHIPPING_CART_ADD_COUNT, hide: true, width: 94 },
    { colId: ColumnId.TOTAL_ONE_DAY_SHIPPING_CART_ADD_COUNT, hide: true, width: 83 },
    { colId: ColumnId.TOTAL_TWO_DAY_SHIPPING_CART_ADD_COUNT, hide: true, width: 83 },
    { colId: ColumnId.TOTAL_MEDIAN_PURCHASE_PRICE, hide: true, width: 75 },
    { colId: ColumnId.ASIN_MEDIAN_PURCHASE_PRICE, hide: true, width: 68 },
    { colId: ColumnId.TOTAL_SAME_DAY_SHIPPING_PURCHASE_COUNT, hide: true, width: 97 },
    { colId: ColumnId.TOTAL_ONE_DAY_SHIPPING_PURCHASE_COUNT, hide: true, width: 83 },
    { colId: ColumnId.TOTAL_TWO_DAY_SHIPPING_PURCHASE_COUNT, hide: true, width: 84 },


  ];
}
