export enum Weekday {
  Monday = 'MON',
  Tuesday = 'TUE',
  Wednesday = 'WED',
  Thursday = 'THU',
  Friday = 'FRI',
  Saturday = 'SAT',
  Sunday = 'SUN',
}

export enum DaypartingScheduleState {
  Enabled = 'ENABLED',
  Paused = 'PAUSED',
  Error = 'ERROR',
}

export interface DaypartingCreateDTO {
  name: string;
  days: DaypartingScheduleDayDTO[];
}

export interface DaypartingUpdateDTO {
  schedule_id: number;
  name?: string;
  state?: DaypartingScheduleState;
  days?: DaypartingScheduleDayDTO[]; // Full days must be given. Days not given are not changed.
}

export interface DaypartingAssignDTO {
  assignments: DaypartingScheduleAssignmentDTO[];
}

export interface DaypartingAssignDeleteDTO {
  campaign_ids: string[];
}

export interface DaypartingDeleteDTO {
  schedule_id: number;
}

export interface DaypartingScheduleDTO {
  id: number;
  name: string;
  state: DaypartingScheduleState;
  created_by: string;
  campaign_ids: string[];
  created_at: string;
  updated_at: string;
  days: DaypartingScheduleDayDTO[];
}

export interface DaypartingScheduleDayDTO {
  day: Weekday; // Enum for weekdays (Monday, Tuesday, etc.)
  changes: DaypartingScheduleTimeDTO[];
}

export interface DaypartingScheduleTimeDTO {
  time: string; // e.g., "08:00"
  change: number; // e.g., 20 for +20%, -10 for -10%
}

export interface DaypartingScheduleAssignmentDTO {
  schedule_id: number;
  campaign_ids: string[];
}
