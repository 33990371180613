import { useMemo } from 'react';
import { BiddingEntity } from '../components/optimization/models/OptimizationModel';
import { OptimizationApplyData } from '../components/optimization/OptimizerConfig';

interface ApplyOptimizationsInfo {
  warnings: string[];
  keywordCount: number;
  productTargetCount: number;
  placementCount: number;
}

interface UseCampaignWarningsProps {
  selectedItems: OptimizationApplyData[];
}

const useOptimizationApplyWarnings = ({ selectedItems }: UseCampaignWarningsProps) => {
  const preApplyInfo: ApplyOptimizationsInfo = useMemo(() => {
    const warningMessages: string[] = [];

    // Keywords
    const keywords = selectedItems.filter((selectedItem) => selectedItem.bidding_entity === BiddingEntity.KEYWORD);
    const keywordCount = keywords.length;
    const manuallyEditedKeywordsCount = keywords.filter((selectedItem) => selectedItem.new_value != selectedItem.algo_value).length;
    const keywordsWithSignificantChangeCount = keywords.filter((selectedItem) => {
      return isSignificantChange(selectedItem);
    }).length;

    // Product Targets
    const productTargets = selectedItems.filter((selectedItem) => selectedItem.bidding_entity === BiddingEntity.PRODUCT_TARGET);
    const productTargetCount = productTargets.length;
    const manuallyEditedProductTargetsCount = productTargets.filter(
      (selectedItem) => selectedItem.new_value != selectedItem.algo_value,
    ).length;
    const productTargetsWithSignificantChangeCount = productTargets.filter((selectedItem) => {
      return isSignificantChange(selectedItem);
    }).length;

    // Placements
    const placements = selectedItems.filter(
      (selectedItem) => selectedItem.bidding_entity !== BiddingEntity.KEYWORD && selectedItem.bidding_entity !== BiddingEntity.PRODUCT_TARGET,
    );
    const placementCount = placements.length;
    const manuallyEditedPlacementsCount = placements.filter((selectedItem) => selectedItem.new_value != selectedItem.algo_value).length;
    const placementsWithSignificantChangeCount = placements.filter((selectedItem) => {
      return isSignificantChange(selectedItem);
    }).length;

    // Warnings
    const totalCountParts = [];
    if (keywordCount > 0) {
      totalCountParts.push(`${keywordCount} keyword${keywordCount > 1 ? 's' : ''}`);
    }

    if (productTargetCount > 0) {
      totalCountParts.push(`${productTargetCount} product target${productTargetCount > 1 ? 's' : ''}`);
    }

    if (placementCount > 0) {
      totalCountParts.push(`${placementCount} placement${placementCount > 1 ? 's' : ''}`);
    }

    if (totalCountParts.length > 0) {
      warningMessages.push(`Optimize ${totalCountParts.join(totalCountParts.length > 2 ? ', ' : ' and ')}`);
    }

    // Manually edited warnings
    const manualEditWarningParts = [];

    if (manuallyEditedKeywordsCount > 0) {
      manualEditWarningParts.push(`${manuallyEditedKeywordsCount} keyword${manuallyEditedKeywordsCount > 1 ? 's' : ''}`);
    }

    if (manuallyEditedProductTargetsCount > 0) {
      manualEditWarningParts.push(`${manuallyEditedProductTargetsCount} product target${manuallyEditedProductTargetsCount > 1 ? 's' : ''}`);
    }

    if (manuallyEditedPlacementsCount > 0) {
      manualEditWarningParts.push(`${manuallyEditedPlacementsCount} placement${manuallyEditedPlacementsCount > 1 ? 's' : ''}`);
    }

    if (manualEditWarningParts.length > 0) {
      warningMessages.push(`Manually adjusted ${manualEditWarningParts.join(manualEditWarningParts.length > 2 ? ', ' : ' and ')}`);
    }

    // Significant change part
    const significantChangeWarningParts = [];

    if (keywordsWithSignificantChangeCount > 0) {
      significantChangeWarningParts.push(`${keywordsWithSignificantChangeCount} keyword${keywordsWithSignificantChangeCount > 1 ? 's' : ''}`);
    }

    if (productTargetsWithSignificantChangeCount > 0) {
      significantChangeWarningParts.push(
        `${productTargetsWithSignificantChangeCount} product target${productTargetsWithSignificantChangeCount > 1 ? 's' : ''}`,
      );
    }

    if (placementsWithSignificantChangeCount > 0) {
      significantChangeWarningParts.push(
        `${placementsWithSignificantChangeCount} placement${placementsWithSignificantChangeCount > 1 ? 's' : ''}`,
      );
    }

    if (significantChangeWarningParts.length > 0) {
      warningMessages.push(
        `Adjustments of ${significantChangeWarningParts.join(significantChangeWarningParts.length > 2 ? ', ' : ' and ')} are more than 200%`,
      );
    }

    return { warnings: warningMessages, keywordCount, productTargetCount, placementCount };
  }, [selectedItems]);

  return { ...preApplyInfo };
};

const SIGNIFICANT_CHANGE_PERCENTAGE = 200;
function isSignificantChange(selectedItem: OptimizationApplyData) {
  const newValue = selectedItem.new_value;
  const oldValue = selectedItem.old_value;

  // In case of placements we need to check if the change is over 200 percentage points
  if (selectedItem.bidding_entity !== BiddingEntity.KEYWORD && selectedItem.bidding_entity !== BiddingEntity.PRODUCT_TARGET) {
    return Math.abs(newValue - oldValue) * 100 > SIGNIFICANT_CHANGE_PERCENTAGE;
  }
  return Math.abs(((newValue - oldValue) / oldValue) * 100) > SIGNIFICANT_CHANGE_PERCENTAGE;
}

export default useOptimizationApplyWarnings;
