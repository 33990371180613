import MultipleMetricSelect from '@/components/form/MultipleMetricSelect';
import {
  CommonMetricField,
  CommonWithSellerAndVendorMetricField,
  MetricField,
  SellerMetricField,
  VendorMetricField,
} from '@/components/metrics/types/MetricField';
import { useTranslation } from '@/lib';
import { useDashboardContextValue } from '@/modules/dashboards/contexts/DashboardContextProvider';
import { EntityType } from '@/modules/dashboards/types/EntityType';
import { Typography } from '@mui/material';
import { cloneDeep } from 'lodash-es';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { DashboardWidgetContext } from '../../dashboard-widget/DashboardWidgetContextProvider';
import DashboardWidgetRichTextTitleEditor from '../../dashboard-widget/DashboardWidgetRichTextTitleEditor';
import EntityTypeSelect from '../../forms/EntityTypeSelect';
import GroupByTimeSelect, { GroupByTimeSelectOption } from '../../forms/GroupByTimeSelect';
import ProfileSelect from '../../forms/ProfileSelect';
import { ComboGraphWidgetConfiguration } from './ComboGraphWidgetConfiguration';
import ComboGraphWidgetFilters from './ComboGraphWidgetFilters';

interface ComboGraphWidgetConfigurationFormProps {
  configuration: ComboGraphWidgetConfiguration;
  id: string;
}

const ComboGraphWidgetConfigurationForm: FunctionComponent<ComboGraphWidgetConfigurationFormProps> = ({ configuration }) => {
  const { setModifiedConfiguration } = useContext(DashboardWidgetContext);
  const dashboardProfiles = useDashboardContextValue((context) => context.dashboard?.teamProfiles);
  const { t } = useTranslation();
  const [localConfiguration, setLocalConfiguration] = useState<ComboGraphWidgetConfiguration>(cloneDeep(configuration));

  useEffect(() => {
    localConfiguration.isConfigured = true;
    setModifiedConfiguration(cloneDeep(localConfiguration));
  }, [localConfiguration, setModifiedConfiguration]);

  const handleTitleChange = (html: string) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.title = html || '';
      return newConfig;
    });
  };

  const handleGroupByTimeChange = (groupByTime: GroupByTimeSelectOption) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.groupByTime = groupByTime.value;
      return newConfig;
    });
  };

  const handleSelectedMetricsChange = (selectedMetrics: MetricField[]) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.selectedMetrics = selectedMetrics as CommonWithSellerAndVendorMetricField[];
      return newConfig;
    });
  };

  const handleEntityTypeChange = (entityType: EntityType | '') => {
    if (entityType === '') return;
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.entityType = entityType;
      return newConfig;
    });
  };

  const availableMetrics = new Map<string, MetricField[]>([[t('ad_metrics'), Object.values(CommonMetricField)]]);

  if (localConfiguration.entityType === EntityType.PROFILE || localConfiguration.entityType === EntityType.PRODUCT) {
    if (dashboardProfiles?.some((p) => p.isSellerCentralConnected)) {
      availableMetrics.set(t('total_metrics'), Object.values(SellerMetricField));
    }

    if (dashboardProfiles?.some((p) => p.isVendorCentralConnected)) {
      availableMetrics.set(t('total_metrics'), Object.values(VendorMetricField));
    }
  }

  const onSelectedProfilesChanged = (selectedProfileIds: string[]) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.profilesIds = selectedProfileIds;
      return newConfig;
    });
  };

  // NB! combo graph widget does not support profile type
  // It does not make any sense to group by at profile level

  return (
    <>
      <EntityTypeSelect
        value={localConfiguration.entityType}
        onChange={handleEntityTypeChange}
        helperText={'Select an Entity Type to filter on.'}
        disabledEntityTypes={[EntityType.PROFILE]}
      />

      <DashboardWidgetRichTextTitleEditor content={localConfiguration.title} onContentChange={handleTitleChange} />

      {dashboardProfiles && (
        <ProfileSelect
          profiles={dashboardProfiles}
          selectedProfileIds={localConfiguration.profilesIds}
          setSelectedProfileIds={onSelectedProfilesChanged}
        />
      )}

      <div>
        <div className="text-sm">Time Grouping</div>
        <Typography variant="caption">How the data will be grouped on the x-axis</Typography>
        <div className="mt-1">
          <GroupByTimeSelect value={localConfiguration.groupByTime} onChange={handleGroupByTimeChange} />
        </div>
      </div>
      <div>
        <div className="text-sm">Metrics</div>
        <Typography variant="caption">Select up to 3 Metrics to display</Typography>

        <MultipleMetricSelect
          availableMetrics={availableMetrics}
          selectedMetrics={localConfiguration.selectedMetrics}
          onChange={handleSelectedMetricsChange}
          maxSelectedMetrics={3}
        />
      </div>
      <ComboGraphWidgetFilters configuration={localConfiguration} setConfiguration={setLocalConfiguration} />
    </>
  );
};

export default ComboGraphWidgetConfigurationForm;
