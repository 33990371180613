import InfoMessage from '@/components/feedback/InfoMessage';
import AlSelect from '@/components/form/AlSelect';
import { useHelperComponents } from '@/hooks/useHelperComponents';
import useBidLimits from '@/modules/amazon-constants/hooks/useBidLimits';
import { PlacementUpdateData, PlacementUpdateType } from '@/modules/amazon-constants/types/bulk-edit';
import { FlowType } from '@/modules/log-viewing/api/logs-contracts';
import { CampaignUpdateDTO, PlacementUpdate } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { campaignService } from '@/modules/optimizer/api/campaign/campaign-service';
import { EnabledPausedArchivedState } from '@/modules/optimizer/types/EnabledPausedArchivedState';
import { toastService } from '@/services/toast.service';
import { Button, DialogActions, DialogContent, DialogTitle, Divider, Popover, SelectChangeEvent, TextField } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { ChangeEvent, FunctionComponent, RefObject, useState } from 'react';
import { invalidateAll_placementsWithTimelineQueryKeys } from '../api/placements-service';
import usePlacementWarnings from '../hooks/usePlacementWarnings';
import { PlacementModel, SelectedPlacement } from '../models/PlacementsModel';

interface PlacementsBulkEditPopoverProps {
  buttonRef: RefObject<HTMLButtonElement>;
  selectedPlacements: SelectedPlacement[];
  isOpen: boolean;
  onClose: () => void;
}

const PlacementsBulkEditPopover: FunctionComponent<PlacementsBulkEditPopoverProps> = ({ selectedPlacements, isOpen, onClose, buttonRef }) => {
  const queryClient = useQueryClient();
  const { getClampedPlacementValueWithWarnings, getNewPlacementValue } = useBidLimits();
  const { toastWarnWithSetMessages } = useHelperComponents();

  const [isLoading, setIsLoading] = useState(false);

  const [placementUpdateData, setPlacementUpdateData] = useState<PlacementUpdateData>({
    placementUpdateType: PlacementUpdateType.NO_CHANGE,
    newPlacementValue: 0,
  });

  const handlePlacementUpdateTypeChange = (event: SelectChangeEvent<PlacementUpdateType>) => {
    setPlacementUpdateData({ ...placementUpdateData, placementUpdateType: event.target.value as PlacementUpdateType });
  };

  const PLACEMENT_UPDATE_OPTIONS = [
    { value: PlacementUpdateType.NO_CHANGE, label: 'No change' },
    { value: PlacementUpdateType.SET_PLACEMENT_TO_PERCENTAGE, label: 'Set placement to (%)' },
    { value: PlacementUpdateType.INCREASE_PLACEMENT_BY_PERCENTAGE_POINTS, label: 'Increase by (%) points' },
    { value: PlacementUpdateType.DECREASE_PLACEMENT_BY_PERCENTAGE_POINTS, label: 'Decrease by (%) points' },
  ];

  const [newPlacementValue, setNewPlacementValue] = useState<string>('');
  const onNewPlacementValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = String(event.target.value).replace(/%/g, '');
    // Allow only numeric input
    if (!value || /^-?[0-9\b]*$/.test(value) || value === '-') {
      // Update your state or handle the change here
      setNewPlacementValue(value);
      setPlacementUpdateData({ ...placementUpdateData, newPlacementValue: parseFloat(value) });
    }
  };

  const handleApplyChanges = async () => {
    if (newPlacementValue === '' || isNaN(parseFloat(newPlacementValue)) || newPlacementValue === '-') {
      toastService.error('Invalid value');
      return;
    }
    setIsLoading(true);
    const warnings = new Set<string>();

    const filteredSelectedPlacements = selectedPlacements.filter((c) => c.state != EnabledPausedArchivedState.ARCHIVED);

    const campaignUpdates = createCampaignUpdates(filteredSelectedPlacements, placementUpdateData, warnings);

    if (warnings.size > 0) {
      toastWarnWithSetMessages(warnings);
    }

    const response = await campaignService.updateCampaigns(campaignUpdates, FlowType.PLACEMENT);

    if (response.isSuccess === false) {
      toastService.error(response.message);

      onClose();
      setIsLoading(false);
      return;
    } else if (response.payload.error_count > 0) {
      let errorMessage = `${response.payload.error_count} placement(s) could not be updated.\n`;
      const errors = response.payload.error_details.map((error) => {
        return error.error;
      });
      errorMessage += [...new Set(errors)].join('\n');
      toastService.error(errorMessage);
      onClose();
      setIsLoading(false);
      return;
    }

    toastService.success(`Placements successfully edited`);

    invalidateAll_placementsWithTimelineQueryKeys(queryClient);

    onClose();
    setIsLoading(false);
  };

  // Distributes selected placements into their respective campaigns
  function createCampaignUpdates(
    selectedPlacements: SelectedPlacement[],
    placementUpdateData: PlacementUpdateData,
    warnings: Set<string>,
  ): CampaignUpdateDTO[] {
    const campaignUpdatesMap = new Map<string, CampaignUpdateDTO>();

    selectedPlacements.forEach((placement) => {
      const placementUpdate: PlacementUpdate = {
        placement: PlacementModel.convertToBackendPlacementType(placement.adType, placement.placementType),
        percentage: getClampedPlacementValueWithWarnings(
          getNewPlacementValue(placement.percentage, placementUpdateData),
          placement.adType,
          warnings,
        ),
      };

      if (campaignUpdatesMap.has(placement.campaignId)) {
        const existingEntry = campaignUpdatesMap.get(placement.campaignId);
        if (existingEntry && existingEntry.placement_updates) {
          existingEntry.placement_updates.push(placementUpdate);
        }
      } else {
        campaignUpdatesMap.set(placement.campaignId, {
          campaign_id: placement.campaignId,
          placement_updates: [placementUpdate],
          bid_optimization: placement.bidOptimization,
        });
      }
    });

    return Array.from(campaignUpdatesMap.values());
  }

  const numPlacements = selectedPlacements.length;

  const { warnings, archivedPlacementCount } = usePlacementWarnings({
    selectedPlacements,
  });

  const isApplyChangesDisabled =
    newPlacementValue === '' ||
    newPlacementValue === '-' ||
    isNaN(parseFloat(newPlacementValue)) ||
    selectedPlacements.length == archivedPlacementCount;

  return (
    <Popover
      id={'placement-bulk-edit-popover'}
      open={isOpen}
      anchorEl={buttonRef.current}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      slotProps={{ paper: { style: { width: 500 } } }}
      aria-labelledby="edit-selection-popover-title"
    >
      <DialogTitle id="edit-selection-popover-title">Edit selected items</DialogTitle>

      <DialogContent className="min-w-[500px]">
        <div className="flex max-w-96">
          You have selected {selectedPlacements.length} items to edit. Please select the type of edits you would like to apply to these items.
        </div>
        <div className="mb-4 mt-8 flex min-w-100 flex-col gap-y-6">
          {numPlacements > 0 && (
            <div>
              <div className="mb-2 flex items-center pb-0 text-sm font-medium leading-none">Edit {numPlacements} Placements</div>

              <div className="flex gap-x-4">
                <AlSelect
                  label={'Update action'}
                  value={placementUpdateData.placementUpdateType}
                  options={PLACEMENT_UPDATE_OPTIONS}
                  onChange={handlePlacementUpdateTypeChange}
                  renderOption={(item) => item.label}
                  valueExtractor={(item) => item.value}
                />
                {placementUpdateData.placementUpdateType !== PlacementUpdateType.NO_CHANGE && (
                  <TextField
                    fullWidth
                    label={
                      placementUpdateData.placementUpdateType === PlacementUpdateType.SET_PLACEMENT_TO_PERCENTAGE
                        ? 'New Placement (%)'
                        : 'Placement (%) points'
                    }
                    value={newPlacementValue}
                    onChange={onNewPlacementValueChange}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <div className="flex flex-nowrap whitespace-nowrap text-sm">
                            % {placementUpdateData.placementUpdateType !== PlacementUpdateType.SET_PLACEMENT_TO_PERCENTAGE && 'pts'}
                          </div>
                        ),
                      },

                      htmlInput: { pattern: '[0-9]*' },
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </DialogContent>
      <Divider />
      {warnings.length > 0 && (
        <div className="mx-5 flex flex-col">
          {warnings.map((warning, index) => (
            <InfoMessage key={index} content={warning} />
          ))}
        </div>
      )}
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
        <Button onClick={handleApplyChanges} variant="contained" loading={isLoading} disabled={isApplyChangesDisabled}>
          Apply Changes{' '}
        </Button>
      </DialogActions>
    </Popover>
  );
};

export default PlacementsBulkEditPopover;
