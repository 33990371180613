import AlGrid from '@/components/grid/AlGrid';
import { toastService } from '@/services/toast.service';
import { Button, ClickAwayListener, Paper, TextField, Tooltip } from '@mui/material';
import type {
  CellClassParams,
  CellValueChangedEvent,
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  INumberCellEditorParams,
  NewValueParams,
  ProcessDataFromClipboardParams,
  RangeSelectionChangedEvent,
} from 'ag-grid-enterprise';
import { isEmpty, isNil } from 'lodash-es';
import { FunctionComponent, MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Weekday } from '../api/dayparting.contracts';
import { MAX_ALLOWED_DAYPART_CHANGE, MIN_ALLOWED_DAYPART_CHANGE } from '../configuration/limits';

import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { AlDate } from '@/lib/date/AlDate';
import { DaypartingScheduleDayFormModel } from './dayparting-schedule-form/DaypartingScheduleFormModel';

interface DaypartingScheduleDaysEditorProps {
  days: DaypartingScheduleDayFormModel[];
  onChange: (updatedDays: DaypartingScheduleDayFormModel[]) => void;
}

const DaypartingScheduleDaysEditor: FunctionComponent<DaypartingScheduleDaysEditorProps> = ({ days, onChange }) => {
  const gridApiRef = useRef<GridApi | null>(null);
  const [selectedRanges, setSelectedRanges] = useState<RangeSelectionChangedEvent | null>(null);
  const hours = useMemo(() => Array.from({ length: 24 }, (_, i) => `${i.toString().padStart(2, '0')}:00`), []);

  const { activeProfile } = useActiveTeamContext();

  const scheduleHour = activeProfile
    ? AlDate.nowWithTimezone(activeProfile?.timeZone.toString())
    : AlDate.nowWithTimezone('America/Los_Angeles');

  // Range input state
  const [inputValue, setInputValue] = useState<number | ''>('');
  const shouldDisplayInputRef = useRef(false);
  const [inputPosition, setInputPosition] = useState<{ top: number; left: number } | null>(null);

  const getInitialMinValue = () => Math.min(...days.flatMap((day) => day.changes.map((change) => change.change)));
  const getInitialMaxValue = () => Math.max(...days.flatMap((day) => day.changes.map((change) => change.change)));

  const [minEnteredValue, setMinEnteredValue] = useState<number>(getInitialMinValue);
  const [maxEnteredValue, setMaxEnteredValue] = useState<number>(getInitialMaxValue);

  // Function to update min and max entered values
  const updateMinMaxValues = (days: DaypartingScheduleDayFormModel[]) => {
    const values = days.flatMap((day) => day.changes.map((change) => change.change));
    setMinEnteredValue(Math.min(...values));
    setMaxEnteredValue(Math.max(...values));
  };

  // Handle value changes in cells
  const handleCellChange = (dayName: Weekday, hour: string, newValue: number) => {
    const updatedDays = days.map((day) => {
      if (day.day === dayName) {
        const updatedChanges = day.changes.map((change) => {
          if (change.time === hour) {
            change.change = Math.min(Math.max(newValue, MIN_ALLOWED_DAYPART_CHANGE), MAX_ALLOWED_DAYPART_CHANGE);
          }
          return change;
        });

        day.changes = updatedChanges;
      }
      return day;
    });
    onChange(updatedDays);
    updateMinMaxValues(updatedDays); // Update min and max values after change
    setTimeout(() => {
      gridApiRef.current?.refreshCells({ force: true, suppressFlash: true }); // Force update of cells
    }, 50);
  };

  // Handle manual edits in the grid
  const onCellValueChanged = (event: CellValueChangedEvent) => {
    const newValue = parseInt(event.newValue, 10);
    if (!isNaN(newValue)) {
      handleCellChange(event.data.day, event.column.getColId(), newValue);
    } else {
      handleCellChange(event.data.day, event.column.getColId(), 0);
    }
  };

  // Handle Selection Change (Force Re-render)
  const onRangeSelectionChanged = (event: RangeSelectionChangedEvent) => {
    setSelectedRanges(event);

    handleRangeSelectionInputVisibility(event);

    gridApiRef.current?.refreshCells({ force: true, suppressFlash: true }); // Force update of cells
  };

  const handleRangeSelectionInputVisibility = (event: RangeSelectionChangedEvent) => {
    if (event.finished) {
      // if the event is finished and the there is at least a single cell range with more than 1 row / column,
      // then we should display the input field
      if (gridApiRef.current) {
        const cellRanges = gridApiRef.current.getCellRanges();
        if (isNil(cellRanges) || isEmpty(cellRanges)) {
          shouldDisplayInputRef.current = false;
          setInputValue('');
          return;
        }
        const rangeContainsMoreThanOneCell = cellRanges.some((range) => {
          return range.startRow?.rowIndex !== range.endRow?.rowIndex || range.columns.length > 1;
        });

        if (rangeContainsMoreThanOneCell) {
          shouldDisplayInputRef.current = true;
          setInputValue('');
        } else {
          shouldDisplayInputRef.current = false;
          setInputValue('');
        }
      }
    }
  };

  function onMouseEnter(event: MouseEvent<HTMLDivElement> | undefined) {
    if (!event) return;

    if (shouldDisplayInputRef.current) {
      return;
    }

    // Always update position of input
    const target = event.target as HTMLElement;
    const rect = target.getBoundingClientRect();
    const inputTop = rect.bottom - 3;

    let inputLeft = rect.left - 20;
    if (inputLeft < 190) {
      inputLeft = 190;
    }
    setInputPosition({ top: inputTop, left: inputLeft });
  }

  useEffect(() => {
    gridApiRef.current?.refreshCells({ force: true, suppressFlash: true }); // Force update of cells
  }, [minEnteredValue, maxEnteredValue]);

  // Helper Function: Check if a cell is in the selected range (for styling)
  const isCellInSelection = (params: CellClassParams | ICellRendererParams) => {
    if (!gridApiRef.current || !selectedRanges) return false;

    const cellRanges = gridApiRef.current.getCellRanges();
    if (!cellRanges || cellRanges.length === 0) return false;

    const rowIndex = params.node.rowIndex;
    if (isNil(rowIndex)) return false;

    const colId = params.column?.getColId();
    return cellRanges.some((range) => {
      const startRowIndex = Math.min(range.startRow!.rowIndex, range.endRow!.rowIndex);
      const endRowIndex = Math.max(range.startRow!.rowIndex, range.endRow!.rowIndex);
      return rowIndex >= startRowIndex && rowIndex <= endRowIndex && range.columns.some((col) => col.getColId() === colId);
    });
  };

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      {
        colId: 'day',
        headerName: 'Day \\ Hour',
        field: 'day',
        pinned: 'left',
        lockPinned: true,
        minWidth: 100,
        maxWidth: 100,
        cellClass: 'font-medium border-r border-gray-300 border-b-0 border-l-0 border-t-0',
        menuTabs: [],
      },
      ...hours.map<ColDef>((hour) => ({
        colId: `${hour}`,
        headerName: hour,
        headerClass: 'border-r text-xs border-gray-200 px-2',
        field: hour,
        editable: true,
        minWidth: 53,
        maxWidth: 53,
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: MIN_ALLOWED_DAYPART_CHANGE,
          max: MAX_ALLOWED_DAYPART_CHANGE,
        } as INumberCellEditorParams,

        onCellValueChanged: (event: NewValueParams<any, any>) => {
          const newValue = parseInt(event.newValue, 10);

          handleCellChange(event.data.day, event.column.getColId(), newValue);
        },
        cellRenderer: (params: ICellRendererParams<Record<'day' | string, Weekday | number>>) => {
          const value = params.value;
          const isSelected = isCellInSelection(params);

          let colorClass = '';
          const colorStyle = {};

          const currentHour = hour == scheduleHour.toFormat('HH:00');
          const currentDay = params.data?.day == scheduleHour.toFormat('EEE').toUpperCase();

          if (isSelected) {
            if (value > 0) {
              colorClass = 'bg-green-300 text-green-600';
            } else if (value < 0) {
              colorClass = 'bg-red-400 text-red-900';
            } else {
              colorClass = 'bg-blue-200 text-blue-600';
            }
          } else {
            if (value > 0) {
              colorClass = `bg-green-200 text-green-700 ring-1 ring-green-400 ring-inset`;
            } else if (value < 0) {
              colorClass = 'bg-red-200 text-red-700 ring-1 ring-red-400 ring-inset';
            } else {
              colorClass = 'text-gray-700 ring-1 ring-gray-400 ring-inset';
            }
          }

          let normalizedValue = 0;
          if (value > 0) {
            normalizedValue = value / maxEnteredValue;
          } else if (value < 0) {
            normalizedValue = Math.abs(value) / Math.abs(minEnteredValue);
          }

          if (normalizedValue < 0.1) {
            normalizedValue = 0.1;
          } else if (normalizedValue > 1) {
            normalizedValue = 1;
          }

          // Now based on the value being between 0 and 1, we can determine the color, in 10% increments like tailwind does
          const bgOpacityTailwindClass = `bg-opacity-${Math.round(normalizedValue * 10) * 10}`;

          const cssClasses = `${colorClass} ${bgOpacityTailwindClass} flex w-full p-0`;

          return (
            <div className={`${cssClasses} absolute inset-0 h-full w-full flex-1`} style={colorStyle} onMouseEnter={onMouseEnter}>
              <div className="w-full flex flex-1 relative">
                {currentHour && currentDay && (
                  <Tooltip title={'Current time in profile timezone'} placement="top">
                    <div className="absolute top-0.5 left-0.5 flex items-center justify-center">
                      <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-sky-400 opacity-75 "></span>
                      <span className="relative inline-flex size-2.5 rounded-full bg-sky-500 border border-white border-opacity-75"></span>
                    </div>
                  </Tooltip>
                )}

                <div className={`flex h-full w-full cursor-pointer items-center justify-center px-2 py-1 text-center`}>{params.value}%</div>
              </div>
            </div>
          );
        },
      })),
    ];
  }, [days, minEnteredValue, maxEnteredValue, hours, handleCellChange]);

  const rowData = useMemo(() => {
    return days.map((day) => {
      const row: Record<'day' | string, Weekday | number> = { day: day.day };
      day.changes.forEach((change) => {
        row[change.time] = change.change;
      });
      return row;
    });
  }, [days, minEnteredValue, maxEnteredValue]);

  // Process data from clipboard
  // Validate that the copied data is a number
  const processDataFromClipboard = (params: ProcessDataFromClipboardParams) => {
    if (isValidNumericMatrix(params.data)) {
      return params.data;
    } else {
      toastService.error(`Invalid data copied: ${params.data}`);
      return null;
    }
  };

  const isValidNumericMatrix = (data: string[][]): boolean => {
    const isStrictNumeric = (value: string) => /^-?\d+(\.\d+)?$/.test(value.trim());

    return data.every((row) => row.every((cell) => isStrictNumeric(cell)));
  };

  const gridOptions: GridOptions<Record<'day' | string, Weekday | number>> = {
    domLayout: 'autoHeight',
    suppressMovableColumns: true,
    suppressHorizontalScroll: false,
    enableRangeSelection: true,
    suppressMultiRangeSelection: true,
    stopEditingWhenCellsLoseFocus: true,
    undoRedoCellEditing: true,
    suppressRowHoverHighlight: true,
    getRowId: (data) => data.data.day as string,
    rowStyle: {
      borderBottom: 'none',
      borderTop: 'none',
    },
    processDataFromClipboard,
    onRangeSelectionChanged,
    defaultColDef: {
      resizable: false,
      sortable: false,
      filter: false,
      lockPosition: true,
      lockVisible: true,
      suppressHeaderContextMenu: true,
      suppressHeaderMenuButton: true,
      suppressFiltersToolPanel: true,
      suppressHeaderFilterButton: true,
      wrapHeaderText: false,
    },
    onCellValueChanged,
  };

  function handleApplyValue() {
    if (inputValue === '') return;
    if (!gridApiRef.current) return;

    if (selectedRanges) {
      const currentlySelectedRanges = gridApiRef.current?.getCellRanges();
      if (!currentlySelectedRanges || currentlySelectedRanges.length === 0) return;
      const selectedRange = currentlySelectedRanges[0];

      // Get selected rows and columns
      const startRow = selectedRange.startRow!.rowIndex;
      const endRow = selectedRange.endRow!.rowIndex;

      const selectedColumns = selectedRange.columns.map((col) => col.getColId());

      // Iterate over selected rows and columns to apply the value
      for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
        const row = rowData[rowIndex];
        if (!row) continue;

        selectedColumns.forEach((colId) => {
          handleCellChange(row.day as Weekday, colId, inputValue as number);
        });
      }

      shouldDisplayInputRef.current = false;
      setInputValue('');
      deselectAll();
    }
  }

  function onInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      handleApplyValue();
    } else if (e.key === 'Escape') {
      shouldDisplayInputRef.current = false;
      setInputPosition((prev) => ({ left: prev?.left ?? 0, top: prev?.top ?? 0 }));
      setInputValue('');
    }
  }

  function deselectAll() {
    if (gridApiRef.current) {
      gridApiRef.current.clearRangeSelection();
    }
  }

  return (
    <div className="flex h-full w-full max-w-[1374px]">
      <AlGrid
        colDefs={columnDefs}
        rowData={rowData}
        gridOptions={gridOptions}
        onGridReadyCallback={(params: GridReadyEvent) => (gridApiRef.current = params.api)}
      />

      {shouldDisplayInputRef.current && inputPosition && (
        <ClickAwayListener
          onClickAway={() => {
            shouldDisplayInputRef.current = false;
            setInputValue('');
            setInputPosition(null);
            deselectAll();
          }}
        >
          <Paper className="fixed z-50 flex items-center space-x-2 p-2" style={{ top: inputPosition.top, left: inputPosition.left }}>
            <TextField
              className="m-0 w-28"
              type="number"
              size="small"
              autoFocus
              value={inputValue}
              onChange={(e) => {
                const value = e.target.value;
                if (value === '' || value === '-') {
                  setInputValue('');
                } else {
                  const num = Math.round(Number(value));
                  if (!isNaN(num)) {
                    setInputValue(num);
                  }
                }
              }}
              slotProps={{
                htmlInput: {
                  step: 1,
                  className: 'show-spinner-input',
                },
                input: {
                  endAdornment: <span className="text-gray-500 pl-2">%</span>,
                },
              }}
              onKeyDown={onInputKeyDown}
            />
            <Button variant="contained" size="small" onClick={handleApplyValue}>
              Apply
            </Button>
          </Paper>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default DaypartingScheduleDaysEditor;
