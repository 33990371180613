import { AlMultiSelect } from '@/components/form/AlMultiSelect';
import { isNil } from 'lodash-es';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { PendingFiltersContext } from '../context/PendingFiltersContext';
import { AlFilterModel } from '../models/AlFilterModel';
import { AlMultiSelectOptionModel } from '../models/AlMultiSelectOptionModel';
import { FilterErrorCode } from '../types/FilterErrorCodes';

interface FilterMultiSelectProps {
  filter: AlFilterModel;
  label: string;
  filterErrorCodeSet: Set<FilterErrorCode> | undefined;
}

export const FilterMultiSelect: FunctionComponent<FilterMultiSelectProps> = ({ filter, label, filterErrorCodeSet }) => {
  const { setFilterValue } = useContext(PendingFiltersContext);

  const selectedOptionIds =
    filter.conditions && !isNil(filter?.conditions?.[0]) && Array.isArray(filter?.conditions[0].values)
      ? (filter.conditions[0].values as string[])
      : [];
  function setSelectedOptionIds(selectedOptionIds: string[]) {
    filter.conditions = [
      {
        values: selectedOptionIds,
        operator: filter.defaultConditionOperators[0],
      },
    ];

    setFilterValue(filter);
  }

  const [options, setOptions] = useState<AlMultiSelectOptionModel[]>([]);

  // TODO: check why this component is mounted twice
  useEffect(() => {
    filter.getOptions().then((options) => {
      setOptions(options);
    });
  }, [filter]);

  const firstConditionErrorMessage =
    filterErrorCodeSet?.has(FilterErrorCode.EMPTY_1ST_CONDITION) || filterErrorCodeSet?.has(FilterErrorCode.NO_CONDITIONS)
      ? 'This field is required'
      : undefined;

  return (
    <div className="flex w-full flex-row gap-2">
      {selectedOptionIds && (
        <AlMultiSelect
          options={options}
          selectedOptionIds={selectedOptionIds}
          setSelectedOptionIds={setSelectedOptionIds}
          label={label}
          isLoading={filter.isLoading}
          width="100%"
          isError={!isNil(firstConditionErrorMessage)}
          helperText={firstConditionErrorMessage}
        />
      )}
    </div>
  );
};
