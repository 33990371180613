import { ProfileModel } from '@/modules/profiles/types/ProfileModel';
import { isEmpty, isNil } from 'lodash-es';
import { SavedFilterLevel } from '../api/filters-contracts';
import { SpecificityLevel } from '../models/AlFilterModel';
import { FilterPresetModel } from '../models/FilterPresetModel';
import { FilterKey } from '../types/FilterKey';
import { ALL_PRESETS, WrappedSavedFilterPreset } from '../types/SavedPresets';

export function splitAndWrapPresets(
  presets: FilterPresetModel[],
  applicableFilterKeys: Set<FilterKey>,
  activeProfile: ProfileModel | undefined,
  selectedLevel: SavedFilterLevel | typeof ALL_PRESETS,
  profileIdToProfileMap: Record<string, ProfileModel>,
  searchText?: string,
) {
  let filteredPresets = selectedLevel === ALL_PRESETS ? presets : presets.filter((preset) => preset.level === selectedLevel);

  if (searchText && !isEmpty(searchText)) {
    filteredPresets = filteredPresets.filter((preset) => preset.name.toLowerCase().includes(searchText.toLowerCase()));
  }

  const alreadyAssignedFilterPresetIds = new Set<number>();

  // NON-APPLICABLE
  // Contains profile specific filters - not applicable
  const notApplicableDueProfilePresets = filteredPresets
    .filter((preset) => {
      const adsAccountsMatch = getAdsAccountsMatch(preset, activeProfile, profileIdToProfileMap);

      const isSomeProfileSpecificNotMatching =
        preset.filters.some((f) => f.specificityLevel === SpecificityLevel.PROFILE) && preset.profileId !== activeProfile?.id;
      const isAdsAccountSpecificNotMatching =
        preset.filters.some((f) => f.specificityLevel === SpecificityLevel.ADS_ACCOUNT) && !adsAccountsMatch;

      return isSomeProfileSpecificNotMatching || isAdsAccountSpecificNotMatching;
    })
    .sort((a, b) => a.name.localeCompare(b.name));
  notApplicableDueProfilePresets.map((preset) => alreadyAssignedFilterPresetIds.add(preset.id));

  // Contains page specific filters - not applicable
  const notApplicableDuePagePresets = filteredPresets
    .filter((preset) => preset.getNonApplicableFilters(applicableFilterKeys).length > 0 && !alreadyAssignedFilterPresetIds.has(preset.id))
    .sort((a, b) => a.name.localeCompare(b.name));
  notApplicableDuePagePresets.map((preset) => alreadyAssignedFilterPresetIds.add(preset.id));

  // APPLICABLE
  // Filters that are current profile specific and applicable on the current page
  const currentProfilePresets = filteredPresets
    .filter((preset) => {
      const adsAccountsMatch = getAdsAccountsMatch(preset, activeProfile, profileIdToProfileMap);

      const isSomeProfileSpecificMatching =
        preset.filters.some((f) => f.specificityLevel === SpecificityLevel.PROFILE) && preset.profileId === activeProfile?.id;
      const isAdsAccountSpecificMatching =
        preset.filters.some((f) => f.specificityLevel === SpecificityLevel.ADS_ACCOUNT) && adsAccountsMatch;

      return (isSomeProfileSpecificMatching || isAdsAccountSpecificMatching) && !alreadyAssignedFilterPresetIds.has(preset.id);
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  currentProfilePresets.map((preset) => alreadyAssignedFilterPresetIds.add(preset.id));

  // Filters that are applicable on the current page (all the rest)
  const generalPresets = filteredPresets
    .filter((preset) => !alreadyAssignedFilterPresetIds.has(preset.id))
    .sort((a, b) => a.name.localeCompare(b.name));

  const wrappedProfilePresets: WrappedSavedFilterPreset[] = currentProfilePresets.map((preset) => ({
    preset,
  }));

  const wrappedGeneralPresets: WrappedSavedFilterPreset[] = generalPresets.map((preset) => ({
    preset,
  }));

  const wrappedNotApplicableDueProfilePresets: WrappedSavedFilterPreset[] = notApplicableDueProfilePresets.map((preset) => ({
    preset,
    disabled: true,
    tooltip: (
      <>
        Currently not applicable because it contains filters specific for an other profile (
        {profileIdToProfileMap[preset.profileId]?.name ?? 'unknown'}
        ):
        <ul style={{ listStyle: 'disc', marginLeft: '1em' }}>
          {preset.filters
            .filter((f) => f.specificityLevel === SpecificityLevel.PROFILE || f.specificityLevel === SpecificityLevel.ADS_ACCOUNT)
            .map((f) => (
              <li key={f.key}>{f.shortName}</li>
            ))}
        </ul>
      </>
    ),
  }));

  const wrappedNotApplicableDuePagePresets: WrappedSavedFilterPreset[] = notApplicableDuePagePresets.map((preset) => {
    const nonApplicableFilters = preset.getNonApplicableFilters(applicableFilterKeys);

    return {
      preset,
      disabled: true,
      tooltip: (
        <>
          Currently not applicable because it contains filters specific for another view:
          <ul style={{ listStyle: 'disc' }}>
            {nonApplicableFilters.map(({ key, shortName }) => (
              <li key={key}>{shortName}</li>
            ))}
          </ul>
        </>
      ),
    };
  });

  return {
    currentProfilePresets: wrappedProfilePresets,
    generalPresets: wrappedGeneralPresets,
    notApplicablePresets: [...wrappedNotApplicableDueProfilePresets, ...wrappedNotApplicableDuePagePresets],
  };
}

function getAdsAccountsMatch(
  preset: FilterPresetModel,
  activeProfile: ProfileModel | undefined,
  profileIdToProfileMap: Record<string, ProfileModel>,
): boolean {
  const profile1 = profileIdToProfileMap[preset.profileId];
  const profile2 = profileIdToProfileMap[activeProfile?.id ?? ''];
  return (
    !isNil(profile1?.adsAccountId) &&
    !isNil(profile2?.adsAccountId) &&
    !isEmpty(profile1?.adsAccountId) &&
    !isEmpty(profile2?.adsAccountId) &&
    profile1.adsAccountId === profile2.adsAccountId
  );
}
