import { isPrivatePath, router } from '@/router/router';
import { Routes } from '@/router/router-paths';
import * as Sentry from '@sentry/react';
import { AxiosStatic } from 'axios';

export const registerErrorInterceptor = (axios: AxiosStatic) => {
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (!error.response) {
        console.error('No connection to server', error);
        Sentry.captureMessage(`[error-interceptor] - No connection: ${error}`, 'warning');
      }
      if (error.response && error.response.status === 401) {
        if (isPrivatePath(router.state.location.pathname)) {
          router.navigate(`${Routes.LOGIN}?logOut=true`);
          console.log('Unauthorized');
        }
      }
      return Promise.reject(error);
    },
  );
};
