import { ErrorDetails, UpdatedEntityDTO } from '@/modules/application/types/UpdatedEntity';
import { FlowType } from '@/modules/log-viewing/api/logs-contracts';
import { CampaignAdType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { EnabledPausedArchivedState } from '@/modules/optimizer/types/EnabledPausedArchivedState';
import { TargetEntityType } from '@/modules/targeting/api/targets-contracts';

export enum NegativeMatchType {
  CAMPAIGN_NEGATIVE_EXACT = 'CAMPAIGN_NEGATIVE_EXACT',
  CAMPAIGN_NEGATIVE_PHRASE = 'CAMPAIGN_NEGATIVE_PHRASE',
  CAMPAIGN_NEGATIVE_PRODUCT_TARGET = 'CAMPAIGN_NEGATIVE_PRODUCT_TARGET',
  AD_GROUP_NEGATIVE_EXACT = 'AD_GROUP_NEGATIVE_EXACT',
  AD_GROUP_NEGATIVE_PHRASE = 'AD_GROUP_NEGATIVE_PHRASE',
  AD_GROUP_NEGATIVE_PRODUCT_TARGET = 'AD_GROUP_NEGATIVE_PRODUCT_TARGET',
}

export const AD_GROUP_NEGATIVES = [
  NegativeMatchType.AD_GROUP_NEGATIVE_EXACT,
  NegativeMatchType.AD_GROUP_NEGATIVE_PHRASE,
  NegativeMatchType.AD_GROUP_NEGATIVE_PRODUCT_TARGET,
];

export const CAMPAIGN_NEGATIVES = [
  NegativeMatchType.CAMPAIGN_NEGATIVE_EXACT,
  NegativeMatchType.CAMPAIGN_NEGATIVE_PHRASE,
  NegativeMatchType.CAMPAIGN_NEGATIVE_PRODUCT_TARGET,
];

export const KEYWORD_NEGATIVES = [
  NegativeMatchType.AD_GROUP_NEGATIVE_EXACT,
  NegativeMatchType.AD_GROUP_NEGATIVE_PHRASE,
  NegativeMatchType.CAMPAIGN_NEGATIVE_EXACT,
  NegativeMatchType.CAMPAIGN_NEGATIVE_PHRASE,
];

export const PRODUCT_TARGET_NEGATIVES = [
  NegativeMatchType.AD_GROUP_NEGATIVE_PRODUCT_TARGET,
  NegativeMatchType.CAMPAIGN_NEGATIVE_PRODUCT_TARGET,
];

export interface NegativeMatchBools {
  cne: boolean;
  cnp: boolean;
  cnpt: boolean;
  agne: boolean;
  agnp: boolean;
  agnpt: boolean;
}

// TODO: do similar with selected matches
export function negativesArrayToNegativeBoolsObj(negatives: NegativeMatchType[]): NegativeMatchBools {
  return {
    cne: negatives.includes(NegativeMatchType.CAMPAIGN_NEGATIVE_EXACT),
    cnp: negatives.includes(NegativeMatchType.CAMPAIGN_NEGATIVE_PHRASE),
    cnpt: negatives.includes(NegativeMatchType.CAMPAIGN_NEGATIVE_PRODUCT_TARGET),
    agne: negatives.includes(NegativeMatchType.AD_GROUP_NEGATIVE_EXACT),
    agnp: negatives.includes(NegativeMatchType.AD_GROUP_NEGATIVE_PHRASE),
    agnpt: negatives.includes(NegativeMatchType.AD_GROUP_NEGATIVE_PRODUCT_TARGET),
  };
}

export function negativeBoolsObjToNegativesArray(negativeBools: NegativeMatchBools): NegativeMatchType[] {
  const negatives: NegativeMatchType[] = [];

  if (negativeBools.cne) negatives.push(NegativeMatchType.CAMPAIGN_NEGATIVE_EXACT);
  if (negativeBools.cnp) negatives.push(NegativeMatchType.CAMPAIGN_NEGATIVE_PHRASE);
  if (negativeBools.cnpt) negatives.push(NegativeMatchType.CAMPAIGN_NEGATIVE_PRODUCT_TARGET);
  if (negativeBools.agne) negatives.push(NegativeMatchType.AD_GROUP_NEGATIVE_EXACT);
  if (negativeBools.agnp) negatives.push(NegativeMatchType.AD_GROUP_NEGATIVE_PHRASE);
  if (negativeBools.agnpt) negatives.push(NegativeMatchType.AD_GROUP_NEGATIVE_PRODUCT_TARGET);

  return negatives;
}

export enum NegativeKeywordMatchType {
  NEGATIVE_EXACT = 'NEGATIVE_EXACT',
  NEGATIVE_PHRASE = 'NEGATIVE_PHRASE',
}

export enum NegativeTargetingLevel {
  CAMPAIGN = 'CAMPAIGN',
  AD_GROUP = 'AD_GROUP',
}

export interface NegativeTargetsAllDTO {
  negatives: NegativeTargetsDTO[];
  total_keywords: number;
  total_targets: number;
  sent_keywords: number;
  sent_targets: number;
}

export interface NegativeTargetsDTO {
  id: string;
  targeting: string;
  targeting_type: string;
  match_type: NegativeMatchType;
  target_state: EnabledPausedArchivedState;
  campaign_name: string;
  campaign_ad_type: CampaignAdType;
  campaign_state: EnabledPausedArchivedState;
  ad_group_name: string;
  reported_at: string;
  created_at: string;
}

export interface NegativeTargetsUpdateDTO {
  id: string;
  ad_type: CampaignAdType;
  entity_type: TargetEntityType;
  is_campaign: boolean;
  state: EnabledPausedArchivedState;
}

export interface UpdatedNegativeTargetsDTO extends UpdatedEntityDTO {
  entity_type: TargetEntityType;
}

export enum CreateNegativesEntityType {
  SP_NEG_KEYWORD = 'SP_NEG_KEYWORD',
  SP_NEG_CAMPAIGN_TARGET = 'SP_NEG_CAMPAIGN_TARGET',
  SP_NEG_TARGET = 'SP_NEG_TARGET',
  SP_NEG_CAMPAIGN_KEYWORD = 'SP_NEG_CAMPAIGN_KEYWORD',
  SB_NEG_KEYWORD = 'SB_NEG_KEYWORD',
  SB_NEG_TARGET = 'SB_NEG_TARGET',
  SD_NEG_TARGET = 'SD_NEG_TARGET',
}

export interface CreateNegativesDTO {
  flow_type: FlowType;
  negative_targets: CreateNegativeDTO[];
}

export interface CreateNegativeDTO {
  ad_type: CampaignAdType;
  negative_match: NegativeMatchType;
  campaign_id: string;
  ad_group_id: string; // "" for campaign level
  keyword?: string;
  expression?: string;
}

export interface CreateNegativesResponseItemDTO {
  entity_type: CreateNegativesEntityType;
  create_count: number;
  error_count: number;
  error_details: ErrorDetails[];
}

export interface CreateNegativesResponseDTO {
  created: CreateNegativesResponseItemDTO[];
}
