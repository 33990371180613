import { ApiResponse } from '@/lib/api/api-response';
import { apiClient, apiTeamClient } from '@/lib/api/base-client';
import {
  AllTeamSellerCentralReportStatusesDTO,
  SellerCentralReportsStatusDTO,
  SellerCentralSearchQueryStatusDTO,
} from './seller-central-contracts';

class SellerCentralService {
  public basePath = 'sellercentral';

  async createReport(profileId: string): Promise<ApiResponse<null>> {
    return await apiTeamClient.post(`profiles/${profileId}/${this.basePath}/reports/create`, {});
  }

  async getStatus(activeTeamId: number, profileId: string): Promise<ApiResponse<SellerCentralReportsStatusDTO>> {
    // Not using apiTeamClient because active team might not be set in apiClient yet
    return await apiClient.get<SellerCentralReportsStatusDTO>(`teams/${activeTeamId}/profiles/${profileId}/${this.basePath}/reports/status`);
  }

  async getAllTeamReportsStatus(): Promise<ApiResponse<AllTeamSellerCentralReportStatusesDTO>> {
    return await apiTeamClient.get<AllTeamSellerCentralReportStatusesDTO>(`sellercentral/reports/status/all`);
  }

  async createSearchQueryReports(profileId: string): Promise<ApiResponse<null>> {
    return await apiTeamClient.post(`profiles/${profileId}/${this.basePath}/reports/create/search-query`, {});
  }

  async getSearchQueryStatus(activeTeamId: number, profileId: string): Promise<ApiResponse<SellerCentralSearchQueryStatusDTO>> {
    // Not using apiTeamClient because active team might not be set in apiClient yet
    return await apiClient.get<SellerCentralSearchQueryStatusDTO>(
      `teams/${activeTeamId}/profiles/${profileId}/${this.basePath}/reports/status/search-query`,
    );
  }
}

export const sellerCentralService = new SellerCentralService();
