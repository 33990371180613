import { UserSettingKey, useUserSettingsContext } from '@/modules/users';
import { useEffect, useState } from 'react';

/**
 * Custom hook for managing the tutorial modal state.
 * @param userSettingKey - The key for the user setting, that stores the number of times the video has been shown.
 * @returns An object containing the tutorial modal state and functions to control it.
 */
const useVideoModal = (userSettingKey: UserSettingKey) => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const { getUserSettingValueByKey, upsertUserSetting } = useUserSettingsContext();

  // Function to close the modal and update the user setting
  const onVideoModalClose = () => {
    setIsVideoModalOpen(false);
  };

  // Function to open the modal
  const openVideoModal = () => {
    setIsVideoModalOpen(true);
  };

  // Automatically open the modal if the setting value is less than 1
  useEffect(() => {
    const currentCount = getUserSettingValueByKey<number>(userSettingKey);

    if (!currentCount || currentCount < 1) {
      openVideoModal();

      // Increment the count and update the setting
      const newCount = (currentCount || 0) + 1;
      upsertUserSetting(userSettingKey, newCount);
    }
  }, [userSettingKey, getUserSettingValueByKey]);

  return {
    isVideoModalOpen,
    onVideoModalClose,
    openVideoModal,
  };
};

export default useVideoModal;
