import { EntityType } from '@/modules/dashboards/types/EntityType';
import { TeamProfile } from '@/modules/dashboards/types/TeamProfile';
import { FunctionComponent } from 'react';
import { COMMON_METRICS, SELLER_METRICS, VENDOR_METRICS } from '../metrics/MetricsConfig';
import { CommonMetricField, CommonWithSellerAndVendorMetricField } from '../metrics/types/MetricField';
import AlAutoComplete, { AlAutoCompleteOption } from './AlAutoComplete';

interface MetricSelectProps {
  selectedProfiles: TeamProfile[];
  entityType: EntityType;
  value: CommonWithSellerAndVendorMetricField | '';
  onChange: (value: CommonWithSellerAndVendorMetricField | '') => void;
  helperText?: string;
}

const MetricSelect: FunctionComponent<MetricSelectProps> = ({ entityType, selectedProfiles, value, onChange, helperText }) => {
  const handleChange = (option: AlAutoCompleteOption | null) => {
    if (option) {
      onChange(option.id as CommonMetricField);
    }
  };

  let metricConfigurations = COMMON_METRICS;

  if (entityType == EntityType.PROFILE || entityType == EntityType.PRODUCT) {
    if (selectedProfiles.some((p) => p.isSellerCentralConnected)) {
      metricConfigurations = { ...COMMON_METRICS, ...SELLER_METRICS };
    }

    if (selectedProfiles.some((p) => p.isVendorCentralConnected)) {
      metricConfigurations = { ...COMMON_METRICS, ...VENDOR_METRICS };
    }
  }

  const options: { label: string; id: CommonMetricField }[] = Object.values(metricConfigurations).map((metric) => ({
    label: metric.title,
    id: metric.key as CommonMetricField,
  }));

  return (
    <AlAutoComplete
      placeHolderText={'Select Metric'}
      textFieldLabel={'Select Metric'}
      helperText={helperText}
      currentValue={options.find((option) => option.id === value) ?? options[0]}
      options={options}
      updateValueWithOption={(option: AlAutoCompleteOption | null) => handleChange(option)}
    />
  );
};

export default MetricSelect;
