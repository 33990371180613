import { CampaignToCampaignDataWithCampaignMappingAdGroupDataType } from '@/modules/campaign-mapping/models/CampaignMappingModel';
import { CampaignGroupModel } from '@/modules/optimizer/api/campaign/models/CampaignGroupModel';
import { CreateImportModel } from '@/modules/optimizer/api/campaign/models/CreateImportModel';
import { ImportStep } from '../../types/feedback';

export enum FieldNamesCreate {
  GroupName = 'GroupName',
  TargetAcos = 'TargetAcos',
  Prioritization = 'Prioritization',
}

export enum FieldNamesAssign {
  CampaignName = 'CampaignName',
  GroupName = 'GroupName',
}

export interface CampaignGroupsCreateImportRow {
  [FieldNamesCreate.GroupName]: string;
  [FieldNamesCreate.TargetAcos]: number | undefined;
  [FieldNamesCreate.Prioritization]: string | undefined;
}

export interface CampaignGroupsAssignImportRow {
  [FieldNamesAssign.CampaignName]: string;
  [FieldNamesAssign.GroupName]: string;
}

export const CAMPAIGN_GROUPS_CREATE_IMPORT_ROW_KEYS = [
  FieldNamesCreate.GroupName,
  FieldNamesCreate.TargetAcos,
  FieldNamesCreate.Prioritization,
];
export const CAMPAIGN_GROUPS_ASSIGN_IMPORT_ROW_KEYS = [FieldNamesAssign.CampaignName, FieldNamesAssign.GroupName];

export interface CampaignGroupsViewData {
  [ImportStep.CREATE]: CampaignGroupsCreateImportRow[];
  [ImportStep.ASSIGN]: CampaignGroupsAssignImportRow[];
}

export const NEW_ID_PREFIX = 'new_';

export interface CampaignGroupsAssignMaps {
  campaignNameToCampaignIdsMap: Record<string, string[]>;
  campaignToAdGroupsMap: CampaignToCampaignDataWithCampaignMappingAdGroupDataType;

  groupNameToGroupMap: Record<string, CampaignGroupModel>;
  newGroupNameToCreateGroupMap: Record<string, CreateImportModel>;
  groupIdToGroupMap: Record<string, CampaignGroupModel>;
  newGroupIdToCreateGroupMap: Record<string, CreateImportModel>;
}
