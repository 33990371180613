import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import AlGrid, { DEFAULT_GRID_OPTIONS } from '@/components/grid/AlGrid';
import ImageWithTitleCellRenderer, { IImageWithTitleCellRendererParams } from '@/components/grid/cells/ImageWithTitleCellRenderer';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import useAggregators from '@/components/grid/hooks/useAggregators';
import useColumnTypes from '@/components/grid/hooks/useColumnTypes';
import useComparisonMissing from '@/components/grid/hooks/useComparisonMissing';
import useDynamicHeight, { EXPANDED_VISIBLE_ABOVE_PX_ON_SCROLL_DOWN } from '@/components/grid/hooks/useDynamicHeight';
import useMetricColumnAggregates from '@/components/grid/hooks/useMetricColumnAggregates';
import useToggles from '@/components/grid/hooks/useToggles';
import { AlColDef, DEFAULT_GRID_OPTIONS_ROW_GROUPS, ExpandedGridContext, WithActiveProfileGridContext } from '@/components/grid/types';
import { MetricModel } from '@/components/metrics/models/MetricModel';
import { useLayoutContext } from '@/contexts/LayoutContext';
import { useDataGroups } from '@/hooks/useDataGroups';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { useTranslation } from '@/lib/i18n/useTranslate';
import { getProductLink } from '@/modules/application/amazon-utils';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey } from '@/modules/users';
import { MetricsGraphTablePageContext } from '@/types/context-shared';
import { Card } from '@mui/material';
import * as Sentry from '@sentry/react';
import type {
  BodyScrollEvent,
  CellClickedEvent,
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IAggFuncParams,
  ICellRendererParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { FunctionComponent, useCallback, useEffect, useMemo, useRef } from 'react';
import { SearchQueryPerformanceWithTimeline } from '../../api/search-query-performance-contracts';
import { SearchQueryPerformanceModel } from '../../models/SearchQueryPerformanceModel';
import { generateSearchQueryPerformanceTableColumnState } from './search-query-perfrormance-table.default-column-state';

interface SearchQueryPerformanceTableProps {
  withTimeline: SearchQueryPerformanceWithTimeline | undefined;
  isLoading: boolean;
  searchQueryPerformanceLoadingErrorMessage: string;
  isSearchQueryPerformanceLoadingError: boolean;
  pageVariables: MetricsGraphTablePageContext;
  noTopBorderRadius?: boolean;
  onGridReadyCallback?: (params: GridReadyEvent) => void;
  isExpanded: boolean;
}

interface SearchQueryPerformanceGridContext extends ExpandedGridContext, WithActiveProfileGridContext {}

const SearchQueryPerformanceTable: FunctionComponent<SearchQueryPerformanceTableProps> = ({
  withTimeline,
  isLoading,
  searchQueryPerformanceLoadingErrorMessage,
  isSearchQueryPerformanceLoadingError,
  pageVariables,
  noTopBorderRadius = false,
  onGridReadyCallback,
  isExpanded,
}) => {
  const { setColumnStateGridApi, handleColumnStateChange, applyStateToDefinitions, setIsAutoSaveEnabled } = useGridColumnState(
    UserSettingKey.SEARCH_QUERY_PERFORMANCE_TABLE_COLUMN_STATE,
    generateSearchQueryPerformanceTableColumnState(),
  );

  const { t } = useTranslation();

  const rowData = withTimeline?.searchQueryPerformance ?? [];
  const isComparisonDataMissing = withTimeline?.isComparisonDataMissing ?? false;

  const gridApiRef = useRef<GridApi<SearchQueryPerformanceModel>>();
  const gridContextRef = useRef<SearchQueryPerformanceGridContext>();

  const { onSourceScroll } = useLayoutContext();
  const { heightCssValue, onGridReadyCallback: onGridReadyForDynamicHeight } = useDynamicHeight({});
  const { matchLongColumnType, salesPreviousDaysType, spendPreviousDaysType, asinType } = useColumnTypes();
  const { metricFieldWithChangePercentage, dataGroupColumnType, targetedColumnType } = useColumnTypes();
  const { dataGroups, dataItemToInfoMap, groupIdToItemSetMap, isDataGroupsLoading } = useDataGroups([DataGroupType.PRODUCT]);

  // Additional data sources
  const { onGridReadyForMetricColumnAggregates, metricColumnAggregates } = useMetricColumnAggregates({
    gridApiRef,
    gridContextRef,
    metricColumnAggregates: withTimeline?.metrics ? MetricModel.arrayToMetricAggregates(withTimeline.metrics) : undefined,
  });

  const { onGridReadyForComparisonMissing } = useComparisonMissing({
    gridApiRef,
    gridContextRef,
    isComparisonDataMissing,
  });
  const { gridToggles } = pageVariables;

  const { gridToggleParams } = useToggles<SearchQueryPerformanceModel>({
    gridApiRef,
    gridContextRef,
    gridToggles,
  });

  const {
    enabledPausedArchivedStateAggFunc,
    metricDataWithPreviousDaysAggFunc,
    stringToCountAggFunc,
    targetsAggFunc,
    campaignNameAggFunc,
    adGroupsAggFunc,
    groupNameAggFunc,
    booleanAggFunc,
    metricsDataAggFunc,
    portfolioAggFunc,
    asinAggFunc,
    searchQueryAggFunc,
    targetedAggFunc,
    dataGroupAggFunc,
  } = useAggregators();

  const { activeProfile } = useActiveTeamContext();

  const columnDefs: ColDef<SearchQueryPerformanceModel>[] = useMemo(() => {
    const colDefs: AlColDef<SearchQueryPerformanceModel>[] = [
      {
        colId: ColumnId.TITLE,
        headerName: 'Title',
        field: 'title',
        cellRenderer: ImageWithTitleCellRenderer,
        cellRendererParams: (
          params: ICellRendererParams<SearchQueryPerformanceModel, unknown, WithActiveProfileGridContext>,
        ): IImageWithTitleCellRendererParams<SearchQueryPerformanceModel> => {
          const countryCode = params.context?.countryCode;
          return {
            extractImageUrl: (data) => data?.imageUrl,
            extractTitle: (data) => data?.title,
            extractUrl: (data) => (data?.asin && countryCode ? getProductLink(data.asin, countryCode) : undefined),
            suppressGroupTitle: true,
          };
        },
      },
      {
        colId: ColumnId.ASIN,
        field: 'asin',
        type: 'asinType',
      },
      {
        colId: ColumnId.EXISTING_MATCH_TYPES,
        field: 'existingTargetsState',
        type: 'targetedColumnType',
      },
      {
        colId: ColumnId.SEARCH_QUERY,
        headerName: 'Search Query',
        field: 'searchQuery',
        enableRowGroup: true,
        aggFunc: 'searchQueryAggFunc',
      },
      {
        colId: ColumnId.SEARCH_QUERY_VOLUME,
        headerName: 'Search Query Volume',
        field: 'searchQueryVolume',
        type: 'metricFieldWithChangePercentage',
        aggFunc: 'metricsDataAggFunc_bySearchQuery',
      },
      {
        colId: ColumnId.TOTAL_QUERY_IMPRESSION_COUNT,
        headerName: 'Total Query Impression Count',
        field: 'totalQueryImpressionCount',
        type: 'metricFieldWithChangePercentage',
        aggFunc: 'metricsDataAggFunc_bySearchQuery',
      },
      {
        colId: ColumnId.ASIN_IMPRESSION_COUNT,
        headerName: 'ASIN Impression Count',
        field: 'asinImpressionCount',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.ASIN_IMPRESSION_SHARE,
        headerName: 'ASIN Impression Share (%)',
        field: 'asinImpressionShare',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.TOTAL_CLICK_COUNT,
        headerName: 'Total Click Count',
        field: 'totalClickCount',
        type: 'metricFieldWithChangePercentage',
        aggFunc: 'metricsDataAggFunc_bySearchQuery',
      },
      {
        colId: ColumnId.TOTAL_CLICK_RATE,
        headerName: 'Search-Click Rate (%)',
        field: 'totalClickRate',
        type: 'metricFieldWithChangePercentage',
        aggFunc: 'metricsDataAggFunc_bySearchQuery',
      },
      {
        colId: ColumnId.ASIN_CLICK_COUNT,
        headerName: 'ASIN Click Count',
        field: 'asinClickCount',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.ASIN_CLICK_SHARE,
        headerName: 'ASIN Click Share (%)',
        field: 'asinClickShare',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.TOTAL_CART_ADD_COUNT,
        headerName: 'Total Cart Add Count',
        field: 'totalCartAddCount',
        type: 'metricFieldWithChangePercentage',
        aggFunc: 'metricsDataAggFunc_bySearchQuery',
      },
      {
        colId: ColumnId.TOTAL_CART_ADD_RATE,
        headerName: 'Total Cart Add Rate (%)',
        field: 'totalCartAddRate',
        type: 'metricFieldWithChangePercentage',
        aggFunc: 'metricsDataAggFunc_bySearchQuery',
      },
      {
        colId: ColumnId.ASIN_CART_ADD_COUNT,
        headerName: 'ASIN Cart Add Count',
        field: 'asinCartAddCount',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.ASIN_CART_ADD_SHARE,
        headerName: 'ASIN Cart Add Share (%)',
        field: 'asinCartAddShare',
        type: 'metricFieldWithChangePercentage',
        aggFunc: 'metricsDataAggFunc_bySearchQuery',
      },
      {
        colId: ColumnId.TOTAL_PURCHASE_COUNT,
        headerName: 'Total Purchase Count',
        field: 'totalPurchaseCount',
        type: 'metricFieldWithChangePercentage',
        aggFunc: 'metricsDataAggFunc_bySearchQuery',
      },
      {
        colId: ColumnId.TOTAL_PURCHASE_RATE,
        headerName: 'Total Purchase Rate (%)',
        field: 'totalPurchaseRate',
        type: 'metricFieldWithChangePercentage',
        aggFunc: 'metricsDataAggFunc_bySearchQuery',
      },
      {
        colId: ColumnId.ASIN_PURCHASE_COUNT,
        headerName: 'ASIN Purchase Count',
        field: 'asinPurchaseCount',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.ASIN_PURCHASE_SHARE,
        headerName: 'ASIN Purchase Share (%)',
        field: 'asinPurchaseShare',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.SEARCH_QUERY_SCORE,
        headerName: 'Search Query Score',
        field: 'searchQueryScore',
        type: 'metricFieldWithChangePercentage',
        aggFunc: 'noAgg',
        enableRowGroup: true,
        sortingOrder: ['asc', 'desc', null],
      },
      {
        colId: ColumnId.TOTAL_MEDIAN_CLICK_PRICE,
        headerName: 'Total Median Click Price',
        field: 'totalMedianClickPrice',
        type: 'metricFieldWithChangePercentage',
        aggFunc: 'metricsDataAggFunc_bySearchQuery',
      },
      {
        colId: ColumnId.ASIN_MEDIAN_CLICK_PRICE,
        headerName: 'ASIN Median Click Price',
        field: 'asinMedianClickPrice',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.TOTAL_SAME_DAY_SHIPPING_CLICK_COUNT,
        headerName: 'Total Same-Day Shipping Click Count',
        field: 'totalSameDayShippingClickCount',
        type: 'metricFieldWithChangePercentage',
        aggFunc: 'metricsDataAggFunc_bySearchQuery',
      },
      {
        colId: ColumnId.TOTAL_ONE_DAY_SHIPPING_CLICK_COUNT,
        headerName: 'Total One-Day Shipping Click Count',
        field: 'totalOneDayShippingClickCount',
        type: 'metricFieldWithChangePercentage',
        aggFunc: 'metricsDataAggFunc_bySearchQuery',
      },
      {
        colId: ColumnId.TOTAL_TWO_DAY_SHIPPING_CLICK_COUNT,
        headerName: 'Total Two-Day Shipping Click Count',
        field: 'totalTwoDayShippingClickCount',
        type: 'metricFieldWithChangePercentage',
        aggFunc: 'metricsDataAggFunc_bySearchQuery',
      },
      {
        colId: ColumnId.TOTAL_MEDIAN_CART_ADD_PRICE,
        headerName: 'Total Median Cart Add Price',
        field: 'totalMedianCartAddPrice',
        type: 'metricFieldWithChangePercentage',
        aggFunc: 'metricsDataAggFunc_bySearchQuery',
      },
      {
        colId: ColumnId.ASIN_MEDIAN_CART_ADD_PRICE,
        headerName: 'ASIN Median Cart Add Price',
        field: 'asinMedianCartAddPrice',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.TOTAL_SAME_DAY_SHIPPING_CART_ADD_COUNT,
        headerName: 'Total Same-Day Shipping Cart Add Count',
        field: 'totalSameDayShippingCartAddCount',
        type: 'metricFieldWithChangePercentage',
        aggFunc: 'metricsDataAggFunc_bySearchQuery',
      },
      {
        colId: ColumnId.TOTAL_ONE_DAY_SHIPPING_CART_ADD_COUNT,
        headerName: 'Total One-Day Shipping Cart Add Count',
        field: 'totalOneDayShippingCartAddCount',
        type: 'metricFieldWithChangePercentage',
        aggFunc: 'metricsDataAggFunc_bySearchQuery',
      },
      {
        colId: ColumnId.TOTAL_TWO_DAY_SHIPPING_CART_ADD_COUNT,
        headerName: 'Total Two-Day Shipping Cart Add Count',
        field: 'totalTwoDayShippingCartAddCount',
        type: 'metricFieldWithChangePercentage',
        aggFunc: 'metricsDataAggFunc_bySearchQuery',
      },
      {
        colId: ColumnId.TOTAL_MEDIAN_PURCHASE_PRICE,
        headerName: 'Total Median Purchase Price',
        field: 'totalMedianPurchasePrice',
        type: 'metricFieldWithChangePercentage',
        aggFunc: 'metricsDataAggFunc_bySearchQuery',
      },
      {
        colId: ColumnId.ASIN_MEDIAN_PURCHASE_PRICE,
        headerName: 'ASIN Median Purchase Price',
        field: 'asinMedianPurchasePrice',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.TOTAL_SAME_DAY_SHIPPING_PURCHASE_COUNT,
        headerName: 'Total Same-Day Shipping Purchase Count',
        field: 'totalSameDayShippingPurchaseCount',
        type: 'metricFieldWithChangePercentage',
        aggFunc: 'metricsDataAggFunc_bySearchQuery',
      },
      {
        colId: ColumnId.TOTAL_ONE_DAY_SHIPPING_PURCHASE_COUNT,
        headerName: 'Total One-Day Shipping Purchase Count',
        field: 'totalOneDayShippingPurchaseCount',
        type: 'metricFieldWithChangePercentage',
        aggFunc: 'metricsDataAggFunc_bySearchQuery',
      },
      {
        colId: ColumnId.TOTAL_TWO_DAY_SHIPPING_PURCHASE_COUNT,
        headerName: 'Total Two-Day Shipping Purchase Count',
        field: 'totalTwoDayShippingPurchaseCount',
        type: 'metricFieldWithChangePercentage',
        aggFunc: 'metricsDataAggFunc_bySearchQuery',
      },
      {
        colId: ColumnId.TOTAL_CTR,
        headerName: 'Total CTR',
        field: 'totalCTR',
        type: 'metricFieldWithChangePercentage',
        aggFunc: 'metricsDataAggFunc_bySearchQuery',
      },
      {
        colId: ColumnId.ASIN_CTR,
        headerName: 'ASIN CTR',
        field: 'asinCTR',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.ASIN_CONVERSION_RATE,
        headerName: 'ASIN Conversion Rate',
        field: 'asinConversionRate',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.TOTAL_CONVERSION_RATE,
        headerName: 'Total Conversion Rate',
        field: 'totalConversionRate',
        type: 'metricFieldWithChangePercentage',
        aggFunc: 'metricsDataAggFunc_bySearchQuery',
      },
    ];

    dataGroups
      .filter((group) => group.type === DataGroupType.PRODUCT)
      .forEach((group) => {
        colDefs.push({
          colId: `data_group_${group.id}`,
          headerName: group.name,
          headerClass: 'accented-header data-group-product data-group-base',
          toolPanelClass: 'data-group-base data-group-product',
          headerTooltip: t('product_data_group'),
          valueGetter: (params: ValueGetterParams<SearchQueryPerformanceModel>) => {
            const itemId = params.data?.productDataItemIds.find((itemId) => groupIdToItemSetMap.get(group.id)?.has(itemId));
            return itemId ? dataItemToInfoMap.get(itemId)?.name : undefined;
          },
          type: 'dataGroupColumnType',
        });
      });

    applyStateToDefinitions(colDefs);

    return colDefs;
  }, [groupIdToItemSetMap, isDataGroupsLoading]);

  const onCellClicked = useCallback((params: CellClickedEvent<SearchQueryPerformanceModel>) => {
    if (params.column.getColId() === ColumnId.CHECKBOX) {
      const node = params.node;
      node.setSelected(!node.isSelected());
    }
  }, []);

  const customGridOptions: GridOptions<SearchQueryPerformanceModel> = useMemo(() => {
    // While values might be undefined, default values are needed so grid knows which fields to expect
    const defaultSearchQueryPerformanceGridContext: SearchQueryPerformanceGridContext = {
      metricColumnAggregates,
      isComparisonDataMissing,
      ...gridToggleParams,
      countryCode: activeProfile?.countryCode,
    };
    return {
      ...DEFAULT_GRID_OPTIONS,
      ...DEFAULT_GRID_OPTIONS_ROW_GROUPS,
      sideBar: false,
      getRowId: (params) => params.data.id.toString(),
      onCellClicked: onCellClicked,
      context: defaultSearchQueryPerformanceGridContext,
      maintainColumnOrder: true,
      rowHeight: 68,
      onBodyScroll: (event: BodyScrollEvent) => onSourceScroll(event.top),
      onColumnMoved: handleColumnStateChange,
      onColumnVisible: handleColumnStateChange,
      onColumnResized: handleColumnStateChange,
      onColumnRowGroupChanged: handleColumnStateChange,
      onSortChanged: handleColumnStateChange,
      onColumnPinned: handleColumnStateChange,
      columnTypes: {
        metricFieldWithChangePercentage,
        dataGroupColumnType,
        matchLongColumnType,
        salesPreviousDaysType,
        spendPreviousDaysType,
        asinType,
        targetedColumnType,
      },
      getRowStyle: (params) => {
        // Add custom style to group rows
        if (params.node.group) {
          return {
            background: 'linear-gradient(to bottom, rgba(96,165,250, 0.2), rgba(96,165,250, 0.05))',
          };
        }
      },
      // Defining all aggFuncs here not in colDefs (where using string) so to prevent "stateItem.aggFunc must be a string" warning (col state is stored as a simple JSON)
      aggFuncs: {
        metricsDataAggFunc,
        enabledPausedArchivedStateAggFunc,
        stringToCountAggFunc,
        metricDataWithPreviousDaysAggFunc,
        targetsAggFunc,
        campaignNameAggFunc,
        adGroupsAggFunc,
        groupNameAggFunc,
        booleanAggFunc,
        portfolioAggFunc,
        asinAggFunc,
        searchQueryAggFunc,
        targetedAggFunc,
        metricsDataAggFunc_bySearchQuery: (params: IAggFuncParams<SearchQueryPerformanceModel>) =>
          metricsDataAggFunc<SearchQueryPerformanceModel>(params, 'searchQuery'),
        noAgg: () => undefined,
        dataGroupAggFunc,
      },
    };
  }, []);

  function onGridReady(params: GridReadyEvent) {
    setColumnStateGridApi(params.api);
    gridApiRef.current = params.api;
    onGridReadyCallback?.(params);
    onGridReadyForDynamicHeight(params);

    // Context
    gridContextRef.current = params.context;

    // Need this because for initial context set the external data sources might have not been set yet
    if (gridContextRef.current) {
      onGridReadyForMetricColumnAggregates();
      onGridReadyForComparisonMissing();
      // Not needed for comparison unit as it isn't fetched and always exists

      gridApiRef.current.refreshHeader();
      gridApiRef.current.refreshCells({ force: true });
    } else {
      // Should not happen as gridApiRef was just set
      Sentry.captureMessage(`SearchQueryPerformanceTable: gridContextRef is undefined though it was just set`, 'info');
    }
  }

  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  return (
    <>
      {isSearchQueryPerformanceLoadingError ? (
        <Card className="flex-grow rounded-xl py-0">
          <ErrorLoadingDataAlert details={searchQueryPerformanceLoadingErrorMessage} />
        </Card>
      ) : (
        <div style={{ height: isExpanded ? `calc(100vh - ${EXPANDED_VISIBLE_ABOVE_PX_ON_SCROLL_DOWN}px)` : heightCssValue }}>
          <AlGrid
            colDefs={columnDefs}
            rowData={rowData}
            gridOptions={customGridOptions}
            isLoading={isLoading}
            onGridReadyCallback={onGridReady}
            noTopBorderRadius={noTopBorderRadius}
            fitToResizeEnabled={false}
            addExtraBottomPadding={true}
          />
        </div>
      )}
    </>
  );
};

export default SearchQueryPerformanceTable;
