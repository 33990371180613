import { AlFetchCache } from '@/modules/al-fetch-cache/AlFetchCache';
import { ContextKey } from '@/types/context-shared';
import { SavedFilterDTO, SavedFilterLevel, SavedFiltersJson } from '../api/filters-contracts';
import { FilterKey } from '../types/FilterKey';
import { AlFilterModel } from './AlFilterModel';
import { filterFactory } from './FilterFactory';

export class FilterPresetModel {
  id: number;
  name: string;
  creatorId: number;
  teamId: number;
  orgId: number;
  profileId: string;
  filters: AlFilterModel[];
  pageId: ContextKey;
  level: SavedFilterLevel;

  constructor(args: FilterPresetArguments) {
    this.id = args.id;
    this.name = args.name;
    this.creatorId = args.creatorId;
    this.teamId = args.teamId;
    this.orgId = args.orgId;
    this.profileId = args.profileId;
    this.filters = args.filters;
    this.pageId = args.pageId;
    this.level = args.level;
  }

  static fromDTO(dto: SavedFilterDTO, fetchCache: AlFetchCache): FilterPresetModel {
    return new FilterPresetModel({
      id: dto.id,
      name: dto.name,
      creatorId: dto.creator_id,
      teamId: dto.team_id,
      orgId: dto.org_id,
      profileId: dto.profile_id,
      filters: FilterPresetModel.decodeFilters(dto.filter, fetchCache),
      pageId: dto.page_id,
      level: dto.level,
    });
  }

  static encodeFilters(filters: AlFilterModel[]): string {
    const savedFiltersJson: SavedFiltersJson = {
      filters: filters.sort((a, b) => a.key.localeCompare(b.key)).map((f) => f.toDTO()),
    };

    return JSON.stringify(savedFiltersJson);
  }

  static decodeFilters(encodedFilters: string, fetchCache: AlFetchCache): AlFilterModel[] {
    const savedFiltersJson: SavedFiltersJson = JSON.parse(encodedFilters);
    return filterFactory.createFilterModelsFromDTOs(savedFiltersJson.filters, fetchCache);
  }

  static fromDTOArray(dto: SavedFilterDTO[], fetchCache: AlFetchCache): FilterPresetModel[] {
    return dto.map((d) => FilterPresetModel.fromDTO(d, fetchCache));
  }

  getNonApplicableFilters(applicableFilterKeys: Set<FilterKey>): AlFilterModel[] {
    return this.filters.filter((filter) => !applicableFilterKeys.has(filter.key));
  }
}

interface FilterPresetArguments {
  id: number;
  name: string;
  creatorId: number;
  teamId: number;
  orgId: number;
  profileId: string;
  filters: AlFilterModel[];
  pageId: ContextKey;
  level: SavedFilterLevel;
}
