import { MetricDTO } from '@/components/metrics/api/metrics-contracts';
import {
  CommonMetricField,
  CommonWithSellerAndVendorMetricField,
  SellerMetricFieldWithoutPrefix,
  VendorMetricField,
  VendorMetricFieldWithoutPrefix,
} from '@/components/metrics/types/MetricField';

interface MetricsWithValues {
  ads_metrics: {
    impressions: number[];
    clicks: number[];
    orders: number[];
    units: number[];
    spend: number[];
    sales: number[];
    sso: number[];
    sss: number[];
    oss: number[];
    acos: number[];
    roas: number[];
    rpc: number[];
    cpa: number[];
    aov: number[];
    cpc: number[];
    cvr: number[];
    ctr: number[];
    cpm: number[];
    actc: number[];
  };
  seller_metrics: {
    acos: number[];
    actc: number[];
    ad_sales_of_total: number[];
    aov: number[];
    asp: number[];
    clicks: number[];
    cpa: number[];
    cvr: number[];
    orders: number[];
    org_sales: number[];
    org_traffic: number[];
    roas: number[];
    sales: number[];
    unit_sess: number[];
    unit_view: number[];
    units: number[];
    views: number[];
  };
  vendor_metrics: {
    acogs: number[];
    acos: number[];
    actc: number[];
    ad_sales_of_total: number[];
    asp: number[];
    customer_returns: number[];
    glance_views: number[];
    ordered_revenue: number[];
    ordered_units: number[];
    org_sales: number[];
    return_rate: number[];
    roas: number[];
    shipped_cogs: number[];
    shipped_revenue: number[];
    shipped_units: number[];
    unit_view: number[];
  };
}

export function getMetricValues(dto: MetricsWithValues, metric: CommonWithSellerAndVendorMetricField): number[] {
  if (Object.values(CommonMetricField).includes(metric as CommonMetricField)) {
    return dto.ads_metrics[metric as CommonMetricField];
  } else if (Object.values(VendorMetricField).includes(metric as VendorMetricField)) {
    return dto.vendor_metrics[metric.replace('vendor_', '') as VendorMetricFieldWithoutPrefix];
  } else {
    return dto.seller_metrics[metric.replace('seller_', '') as SellerMetricFieldWithoutPrefix];
  }
}

export type WidgetDataDTO =
  | PieGraphWidgetDataDTO
  | MetricWidgetDataDTO
  | ComboGraphWidgetDataDTO
  | BarGraphWidgetDataDTO
  | TableWidgetDataDTO
  | HorizontalDividerWidgetDataDTO
  | TextWidgetDataDTO
  | LineGraphWidgetDataDTO;

interface MetricsWithPreviousDTO {
  ads_metrics: {
    impressions: MetricDTO;
    clicks: MetricDTO;
    orders: MetricDTO;
    units: MetricDTO;
    spend: MetricDTO;
    sales: MetricDTO;
    sso: MetricDTO;
    sss: MetricDTO;
    oss: MetricDTO;
    acos: MetricDTO;
    actc: MetricDTO;
    roas: MetricDTO;
    rpc: MetricDTO;
    cpa: MetricDTO;
    aov: MetricDTO;
    cpc: MetricDTO;
    cvr: MetricDTO;
    ctr: MetricDTO;
    cpm: MetricDTO;
  };
  seller_metrics: {
    acos: MetricDTO;
    actc: MetricDTO;
    ad_sales_of_total: MetricDTO;
    aov: MetricDTO;
    asp: MetricDTO;
    clicks: MetricDTO;
    cpa: MetricDTO;
    cvr: MetricDTO;
    orders: MetricDTO;
    org_sales: MetricDTO;
    org_traffic: MetricDTO;
    roas: MetricDTO;
    sales: MetricDTO;
    unit_sess: MetricDTO;
    unit_view: MetricDTO;
    units: MetricDTO;
    views: MetricDTO;
  };
  vendor_metrics: {
    acogs: MetricDTO;
    acos: MetricDTO;
    ad_sales_of_total: MetricDTO;
    asp: MetricDTO;
    customer_returns: MetricDTO;
    glance_views: MetricDTO;
    ordered_revenue: MetricDTO; // total sales
    ordered_units: MetricDTO; // total units
    org_sales: MetricDTO;
    return_rate: MetricDTO;
    roas: MetricDTO;
    shipped_cogs: MetricDTO;
    shipped_revenue: MetricDTO;
    shipped_units: MetricDTO;
    unit_view: MetricDTO;
  };
}

export function getMetricValue(dto: MetricsWithPreviousDTO, metric: CommonWithSellerAndVendorMetricField): MetricDTO {
  if (Object.values(CommonMetricField).includes(metric as CommonMetricField)) {
    return dto.ads_metrics[metric as CommonMetricField];
  } else if (Object.values(VendorMetricField).includes(metric as VendorMetricField)) {
    return dto.vendor_metrics[metric.replace('vendor_', '') as VendorMetricFieldWithoutPrefix];
  } else {
    return dto.seller_metrics[metric.replace('seller_', '') as SellerMetricFieldWithoutPrefix];
  }
}

export interface DashboardCalculatedDataDTO {
  widgetData: { [key: string]: WidgetDataDTO }; // Key is the widget ID
  comparison_missing: boolean;
}

export interface PieGraphWidgetDataDTO {
  data: PieGraphWidgetDataItemDTO[];
}

interface PieGraphWidgetDataItemDTO {
  label: string;
  value: number;
}

export interface MetricWidgetDataDTO {
  data: MetricDTO;
}

export interface ComboGraphWidgetDataDTO {
  xAxis: string[];
  yAxis: MetricsWithValues;
  yAxisGrouped: { [key: string]: MetricsWithValues };
}

export interface LineGraphWidgetDataDTO {
  xAxis: string[];
  yAxis: MetricsWithValues;
  yAxisGrouped: { [key: string]: MetricsWithValues };
}

export interface BarGraphWidgetDataDTO {
  xAxis: string[];
  yAxis: MetricsWithPreviousDTO[];
}

export interface TableWidgetDataDTO {
  data: TableWidgetDataRowDTO[];
}

export function getMetricValueFromTableDataRow(dto: TableWidgetDataRowDTO, metric: CommonWithSellerAndVendorMetricField): MetricDTO {
  if (Object.values(CommonMetricField).includes(metric as CommonMetricField)) {
    return dto.ads_metrics[metric as CommonMetricField];
  } else if (Object.values(VendorMetricField).includes(metric as VendorMetricField)) {
    return dto.vendor_metrics[metric.replace('vendor_', '') as VendorMetricFieldWithoutPrefix];
  } else {
    return dto.seller_metrics[metric.replace('seller_', '') as SellerMetricFieldWithoutPrefix];
  }
}

export interface TableWidgetDataRowDTO extends MetricsWithPreviousDTO {
  name: string;
  adType: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface HorizontalDividerWidgetDataDTO {}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface TextWidgetDataDTO {}
