import { ApiResponse } from '@/lib/api/api-response';
import { apiProfileClient } from '@/lib/api/base-client';
import {
  DaypartingCreateDTO,
  DaypartingUpdateDTO,
  DaypartingAssignDTO,
  DaypartingScheduleDTO,
  DaypartingDeleteDTO,
  DaypartingAssignDeleteDTO,
} from './dayparting.contracts';
import { DaypartingScheduleModel } from '../types/DaypartingScheduleModel';

class DaypartingScheduleService {
  public basePath = 'dayparting/schedule';

  async create(schedule: DaypartingCreateDTO): Promise<ApiResponse<DaypartingScheduleDTO>> {
    try {
      return await apiProfileClient.post<DaypartingScheduleDTO>(`${this.basePath}/create`, schedule);
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async update(scheduleId: number, schedule: DaypartingUpdateDTO): Promise<ApiResponse<DaypartingScheduleDTO>> {
    try {
      return await apiProfileClient.post<DaypartingScheduleDTO>(`${this.basePath}/update`, { ...schedule, id: scheduleId });
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getAll(): Promise<ApiResponse<DaypartingScheduleModel[]>> {
    try {
      const applicationResponse = await apiProfileClient.get<DaypartingScheduleDTO[]>(this.basePath);
      return applicationResponse.processPayload((payload) => payload.map((p) => new DaypartingScheduleModel(p)));
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getById(id: number): Promise<ApiResponse<DaypartingScheduleModel>> {
    try {
      const applicationResponse = await apiProfileClient.get<DaypartingScheduleDTO>(`${this.basePath}/${id}`);
      return applicationResponse.processPayload((payload) => new DaypartingScheduleModel(payload));
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async delete(scheduleDeleteRequest: DaypartingDeleteDTO): Promise<ApiResponse<null>> {
    try {
      return await apiProfileClient.post(`${this.basePath}/delete`, scheduleDeleteRequest);
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async assign(assignments: DaypartingAssignDTO): Promise<ApiResponse<null>> {
    try {
      return await apiProfileClient.post(`${this.basePath}/assign`, assignments);
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async deleteAssignment(assignmentsToDelete: DaypartingAssignDeleteDTO): Promise<ApiResponse<null>> {
    try {
      return await apiProfileClient.post(`${this.basePath}/delete-assign`, assignmentsToDelete);
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const daypartingScheduleService = new DaypartingScheduleService();
