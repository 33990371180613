import TextLink from '@/components/buttons/TextLink';
import PopoverLikePopper from '@/components/PopoverLikePopper';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { DaypartingScheduleAssignmentDTO } from '@/modules/dayparting/api/dayparting.contracts';
import { daypartingScheduleService } from '@/modules/dayparting/api/dayparting.service';
import { invalidateProfile_placementsWithTimelineQueryKeys } from '@/modules/placements/api/placements-service';
import { invalidateProfile_targetingWithTimelineQueryKeys } from '@/modules/targeting/api/targets-service';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { Routes } from '@/router/router-paths';
import { toastService } from '@/services/toast.service';
import { CircularProgress, Paper } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import { Dispatch, FunctionComponent, RefObject, SetStateAction, useState } from 'react';
import { invalidateProfile_campaignsWithTimelineQueryKeys } from '../../api/campaign/campaign-service';
import { SelectedCampaignDTO } from '../../api/campaign/models/CampaignModel';
import { UpdateDaypartingSchedules } from './UpdateDaypartingSchedules';
import { createGetAllDaypartingSchedulesQueryKey } from '@/modules/dayparting/api/dayparting.cache';

interface DaypartingSchedulePopoverProps {
  buttonRef: RefObject<HTMLButtonElement>;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  selectedCampaigns: SelectedCampaignDTO[];
  setSelectedCampaigns: Dispatch<SetStateAction<SelectedCampaignDTO[]>>;
}

export const DaypartingSchedulePopover: FunctionComponent<DaypartingSchedulePopoverProps> = ({
  buttonRef,
  isOpen,
  setIsOpen,
  selectedCampaigns,
  setSelectedCampaigns,
}) => {
  const { activeTeam, activeProfile } = useActiveTeamContext();
  const [isApplyingScheduleChange, setIsApplyingScheduleChange] = useState(false);
  const queryClient = useQueryClient();

  const onDaypartingSchedulePopoverClose = () => {
    setIsOpen(false);
  };

  const {
    data: daypartingSchedules,
    isLoading,
    refetch: refetchDaypartingSchedules,
    error,
  } = useQuery({
    queryKey: createGetAllDaypartingSchedulesQueryKey(activeProfile?.id),
    queryFn: async () => {
      const result = await daypartingScheduleService.getAll();
      if (result.isSuccess) {
        return result.payload;
      } else {
        toastService.error('Error loading Dayparting Schedules');

        throw new Error('Error loading Dayparting Schedules');
      }
    },
    enabled: !isNil(activeTeam) && !isNil(activeProfile),
  });

  async function applyChanges(changeSchedules: DaypartingScheduleAssignmentDTO[], isRemoval: boolean): Promise<void> {
    setIsApplyingScheduleChange(true);

    let response;
    if (isRemoval) {
      response = await daypartingScheduleService.deleteAssignment({
        campaign_ids: changeSchedules.flatMap((schedule) => schedule.campaign_ids),
      });
    } else {
      response = await daypartingScheduleService.assign({
        assignments: changeSchedules,
      });
    }
    setIsApplyingScheduleChange(false);

    if (response.isSuccess === false) {
      toastService.error(response.message);
      return;
    }

    refetchDaypartingSchedules();

    updateCampaigns(changeSchedules);

    toastService.success(`Dayparting Schedule changed successfully`);
  }

  const updateCampaigns = (updatedSchedules: DaypartingScheduleAssignmentDTO[]) => {
    const updateScheduleMap = new Map<string, number>();
    updatedSchedules.forEach(({ campaign_ids, schedule_id }) => {
      campaign_ids.forEach((campaign_id) => {
        updateScheduleMap.set(campaign_id, schedule_id);
      });
    });

    invalidateProfile_campaignsWithTimelineQueryKeys(queryClient, activeProfile?.id);
    invalidateProfile_targetingWithTimelineQueryKeys(queryClient, activeProfile?.id);
    invalidateProfile_placementsWithTimelineQueryKeys(queryClient, activeProfile?.id);

    const newSelectedCampaigns: SelectedCampaignDTO[] = selectedCampaigns.map((campaign): SelectedCampaignDTO => {
      const updatedScheduleId = updateScheduleMap.get(campaign.id);
      return {
        id: campaign.id,
        name: campaign.name,
        groupId: campaign.groupId,
        scheduleId: updatedScheduleId !== undefined ? updatedScheduleId : campaign.scheduleId,
        adType: campaign.adType,
        state: campaign.state,
        costType: campaign.costType,
        bidStrategy: campaign.bidStrategy,
        bidOptimization: campaign.bidOptimization,
        multiAdGroupsEnabled: campaign.multiAdGroupsEnabled,
        hasOptRule: campaign.hasOptRule,
        endDate: campaign.endDate,
        lastOptimizedAt: campaign.lastOptimizedAt,
        dataGroupType: DataGroupType.CAMPAIGN,
        dataItemIds: campaign.dataItemIds,
        budgetAmount: campaign.budgetAmount,
      };
    });
    setSelectedCampaigns(newSelectedCampaigns);
  };

  return (
    <PopoverLikePopper open={isOpen} anchorEl={buttonRef.current} onClose={onDaypartingSchedulePopoverClose}>
      <Paper style={{ width: '600px' }}>
        {isLoading ? (
          <CircularProgress />
        ) : error ? (
          <div className="px-4 py-8">Error loading Dayparting Schedules, please try again later.</div>
        ) : !daypartingSchedules || (daypartingSchedules && daypartingSchedules.length === 0) ? (
          <div className="px-4 py-6">
            You have no Dayparting Schedules. Please create one first, on the{' '}
            <div className="inline-flex pr-1">
              <TextLink to={Routes.DAYPARTING} className="text-blue-600 font-semibold hover:underline">
                Dayparting Schedules
              </TextLink>
            </div>
            page.
          </div>
        ) : (
          <UpdateDaypartingSchedules
            existingSchedules={daypartingSchedules.filter((schedule) => schedule.id !== 0) ?? []}
            selectedCampaigns={selectedCampaigns}
            onClose={onDaypartingSchedulePopoverClose}
            applyChanges={applyChanges}
            isApplyPending={isApplyingScheduleChange}
          />
        )}
      </Paper>
    </PopoverLikePopper>
  );
};
