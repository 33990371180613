import { createBrowserRouter, RouteObject, Navigate } from 'react-router';
import * as Sentry from '@sentry/react';
import { Environment } from '@/config/Environment';
import ExternalLayout from '../layouts/ExternalLayout';
import ExternalDashboardPage from '../pages/ExternalDashboardPage';
import ExternalNotFoundPage from '../pages/ExternalNotFoundPage';

// Define external routes
const EXTERNAL_ROUTES: RouteObject[] = [
  {
    path: '/external',
    element: <ExternalLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="dashboard" replace />,
      },
      {
        path: 'dashboard/:dashboardShareId',
        element: <ExternalDashboardPage />,
      },
      {
        path: '*',
        element: <ExternalNotFoundPage />,
      },
    ],
  },
];

// Create the external router with Sentry integration if in production
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);

export const externalRouter = Environment.isAdLabsProd() ? sentryCreateBrowserRouter(EXTERNAL_ROUTES) : createBrowserRouter(EXTERNAL_ROUTES);
