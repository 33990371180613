import { DashboardWidgetType } from '@/modules/dashboards/types/DashboardWidgetType';
import { PieChartRounded } from '@mui/icons-material';
import { v4 } from 'uuid';
import { IDashboardWidgetCatalogItem } from '../../../types/IDashboardWidgetCatalogItem';
import { PieGraphWidgetConfiguration } from './PieGraphWidgetConfiguration';

export const PIE_GRAPH_WIDGET_CATALOG_ITEM: IDashboardWidgetCatalogItem<PieGraphWidgetConfiguration> = {
  title: 'Pie Graph',
  description: 'Shows a Pie Graph of up to 4 Metrics.',
  icon: <PieChartRounded />,
  id: v4(),
  type: DashboardWidgetType.PieGraphWidget,
  configuration: new PieGraphWidgetConfiguration(),
  layout: {
    h: 6,
    w: 6,
    i: v4(),
    x: 0,
    y: 0,
    maxH: 16,
    maxW: 16,
    minH: 4,
    minW: 4,
  },
};
