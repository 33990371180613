import { DEFAULT_VISIBLE_METRICS, MetricSelectionColorIndex } from '@/components/metrics/MetricsConfig';
import { MetricField } from '@/components/metrics/types/MetricField';
import { UserSettingKey } from '@/modules/users';
import { DEFAULT_AVAILABLE_METRICS } from '@/types/context-shared';
import { isEqual } from 'lodash-es';
import { useMemo } from 'react';
import { useUserSetting } from './useUserSetting';

const useMetricsInContext = (
  settingKey: UserSettingKey,
  availableMetrics: MetricField[] = DEFAULT_AVAILABLE_METRICS,
  inputVisibleMetrics = DEFAULT_VISIBLE_METRICS,
) => {
  const { handleSettingStateChange: updateVisibleMetrics, settingState: visibleMetricsSetting } =
    useUserSetting<MetricSelectionColorIndex[]>(settingKey);

  // TODO: remove legacy handling after 1. aug 2025 (legacy conversion added nov 2024). By then all of the users should have moved on to new structure. Also remove old keys from db, front stuff removed on 5. Mar 2025

  function getSavedVisibleMetrics(): MetricSelectionColorIndex[] {
    if (visibleMetricsSetting) return visibleMetricsSetting;

    return inputVisibleMetrics;
  }

  const visibleMetrics = useMemo(
    () => getSavedVisibleMetrics().filter((metric) => availableMetrics.includes(metric.key)),
    [availableMetrics, visibleMetricsSetting],
  );

  function setVisibleMetrics(metrics: MetricSelectionColorIndex[]) {
    if (isEqual(visibleMetrics, metrics)) return;

    updateVisibleMetrics(metrics);
  }

  return {
    visibleMetrics,
    setVisibleMetrics,
  };
};

export default useMetricsInContext;
