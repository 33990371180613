import { CampaignGroupModel } from '@/modules/optimizer/api/campaign/models/CampaignGroupModel';
import { BidCeilingType } from '@/modules/optimizer/types/BidCeilingType';
import { BidFloorType } from '@/modules/optimizer/types/BidFloorType';
import { SmartBidCeilingType } from '@/modules/optimizer/types/SmartBidCeilingType';
import { z } from 'zod';
import { OptimizationPreset } from '../../optimization/OptimizerConfig';

export const createCampaignGroupSchema = (existingGroups?: CampaignGroupModel[]) => {
  return z.object({
    groupName: z
      .string()
      .min(1, 'Group Name is required')
      .refine((name) => !existingGroups || !existingGroups.some((group) => group.name === name), 'This group already exists'),
    tacos: z
      .string()
      .optional()
      .refine((value) => value === '' || !isNaN(Number(value)), 'ACOS must be a number'),
    bidCeiling: z
      .string()
      .optional()
      .refine((value) => value === '' || !isNaN(Number(value)), 'Bid Ceiling must be a number'),
    bidFloor: z
      .string()
      .optional()
      .refine((value) => value === '' || !isNaN(Number(value)), 'Bid Floor must be a number'),
    prioritization: z.nativeEnum(OptimizationPreset),
    bidCeilingType: z.nativeEnum(BidCeilingType),
    bidFloorType: z.nativeEnum(BidFloorType),
    smartBidCeilingType: z.nativeEnum(SmartBidCeilingType),
    optimizationEnabled: z.boolean().default(true),
  });
};
