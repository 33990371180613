import MetricTimelineChart from '@/components/chart/MetricTimelineChart';
import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';
import ProfileDataStatusAlerts from '@/components/feedback/ProfileDataStatusAlerts';
import { getDefaultTargetFilters } from '@/components/filter-builder/models/AlFilterModel';
import MetricsContainer from '@/components/metrics/MetricsContainer';
import VideoModal from '@/components/modals/video-modal/VideoModal';
import useVideoModal from '@/components/modals/video-modal/useVideoModal';
import { VideoUrl } from '@/config/urls';
import useEscapableToggle from '@/hooks/useEscapableToggle';
import useGlobalLoadingStateObserver from '@/hooks/useGlobalLoadingStateObserver';
import useMetricChartTablePageVariables from '@/hooks/useMetricChartTablePageVariables';
import { useTranslation } from '@/lib';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import ProfileSyncSelectButton from '@/modules/optimizer/ProfileSyncSelectButton';
import { usePaywallManager } from '@/modules/plans/hooks/usePaywallManager';
import { createTargetingWithTimelineQueryKey, targetingService } from '@/modules/targeting/api/targets-service';
import { PreferredTimePicker } from '@/modules/teams/components/PreferredTimePicker';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';
import UpgradeSubscriptionButton from '@/modules/teams/components/UpgradeSubscriptionButton';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey, useUserContext } from '@/modules/users';
import { Routes } from '@/router/router-paths';
import { toastService } from '@/services/toast.service';
import { ContextKey, DEFAULT_GRID_TOGGLES } from '@/types/context-shared';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import type { GridReadyEvent } from 'ag-grid-community';
import type { GridApi } from 'ag-grid-enterprise';
import { isEmpty } from 'lodash-es';
import { FunctionComponent, useRef, useState } from 'react';
import DataLimitReachedAlert from '../components/DataLimitReachedAlert';
import TargetingFilterBar from '../components/TargetingFilterBar';
import TargetingSelectionActionsBar from '../components/TargetingSelectionActionsBar';
import TooManyNoStatsTargetsAlert from '../components/TooManyNoStatsTargetsAlert';
import TargetingTable from '../components/targeting-table/TargetingTable';
import { TargetModel } from '../models/TargetsModel';

const TargetingPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { activeTeam, activeProfile } = useActiveTeamContext();
  const { refetchUser } = useUserContext();

  usePaywallManager({
    route: Routes.TARGETING,
    message: 'Upgrade to Pro to view Targets',
    videoUrl: VideoUrl.TARGETING,
    canUsePage: (team) => team.subscriptionPlan.canUseTargetingPage,
  });

  const pageVariables = useMetricChartTablePageVariables({
    contextKey: ContextKey.TARGETING,
    metricsUserSettingKey: UserSettingKey.VISIBLE_METRICS_TARGETING,
    defaultFilters: getDefaultTargetFilters(),
    gridToggles: DEFAULT_GRID_TOGGLES,
  });

  const [selectedTargets, setSelectedTargets] = useState<TargetModel[]>([]);
  const [isShowingAlerts, setIsShowingAlerts] = useState<boolean>(true);

  // TARGETS
  const targetingTableGridApi = useRef<GridApi | null>(null);
  function clearSelections() {
    setSelectedTargets([]);
    if (targetingTableGridApi.current && !targetingTableGridApi.current.isDestroyed()) {
      targetingTableGridApi.current.deselectAll();
    }
  }

  const targetsWithTimelineQueryKey = createTargetingWithTimelineQueryKey(activeProfile?.id, pageVariables.filters);
  const {
    data: targetsWithTimeline,
    isLoading: isTargetsRowDataLoading,
    isError: isTargetsLoadingError,
    error: targetsLoadingError,
    isFetching,
  } = useQuery({
    queryKey: targetsWithTimelineQueryKey,
    queryFn: async () => {
      const result = await targetingService.getTargetsWithTimeline(pageVariables.filters);
      if (result.isSuccess) {
        return result.payload;
      } else {
        toastService.error('Error loading targeting ' + JSON.stringify(result));
      }
    },
    enabled: pageVariables.isFiltersEnabled && !isEmpty(activeProfile?.id),
  });

  useGlobalLoadingStateObserver('isFetching targeting', isFetching);

  function onTargetingTableGridReady(params: GridReadyEvent) {
    targetingTableGridApi.current = params.api;
  }

  // TUTORIAL MODAL
  const {
    isVideoModalOpen: isVideoModalOpen,
    onVideoModalClose: onVideoModalClose,
    openVideoModal: openVideoModal,
  } = useVideoModal(UserSettingKey.TARGETING_TUTORIAL_DISPLAY_COUNT);

  const isDataLimitReached = targetsWithTimeline?.limitReached ?? false;

  const [isTableExpanded, toggleTableExpanded] = useEscapableToggle(false);

  return (
    <>
      <PageLayout showFullscreen={isTableExpanded}>
        {!isTableExpanded && (
          <PageLayoutTopBar
            header={
              <div className="flex flex-row items-center gap-4">
                {t('targeting')}
                <Button onClick={() => openVideoModal()} variant="contained" startIcon={<OndemandVideoIcon />}>
                  How to Use Targeting
                </Button>
              </div>
            }
            actions={
              <div className="flex flex-row items-center gap-2">
                {!activeTeam?.hasProPlan && <UpgradeSubscriptionButton />}
                <PreferredTimePicker isEmbeddedInTopBar onSuccess={() => refetchUser()} />
                <TeamSelect />
                <ProfileSyncSelectButton disableFilters={pageVariables.disableFilters} />{' '}
              </div>
            }
          />
        )}

        <ProfileDataStatusAlerts isShowingAlerts={isShowingAlerts} setIsShowingAlerts={setIsShowingAlerts} />

        {!isShowingAlerts && (
          <PageLayoutBody suppressBottomPadding={isTableExpanded} suppressHorizontalPadding={isTableExpanded}>
            {!isDataLimitReached && !isTableExpanded && (
              <div className="mt-2">
                <AlErrorBoundary>
                  <MetricsContainer
                    metricValues={targetsWithTimeline?.metrics ?? []}
                    isLoading={isTargetsRowDataLoading}
                    isError={isTargetsLoadingError}
                    error={targetsLoadingError}
                    visibleMetrics={pageVariables.visibleMetrics}
                    setVisibleMetrics={pageVariables.setVisibleMetrics}
                    showComparison={pageVariables.gridToggles.comparisonUnit != 'hidden'}
                  />
                </AlErrorBoundary>
              </div>
            )}

            <AlErrorBoundary>
              <TooManyNoStatsTargetsAlert noStatsTargetCount={targetsWithTimeline?.targetsNoStats} setFilters={pageVariables.setFilters} />
            </AlErrorBoundary>

            {isDataLimitReached && <DataLimitReachedAlert setFilters={pageVariables.setFilters} />}

            {!isDataLimitReached && !isTableExpanded && (
              <div className="my-2">
                <AlErrorBoundary>
                  <MetricTimelineChart
                    visibleMetrics={pageVariables.visibleMetrics}
                    timelineData={targetsWithTimeline?.timeline}
                    isLoading={isTargetsRowDataLoading}
                    isError={isTargetsLoadingError}
                    error={targetsLoadingError}
                  />
                </AlErrorBoundary>
              </div>
            )}

            <AlErrorBoundary>
              <TargetingFilterBar
                withTimeline={targetsWithTimeline}
                gridApiRef={targetingTableGridApi}
                isExpanded={isTableExpanded}
                onExpandTable={toggleTableExpanded}
                pageVariables={pageVariables}
              />
            </AlErrorBoundary>

            <AlErrorBoundary>
              <TargetingTable
                withTimeline={targetsWithTimeline}
                isLoading={isTargetsRowDataLoading}
                selectedTargets={selectedTargets}
                setSelectedTargets={setSelectedTargets}
                targetingLoadingErrorMessage={targetsLoadingError instanceof Error ? targetsLoadingError.message : ''}
                isTargetingLoadingError={isTargetsLoadingError}
                pageVariables={pageVariables}
                onGridReadyCallback={onTargetingTableGridReady}
                noTopBorderRadius={true}
                isExpanded={isTableExpanded}
              />
            </AlErrorBoundary>
          </PageLayoutBody>
        )}
      </PageLayout>
      <AlErrorBoundary>
        <TargetingSelectionActionsBar
          selectedTargets={selectedTargets}
          totalTargetsCount={targetsWithTimeline?.targets ? targetsWithTimeline.targets.length : 0}
          onDiscardClicked={clearSelections}
          pageVariables={pageVariables}
        />
      </AlErrorBoundary>

      <VideoModal isOpen={isVideoModalOpen} onClose={onVideoModalClose} url={VideoUrl.TARGETING} title={'How to Use the Targeting Tab'} />
    </>
  );
};

export default TargetingPage;
