import { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router';
import Dashboard from '@/modules/dashboards/components/dashboard/Dashboard';
import { DashboardContext, useDashboard } from '@/modules/dashboards/contexts/DashboardContextProvider';
import { useExternalContext } from '../hooks/useExternalContext';
import DateRangeButton from '@/modules/application/components/date-range-picker/DateRangeButton';
import { generateDashboardQueryKey } from '@/modules/dashboards/hooks/useDashboardQueries';
import { DashboardCalculatedDataModel } from '@/modules/dashboards/types/DashboardCalculatedDataModel';
import { Skeleton } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { v4 } from 'uuid';
import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { FilterKey } from '@/components/filter-builder/types/FilterKey';
import { AlDate } from '@/lib/date/AlDate';

const ExternalDashboardPage: FunctionComponent = () => {
  const { dashboardShareId } = useParams<{ dashboardShareId: string }>();

  const dashboardContext = useDashboard(dashboardShareId, { external: true });
  const { setDashboardShareToken } = useExternalContext();

  const queryClient = useQueryClient();
  const dashboardData = queryClient.getQueryData<DashboardCalculatedDataModel>(generateDashboardQueryKey(dashboardContext?.dashboard));

  useEffect(() => {
    setDashboardShareToken(dashboardShareId ?? null);
  }, [dashboardShareId]);

  function onSetDates(filters: AlFilterModel[]) {
    const getFilterValues = (key: FilterKey) => {
      const filter = filters.find((f) => f.key === key);
      return [filter?.conditions?.[0]?.values?.[0] as string | undefined, filter?.conditions?.[1]?.values?.[0] as string | undefined];
    };

    const [dateFilterStart, dateFilterEnd] = getFilterValues(FilterKey.DATE);
    const [comparisonDateFilterStart, comparisonDateFilterEnd] = getFilterValues(FilterKey.COMPARE_DATE);

    if (!dateFilterStart || !dateFilterEnd || !comparisonDateFilterStart || !comparisonDateFilterEnd) return;

    const dates = [AlDate.parse(dateFilterStart), AlDate.parse(dateFilterEnd)];
    const comparisonDates = [AlDate.parse(comparisonDateFilterStart), AlDate.parse(comparisonDateFilterEnd)];

    if (!dates[0] || !dates[1] || !comparisonDates[0] || !comparisonDates[1]) return;

    dashboardContext.setDates(dates);
    dashboardContext.setComparisonDates(comparisonDates);

    if (dashboardContext.dashboard) {
      dashboardContext.dashboard.dto.filter_start_date_offset_in_days = dates[0].diff(AlDate.now(), 'days');
      dashboardContext.dashboard.dto.filter_end_date_offset_in_days = dates[1].diff(AlDate.now(), 'days');
      dashboardContext.dashboard.dto.filter_compare_start_date_offset_in_days = comparisonDates[0].diff(AlDate.now(), 'days');
      dashboardContext.dashboard.dto.filter_compare_end_date_offset_in_days = comparisonDates[1].diff(AlDate.now(), 'days');
    }

    dashboardContext.setDashboardVersion(v4());
  }
  return (
    <DashboardContext.Provider value={dashboardContext}>
      <div className="flex h-screen w-full flex-col">
        <div className="font-blue flex h-12 shrink-0 w-full items-center justify-between border-b border-primary-400 bg-primary-300 px-4 text-xl font-semibold text-blue-950">
          <div>{dashboardContext.dashboard?.dto.name}</div>
          <div className="flex items-center ">
            {!dashboardContext.dashboard || dashboardContext.isLoadingDashboard ? (
              <Skeleton className="rounded-xl" variant="rectangular" animation="wave" width={200} height={35} />
            ) : (
              <div className="rounded-md bg-white flex items-center leading-none p-0.5">
                <DateRangeButton
                  disablePaywall={true}
                  title="Select Dates"
                  filters={dashboardContext.dashboardDateFilters}
                  setFilterValues={onSetDates}
                  tooltip="Date range to use"
                  comparisonRangeWarning={
                    dashboardData && dashboardData.dto.comparison_missing
                      ? 'Comparison data is missing for the selected date range'
                      : undefined
                  }
                />
              </div>
            )}
          </div>
        </div>
        <div className="flex-1 bg-gray-50 px-1 overflow-auto">
          <Dashboard />
        </div>
      </div>
    </DashboardContext.Provider>
  );
};

export default ExternalDashboardPage;
