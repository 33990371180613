import useGlobalLoadingStateObserver from '@/hooks/useGlobalLoadingStateObserver';
import useAlFetchCache from '@/modules/al-fetch-cache/useAlFetchCache';
import { ContextKey } from '@/types/context-shared';
import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { filtersService } from '../api/filters-service';
import { AlFilterModel, areFilterSetsEqual } from '../models/AlFilterModel';
import { FilterPresetModel } from '../models/FilterPresetModel';

interface UseSavedFilterPresetsProps {
  filters: AlFilterModel[];
  setFilters: React.Dispatch<React.SetStateAction<AlFilterModel[]>>;
  pendingSelectedPreset: FilterPresetModel | 'new' | null;
  setPendingSelectedPreset: Dispatch<SetStateAction<FilterPresetModel | 'new' | null>>;
  filtersSetOnNew: AlFilterModel[];
  contextKey?: ContextKey;
}

const useSavedFilterPresets = ({
  filters,
  setFilters,
  contextKey,
  pendingSelectedPreset,
  setPendingSelectedPreset,
  filtersSetOnNew,
}: UseSavedFilterPresetsProps) => {
  const { fetchCache } = useAlFetchCache();

  const {
    data: savedPresets,
    isLoading,
    isError,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: filtersService.getSavedFiltersQueryKey(),
    queryFn: async () => {
      const result = await filtersService.getSavedFilters(fetchCache);
      if (result.isSuccess) {
        return result.payload;
      } else {
        throw new Error('Error loading saved filters\n' + JSON.stringify(result));
      }
    },
    enabled: !isNil(contextKey),
  });

  useGlobalLoadingStateObserver('isFetchingSavedFilterPresets', isFetching);

  useEffect(() => {
    if (isNil(savedPresets)) return;

    if (isNil(pendingSelectedPreset)) {
      // Check if a preset matches with the current filter builder filters
      const filterBuilderFilters = filters.filter((f) => f.isFilterBuilderFilter);
      const presetWithSelectedFilters = savedPresets?.find((p) => areFilterSetsEqual(p.filters, filterBuilderFilters));
      setSelectedPreset(presetWithSelectedFilters ?? 'new', filters);
    } else if (pendingSelectedPreset != 'new') {
      // Check if currently selected preset exists among the saved presets - might have been just deleted
      const existingCurrentPreset = savedPresets?.find((p) => p.id === pendingSelectedPreset.id);
      if (isNil(existingCurrentPreset)) {
        setSelectedPreset('new', filters);
      } else {
        // Usually not needed, but on Update & Apply the external preset might be saved as stale
        setSelectedPreset(existingCurrentPreset, filters);
      }
    }
  }, [savedPresets]);

  function setSelectedPreset(newSelectedPreset: FilterPresetModel | 'new', filters?: AlFilterModel[]) {
    // Optional filters used when duplicating and received new filters from duplication request - might not yet be available here
    setFilters((previousFilters) => {
      // Only change filter builder filters (not date filters)
      const nonFilterBuilderFilters = previousFilters.filter((f) => !f.isFilterBuilderFilter);
      let filtersToSet: AlFilterModel[] = [];

      if (newSelectedPreset === 'new') {
        filtersToSet = [...nonFilterBuilderFilters, ...filtersSetOnNew.filter((f) => f.isFilterBuilderFilter)];
      } else if (!newSelectedPreset) {
        filtersToSet = nonFilterBuilderFilters;
      } else {
        filtersToSet = [...nonFilterBuilderFilters, ...(filters ?? newSelectedPreset.filters.filter((f) => f.isFilterBuilderFilter))];
      }

      // Clone deep to detach preset filters from pending filters to allow isModified check
      return AlFilterModel.cloneFilterArray(filtersToSet, fetchCache);
    });

    setPendingSelectedPreset(newSelectedPreset === 'new' ? 'new' : newSelectedPreset);
  }

  const isUpdatingExisting = !isNil(pendingSelectedPreset) && pendingSelectedPreset !== 'new';

  const isExistingPresetModified =
    isUpdatingExisting &&
    !areFilterSetsEqual(
      pendingSelectedPreset.filters.filter((f) => f.isFilterBuilderFilter),
      filters.filter((f) => f.isFilterBuilderFilter),
    );

  return {
    selectedPreset: pendingSelectedPreset,
    setSelectedPreset,
    savedPresets: savedPresets ?? [],
    refetchSavedPresets: refetch,
    isLoading,
    isError,
    isUpdatingExisting,
    isExistingPresetModified,
  };
};

export default useSavedFilterPresets;
