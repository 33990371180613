import { AlDate } from '@/lib/date/AlDate';
import { DTO } from '@/types/dto-wrapper';
import { DashboardLinkReadDTO } from '../api/dashboard-links/dashboard-link.contracts';

export class DashboardLinkModel extends DTO<DashboardLinkReadDTO> {
  constructor(dto: DashboardLinkReadDTO) {
    super(dto);
  }

  get id(): number {
    return this.dto.id;
  }

  get dashboardId(): number {
    return this.dto.dashboard_id;
  }

  get token(): string {
    return this.dto.token;
  }

  get createdAt(): AlDate {
    return AlDate.fromISO(this.dto.created_at) as AlDate;
  }

  get shareableUrl(): string {
    return `${window.location.origin}/external/dashboard/${this.token}`;
  }
}
