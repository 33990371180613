import { scrollToRowWithId } from '@/components/grid/utils';
import { CampaignMappingImportDetails, MAX_ERROR_WARNING_SAMPLES } from '@/modules/data-management/types/feedback';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Tooltip, useTheme } from '@mui/material';
import { GridApi } from 'ag-grid-enterprise';
import { MutableRefObject } from 'react';

interface ImportActionBarErrorWarningProps<T> {
  importDetails: CampaignMappingImportDetails;
  gridApiRef: MutableRefObject<GridApi<T> | null>;
}

const ImportActionBarErrorWarning = <T,>({ importDetails, gridApiRef }: ImportActionBarErrorWarningProps<T>) => {
  const theme = useTheme();

  const errorTooltipContent = (
    <div className="flex flex-col gap-1">
      {importDetails.errorMessageSamples.slice(0, MAX_ERROR_WARNING_SAMPLES).map((errorMessageSample, index) => (
        <span key={index}>{'❗ ' + errorMessageSample}</span>
      ))}
    </div>
  );

  const warningTooltipContent = (
    <div className="flex flex-col gap-1">
      {importDetails.warningSamples.slice(0, MAX_ERROR_WARNING_SAMPLES).map((warningSample, index) => (
        <span key={index}>{'⚠️ ' + warningSample}</span>
      ))}
    </div>
  );
  return (
    <>
      {importDetails.errorCount > 0 && (
        <Tooltip title={errorTooltipContent}>
          <span
            onClick={() => scrollToRowWithId(importDetails.firstErrorRowId, gridApiRef.current)}
            className="flex cursor-pointer items-center font-medium"
          >
            <ErrorOutlineIcon fontSize="small" style={{ marginRight: '6px', color: theme.palette.error.light }} />
            {importDetails.errorCount} {importDetails.errorCount == 1 ? 'Error' : 'Errors'}
          </span>
        </Tooltip>
      )}

      {importDetails.warningCount > 0 && (
        <Tooltip title={warningTooltipContent}>
          <span
            onClick={() => scrollToRowWithId(importDetails.firstWarningRowId, gridApiRef.current)}
            className="flex cursor-pointer items-center font-medium"
          >
            <WarningAmberIcon fontSize="small" style={{ marginRight: '6px', color: theme.palette.warning.light }} />
            {importDetails.warningCount} {importDetails.warningCount == 1 ? 'Warning' : 'Warnings'}
          </span>
        </Tooltip>
      )}
    </>
  );
};

export default ImportActionBarErrorWarning;
