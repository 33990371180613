import { UnitType } from '@/components/metrics/MetricsConfig';
import useFormatting from '@/hooks/useFormatting';
import { useTranslation } from '@/lib';
import { truncateString } from '@/modules/application/utils';
import * as Sentry from '@sentry/react';
import { JSX } from 'react';
import { AlFilterModel, OperatorDisplayMap, OperatorType } from '../models/AlFilterModel';
import { FilterType } from '../types/FilterKey';

const MAX_STRING_COMPARISON_VALUES = 1;
const MAX_STRING_LENGTH = 15;

const useChipLabelFormatting = () => {
  const { t } = useTranslation();
  const { formatPercent, formatWithThousandsSeparator, formatCurrency, formatDateStringTimeNoHours } = useFormatting();

  const getBetweenFormatter = (filter: AlFilterModel) => {
    switch (filter.unitType) {
      case UnitType.CURRENCY:
        return formatCurrency;
      case UnitType.PERCENTAGE:
        return (value: number | null | undefined) => {
          if (value !== null && value !== undefined) {
            return formatPercent(value / 100); // Formatter expects fractions
          }
          return '';
        };
      default:
        return formatWithThousandsSeparator;
    }
  };

  const formatIfNumber = (formatter: (value: number | null | undefined) => string, value: string | number): string | number => {
    const numValue = Number(value);
    return !isNaN(numValue) ? formatter(numValue) : value;
  };

  const getLabelForFilter = (filter: AlFilterModel): JSX.Element => {
    if (!filter.conditions || filter.conditions.length === 0) {
      return <></>;
    }

    const condition = filter.conditions[0];
    const firstValue = condition.values[0];

    switch (filter.type) {
      case FilterType.DATE_SELECT:
        if (condition.operator === OperatorType.IS_NULL) {
          return (
            <>
              <span className="font-semibold">{filter.shortName}</span>: NEVER
            </>
          );
        } else {
          return getDateBetweenLabel(filter, firstValue.toString());
        }

      case FilterType.BETWEEN:
        return getBetweenLabel(filter, firstValue);

      case FilterType.SELECT:
        return (
          <>
            <span className="font-semibold">{filter.shortName}</span>: {t(`${filter.getOptionsTranslationKey()}.${firstValue}`)}
          </>
        );

      case FilterType.MULTI_SELECT:
        return (
          <>
            <span className="font-semibold">{filter.shortName}</span>: {condition.values.length} selected
          </>
        );

      case FilterType.STRING_COMPARISON:
      case FilterType.STRING_EXACT_MATCH:
        return (
          <>
            <span className="font-semibold">{filter.shortName}</span>{' '}
            <span className="italic">
              {condition.values
                .map((v) => truncateString(v.toString(), MAX_STRING_LENGTH))
                .slice(0, MAX_STRING_COMPARISON_VALUES)
                .join(', ')}
            </span>
            {condition.values.length > MAX_STRING_COMPARISON_VALUES && (
              <span className="italic"> +{condition.values.length - MAX_STRING_COMPARISON_VALUES} more</span>
            )}
          </>
        );

      default:
        return <></>;
    }
  };

  const getBetweenLabel = (filter: AlFilterModel, firstValue: string | number): JSX.Element => {
    if (!filter.conditions || filter.conditions.length === 0) {
      return <></>;
    }

    const formatter = getBetweenFormatter(filter);

    if (filter.conditions.length === 1) {
      return (
        <>
          <span className="font-semibold">{filter.shortName}</span> {OperatorDisplayMap[filter.conditions[0].operator]}{' '}
          {formatIfNumber(formatter, firstValue)}
        </>
      );
    } else {
      const secondValue = filter.conditions[1].values[0];
      return (
        <>
          <span className="font-semibold">{filter.shortName}</span> {formatIfNumber(formatter, firstValue)} to{' '}
          {formatIfNumber(formatter, secondValue)}
        </>
      );
    }
  };

  const getDateBetweenLabel = (filter: AlFilterModel, firstValue: string): JSX.Element => {
    if (!filter.conditions || filter.conditions.length === 0) {
      return <></>;
    }

    const isSameDate = (date1: Date, date2: Date): boolean => {
      const newDate1 = new Date(date1);
      const newDate2 = new Date(date2);

      newDate1.setHours(0, 0, 0, 0);
      newDate2.setHours(0, 0, 0, 0);
      return newDate1.getTime() === newDate2.getTime();
    };

    if (filter.conditions.length === 1) {
      // should not happen
      Sentry.captureMessage(
        `FilterChip: getDateBetweenLabel received 1 only condition. Filter: ${JSON.stringify(filter.toDTO())}, firstValue: ${firstValue}`,
        'info',
      );
      return (
        <>
          <span className="font-semibold">{filter.shortName}</span> {OperatorDisplayMap[filter.conditions[0].operator]}{' '}
          {formatDateStringTimeNoHours(firstValue)}
        </>
      );
    }

    // Both beginning and end provided
    const BEGINNING = 'the beginning';
    const TODAY = 'Today';

    const beginningOfTime = new Date(0);
    const today = new Date();

    const firstValueDate = new Date(firstValue);

    const beginning = isSameDate(firstValueDate, beginningOfTime)
      ? BEGINNING
      : isSameDate(firstValueDate, today)
        ? TODAY
        : formatDateStringTimeNoHours(firstValue);

    const secondValue = filter.conditions[1].values[0].toString();
    const ending = isSameDate(firstValueDate, today) ? TODAY : formatDateStringTimeNoHours(secondValue);

    if (beginning == TODAY && ending == TODAY) {
      return (
        <>
          <span className="font-semibold">{filter.shortName}</span> {TODAY}
        </>
      );
    }

    return (
      <>
        <span className="font-semibold">{filter.shortName}</span> <span className="italic">{beginning}</span> to{'   '}
        <span className="italic">{ending}</span>
      </>
    );
  };

  return { getLabelForFilter };
};

export default useChipLabelFormatting;
