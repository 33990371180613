import { AssignImportModel } from '@/modules/optimizer/api/campaign/models/AssignImportModel';
import { CampaignGroupModel } from '@/modules/optimizer/api/campaign/models/CampaignGroupModel';
import { CreateImportModel } from '@/modules/optimizer/api/campaign/models/CreateImportModel';
import { OptimizationPreset } from '@/modules/optimizer/components/optimization/OptimizerConfig';
import { toastService } from '@/services/toast.service';
import type { GridApi, GridReadyEvent } from 'ag-grid-enterprise';
import { isEmpty, isNil } from 'lodash-es';
import { useMemo, useRef } from 'react';
import { CampaignMappingImportDetails } from '../../../types/feedback';
import { CampaignGroupsCreateImportRow, FieldNamesCreate, NEW_ID_PREFIX } from '../types';

const useCreateData = (
  uploadedFileParsed: CampaignGroupsCreateImportRow[],
  importDetails: CampaignMappingImportDetails,
  updateImportDetails: (api: GridApi<CreateImportModel> | GridApi<AssignImportModel>) => void,
  groupNameToGroupMap: Record<string, CampaignGroupModel>,
) => {
  const gridApiRef = useRef<GridApi<CreateImportModel> | null>(null);

  // rowData is memoed, but something can be fetched again after a long time, don't show dupe removal message again
  const isNotifiedAboutDuplicatesRef = useRef(false);

  function importCreateRowsToModels(importedRows: CampaignGroupsCreateImportRow[]): CreateImportModel[] {
    const models = [];
    const uniqueRowIds = new Set();
    let duplicateCount = 0;

    for (const [index, importRow] of importedRows.entries()) {
      // Ignore rows with no GroupName
      if (isNil(importRow[FieldNamesCreate.GroupName]) || isEmpty(importRow[FieldNamesCreate.GroupName])) {
        continue;
      }

      // Use a unique identifier for each row to check for duplicates
      const uniqueIdentifier = importRow[FieldNamesCreate.GroupName];

      if (uniqueRowIds.has(uniqueIdentifier)) {
        // Don't count empty
        if (importRow[FieldNamesCreate.GroupName] !== '') {
          duplicateCount++;
        }
        continue; // Skip processing this row if it's a duplicate
      }

      uniqueRowIds.add(uniqueIdentifier);

      const existingGroup = groupNameToGroupMap[importRow[FieldNamesCreate.GroupName]];
      const rowId = existingGroup?.id.toString() || NEW_ID_PREFIX + index.toString();
      importRow[FieldNamesCreate.Prioritization] = convertPrioritizationInputToOptimizationPresetEnum(
        importRow[FieldNamesCreate.Prioritization],
      );
      const model = new CreateImportModel(rowId, importRow, groupNameToGroupMap);

      models.push(model);
    }

    setTimeout(() => {
      if (!isNotifiedAboutDuplicatesRef.current && duplicateCount > 0) {
        toastService.info(
          `${duplicateCount} duplicate${duplicateCount > 1 ? 's' : ''} row${duplicateCount > 1 ? 's' : ''} removed (opt group name)`,
        );
        isNotifiedAboutDuplicatesRef.current = true;
      }
    }, 0);

    return models;
  }

  // memo the conversion of the uploadedFileParsed to the format that the table expects
  const rowDataCreate = useMemo(() => {
    if (uploadedFileParsed.length <= 1) return [];
    const importedRows = uploadedFileParsed.slice(1); // remove headers

    const models = importCreateRowsToModels(importedRows);

    return models;
  }, [uploadedFileParsed, groupNameToGroupMap]);

  function onCreateGridReady(params: GridReadyEvent) {
    // Init: Try to gather errors and warnings. On row data setting and on grid ready
    gridApiRef.current = params.api;
    if (gridApiRef.current && importDetails.rowCount == 0) {
      updateImportDetails(gridApiRef.current);
    }
  }

  return {
    rowDataCreate,
    onCreateGridReady,
    createGridApiRef: gridApiRef,
  };
};

export default useCreateData;

// Make sure all these are lowecase
const VALID_PRIORITIZATION_VALUES_BALANCED = [OptimizationPreset.BALANCED.toLowerCase(), 'balanced'];
const VALID_PRIORITIZATION_VALUES_INCREASE_SALES = [OptimizationPreset.INCREASE_SALES.toLowerCase(), 'increase sales', 'increased sales'];
const VALID_PRIORITIZATION_VALUES_REDUCE_ACOS = [OptimizationPreset.REDUCE_ACOS.toLowerCase(), 'reduce acos'];
const VALID_PRIORITIZATION_VALUES_NOT_SET = [OptimizationPreset.NOT_SET.toLowerCase(), 'not set'];

function convertPrioritizationInputToOptimizationPresetEnum(prioritization: string | undefined): OptimizationPreset | string | undefined {
  if (!prioritization) return OptimizationPreset.NOT_SET;

  const p = prioritization?.toLowerCase().trim();
  if (VALID_PRIORITIZATION_VALUES_BALANCED.includes(p)) return OptimizationPreset.BALANCED;
  if (VALID_PRIORITIZATION_VALUES_INCREASE_SALES.includes(p)) return OptimizationPreset.INCREASE_SALES;
  if (VALID_PRIORITIZATION_VALUES_REDUCE_ACOS.includes(p)) return OptimizationPreset.REDUCE_ACOS;
  if (VALID_PRIORITIZATION_VALUES_NOT_SET.includes(p)) return OptimizationPreset.NOT_SET;
  return p;
}
