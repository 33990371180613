import { useConfirmationModal } from '@/components/modals/confirmation-modal/useConfirmationModal';
import { useNavigationConfirmationModal } from '@/components/modals/confirmation-modal/useNavigationConfirmationModal';
import Close from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { FileUploader } from '../../components/FileUploader';
import { useSheets } from '../../hooks/useSheets';
import { ImportStep } from '../../types/feedback';
import { ImportModalProps } from '../../types/import-definition';
import { CampaignGroupsPreview } from './CampaignGroupsPreview';
import {
  CAMPAIGN_GROUPS_ASSIGN_IMPORT_ROW_KEYS,
  CAMPAIGN_GROUPS_CREATE_IMPORT_ROW_KEYS,
  CampaignGroupsAssignImportRow,
  CampaignGroupsCreateImportRow,
  CampaignGroupsViewData,
} from './types';

export const CampaignGroupsImportModal: FunctionComponent<ImportModalProps> = ({ isOpen, onClose }) => {
  const [file, setFile] = useState<File | null>(null);
  const [uploadedFileParsed, setUploadedFileParsed] = useState<CampaignGroupsViewData>({
    [ImportStep.CREATE]: [],
    [ImportStep.ASSIGN]: [],
  });
  const { getFirstSheetData, getSecondSheetData } = useSheets();

  const { ModalComponent, handleOpenModal } = useConfirmationModal({
    questionText: 'Are you sure you want to cancel the import? All progress will be lost.',
    onConfirm: onClose,
    questionTitle: 'Cancel import',
    confirmButtonText: 'Cancel import',
    cancelButtonText: 'Continue importing',
  });

  const { ModalComponent: navigationModal, setBlock } = useNavigationConfirmationModal({
    questionText: 'Are you sure you want to cancel the import? All progress will be lost.',
  });

  async function onFileChanged(file: File | null) {
    setFile(file);

    if (!file) return;

    const [createParsedData, assignParsedData] = await Promise.all([
      getFirstSheetData(file, CAMPAIGN_GROUPS_CREATE_IMPORT_ROW_KEYS),
      getSecondSheetData(file, CAMPAIGN_GROUPS_ASSIGN_IMPORT_ROW_KEYS),
    ]);

    setUploadedFileParsed({
      [ImportStep.CREATE]: createParsedData as CampaignGroupsCreateImportRow[],
      [ImportStep.ASSIGN]: assignParsedData as CampaignGroupsAssignImportRow[],
    });
  }

  useEffect(() => {
    if (file) {
      setBlock(true);
    } else {
      setBlock(false);
    }
  }, [file]);

  function onCloseModalClicked() {
    handleOpenModal();
  }

  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen fullWidth>
      <DialogTitle>
        <div className="flex flex-row">
          Optimization Groups Import
          <div className="flex flex-grow"></div>
          <IconButton edge="start" color="inherit" onClick={onCloseModalClicked} aria-label="close">
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        {!file && (
          <div className="flex h-full w-full flex-1 flex-col items-center justify-center">
            <FileUploader file={file} onFileChanged={onFileChanged} />
          </div>
        )}
        {file && <CampaignGroupsPreview uploadedFile={file} uploadedFileParsed={uploadedFileParsed} onCloseModal={onClose} />}
      </DialogContent>
      {ModalComponent}
      {navigationModal}
    </Dialog>
  );
};
