import { isEmpty } from 'lodash-es';
import { FunctionComponent, useEffect, useRef, useState } from 'react';

interface EditableTextProps {
  text: string;
  onTextChange: (newText: string) => void;
  className?: string;
}

const EditableText: FunctionComponent<EditableTextProps> = ({ text, onTextChange, className }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentText, setCurrentText] = useState(text);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setCurrentText(text);
  }, [text]);

  const handleBlur = () => {
    if (divRef.current && currentText !== divRef.current.textContent) {
      let newText = divRef.current.textContent || '';
      newText = isEmpty(newText) ? text : newText.trim();

      divRef.current.textContent = newText; // needs to be set when reverting back to original text on empty
      setCurrentText(newText);
      onTextChange(newText);
    }
    setIsEditing(false);
  };

  const handleClick = () => {
    if (!isEditing) {
      setIsEditing(true);
    }

    if (divRef.current) {
      divRef.current.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (divRef.current) {
        divRef.current.blur();
      }
    }
  };

  return (
    <div
      ref={divRef}
      contentEditable={isEditing}
      suppressContentEditableWarning
      onBlur={handleBlur}
      onMouseDown={handleClick}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      className={`cursor-text focus:outline-none ${className || ''}`}
      onFocus={() => setIsEditing(true)}
      spellCheck={false}
    >
      {currentText}
    </div>
  );
};

export default EditableText;
