import { createSearchQueryPerformanceFilters } from '@/components/filter-builder/models/AlFilterModel';

const useAvailableSearchQueryPerformanceFilters = () => {
  const availableSearchQueryFilters = createSearchQueryPerformanceFilters();

  const availableSearchQueryFilterBuilderFilters = availableSearchQueryFilters.filter((filter) => filter.isFilterBuilderFilter);

  return {
    availableSearchQueryFilters,
    availableSearchQueryFilterBuilderFilters,
  };
};

export default useAvailableSearchQueryPerformanceFilters;
