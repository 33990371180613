import InfoMessage from '@/components/feedback/InfoMessage';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import useOptimizationApplyWarnings from '../../hooks/useOptimizationApplyWarnings';
import { OptimizationApplyData } from './OptimizerConfig';

interface OptimizationsApplyModalProps {
  selectedItems: OptimizationApplyData[];
  isOpen: boolean;
  onClose: () => void;
  onApply: () => void;
  isApplyLoading: boolean;
  note: string;
  setNote: (note: string) => void;
}

export const OptimizationsApplyModal: FunctionComponent<OptimizationsApplyModalProps> = ({
  selectedItems,
  isOpen,
  onClose,
  onApply,
  isApplyLoading,
  note,
  setNote,
}) => {
  const { warnings } = useOptimizationApplyWarnings({ selectedItems });

  return (
    <Dialog open={isOpen}>
      <DialogTitle>
        <div className="flex flex-row justify-between">
          <div>Optimize Bids</div>

          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className="max-w-lg">
        <Typography fontSize={14}>(Optional) Leave a note. This is for your team reference only.</Typography>
        <TextField
          fullWidth
          multiline
          placeholder='Description of changes (e.g. "High ACOS bid adjustments on premium sneakers")'
          rows={4}
          variant="outlined"
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />

        {warnings.length > 0 && (
          <div className="flex flex-col">
            {warnings.map((warning, index) => (
              <InfoMessage key={index} content={warning} />
            ))}
          </div>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="text" disabled={isApplyLoading}>
          Cancel
        </Button>
        <Button loading={isApplyLoading} onClick={onApply} color="primary" variant="contained" endIcon={<SendIcon />}>
          Send changes to Amazon
        </Button>
      </DialogActions>
    </Dialog>
  );
};
