import { MetricData, MetricDataWithPreviousDays } from '@/components/metrics/types/MetricData';
import { SelectedEntityForDataGroup } from '@/modules/data-groups/models/DataItem';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { CampaignAdType, CostType, MultiAdGroupsEnabledType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { MatchType } from '@/modules/optimizer/components/optimization/api/optimization-contracts';
import { EnabledPausedArchivedState } from '@/modules/optimizer/types/EnabledPausedArchivedState';
import { DTO } from '@/types/dto-wrapper';
import { isEmpty, isNil } from 'lodash-es';
import { TargetDTO, TargetEntityExtendedType, TargetEntityType } from '../api/targets-contracts';

// keyword and product target
export class TargetModel extends DTO<TargetDTO> implements SelectedEntityForDataGroup {
  constructor(data: TargetDTO) {
    super(data);
  }

  dataGroupType: DataGroupType = DataGroupType.TARGET;

  public get id(): string {
    return this.dto.i;
  }

  public get bid(): number {
    return this.dto.b;
  }

  public set bid(newBid: number) {
    this.dto.b = newBid;
  }

  public get state(): EnabledPausedArchivedState {
    return this.dto.s;
  }

  public get targetEntityType(): TargetEntityType {
    return this.dto.et;
  }

  public get entityExtendedType(): TargetEntityExtendedType {
    return getTargetEntityTypeByExpression(this.dto.et, this.dto.t);
  }

  public get matchType(): MatchType {
    return this.dto.mt;
  }

  public get targeting(): string {
    return this.dto.t;
  }

  public get adGroupName(): string {
    return this.dto.agn;
  }

  public get adGroupState(): string {
    return this.dto.ags;
  }

  public get campaignName(): string {
    return this.dto.cn;
  }

  public get portfolioName(): string {
    return this.dto.p ?? '';
  }

  public get campaignAdType(): CampaignAdType {
    return this.dto.ct;
  }

  public get campaignAdTypeTv(): boolean {
    return this.campaignAdType == CampaignAdType.TV;
  }

  public get campaignState(): EnabledPausedArchivedState {
    return this.dto.cs;
  }

  public get campaignCostType(): CostType {
    return isEmpty(this.dto.co) ? CostType.NONE : this.dto.co;
  }

  public get groupName(): string {
    return this.dto.gn;
  }

  public get multiAdGroupsEnabled(): MultiAdGroupsEnabledType {
    return this.dto.mag == true
      ? MultiAdGroupsEnabledType.TRUE
      : this.dto.mag == false
        ? MultiAdGroupsEnabledType.FALSE
        : MultiAdGroupsEnabledType.NONE;
  }

  public get lastOptimizedAt(): string | null {
    return this.dto.lo;
  }

  public get campaignLastOptimizedAt(): string | null {
    return this.dto.clo;
  }

  public get campaignIsVideo(): boolean {
    return this.dto.v ?? false;
  }

  public get campaignBudgetAmount(): number {
    return this.dto.cb;
  }

  public get hasOptRule(): boolean {
    // existing opt rule doesn't allow bid changing (manual change, optimizing etc)
    return this.dto.o ?? false;
  }

  public get isBidChangeEnabled(): boolean {
    const stateAllowsEditing =
      this.campaignState !== EnabledPausedArchivedState.ARCHIVED && this.state !== EnabledPausedArchivedState.ARCHIVED;

    return !this.hasOptRule && !this.isLegacyAutoTarget && !this.campaignAdTypeTv && stateAllowsEditing;
  }

  public get impressions(): MetricData {
    return this.dto.impressions;
  }

  public get clicks(): MetricData {
    return this.dto.clicks;
  }

  public get orders(): MetricData {
    return this.dto.orders;
  }

  public get units(): MetricData {
    return this.dto.units;
  }

  public get ctr(): MetricData {
    return this.dto.ctr;
  }

  public get cvr(): MetricData {
    return this.dto.cvr;
  }

  public get cpc(): MetricData {
    return this.dto.cpc;
  }

  public get spend(): MetricDataWithPreviousDays {
    return this.dto.spend;
  }

  public get sales(): MetricDataWithPreviousDays {
    return this.dto.sales;
  }

  public get sso(): MetricData {
    return this.dto.sso;
  }

  public get sss(): MetricData {
    return this.dto.sss;
  }

  public get acos(): MetricData {
    return this.dto.acos;
  }

  public get actc(): MetricData {
    return this.dto.actc;
  }

  public get roas(): MetricData {
    return this.dto.roas;
  }

  public get rpc(): MetricData {
    return this.dto.rpc;
  }

  public get cpa(): MetricData {
    return this.dto.cpa;
  }

  public get aov(): MetricData {
    return this.dto.aov;
  }

  public get cpm(): MetricData {
    return this.dto.cpm;
  }

  public get campaignDataItemIds(): number[] {
    return this.dto.cd ?? [];
  }

  // targetingDataItemIds
  public get dataItemIds(): number[] {
    return this.dto.td ?? [];
  }

  public get isLegacyAutoTarget(): boolean {
    return this.targeting == '(_targeting_auto_)';
  }

  public get reportedAt(): string {
    return this.dto.r;
  }

  public static fromResponse(dto: TargetDTO): TargetModel {
    return new TargetModel(dto);
  }

  public static fromResponseArray(dtos: TargetDTO[] | null): TargetModel[] {
    return isNil(dtos) ? [] : dtos.map(TargetModel.fromResponse);
  }
}

function getTargetEntityTypeByExpression(entityType?: TargetEntityType, expression?: string): TargetEntityExtendedType {
  if (expression?.startsWith('audience=') || expression?.startsWith('views=') || expression?.startsWith('purchases=')) {
    return 'AUDIENCE';
  }
  return entityType ?? TargetEntityType.KEYWORD;
}
