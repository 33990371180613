import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip, useTheme } from '@mui/material';
import { FunctionComponent, PropsWithChildren } from 'react';

interface InfoIconWithTooltipProps extends PropsWithChildren {
  text: string | undefined;
  placement?:
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start';
}

const InfoIconWithTooltip: FunctionComponent<InfoIconWithTooltipProps> = ({ text, placement = 'bottom' }) => {
  const theme = useTheme();
  return (
    <div className="flex flex-row">
      <Tooltip title={text} placement={placement}>
        <InfoOutlinedIcon fontSize="small" style={{ color: theme.palette.info.main }} />
      </Tooltip>
    </div>
  );
};

export default InfoIconWithTooltip;
