import {
  CampaignGroupsAssignImportRow,
  CampaignGroupsAssignMaps,
  FieldNamesAssign,
} from '@/modules/data-management/importers/campaign-groups/types';
import { HasValidation } from '@/modules/data-management/types/feedback';
import { isEmpty, isNil } from 'lodash-es';

export class AssignImportModel implements HasValidation<AssignImportModel> {
  public id: string;
  private campaignGroupsAssignImportRow: CampaignGroupsAssignImportRow;
  private _campaignId: string | null;
  private _groupId: string | null;

  public isGroupExisting: boolean;
  private _isCampaignNameDuplicate: boolean = false; //duplicate compared to other campaigns in the column - modified externally after init and already in grid

  private _campaignGroupsAssignMaps: CampaignGroupsAssignMaps;

  public validationErrors: Partial<Record<keyof AssignImportModel, string>> = {};
  public validationWarnings: Partial<Record<keyof AssignImportModel, string>> = {};

  constructor({ id, campaignGroupsAssignImportRow, campaignGroupsAssignMaps }: AssignImportModelArguments) {
    this.id = id;
    this.campaignGroupsAssignImportRow = campaignGroupsAssignImportRow;
    this._campaignGroupsAssignMaps = campaignGroupsAssignMaps;

    const inputCampaignName = isEmpty(campaignGroupsAssignImportRow[FieldNamesAssign.CampaignName])
      ? null
      : campaignGroupsAssignImportRow[FieldNamesAssign.CampaignName].trim();
    this._campaignId = this._campaignGroupsAssignMaps.campaignNameToCampaignIdsMap[inputCampaignName ?? '']?.[0] ?? null;

    const inputGroupName = isEmpty(campaignGroupsAssignImportRow[FieldNamesAssign.GroupName])
      ? null
      : campaignGroupsAssignImportRow[FieldNamesAssign.GroupName].trim();

    const groupName = inputGroupName ?? '';
    const existingGroup = this._campaignGroupsAssignMaps.groupNameToGroupMap[groupName];
    const newGroup = this._campaignGroupsAssignMaps.newGroupNameToCreateGroupMap[groupName];

    if (newGroup) {
      this._groupId = newGroup.id;
      this.isGroupExisting = false;
    } else if (existingGroup) {
      this._groupId = existingGroup.id.toString();
      this.isGroupExisting = true;
    } else {
      this.isGroupExisting = false;
      this._groupId = null;
    }

    this.validate();
  }

  public get campaignName(): string {
    return this.campaignGroupsAssignImportRow[FieldNamesAssign.CampaignName];
  }

  public get isCampaignNameDuplicate(): boolean {
    return this._isCampaignNameDuplicate;
  }

  public set isCampaignNameDuplicate(value: boolean) {
    this._isCampaignNameDuplicate = value;
    this.validate();
  }

  public get groupName(): string {
    return this.campaignGroupsAssignImportRow[FieldNamesAssign.GroupName];
  }

  public get campaignId(): string | null {
    return this._campaignId;
  }

  public set campaignId(value: string | null) {
    this._campaignId = value;

    const campaign = this._campaignGroupsAssignMaps.campaignToAdGroupsMap[value ?? ''] ?? null;
    this.campaignGroupsAssignImportRow[FieldNamesAssign.CampaignName] = campaign?.name ?? null;

    this.validate();
  }

  public get groupId(): string | null {
    return this._groupId;
  }

  public set groupId(value: string | null) {
    this._groupId = value;

    const existingGroup = this._campaignGroupsAssignMaps.groupIdToGroupMap[value ?? ''];
    const newGroup = this._campaignGroupsAssignMaps.newGroupIdToCreateGroupMap[value ?? ''];

    if (newGroup) {
      this._groupId = newGroup.id;
      this.campaignGroupsAssignImportRow[FieldNamesAssign.GroupName] = newGroup.name ?? null;
      this.isGroupExisting = false;
    } else if (existingGroup) {
      this._groupId = existingGroup.id.toString();
      this.campaignGroupsAssignImportRow[FieldNamesAssign.GroupName] = existingGroup.name ?? null;
      this.isGroupExisting = true;
    } else {
      this._groupId = null;
      this.campaignGroupsAssignImportRow[FieldNamesAssign.GroupName] = '';
      this.isGroupExisting = false;
    }

    this.validate();
  }

  public validate() {
    this.validationErrors = {};
    this.validationWarnings = {};

    // Campaign Name
    if (!this.campaignId) {
      // TODO: fuzzy match name if incorrect and "... did you mean ..."
      this.validationErrors.campaignId = 'Unable to match given campaign name with existing campaign';
    } else if (this._isCampaignNameDuplicate) {
      this.validationErrors.campaignId = 'Campaign name is duplicated in the table';
    } else if (isEmpty(this.campaignName) || isNil(this.campaignName)) {
      this.validationErrors.campaignName = 'Campaign name cannot be empty';
    } else if (this._campaignGroupsAssignMaps.campaignNameToCampaignIdsMap[this.campaignName ?? '']?.length > 1) {
      this.validationWarnings.campaignId = 'Multiple campaigns exist with given name';
    }

    // Group Name
    if (isEmpty(this.groupName) || isNil(this.groupName)) {
      this.validationErrors.groupId = 'Group name cannot be empty';
    } else if (
      isNil(this._campaignGroupsAssignMaps.newGroupNameToCreateGroupMap[this.groupName]) &&
      !this._campaignGroupsAssignMaps.groupNameToGroupMap[this.groupName]
    ) {
      this.validationErrors.groupId = 'Unable to match given group name with a group (neither new nor existing)';
    }
  }
}

interface AssignImportModelArguments {
  id: string;
  campaignGroupsAssignImportRow: CampaignGroupsAssignImportRow;
  campaignGroupsAssignMaps: CampaignGroupsAssignMaps;
}
