import { ColumnId } from '@/components/grid/columns/columns.enum';
import type { ColumnState } from 'ag-grid-enterprise';

export function generateSearchTermsTableColumnState(): ColumnState[] {
  return [
    { colId: ColumnId.CHECKBOX, hide: false, pinned: 'left' },
    { colId: ColumnId.SEARCH_TERM, hide: false, width: 220, pinned: 'left' },
    { colId: ColumnId.EXISTING_MATCH_TYPES, hide: false, width: 110, pinned: 'left' },
    { colId: ColumnId.HARVESTED, hide: false, width: 87, pinned: 'left' },
    { colId: ColumnId.NEGATIVES, hide: false, width: 87, pinned: 'left' },
    { colId: ColumnId.TARGETING, hide: false, width: 170, pinned: 'left' },
    { colId: ColumnId.ENTITY_TYPE, hide: false, width: 66, pinned: 'left' },
    { colId: ColumnId.BID, hide: false, width: 62, pinned: 'left' },
    { colId: ColumnId.WORD_COUNT, hide: true, width: 64 },
    { colId: ColumnId.MATCH, hide: false, pinned: false, width: 80 },
    { colId: ColumnId.CAMPAIGN_NAME, hide: false, pinned: false },
    { colId: ColumnId.PORTFOLIO_NAME, hide: true, pinned: false },
    { colId: ColumnId.STATE, hide: true, pinned: false },
    { colId: ColumnId.CAMPAIGN_STATE, hide: true, pinned: false },
    { colId: ColumnId.COST_TYPE, hide: true, pinned: false },
    { colId: ColumnId.CAMPAIGN_AD_TYPE, hide: true, pinned: false },
    { colId: ColumnId.GROUP_NAME, hide: true, pinned: false },
    { colId: ColumnId.SALES_PREVIOUS_DAYS, hide: false, pinned: false },
    { colId: ColumnId.SPEND_PREVIOUS_DAYS, hide: false, pinned: false },
    { colId: ColumnId.IMPRESSIONS, hide: false, pinned: false },
    { colId: ColumnId.CLICKS, hide: false, pinned: false },
    { colId: ColumnId.ORDERS, hide: false, pinned: false },
    { colId: ColumnId.UNITS, hide: false, pinned: false },
    { colId: ColumnId.SALES, hide: false, pinned: false },
    { colId: ColumnId.SPEND, hide: false, pinned: false },
    { colId: ColumnId.SAME_SKU_ORDERS, hide: false, pinned: false },
    { colId: ColumnId.SAME_SKU_SALES, hide: false, pinned: false },
    { colId: ColumnId.OTHER_SKU_SALES, hide: false, pinned: false },
    { colId: ColumnId.ACOS, hide: false, pinned: false },
    { colId: ColumnId.ACTC, hide: true, pinned: false },
    { colId: ColumnId.CTR, hide: false, pinned: false },
    { colId: ColumnId.CVR, hide: false, pinned: false },
    { colId: ColumnId.CPC, hide: false, pinned: false },
    { colId: ColumnId.ROAS, hide: true, pinned: false },
    { colId: ColumnId.RPC, hide: true, pinned: false },
    { colId: ColumnId.CPA, hide: true, pinned: false },
    { colId: ColumnId.AOV, hide: true, pinned: false },
    { colId: ColumnId.CPM, hide: true, pinned: false },
    { colId: ColumnId.AD_GROUP, hide: true, pinned: false },
    { colId: ColumnId.AD_GROUP_STATE, hide: true, pinned: false },
    { colId: ColumnId.ENTITY_ID, hide: true, pinned: false },
  ];
}
