import { FunctionComponent, PropsWithChildren, useContext, useCallback, useState, useEffect } from 'react';
import { DashboardWidgetContext } from './DashboardWidgetContextProvider';
import LoadingBlock from '@/components/feedback/LoadingBlock';
import { useDashboardContextValue } from '../../contexts/DashboardContextProvider';
import { Settings } from '@mui/icons-material';
import { isEmpty } from 'lodash-es';
import { useDynamicHandleSize } from '../../hooks/useDynamicHandleSize';
import DashboardWidgetConfigurationButton from './DashboardWidgetConfigurationButton';

interface DashboardWidgetContentProps extends PropsWithChildren {
  onMouseChange: (isMouseOnWidget: boolean) => void;
}

const DashboardWidgetContent: FunctionComponent<DashboardWidgetContentProps> = ({ children, onMouseChange }) => {
  const { isLoading, setIsConfiguring, widgetId, isConfigured } = useContext(DashboardWidgetContext);
  const [isMouseOnWidget, setIsMouseOnWidget] = useState(false);

  const { setEditingWidgetId, editingWidgetId, isExternal } = useDashboardContextValue((context) => ({
    setEditingWidgetId: context.setEditingWidgetId,
    editingWidgetId: context.editingWidgetId,
    isExternal: context.isExternal,
  }));

  const { containerRef } = useDynamicHandleSize();

  const handleMouseEnter = useCallback(() => {
    onMouseChange(true);
    setIsMouseOnWidget(true);
  }, [onMouseChange]);

  const handleMouseLeave = useCallback(() => {
    onMouseChange(false);
    setIsMouseOnWidget(false);
  }, [onMouseChange]);

  useEffect(() => {
    if (isEmpty(editingWidgetId)) {
      onMouseChange(false);
      setIsMouseOnWidget(false);
    }
  }, [editingWidgetId]);

  if (isLoading && isConfigured) {
    return <LoadingBlock />;
  }

  return (
    <div
      ref={containerRef}
      className="relative flex h-full w-full flex-col"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onLostPointerCapture={handleMouseLeave}
    >
      {!isConfigured ? (
        <>
          {isExternal ? (
            <div className="flex-1 flex items-center justify-center">
              <span>This widget is not configured.</span>
            </div>
          ) : (
            <div className="w-full h-full rounded-md flex items-center justify-center relative">
              <DashboardWidgetConfigurationButton isMouseOnWidget={isMouseOnWidget}></DashboardWidgetConfigurationButton>
              <div
                className="flex flex-col items-center justify-center space-y-1 cursor-pointer absolute z-50 hover:underline group"
                onMouseDown={() => {
                  setIsConfiguring(true);
                  setEditingWidgetId(widgetId ?? '');
                }}
              >
                <Settings className="text-gray-400 w-8 h-8 group-hover:text-primary-500" />
                <span className="text-xs text-gray-700">Configure this widget</span>
              </div>
            </div>
          )}
        </>
      ) : (
        children
      )}
    </div>
  );
};

export default DashboardWidgetContent;
