import { teamService } from '@/modules/teams';
import { createOrgProfilesQueryKey } from '@/modules/teams/api/teams.service';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash-es';

const useOrgProfiles = () => {
  const { activeTeam } = useActiveTeamContext();

  const {
    data: orgProfiles,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: createOrgProfilesQueryKey(activeTeam?.id),
    queryFn: async () => {
      const result = await teamService.getAllOrgProfilesByTeamId(activeTeam?.id ?? 0);
      if (result.isSuccess) {
        return result.payload;
      } else {
        throw new Error('Error loading org profiles filters\n' + JSON.stringify(result));
      }
    },
    enabled: !isNil(activeTeam),
  });

  return { orgProfiles, isLoading, isFetching };
};

export default useOrgProfiles;
