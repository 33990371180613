import { MoreVertOutlined } from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Box, IconButton, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Menu, MenuItem, Tooltip } from '@mui/material';
import { isNil } from 'lodash-es';
import { FunctionComponent, MouseEvent, useState } from 'react';
import { FilterPresetModel } from '../models/FilterPresetModel';
import { WrappedSavedFilterPreset } from '../types/SavedPresets';

interface SavedPresetsListGroupProps {
  isNewPresetSelected: boolean;
  selectedPresetId: number | 'new' | null; // TODO: extract type
  header: string;
  presets: WrappedSavedFilterPreset[];
  searchText: string;
  handleSelect: (preset: FilterPresetModel) => void;
  onDuplicate: (presetId: number) => void;
  onDelete: (presetId: number) => void;
  onStartUpdatingExistingPreset: (presetId: number) => void;
}

export const SavedPresetsListGroup: FunctionComponent<SavedPresetsListGroupProps> = ({
  isNewPresetSelected,
  selectedPresetId,
  header,
  presets,
  searchText,
  handleSelect,
  onDuplicate,
  onDelete,
  onStartUpdatingExistingPreset,
}) => {
  const [hoveredPresetId, setHoveredPresetId] = useState<number | null>(null);

  function ListItemClicked(preset: FilterPresetModel) {
    // When menu is open, do not select preset from list
    if (!isNil(anchorEl)) return;

    handleSelect(preset);
  }

  // Handle menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMoreClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setHoveredPresetId(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <ListSubheader className="bg-slate-50 pb-0.5 pt-4 text-xxs font-bold uppercase leading-none tracking-wider text-gray-400">
        {header}
      </ListSubheader>

      {presets.map((wrappedPreset) => (
        <Tooltip title={wrappedPreset.tooltip} key={wrappedPreset.preset.id} placement="right" disableHoverListener={open}>
          <span>
            <ListItemButton
              id={`preset-${wrappedPreset.preset.id}`}
              selected={!isNewPresetSelected && selectedPresetId === wrappedPreset.preset.id}
              onClick={wrappedPreset.disabled ? undefined : () => ListItemClicked(wrappedPreset.preset)}
              className={`py-1 ${wrappedPreset.disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
              onMouseEnter={() => setHoveredPresetId(wrappedPreset.preset.id)}
              onMouseLeave={() => setHoveredPresetId(null)}
              style={{ position: 'relative' }}
            >
              {/* Display team or personal icon based on preset level */}
              {wrappedPreset.preset.level === 'TEAM' ? (
                <GroupsOutlinedIcon fontSize="small" className="text-gray-500" />
              ) : wrappedPreset.preset.level === 'PERSONAL' ? (
                <PersonOutlineOutlinedIcon fontSize="small" className="text-gray-500" />
              ) : null}

              {/* Main List Text */}
              <ListItemText
                className="ml-2"
                slotProps={{
                  primary: {
                    className: `text-sm ${wrappedPreset.preset.id === selectedPresetId ? 'text-primary-500 font-semibold' : ''}`,
                  },
                }}
                primary={highlightMatch(wrappedPreset.preset.name, searchText)}
              />

              <ListItemIcon
                className="flex flex-row items-center justify-end"
                style={{
                  position: 'absolute',
                  right: 0,
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              >
                {hoveredPresetId === wrappedPreset.preset.id && (
                  // Adding onMouseLeave ensures that when the mouse leaves the button/menu region,
                  // the handleMenuClose is called to clear the anchor.
                  <Box sx={{ pointerEvents: 'auto' }} onMouseLeave={() => setAnchorEl(null)}>
                    <IconButton onClick={handleMoreClick} sx={{ borderRadius: 1 }}>
                      <MoreVertOutlined />
                    </IconButton>

                    <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
                      <SavedPresetsListGroupMenuItems
                        presetId={wrappedPreset.preset.id}
                        onDuplicate={onDuplicate}
                        onDelete={onDelete}
                        onStartUpdatingExistingPreset={onStartUpdatingExistingPreset}
                        wrappedPreset={wrappedPreset}
                        onClose={handleMenuClose}
                      />
                    </Menu>
                  </Box>
                )}

                {!isNewPresetSelected && selectedPresetId === wrappedPreset.preset.id && hoveredPresetId !== wrappedPreset.preset.id && (
                  <ChevronRightIcon className="mr-1" />
                )}
              </ListItemIcon>
            </ListItemButton>
          </span>
        </Tooltip>
      ))}
    </>
  );
};

function highlightMatch(text: string, query: string) {
  if (!query) return text;
  const safeQuery = escapeRegExp(query);
  const parts = text.split(new RegExp(`(${safeQuery})`, 'gi'));
  return (
    <span>
      {parts.map((part, index) => (
        <span
          key={index}
          style={{
            fontWeight: part.toLowerCase() === query.toLowerCase() ? 'bold' : 'normal',
          }}
        >
          {part}
        </span>
      ))}
    </span>
  );
}

function escapeRegExp(text: string): string {
  return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

interface SavedPresetsListGroupMenuProps {
  presetId: number;
  onDuplicate: (presetId: number) => void;
  onDelete: (presetId: number) => void;
  onStartUpdatingExistingPreset: (presetId: number) => void;
  wrappedPreset: WrappedSavedFilterPreset;
  onClose: () => void;
}

const SavedPresetsListGroupMenuItems: FunctionComponent<SavedPresetsListGroupMenuProps> = ({
  onDuplicate,
  onDelete,
  onStartUpdatingExistingPreset,
  wrappedPreset,
  onClose,
}) => {
  return (
    <>
      <MenuItem
        className="p-0"
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        {!wrappedPreset.disabled && (
          <>
            <Tooltip title="Edit">
              <span>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    onStartUpdatingExistingPreset(wrappedPreset.preset.id);
                    onClose();
                  }}
                  color="primary"
                  sx={{
                    borderRadius: 1,
                  }}
                >
                  <SettingsOutlinedIcon fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>

            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                onDuplicate(wrappedPreset.preset.id);
                onClose();
              }}
              color="primary"
              sx={{
                borderRadius: 1,
              }}
            >
              <Tooltip title="Duplicate">
                <ContentCopyIcon fontSize="small" />
              </Tooltip>
            </IconButton>
          </>
        )}

        <Tooltip title="Delete">
          <span>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(wrappedPreset.preset.id);
                onClose();
              }}
              color="error"
              sx={{
                borderRadius: 1,
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </MenuItem>
    </>
  );
};
