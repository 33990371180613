import {
  ComparisonDateFilterModel,
  DateFilterModel,
  FilterCondition,
  getDefaultStartEndDate,
  LogicalOperatorType,
  OperatorType,
} from '@/components/filter-builder/models/AlFilterModel';
import { AlDate, AlDateRawType } from '@/lib/date/AlDate';
import { PaywallModal } from '@/modules/plans/components/PaywallModal';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useUserContext } from '@/modules/users';
import { Routes } from '@/router/router-paths';
import { Box, Button, Chip, Paper, Tooltip } from '@mui/material';
import {
  DateRange,
  DateRangePickerDay,
  DateRangePickerDayProps,
  LocalizationProvider,
  PickersShortcutsItem,
  PickersShortcutsProps,
} from '@mui/x-date-pickers-pro';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { DateTime } from 'luxon';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { DayStyleConfig } from '../../types/DayStyleConfig';
import { getWeekPickerShortcutsItems } from './WeekRangePickerConfig';

interface WeekRangePickerProps {
  title: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onSubmit: (filters: DateFilterModel[]) => void;
  defaultDateRange: FilterCondition[] | undefined;
  defaultComparisonDateRange: FilterCondition[] | undefined;
  disablePaywall?: boolean;
  hideComparison?: boolean;
  minDateString?: string;
  getDayStyleConfig: (day: DateTime<boolean>) => DayStyleConfig;
}

export default function WeekRangePicker({
  title,
  setIsOpen,
  onSubmit,
  defaultDateRange,
  defaultComparisonDateRange,
  disablePaywall,
  hideComparison = false,
  minDateString,
  getDayStyleConfig,
}: WeekRangePickerProps) {
  const { activeTeam } = useActiveTeamContext();
  const { isAdminModeActive } = useUserContext();

  const { startDate, endDate, comparisonStartDate, comparisonEndDate } = getDefaultStartEndDate();

  const [mainCalendarDateRange, setMainCalendarDateRange] = useState<DateRange<AlDateRawType>>(
    defaultDateRange
      ? [AlDate.parse(defaultDateRange[0].values[0].toString()).toRaw(), AlDate.parse(defaultDateRange[1].values[0].toString()).toRaw()]
      : [startDate.toRaw(), endDate.toRaw()],
  );

  const [comparisonCalendarDateRange, setComparisonCalendarDateRange] = useState<DateRange<AlDateRawType>>(
    defaultComparisonDateRange
      ? [
          AlDate.parse(defaultComparisonDateRange[0].values[0].toString()).toRaw(),
          AlDate.parse(defaultComparisonDateRange[1].values[0].toString()).toRaw(),
        ]
      : [comparisonStartDate.toRaw(), comparisonEndDate.toRaw()],
  );

  // Default comparison shortcut (will be updated in the useEffect below)
  const [comparisonCalenderShortcutsItems, setComparisonCalenderShortcutsItems] = useState<PickersShortcutsItem<DateRange<AlDateRawType>>[]>([
    { label: 'Custom', getValue: (): DateRange<AlDateRawType> => [null, null] },
  ]);

  // Whenever the main calendar date range changes, enforce full-week selection (Sunday–Saturday)
  // and update the comparison shortcuts accordingly.
  const handleMainCalendarDateChange = (newValue: DateRange<AlDateRawType>) => {
    // Ensure both dates are selected
    if (newValue[0] && newValue[1]) {
      const selectedStart = AlDate.fromRaw(newValue[0]);
      const selectedEnd = AlDate.fromRaw(newValue[1]);

      if (!selectedStart || !selectedEnd) return;

      // Expand the selected range to full week boundaries:
      const weekStart = selectedStart.startOf('week'); // Sunday
      const weekEnd = selectedEnd.endOf('week'); // Saturday

      // Update the main calendar date range with full week boundaries:
      setMainCalendarDateRange([weekStart.toRaw(), weekEnd.toRaw()]);

      // Optionally update the comparison range based on the full week range:
      const periodLengthInDays = weekEnd.diff(weekStart, 'days');
      const comparisonStart = weekStart.subtractDays(periodLengthInDays);
      const comparisonEnd = weekEnd.subtractDays(periodLengthInDays + 1);
      setComparisonCalendarDateRange([comparisonStart.toRaw(), comparisonEnd.toRaw()]);
    } else {
      setMainCalendarDateRange(newValue);
      setComparisonCalendarDateRange([null, null]);
    }
  };

  const handleComparisonCalendarDateChange = (newValue: DateRange<AlDateRawType>) => {
    if (newValue[0] && newValue[1]) {
      setComparisonCalendarDateRange(newValue);
    }
  };

  // Update comparison shortcuts based on the selected main calendar week range.
  // We offer two shortcuts: "Previous Period" and "Previous Year Period".
  useEffect(() => {
    if (mainCalendarDateRange[0] && mainCalendarDateRange[1]) {
      const mainStart = AlDate.fromRaw(mainCalendarDateRange[0]);
      const mainEnd = AlDate.fromRaw(mainCalendarDateRange[1]);

      if (!mainStart || !mainEnd) return;

      const periodLengthInDays = mainEnd.diff(mainStart, 'days') + 1;

      const previousPeriodStart = mainStart.subtractDays(periodLengthInDays);
      const previousPeriodEnd = mainEnd.subtractDays(periodLengthInDays);

      const previousYearStart = mainStart.minus({ years: 1 });
      const previousYearEnd = mainEnd.minus({ years: 1 });

      setComparisonCalenderShortcutsItems([
        {
          label: 'Previous Period',
          getValue: (): DateRange<AlDateRawType> => [previousPeriodStart.toRaw(), previousPeriodEnd.toRaw()],
        },
        {
          label: 'Previous Year Period',
          getValue: (): DateRange<AlDateRawType> => [previousYearStart.toRaw(), previousYearEnd.toRaw()],
        },
      ]);
    }
  }, [mainCalendarDateRange]);

  const minDate = minDateString ? AlDate.parse(minDateString).toRaw() : undefined;

  function CustomDateRangePickerDay(props: DateRangePickerDayProps<DateTime>) {
    const { day, ...other } = props;
    const dayStyleConfig = getDayStyleConfig(day);
    return (
      <Tooltip title={dayStyleConfig.tooltip} slotProps={{ popper: { disablePortal: true } }}>
        <DateRangePickerDay {...other} day={day} style={dayStyleConfig.style} />
      </Tooltip>
    );
  }

  function onApplyDateRangeFilterClicked() {
    const startDateObj = AlDate.fromRaw(mainCalendarDateRange[0]);
    const endDateObj = AlDate.fromRaw(mainCalendarDateRange[1]);

    const newFilterValues: DateFilterModel[] = [];

    if (startDateObj && endDateObj) {
      const periodLengthInDays = endDateObj.diff(startDateObj, 'days');
      if (
        !isAdminModeActive &&
        activeTeam &&
        periodLengthInDays > activeTeam.subscriptionPlan.dateRangeLengthLimitInDays &&
        !disablePaywall
      ) {
        setIsPaywallModalOpen(true);
        return;
      }

      const newFilter = new DateFilterModel({
        logicalOperator: LogicalOperatorType.AND,
        conditions: [
          {
            values: [startDateObj.toDefaultFormat()],
            operator: OperatorType.GREATER_THAN_OR_EQUAL,
          },
          {
            values: [endDateObj.toDefaultFormat()],
            operator: OperatorType.LESS_THAN_OR_EQUAL,
          },
        ],
      });
      newFilterValues.push(newFilter);
    }

    if (comparisonCalendarDateRange[0] && comparisonCalendarDateRange[1]) {
      const newFilter = new ComparisonDateFilterModel({
        logicalOperator: LogicalOperatorType.AND,
        conditions: [
          {
            values: [AlDate.fromRaw(comparisonCalendarDateRange[0])?.toDefaultFormat() ?? ''],
            operator: OperatorType.GREATER_THAN_OR_EQUAL,
          },
          {
            values: [AlDate.fromRaw(comparisonCalendarDateRange[1])?.toDefaultFormat() ?? ''],
            operator: OperatorType.LESS_THAN_OR_EQUAL,
          },
        ],
      });
      newFilterValues.push(newFilter);
    }

    if (newFilterValues.length > 0) onSubmit(newFilterValues);
    setIsOpen(false);
  }

  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const onClosePaywallModal = () => {
    setIsPaywallModalOpen(false);
  };

  return (
    <LocalizationProvider dateAdapter={AlDate.adapter}>
      <PaywallModal isOpen={isPaywallModalOpen} onClose={onClosePaywallModal} returnURLPath={Routes.BILLING}>
        {`Free tier limit of ${activeTeam?.subscriptionPlan.dateRangeLengthLimitInDays} days reached. Upgrade to access unlimited data retention.`}
      </PaywallModal>

      <div className="flex h-full flex-col justify-between px-2 pt-4">
        <div>
          <div className="my-0 ml-4 text-lg font-bold">{title}</div>
          <StaticDateRangePicker
            className="bg-transparent"
            slots={{
              day: CustomDateRangePickerDay,
              toolbar: () => <div> </div>,
            }}
            slotProps={{
              shortcuts: {
                items: getWeekPickerShortcutsItems(),
                sx: { p: 2, width: 250 },
              },
              actionBar: { actions: [] },
            }}
            disableFuture
            minDate={minDate}
            calendars={2}
            value={mainCalendarDateRange}
            onChange={handleMainCalendarDateChange}
          />
        </div>
        {!hideComparison && (
          <div>
            <StaticDateRangePicker
              className="bg-transparent"
              slots={{
                day: CustomDateRangePickerDay,
                toolbar: () => <div> </div>,
                shortcuts: (props) => getShortcutsWithTitle('Comparison Period', props),
              }}
              slotProps={{
                shortcuts: {
                  items: comparisonCalenderShortcutsItems,
                  sx: { p: 2, width: 250 },
                  dense: true,
                  disablePadding: true,
                },
                actionBar: { actions: [] },
              }}
              disableFuture
              calendars={2}
              minDate={minDate}
              value={comparisonCalendarDateRange}
              onChange={handleComparisonCalendarDateChange}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                '& .MuiDateRangePicker-shortcuts': {
                  flexBasis: '250px',
                  flexShrink: 0,
                },
                '& .MuiDateRangePicker-content': {
                  flexGrow: 1,
                },
              }}
            />
          </div>
        )}
      </div>
      <Paper
        square
        className="sticky bottom-0 left-0 right-0 flex flex-row justify-end gap-2 border-t px-3 py-2 dark:border-gray-700 dark:bg-gray-700"
      >
        <Button variant="text" onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button onClick={onApplyDateRangeFilterClicked}>Apply Date Ranges</Button>
      </Paper>
    </LocalizationProvider>
  );
}

function getShortcutsWithTitle(title: string, props: PickersShortcutsProps<DateRange<AlDateRawType>>) {
  const { items, onChange, isValid, changeImportance = 'accept', disablePadding, sx } = props;

  const resolvedItems = items?.map((item) => {
    const newValue = item.getValue({ isValid });
    return {
      label: item.label,
      onClick: () => {
        onChange(newValue, changeImportance, item);
      },
      disabled: !isValid(newValue),
    };
  });

  return (
    <Box sx={{ ...sx, p: disablePadding ? 0 : 2 }}>
      <div className="mt-4 mb-2 ml-4 text-lg font-bold">{title}</div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          gap: 8,
          marginLeft: 16,
          alignItems: 'flex-start',
        }}
      >
        {resolvedItems?.map((item, index) => <Chip key={index} {...item} />)}
      </Box>
    </Box>
  );
}
