import { RouteObject } from 'react-router';
import SearchQueryPerformancePage from '../pages/SearchQueryPerformancePage';

export const SEARCH_QUERY_PERFORMANCE_ROUTES: RouteObject[] = [
  {
    path: 'search-query-performance',
    children: [
      {
        index: true,
        element: <SearchQueryPerformancePage />,
      },
    ],
  },
];
