import { CommonMetricsDTO, MetricsDTO } from '@/components/metrics/api/metrics-contracts';
import { MetricModel } from '@/components/metrics/models/MetricModel';
import { CommonMetricField } from '@/components/metrics/types/MetricField';
import { ErrorDetails } from '@/modules/application/types/UpdatedEntity';
import { FlowType } from '@/modules/log-viewing/api/logs-contracts';
import { TargetEntityType } from '@/modules/targeting/api/targets-contracts';
import { FilterDTO } from '../../../../components/filter-builder/api/filters-contracts';
import { MaxChange } from '../../components/optimization/api/optimization-contracts';
import { OptimizationPreset } from '../../components/optimization/OptimizerConfig';
import { TimelineModel } from '../../components/timeline/models/TimelineModel';
import { EnabledPausedArchivedState } from '../../types/EnabledPausedArchivedState';
import { CampaignGroupMetricsModel } from './models/CampaignGroupMetricsModel';
import { CampaignModel } from './models/CampaignModel';
import { EnabledPausedErrorState } from '../../types/EnabledPausedErrorState';

export enum BidStrategyType {
  LEGACY_FOR_SALES = 'LEGACY_FOR_SALES',
  AUTO_FOR_SALES = 'AUTO_FOR_SALES',
  MANUAL = 'MANUAL',
  RULE_BASED = 'RULE_BASED',
  NONE = '',
}

export enum CampaignAdType {
  PRODUCTS = 'PRODUCTS',
  BRANDS = 'BRANDS',
  DISPLAY = 'DISPLAY',
  TV = 'TV',
}

// Only brands have bid optimization AUTO or MANUAL for other types it is empty string
export enum AmazonBrandsBidOptimization {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
  NONE = '',
}

export enum CostType {
  CPC = 'CPC',
  VCPM = 'VCPM', // Cost per 1000 viewable impressions
  CPM = 'CPM',
  NONE = '',
}

export enum PlacementType {
  PLACEMENT_TOP = 'PLACEMENT_TOP',
  PLACEMENT_PRODUCT_PAGE = 'PLACEMENT_PRODUCT_PAGE',
  PLACEMENT_REST_OF_SEARCH = 'PLACEMENT_REST_OF_SEARCH',
  HOME = 'HOME',
  DETAIL_PAGE = 'DETAIL_PAGE',
  OTHER = 'OTHER',
}

export enum CreativeType {
  PRODUCT_COLLECTION = 'PRODUCT_COLLECTION',
  STORE_SPOTLIGHT = 'STORE_SPOTLIGHT',
  VIDEO = 'VIDEO',
  BRAND_VIDEO = 'BRAND_VIDEO',
  NONE = '',
}

export enum TargetingType {
  TARGETING = 'TARGETING',
  AUDIENCES = 'AUDIENCES',
  MANUAL = 'MANUAL',
  AUTO = 'AUTO',
  CONTEXTUAL = 'CONTEXTUAL',
  NONE = '',
}

export enum MultiAdGroupsEnabledType {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
  NONE = '',
}

export interface CampaignWithTimelineDTO {
  campaigns: CampaignDTO[] | null;
  timeline: TimelineDTO;
  comparison_missing: boolean;
  metrics: MetricsDTO;
}

export interface CampaignsWithTimeline {
  profileId?: string; //TODO: check if back even can send this
  campaigns: CampaignModel[];
  timeline: TimelineModel;
  isComparisonDataMissing: boolean;
  metrics?: MetricModel[];
}

export interface CampaignDTO extends CommonMetricsDTO {
  i: string;
  n: string;
  p: string | undefined; // portfolio name, omitempty
  pi: string | undefined; // portfolio id, omitempty
  ad: CampaignAdType;
  cr: CreativeType;
  co: CostType;
  mag: boolean | null;
  o: boolean | null;
  gi: number; // group id
  gn: string; // group name
  si: string | undefined; // schedule id
  sn: string | undefined; // schedule name
  ss: EnabledPausedErrorState | undefined; // schedule state
  s: EnabledPausedArchivedState;
  sd: string | null;
  ed: string | null;
  tt: TargetingType;
  ba: number;
  bs: BidStrategyType;
  bo: AmazonBrandsBidOptimization;
  lo: string | null;
  cd: number[];
}

export interface TimelineDTO {
  x_axis: string[];
  y_axis: Record<CommonMetricField, number[]>;
}

export interface IdNameDTO {
  id: string;
  name: string;
}

// export interface AdGroupDTO {
//   id: string;
//   name: string;
//   state: string;
//   default_bid: number;
//   entity_type: TargetEntityType;
// }

export type AdGroupDTO = [
  // Positional array for AdGroupDTO
  string, // 0: id
  string, // 1: name
  string, // 2: state
  number, // 3: default_bid
  TargetEntityType, // 4: entity_type
];

export interface CreateNewCampaignGroupsDTO {
  flow_type: FlowType;
  groups: NewCampaignGroupDTO[];
}

export interface NewCampaignGroupDTO {
  name: string;
  tacos?: number;
  preset?: OptimizationPreset;
  bid_ceiling?: MaxChange;
  bid_floor?: number;
  bid_max_increase?: MaxChange;
  bid_max_decrease?: MaxChange;
  placement_max_increase?: number;
  placement_max_decrease?: number;
  bid_ceiling_off?: boolean;
  bid_floor_off?: boolean;
  bid_max_increase_off?: boolean;
  bid_max_decrease_off?: boolean;
  placement_max_increase_off?: boolean;
  placement_max_decrease_off?: boolean;
  no_optimize?: boolean;
}

export interface CampaignGroupDTO extends NewCampaignGroupDTO {
  id: number;
  total_campaigns: number;
}

export interface UpdateCampaignGroups {
  groups: CampaignGroupDTO[];
}

export interface CampaignGroupMetricsDTO extends CampaignGroupDTO {
  metrics: CommonMetricsDTO;
}

export interface CampaignGroupsWithMetricsDTO {
  campaign_groups: CampaignGroupMetricsDTO[];
  metrics: MetricsDTO;
  comparison_missing: boolean;
}

export interface CampaignGroupsWithMetrics {
  campaignGroups: CampaignGroupMetricsModel[];
  isComparisonDataMissing: boolean;
  metrics: MetricModel[];
}

export interface ChangeCampaignGroupDTO {
  campaign_id: string;
  group_id: number;
}

export interface UpdateCampaignsDTO {
  flow_type: FlowType;
  updates: CampaignUpdateDTO[];
}

export interface CampaignUpdateDTO {
  campaign_id: string;
  state?: EnabledPausedArchivedState;
  dynamic_bid_strategy?: BidStrategyType;
  bid_optimization?: AmazonBrandsBidOptimization;
  budget_amount?: number;
  placement_updates?: PlacementUpdate[];
}

export interface PlacementUpdate {
  placement: PlacementType;
  percentage: number;
}

export interface UpdatedCampaignsDTO {
  update_count: number;
  error_count: number;
  error_details: ErrorDetails[];
}

export interface GroupsMetricsRequest {
  filters: FilterDTO[];
}
