import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';
import useFilters from '@/components/filter-builder/hooks/useFilters';
import { getDefaultProfileStatsFilters } from '@/components/filter-builder/models/AlFilterModel';
import { SELLER_VENDOR_COLUMNS } from '@/components/grid/columns/columns.enum';
import { FormatterType, GridToggles } from '@/components/grid/types';
import { getMetricConfigByColId } from '@/components/metrics/MetricsConfig';
import { MetricField, SellerMetricField, vendorFieldToSellerFieldMap, VendorMetricField } from '@/components/metrics/types/MetricField';
import useEscapableToggle from '@/hooks/useEscapableToggle';
import useMetricChartTablePageVariables from '@/hooks/useMetricChartTablePageVariables';
import useMetricsInContext from '@/hooks/useMetricsInContext';
import { useUserSetting } from '@/hooks/useUserSetting';
import { useTranslation } from '@/lib';
import { CurrencyCode } from '@/modules/amazon-constants/types/CurrencyCode';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import useCurrency from '@/modules/currency/hooks/useCurrency';
import { profileService } from '@/modules/profiles/api/profile.service';
import { UserSettingKey, useUserContext } from '@/modules/users';
import { toastService } from '@/services/toast.service';
import { ContextKey, DEFAULT_AVAILABLE_METRICS, DEFAULT_GRID_TOGGLES } from '@/types/context-shared';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Alert, AlertTitle, Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import type { GridApi } from 'ag-grid-enterprise';
import { FunctionComponent, useMemo, useRef, useState } from 'react';
import ProfileStatsFilterBar from '../components/ProfileStatsFilterBar';
import ProfilesStatsMetrics from '../components/ProfilesStatsMetrics';
import ProfilesStatsTimelineChart from '../components/ProfilesStatsTimelineChart';
import ProfilesStatsTable, { ProfilesStatsGridContext } from '../components/profile-stats-table/ProfilesStatsTable';
import useProfileStatsMetricColumnAggregates from '../hooks/useProfileStatsMetricColumnAggregates';
import { ProfileWithMetricsModel } from '../models/ProfileWithMetricsModel';
import { SelectedProfileDTO } from '../models/SelectedProfileDTO';
import ProfileStatsSelectionActionsBar from '../components/ProfileStatsSelectionActionsBar';
import { createProfileStatsQueryKey } from '@/modules/profiles/api/profile.invalidations';

const ProfilesStatsPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { user, hasSellerProfiles, hasVendorProfiles } = useUserContext();

  const [selectedProfiles, setSelectedProfiles] = useState<SelectedProfileDTO[]>([]);

  // GRID - set in table
  const gridApiRef = useRef<GridApi<ProfileWithMetricsModel>>();
  const gridContextRef = useRef<ProfilesStatsGridContext>();

  let metricsUserSettingKey = UserSettingKey.VISIBLE_METRICS_PROFILES_STATS;
  if (hasSellerProfiles && hasVendorProfiles) {
    metricsUserSettingKey = UserSettingKey.VISIBLE_METRICS_PROFILES_STATS_SELLER_VENDOR;
  } else if (hasSellerProfiles) {
    metricsUserSettingKey = UserSettingKey.VISIBLE_METRICS_PROFILES_STATS_SELLER;
  } else if (hasVendorProfiles) {
    metricsUserSettingKey = UserSettingKey.VISIBLE_METRICS_PROFILES_STATS_VENDOR;
  }

  const pageVariables = useMetricChartTablePageVariables({
    contextKey: ContextKey.PROFILE_STATS,
    metricsUserSettingKey,
    defaultFilters: getDefaultProfileStatsFilters(),
    gridToggles: DEFAULT_GRID_TOGGLES,
  });

  const { filters, setFilters } = pageVariables;

  const { dates, comparisonDates } = useFilters({ filters, setFilters });

  const {
    data: rowData,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: createProfileStatsQueryKey(filters, user),
    queryFn: async () => {
      const result = await profileService.getUserProfilesWithMetrics({
        userId: user?.id as number,
        dates: {
          startDate: dates[0].toDefaultFormat(),
          endDate: dates[1].toDefaultFormat(),
        },
        compareDates: {
          startDate: comparisonDates[0].toDefaultFormat(),
          endDate: comparisonDates[1].toDefaultFormat(),
        },
      });

      if (result.isSuccess) {
        return result.payload.filter((p) => p.isActive);
      } else {
        toastService.error('Error loading profile stats');
      }
    },
  });

  const noActiveProfilesReceived = !isLoading && !isFetching && rowData && rowData?.length === 0;

  // TODO: create a a function applyFiltersToRow<T>(rowData: T[], filters: AlFilterModel[]): T[] - requires some refactoring in doesFilterPass
  const filteredRowData = useMemo(() => {
    if (!rowData) return undefined;

    if (!filters || filters.length === 0) {
      return rowData; // No filters applied, return all rows
    }

    return rowData.filter((row) => {
      if (!row) {
        return false; // Exclude rows without data
      }

      // Check if any filter fails; exclude the row if it doesn't pass
      return !filters.some((filter) => !filter.doesFilterPass(row));
    });
  }, [rowData, filters]);

  // Table toggles
  const [gridToggles, setGridToggles] = useState<GridToggles>({
    comparisonUnit: 'nominal',
    // sortByUnit: SortByUnit.Count, // TODO
    formatterType: FormatterType.LONG,
  });

  const { settingState: selectedCurrency, handleSettingStateChange: setSelectedCurrency } = useUserSetting<CurrencyCode>(
    UserSettingKey.PROFILES_STATS_SELECTED_CURRENCY,
    CurrencyCode.USD,
  );

  // Metrics and timeline data
  const profileStatsMetricAggregates = useProfileStatsMetricColumnAggregates({
    gridApiRef,
    gridContextRef,
    aggDataCurrencyCode: selectedCurrency,
    rowData: filteredRowData,
    conversionRatesModel: useCurrency().conversionRatesModel,
  });

  const sellerFieldsUsedByBoth = SELLER_VENDOR_COLUMNS.map((key) => getMetricConfigByColId(key)?.key).filter((metric) => metric != undefined);

  // Some vendor metrics like sales are used under Seller Total sales col key
  const availableMetrics: MetricField[] = [
    ...DEFAULT_AVAILABLE_METRICS,
    ...(hasSellerProfiles ? Object.values(SellerMetricField) : []),
    ...(hasVendorProfiles ? Object.values(VendorMetricField).filter((key) => !vendorFieldToSellerFieldMap[key]) : []),
    ...(!hasSellerProfiles && hasVendorProfiles ? sellerFieldsUsedByBoth : []),
  ];

  function getMetricsUserSettingKey() {
    if (hasSellerProfiles && hasVendorProfiles) {
      return UserSettingKey.VISIBLE_METRICS_PROFILES_STATS_SELLER_VENDOR;
    }

    if (hasSellerProfiles) {
      return UserSettingKey.VISIBLE_METRICS_PROFILES_STATS_SELLER;
    }

    if (hasVendorProfiles) {
      return UserSettingKey.VISIBLE_METRICS_PROFILES_STATS_VENDOR;
    }

    return UserSettingKey.VISIBLE_METRICS_PROFILES_STATS;
  }

  const { visibleMetrics, setVisibleMetrics } = useMetricsInContext(getMetricsUserSettingKey(), availableMetrics);

  const [isTableExpanded, toggleTableExpanded] = useEscapableToggle(false);

  const onDiscardClicked = () => {
    clearSelections();
  };

  function clearSelections() {
    setSelectedProfiles([]);
  }

  return (
    <PageLayout showFullscreen={isTableExpanded}>
      {!isTableExpanded && (
        <PageLayoutTopBar
          header={
            <div className="flex items-center gap-x-2">
              <div>{t('profiles_overview')}</div>
              <div>
                <Tooltip title={'Profile totals include all campaigns (incl. archived)'}>
                  <span className="flex items-center font-medium">
                    <InfoIcon fontSize="small" style={{ marginRight: '6px' }} />
                  </span>
                </Tooltip>
              </div>
            </div>
          }
        ></PageLayoutTopBar>
      )}

      <PageLayoutBody suppressBottomPadding={isTableExpanded} suppressHorizontalPadding={isTableExpanded}>
        {noActiveProfilesReceived && (
          <Alert className="mt-2 py-4" severity="info">
            <AlertTitle>No active profiles found</AlertTitle>
            No active profiles found. If you expect to see active profiles, try refreshing the page or contact support if the issue continues.
          </Alert>
        )}

        {!isTableExpanded && !noActiveProfilesReceived && (
          <div className={`mt-2 space-y-2`}>
            <AlErrorBoundary>
              <ProfilesStatsMetrics
                metricColumnAggregates={profileStatsMetricAggregates.metricColumnAggregates}
                visibleMetrics={visibleMetrics}
                setVisibleMetrics={setVisibleMetrics}
                availableMetrics={availableMetrics}
                selectedCurrency={selectedCurrency}
                showComparison={gridToggles.comparisonUnit != 'hidden'}
              />
            </AlErrorBoundary>

            <AlErrorBoundary>
              <ProfilesStatsTimelineChart
                profilesWithMetrics={filteredRowData}
                visibleMetrics={visibleMetrics}
                selectedCurrency={selectedCurrency}
              />
            </AlErrorBoundary>
          </div>
        )}

        {!noActiveProfilesReceived && (
          <div className={`flex flex-1 flex-grow flex-col ${!isTableExpanded && 'mt-2'}`}>
            <AlErrorBoundary>
              <ProfileStatsFilterBar
                profilesWithMetrics={filteredRowData}
                gridApiRef={gridApiRef}
                pageVariables={pageVariables}
                gridToggles={gridToggles}
                setGridToggles={setGridToggles}
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
                isExpanded={isTableExpanded}
                onExpandTable={toggleTableExpanded}
              />
            </AlErrorBoundary>

            <AlErrorBoundary>
              <ProfilesStatsTable
                isLoading={isLoading}
                rowData={filteredRowData}
                gridToggles={gridToggles}
                selectedCurrency={selectedCurrency}
                isExpanded={isTableExpanded}
                refetch={refetch}
                profileStatsMetricAggregates={profileStatsMetricAggregates}
                gridApiRef={gridApiRef}
                gridContextRef={gridContextRef}
                selectedProfiles={selectedProfiles}
                setSelectedProfiles={setSelectedProfiles}
              />
            </AlErrorBoundary>

            <AlErrorBoundary>
              <ProfileStatsSelectionActionsBar
                selectedProfiles={selectedProfiles}
                setSelectedProfiles={setSelectedProfiles}
                totalProfilesCount={filteredRowData?.length ?? 0}
                onDiscardClicked={onDiscardClicked}
              />
            </AlErrorBoundary>
          </div>
        )}
      </PageLayoutBody>
    </PageLayout>
  );
};

export default ProfilesStatsPage;
