import { MIN_DATE_OFFSET_FROM_CREATION_DAYS } from '@/components/filter-builder/models/AlFilterModel';
import { AlDate } from '@/lib/date/AlDate';
import { getCountryCode } from '@/modules/amazon-constants/types/CountryCode';
import { CurrencyCode, getCurrencyCode } from '@/modules/amazon-constants/types/CurrencyCode';
import { ProfileState, getProfileState } from '@/modules/users/types/ProfileState';
import { ProfileType, getProfileType } from '@/modules/users/types/ProfileType';
import { Timezone, getTimezone } from '@/modules/users/types/Timezone';
import { isEmpty, isNil } from 'lodash-es';
import { ProfileDTO } from '../api/profile.contracts';
import { BasicProfileCreateArguments, BasicProfileModel } from './BasicProfileModel';

export class ProfileModel extends BasicProfileModel {
  public accountId: string;
  public adsAccountId: string;
  public type: ProfileType;
  public subType: string;
  public currencyCode: CurrencyCode;
  public dailyBudget: number;
  public timeZone: Timezone;
  public marketplaceId: string;
  public state: ProfileState;
  public createdAt: string;
  public createSTVReports: boolean;
  public sellerCentralAuthAt: string | null;
  public vendorCentralAuthAt: string | null;
  private _adsDataStartDate: string | null;
  public adsDataEndDate: string | null;
  public sellingPartnerDataStartDate: string | null;
  public sellingPartnerDataEndDate: string | null;
  public profileDataItemIds: number[];

  public get isActive() {
    return this.state === ProfileState.ACTIVE;
  }

  public get isNotAuthorized() {
    return this.state === ProfileState.NOT_AUTHORIZED;
  }

  public get isSeller() {
    return !isNil(this.sellerCentralAuthAt);
  }

  public get isVendor() {
    return !isNil(this.vendorCentralAuthAt);
  }

  public get adsDataStartDate(): string {
    if (isNil(this._adsDataStartDate)) {
      const createdAt = AlDate.fromISO(this.createdAt) ?? AlDate.now();
      return createdAt.subtractDays(MIN_DATE_OFFSET_FROM_CREATION_DAYS).toDefaultFormat();
    }

    return this._adsDataStartDate;
  }

  public get dataStartDate(): AlDate {
    const adsDataStartDate = AlDate.parse(this.adsDataStartDate);

    if (this.isSeller || this.isVendor) {
      const sellingPartnerDataStartDate = !isNil(this.sellingPartnerDataStartDate) ? new AlDate(this.sellingPartnerDataStartDate) : undefined;
      if (sellingPartnerDataStartDate) {
        return adsDataStartDate.isBefore(sellingPartnerDataStartDate) ? adsDataStartDate : sellingPartnerDataStartDate;
      }
    }

    return adsDataStartDate;
  }

  public get groupId(): number {
    return 0; // this.dto.gi; // TODO
  }

  constructor(args: ProfileCreateArguments) {
    super(args);
    this.id = args.id;
    this.accountId = args.accountId;
    this.adsAccountId = args.adsAccountId;
    this.name = args.name;
    this.type = args.type;
    this.subType = args.subType;
    this.countryCode = args.countryCode;
    this.currencyCode = args.currencyCode;
    this.dailyBudget = args.dailyBudget;
    this.timeZone = args.timeZone;
    this.marketplaceId = args.marketplaceId;
    this.state = args.state;
    this.createdAt = args.createdAt;
    this.createSTVReports = args.createSTVReports;
    this.sellerCentralAuthAt = args.sellerCentralAuthAt;
    this.vendorCentralAuthAt = args.vendorCentralAuthAt;
    this._adsDataStartDate = args.adsDataStartDate;
    this.adsDataEndDate = args.adsDataEndDate;
    this.sellingPartnerDataStartDate = args.sellingPartnerDataStartDate;
    this.sellingPartnerDataEndDate = args.sellingPartnerDataEndDate;
    this.profileDataItemIds = args.profileDataItemIds;
  }

  public static fromDTO(dto: ProfileDTO): ProfileModel {
    return new ProfileModel({
      id: dto.id,
      accountId: dto.account_id,
      adsAccountId: dto.ads_account_id,
      name: dto.name,
      type: getProfileType(dto.type),
      subType: dto.sub_type,
      countryCode: getCountryCode(dto.country_code),
      currencyCode: getCurrencyCode(dto.currency_code),
      dailyBudget: dto.daily_budget,
      timeZone: getTimezone(dto.timezone),
      marketplaceId: dto.marketplace_id,
      state: getProfileState(dto.state),
      createdAt: dto.created_at,
      createSTVReports: dto.create_stv_reports,
      sellerCentralAuthAt: dto.seller_central_auth_at,
      vendorCentralAuthAt: dto.vendor_central_auth_at,
      adsDataStartDate: dto.ads_data_start_date,
      adsDataEndDate: dto.ads_data_end_date,
      sellingPartnerDataStartDate: dto.selling_partner_data_start_date,
      sellingPartnerDataEndDate: dto.selling_partner_data_end_date,
      profileDataItemIds: dto.dg ?? [],
    });
  }

  public isAdsAccountIdMatches(adsAccountId: string): boolean {
    return !isEmpty(this.adsAccountId) && !isEmpty(adsAccountId) && this.adsAccountId === adsAccountId;
  }
}

export interface ProfileCreateArguments extends BasicProfileCreateArguments {
  accountId: string;
  adsAccountId: string;
  type: ProfileType;
  subType: string;
  currencyCode: CurrencyCode;
  dailyBudget: number;
  timeZone: Timezone;
  marketplaceId: string;
  state: ProfileState;
  createdAt: string;
  createSTVReports: boolean;
  sellerCentralAuthAt: string | null;
  vendorCentralAuthAt: string | null;
  adsDataStartDate: string | null;
  adsDataEndDate: string | null;
  sellingPartnerDataStartDate: string | null;
  sellingPartnerDataEndDate: string | null;
  profileDataItemIds: number[];
}
