import { ApiResponse } from '@/lib/api/api-response';
import { apiClient, apiTeamClient } from '@/lib/api/base-client';
import { BasicProfileDTO, ProfileDTO } from '@/modules/profiles/api/profile.contracts';
import { TeamModel } from '../types/TeamModel';
import { AddProfilesToTeamDTO, AddUserToTeamDTO, CreateTeamDTO, RemoveUsersFromTeamDTO, TeamDTO, UpdateTeamDTO } from './teams.contracts';
import { BasicProfileModel } from '@/modules/profiles/types/BasicProfileModel';
import { ProfileModel } from '@/modules/profiles/types/ProfileModel';

const _ORG_PROFILES_KEY = 'org_profiles';

export function createOrgProfilesQueryKey(teamId: number | undefined) {
  return [_ORG_PROFILES_KEY, teamId];
}

class TeamService {
  public static basePath = 'teams';

  async getAllUserTeams(): Promise<ApiResponse<TeamModel[]>> {
    try {
      const response = await apiClient.get<TeamDTO[]>(`${TeamService.basePath}`);

      return response.processPayload(TeamModel.fromDTOArray);
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async create(userId: number, name: string): Promise<ApiResponse<number>> {
    try {
      const createTeamDTO: CreateTeamDTO = {
        name,
      };

      const response = await apiClient.post<string>(`/users/${userId}/${TeamService.basePath}`, createTeamDTO);

      return response.processPayload((p) => parseFloat(p));
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async deleteTeams(teamIds: number[]): Promise<ApiResponse<null>> {
    const payload = {
      delete_ids: teamIds,
    };

    return apiClient.post(`${TeamService.basePath}/delete`, payload);
  }

  async addProfilesToTeam(teamId: number, profileIds: string[]): Promise<ApiResponse<null>> {
    const payload: AddProfilesToTeamDTO = {
      profile_ids: profileIds,
    };

    return await apiClient.post(`${TeamService.basePath}/${teamId}/profiles/add`, payload);
  }

  async removeProfilesFromTeam(teamId: number, profileIds: string[]): Promise<ApiResponse<null>> {
    const payload: AddProfilesToTeamDTO = {
      profile_ids: profileIds,
    };
    return await apiClient.post(`${TeamService.basePath}/${teamId}/profiles/remove`, payload);
  }

  async updateTeamName(teamId: number, name: string): Promise<ApiResponse<null>> {
    try {
      const updateTeamDTO: UpdateTeamDTO = {
        name,
      };

      return await apiClient.post(`teams/${teamId}/`, updateTeamDTO);
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async updatePreferredUpdateTime(preferredUpdateTime: string): Promise<ApiResponse<null>> {
    const updateTeamDTO: UpdateTeamDTO = {
      preferred_update_time: preferredUpdateTime,
    };

    return await apiTeamClient.post(`/`, updateTeamDTO);
  }

  async getProfiles(): Promise<ApiResponse<ProfileModel[]>> {
    const response = await apiTeamClient.get<ProfileDTO[]>(`/amazon-profiles`);
    return response.processPayload((p) => p.map(ProfileModel.fromDTO));
  }

  async addUserToTeam(teamId: number, email: string): Promise<ApiResponse<null>> {
    const payload: AddUserToTeamDTO = {
      email,
    };
    return await apiClient.post(`${TeamService.basePath}/${teamId}/users/add`, payload);
  }

  async removeUsersFromTeam(teamId: number, userIds: number[]): Promise<ApiResponse<null>> {
    const payload: RemoveUsersFromTeamDTO = {
      user_ids: userIds,
    };
    return await apiClient.post(`${TeamService.basePath}/${teamId}/users/remove`, payload);
  }

  async getAllOrgProfilesByTeamId(teamId: number): Promise<ApiResponse<BasicProfileModel[]>> {
    try {
      const response = await apiClient.get<BasicProfileDTO[]>(`${TeamService.basePath}/${teamId}/org/profiles`);

      return response.processPayload((p) => p.map(BasicProfileModel.fromDTO));
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const teamService = new TeamService();
