import { useTranslation } from '@/lib';
import { assertUnhandledCase } from '@/modules/application/utils';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash-es';
import { ChangeEvent, FunctionComponent, useEffect, useRef, useState } from 'react';
import { SavedFilterLevel } from '../api/filters-contracts';
import { FilterPresetModel } from '../models/FilterPresetModel';
import { SaveFlowType } from '../types/SavedPresets';

interface SaveFiltersModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (name: string, level: SavedFilterLevel, createNew: boolean) => void;
  existingNames: string[];
  isLoading: boolean;
  presetToEdit: FilterPresetModel | 'new' | null;
  saveFlowType: SaveFlowType;
}

const SaveFiltersModal: FunctionComponent<SaveFiltersModalProps> = ({
  open,
  onClose,
  onSave,
  existingNames,
  isLoading,
  presetToEdit,
  saveFlowType,
}) => {
  const [name, setName] = useState('');
  const [level, setLevel] = useState<SavedFilterLevel | null>(null);
  const [nameError, setNameError] = useState(false);
  const isExistingPresetSelected = !isNil(presetToEdit) && presetToEdit !== 'new';
  const nameInputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (!open) return;

    if (saveFlowType === SaveFlowType.SAVE_AS_NEW) {
      setName('');
      setLevel(SavedFilterLevel.PERSONAL);
    } else if (saveFlowType === SaveFlowType.UPDATE) {
      setName(isExistingPresetSelected ? presetToEdit.name : '');
      setLevel(isExistingPresetSelected ? presetToEdit.level : null);
    } else {
      assertUnhandledCase(saveFlowType);
    }

    setTimeout(() => {
      if (nameInputRef.current) {
        nameInputRef.current.focus();
      }
    }, 100); // Delay ensures the field is mounted before focusing
  }, [open]);

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputName = e.target.value;
    setName(inputName);

    // Check if the entered name already exists
    if (saveFlowType === SaveFlowType.SAVE_AS_NEW) {
      setNameError(existingNames.includes(inputName));
    } else if (saveFlowType === SaveFlowType.UPDATE) {
      const isInputNameSelectedPresetName = isExistingPresetSelected && presetToEdit.name === inputName;
      setNameError(existingNames.includes(inputName) && !isInputNameSelectedPresetName);
    } else {
      assertUnhandledCase(saveFlowType);
    }
  };

  function resetStates() {
    // Give time to close so wouldn't flash empty
    setTimeout(() => {
      setName('');
      setLevel(null);
      setNameError(false);
    }, 300);
  }

  const onSaveClicked = (createNew: boolean) => {
    if (!nameError && name.trim() && level) {
      onSave(name.trim(), level, createNew);

      onClose();
      resetStates();
    }
  };

  function onCloseClicked() {
    onClose();
    resetStates();
  }

  const helperText = (() => {
    if (nameError) return 'This filter name already exists';

    if (saveFlowType === SaveFlowType.UPDATE && isExistingPresetSelected && name !== presetToEdit.name)
      return `Name changed from '${presetToEdit.name}'`;
  })();

  const isApplyDisabled = !name.trim() || !level || nameError;
  return (
    <Dialog
      open={open}
      onClose={onCloseClicked}
      slotProps={{
        paper: {
          sx: { width: '450px' },
        },
      }}
    >
      <DialogTitle>
        <div className="flex flex-row justify-between">
          <div>{saveFlowType === SaveFlowType.UPDATE ? 'Update Filters' : 'Save New Filters'}</div>

          <IconButton edge="start" color="inherit" onClick={onCloseClicked} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className="flex flex-col gap-2">
        {/* Name Input */}
        <TextField
          label="Name"
          value={name}
          onChange={handleNameChange}
          placeholder="Enter a unique name"
          inputRef={nameInputRef}
          fullWidth
          error={nameError} // Highlight the input in red if there's an error
          helperText={helperText}
        />

        {/* Radio Buttons */}
        <FormControl>
          <Typography variant="body2" className="mb-2">
            Select Saving Level
          </Typography>
          <RadioGroup className="flex flex-col space-y-2" value={level} onChange={(e) => setLevel(e.target.value as SavedFilterLevel)}>
            <FormControlLabel
              value={SavedFilterLevel.PERSONAL}
              control={<Radio />}
              label={
                <div className="flex flex-col space-y-0">
                  <Typography variant="subtitle2">{t(`enums.saved_filter_level.${SavedFilterLevel.PERSONAL}`)}</Typography>
                  <Typography variant="caption">Save this filter for your personal use only.</Typography>
                </div>
              }
            />
            <FormControlLabel
              value={SavedFilterLevel.TEAM}
              control={<Radio />}
              label={
                <div className="flex flex-col space-y-0">
                  <Typography variant="subtitle2">{t(`enums.saved_filter_level.${SavedFilterLevel.TEAM}`)}</Typography>
                  <Typography variant="caption">Share this filter with your team members.</Typography>
                </div>
              }
            />
            {/* <FormControlLabel
              value={SavedFilterLevel.ORG}
              control={<Radio />}
              label={
                <div className="flex flex-col space-y-0">
                  <Typography variant="subtitle2">{t(`enums.saved_filter_level.${SavedFilterLevel.ORG}`)}</Typography>
                  <Typography variant="caption">Make this filter available to everyone in your organization.</Typography>
                </div>
              }
            /> */}
          </RadioGroup>
          {isExistingPresetSelected && saveFlowType === SaveFlowType.UPDATE && level && level != presetToEdit.level && (
            <FormHelperText>{`Changed from '${t(`enums.saved_filter_level.${presetToEdit.level}`)}'`}</FormHelperText>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseClicked} variant="text" disabled={isLoading}>
          Cancel
        </Button>
        {saveFlowType === SaveFlowType.SAVE_AS_NEW && (
          <Button loading={isLoading} onClick={() => onSaveClicked(true)} variant="contained" disabled={isApplyDisabled} color="primary">
            Save as New
          </Button>
        )}
        {saveFlowType === SaveFlowType.UPDATE && (
          <Button loading={isLoading} onClick={() => onSaveClicked(false)} variant="contained" disabled={isApplyDisabled} color="primary">
            Update
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SaveFiltersModal;
