import AlGrid, { DEFAULT_GRID_OPTIONS } from '@/components/grid/AlGrid';
import ChangePercentageCellRenderer from '@/components/grid/cells/ChangePercentageCellRenderer';
import LinkCallbackCellRenderer, { ILinkCallbackCellRendererParams } from '@/components/grid/cells/LinkCallbackCellRenderer';
import TargetEstimateCellRenderer, { ITargetEstimateCellRendererParams } from '@/components/grid/cells/TargetEstimateCellRenderer';
import { AD_PERFORMANCE_COLUMNS, ColumnId } from '@/components/grid/columns/columns.enum';
import ChangePercentageHeaderRenderer from '@/components/grid/headers/ChangePercentageHeaderRenderer';
import useAggregators from '@/components/grid/hooks/useAggregators';
import useColDefsFunctions from '@/components/grid/hooks/useColDefsFunctions';
import useColumnTypes from '@/components/grid/hooks/useColumnTypes';
import useDynamicHeight, { EXPANDED_VISIBLE_ABOVE_PX_ON_SCROLL_DOWN } from '@/components/grid/hooks/useDynamicHeight';
import useToggles from '@/components/grid/hooks/useToggles';
import { DEFAULT_GRID_OPTIONS_ROW_GROUPS, ExpandedGridContext, GridToggles } from '@/components/grid/types';
import { UnitType } from '@/components/metrics/MetricsConfig';
import { MetricData } from '@/components/metrics/types/MetricData';
import { MetricAggregates } from '@/components/metrics/types/MetricField';
import { useLayoutContext } from '@/contexts/LayoutContext';
import useFormatting from '@/hooks/useFormatting';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { sleep } from '@/lib/api/api-utils';
import { CurrencyCode } from '@/modules/amazon-constants/types/CurrencyCode';
import useCurrency from '@/modules/currency/hooks/useCurrency';
import { CurrencyRatesModel } from '@/modules/currency/models/CurrencyRatesModel';
import { PerformanceTargetEstimate } from '@/modules/profiles/api/profile.contracts';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey, useUserContext } from '@/modules/users';
import { Routes } from '@/router/router-paths';
import { toastService } from '@/services/toast.service';
import { Tooltip } from '@mui/material';
import type {
  BodyScrollEvent,
  CellClassParams,
  CellValueChangedEvent,
  EditableCallbackParams,
  GridReadyEvent,
  IAggFuncParams,
  IHeaderParams,
  SelectionChangedEvent,
  ValueFormatterParams,
  ValueGetterParams,
  ValueParserParams,
} from 'ag-grid-community';
import type { ColDef, GridApi, GridOptions, ICellRendererParams } from 'ag-grid-enterprise';
import { isArray, isNil } from 'lodash-es';
import { Dispatch, FunctionComponent, MutableRefObject, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import useProfileSettingsUpdate from '../../hooks/useProfileSettingsUpdate';
import useSelectedCurrency from '../../hooks/useSelectedCurrency';
import { PerformanceMetricsModel } from '../../models/PerformanceMetricsModel';
import { ProfileWithMetricsModel } from '../../models/ProfileWithMetricsModel';
import { profilesStatsTableDefaultColumnState } from './profiles-stats-table.default-column-state';
import { SelectedProfileDTO } from '../../models/SelectedProfileDTO';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { useTranslation } from '@/lib';
import { useDataGroups } from '@/hooks/useDataGroups';

const PLACEHOLDER_VALUE = '–';

export interface ProfilesStatsGridContext extends ExpandedGridContext {
  metricColumnAggregates: MetricAggregates | undefined;
  conversionRatesModel: CurrencyRatesModel | undefined;
  selectedCurrency: CurrencyCode;
}

interface ProfilesStatsTableProps {
  rowData: ProfileWithMetricsModel[] | undefined;
  isLoading: boolean;
  gridToggles: GridToggles;
  selectedCurrency: CurrencyCode;
  refetch: () => void;
  isExpanded: boolean;
  gridApiRef: MutableRefObject<GridApi<ProfileWithMetricsModel> | undefined>;
  gridContextRef: MutableRefObject<ProfilesStatsGridContext | undefined>;
  profileStatsMetricAggregates: {
    metricColumnAggregates: MetricAggregates | undefined;
    onGridReadyForMetricColumnAggregates: () => void;
    profileStatsMetricAggFunc: (params: IAggFuncParams<ProfileWithMetricsModel>) => number[];
  };
  selectedProfiles: SelectedProfileDTO[];
  setSelectedProfiles: Dispatch<SetStateAction<SelectedProfileDTO[]>>;
}

const ProfilesStatsTable: FunctionComponent<ProfilesStatsTableProps> = ({
  rowData,
  isLoading,
  gridToggles,
  selectedCurrency,
  refetch,
  isExpanded,
  gridApiRef,
  gridContextRef,
  profileStatsMetricAggregates,
  selectedProfiles,
  setSelectedProfiles,
}) => {
  const navigate = useNavigate();
  const { setActiveTeam } = useActiveTeamContext();
  const { hasSellerProfiles, hasVendorProfiles, isProfileSeller, isProfileVendor } = useUserContext();
  const { formatPercent, formatCurrencyWholeNumbers } = useFormatting();
  const { metricColDefsConditionallyAttachEmptyCellRendererSelectorByProfileField } = useColDefsFunctions();
  const { t } = useTranslation();
  const { onSourceScroll } = useLayoutContext(); // TODO: move to AlGrid and make optionally turned on
  const { heightCssValue, onGridReadyCallback: onGridReadyForDynamicHeight } = useDynamicHeight({});

  const getSettingsKey = useCallback(() => {
    if (hasSellerProfiles && hasVendorProfiles) return UserSettingKey.PROFILES_STATS_TABLE_SELLER_VENDOR_COLUMN_STATE;
    if (hasSellerProfiles) return UserSettingKey.PROFILES_STATS_TABLE_SELLER_COLUMN_STATE;
    if (hasVendorProfiles) return UserSettingKey.PROFILES_STATS_TABLE_VENDOR_COLUMN_STATE;
    return UserSettingKey.PROFILES_STATS_TABLE_COLUMN_STATE;
  }, [hasSellerProfiles, hasVendorProfiles]);

  const { setColumnStateGridApi, handleColumnStateChange, applyStateToDefinitions, setIsAutoSaveEnabled } = useGridColumnState(
    getSettingsKey(),
    profilesStatsTableDefaultColumnState(),
  );

  const { gridToggleParams } = useToggles<ProfileWithMetricsModel>({
    gridApiRef,
    gridContextRef,
    gridToggles,
  });

  const { conversionRatesModel } = useCurrency();

  const { teamNameFilteredGridAggFunc, profileNameFilteredGridAggFunc, lastOptimizedAggFunc } = useAggregators();

  const { updateProfileSettings } = useProfileSettingsUpdate();

  const { getChangePercentageHeaderRendererParams, lastOptimizedType, checkboxColumnType, dataGroupColumnType } = useColumnTypes();
  const { metricsDataComparator, getMetricFieldChangePercentageCellRendererParams } = useColDefsFunctions();

  const { metricColumnAggregates, onGridReadyForMetricColumnAggregates, profileStatsMetricAggFunc } = profileStatsMetricAggregates;

  const { onGridReadyForSelectedCurrency } = useSelectedCurrency({
    gridApiRef,
    gridContextRef,
    selectedCurrency,
  });

  async function onProfileNameClicked(profileWithMetrics: ProfileWithMetricsModel) {
    try {
      if (!profileWithMetrics.id) {
        return;
      }
      setActiveTeam({ teamId: profileWithMetrics.teamId, profileId: profileWithMetrics.id });
      await sleep(50); // wait for 50 ms before navigate
      navigate(Routes.OPTIMIZER);
    } catch (error) {
      console.error(error);
      toastService.error('Unable to navigate to profile. Please try again later.');
    }
  }

  function getCellStyleBasedOnPerformanceMetric(
    params: CellClassParams<ProfileWithMetricsModel, MetricData>,
    performanceMetricField: keyof ProfileWithMetricsModel,
    performanceMetricColId: ColumnId,
  ) {
    const target = params.data?.[performanceMetricField];
    const current = params.value;

    if (!isArray(target) || !isArray(current)) return null;

    const targetValue = target[0];
    const currentValue = current[0];

    if (isNil(targetValue) || isNil(currentValue)) return null;

    const targetEstimate: PerformanceTargetEstimate = [targetValue, currentValue];
    const positionOnGradient = PerformanceMetricsModel.calculateEstimateToTarget(targetEstimate);
    if (!positionOnGradient) return null;
    const color = PerformanceMetricsModel.getPerformanceMetricColor(performanceMetricColId, positionOnGradient);
    if (!color) return null;

    return { backgroundColor: color };
  }

  const { dataGroups, dataItemToInfoMap, groupIdToItemSetMap, isDataGroupsLoading } = useDataGroups([DataGroupType.PROFILE]);

  const columnDefs: ColDef<ProfileWithMetricsModel>[] = useMemo(() => {
    const colDefs: ColDef<ProfileWithMetricsModel>[] = [
      {
        colId: ColumnId.CHECKBOX,
        pinned: 'left',
        lockPosition: 'left',
        type: 'checkboxColumnType',
      },
      {
        colId: ColumnId.TEAM_NAME,
        headerName: 'Team',
        field: 'teamName',
        minWidth: 90,
        enableRowGroup: true,
        aggFunc: 'teamNameFilteredGridAggFunc',
      },
      {
        colId: ColumnId.PROFILE_NAME,
        headerName: 'Profile',
        minWidth: 90,
        field: 'nameWithMarket',
        cellRendererSelector(params: ICellRendererParams<ProfileWithMetricsModel>) {
          if (params.node.group) {
            return {
              component: () => <div className="flex h-full items-center">{params.value}</div>,
            };
          }
        },
        aggFunc: 'profileNameFilteredGridAggFunc',
        cellRenderer: LinkCallbackCellRenderer,
        cellRendererParams: (
          params: ICellRendererParams<ProfileWithMetricsModel>,
        ): ILinkCallbackCellRendererParams<ProfileWithMetricsModel> => {
          return {
            buttonText: params.data?.nameWithMarket || '',
            tooltip: 'Start optimizing this Profile',
            callback: onProfileNameClicked,
          };
        },
      },
      {
        colId: ColumnId.CAMPAIGN_LAST_OPTIMIZED,
        field: 'lastOptimizedAt',
        type: 'lastOptimizedType',
      },
      // Performance
      {
        colId: ColumnId.PERFORMANCE_ACOS,
        headerName: 'Target ACOS',
        field: 'performanceAcos',
        type: 'targetEstimateColType',
        comparator: getPerformanceMetricComparator('acos'),
        headerTooltip: 'Target ACOS compared to the current MTD performance.',
      },
      {
        colId: ColumnId.PERFORMANCE_AD_SPEND,
        headerName: 'Target Ad Spend (Pacing)',
        field: 'performanceAdSpend',
        type: 'targetEstimateColType',
        comparator: getPerformanceMetricComparator('adSpend'),
        headerTooltip:
          'Ad Spend Monthly Target compared to the current month estimate. Estimate calc: (MTD Ad Spend * Days in Month / MTD Days) / Monthly Target',
        width: 104,
      },
      {
        colId: ColumnId.PERFORMANCE_AD_SALES,
        headerName: 'Target Ad Sales (Pacing)',
        field: 'performanceAdSales',
        type: 'targetEstimateColType',
        comparator: getPerformanceMetricComparator('adSales'),
        headerTooltip:
          'Ad Sales Monthly Target compared to the current month estimate. Estimate calc: (MTD Ad Sales * Days in Month / MTD Days) / Monthly Target',
        width: 114,
      },
      // Metrics
      {
        colId: ColumnId.IMPRESSIONS,
        headerName: 'Ad Impressions',
        field: 'impressions',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.CLICKS,
        headerName: 'Ad Clicks',
        field: 'clicks',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.ORDERS,
        headerName: 'Ad Orders',
        field: 'orders',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.CTR,
        headerName: 'Ad CTR',
        field: 'ctr',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.CVR,
        headerName: 'Ad CVR',
        field: 'cvr',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.CPC,
        headerName: 'Ad CPC',
        field: 'cpc',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.SPEND,
        headerName: 'Ad Spend',
        field: 'spend',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.SALES,
        headerName: 'Ad Sales',
        field: 'sales',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.ACOS,
        headerName: 'ACOS',
        field: 'acos',
        type: 'profileStatsMetricColType',
        cellStyle: (params: CellClassParams<ProfileWithMetricsModel, MetricData>) => {
          return getCellStyleBasedOnPerformanceMetric(params, 'performanceAcos', ColumnId.PERFORMANCE_ACOS);
        },
      },
      {
        colId: ColumnId.ACTC,
        headerName: 'aCTC',
        field: 'actc',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.ROAS,
        headerName: 'Ad ROAS',
        field: 'roas',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.RPC,
        headerName: 'Ad RPC',
        field: 'rpc',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.CPA,
        headerName: 'Ad CPA',
        field: 'cpa',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.AOV,
        headerName: 'Ad AOV',
        field: 'aov',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.CPM,
        headerName: 'Ad CPM',
        field: 'cpm',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.UNITS,
        headerName: 'Ad Units',
        field: 'units',
        type: 'profileStatsMetricColType',
      },
    ];

    if (hasSellerProfiles || hasVendorProfiles) {
      colDefs.push(
        {
          colId: ColumnId.ACOTS,
          headerName: 'TACOS',
          field: 'acots',
          type: 'profileStatsMetricColType',
          cellStyle: (params: CellClassParams<ProfileWithMetricsModel, MetricData>) => {
            return getCellStyleBasedOnPerformanceMetric(params, 'performanceAcots', ColumnId.PERFORMANCE_ACOTS);
          },
        },
        {
          colId: ColumnId.AD_SALES_OF_TOTAL,
          headerName: 'Ad Sales % of Total',
          field: 'adSalesOfTotal',
          type: 'profileStatsMetricColType',
          width: 108,
        },
        {
          colId: ColumnId.ASP,
          headerName: 'Total ASP',
          field: 'asp',
          type: 'profileStatsMetricColType',
        },
        {
          colId: ColumnId.ORGANIC_SALES,
          headerName: 'Organic Sales',
          field: 'organicSales',
          type: 'profileStatsMetricColType',
        },
        {
          colId: ColumnId.TOTAL_ROAS,
          headerName: 'Total ROAS',
          field: 'totalRoas',
          type: 'profileStatsMetricColType',
        },
        {
          colId: ColumnId.TOTAL_SALES,
          headerName: 'Total Sales',
          field: 'totalSales',
          type: 'profileStatsMetricColType',
        },
        {
          colId: ColumnId.UNIT_VIEW,
          headerName: 'Unit Pageview %',
          field: 'unitView',
          type: 'profileStatsMetricColType',
          width: 130,
        },
        {
          colId: ColumnId.TOTAL_UNITS,
          headerName: 'Total Units',
          field: 'totalUnits',
          type: 'profileStatsMetricColType',
        },
        {
          colId: ColumnId.TOTAL_VIEWS,
          headerName: 'Total Page Views',
          field: 'totalViews',
          type: 'profileStatsMetricColType',
        },
        {
          colId: ColumnId.UNITS_REFUNDED,
          headerName: 'Units Refunded',
          field: 'unitsRefunded',
          type: 'profileStatsMetricColType',
          width: 102,
        },
        {
          colId: ColumnId.UNITS_REFUND_RATE,
          headerName: 'Refund Rate',
          field: 'unitRefundRate',
          type: 'profileStatsMetricColType',
        },
        {
          colId: ColumnId.PERFORMANCE_ACOTS,
          headerName: 'Target TACOS',
          field: 'performanceAcots',
          type: 'targetEstimateColType',
          comparator: getPerformanceMetricComparator('acots'),
          headerTooltip: 'Target TACOS compared to the current MTD performance.',
        },
        {
          colId: ColumnId.PERFORMANCE_TOTAL_SALES,
          headerName: 'Target Total Sales (Pacing)',
          field: 'performanceTotalSales',
          type: 'targetEstimateColType',
          comparator: getPerformanceMetricComparator('totalSales'),
          headerTooltip:
            'Total Sales Monthly Target compared to the current month estimate. Estimate calc: (MTD Total Sales * Days in Month / MTD Days) / Monthly Target',
        },
      );
    }

    if (hasSellerProfiles) {
      colDefs.push(
        // {
        //   colId: ColumnId.TOTAL_ACTC,
        //   headerName: 'Total aCTC',
        //   field: 'totalActc',
        //   type: 'profileStatsMetricColType',
        // },
        {
          colId: ColumnId.ORGANIC_TRAFFIC,
          headerName: 'Organic Traffic',
          field: 'organicTraffic',
          type: 'profileStatsMetricColType',
        },
        {
          colId: ColumnId.TOTAL_CPA,
          headerName: 'Total CPA',
          field: 'totalCpa',
          type: 'profileStatsMetricColType',
        },

        {
          colId: ColumnId.UPS,
          headerName: 'Units Per Session',
          field: 'ups',
          type: 'profileStatsMetricColType',
        },
        {
          colId: ColumnId.TOTAL_CVR,
          headerName: 'Total CVR',
          field: 'totalCvr',
          type: 'profileStatsMetricColType',
        },
        {
          colId: ColumnId.TOTAL_CLICKS,
          headerName: 'Total Sessions',
          field: 'totalClicks',
          type: 'profileStatsMetricColType',
        },
        {
          colId: ColumnId.TOTAL_ORDERS,
          headerName: 'Total Orders',
          field: 'totalOrders',
          type: 'profileStatsMetricColType',
        },
        {
          colId: ColumnId.TOTAL_AOV,
          headerName: 'Total AOV',
          field: 'totalAov',
          type: 'profileStatsMetricColType',
        },
      );
    }

    if (hasVendorProfiles) {
      colDefs.push(
        {
          colId: ColumnId.VENDOR_ACOGS,
          headerName: 'ACOGS',
          field: 'acogs',
          type: 'profileStatsMetricColType',
        },
        {
          colId: ColumnId.SHIPPED_COGS,
          headerName: 'Shipped COGS',
          field: 'shippedCogs',
          type: 'profileStatsMetricColType',
        },
        {
          colId: ColumnId.SHIPPED_REVENUE,
          headerName: 'Shipped Revenue',
          field: 'shippedRevenue',
          type: 'profileStatsMetricColType',
        },
        {
          colId: ColumnId.SHIPPED_UNITS,
          headerName: 'Shipped Units',
          field: 'shippedUnits',
          type: 'profileStatsMetricColType',
        },
      );
    }

    dataGroups
      .filter((group) => group.type === DataGroupType.PROFILE)
      .forEach((group) => {
        colDefs.push({
          colId: `data_group_${group.id}`,
          headerName: group.name,
          headerClass: 'accented-header data-group-profile data-group-base',
          toolPanelClass: 'data-group-base data-group-profile',
          headerTooltip: t('profile_data_group'),
          valueGetter: (params: ValueGetterParams<ProfileWithMetricsModel>) => {
            const itemId = params.data?.profileDataItemIds.find((itemId) => groupIdToItemSetMap.get(group.id)?.has(itemId));
            return itemId ? dataItemToInfoMap.get(itemId)?.name : undefined;
          },
          type: 'dataGroupColumnType',
        });
      });

    metricColDefsConditionallyAttachEmptyCellRendererSelectorByProfileField<ProfileWithMetricsModel>(colDefs, 'id');

    applyStateToDefinitions(colDefs);

    return colDefs;
  }, [groupIdToItemSetMap, isDataGroupsLoading]);

  // Needed to keep the selection up to date when opt or Tags change on the campaign and data is refetched
  useEffect(() => {
    if (!gridApiRef.current || !rowData || rowData.length == 0 || selectedProfiles.length == 0) return;
    updateSelectedProfiles(gridApiRef.current);
  }, [rowData]);

  const updateSelectedProfiles = (api: GridApi<ProfileWithMetricsModel>) => {
    if (!api || api.isDestroyed()) {
      console.warn('[ProfileStatsTable.tsx] Grid API is not available or has been destroyed.');
      return;
    }

    try {
      const selectedRows: ProfileWithMetricsModel[] = api.getSelectedRows();

      const selectedProfilesDTO = selectedRows.map(
        (row): SelectedProfileDTO => ({
          id: row.id, // TODO: row.id is profileID, but selected id is teamID + profileID
          name: row.name,
          groupId: row.groupId,
          dataGroupType: DataGroupType.PROFILE,
          dataItemIds: row.profileDataItemIds,
        }),
      );

      setSelectedProfiles(selectedProfilesDTO);
    } catch (error) {
      console.error('Error updating selected campaigns:', error);
    }
  };

  const handleRowSelection = (event: SelectionChangedEvent<ProfileWithMetricsModel>) => {
    updateSelectedProfiles(event.api);
  };

  const profileStatsMetricColType = {
    autoHeight: true,
    width: 125,
    headerComponent: ChangePercentageHeaderRenderer,
    headerComponentParams: (params: IHeaderParams<ProfileWithMetricsModel, ProfilesStatsGridContext>) =>
      getChangePercentageHeaderRendererParams(params, { customCurrencyCode: params.context?.selectedCurrency || CurrencyCode.USD }),
    comparator: metricsDataComparator,
    aggFunc: 'profileStatsMetricAggFunc',
    cellRenderer: ChangePercentageCellRenderer,
    cellRendererParams: (params: ICellRendererParams<ProfileWithMetricsModel, unknown, ProfilesStatsGridContext>) =>
      getMetricFieldChangePercentageCellRendererParams(params, {
        customCurrencyCode: params.data?.currencyCode || CurrencyCode.USD,
      }),
    valueFormatter: (params: ValueFormatterParams) => {
      if (Array.isArray(params.value) && params.value.length > 0) {
        // Convert to string to avoid 0 values to be discarded and whole array returned on export
        return params.value[0].toString();
      }
      return '';
    },
  };

  const targetEstimateColType = {
    width: 100,
    singleClickEdit: true,
    editable: (params: EditableCallbackParams<ProfileWithMetricsModel>): boolean => {
      const colKey = params.column?.getId() as ColumnId | undefined;
      if (colKey && AD_PERFORMANCE_COLUMNS.includes(colKey)) {
        return true;
      }

      return isProfileSeller(params.data?.id ?? '') || isProfileVendor(params.data?.id ?? '');
    },
    headerClass: 'accented-header',
    cellRendererSelector: (params: ICellRendererParams<ProfileWithMetricsModel, PerformanceTargetEstimate>) => {
      // Don't use the custom cell renderer on special cases
      if (params.node.group) {
        return { component: () => <></> };
      }

      const colKey = params.column?.getId() as ColumnId;

      if (!AD_PERFORMANCE_COLUMNS.includes(colKey) && !isProfileSeller(params.data?.id ?? '') && !isProfileVendor(params.data?.id ?? '')) {
        return {
          component: () => (
            <Tooltip title="Selling partner not connected">
              <div className="flex h-full flex-1 justify-end opacity-50">{PLACEHOLDER_VALUE}</div>
            </Tooltip>
          ),
        };
      }

      if (!isArray(params.value)) {
        console.error('Received invalid target estimate: ', params.value);
        return {
          component: () => (
            <Tooltip title="Something went wrong">
              <div className="flex flex-1"></div>
            </Tooltip>
          ),
        };
      }

      return undefined;
    },
    // cellStyle: (params: CellClassParams<ProfileWithMetricsModel, PerformanceTargetEstimate>) => {
    //   // Don't style if not array (should not happen) or target isn't set
    //   if (!isArray(params.value) || !params.value[0]) return null;

    //   const colId = params.colDef?.colId as ColumnId | undefined;
    //   if (!colId) return null;

    //   const positionOnGradient = PerformanceMetricsModel.calculateEstimateToTarget(params.value);
    //   if (!positionOnGradient) return null;
    //   const color = PerformanceMetricsModel.getPerformanceMetricColor(colId, positionOnGradient);
    //   if (!color) return null;

    //   return { backgroundColor: color };
    // },
    valueFormatter: (params: ValueFormatterParams<ProfileWithMetricsModel, PerformanceTargetEstimate>) => {
      // Using valueFormatter to set which value is shown when entering edit mode, doesn't affect rendering when not editing
      // Not using valueGetter because that is also being used by comparator which needs both values
      if (!isArray(params.value) || !params.value[0]) return '';

      const colId = params.colDef?.colId as ColumnId | undefined;
      let multiplier = 1;

      const unitType = PerformanceMetricsModel.getUnitTypeForColId(colId);
      if (unitType === UnitType.PERCENTAGE) {
        multiplier = 100;
      } // else just convert to string

      return (params.value[0] * multiplier).toString() ?? '';
    },
    cellEditorParams: {
      // Getting target value from formatter to edit
      useFormatter: true,
    },
    cellRenderer: TargetEstimateCellRenderer,
    cellRendererParams: (
      params: ICellRendererParams<ProfileWithMetricsModel, PerformanceTargetEstimate, ProfilesStatsGridContext>,
    ): ITargetEstimateCellRendererParams => {
      const defaultFormatter = (value: number | null) => value?.toString() ?? '';

      if (!isArray(params.value) || !params.value[0]) {
        // Target not set - offer to set target by setting target null
        // params.value == null handled by cellRendererSelector
        return {
          ...params,
          target: null,
          estimate: null,
          isEditable: true,
          formatter: defaultFormatter,
        };
      }

      let formatter = (value: number | null): string => value?.toString() ?? '';
      const colId = params.colDef?.colId as ColumnId | undefined;

      const unitType = PerformanceMetricsModel.getUnitTypeForColId(colId);
      if (unitType === UnitType.CURRENCY) {
        const profileCurrency = params.data?.currencyCode || CurrencyCode.USD;
        formatter = (value: number | null) => formatCurrencyWholeNumbers(value, { customCurrencyCode: profileCurrency });
      } else if (unitType === UnitType.PERCENTAGE) {
        formatter = (value: number | null) => formatPercent(value);
      } // else just convert to string

      const target = params.value[0];
      const estimate = params.value[1];

      const percent = formatPercent(PerformanceMetricsModel.calculateEstimateToTarget(params.value)) || '-%';
      const estimateText =
        estimate == null ? (
          'Not enough data for this month'
        ) : (
          <>
            {formatter(estimate)} ({percent} of Target)
          </>
        );
      const tooltip = (
        <>
          <strong>TARGET VS ESTIMATE (MTD)</strong>
          <p>
            <strong>Target:</strong> {formatValueWithFormatterOrPlaceholder(target, formatter)}
            <br />
            <strong>Estimate:</strong> {estimateText}
          </p>
          <i>Click to edit Target</i>
        </>
      );
      return {
        ...params,
        target,
        estimate,
        isEditable: true,
        tooltip,
        formatter: (value: number | null) => formatValueWithFormatterOrPlaceholder(value, formatter),
      };
    },
    valueParser: (params: ValueParserParams<ProfileWithMetricsModel, PerformanceTargetEstimate>) => {
      if (params.newValue == null || params.newValue === '') return [null, params.oldValue?.[1] ?? null];
      const colId = params.colDef?.colId as ColumnId | undefined;

      const parsedValue = Number(params.newValue);
      if (isNaN(parsedValue) || parsedValue < 0) {
        toastService.error('Invalid new target value');
        return params.oldValue;
      }

      const newValue = [isPerformanceMetricFieldPercent(colId) ? parsedValue / 100 : parsedValue, params.oldValue?.[1] ?? null];
      return newValue;
    },
  };

  function formatValueWithFormatterOrPlaceholder(value: number | null, formatter: (value: number | null) => string) {
    if (value === null || value === 0) {
      // Use the formatter to get the format structure, then replace the numeric part
      const formattedZeroValue = formatter(0);

      // Replace the entire numeric part, including decimals, with the placeholder
      const formattedWithPlaceholder = formattedZeroValue.replace(
        /[0-9.,]+/g, // Match the whole number block, including commas and periods
        PLACEHOLDER_VALUE,
      );

      return formattedWithPlaceholder.trim();
    }

    return formatter(value);
  }

  function isPerformanceMetricFieldPercent(colId: ColumnId | undefined) {
    const unitType = PerformanceMetricsModel.getUnitTypeForColId(colId);
    if (unitType === UnitType.PERCENTAGE) {
      return true;
    }

    return false;
  }

  function getPerformanceMetricComparator(field: keyof PerformanceMetricsModel) {
    const idealValue = PerformanceMetricsModel.getIdealEstimateToTargetForMetric(field);

    const comparator = (valueA: PerformanceTargetEstimate, valueB: PerformanceTargetEstimate) => {
      if (!valueA || !valueB) return 0;

      const estimateToTargetA = PerformanceMetricsModel.calculateEstimateToTarget(valueA);
      const estimateToTargetB = PerformanceMetricsModel.calculateEstimateToTarget(valueB);

      const distanceToIdealA = estimateToTargetA ? Math.abs(estimateToTargetA - idealValue) : Infinity;
      const distanceToIdealB = estimateToTargetB ? Math.abs(estimateToTargetB - idealValue) : Infinity;

      return distanceToIdealA - distanceToIdealB;
    };

    return comparator;
  }

  const onCellValueChanged = (event: CellValueChangedEvent<ProfileWithMetricsModel>) => {
    // Check if the edited cell is the one you're interested in
    if (PerformanceMetricsModel.isPerformanceMetricColId(event.colDef.colId as ColumnId | undefined)) {
      const updatedData = event.data;

      updatePerformanceMetrics(updatedData);
    }
  };

  function updatePerformanceMetrics(data: ProfileWithMetricsModel) {
    updateProfileSettings({
      teamId: data.teamId,
      profileId: data.id,
      performanceAcos: data.performanceAcos[0] ?? 0,
      performanceAcots: data.performanceAcots[0] ?? 0,
      performanceAdSpend: data.performanceAdSpend[0] ?? 0,
      performanceAdSales: data.performanceAdSales[0] ?? 0,
      performanceTotalSales: data.performanceTotalSales[0] ?? 0,
      createSTVReports: data.createSTVReports,
      refetch,
    });
  }

  const customGridOptions: GridOptions<ProfileWithMetricsModel> = {
    ...DEFAULT_GRID_OPTIONS,
    ...DEFAULT_GRID_OPTIONS_ROW_GROUPS,
    sideBar: false,
    getRowId: (params) => params.data.teamId.toString() + '-' + params.data.id.toString(),
    onSelectionChanged: handleRowSelection,

    // TODO: extract context and use context type
    context: {
      metricColumnAggregates,
      conversionRatesModel,
      ...gridToggleParams,
    },
    onColumnMoved: handleColumnStateChange,
    onColumnVisible: handleColumnStateChange,
    onColumnResized: handleColumnStateChange,
    onColumnRowGroupChanged: handleColumnStateChange,
    onSortChanged: handleColumnStateChange,
    onColumnPinned: handleColumnStateChange,
    onBodyScroll: (event: BodyScrollEvent) => onSourceScroll(event.top),
    onCellValueChanged,
    columnTypes: {
      profileStatsMetricColType,
      targetEstimateColType,
      lastOptimizedType,
      checkboxColumnType,
      dataGroupColumnType,
    },
    aggFuncs: {
      profileStatsMetricAggFunc,
      teamNameFilteredGridAggFunc,
      profileNameFilteredGridAggFunc,
      lastOptimizedAggFunc,
    },
  };

  const onGridReady = (params: GridReadyEvent<ProfileWithMetricsModel>) => {
    setColumnStateGridApi(params.api);
    gridApiRef.current = params.api;
    gridContextRef.current = params.context;
    onGridReadyForMetricColumnAggregates();
    onGridReadyForSelectedCurrency();
    onGridReadyForDynamicHeight(params);
  };

  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  useEffect(() => {
    // Don't autosave while colDefs are changing
    setIsAutoSaveEnabled(false);

    // Makes sure the updated colDefs are used and in the given order
    gridApiRef.current?.setGridOption('columnDefs', columnDefs);
    gridApiRef.current?.resetColumnState();

    // Give the grid time to update cols
    setTimeout(() => {
      setIsAutoSaveEnabled(true);
    }, 3000);
  }, [columnDefs]);

  return (
    <div style={{ height: isExpanded ? `calc(100vh - ${EXPANDED_VISIBLE_ABOVE_PX_ON_SCROLL_DOWN}px)` : heightCssValue }}>
      <AlGrid
        colDefs={columnDefs}
        rowData={rowData}
        gridOptions={customGridOptions}
        isLoading={isLoading}
        onGridReadyCallback={onGridReady}
        noTopBorderRadius={true}
        fitToResizeEnabled={false}
        addExtraBottomPadding={true}
      />
    </div>
  );
};

export default ProfilesStatsTable;
