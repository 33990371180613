import { CountryCode, getCountryCode } from '@/modules/amazon-constants/types/CountryCode';
import { BasicProfileDTO } from '../api/profile.contracts';

export class BasicProfileModel {
  public id: string;
  public name: string;
  public countryCode: CountryCode;

  public get nameWithMarket() {
    return `${this.name} (${this.countryCode})`;
  }

  public getTruncatedNameWithMarket(maxLength: number) {
    if (this.name.length > maxLength) {
      return `${this.name.substring(0, maxLength) + '...'} (${this.countryCode})`;
    }

    return this.nameWithMarket;
  }

  constructor(args: BasicProfileCreateArguments) {
    this.id = args.id;
    this.name = args.name;
    this.countryCode = args.countryCode;
  }

  public static fromDTO(dto: BasicProfileDTO): BasicProfileModel {
    return new BasicProfileModel({
      id: dto.id,
      name: dto.name,
      countryCode: getCountryCode(dto.country_code),
    });
  }
}

export interface BasicProfileCreateArguments {
  id: string;
  name: string;
  countryCode: CountryCode;
}
