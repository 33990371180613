import OppositeModeOverlayBar from '@/components/OppositeModeOverlayBar';
import useFormatting from '@/hooks/useFormatting';
import HelpButton from '@/modules/application/components/HelpButton';
import { CreateImportModel } from '@/modules/optimizer/api/campaign/models/CreateImportModel';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { Button, Divider } from '@mui/material';
import type { GridApi } from 'ag-grid-enterprise';
import { FunctionComponent, MutableRefObject } from 'react';
import ImportActionBarErrorWarning from '../../components/ImportActionBarErrorWarning';
import { CampaignMappingImportDetails, ImportStep } from '../../types/feedback';

interface CampaignGroupsImportPreviewActionBarProps {
  importDetails: CampaignMappingImportDetails;
  gridApiRef: MutableRefObject<GridApi<CreateImportModel> | null>;
  onGoToStep: (step: ImportStep) => void;
  currentStep: ImportStep;
  onApplyClicked: (createOnly?: boolean) => void;
  isLoadingCreate: boolean;
}

const CampaignGroupsImportPreviewActionBar: FunctionComponent<CampaignGroupsImportPreviewActionBarProps> = ({
  importDetails,
  gridApiRef,
  onGoToStep,
  onApplyClicked,
  currentStep,
  isLoadingCreate,
}) => {
  const { formatWithThousandsSeparator } = useFormatting();

  return (
    <>
      <OppositeModeOverlayBar visible={true} marginBottom={'36px'}>
        <div className="flex flex-row items-center gap-4 px-1.5">
          {formatWithThousandsSeparator(importDetails.rowCount)} Rows
          <Divider className="my-2" orientation="vertical" flexItem />
          <HelpButton />
          <Divider className="my-2" orientation="vertical" flexItem />
          <div className="flex flex-row items-center gap-4 whitespace-nowrap">
            <ImportActionBarErrorWarning importDetails={importDetails} gridApiRef={gridApiRef} />

            {currentStep == ImportStep.CREATE && (
              <>
                <Button
                  variant="outlined"
                  onClick={() => onApplyClicked(true)}
                  endIcon={<SendRoundedIcon />}
                  disabled={isLoadingCreate || importDetails.errorCount > 0}
                >
                  Create groups w/o assignments
                </Button>

                <Button
                  variant="contained"
                  endIcon={<ArrowForwardRoundedIcon />}
                  onClick={() => onGoToStep(ImportStep.ASSIGN)}
                  disabled={importDetails.errorCount > 0}
                >
                  Review Assignments
                </Button>
              </>
            )}
            {currentStep == ImportStep.ASSIGN && (
              <>
                <Button variant="outlined" onClick={() => onGoToStep(ImportStep.CREATE)} startIcon={<ArrowBackRoundedIcon />}>
                  Back to Groups
                </Button>

                <Button
                  variant="contained"
                  endIcon={<SendRoundedIcon />}
                  onClick={() => onApplyClicked()}
                  disabled={isLoadingCreate || importDetails.errorCount > 0}
                >
                  {isLoadingCreate ? 'Creating...' : 'Create Groups and Assignments'}
                </Button>
              </>
            )}
          </div>
        </div>
      </OppositeModeOverlayBar>
    </>
  );
};

export default CampaignGroupsImportPreviewActionBar;
