import WeekRangeButtonBase from '@/components/buttons/DateRangeButtonBase'; // reuse the base component (or rename it as needed)
import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { FilterKey } from '@/components/filter-builder/types/FilterKey';
import { AlDate, DATE_FORMAT_YYYY_MM_DD } from '@/lib/date/AlDate';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useUserContext } from '@/modules/users';
import { Routes } from '@/router/router-paths';
import Tooltip from '@mui/material/Tooltip';
import { isNil } from 'lodash-es';
import { DateTime } from 'luxon';
import { CSSProperties, FunctionComponent, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import {
  ADS_DATA_INCOMPLETE_CONFIG,
  ADS_DATA_MISSING_CONFIG,
  DayStyleConfig,
  SELLER_DATA_INCOMPLETE_CONFIG,
  SELLER_DATA_MISSING_CONFIG,
  VENDOR_DATA_INCOMPLETE_CONFIG,
  VENDOR_DATA_MISSING_CONFIG,
} from '../../types/DayStyleConfig';
import { WeekRangePickerPopover } from './WeekRangePickerPopover';

interface WeekRangeButtonProps {
  title: string;
  filters: AlFilterModel[];
  setFilterValues: (filters: AlFilterModel[]) => void;
  tooltip: string;
  isDisabled?: boolean;
  hideComparison?: boolean;
  comparisonRangeWarning?: string | null;
  minDate?: string;
}

const WeekRangeButton: FunctionComponent<WeekRangeButtonProps> = ({
  title,
  filters,
  setFilterValues,
  tooltip,
  isDisabled = false,
  hideComparison = false,
  comparisonRangeWarning,
  minDate,
}) => {
  const { activeProfile } = useActiveTeamContext();
  const currentPath = useLocation().pathname;
  const { hasSellerProfiles, hasVendorProfiles } = useUserContext();
  const dateRangeButtonRef = useRef<HTMLButtonElement | null>(null);
  const [isDateRangePopoverOpen, setIsDateRangePopoverOpen] = useState(false);

  const optimizationDateRange = filters.find((filterItem) => filterItem.key === FilterKey.DATE)?.conditions;
  const comparisonDateRange = filters.find((filterItem) => filterItem.key === FilterKey.COMPARE_DATE)?.conditions;

  const onDateRangeButtonClicked = () => {
    setIsDateRangePopoverOpen((prev) => !prev);
  };

  // For pages where minDate is not provided, use the active profile’s ads start date.
  if (isNil(minDate) && activeProfile) {
    minDate = activeProfile.adsDataStartDate;
  }

  function getDayStyleConfig(day: DateTime): DayStyleConfig {
    const tooltips: string[] = [];
    let style: CSSProperties | undefined = undefined;

    const datePickerDay = new AlDate(day);
    const datePickerDayString = datePickerDay.toFormat(DATE_FORMAT_YYYY_MM_DD);

    if (ADS_DATA_INCOMPLETE_CONFIG.dates.has(datePickerDayString)) {
      tooltips.push(ADS_DATA_INCOMPLETE_CONFIG.tooltip);
      style = ADS_DATA_INCOMPLETE_CONFIG.style;
    }

    if (currentPath === Routes.PROFILES_STATS && (hasSellerProfiles || hasVendorProfiles)) {
      if (hasSellerProfiles && SELLER_DATA_INCOMPLETE_CONFIG.dates.has(datePickerDayString)) {
        tooltips.push(SELLER_DATA_INCOMPLETE_CONFIG.tooltip);
        style = SELLER_DATA_INCOMPLETE_CONFIG.style;
      }

      if (hasVendorProfiles && VENDOR_DATA_INCOMPLETE_CONFIG.dates.has(datePickerDayString)) {
        tooltips.push(VENDOR_DATA_INCOMPLETE_CONFIG.tooltip);
        style = VENDOR_DATA_INCOMPLETE_CONFIG.style;
      }
    }

    if (currentPath === Routes.PRODUCTS && (activeProfile?.isSeller || activeProfile?.isVendor)) {
      const sellingPartnerConfig = activeProfile?.isVendor ? VENDOR_DATA_INCOMPLETE_CONFIG : SELLER_DATA_INCOMPLETE_CONFIG;
      if (sellingPartnerConfig && sellingPartnerConfig.dates.has(datePickerDayString)) {
        tooltips.push(sellingPartnerConfig.tooltip);
        style = sellingPartnerConfig.style;
      }

      if (activeProfile?.sellingPartnerDataStartDate && activeProfile.adsDataStartDate) {
        const sellingPartnerDataStartDate = AlDate.parse(activeProfile.sellingPartnerDataStartDate).setHour(0);
        const adsDataStartDate = AlDate.parse(activeProfile.adsDataStartDate).setHour(0);

        if (
          sellingPartnerDataStartDate.isBefore(adsDataStartDate) &&
          datePickerDay.isBefore(adsDataStartDate) &&
          (datePickerDay.isAfter(sellingPartnerDataStartDate) || datePickerDay.isSameDate(sellingPartnerDataStartDate))
        ) {
          tooltips.push(ADS_DATA_MISSING_CONFIG.tooltip);
          style = ADS_DATA_MISSING_CONFIG.style;
        }

        if (
          adsDataStartDate.isBefore(sellingPartnerDataStartDate) &&
          datePickerDay.isBefore(sellingPartnerDataStartDate) &&
          (datePickerDay.isAfter(adsDataStartDate) || datePickerDay.isSameDate(adsDataStartDate))
        ) {
          const sellingPartnerDataMissingConfig = activeProfile?.isVendor ? VENDOR_DATA_MISSING_CONFIG : SELLER_DATA_MISSING_CONFIG;
          tooltips.push(sellingPartnerDataMissingConfig.tooltip);
          style = sellingPartnerDataMissingConfig.style;
        }
      }
    }

    return {
      tooltip: tooltips.join('. '),
      style: style || {},
    };
  }

  return (
    <div className="flex shrink-0">
      <Tooltip title={tooltip}>
        <span>
          <WeekRangeButtonBase
            buttonRef={dateRangeButtonRef}
            isDisabled={isDisabled}
            onDateRangeButtonClicked={onDateRangeButtonClicked}
            dateRange={optimizationDateRange}
            comparisonDateRange={comparisonDateRange}
            hideComparison={hideComparison}
            comparisonRangeWarning={comparisonRangeWarning}
          />
        </span>
      </Tooltip>
      <WeekRangePickerPopover
        title={title}
        buttonRef={dateRangeButtonRef}
        setIsOpen={setIsDateRangePopoverOpen}
        isOpen={isDateRangePopoverOpen}
        onSubmit={setFilterValues}
        defaultDateRange={optimizationDateRange}
        defaultComparisonDateRange={comparisonDateRange}
        minDateString={minDate}
        hideComparison={hideComparison}
        getDayStyleConfig={getDayStyleConfig}
      />
    </div>
  );
};

export default WeekRangeButton;
