import { getSeparatedValuesFromText, truncateString } from '@/modules/application/utils';
import { toastService } from '@/services/toast.service';
import { Autocomplete, AutocompleteInputChangeReason, Chip, TextField, Tooltip } from '@mui/material';
import { isEmpty, isNil } from 'lodash-es';
import { ClipboardEvent, FunctionComponent, KeyboardEvent, SyntheticEvent, useContext, useState } from 'react';
import { PendingFiltersContext } from '../context/PendingFiltersContext';
import { AlFilterModel, LogicalOperatorType, OperatorType } from '../models/AlFilterModel';
import { FilterErrorCode } from '../types/FilterErrorCodes';
import { StringComparisonOperatorSelect, StringComparisonOperatorSelectOption } from './StringComparisonOperatorSelect';

interface FilterStringComparisonProps {
  filter: AlFilterModel;
  label: string;
  filterErrorCodeSet: Set<FilterErrorCode> | undefined;
}

export const FilterStringComparison: FunctionComponent<FilterStringComparisonProps> = ({ filter, filterErrorCodeSet }) => {
  const { setFilterValue } = useContext(PendingFiltersContext);
  const filterKey = filter.key;

  const [inputValue, setInputValue] = useState<string>('');

  const tags =
    filter.conditions && !isNil(filter?.conditions?.[0]) && Array.isArray(filter?.conditions[0].values)
      ? filter?.conditions[0].values.map(String)
      : [];
  const stringComparisonOperator =
    filter.conditions && !isNil(filter?.conditions?.[0]) ? filter.conditions[0].operator : filter.defaultConditionOperators[0];
  const logicalOperator = filter.logicalOperator as LogicalOperatorType; // filter.logicalOperator should be always defined as it is set in the constructor

  // SETTERS
  function setTags(newTags: string[]) {
    setNewValues(newTags, stringComparisonOperator, logicalOperator);
  }

  function setStringComparisonOperator(newStringComparisonOperator: OperatorType) {
    setNewValues(tags, newStringComparisonOperator, logicalOperator);
  }

  function setLogicalOperator(newLogicalOperator: LogicalOperatorType) {
    setNewValues(tags, stringComparisonOperator, newLogicalOperator);
  }

  function setNewValues(newTags: string[], newStringComparisonOperator: OperatorType, newLogicalOperator: LogicalOperatorType) {
    filter.logicalOperator = newLogicalOperator;
    filter.conditions = [
      {
        values: newTags,
        operator: newStringComparisonOperator,
      },
    ];

    setFilterValue(filter);
  }

  const getTextFieldLabel = () => {
    switch (stringComparisonOperator) {
      case OperatorType.LIKE:
        return 'Contains';
      case OperatorType.NOT_LIKE:
        return "Doesn't Contain";
      default:
        return 'Partial Name';
    }
  };

  const handleInputChange = (event: SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
    if (reason === 'input') {
      setInputValue(value);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && inputValue) {
      const inputValueTrimmed = inputValue.trim();
      if (isValueValid(inputValueTrimmed)) {
        setTags([...tags, inputValueTrimmed]);
        setInputValue('');
        event.preventDefault(); // Prevent form submission if inside a form
      }
    } else if (event.key === 'Backspace' && !inputValue) {
      setTags(tags.slice(0, -1));
    }
  };

  const handleBlur = () => {
    if (isValueValid(inputValue)) {
      setTags([...tags, inputValue]);
      setInputValue('');
    }
  };

  const isValueValid = (value: string): boolean => {
    if (isEmpty(value)) {
      return false;
    }

    const isTagDuplicate = tags.some((tag) => tag.toLowerCase() === value.toLowerCase());
    if (isTagDuplicate) {
      toastService.error(value + ' is already in the list');
    }

    return !isTagDuplicate;
  };

  const handleDelete = (tagToDelete: string) => {
    setTags(tags.filter((tag) => tag !== tagToDelete));
  };

  const handleOperatorChange = (newSelection: StringComparisonOperatorSelectOption) => {
    setStringComparisonOperator(newSelection.operator);
    setLogicalOperator(newSelection.logicalOperator);
  };

  const handlePaste = (event: ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault(); // Prevent the default paste behavior
    const newTags = getSeparatedValuesFromText(event.clipboardData.getData('Text'), tags);

    setTags([...new Set([...tags, ...newTags])]); // Combine new and existing tags, removing duplicates
    setInputValue(''); // Clear the input field after processing the paste
  };

  const firstConditionErrorMessage =
    filterErrorCodeSet?.has(FilterErrorCode.EMPTY_1ST_CONDITION) || filterErrorCodeSet?.has(FilterErrorCode.NO_CONDITIONS)
      ? 'This field is required'
      : undefined;

  return (
    <div className="flex w-full flex-row gap-2">
      <div className="flex-0">
        <StringComparisonOperatorSelect
          label={'Operator'}
          filter={filter}
          handleChange={handleOperatorChange}
          operatorValue={stringComparisonOperator}
          logicalOperatorValue={logicalOperator}
        />
      </div>

      <div className="w-full flex-1">
        <Autocomplete
          renderInput={(params) => (
            <TextField
              label={getTextFieldLabel()}
              {...params}
              placeholder="Type the phrase and press [ENTER] to add it to the list"
              slotProps={{
                inputLabel: { shrink: true },
              }}
              error={!isNil(firstConditionErrorMessage)}
              helperText={firstConditionErrorMessage}
            />
          )}
          id={'input-' + filterKey}
          clearIcon={false}
          options={[]}
          freeSolo
          multiple
          value={tags} // Setting value to the existing tags
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          clearOnBlur
          renderTags={(value) =>
            value.map((tag, index) => (
              <Tooltip key={index} title={tag}>
                <Chip className="mr-0.5" size="small" label={truncateString(tag)} onDelete={() => handleDelete(tag)} />
              </Tooltip>
            ))
          }
          inputValue={inputValue}
          onInputChange={handleInputChange}
          onPaste={handlePaste}
        />
      </div>
    </div>
  );
};
