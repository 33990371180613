import * as Sentry from '@sentry/react';
import { isEmpty } from 'lodash-es';

export enum CountryCode {
  BR = 'BR', // Brazil
  CA = 'CA', // Canada
  MX = 'MX', // Mexico
  US = 'US', // United States
  AE = 'AE', // United Arab Emirates
  BE = 'BE', // Belgium
  DE = 'DE', // Germany
  EG = 'EG', // Egypt
  ES = 'ES', // Spain
  FR = 'FR', // France
  IN = 'IN', // India
  IT = 'IT', // Italy
  NL = 'NL', // Netherlands
  PL = 'PL', // Poland
  SA = 'SA', // Saudi Arabia
  SE = 'SE', // Sweden
  TR = 'TR', // Turkey
  UK = 'UK', // United Kingdom
  AU = 'AU', // Australia
  JP = 'JP', // Japan
  SG = 'SG', // Singapore
  ZA = 'ZA', // South Africa
}

export function getCountryCode(code: string): CountryCode {
  switch (code.toUpperCase()) {
    case 'BR':
      return CountryCode.BR;
    case 'CA':
      return CountryCode.CA;
    case 'MX':
      return CountryCode.MX;
    case 'US':
      return CountryCode.US;
    case 'AE':
      return CountryCode.AE;
    case 'BE':
      return CountryCode.BE;
    case 'DE':
      return CountryCode.DE;
    case 'EG':
      return CountryCode.EG;
    case 'ES':
      return CountryCode.ES;
    case 'FR':
      return CountryCode.FR;
    case 'IN':
      return CountryCode.IN;
    case 'IT':
      return CountryCode.IT;
    case 'NL':
      return CountryCode.NL;
    case 'PL':
      return CountryCode.PL;
    case 'SA':
      return CountryCode.SA;
    case 'SE':
      return CountryCode.SE;
    case 'TR':
      return CountryCode.TR;
    case 'UK':
      return CountryCode.UK;
    case 'AU':
      return CountryCode.AU;
    case 'JP':
      return CountryCode.JP;
    case 'SG':
      return CountryCode.SG;
    default:
      console.error(`Invalid country code: ${isEmpty(code) ? '<empty>' : code}`);
      Sentry.captureMessage(`Invalid country code: ${isEmpty(code) ? '<empty>' : code}`, 'error');
      return CountryCode.US;
  }
}

export function getSortedCountryCodes(priorityOrder: CountryCode[]) {
  return Object.values(CountryCode).sort((a, b) => {
    // Get the position of a and b in the priorityOrder array
    const indexA = priorityOrder.indexOf(a);
    const indexB = priorityOrder.indexOf(b);

    // If either a or b is in the priorityOrder, compare their positions
    if (indexA !== -1 || indexB !== -1) {
      // If one of them is not in the priorityOrder, give it a large index (Infinity)
      return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
    }

    // If neither a nor b is in the priorityOrder, keep their original order
    return 0;
  });
}
