import { AdLabsColorVariant, MuiColorVariant, TailwindColorVariant } from '@/config/theme/color.type';
import { FlowType } from '@/modules/log-viewing/api/logs-contracts';
import { CampaignAdType, PlacementType, TargetingType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { ProductAvailability } from '@/modules/products/api/products-contracts';
import { ReportingStatusType, StatusType } from '@/modules/profiles/api/profile.contracts';
import { TargetEntityExtendedType, TargetEntityType } from '@/modules/targeting/api/targets-contracts';
import { SentimentDirection } from './SentimentDirection';

export enum SentimentColors {
  RED = 'text-orange-400 dark:text-orange-400',
  GRAY = 'text-slate-400 dark:text-slate-400',
  GREEN = 'text-emerald-600 dark:text-emerald-600',
}

export const getColorClassBySentimentCurrentPrevious = (
  sentimentDirection: SentimentDirection,
  current: number,
  previous: number,
): string => {
  if (sentimentDirection === SentimentDirection.NEUTRAL || current === previous) {
    return SentimentColors.GRAY;
  } else {
    const isPositiveChange = current > previous;
    return (sentimentDirection === SentimentDirection.SYNCED) === isPositiveChange ? SentimentColors.GREEN : SentimentColors.RED;
  }
};

export function getColorForProfileDataStatus(
  status: StatusType | ReportingStatusType | undefined | null,
): MuiColorVariant | TailwindColorVariant {
  switch (status) {
    case StatusType.PROCESSED:
    case ReportingStatusType.COMPLETED:
      return TailwindColorVariant.GREEN;

    case StatusType.DOWNLOADED:
    case StatusType.ONGOING:
    case StatusType.PENDING:
    case ReportingStatusType.LOADING:
    case ReportingStatusType.NEVER:
    case ReportingStatusType.WAITING_RESPONSE:
      return TailwindColorVariant.BLUE;

    case ReportingStatusType.OUTDATED:
    case StatusType.FAILED:
    case ReportingStatusType.ERROR:
      return TailwindColorVariant.ORANGE;

    default:
      return MuiColorVariant.Secondary;
  }
}

export enum GridColors {
  GRAY = SentimentColors.GRAY,
}

export function getCampaignAdTypeColor(adType: CampaignAdType | undefined) {
  switch (adType) {
    case CampaignAdType.PRODUCTS:
      return TailwindColorVariant.BLUE;
    case CampaignAdType.BRANDS:
      return TailwindColorVariant.GREEN;
    case CampaignAdType.DISPLAY:
      return TailwindColorVariant.VIOLET;
    case CampaignAdType.TV:
      return TailwindColorVariant.PINK;
    default:
      return TailwindColorVariant.BLUE;
  }
}

export function getPlacementTypeColor(placementType: PlacementType | undefined) {
  switch (placementType) {
    case PlacementType.PLACEMENT_PRODUCT_PAGE:
      return TailwindColorVariant.BLUE;
    case PlacementType.PLACEMENT_REST_OF_SEARCH:
      return TailwindColorVariant.GREEN;
    case PlacementType.PLACEMENT_TOP:
      return TailwindColorVariant.VIOLET;
    case PlacementType.HOME:
      return TailwindColorVariant.AMBER;
    case PlacementType.DETAIL_PAGE:
      return TailwindColorVariant.LIME;
    case PlacementType.OTHER:
      return TailwindColorVariant.SLATE;
    default:
      return TailwindColorVariant.GREEN;
  }
}

export const TargetingTypeColors: Record<TargetingType, AdLabsColorVariant> = {
  [TargetingType.TARGETING]: 'orange',
  [TargetingType.AUDIENCES]: 'blue',
  [TargetingType.MANUAL]: 'emerald',
  [TargetingType.AUTO]: 'amber',
  [TargetingType.CONTEXTUAL]: 'sky',
  [TargetingType.NONE]: 'default',
};

export const TargetEntityTypeColors: Record<TargetEntityExtendedType, AdLabsColorVariant> = {
  [TargetEntityType.KEYWORD]: 'emerald',
  [TargetEntityType.PRODUCT_TARGET]: 'blue',
  ['AUDIENCE']: 'violet',
  [TargetEntityType.UNSET]: 'sky',
};

export const LogEntityTypeColors: Record<FlowType, AdLabsColorVariant> = {
  [FlowType.NEGATIVE_TARGETING]: 'emerald',
  [FlowType.HARVESTING]: 'amber',
  [FlowType.CAMPAIGN_OPTIMIZER]: 'blue',
  [FlowType.TARGETING]: 'lime',
  [FlowType.PLACEMENT]: 'pink',
  [FlowType.SEARCH_TERMS]: 'violet',
  [FlowType.CAMPAIGN_MAPPING]: 'orange',
  [FlowType.OPTIMIZATION_GROUPS]: 'sky',
  [FlowType.OPTIMIZATION_GROUPS_IMPORT]: 'sky',
  [FlowType.DATA_GROUPS]: 'blue',
};

export const AvailabilityColors: Record<ProductAvailability, AdLabsColorVariant> = {
  [ProductAvailability.IN_STOCK]: 'emerald',
  [ProductAvailability.AVAILABLE_DATE]: 'amber',
  [ProductAvailability.IN_STOCK_SCARCE]: 'orange',
  [ProductAvailability.OUT_OF_STOCK]: 'error',
  [ProductAvailability.PREORDER]: 'emerald',
  [ProductAvailability.LEADTIME]: 'violet',
  [ProductAvailability.ERROR]: 'error',
  [ProductAvailability.UNKNOWN]: 'amber',
};

export function simpleHash(text: string): number {
  let hash = 0;
  for (let i = 0; i < text.length; i++) {
    const char = text.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return Math.abs(hash);
}

export function getColorForText(inputText: string): TailwindColorVariant {
  const colorVariants = Object.values(TailwindColorVariant);
  const hash = simpleHash(inputText);
  const index = hash % colorVariants.length;
  return colorVariants[index];
}

// GRADIENTS
enum GradientColors {
  RED = '#fca5a5',
  YELLOW = '#fef08a',
  GREEN = '#6ee7b7',
}

// Gradient position values are absolute which will be directly compared with the input value (not percentages)
export const gradientAround1Good = [
  { position: 0.85, color: GradientColors.RED },
  { position: 0.9, color: GradientColors.YELLOW },
  { position: 0.95, color: GradientColors.GREEN },
  { position: 1.05, color: GradientColors.GREEN },
  { position: 1.1, color: GradientColors.YELLOW },
  { position: 1.15, color: GradientColors.RED },
];

export const gradientBelow1Good = [
  { position: 1, color: GradientColors.GREEN },
  { position: 1.05, color: GradientColors.YELLOW },
  { position: 1.1, color: GradientColors.RED },
];

export const gradientAbove1Good = [
  { position: 0.9, color: GradientColors.RED },
  { position: 0.95, color: GradientColors.YELLOW },
  { position: 1, color: GradientColors.GREEN },
];

export type AlGradient = { position: number; color: string }[];

export function getColorOnGradientByValue(
  gradient: AlGradient | null,
  value: number | null,
  opacity: number = 0.3, // default to full opacity
): string | null {
  if (!gradient || !value) return null;

  const minPosition = gradient[0].position;
  const maxPosition = gradient[gradient.length - 1].position;
  const clampedValue = Math.max(minPosition, Math.min(value, maxPosition));

  let finalHex = gradient[0].color; // default fallback

  for (let i = 0; i < gradient.length - 1; i++) {
    const start = gradient[i];
    const end = gradient[i + 1];

    if (clampedValue >= start.position && clampedValue <= end.position) {
      const ratio = (clampedValue - start.position) / (end.position - start.position);
      finalHex = interpolateColor(start.color, end.color, ratio);
      break;
    }
  }

  return hexToRgba(finalHex, opacity);
}

function hexToRgba(hex: string, opacity: number): string {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

// Helper function for color interpolation
function interpolateColor(color1: string, color2: string, ratio: number): string {
  // Helper: convert hex to RGB
  const hexToRgb = (hex: string): { r: number; g: number; b: number } => {
    const bigint = parseInt(hex.slice(1), 16);
    return {
      r: (bigint >> 16) & 255,
      g: (bigint >> 8) & 255,
      b: bigint & 255,
    };
  };

  // Helper: convert RGB to hex
  const rgbToHex = (r: number, g: number, b: number): string =>
    `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;

  // Convert sRGB component to linear space.
  const srgbToLinear = (c: number): number => {
    const cs = c / 255;
    return cs <= 0.04045 ? cs / 12.92 : Math.pow((cs + 0.055) / 1.055, 2.4);
  };

  // Convert linear space value to sRGB component.
  const linearToSrgb = (c: number): number => {
    const cs = c <= 0.0031308 ? c * 12.92 : 1.055 * Math.pow(c, 1 / 2.4) - 0.055;
    return Math.round(cs * 255);
  };

  const c1 = hexToRgb(color1);
  const c2 = hexToRgb(color2);

  // Convert each channel to linear space.
  const r1 = srgbToLinear(c1.r);
  const g1 = srgbToLinear(c1.g);
  const b1 = srgbToLinear(c1.b);
  const r2 = srgbToLinear(c2.r);
  const g2 = srgbToLinear(c2.g);
  const b2 = srgbToLinear(c2.b);

  // Interpolate in linear space.
  const rLin = r1 + (r2 - r1) * ratio;
  const gLin = g1 + (g2 - g1) * ratio;
  const bLin = b1 + (b2 - b1) * ratio;

  // Convert back to sRGB space.
  const r = linearToSrgb(rLin);
  const g = linearToSrgb(gLin);
  const b = linearToSrgb(bLin);

  return rgbToHex(r, g, b);
}

export const COLOR_BG_ERROR = 'bg-red-300';
export const COLOR_BG_WARNING = 'bg-orange-300';

export function getErrorWarningCellClass(isError: boolean, isWarning: boolean): string {
  let cellClasses = 'flex items-center w-full bg-opacity-50 pr-1 pl-3';

  if (isError) {
    cellClasses += ` ${COLOR_BG_ERROR}`;
  } else if (isWarning) {
    cellClasses += ` ${COLOR_BG_WARNING}`;
  }

  return cellClasses;
}
