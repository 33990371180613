import OppositeModeOverlayBar from '@/components/OppositeModeOverlayBar';
import useFormatting from '@/hooks/useFormatting';
import BugButton from '@/modules/application/components/BugButton';
import HelpButton from '@/modules/application/components/HelpButton';
import { campaignMappingService, invalidateProfile_campaignMappingQueryKeys } from '@/modules/campaign-mapping/api/campaign-mapping-service';
import { CampaignMappingModel } from '@/modules/campaign-mapping/models/CampaignMappingModel';
import { CampaignMappingModelWithValidation } from '@/modules/campaign-mapping/models/CampaignMappingModelWithValidation';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { toastService } from '@/services/toast.service';
import { Button, Divider } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import type { GridApi } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import { FunctionComponent, MutableRefObject, useCallback, useState } from 'react';
import ImportActionBarErrorWarning from '../../components/ImportActionBarErrorWarning';
import { CampaignMappingImportDetails } from '../../types/feedback';

interface CampaignMappingImportPreviewActionBarProps {
  importDetails: CampaignMappingImportDetails;
  campaignMappingTableGridApiRef: MutableRefObject<GridApi<CampaignMappingModelWithValidation> | null>;
  onCloseModal: () => void;
}

const CampaignMappingImportPreviewActionBar: FunctionComponent<CampaignMappingImportPreviewActionBarProps> = ({
  importDetails,
  campaignMappingTableGridApiRef,
  onCloseModal,
}) => {
  const queryClient = useQueryClient();
  const { activeProfile } = useActiveTeamContext();
  const { formatWithThousandsSeparator } = useFormatting();

  // Create
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const onCreateClicked = useCallback(async () => {
    if (!campaignMappingTableGridApiRef.current) {
      return;
    }
    setIsLoadingCreate(true);

    try {
      const campaignMappingsWithValidation: CampaignMappingModelWithValidation[] = [];
      campaignMappingTableGridApiRef.current.forEachNode((node) => {
        if (isNil(node.data)) return;

        const data: CampaignMappingModelWithValidation = node.data;
        campaignMappingsWithValidation.push(data);
      });
      const campaignMappings: CampaignMappingModel[] =
        CampaignMappingModel.convertCampaignMappingWithValidationArrayToCampaignMappingArray(campaignMappingsWithValidation);

      const response = await campaignMappingService.createCampaignMappings(campaignMappings);

      if (!response.isSuccess) {
        toastService.error(response.message);
      } else {
        toastService.success('Created new campaign mapping');
        invalidateProfile_campaignMappingQueryKeys(queryClient, activeProfile?.id);
        onCloseModal();
      }
    } catch (error) {
      console.error(error);
      toastService.error('Failed to create campaign mappings: ' + error);
    }

    setIsLoadingCreate(false);
  }, [campaignMappingTableGridApiRef, activeProfile?.id]);

  return (
    <>
      <OppositeModeOverlayBar visible={true} marginBottom={'36px'}>
        <div className="flex flex-row items-center gap-4 px-1.5">
          {formatWithThousandsSeparator(importDetails.rowCount)} Rows
          <Divider className="my-2" orientation="vertical" flexItem />
          <HelpButton />
          <BugButton />
          <Divider className="my-2" orientation="vertical" flexItem />
          <div className="flex flex-row items-center gap-4 whitespace-nowrap">
            <ImportActionBarErrorWarning importDetails={importDetails} gridApiRef={campaignMappingTableGridApiRef} />

            <Button
              variant="contained"
              loading={isLoadingCreate}
              onClick={onCreateClicked}
              color="primary"
              size="small"
              disabled={importDetails.errorCount > 0 || isLoadingCreate || importDetails.rowCount == 0}
            >
              Create New Mappings
            </Button>
          </div>
        </div>
      </OppositeModeOverlayBar>
    </>
  );
};

export default CampaignMappingImportPreviewActionBar;
