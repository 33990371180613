import { RouteObject } from 'react-router';
import DaypartingOverviewPage from '../pages/DaypartingOverviewPage';
export const DAYPARTING_ROUTES: RouteObject[] = [
  {
    path: 'dayparting',
    children: [
      {
        index: true,
        element: <DaypartingOverviewPage />,
      },
    ],
  },
];
