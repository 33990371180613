import { GridColors } from '@/types/colors.enum';
import { Tooltip } from '@mui/material';
import type { ICellRendererParams } from 'ag-grid-enterprise';
import { JSX } from 'react';
import { IconCellRendererConfig } from './IconCellRendererConfigs';

interface IconCellRendererParams<T> extends ICellRendererParams {
  state: T;
  config: Record<string, IconCellRendererConfig<T>>;
}

const IconCellRenderer = <T,>({ node, colDef, value, state, config }: IconCellRendererParams<T>): JSX.Element => {
  if (node.group && value) {
    const isAutoGroupColumn = colDef?.colId?.includes('ag-Grid-AutoColumn') ?? false;

    if (isAutoGroupColumn) {
      return (
        <div className="flex flex-row items-center gap-1">
          <GenericIcon state={state} config={config} />

          <span className={GridColors.GRAY}>{node.allChildrenCount}</span>
        </div>
      );
    } else {
      return <>{createElements(config, value)}</>;
    }
  }

  return <GenericIcon state={state} config={config} />;
};

function createElements<T>(config: Record<string, IconCellRendererConfig<T>>, stateValueAndCount: Record<string, number>): JSX.Element {
  const elements = Object.entries(stateValueAndCount)
    .filter(([, count]) => count > 0)
    .map(([key, count]) => {
      return (
        <div className="mr-2 flex flex-row items-center gap-1" key={key}>
          <GenericIcon state={key as unknown as T} config={config} />
          <span className={GridColors.GRAY}>{count}</span>
        </div>
      );
    });

  return <div className="flex flex-row gap-2">{elements}</div>;
}

export default IconCellRenderer;

interface GenericIconProps<T> {
  state: T;
  config: Record<string, IconCellRendererConfig<T>>;
}

const GenericIcon = <T,>({ state, config }: GenericIconProps<T>): JSX.Element => {
  const conf = config[state as string];

  return (
    <div className="mr-0.5 flex h-full flex-row items-center">
      <Tooltip title={conf?.tooltip}>
        <div className={`flex h-5 w-5 items-center justify-center rounded-full bg-opacity-10 ${config?.styleClasses}`}>{conf?.icon}</div>
      </Tooltip>
    </div>
  );
};
