import MetricTimelineChart from '@/components/chart/MetricTimelineChart';
import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';
import ProfileDataStatusAlerts from '@/components/feedback/ProfileDataStatusAlerts';
import ShowXTimesAlert from '@/components/feedback/ShowXTimesAlert';
import { getDefaultSearchQueryPerformanceFilters } from '@/components/filter-builder/models/AlFilterModel';
import { DateRangeType } from '@/components/filter-builder/types/DateRangeType';
import { DEFAULT_VISIBLE_METRICS_SQP } from '@/components/metrics/MetricsConfig';
import MetricsContainer from '@/components/metrics/MetricsContainer';
import { SQP_METRIC_CARD_FIELDS } from '@/components/metrics/types/MetricField';
import { useNavigationConfirmationModal } from '@/components/modals/confirmation-modal/useNavigationConfirmationModal';
import useEscapableToggle from '@/hooks/useEscapableToggle';
import useGlobalLoadingStateObserver from '@/hooks/useGlobalLoadingStateObserver';
import useMetricChartTablePageVariables from '@/hooks/useMetricChartTablePageVariables';
import { useTranslation } from '@/lib';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import ProfileSyncSelectButton from '@/modules/optimizer/ProfileSyncSelectButton';
import { usePaywallManager } from '@/modules/plans/hooks/usePaywallManager';
import { ReportingStatusType } from '@/modules/profiles/api/profile.contracts';
import { QUERY_SEARCH_QUERY_PERFORMANCE_STATUS_REPORT_KEY } from '@/modules/profiles/api/profile.service';
import { sellerCentralService } from '@/modules/seller-central/api/seller-central-service';
import { PreferredTimePicker } from '@/modules/teams/components/PreferredTimePicker';
import { SellingPartnerAuthButton } from '@/modules/teams/components/SellingPartnerAuthButton';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';
import UpgradeSubscriptionButton from '@/modules/teams/components/UpgradeSubscriptionButton';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey, useUserContext } from '@/modules/users';
import { Routes } from '@/router/router-paths';
import { toastService } from '@/services/toast.service';
import { ContextKey, DEFAULT_GRID_TOGGLES } from '@/types/context-shared';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Alert, AlertTitle, Card, CircularProgress, Tooltip, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import type { GridReadyEvent } from 'ag-grid-community';
import type { GridApi } from 'ag-grid-enterprise';
import { isEmpty, isNil } from 'lodash-es';
import { FunctionComponent, useRef, useState } from 'react';
import { createSearchQueryPerformanceWithTimelineQueryKey, searchQueryPerformanceService } from '../api/search-query-performance-service';
import SearchQueryPerformanceTable from '../components/search-terms-table/SearchQueryPerformanceTable';
import SearchQueryPerformanceFilterBar from '../components/SearchQueryPerformanceFilterBar';

const SearchQueryPerformancePage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { activeTeam, activeProfile } = useActiveTeamContext();
  const { refetchUser } = useUserContext();
  const { ModalComponent: navigationModal } = useNavigationConfirmationModal(); // TODO

  usePaywallManager({
    route: Routes.SEARCH_QUERY_PERFORMANCE,
    message: 'Upgrade to Pro to Access the Search Query Performance Tool Suite',
    canUsePage: (team) => team.subscriptionPlan.canUseSearchQueryPerformancePage,
  });

  const availableMetrics = [...(activeProfile?.isSeller ? SQP_METRIC_CARD_FIELDS : [])];

  const pageVariables = useMetricChartTablePageVariables({
    contextKey: ContextKey.SEARCH_QUERY_PERFORMANCE,
    metricsUserSettingKey: UserSettingKey.VISIBLE_METRICS_SEARCH_QUERY_PERFORMANCE,
    defaultFilters: getDefaultSearchQueryPerformanceFilters(),
    gridToggles: DEFAULT_GRID_TOGGLES,
    availableMetrics,
    defaultVisibleMetrics: DEFAULT_VISIBLE_METRICS_SQP,
    dateRangeType: DateRangeType.WEEK,
  });

  const [isShowingAlerts, setIsShowingAlerts] = useState<boolean>(true);

  const searchQueryPerformanceTableGridApiRef = useRef<GridApi | null>(null);

  const searchQueryPerformanceWithTimelineQueryKey = createSearchQueryPerformanceWithTimelineQueryKey(
    activeProfile?.id,
    pageVariables.filters,
  );
  const {
    data: searchQueryPerformanceWithTimeline,
    isLoading: isSearchQueryPerformanceRowDataLoading,
    isError: isSearchQueryPerformanceLoadingError,
    error: searchQueryPerformanceLoadingError,
    isFetching,
  } = useQuery({
    queryKey: searchQueryPerformanceWithTimelineQueryKey,
    queryFn: async () => {
      const result = await searchQueryPerformanceService.getSearchQueryPerformanceWithTimeline(pageVariables.filters);
      if (result.isSuccess) {
        return result.payload;
      } else {
        toastService.error('Error loading search query performance ' + JSON.stringify(result));
      }
    },
    enabled: pageVariables.isFiltersEnabled && !isEmpty(activeProfile?.id),
  });

  useGlobalLoadingStateObserver('isFetching search query performance', isFetching);

  function onSearchQueryPerformanceTableGridReady(params: GridReadyEvent) {
    searchQueryPerformanceTableGridApiRef.current = params.api;
  }

  const [isTableExpanded, toggleTableExpanded] = useEscapableToggle(false);

  const showSellerNotConnectedAlert = activeProfile && !activeProfile?.isSeller;

  const { data: searchQueryReportStatus } = useQuery({
    queryKey: [QUERY_SEARCH_QUERY_PERFORMANCE_STATUS_REPORT_KEY, activeTeam?.id, activeProfile?.id],
    queryFn: async () => {
      const result = await sellerCentralService.getSearchQueryStatus(activeTeam?.id ?? 0, activeProfile?.id ?? '');
      if (result.isSuccess) {
        return result.payload;
      } else {
        throw new Error('Error loading SQP report status \n' + JSON.stringify(result));
      }
    },
    enabled: !isNil(activeProfile) && !isNil(activeTeam),
  });

  const isDataUpdating = searchQueryReportStatus?.status == ReportingStatusType.LOADING;

  return (
    <>
      <PageLayout showFullscreen={isTableExpanded}>
        {!isTableExpanded && (
          <PageLayoutTopBar
            header={
              <div className="flex flex-row items-center gap-4">
                {t('search_query_performance')}
                <div>
                  <Tooltip
                    title={
                      'SQP data is updated weekly on Wednesdays with the daily update. Adding or removing products from the SQP reports will only take effect in the subsequent report update'
                    }
                  >
                    <span className="flex items-center font-medium">
                      <InfoIcon fontSize="small" style={{ marginRight: '6px' }} />
                    </span>
                  </Tooltip>
                </div>
              </div>
            }
            actions={
              <div className="flex flex-row items-center gap-2">
                {!activeTeam?.hasProPlan && <UpgradeSubscriptionButton />}
                <PreferredTimePicker isEmbeddedInTopBar onSuccess={() => refetchUser()} />
                <TeamSelect />
                <ProfileSyncSelectButton disableFilters={pageVariables.disableFilters} />{' '}
              </div>
            }
          />
        )}

        <ProfileDataStatusAlerts isShowingAlerts={isShowingAlerts} setIsShowingAlerts={setIsShowingAlerts} />

        {showSellerNotConnectedAlert && !activeProfile?.isVendor && (
          <Card className="m-4 rounded-xl">
            <Alert severity="info">
              <div className="flex w-full flex-col gap-4">
                <div className="flex flex-col items-start gap-2">
                  <Typography variant="body1">
                    Connect your Seller Central account to unlock Search Query Performance Reports and see how you stack up against
                    competitors
                  </Typography>
                  <div className="mt-4">
                    <SellingPartnerAuthButton />
                  </div>
                </div>
              </div>
            </Alert>
          </Card>
        )}

        {showSellerNotConnectedAlert && activeProfile?.isVendor && (
          <Card className="m-4 rounded-xl">
            <Alert severity="info">
              <Typography variant="body1">
                Amazon does not provide Seach Query Performance reports for Vendors 😕 We hope they will one day, and then this page will be
                ready for you to use!
              </Typography>
            </Alert>
          </Card>
        )}

        {!isShowingAlerts && !showSellerNotConnectedAlert && (
          <PageLayoutBody suppressBottomPadding={isTableExpanded} suppressHorizontalPadding={isTableExpanded}>
            {!isTableExpanded && isDataUpdating && (
              <Card className="mt-2">
                <Alert severity="info">
                  <div className="flex w-full flex-col gap-4">
                    <div className="flex flex-col items-start gap-2">
                      <AlertTitle>
                        <div className="flex items-center gap-2">
                          <CircularProgress style={{ color: 'blue' }} size={18} />
                          SQP Data is currently updating
                        </div>
                      </AlertTitle>
                      SQP reports may take up to several hours to complete.
                    </div>
                  </div>
                </Alert>
              </Card>
            )}

            {!isTableExpanded && (
              <ShowXTimesAlert
                timesToDisplay={2}
                title="AdLabs pulls SQP data for up to 100 ASINs (Updates every Wednesday)"
                content={
                  <>
                    We’ve prioritized enabling your top-performing ASINs from distinct parents to maximize coverage of unique search terms.
                    You can change enabled ASINs anytime in the{' '}
                    <a href="/products" target="_blank" rel="noopener noreferrer" className="font-semibold no-underline hover:text-blue-600">
                      Products Overview
                    </a>
                    , just like managing data groups.{' '}
                    <a
                      className="group font-semibold text-inherit no-underline hover:text-blue-600"
                      href="https://help.adlabs.app/en/articles/47-search-query-performance-reports"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Learn More
                      <OpenInNewIcon fontSize="inherit" className="ml-1 opacity-0 transition-opacity duration-200 group-hover:opacity-100" />
                    </a>
                  </>
                }
                userSettingKey={UserSettingKey.SQP_INFO_MESSAGE_DISPLAY_COUNT}
              />
            )}

            {!isTableExpanded && (
              <div className="mt-2">
                <AlErrorBoundary>
                  <MetricsContainer
                    metricValues={searchQueryPerformanceWithTimeline?.metrics}
                    isLoading={isSearchQueryPerformanceRowDataLoading}
                    isError={isSearchQueryPerformanceLoadingError}
                    error={searchQueryPerformanceLoadingError}
                    visibleMetrics={pageVariables.visibleMetrics}
                    setVisibleMetrics={pageVariables.setVisibleMetrics}
                    showComparison={pageVariables.gridToggles.comparisonUnit != 'hidden'}
                    availableMetrics={availableMetrics}
                  />
                </AlErrorBoundary>
              </div>
            )}

            {!isTableExpanded && (
              <div className="my-2">
                <AlErrorBoundary>
                  <MetricTimelineChart
                    visibleMetrics={pageVariables.visibleMetrics}
                    timelineData={searchQueryPerformanceWithTimeline?.timeline}
                    isLoading={isSearchQueryPerformanceRowDataLoading}
                    isError={isSearchQueryPerformanceLoadingError}
                    error={searchQueryPerformanceLoadingError}
                    isViewChartByToggleButtonsVisible={false}
                  />
                </AlErrorBoundary>
              </div>
            )}

            <AlErrorBoundary>
              <SearchQueryPerformanceFilterBar
                withTimeline={searchQueryPerformanceWithTimeline}
                pageVariables={pageVariables}
                gridApiRef={searchQueryPerformanceTableGridApiRef}
                isExpanded={isTableExpanded}
                onExpandTable={toggleTableExpanded}
              />
            </AlErrorBoundary>

            <AlErrorBoundary>
              <SearchQueryPerformanceTable
                withTimeline={searchQueryPerformanceWithTimeline}
                isLoading={isSearchQueryPerformanceRowDataLoading}
                searchQueryPerformanceLoadingErrorMessage={
                  searchQueryPerformanceLoadingError instanceof Error ? searchQueryPerformanceLoadingError.message : ''
                }
                isSearchQueryPerformanceLoadingError={isSearchQueryPerformanceLoadingError}
                pageVariables={pageVariables}
                onGridReadyCallback={onSearchQueryPerformanceTableGridReady}
                noTopBorderRadius={true}
                isExpanded={isTableExpanded}
              />
            </AlErrorBoundary>
          </PageLayoutBody>
        )}
      </PageLayout>
      {/* <AlErrorBoundary>
        <SearchQueryPerformanceSelectionActionsBar
          selectedSearchQueryPerformance={selectedSearchQueryPerformance}
          setSelectedSearchQueryPerformance={setSelectedSearchQueryPerformance}
          totalSearchQueryPerformanceCount={
            searchQueryPerformanceWithTimeline?.searchQueryPerformance ? searchQueryPerformanceWithTimeline.searchQueryPerformance.length : 0
          }
          onDiscardClicked={clearSelections}
          onCreateNegativesParamsClicked={onCreateNegativesParamsClicked}
          searchQueryPerformanceTableGridApiRef={searchQueryPerformanceTableGridApiRef}
          onHarvestClicked={() => setIsKeywordHarvestingParamsModalOpen(true)}
        />
      </AlErrorBoundary> */}

      {navigationModal}
    </>
  );
};

export default SearchQueryPerformancePage;
