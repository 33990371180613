import { ContextKey } from '@/types/context-shared';
import CloseIcon from '@mui/icons-material/Close';
import { Button, DialogActions, FormControl, FormHelperText, IconButton, Tooltip } from '@mui/material';
import { cloneDeep, isNil } from 'lodash-es';
import { FunctionComponent, useRef, useState } from 'react';
import { PendingFiltersContext } from './context/PendingFiltersContext';
import SavedPresetsPanel from './filter-presets/SavedPresetsPanel';
import SaveFiltersButton from './filter-presets/SaveFiltersButton';
import SaveFiltersModal from './filter-presets/SaveFiltersModal';
import FilterBuilderTitle from './FilterBuilderTitle';
import FilterRowsWithControls from './FilterRowsWithControls';
import useFilterErrorHandling from './hooks/useFilterErrorHandling';
import { getFilterErrorCodes, splitFiltersToApplicableAndDiscarded } from './hooks/useFilters';
import usePresetSaving from './hooks/usePresetSaving';
import useSavedFilterPresets from './hooks/useSavedFilterPresets';
import { AlFilterModel, getUpdatedFiltersValue } from './models/AlFilterModel';
import { FilterPresetModel } from './models/FilterPresetModel';
import { SaveFlowType } from './types/SavedPresets';

interface FilterBuilderProps {
  onApply: (filters: AlFilterModel[], selectedPreset: FilterPresetModel | 'new' | null) => void;
  filterBuilderFiltersOnOpen: AlFilterModel[];
  availableFilters: AlFilterModel[];
  onCancel: () => void;
  onClose: () => void;
  selectedPreset: FilterPresetModel | 'new' | null;
  contextKey?: ContextKey;
  dialogOpen: boolean;
}

export const FilterBuilder: FunctionComponent<FilterBuilderProps> = ({
  onApply,
  filterBuilderFiltersOnOpen,
  availableFilters,
  onCancel,
  onClose,
  contextKey,
  selectedPreset: selectedPresetExternal,
  dialogOpen,
}) => {
  const [pendingFilters, setPendingFilters] = useState<AlFilterModel[]>(cloneDeep(filterBuilderFiltersOnOpen));
  const [pendingSelectedPreset, setPendingSelectedPreset] = useState<FilterPresetModel | 'new' | null>(selectedPresetExternal);
  const previousFilters = useRef<string>(JSON.stringify(pendingFilters));

  const showSavedFilterComponents = !!contextKey;

  const setPendingFilterValue = (filter: AlFilterModel) => {
    setPendingFilters((previousValue) => getUpdatedFiltersValue(previousValue, filter));
  };

  const onApplyFiltersClicked = () => {
    const newFilterToErrorRecord = getFilterErrorCodes(pendingFilters);

    if (Object.keys(newFilterToErrorRecord).length !== 0) {
      setFilterToErrorsRecord(newFilterToErrorRecord);
      return;
    }

    onApply(pendingFilters, pendingSelectedPreset);
    previousFilters.current = JSON.stringify(pendingFilters); // Update the last applied filters
  };

  // Error handling
  const { filterToErrorsRecord, setFilterToErrorsRecord, hasErrors, errorCount } = useFilterErrorHandling({ pendingFilters });

  // Saved filter presets
  const { selectedPreset, savedPresets, setSelectedPreset, refetchSavedPresets, isLoading, isError, isExistingPresetModified } =
    useSavedFilterPresets({
      filters: pendingFilters,
      setFilters: setPendingFilters,
      contextKey,
      pendingSelectedPreset,
      setPendingSelectedPreset,
      filtersSetOnNew: filterBuilderFiltersOnOpen,
    });

  const [isSavingModalOpen, setIsSavingModalOpen] = useState(false);
  const existingNames = savedPresets.map((preset) => preset.name);

  const { handleSave, isSavingLoading } = usePresetSaving({
    filters: pendingFilters,
    selectedPreset,
    setSelectedPreset,
    refetchSavedPresets,
    contextKey,
  });

  function updateFilters() {
    if (!isNil(selectedPreset) && selectedPreset != 'new') {
      handleSave(selectedPreset.name, selectedPreset.level, false);
    }
  }

  function updateAndApply() {
    updateFilters();
    onApplyFiltersClicked();
  }

  // Saving and updating
  const [saveFlowType, setSaveFlowType] = useState<SaveFlowType>(SaveFlowType.SAVE_AS_NEW);
  function onSaveAsNewClicked() {
    setIsSavingModalOpen(true);
    setSaveFlowType(SaveFlowType.SAVE_AS_NEW);
  }

  const [presetToEditId, setPresetToEditId] = useState<number | null>(null);
  function onStartUpdatingExistingPreset(presetId: number) {
    setIsSavingModalOpen(true);
    setSaveFlowType(SaveFlowType.UPDATE);
    setPresetToEditId(presetId);
  }

  function onCloseSaveModal() {
    setIsSavingModalOpen(false);
    setTimeout(() => {
      setPresetToEditId(null);
    }, 300);
  }

  const presetToEdit = (presetToEditId && savedPresets.find((p) => p.id === presetToEditId)) || selectedPreset;

  const { discardedFilters: notApplicableFilters } = splitFiltersToApplicableAndDiscarded(pendingFilters);
  const hasPendingFiltersWithoutValuesOrNoFilters = notApplicableFilters.length > 0 || pendingFilters.length === 0;

  return (
    <PendingFiltersContext.Provider value={{ filters: pendingFilters, setFilters: setPendingFilters, setFilterValue: setPendingFilterValue }}>
      {/* min h set to get scrolling in saved filters list */}
      <div className={`flex flex-row ${showSavedFilterComponents ? 'min-h-[40vh]' : 'min-h-[20vh]'} gap-2`}>
        {showSavedFilterComponents && (
          <>
            <SavedPresetsPanel
              setSelectedPreset={setSelectedPreset}
              presets={savedPresets}
              selectedPreset={selectedPreset}
              isLoading={isLoading}
              isError={isError}
              applicableFilterKeys={new Set(availableFilters.map((f) => f.key))}
              contextKey={contextKey}
              refetchSavedPresets={refetchSavedPresets}
              onStartUpdatingExistingPreset={onStartUpdatingExistingPreset}
              dialogOpen={dialogOpen}
            />
          </>
        )}
        <div className="flex w-full flex-col gap-2 p-4">
          <div className="flex flex-row gap-2">
            <FilterBuilderTitle
              selectedPreset={selectedPreset}
              existingNames={existingNames}
              handleSave={handleSave}
              isExistingPresetModified={isExistingPresetModified}
              showSavedFilterComponents={showSavedFilterComponents}
            />
            <div className="flex-grow" />

            {showSavedFilterComponents && (
              <SaveFiltersButton
                filters={pendingFilters}
                isExistingPresetModified={isExistingPresetModified}
                isExistingPresetSelected={selectedPreset !== 'new' && !isNil(selectedPreset)}
                onSaveAsNewClicked={onSaveAsNewClicked}
                updateFilters={updateFilters}
                hasPendingFiltersWithoutValuesOrNoFilters={hasPendingFiltersWithoutValuesOrNoFilters}
                notApplicableFilters={notApplicableFilters}
              />
            )}
            <div>
              <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          <FilterRowsWithControls
            pendingFilters={pendingFilters}
            setPendingFilters={setPendingFilters}
            setPendingSelectedPreset={setPendingSelectedPreset}
            onApplyFiltersClicked={onApplyFiltersClicked}
            previousFilters={previousFilters}
            availableFilters={availableFilters}
            filterToErrorsRecord={filterToErrorsRecord}
          />

          <DialogActions className="px-0">
            <div className="flex flex-col">
              <div className="flex flex-row justify-end gap-2">
                <Button variant="text" onClick={onCancel}>
                  Cancel
                </Button>
                {isExistingPresetModified && !hasPendingFiltersWithoutValuesOrNoFilters && (
                  <Button variant="outlined" onClick={updateAndApply}>
                    Update & Apply
                  </Button>
                )}
                <Button onClick={onApplyFiltersClicked}>
                  {pendingFilters.length === 0
                    ? 'Apply No Filters'
                    : pendingFilters.length === 1
                      ? 'Apply 1 Filter'
                      : `Apply ${pendingFilters.length} Filters`}
                </Button>
              </div>
              {hasErrors && (
                <FormControl className="m-0" fullWidth error={hasErrors}>
                  <Tooltip
                    title={
                      <ul className="m-0 pl-4">
                        {Object.keys(filterToErrorsRecord).map((filterKey) => {
                          const filter = pendingFilters.find((f) => f.key === filterKey);
                          return filter && <li key={filterKey}>{filter.longName || filter.shortName}</li>;
                        })}
                      </ul>
                    }
                  >
                    <FormHelperText className="mr-0">{`${errorCount} filter${errorCount === 1 ? '' : 's'} contain${errorCount === 1 ? 's' : ''} invalid values`}</FormHelperText>
                  </Tooltip>
                </FormControl>
              )}
            </div>
          </DialogActions>
        </div>
      </div>

      <SaveFiltersModal
        open={isSavingModalOpen}
        onClose={onCloseSaveModal}
        onSave={handleSave}
        existingNames={existingNames}
        isLoading={isSavingLoading}
        presetToEdit={presetToEdit}
        saveFlowType={saveFlowType}
      />
    </PendingFiltersContext.Provider>
  );
};
