import { EMPTY_METRIC_DATA, MetricData } from '@/components/metrics/types/MetricData';
import { MetricField, stringToSellerMetricFieldString, stringToVendorMetricFieldString } from '@/components/metrics/types/MetricField';
import { TimelineModel } from '@/modules/optimizer/components/timeline/models/TimelineModel';
import { PerformanceMetricsModel, ProfileMetricsArguments } from '@/modules/profiles-stats/models/PerformanceMetricsModel';
import {
  PerformanceTargetEstimate,
  ProfileCommonMetricsDTO,
  ProfileSellerMetricsDTO,
  ProfilesMetricsWithTeamDTO,
  ProfileVendorMetricsDTO,
} from '@/modules/profiles/api/profile.contracts';
import { ProfileModel } from '@/modules/profiles/types/ProfileModel';

export class ProfileWithMetricsModel extends ProfileModel {
  public teamId: number;
  public teamName: string;
  public metricsDTO: ProfileCommonMetricsDTO;
  public sellerMetricsDTO: ProfileSellerMetricsDTO;
  public vendorMetricsDTO: ProfileVendorMetricsDTO;
  public comparisonMissing: boolean;
  public performanceTargetEstimates: PerformanceMetricsModel;
  public timeline: TimelineModel;
  public lastOptimizedAt: string | null;

  constructor(args: ProfileMetricsArguments) {
    super(args);
    this.teamId = args.teamId;
    this.teamName = args.teamName;
    this.metricsDTO = args.metrics;
    this.sellerMetricsDTO = args.sellerMetrics;
    this.vendorMetricsDTO = args.vendorMetrics;
    this.comparisonMissing = args.comparisonMissing;

    this.performanceTargetEstimates = new PerformanceMetricsModel(args.performance, args.performanceLowData);

    const yAxisData: {
      key: MetricField;
      values: number[];
    }[] = [];

    for (const [key, values] of Object.entries(args.timeline.y_axis.metrics)) {
      yAxisData.push({ key: key as MetricField, values: values as number[] });
    }

    for (const [key, values] of Object.entries(args.timeline.y_axis.seller_metrics)) {
      yAxisData.push({ key: stringToSellerMetricFieldString(key) as MetricField, values: values as number[] });
    }

    for (const [key, values] of Object.entries(args.timeline.y_axis.vendor_metrics)) {
      yAxisData.push({ key: stringToVendorMetricFieldString(key) as MetricField, values: values as number[] });
    }

    this.timeline = new TimelineModel({
      xAxisData: args.timeline.x_axis,
      yAxisData: yAxisData,
    });

    this.lastOptimizedAt = args.lastOptimized;
  }

  public get impressions(): MetricData {
    return this.metricsDTO.impressions;
  }

  public get clicks(): MetricData {
    return this.metricsDTO.clicks;
  }

  public get orders(): MetricData {
    return this.metricsDTO.orders;
  }

  public get units(): MetricData {
    return this.metricsDTO.units;
  }

  public get ctr(): MetricData {
    return this.metricsDTO.ctr;
  }

  public get cvr(): MetricData {
    return this.metricsDTO.cvr;
  }

  public get cpc(): MetricData {
    return this.metricsDTO.cpc;
  }

  public get spend(): MetricData {
    return this.metricsDTO.spend;
  }

  public get sales(): MetricData {
    return this.metricsDTO.sales;
  }

  public get sso(): MetricData {
    return this.metricsDTO.sso;
  }

  public get sss(): MetricData {
    return this.metricsDTO.sss;
  }

  public get acos(): MetricData {
    return this.metricsDTO.acos;
  }

  public get actc(): MetricData {
    return this.metricsDTO.actc;
  }

  public get roas(): MetricData {
    return this.metricsDTO.roas;
  }

  public get rpc(): MetricData {
    return this.metricsDTO.rpc;
  }

  public get cpa(): MetricData {
    return this.metricsDTO.cpa;
  }

  public get aov(): MetricData {
    return this.metricsDTO.aov;
  }

  public get cpm(): MetricData {
    return this.metricsDTO.cpm;
  }

  // Seller and Vendor metrics
  public get acots(): MetricData {
    if (this.isVendor) return this.vendorMetricsDTO.acos;
    if (this.isSeller) return this.sellerMetricsDTO.acos;
    return EMPTY_METRIC_DATA;
  }

  public get adSalesOfTotal(): MetricData {
    if (this.isVendor) return this.vendorMetricsDTO.ad_sales_of_total;
    if (this.isSeller) return this.sellerMetricsDTO.ad_sales_of_total;
    return EMPTY_METRIC_DATA;
  }

  public get asp(): MetricData {
    if (this.isVendor) return this.vendorMetricsDTO.asp;
    if (this.isSeller) return this.sellerMetricsDTO.asp;
    return EMPTY_METRIC_DATA;
  }

  public get organicSales(): MetricData {
    if (this.isVendor) return this.vendorMetricsDTO.org_sales;
    if (this.isSeller) return this.sellerMetricsDTO.org_sales;
    return EMPTY_METRIC_DATA;
  }

  public get totalRoas(): MetricData {
    if (this.isVendor) return this.vendorMetricsDTO.roas;
    if (this.isSeller) return this.sellerMetricsDTO.roas;
    return EMPTY_METRIC_DATA;
  }

  public get totalSales(): MetricData {
    if (this.isVendor) return this.vendorMetricsDTO.ordered_revenue;
    if (this.isSeller) return this.sellerMetricsDTO.sales;
    return EMPTY_METRIC_DATA;
  }

  public get unitView(): MetricData {
    if (this.isVendor) return this.vendorMetricsDTO.unit_view;
    if (this.isSeller) return this.sellerMetricsDTO.unit_view;
    return EMPTY_METRIC_DATA;
  }

  public get totalUnits(): MetricData {
    if (this.isVendor) return this.vendorMetricsDTO.ordered_units;
    if (this.isSeller) return this.sellerMetricsDTO.units;
    return EMPTY_METRIC_DATA;
  }

  public get totalViews(): MetricData {
    if (this.isVendor) return this.vendorMetricsDTO.glance_views;
    if (this.isSeller) return this.sellerMetricsDTO.views;
    return EMPTY_METRIC_DATA;
  }

  public get unitsRefunded(): MetricData {
    if (this.isVendor) return this.vendorMetricsDTO.customer_returns;
    if (this.isSeller) return this.sellerMetricsDTO.units_refunded;
    return EMPTY_METRIC_DATA;
  }

  public get unitRefundRate(): MetricData {
    if (this.isVendor) return this.vendorMetricsDTO.return_rate;
    if (this.isSeller) return this.sellerMetricsDTO.units_refund_rate;
    return EMPTY_METRIC_DATA;
  }

  // Seller metrics
  public get totalActc(): MetricData {
    return this.sellerMetricsDTO.actc;
  }

  public get totalAov(): MetricData {
    return this.sellerMetricsDTO.aov;
  }

  public get totalClicks(): MetricData {
    return this.sellerMetricsDTO.clicks;
  }

  public get totalCpa(): MetricData {
    return this.sellerMetricsDTO.cpa;
  }

  public get totalCvr(): MetricData {
    return this.sellerMetricsDTO.cvr;
  }

  public get totalOrders(): MetricData {
    return this.sellerMetricsDTO.orders;
  }

  public get organicTraffic(): MetricData {
    return this.sellerMetricsDTO.org_traffic;
  }

  public get ups(): MetricData {
    return this.sellerMetricsDTO.unit_sess;
  }

  // Vendor metrics
  public get acogs(): MetricData {
    return this.vendorMetricsDTO.acogs;
  }

  public get shippedCogs(): MetricData {
    return this.vendorMetricsDTO.shipped_cogs;
  }

  public get shippedRevenue(): MetricData {
    return this.vendorMetricsDTO.shipped_revenue;
  }

  public get shippedUnits(): MetricData {
    return this.vendorMetricsDTO.shipped_units;
  }

  // Performance
  public get performanceAcos(): PerformanceTargetEstimate {
    return this.performanceTargetEstimates.acos;
  }

  public get performanceAcots(): PerformanceTargetEstimate {
    return this.performanceTargetEstimates.acots;
  }

  public get performanceAdSpend(): PerformanceTargetEstimate {
    return this.performanceTargetEstimates.adSpend;
  }

  public get performanceAdSales(): PerformanceTargetEstimate {
    return this.performanceTargetEstimates.adSales;
  }

  public get performanceTotalSales(): PerformanceTargetEstimate {
    return this.performanceTargetEstimates.totalSales;
  }

  public set performanceAcos(targetEstimates: PerformanceTargetEstimate) {
    this.performanceTargetEstimates.acos = targetEstimates;
  }

  public set performanceAcots(targetEstimates: PerformanceTargetEstimate) {
    this.performanceTargetEstimates.acots = targetEstimates;
  }

  public set performanceAdSpend(targetEstimates: PerformanceTargetEstimate) {
    this.performanceTargetEstimates.adSpend = targetEstimates;
  }

  public set performanceAdSales(targetEstimates: PerformanceTargetEstimate) {
    this.performanceTargetEstimates.adSales = targetEstimates;
  }

  public set performanceTotalSales(targetEstimates: PerformanceTargetEstimate) {
    this.performanceTargetEstimates.totalSales = targetEstimates;
  }

  public static fromUserProfilesWithMetricsResponse(dtos: ProfilesMetricsWithTeamDTO[]): ProfileWithMetricsModel[] {
    return dtos.map(
      (dto) =>
        new ProfileWithMetricsModel({
          ...ProfileModel.fromDTO(dto),
          adsDataStartDate: dto.ads_data_start_date, // as adsDataStartDate is a getter field then it is not returned with ...
          teamId: dto.team_id,
          teamName: dto.team_name,
          metrics: dto.metrics,
          sellerMetrics: dto.seller_metrics,
          vendorMetrics: dto.vendor_metrics,
          comparisonMissing: dto.comparison_missing,
          performanceLowData: dto.performance_low_data,
          performance: dto.performance,
          timeline: dto.timeline,
          lastOptimized: dto.last_optimized,
        }),
    );
  }
}
