import { AxiosStatic } from 'axios';
import { getAuth, getIdToken, onAuthStateChanged } from 'firebase/auth';
import { isEmpty } from 'lodash-es';

export const registerFirebaseInterceptor = (axios: AxiosStatic) => {
  axios.interceptors.request.use(async (request) => {
    const token = await getUserToken();

    if (!isEmpty(token)) {
      request.headers.Authorization = `Bearer ${token}`;
    }

    return request;
  });
};

const getUserToken = async () => {
  return new Promise((resolve) => {
    const unsub = onAuthStateChanged(getAuth(), async (user) => {
      if (user) {
        try {
          const token = await getIdToken(user);
          resolve(token);
        } catch (e) {
          console.error(e);
          resolve(null);
        }
      } else {
        resolve(null);
      }
      unsub();
    });
  });
};
