import { Chip, Tooltip } from '@mui/material';
import { FunctionComponent } from 'react';
import useFilterFormatting from './hooks/useFilterFormatting';
import { AlFilterModel } from './models/AlFilterModel';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

interface FilterChipProps {
  filter: AlFilterModel;
  handleClick: () => void;
  handleDelete: (filter: AlFilterModel) => void;
}

export const FilterChip: FunctionComponent<FilterChipProps> = ({ filter, handleClick, handleDelete }) => {
  const { getLabelForFilter } = useFilterFormatting();

  return (
    <Chip
      label={getLabelForFilter(filter)}
      size="medium"
      variant="outlined"
      onClick={handleClick}
      onDelete={() => handleDelete(filter)}
      deleteIcon={
        <Tooltip title="Remove this filter">
          <CancelRoundedIcon sx={{ height: 16, width: 16 }} />
        </Tooltip>
      }
    />
  );
};
