import { useMemo } from 'react';
import { SelectedCampaignDTO } from '../api/campaign/models/CampaignModel';
import { EnabledPausedArchivedState } from '../types/EnabledPausedArchivedState';

export interface CampaignDaypartingWarnings {
  warnings: string[];
  campaignCount: number;
  duplicateCount: number;
  notEnabledCount: number;
  endingSoonCount: number;
  vcpmCount: number;
  optRuleCount: number;
  filterValidItems: (items: SelectedCampaignDTO[]) => SelectedCampaignDTO[];
}

interface UseCampaignDaypartingAssignmentWarningsProps {
  selectedItems: SelectedCampaignDTO[];
}

const CAMPAIGN_END_SOON_DAYS = 7;

const useCampaignDaypartingAssignmentWarnings = ({
  selectedItems,
}: UseCampaignDaypartingAssignmentWarningsProps): CampaignDaypartingWarnings => {
  return useMemo(() => {
    const now = new Date();

    // Helper: Check if campaign is enabled.
    const isItemEnabled = (item: SelectedCampaignDTO): boolean => item.state === EnabledPausedArchivedState.ENABLED;

    // Helper: Check if campaign is ending soon.
    const isItemEndingSoon = (item: SelectedCampaignDTO, now: Date): boolean => {
      if (!item.endDate) return false;
      const end = new Date(item.endDate);
      const diffDays = (end.getTime() - now.getTime()) / (1000 * 3600 * 24);
      return diffDays < CAMPAIGN_END_SOON_DAYS;
    };

    // Helper: Check if campaign has cost type VCPM.
    const isItemVcpm = (item: SelectedCampaignDTO): boolean => item.costType === 'VCPM';

    // Helper: Check if campaign has an optimization rule preventing bid changes.
    const hasItemOptRule = (item: SelectedCampaignDTO): boolean => !!item.hasOptRule;

    // Helper: Check if an item is valid for assignment.
    const isItemValid = (item: SelectedCampaignDTO, now: Date): boolean =>
      isItemEnabled(item) && !isItemEndingSoon(item, now) && !isItemVcpm(item) && !hasItemOptRule(item);

    const campaignCount = selectedItems.length;

    // Duplicate check.
    const uniqueCampaignIds = new Set(selectedItems.map((item) => item.id));
    const duplicateCount = campaignCount - uniqueCampaignIds.size;

    // Build warnings.
    const warnings: string[] = [];
    if (duplicateCount > 0) {
      warnings.push(`${duplicateCount} campaign${duplicateCount > 1 ? 's' : ''} assigned more than once`);
    }

    const notEnabled = selectedItems.filter((item) => !isItemEnabled(item));
    const notEnabledCount = notEnabled.length;
    if (notEnabledCount > 0) {
      warnings.push(`${notEnabledCount} campaign${notEnabledCount > 1 ? 's' : ''} are not enabled`);
    }

    const endingSoon = selectedItems.filter((item) => isItemEndingSoon(item, now));
    const endingSoonCount = endingSoon.length;
    if (endingSoonCount > 0) {
      warnings.push(`${endingSoonCount} campaign${endingSoonCount > 1 ? 's' : ''} are ending within ${CAMPAIGN_END_SOON_DAYS} days`);
    }

    const vcpmCampaigns = selectedItems.filter((item) => isItemVcpm(item));
    const vcpmCount = vcpmCampaigns.length;
    if (vcpmCount > 0) {
      warnings.push(`${vcpmCount} campaign${vcpmCount > 1 ? 's' : ''} have cost type VCPM, which does not support bid changes`);
    }

    const optRuleCampaigns = selectedItems.filter((item) => hasItemOptRule(item));
    const optRuleCount = optRuleCampaigns.length;
    if (optRuleCount > 0) {
      warnings.push(`${optRuleCount} campaign${optRuleCount > 1 ? 's' : ''} have optimization rules preventing bid changes`);
    }

    // Return a function to filter valid items.
    const filterValidItems = (items: SelectedCampaignDTO[]): SelectedCampaignDTO[] => {
      const now = new Date();
      const validItems = items.filter((item) => isItemValid(item, now));
      const seenIds = new Set();
      return validItems.filter((item) => {
        if (seenIds.has(item.id)) {
          return false;
        }
        seenIds.add(item.id);
        return true;
      });
    };

    return {
      warnings,
      campaignCount,
      duplicateCount,
      notEnabledCount,
      endingSoonCount,
      vcpmCount,
      optRuleCount,
      filterValidItems,
    };
  }, [selectedItems]);
};

export default useCampaignDaypartingAssignmentWarnings;
