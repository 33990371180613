import { app } from '@/modules/auth';
import { planService } from '@/modules/plans/api/plan.service';
import { userService } from '@/modules/users';
import {
  MAX_FIRST_NAME_LENGTH,
  MAX_SURNAME_LENGTH,
  MIN_EMAIL_LENGTH,
  MIN_FIRST_NAME_LENGTH,
  MIN_PASSWORD_LENGTH,
  MIN_SURNAME_LENGTH,
} from '@/modules/users/constants';
import { APP_DEFAULT_PATH } from '@/router/router';
import { Routes } from '@/router/router-paths';
import { toastService } from '@/services/toast.service';
import { Alert, Button, TextField } from '@mui/material';
import { FirebaseError } from 'firebase/app';
import { AuthErrorCodes, getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, UserCredential } from 'firebase/auth';
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input';
import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import RegistrationWizardPage from '../components/RegistrationWizardPage';
import RegistrationWizardPageTitle from '../components/RegistrationWizardPageTitle';
import { useRegistrationContext } from '../contexts/registration/RegistrationContext';

const RegistrationStepCreateAccountPage: FunctionComponent = () => {
  const { setTeamId, setUser } = useRegistrationContext();
  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isGetStartedEnabled, setIsGetStartedEnabled] = useState(false);
  const navigate = useNavigate();
  const [resetPasswordButtonDisabled, setResetPasswordButtonDisabled] = useState(false);
  // const [showWaitListAlert, setShowWaitListAlert] = useState(false);
  const [authErrorCode, setAuthErrorCode] = useState<string | null>(null);

  // Coupon related state
  const [showCouponInput, setShowCouponInput] = useState(false);
  const [appliedCouponCodeDescription, setAppliedCouponCodeDescription] = useState('');
  const [appliedCouponCode, setAppliedCouponCode] = useState<string | null>(null);
  const [couponCode, setCouponCode] = useState('');
  const [couponError, setCouponError] = useState('');

  useEffect(() => {
    if (
      firstName.length >= MIN_FIRST_NAME_LENGTH &&
      firstName.length <= MAX_FIRST_NAME_LENGTH &&
      surname.length >= MIN_SURNAME_LENGTH &&
      surname.length <= MAX_SURNAME_LENGTH &&
      email.length >= MIN_EMAIL_LENGTH &&
      password.length >= MIN_PASSWORD_LENGTH &&
      matchIsValidTel(phoneNumber) &&
      !isSubmitting
    ) {
      setIsGetStartedEnabled(true);
    } else {
      setIsGetStartedEnabled(false);
    }
  }, [firstName, surname, email, password, phoneNumber, isSubmitting]);

  async function onGetStaredClicked() {
    setIsSubmitting(true);
    setIsError(false);
    setAuthErrorCode(null);
    // setShowWaitListAlert(false);

    try {
      // Try to log in and see if user exists
      let firebaseUser: UserCredential | null = null;
      try {
        const auth = getAuth(app);
        firebaseUser = await signInWithEmailAndPassword(auth, email, password);
      } catch (error) {
        console.error({ LOGIN_ERROR: error, IsFirebaseError: error instanceof FirebaseError });

        if (error instanceof FirebaseError) {
          switch (error.code) {
            case AuthErrorCodes.USER_DELETED:
              // User is not found (stupid firebase error code)
              setAuthErrorCode(AuthErrorCodes.USER_DELETED);
              break;
            case AuthErrorCodes.INVALID_PASSWORD:
              setAuthErrorCode(AuthErrorCodes.INVALID_PASSWORD);
              return;
            default:
              setAuthErrorCode(error.code);
              return;
          }
        } else {
          setAuthErrorCode(AuthErrorCodes.INTERNAL_ERROR);
          return;
        }
      }

      // If it is an existing user
      if (firebaseUser != null) {
        // Check if user has a team
        const adlabsUserResponse = await userService.getUserByFirebaseId(firebaseUser.user.uid);

        if (!adlabsUserResponse.isSuccess && adlabsUserResponse.httpResponseCode !== 404) {
          // If there was an error, but not a 404, display error message
          setIsError(true);
          setErrorMessage(adlabsUserResponse.message);
          return;
        }
        // If user has a team
        else if (adlabsUserResponse.isSuccess && adlabsUserResponse.payload.teams.length > 0) {
          const user = adlabsUserResponse.payload;
          const teams = user.teams;
          // Find team with at least one profile
          const teamWithAtLeastOneProfile = teams.find((t) => t.profiles.length > 0);

          // If user has a team with at least one profile, navigate to app
          if (teamWithAtLeastOneProfile) {
            // hard navigate to /app page
            window.location.href = APP_DEFAULT_PATH;
            navigate(APP_DEFAULT_PATH);
            window.location.reload();
            return;
          } else {
            // User already has a team, but no profiles, navigate to authorize amazon
            setTeamId(teams[0].id);
            const wizardUser = {
              email,
              userId: user.id,
              firebaseId: firebaseUser.user.uid,
              phone: user.phone,
            };
            setUser(wizardUser);
            navigate(Routes.REGISTER_ABOUT_YOU);
            return;
          }
        } else {
          // User has no teams, create a team and user
          // TODO: create team for existing user that doesn't have any teams yet?
          console.error(`User does not have a team ${JSON.stringify(adlabsUserResponse)}`);
        }
      }
      const result = await userService.createUserAndTeam({
        team_name: firstName + "'s Team",
        name: firstName,
        surname,
        phone: phoneNumber,
        email,
        password,
        coupon_code: appliedCouponCode,
      });

      if (result.isSuccess) {
        setTeamId(result.payload.team_id);
        const user = {
          email,
          userId: result.payload.user_id,
          firebaseId: result.payload.firebase_id,
          phone: phoneNumber,
        };
        setUser(user);
        await signInWithEmailAndPassword(getAuth(app), email, password);

        navigate(Routes.REGISTER_ABOUT_YOU);
        return;
      } else {
        setIsError(true);
        setErrorMessage(result.message);
      }
    } catch (error) {
      console.error(error);
      setIsError(true);
      setErrorMessage('Something went wrong. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  }

  async function onForgetPasswordClicked() {
    try {
      setResetPasswordButtonDisabled(true);
      await sendPasswordResetEmail(getAuth(app), email);
      toastService.success('Password reset email sent!');

      setTimeout(() => {
        setResetPasswordButtonDisabled(false);
      }, 5000);
    } catch (error) {
      console.error({ error });
      toastService.error('Error sending password reset email');
    }
  }

  async function handleApplyCoupon() {
    setCouponError('');
    try {
      const response = await planService.getPromotionCode(couponCode.trim());
      if (response.isSuccess) {
        setAppliedCouponCodeDescription(`${response.payload.promotion_code} (${response.payload.discount_description})`);
        setAppliedCouponCode(response.payload.promotion_code);
        // Optionally, display a success toast
        // toastService.success('Coupon applied successfully!');
      } else {
        setCouponError('Invalid or expired coupon code.');
      }
    } catch (_) {
      setCouponError('Error validating coupon. Please try again.');
    }
  }

  function removeAppliedCoupon() {
    setAppliedCouponCode(null);
    setAppliedCouponCodeDescription('');
  }

  return (
    <RegistrationWizardPage>
      <div className="flex flex-col items-center pt-10">
        <RegistrationWizardPageTitle>Join AdLabs Beta!</RegistrationWizardPageTitle>
        <div className="mt-10 w-96">
          <div className="flex flex-col gap-y-3">
            <div className="mt-4 text-center font-semibold text-slate-600">Your Personal Account Details</div>
            <TextField
              id="firstName"
              label="First Name"
              placeholder="First Name"
              value={firstName}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setFirstName(event.target.value)}
              error={firstName.length > MAX_FIRST_NAME_LENGTH}
              helperText={
                firstName.length < MIN_FIRST_NAME_LENGTH
                  ? `Enter at least ${MIN_FIRST_NAME_LENGTH} characters`
                  : firstName.length > MAX_FIRST_NAME_LENGTH && `First name cannot be longer than ${MAX_FIRST_NAME_LENGTH} characters`
              }
            />

            <TextField
              id="surname"
              label="Last Name"
              placeholder="Last Name"
              value={surname}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setSurname(event.target.value)}
              error={surname.length > MAX_SURNAME_LENGTH}
              helperText={
                surname.length < MIN_SURNAME_LENGTH
                  ? `Enter at least ${MIN_SURNAME_LENGTH} characters`
                  : surname.length > MAX_SURNAME_LENGTH && `Last name cannot be longer than ${MAX_SURNAME_LENGTH} characters`
              }
            />

            <MuiTelInput
              id="phone"
              label="Phone Number"
              forceCallingCode
              focusOnSelectCountry
              defaultCountry="US"
              value={phoneNumber}
              onChange={(value: string) => setPhoneNumber(value)}
              helperText={phoneNumber.length > 1 && !matchIsValidTel(phoneNumber) ? 'Invalid phone number' : ''}
            />

            <TextField
              id="email"
              label="Email"
              type="email"
              placeholder="Enter your Email"
              value={email}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
            />

            <TextField
              id="password"
              label="Password"
              type="password"
              placeholder="Enter your Password"
              value={password}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
              helperText={password.length < MIN_PASSWORD_LENGTH && `Enter at least ${MIN_PASSWORD_LENGTH} characters`}
            />

            {/* Coupon Code Section */}
            {!appliedCouponCode ? (
              <div className="flex flex-col mb-1">
                <div className="flex items-center">
                  {!showCouponInput && (
                    <Button variant="text" onClick={() => setShowCouponInput(!showCouponInput)}>
                      Have a coupon?
                    </Button>
                  )}
                  <div className="flex items-center">
                    {showCouponInput && (
                      <div className="flex items-end gap-2 mt-1">
                        <TextField
                          className="m-0"
                          id="coupon"
                          label="Enter coupon"
                          value={couponCode}
                          onChange={(e) => setCouponCode(e.target.value)}
                        />
                        <Button variant="contained" onClick={handleApplyCoupon} disabled={!couponCode?.trim()}>
                          Apply
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                {couponError && (
                  <div className="mt-2">
                    <Alert severity="error">{couponError}</Alert>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <Alert
                  severity="success"
                  className="mt-1 border border-green-500"
                  onClose={() => {
                    removeAppliedCoupon();
                  }}
                >
                  <span className="font-bold">{appliedCouponCodeDescription}</span>
                </Alert>
              </div>
            )}

            {authErrorCode && authErrorCode === AuthErrorCodes.INVALID_PASSWORD && (
              <Alert severity="error" className="mt-4">
                <div className="flex flex-col">
                  <div>
                    It seems you already have an account, but entered an invalid password. Try again, <br /> or reset it (we&rsquo;ll mail you
                    a reset link).
                  </div>
                  <div className="mt-2">
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={onForgetPasswordClicked}
                      disabled={resetPasswordButtonDisabled}
                      loading={resetPasswordButtonDisabled}
                    >
                      Reset password
                    </Button>
                  </div>
                </div>
              </Alert>
            )}
            {authErrorCode && authErrorCode !== AuthErrorCodes.INVALID_PASSWORD && authErrorCode !== AuthErrorCodes.USER_DELETED && (
              <Alert severity="error" className="mt-4">
                Something went wrong. Please try again later. The error code was: {authErrorCode}
              </Alert>
            )}

            {isError && (
              <Alert severity="error" className="mt-4">
                Something went wrong. The message was: {errorMessage}
              </Alert>
            )}

            <Button
              variant="contained"
              size="large"
              color="primary"
              className="mt-6"
              onClick={onGetStaredClicked}
              disabled={!isGetStartedEnabled}
              loading={isSubmitting}
            >
              Get Started
            </Button>
          </div>
        </div>
      </div>
    </RegistrationWizardPage>
  );
};

export default RegistrationStepCreateAccountPage;
