import { useLayoutContext } from '@/contexts/LayoutContext';
import useAlFetchCache from '@/modules/al-fetch-cache/useAlFetchCache';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { toastService } from '@/services/toast.service';
import { ContextKey } from '@/types/context-shared';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import { useState } from 'react';
import { CreateOrUpdateFilterDTO, SavedFilterLevel } from '../api/filters-contracts';
import { filtersService } from '../api/filters-service';
import { AlFilterModel } from '../models/AlFilterModel';
import { FilterPresetModel } from '../models/FilterPresetModel';

interface UsePresetSavingProps {
  filters: AlFilterModel[];
  selectedPreset: FilterPresetModel | 'new' | null;
  setSelectedPreset: (selectedPreset: FilterPresetModel | 'new') => void;
  refetchSavedPresets: (options?: RefetchOptions) => Promise<QueryObserverResult<FilterPresetModel[], Error>>;
  contextKey?: ContextKey;
}

const usePresetSaving = ({ filters, selectedPreset, setSelectedPreset, refetchSavedPresets, contextKey }: UsePresetSavingProps) => {
  const { startGlobalLoading, stopGlobalLoading } = useLayoutContext();
  const { activeTeam, activeProfile } = useActiveTeamContext();
  const { fetchCache } = useAlFetchCache();

  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async (name: string, level: SavedFilterLevel, createNew: boolean) => {
    if (!activeTeam) {
      toastService.error('No active team');
      return;
    }

    if (!activeTeam.organization.id) {
      toastService.error('No organization id');
      return;
    }

    if (!activeProfile?.id) {
      toastService.error('No profile id');
      return;
    }

    if (!contextKey) {
      toastService.error('No context key');
      return;
    }

    const isUpdatingExisting = !isNil(selectedPreset) && selectedPreset !== 'new' && !createNew;

    const loadingKey = 'saveFilterPreset' + (isUpdatingExisting ? selectedPreset.name : 'new');
    setIsLoading(true);

    startGlobalLoading(loadingKey);

    const dto: CreateOrUpdateFilterDTO = {
      id: isUpdatingExisting ? selectedPreset.id : undefined,
      name: name,
      org_id: activeTeam.organization.id,
      profile_id: activeProfile.id,
      filter: FilterPresetModel.encodeFilters(filters),
      page_id: contextKey,
      level: level,
    };

    const response = isUpdatingExisting
      ? await filtersService.updateSavedFilter(selectedPreset.id, dto, fetchCache)
      : await filtersService.saveFilters(dto, fetchCache);
    if (!response.isSuccess) {
      toastService.error(response.message);
      setIsLoading(false);
      stopGlobalLoading(loadingKey);
      return;
    }

    const newPreset = response.payload;

    if (isUpdatingExisting) {
      toastService.success(`'${name}' updated successfully`);
    } else {
      toastService.success(`'${name}' saved successfully`);
    }

    refetchSavedPresets();

    stopGlobalLoading(loadingKey);

    // Wait before setting isLoading to false to not show not disabled button while modal closing animation
    setTimeout(() => {
      setIsLoading(false);
      setSelectedPreset(newPreset);
    }, 200);
  };
  return { handleSave, isSavingLoading: isLoading };
};

export default usePresetSaving;
