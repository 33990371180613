import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';
import { assertUnhandledCase } from '@/modules/application/utils';
import { campaignService, createCampaignGroupsQueryKey } from '@/modules/optimizer/api/campaign/campaign-service';
import { AssignImportModel } from '@/modules/optimizer/api/campaign/models/AssignImportModel';
import { campaignGroupsToCampaignGroupNameToGroupMap } from '@/modules/optimizer/api/campaign/models/CampaignGroupModel';
import { CreateImportModel } from '@/modules/optimizer/api/campaign/models/CreateImportModel';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { toastService } from '@/services/toast.service';
import { useQuery } from '@tanstack/react-query';
import type { GridApi } from 'ag-grid-community';
import { isNil } from 'lodash-es';
import { FunctionComponent, useMemo, useState } from 'react';
import { CampaignMappingImportDetails, ImportStep, MAX_ERROR_WARNING_SAMPLES } from '../../types/feedback';
import { createImportDetails } from '../helpers';
import CampaignGroupsAssignPreviewTable from './assign/CampaignGroupsAssignPreviewTable';
import useAssignData from './assign/useAssignData';
import CampaignGroupsImportPreviewActionBar from './CampaignGroupsImportPreviewActionBar';
import CampaignGroupsCreatePreviewTable from './create/CampaignGroupsCreatePreviewTable';
import useCreateData from './create/useCreateData';
import { CampaignGroupsViewData } from './types';
import useApply from './useApply';

interface CampaignGroupsPreviewProps {
  uploadedFile: File;
  uploadedFileParsed: CampaignGroupsViewData;
  onCloseModal: () => void;
}

export const CampaignGroupsPreview: FunctionComponent<CampaignGroupsPreviewProps> = ({ uploadedFileParsed, onCloseModal }) => {
  // FLOW CONTROL
  const [importStep, setImportStep] = useState<ImportStep>(ImportStep.CREATE);

  function onGoToStep(step: ImportStep) {
    setImportStep(step);
    if (step === ImportStep.ASSIGN) {
      updateImportDetails(assignGridApiRef.current);
    } else if (step === ImportStep.CREATE) {
      updateImportDetails(createGridApiRef.current);
    } else {
      assertUnhandledCase(step);
    }
  }

  // EXTERNAL DATA SOURCES
  const { activeTeam, activeProfile } = useActiveTeamContext();

  const { data: campaignGroupsAll, isLoading: isCampaignGroupsLoading } = useQuery({
    queryKey: createCampaignGroupsQueryKey(activeProfile?.id),
    queryFn: async () => {
      const result = await campaignService.getGroups();
      if (result.isSuccess) {
        return result.payload;
      } else {
        toastService.error('Error loading groups');
      }
    },
    enabled: !isNil(activeTeam) && !isNil(activeProfile),
  });

  // TODO: check other places with createCampaignGroupsQueryKey to see that the output is always the same and no payload modification is done before return
  // Don't show "Unassigned" group in options
  const campaignGroups = campaignGroupsAll?.filter((group) => group.id !== 0);

  const groupNameToGroupMap = useMemo(() => campaignGroupsToCampaignGroupNameToGroupMap(campaignGroups), [campaignGroups]);

  // ACTION BAR DATA
  const [importDetails, setImportDetails] = useState<CampaignMappingImportDetails>({
    rowCount: 0,
    errorCount: 0,
    warningCount: 0,
    errorMessageSamples: [],
    warningSamples: [],
    firstErrorRowId: null,
    firstWarningRowId: null,
  });

  function updateImportDetails(api: GridApi<CreateImportModel> | GridApi<AssignImportModel> | null) {
    if (!api) return;

    let importDetailsLocal = importDetails;

    switch (importStep) {
      case ImportStep.CREATE:
        importDetailsLocal = createImportDetails<CreateImportModel>(api, MAX_ERROR_WARNING_SAMPLES);
        break;
      case ImportStep.ASSIGN:
        importDetailsLocal = createImportDetails<AssignImportModel>(api, MAX_ERROR_WARNING_SAMPLES);
        break;
      default:
        assertUnhandledCase(importStep);
    }

    importDetailsLocal.errorMessageSamples = [...new Set(importDetailsLocal.errorMessageSamples)]; // remove duplicates
    importDetailsLocal.warningSamples = [...new Set(importDetailsLocal.warningSamples)]; // remove duplicates

    setImportDetails(importDetailsLocal);
  }

  function onGridDataChanged(api: GridApi<CreateImportModel> | GridApi<AssignImportModel>) {
    updateImportDetails(api);
  }

  // CREATE VIEW
  const { rowDataCreate, onCreateGridReady, createGridApiRef } = useCreateData(
    uploadedFileParsed[ImportStep.CREATE],
    importDetails,
    updateImportDetails,
    groupNameToGroupMap,
  );

  // ASSIGN VIEW
  const { rowDataAssign, onAssignGridReady, assignGridApiRef, newGroupNameToCreateGroupMap } = useAssignData({
    uploadedFileAssignSheet: importStep === ImportStep.ASSIGN ? uploadedFileParsed[ImportStep.ASSIGN] : [], // Import assign when in assign step so info toasts show then
    importDetails,
    updateImportDetails,
    rowDataCreate, // for new group data - when assigning to new group
    campaignGroups,
    groupNameToGroupMap,
  });

  // APPLY
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const { onApplyClicked } = useApply({ setIsLoadingCreate, createGridApiRef, assignGridApiRef, onCloseModal });

  return (
    <>
      <AlErrorBoundary>
        <div className={importStep === ImportStep.CREATE ? 'block' : 'hidden'}>
          <CampaignGroupsCreatePreviewTable
            rowData={rowDataCreate ?? []}
            isLoading={isCampaignGroupsLoading}
            onGridDataChanged={onGridDataChanged}
            onGridReadyCallback={onCreateGridReady}
          />
        </div>

        <div className={importStep === ImportStep.ASSIGN ? 'block' : 'hidden'}>
          <CampaignGroupsAssignPreviewTable
            rowData={rowDataAssign ?? []}
            isLoading={isCampaignGroupsLoading}
            onGridDataChanged={onGridDataChanged}
            onGridReadyCallback={onAssignGridReady}
            groupNameToGroupMap={groupNameToGroupMap}
            newGroupNameToCreateGroupMap={newGroupNameToCreateGroupMap}
          />
        </div>
      </AlErrorBoundary>

      <AlErrorBoundary>
        <CampaignGroupsImportPreviewActionBar
          importDetails={importDetails}
          gridApiRef={createGridApiRef}
          onGoToStep={onGoToStep}
          currentStep={importStep}
          onApplyClicked={onApplyClicked}
          isLoadingCreate={isLoadingCreate}
        />
      </AlErrorBoundary>
    </>
  );
};
