import { FunctionComponent } from 'react';

import { Typography } from '@mui/material';
import { DaypartingScheduleModel } from '../types/DaypartingScheduleModel';
import LoadingBlock from '@/components/feedback/LoadingBlock';
import DaypartingScheduleForm from './dayparting-schedule-form/DaypartingScheduleForm';

interface DaypartingScheduleListProps {
  schedules: DaypartingScheduleModel[];
  isLoading: boolean;
}

const DaypartingScheduleList: FunctionComponent<DaypartingScheduleListProps> = ({ schedules, isLoading }) => {
  if (isLoading && (!schedules || schedules.length === 0)) {
    return (
      <div className="flex shrink-0 w-full items-center justify-center">
        <LoadingBlock />
      </div>
    );
  }

  if (!schedules || schedules.length === 0) {
    return <Typography>No schedules available.</Typography>;
  }

  return (
    <div className="flex flex-col gap-2 w-full">
      {schedules.map((schedule, index) => (
        <DaypartingScheduleForm
          key={`${schedule.id}${schedule.campaignIds.reduce((acc, campaignId) => acc + campaignId, '')}${index}`}
          schedule={schedule}
        />
      ))}
    </div>
  );
};

export default DaypartingScheduleList;
