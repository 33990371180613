import { FlowType } from '@/modules/log-viewing/api/logs-contracts';
import { Dialog } from '@mui/material';
import { useState } from 'react';
import { CampaignGroupMetricsModel } from '../api/campaign/models/CampaignGroupMetricsModel';
import { CreateUpdateCampaignGroup } from '../components/campaign-groups/campaign-groups-popover/CreateUpdateCampaignGroup';

export const useEditCampaignGroup = (existingGroups: CampaignGroupMetricsModel[]) => {
  const [isOpen, setIsOpen] = useState(false);
  const [campaignGroupModel, setCampaignGroupModel] = useState<CampaignGroupMetricsModel>();

  const handleOpenModal = (group: CampaignGroupMetricsModel) => {
    setCampaignGroupModel(group);
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onUpdateSuccess = () => {
    onClose();
  };

  const ModalComponent = (
    <>
      {campaignGroupModel && (
        <Dialog open={isOpen} onClose={onClose} maxWidth={'lg'}>
          <CreateUpdateCampaignGroup
            campaignGroup={campaignGroupModel}
            existingGroups={existingGroups}
            onClose={onClose}
            onSuccess={onUpdateSuccess}
            flowType={FlowType.OPTIMIZATION_GROUPS}
          />
        </Dialog>
      )}
    </>
  );

  return { ModalComponent, handleOpenModal };
};
