import { PayloadWithFiltersDTO } from '@/components/filter-builder/api/filters-contracts';
import { AlFilterModel, createQueryKeyFromFilters } from '@/components/filter-builder/models/AlFilterModel';
import { MetricModel } from '@/components/metrics/models/MetricModel';
import { ApiResponse } from '@/lib/api/api-response';
import { apiProfileClient } from '@/lib/api/base-client';
import { TimelineModel } from '@/modules/optimizer/components/timeline/models/TimelineModel';
import { SearchQueryPerformanceModel } from '../models/SearchQueryPerformanceModel';
import { SearchQueryPerformanceWithTimeline, SearchQueryPerformanceWithTimelineDTO } from './search-query-performance-contracts';

// Don't expose this and only use creators
const _SEARCH_QUERY_PERFORMANCE_WITH_TIMELINE_QUERY_KEY = 'search-query-performance-with-timeline';

// Main key to use
export function createSearchQueryPerformanceWithTimelineQueryKey(activeProfileId: string | undefined, filters: AlFilterModel[]) {
  const withTimelineQueryKey_withoutFilters = createSearchQueryPerformanceWithTimelineQueryKey_withoutFilters(activeProfileId);
  return [...withTimelineQueryKey_withoutFilters, createQueryKeyFromFilters(filters)];
}

// function invalidateAll_searchQueryPerformanceWithTimelineQueryKeys(queryClient: QueryClient) {
//   const key = [_SEARCH_QUERY_PERFORMANCE_WITH_TIMELINE_QUERY_KEY];
//   queryClient.invalidateQueries({
//     predicate: (query) => key.every((keyPart, index) => query.queryKey[index] === keyPart),
//   });
// }

function createSearchQueryPerformanceWithTimelineQueryKey_withoutFilters(activeProfileId: string | undefined) {
  return [_SEARCH_QUERY_PERFORMANCE_WITH_TIMELINE_QUERY_KEY, activeProfileId];
}

// function invalidateProfile_searchQueryPerformanceWithTimelineQueryKeys(queryClient: QueryClient, activeProfileId: string | undefined) {
//   const key = createSearchQueryPerformanceWithTimelineQueryKey_withoutFilters(activeProfileId);
//   queryClient.invalidateQueries({
//     predicate: (query) => key.every((keyPart, index) => query.queryKey[index] === keyPart),
//   });
// }

class SearchQueryPerformanceService {
  async getSearchQueryPerformanceWithTimeline(filters: AlFilterModel[]): Promise<ApiResponse<SearchQueryPerformanceWithTimeline>> {
    try {
      const filterData = filters.map((filter) => filter.toDTO());

      const requestData: PayloadWithFiltersDTO = {};
      if (filterData) {
        requestData.filters = filterData;
      }

      const applicationResponse = await apiProfileClient.post<SearchQueryPerformanceWithTimelineDTO>(
        `products/search-query-performance/with-timeline`,
        requestData,
      );

      return applicationResponse.processPayload((payload) => ({
        searchQueryPerformance: SearchQueryPerformanceModel.fromResponseArray(payload.search_query_performance),
        timeline: TimelineModel.fromResponse(payload.timeline),
        metrics: MetricModel.fromDTO(payload.metrics),
        isComparisonDataMissing: payload.comparison_missing,
      }));
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async addAsinsToSQP(asins: string[]) {
    const payload = {
      enable_asins: asins,
      disable_asins: [],
    };

    return await apiProfileClient.post<void>(`products/asin-toggle-sqp`, payload);
  }

  async removeAsinsFromSQP(asins: string[]) {
    const payload = {
      enable_asins: [],
      disable_asins: asins,
    };

    return await apiProfileClient.post<void>(`products/asin-toggle-sqp`, payload);
  }
}

export const searchQueryPerformanceService = new SearchQueryPerformanceService();
