import { FunctionComponent, PropsWithChildren, useContext } from 'react';
import { useDashboardContextValue } from '../../contexts/DashboardContextProvider';
import { IDashboardWidgetBaseConfiguration } from '../../types/IDashboardWidgetBaseConfiguration';
import { DashboardWidgetContext } from './DashboardWidgetContextProvider';
import EditableText from '@/components/form/EditableText';

const DashboardWidgetTitle: FunctionComponent<PropsWithChildren<{ configuration: IDashboardWidgetBaseConfiguration }>> = ({
  configuration,
}) => {
  const { widgetId } = useContext(DashboardWidgetContext);
  const { isExternal, updateWidgetConfiguration } = useDashboardContextValue((context) => ({
    isExternal: context.isExternal,
    updateWidgetConfiguration: context.updateWidgetConfiguration,
  }));
  const handleTitleChange = async (newTitle: string) => {
    if (!widgetId) {
      return;
    }
    configuration.title = newTitle;
    await updateWidgetConfiguration(widgetId, configuration, false);
  };

  return (
    <div className="text-sm pt-2 min-h-[30px] px-2 font-bold text-primary-950 opacity-90 flex">
      {isExternal ? (
        configuration.title
      ) : (
        <EditableText
          text={configuration.title}
          onTextChange={handleTitleChange}
          className=" min-w-20 normal-case underline underline-offset-2  decoration-dotted decoration-transparent hover:decoration-gray-600 focus:decoration-gray-600 transition-colors"
        />
      )}
    </div>
  );
};

export default DashboardWidgetTitle;
