import OppositeModeOverlayBar from '@/components/OppositeModeOverlayBar';
import useFormatting from '@/hooks/useFormatting';
import { Button, Checkbox, Divider, Tooltip } from '@mui/material';
import { FunctionComponent, useRef, useState, Dispatch, SetStateAction } from 'react';
import { SelectedProfileDTO } from '../models/SelectedProfileDTO';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { isNil } from 'lodash-es';
import { toastService } from '@/services/toast.service';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { PaywallModal } from '@/modules/plans/components/PaywallModal';
import { Routes } from '@/router/router-paths';
import { VideoUrl } from '@/config/urls';
import { DataGroupsPopover } from '@/modules/data-groups/components/DataGroupsPopover';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';

interface ProfileStatsSelectionActionsBarProps {
  selectedProfiles: SelectedProfileDTO[];
  setSelectedProfiles: Dispatch<SetStateAction<SelectedProfileDTO[]>>;
  totalProfilesCount: number;
  onDiscardClicked: () => void;
}

const ProfileStatsSelectionActionsBar: FunctionComponent<ProfileStatsSelectionActionsBarProps> = ({
  selectedProfiles,
  setSelectedProfiles,
  totalProfilesCount,
  onDiscardClicked,
}) => {
  const { activeTeam } = useActiveTeamContext();
  const { formatWithThousandsSeparator } = useFormatting();

  // Paywall
  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const onClosePaywallModal = () => {
    setIsPaywallModalOpen(false);
  };

  // Tags
  const dataGroupsButtonRef = useRef<HTMLButtonElement | null>(null);
  const [isDataGroupsPopoverOpen, setIsDataGroupsPopoverOpen] = useState(false);
  const onDataGroupsClicked = () => {
    if (isNil(activeTeam)) {
      toastService.error('Active team is not set');
      return;
    }
    if (!activeTeam.subscriptionPlan.canAssignDataGroups) {
      setIsPaywallModalOpen(true);
      return;
    }

    setIsDataGroupsPopoverOpen(true);
  };
  return (
    <>
      <OppositeModeOverlayBar visible={selectedProfiles.length > 0}>
        <div className="flex flex-row gap-4 items-center px-1.5">
          <Tooltip title="Deselect all">
            <div onClick={onDiscardClicked} className="flex flex-row text-sm font-bold items-center hover:cursor-pointer min-w-28">
              <Checkbox checked={true} name="deselectCheckbox" color="secondary" />
              {formatWithThousandsSeparator(selectedProfiles.length)} of {formatWithThousandsSeparator(totalProfilesCount)}
            </div>
          </Tooltip>

          <Divider className="my-2" orientation="vertical" flexItem />

          <div className="flex flex-row gap-2 items-center">
            <Button
              ref={dataGroupsButtonRef}
              size="small"
              className="whitespace-nowrap"
              onClick={onDataGroupsClicked}
              variant={isDataGroupsPopoverOpen ? 'contained' : 'outlined'}
              startIcon={<AccountTreeIcon />}
            >
              Tags
            </Button>
          </div>
        </div>
      </OppositeModeOverlayBar>

      <DataGroupsPopover
        buttonRef={dataGroupsButtonRef}
        isOpen={isDataGroupsPopoverOpen}
        setIsOpen={setIsDataGroupsPopoverOpen}
        selectedItems={selectedProfiles}
        dataGroupType={DataGroupType.PROFILE}
      />

      <PaywallModal
        isOpen={isPaywallModalOpen}
        onClose={onClosePaywallModal}
        returnURLPath={Routes.OPTIMIZER}
        headerText="Upgrade to Pro to Access Tags"
        videoUrl={VideoUrl.DATA_GROUPS}
      ></PaywallModal>
    </>
  );
};

export default ProfileStatsSelectionActionsBar;
