export function isOnExternalLocation(windowLocation: Location): boolean {
  let isExternalLink = windowLocation.hostname === 'analytics.adlabs.app' || windowLocation.hostname === 'analytics.localhost';
  if (!isExternalLink) {
    const path = windowLocation.pathname;
    if (path.startsWith('/external')) {
      isExternalLink = true;
    }
  }
  return isExternalLink;
}
