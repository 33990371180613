import { ContextKey } from '@/types/context-shared';
import { OperatorType } from '../models/AlFilterModel';
import { FilterKey } from '../types/FilterKey';

export interface FilterConditionDTO {
  values: number[] | string[];
  operator: OperatorType;
}

export interface FilterDTO {
  key: FilterKey;
  logical_operator?: string;
  conditions: Array<FilterConditionDTO>;
}

export interface PayloadWithFiltersDTO {
  filters?: FilterDTO[];
}

// SAVED FILTERS
export enum SavedFilterLevel {
  PERSONAL = 'PERSONAL',
  TEAM = 'TEAM',
  ORG = 'ORG',
}

export interface SavedFilterDTO {
  id: number;
  name: string;
  creator_id: number;
  team_id: number;
  org_id: number;
  profile_id: string;
  filter: string; // JSON
  page_id: ContextKey;
  level: SavedFilterLevel;
}

export interface CreateOrUpdateFilterDTO {
  id?: number;
  name: string;
  org_id: number;
  profile_id: string;
  filter: string; // JSON
  page_id: ContextKey;
  level: SavedFilterLevel;
}

export interface SavedFiltersJson {
  filters: FilterDTO[];
}
