import { Environment } from '@/config/Environment';
import { initAgGrid } from './ag-grid';
import { initApiClient } from './api';
import { initGleap } from './gleap';
import { initReactMUI } from './react-mui';
import { initSentry } from './sentry';

export { useTranslation } from './i18n/useTranslate';

export function initLibs(isExternal: boolean) {
  initApiClient(isExternal);
  initAgGrid();
  if (!isExternal && (Environment.isAdlabsDev() || Environment.isAdLabsProd())) {
    initGleap();
  }
  initSentry();
  initReactMUI();
}
