import { ColumnId } from '@/components/grid/columns/columns.enum';
import type { ColumnState } from 'ag-grid-enterprise';

export function generateDataGroupDefaultColumnState(): ColumnState[] {
  return [
    { colId: ColumnId.TYPE, hide: false, pinned: false },
    { colId: ColumnId.NAME, hide: false, pinned: false },
    { colId: ColumnId.DATA_ITEMS, hide: false, pinned: false },
    { colId: ColumnId.PROFILES, hide: false, pinned: false },
    { colId: ColumnId.ACTIONS, hide: false, pinned: false },
  ];
}
