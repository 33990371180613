import InfoIconWithTooltip from '@/components/feedback/InfoIconWithTooltip';
import FilterControls from '@/components/filter-builder/FilterControls';
import { FilterKey } from '@/components/filter-builder/types/FilterKey';
import GridTools from '@/components/grid/components/GridTools';
import { GridToggles } from '@/components/grid/types';
import { useTranslation } from '@/lib';
import useAlFetchCache from '@/modules/al-fetch-cache/useAlFetchCache';
import { PageLayoutBody } from '@/modules/application';
import DateRangeButton from '@/modules/application/components/date-range-picker/DateRangeButton';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { ContextKey } from '@/types/context-shared';
import WestRoundedIcon from '@mui/icons-material/WestRounded';
import { Button, Dialog } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import type { GridApi } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import { FunctionComponent, useMemo, useRef, useState } from 'react';
import { AlFilterModel, createOptimizerFilters } from '../../../../components/filter-builder/models/AlFilterModel';
import { IdNameDTO } from '../../api/campaign/campaign-contracts';
import { campaignService, createCampaignGroupsQueryKey } from '../../api/campaign/campaign-service';
import { CampaignGroupModel, emptyUnAssignedCampaignGroup } from '../../api/campaign/models/CampaignGroupModel';
import { SelectedCampaignDTO } from '../../api/campaign/models/CampaignModel';
import OptimizerPreviewActionsBar from '../OptimizerPreviewActionsBar';
import OptimizationTable from './OptimizationTable';
import { OptimizationApplyData } from './OptimizerConfig';
import { OptimizationModel } from './models/OptimizationModel';
import { PreviewDataRow } from './models/PreviewDataRow';

interface OptimizationsPreviewModalProps {
  optimizationResults: OptimizationModel;
  isOpen: boolean;
  onClose: () => void;
  onApplyOptimization: () => void;
  isLoadingApplyOptimization: boolean;
  optimizationPreviewSelection: OptimizationApplyData[];
  setOptimizationPreviewSelection: (optimizationApplyData: OptimizationApplyData[]) => void;
  selectedCampaigns: SelectedCampaignDTO[];
  filtersUsedForOptimizing: AlFilterModel[];
  onPreviewOptimizationsClicked: (filtersModified: AlFilterModel[]) => void;
  note: string;
  setNote: (note: string) => void;
}

export const OptimizationsPreviewModal: FunctionComponent<OptimizationsPreviewModalProps> = ({
  optimizationResults,
  isOpen,
  onClose,
  onApplyOptimization: onApplyChanges,
  isLoadingApplyOptimization: isApplyLoading,
  optimizationPreviewSelection,
  setOptimizationPreviewSelection,
  selectedCampaigns,
  filtersUsedForOptimizing,
  onPreviewOptimizationsClicked,
  note,
  setNote,
}) => {
  const { activeTeam, activeProfile } = useActiveTeamContext();
  const gridApiRef = useRef<GridApi<PreviewDataRow> | null>(null);
  const { fetchCache } = useAlFetchCache();
  const { t } = useTranslation();

  const onApply = () => {
    onApplyChanges();
    closeAndClearFilters();
  };

  const closeAndClearFilters = () => {
    onClose();
    setFilters([]);
  };

  // FILTERS
  const [filters, setFilters] = useState<AlFilterModel[]>([]);
  const [visibleRowCount, setVisibleRowCount] = useState<number>(0);

  // Get unique ad group names
  const uniqueAdGroupNames = Array.from(
    new Set(optimizationResults.previewData.map((optimizationResult) => optimizationResult.adGroup).filter((adGroup) => adGroup !== '')),
  ).sort();

  const adGroups: IdNameDTO[] = uniqueAdGroupNames.map((adGroupName) => ({ id: adGroupName, name: adGroupName }));

  const { data: campaignGroups } = useQuery({
    queryKey: createCampaignGroupsQueryKey(activeProfile?.id),
    queryFn: async () => {
      const result = await campaignService.getGroups();
      if (result.isSuccess) {
        return result.payload;
      } else {
        throw new Error('Error loading groups');
      }
    },
    select: (campaignGroups): CampaignGroupModel[] => {
      if (!campaignGroups.find((group) => group.id === 0)) {
        campaignGroups.unshift(emptyUnAssignedCampaignGroup);
      }

      return campaignGroups;
    },
    enabled: !isNil(activeTeam) && !isNil(activeProfile),
  });

  // TODO: take from preview data like adGroups and portfolios
  const campaignIdsWithNames = useMemo(() => {
    return selectedCampaigns.map((campaign) => {
      return { id: campaign.id, name: campaign.name } as IdNameDTO;
    });
  }, [selectedCampaigns]);

  // Get unique portfolios
  const portfolioMap = optimizationResults.previewData.reduce(
    (acc, optimizationResult) => {
      if (optimizationResult.portfolioId !== null && optimizationResult.portfolioName !== null) {
        acc[optimizationResult.portfolioId] = optimizationResult.portfolioName;
      }
      return acc;
    },
    {} as Record<string, string>,
  );

  const portfolios: IdNameDTO[] = Object.entries(portfolioMap)
    .map(([id, name]) => ({ id, name }))
    .sort((a, b) => a.name.localeCompare(b.name));

  const OPTIMIZER_FILTERS = createOptimizerFilters(adGroups, fetchCache, campaignGroups || [], campaignIdsWithNames || [], portfolios || []);

  function onOptimizationTableGridReady(api: GridApi<PreviewDataRow>) {
    gridApiRef.current = api;
  }

  // Grid toggles
  const [gridToggles, setGridToggles] = useState<GridToggles>({
    comparisonUnit: 'nominal',
    // sortByUnit: SortByUnit.Count, // TODO: add and use DEFAULT_GRID_TOGGLES
    // formatterType: FormatterType.LONG,  // TODO: add and use DEFAULT_GRID_TOGGLES
  });

  // Updating modal with new dates
  function setFilterValues(filters: AlFilterModel[]) {
    onPreviewOptimizationsClicked(filters);
  }

  return (
    <Dialog fullScreen open={isOpen} onClose={closeAndClearFilters} fullWidth={true}>
      <PageLayoutBody suppressBottomPadding>
        <div className="flex w-full flex-row justify-between mt-4 mb-2">
          <div className="flex gap-x-4">
            <Button className="flex-shrink-0" onClick={closeAndClearFilters} variant="text" startIcon={<WestRoundedIcon />}>
              Back to Campaigns
            </Button>
            <FilterControls
              contextKey={ContextKey.OPTIMIZATION_PREVIEW}
              filters={filters}
              setFilters={setFilters}
              availableFilters={OPTIMIZER_FILTERS}
              defaultFilterKey={FilterKey.CAMPAIGN_ID}
            />
          </div>
          <div className="flex text-sm gap-3 items-center ">
            <InfoIconWithTooltip text={t('totals_are_based_on_the_values_currently_shown_in_this_table')} />
            <DateRangeButton
              title="Optimization Range"
              filters={filtersUsedForOptimizing}
              setFilterValues={setFilterValues}
              tooltip="Date range used to calculate bid optimizations"
              hideComparison
            />
          </div>
        </div>
        <div className="flex w-full relative overflow-visible -mb-[3px]">
          <GridTools gridToggles={gridToggles} setGridToggles={setGridToggles} gridApiRef={gridApiRef} />
        </div>
        <OptimizationTable
          externalFilters={filters}
          optimizationResults={optimizationResults}
          setOptimizationPreviewSelection={setOptimizationPreviewSelection}
          setVisibleRowCount={setVisibleRowCount}
          onInternalGridReady={onOptimizationTableGridReady}
          gridToggles={gridToggles}
        />

        <OptimizerPreviewActionsBar
          optimizationPreviewSelection={optimizationPreviewSelection}
          visibleRowCount={visibleRowCount}
          onApply={onApply}
          isApplyLoading={isApplyLoading}
          optimizationResults={optimizationResults}
          optimizationTableApiRef={gridApiRef}
          setOptimizationPreviewSelection={setOptimizationPreviewSelection}
          note={note}
          setNote={setNote}
        />
      </PageLayoutBody>
    </Dialog>
  );
};
