import { ImportType } from '@/modules/data-management/api/importer.contracts';
import { CampaignGroupsImportModal } from '@/modules/data-management/importers/campaign-groups/CampaignGroupsImportModal';
import { ImportDefinitionFactory } from '@/modules/data-management/types/import-factory';
import { ArrowDropDown } from '@mui/icons-material';
import { Button, ButtonGroup, Menu, MenuItem } from '@mui/material';
import { FunctionComponent, MouseEvent, useState } from 'react';

const CampaignGroupsBulkImportButton: FunctionComponent = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const onImportClicked = () => {
    setIsModalOpen(true);
  };

  const onDownloadTemplateClicked = () => {
    handleClose();
    const importer = ImportDefinitionFactory.create(ImportType.CAMPAIGN_GROUPS);
    window.open(importer.templateFileUrl, '_self');
  };

  return (
    <>
      <ButtonGroup>
        <Button variant="outlined" onClick={onImportClicked}>
          Bulk Upload & Assign
        </Button>

        <Button variant="outlined" onClick={handleClick}>
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={onDownloadTemplateClicked}>Download Template</MenuItem>
      </Menu>

      {isModalOpen && <CampaignGroupsImportModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />}
    </>
  );
};

export default CampaignGroupsBulkImportButton;
