import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import {
  ColumnCategoryID,
  ColumnConfiguration,
  ColumnConfigurationCategory,
  ColumnConfigurationGroup,
  ColumnGroupID,
  organizeColumnsByCategory,
} from './column-categories';
import ColumnSelectionList from './ColumnSelectionList';
import MetricCategoryList from './MetricCategoryList';
import { SortableColumnList } from './SortableColumnList';

interface ColumnConfigurationModalProps {
  open: boolean;
  onClose: () => void;
  selectedColumns: ColumnConfiguration[];
  onSelectedColumnsChange: (columns: ColumnConfiguration[]) => void;
}

const ColumnConfigurationModal: FunctionComponent<ColumnConfigurationModalProps> = ({
  open,
  onClose,
  onSelectedColumnsChange,
  selectedColumns,
}) => {
  const [localSelectedColumns, setLocalSelectedColumns] = useState<ColumnConfiguration[]>(selectedColumns);
  const [selectedCategory, setSelectedCategory] = useState<ColumnCategoryID | ColumnGroupID>(ColumnCategoryID.ALL);
  const [selectedColumnGroupID, setSelectedColumnGroupID] = useState<ColumnGroupID>(ColumnGroupID.ALL);
  const [categoryGroups, setCategoryGroups] = useState<ColumnConfigurationCategory[]>(organizeColumnsByCategory(selectedColumns));
  const [columnGroups, setColumnGroups] = useState<ColumnConfigurationGroup[]>(
    organizeColumnsByCategory(selectedColumns).find((category) => category.id === ColumnCategoryID.ALL)?.columnGroups || [],
  );

  useEffect(() => {
    if (open) {
      setLocalSelectedColumns(selectedColumns);
      setCategoryGroups(organizeColumnsByCategory(selectedColumns));

      if (selectedCategory === ColumnCategoryID.ALL) {
        setSelectedColumnGroupID(ColumnGroupID.ALL);
        setColumnGroups(
          organizeColumnsByCategory(selectedColumns).find((category) => category.id === ColumnCategoryID.ALL)?.columnGroups || [],
        );
      } else {
        const category = categoryGroups.find((category) => category.id === selectedCategory);
        if (category) {
          setSelectedColumnGroupID(selectedColumnGroupID);
          setColumnGroups(category.columnGroups);

          if (!category.columnGroups.some((group) => group.id === selectedColumnGroupID)) {
            setSelectedColumnGroupID(category.columnGroups[0].id);
            setColumnGroups(category.columnGroups);
          }
        }
      }
    }
  }, [open, selectedColumns]);

  const handleCategorySelect = (
    category: ColumnConfigurationCategory,
    columnGroupID: ColumnGroupID,
    columnGroups: ColumnConfigurationGroup[],
  ) => {
    setSelectedCategory(category.id);
    if (category.id === ColumnCategoryID.ALL) {
      setSelectedColumnGroupID(ColumnGroupID.ALL);
      setColumnGroups(columnGroups);
    } else {
      setSelectedColumnGroupID(columnGroupID);
      setColumnGroups(columnGroups.filter((group) => group.id === columnGroupID));
    }
  };

  const handleSave = () => {
    onSelectedColumnsChange(localSelectedColumns);
    onClose();
  };

  const handleRemoveAll = () => {
    setLocalSelectedColumns(localSelectedColumns.map((column) => (column.lockVisible ? column : { ...column, isSelected: false })));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" className="min-h-[calc(80vh)]">
      <DialogTitle className="py-2 pl-3">
        <div className="flex flex-row justify-between">
          <div>Customize Columns</div>

          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className="p-0 flex flex-1  min-h-[calc(80vh-55px)]">
        <div className="flex w-full  flex-1 border-t border-b border-gray-200 ">
          {/* Left Panel: Metric Categories */}
          <div className="w-1/5 max-w-56 border-r border-r-gray-200 py-4 overflow-y-auto">
            <MetricCategoryList
              categoryGroups={categoryGroups}
              selectedColumnGroupID={selectedColumnGroupID}
              onCategorySelect={handleCategorySelect}
            />
          </div>

          {/* Middle Panel: Available Columns */}
          <div className="w-2/5 pt-2 border-r border-r-gray-200 flex flex-col flex-1">
            <ColumnSelectionList
              columnGroups={columnGroups}
              localSelectedColumns={localSelectedColumns}
              setLocalSelectedColumns={setLocalSelectedColumns}
            />
          </div>

          {/* Right Panel: Selected Columns */}
          <div className="w-2/5 pt-2 flex-1 flex flex-col">
            <div className="flex flex-row items-center justify-between px-3 mb-1">
              <Typography>
                Selected
                {localSelectedColumns.length > 0 && (
                  <span className="text-xs text-gray-500"> ({localSelectedColumns.filter((col) => col.isSelected).length})</span>
                )}
              </Typography>
              <div
                className="flex items-center justify-end px-4 py-1  text-xs text-primary-500 cursor-pointer hover:underline"
                onClick={handleRemoveAll}
              >
                <span>Remove All</span>
              </div>
            </div>

            <div className="flex flex-col flex-1 overflow-y-auto border-t border-t-gray-200" style={{ minHeight: '46px' }}>
              <SortableColumnList columns={localSelectedColumns} onReorder={(updatedColumns) => setLocalSelectedColumns(updatedColumns)} />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className=" mr-2">
        <Button className="min-w-28" onClick={onClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button className="min-w-28" onClick={handleSave} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ColumnConfigurationModal;
