import { useLayoutContext } from '@/contexts/LayoutContext';
import useAlFetchCache from '@/modules/al-fetch-cache/useAlFetchCache';
import { generateCopyName } from '@/modules/application/utils';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useUserContext } from '@/modules/users';
import { toastService } from '@/services/toast.service';
import { ContextKey } from '@/types/context-shared';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {
  CardContent,
  CircularProgress,
  Divider,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { isEmpty, isNil } from 'lodash-es';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CreateOrUpdateFilterDTO, SavedFilterLevel } from '../api/filters-contracts';
import { filtersService } from '../api/filters-service';
import { FilterPresetModel } from '../models/FilterPresetModel';
import { FilterKey } from '../types/FilterKey';
import { ALL_PRESETS, SavedFilterLevelType } from '../types/SavedPresets';
import { splitAndWrapPresets } from './helpers';
import { SavedPresetsListGroup } from './SavedPresetsListGroup';

interface SavedPresetsPanelProps {
  setSelectedPreset: (selectedPreset: FilterPresetModel | 'new') => void;
  selectedPreset: FilterPresetModel | 'new' | null;
  presets: FilterPresetModel[];
  isLoading: boolean;
  isError: boolean;
  applicableFilterKeys: Set<FilterKey>;
  contextKey?: ContextKey;
  refetchSavedPresets: (options?: RefetchOptions) => Promise<QueryObserverResult<FilterPresetModel[], Error>>;
  onStartUpdatingExistingPreset: (presetId: number) => void;
  dialogOpen: boolean;
}

const SavedPresetsPanel: React.FunctionComponent<SavedPresetsPanelProps> = ({
  setSelectedPreset,
  selectedPreset,
  presets,
  isLoading,
  isError,
  applicableFilterKeys,
  contextKey,
  refetchSavedPresets,
  onStartUpdatingExistingPreset,
  dialogOpen,
}) => {
  const { fetchCache } = useAlFetchCache();
  const { activeTeam, activeProfile } = useActiveTeamContext();
  const { startGlobalLoading, stopGlobalLoading } = useLayoutContext();
  const { getProfileIdToProfileMap } = useUserContext();

  const [searchText, setSearchText] = useState('');
  const [selectedLevelInternal, setSelectedLevel] = useState<SavedFilterLevelType>(ALL_PRESETS);
  const selectedLevel = !isEmpty(searchText) ? ALL_PRESETS : selectedLevelInternal;

  const handleLevelChange = (event: React.MouseEvent<HTMLElement>, newLevel: SavedFilterLevelType) => {
    if (newLevel === null) return;

    setSelectedLevel(newLevel);
  };

  const isNewPresetSelected = selectedPreset === 'new';

  const selectedPresetId: number | 'new' | null = (() => {
    if (!isEmpty(searchText)) {
      return null;
    }

    if (selectedPreset === 'new') {
      return 'new';
    }

    if (selectedPreset) {
      return selectedPreset.id;
    }

    return null;
  })();

  // Switch to corresponding level filter
  useEffect(() => {
    if (selectedPreset === 'new' || isNil(selectedPreset)) return;
    if (selectedLevel === ALL_PRESETS) return;

    if (selectedLevel !== selectedPreset.level) {
      setSelectedLevel(selectedPreset.level);
    }
  }, [selectedPreset]);

  const { currentProfilePresets, generalPresets, notApplicablePresets } = useMemo(
    () => splitAndWrapPresets(presets, applicableFilterKeys, activeProfile, selectedLevel, getProfileIdToProfileMap(), searchText),
    [presets, applicableFilterKeys, activeProfile, selectedLevel, searchText],
  );

  const handleSelect = (preset: FilterPresetModel) => {
    setSelectedPreset(preset);
    setSearchText(''); // Clear search box when a preset is selected
  };

  const handleClearSearch = () => {
    setSearchText('');
  };

  async function onDuplicate(presetId: number) {
    if (!activeTeam) {
      toastService.error('No active team');
      return;
    }

    if (!activeTeam.organization.id) {
      toastService.error('No organization id');
      return;
    }

    if (!activeProfile?.id) {
      toastService.error('No profile id');
      return;
    }

    if (!contextKey) {
      toastService.error('No context key');
      return;
    }

    const preset = presets.find((p) => p.id === presetId);
    if (!preset) {
      toastService.error('Preset not found');
      return;
    }

    const newName = generateCopyName(preset.name, new Set(presets.map((p) => p.name)));

    const loadingKey = 'duplicateFilterPreset' + newName;

    startGlobalLoading(loadingKey);

    const dto: CreateOrUpdateFilterDTO = {
      name: newName,
      org_id: activeTeam.organization.id,
      profile_id: activeProfile.id,
      filter: FilterPresetModel.encodeFilters(preset.filters),
      page_id: contextKey,
      level: preset.level,
    };

    const response = await filtersService.saveFilters(dto, fetchCache);
    if (!response.isSuccess) {
      toastService.error(response.message);
      stopGlobalLoading(loadingKey);
      return;
    }

    const newPreset = response.payload;
    setSelectedPreset(newPreset);

    refetchSavedPresets();

    toastService.success(`'${preset.name}' has been successfully duplicated as '${newName}'`);

    stopGlobalLoading(loadingKey);
  }

  async function onDelete(presetId: number) {
    const preset = presets.find((p) => p.id === presetId);
    if (!preset) {
      toastService.error('Preset not found');
      return;
    }

    const loadingKey = 'deleteFilterPreset' + presetId;
    startGlobalLoading(loadingKey);

    const response = await filtersService.deleteSavedFilter(presetId);
    if (!response.isSuccess) {
      toastService.error(response.message);
      stopGlobalLoading(loadingKey);
      return;
    }

    refetchSavedPresets();

    toastService.success(`'${preset.name}' has been successfully deleted"`);

    stopGlobalLoading(loadingKey);
  }

  const displayedPresetCount = currentProfilePresets.length + generalPresets.length + notApplicablePresets.length;

  // If presets change, scroll to make the selected preset visible. Important when name changes
  const cardContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const selectedPresetId = selectedPreset === 'new' ? undefined : selectedPreset?.id;
    const presetElement = document.getElementById('preset-' + selectedPresetId);
    if (presetElement && cardContentRef.current) {
      presetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [presets]);

  // Autofocus on search
  const searchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (dialogOpen && searchRef.current) {
      searchRef.current.focus();
    }
  }, [dialogOpen]);
  return (
    <div className="flex flex-row">
      <div className="flex min-w-[266px] flex-col bg-slate-50 py-4">
        {/* Search Field */}
        <TextField
          inputRef={searchRef}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Search Saved Filters"
          fullWidth
          variant="outlined"
          className="mb-2 px-4"
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ opacity: 0.5 }} />
                </InputAdornment>
              ),
              endAdornment: searchText && (
                <InputAdornment position="end">
                  <ClearIcon onClick={handleClearSearch} sx={{ cursor: 'pointer' }} />
                </InputAdornment>
              ),
            },
          }}
        />

        <ToggleButtonGroup
          value={selectedLevel}
          exclusive
          onChange={handleLevelChange}
          orientation="horizontal"
          fullWidth
          size="small"
          sx={{
            backgroundColor: 'transparent', // Ensure container is transparent
            borderBottom: '1px solid',
            borderRadius: 0,
            borderColor: 'divider',
            height: '32px', // overall container height
            '& .MuiToggleButton-root': {
              border: 'none',
              borderBottom: '2px solid transparent',
              borderRadius: 0,
              padding: '2px 8px', // reduced vertical padding
              minHeight: '32px', // force smaller height for each button
              fontSize: '0.75rem', // adjust font size if needed
              color: 'text.secondary',
              fontWeight: 'medium',
              backgroundColor: 'transparent', // force buttons to be transparent normally
              '&:hover': {
                backgroundColor: 'action.hover',
              },
            },
            '& .MuiToggleButton-root.Mui-selected': {
              backgroundColor: 'transparent',
              color: 'primary.main',
              borderBottomColor: 'primary.main',
              fontWeight: 'bold',
            },
          }}
        >
          <ToggleButton value={ALL_PRESETS}>All</ToggleButton>
          <ToggleButton value={SavedFilterLevel.TEAM}>Team</ToggleButton>
          <ToggleButton value={SavedFilterLevel.PERSONAL}>Personal</ToggleButton>
        </ToggleButtonGroup>

        <CardContent
          className="p-0"
          sx={{
            p: 0,
            overflowY: 'auto',
            scrollbarGutter: 'stable', // reserve space for scrollbar so layout doesn't shift
            // For WebKit browsers:
            '&::-webkit-scrollbar': {
              width: '18px',
              transition: 'opacity 0.3s ease-in-out',
              opacity: 0, // hidden by default
            },
            '&:hover::-webkit-scrollbar': {
              opacity: 1, // visible on hover
            },
            // For Firefox:
            scrollbarWidth: 'thin',
            scrollbarColor: 'transparent transparent', // thumb and track hidden
            '&:hover': {
              scrollbarColor: 'rgba(0,0,0,0.4) transparent', // thumb visible on hover
            },
          }}
        >
          {isLoading && !isError && (
            <div className="flex mt-24 flex-grow items-center justify-center">
              <CircularProgress />
            </div>
          )}

          {isError && (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              className={'h-full'}
            >
              Failed to load saved filters
            </Typography>
          )}

          {!isLoading && !isError && displayedPresetCount === 0 && (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              className={'mt-24'}
            >
              {!isEmpty(searchText) ? 'Nothing found' : 'Presets will appear here'}
            </Typography>
          )}

          {!isLoading && !isError && displayedPresetCount > 0 && (
            <div className="flex flex-col">
              {currentProfilePresets.length > 0 && (
                <SavedPresetsListGroup
                  isNewPresetSelected={isNewPresetSelected}
                  selectedPresetId={selectedPresetId}
                  header={activeProfile?.name ?? ''}
                  presets={currentProfilePresets}
                  searchText={searchText}
                  handleSelect={handleSelect}
                  onDuplicate={onDuplicate}
                  onDelete={onDelete}
                  onStartUpdatingExistingPreset={onStartUpdatingExistingPreset}
                />
              )}
              {generalPresets.length > 0 && (
                <SavedPresetsListGroup
                  header="General"
                  presets={generalPresets}
                  isNewPresetSelected={isNewPresetSelected}
                  selectedPresetId={selectedPresetId}
                  searchText={searchText}
                  handleSelect={handleSelect}
                  onDuplicate={onDuplicate}
                  onDelete={onDelete}
                  onStartUpdatingExistingPreset={onStartUpdatingExistingPreset}
                />
              )}
              {notApplicablePresets.length > 0 && (
                <SavedPresetsListGroup
                  header="Not Applicable"
                  presets={notApplicablePresets}
                  isNewPresetSelected={isNewPresetSelected}
                  selectedPresetId={selectedPresetId}
                  searchText={searchText}
                  handleSelect={handleSelect}
                  onDuplicate={onDuplicate}
                  onDelete={onDelete}
                  onStartUpdatingExistingPreset={onStartUpdatingExistingPreset}
                />
              )}
            </div>
          )}
        </CardContent>
      </div>
      <Divider className="mr-2" orientation="vertical" flexItem />
    </div>
  );
};

export default SavedPresetsPanel;
