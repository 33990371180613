import { DeleteSweep } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { cloneDeep } from 'lodash-es';
import { Dispatch, FunctionComponent, MutableRefObject, SetStateAction } from 'react';
import { FilterRow } from './FilterRow';
import { AlFilterModel } from './models/AlFilterModel';
import { FilterPresetModel } from './models/FilterPresetModel';
import { FilterErrorCode } from './types/FilterErrorCodes';
import { FilterKey, filterTypesWithOptions } from './types/FilterKey';

interface FilterRowsWithControlsProps {
  pendingFilters: AlFilterModel[];
  setPendingFilters: Dispatch<SetStateAction<AlFilterModel[]>>;
  setPendingSelectedPreset: (preset: FilterPresetModel | 'new' | null) => void;
  onApplyFiltersClicked: () => void;
  previousFilters: MutableRefObject<string>;
  availableFilters: AlFilterModel[];
  filterToErrorsRecord: Record<FilterKey, Set<FilterErrorCode>>;
}

const FilterRowsWithControls: FunctionComponent<FilterRowsWithControlsProps> = ({
  pendingFilters,
  setPendingFilters,
  setPendingSelectedPreset,
  onApplyFiltersClicked,
  previousFilters,
  availableFilters,
  filterToErrorsRecord,
}) => {
  const isFilterAreaOverflowing = pendingFilters.filter((f) => f.isFilterBuilderFilter).length > 6; // keep in sync with height

  const usedFilterKeys = pendingFilters.map((filter) => filter.key);
  const unUsedFilters = availableFilters.filter((f) => !usedFilterKeys.includes(f.key));

  const areFiltersUnchanged = () => {
    return JSON.stringify(pendingFilters) === previousFilters.current;
  };

  const onAddFilterClicked = () => {
    if (unUsedFilters.length > 0) {
      const newFilterToAdd = availableFilters.find((filter) => filter.key === unUsedFilters[0].key);
      if (newFilterToAdd) {
        setPendingFilters((prevFilters) => [...prevFilters, newFilterToAdd]);
      }
    }
  };

  const onDiscardAllClicked = () => {
    const nonFilterBuilderFilters = pendingFilters.filter((f) => !f.isFilterBuilderFilter);
    setPendingFilters(nonFilterBuilderFilters);
    previousFilters.current = JSON.stringify(nonFilterBuilderFilters); // Update the last applied filters
    setPendingSelectedPreset('new');
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (areFiltersUnchanged()) {
        onApplyFiltersClicked();
      } else {
        previousFilters.current = JSON.stringify(pendingFilters);
      }
    }
  };

  const updateFilterByFilterKey = (oldKey: FilterKey, filter: AlFilterModel | null) => {
    setPendingFilters((previousValue) => {
      // Delete filter
      if (filter == null) {
        return previousValue.filter((filterItem) => filterItem.key !== oldKey);
      }
      // Update filter
      const index = previousValue.findIndex((filterItem) => filterItem.key === oldKey);

      // Following should not happen in the correct flow
      if (index == -1) {
        console.error('Pending filters do not contain a filter with a matching key', oldKey, filter.key);
        // Provided filter is ignored as it would be weird if user edits single row and then new row is added
        return previousValue;
      }

      // Transfer conditions from old filter to new filter
      // Do not transfer when the old filter has options as it usually doesn't make sense (e.g. selected campaigns to campaign state)
      if (previousValue[index].type === filter.type && !filterTypesWithOptions.includes(filter.type)) {
        filter.conditions = cloneDeep(previousValue[index].conditions)?.map((c, ix) => {
          if (filter.isInverseFilter != previousValue[index].isInverseFilter) {
            c.operator = filter.defaultConditionOperators[ix];
          }
          return c;
        });
      }
      previousValue[index] = filter;

      return [...previousValue];
    });
  };

  return (
    <>
      <div
        className={`flex flex-col overflow-y-auto overflow-x-hidden ${isFilterAreaOverflowing ? 'border-b border-gray-200' : ''}`}
        onKeyUp={handleKeyUp}
      >
        {pendingFilters
          .filter((f) => f.isFilterBuilderFilter)
          .map((filter) => (
            <FilterRow
              key={filter.key}
              filter={filter}
              unUsedFilters={unUsedFilters}
              updateFilterByFilterKey={updateFilterByFilterKey}
              filterErrorCodeSet={filterToErrorsRecord[filter.key]}
            />
          ))}
      </div>

      <div className={`flex flex-row ${unUsedFilters.length > 0 ? 'justify-between' : 'justify-end'}`}>
        {unUsedFilters.length > 0 && (
          <Button autoFocus variant="text" startIcon={<AddIcon />} onClick={onAddFilterClicked}>
            Add New Filter
          </Button>
        )}
        {pendingFilters.length > 0 && (
          <Button variant="text" startIcon={<DeleteSweep />} onClick={onDiscardAllClicked}>
            Clear All
          </Button>
        )}
      </div>
    </>
  );
};

export default FilterRowsWithControls;
