import { FlowType } from '@/modules/log-viewing/api/logs-contracts';
import { ChangeCampaignGroupDTO, NewCampaignGroupDTO } from '@/modules/optimizer/api/campaign/campaign-contracts';
import {
  campaignService,
  createCampaignGroupsQueryKey,
  invalidateProfile_campaignsWithTimelineQueryKeys,
} from '@/modules/optimizer/api/campaign/campaign-service';
import { AssignImportModel } from '@/modules/optimizer/api/campaign/models/AssignImportModel';
import { CampaignGroupModel } from '@/modules/optimizer/api/campaign/models/CampaignGroupModel';
import { CreateImportModel } from '@/modules/optimizer/api/campaign/models/CreateImportModel';
import { OptimizationPreset } from '@/modules/optimizer/components/optimization/OptimizerConfig';
import { invalidateProfile_placementsWithTimelineQueryKeys } from '@/modules/placements/api/placements-service';
import { invalidateProfile_targetingWithTimelineQueryKeys } from '@/modules/targeting/api/targets-service';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { toastService } from '@/services/toast.service';
import { useQueryClient } from '@tanstack/react-query';
import type { GridApi, IRowNode } from 'ag-grid-community';
import { isEmpty } from 'lodash-es';
import { isEnumValue } from '../helpers';

const useApply = ({
  setIsLoadingCreate,
  createGridApiRef,
  assignGridApiRef,
  onCloseModal,
}: {
  setIsLoadingCreate: React.Dispatch<React.SetStateAction<boolean>>;
  createGridApiRef: React.MutableRefObject<GridApi<CreateImportModel> | null>;
  assignGridApiRef: React.MutableRefObject<GridApi<AssignImportModel> | null>;
  onCloseModal: () => void;
}) => {
  const { activeProfile } = useActiveTeamContext();
  const queryClient = useQueryClient();

  async function finalizeCreate() {
    // Refetch instead of invalidate because we want to see the updated data immediately when the import modal closes
    const queryKey = createCampaignGroupsQueryKey(activeProfile?.id);
    await queryClient.refetchQueries({ queryKey });

    setIsLoadingCreate(false);
    onCloseModal();
  }

  async function onApplyClicked(createOnly = false) {
    if (!createGridApiRef.current || !assignGridApiRef.current) {
      return;
    }
    setIsLoadingCreate(true);

    // CREATE NEW GROUPS
    const newGroups: NewCampaignGroupDTO[] = getNewGroups(createGridApiRef.current);
    let newlyCreatedGroups: CampaignGroupModel[] = [];

    if (newGroups.length > 0) {
      const createResponse = await campaignService.createGroups(newGroups, FlowType.OPTIMIZATION_GROUPS_IMPORT);

      if (!createResponse.isSuccess) {
        toastService.error(`Failed to create new groups: ${createResponse.message}`);
        finalizeCreate();
        return;
      }

      newlyCreatedGroups = createResponse.payload;
    }

    // UPDATE EXISTING GROUPS
    const existingGroupsToUpdate: CampaignGroupModel[] = getExistingGroupsToUpdate(createGridApiRef.current);
    if (existingGroupsToUpdate.length > 0) {
      const updateResponse = await campaignService.updateGroups(existingGroupsToUpdate);

      if (!updateResponse.isSuccess) {
        toastService.error(`Failed to update existing groups: ${updateResponse.message}`);
        finalizeCreate();
        return;
      }
    }

    // ASSIGN
    const assignments: ChangeCampaignGroupDTO[] = getAssignments(assignGridApiRef.current, newlyCreatedGroups);
    if (assignments.length > 0 && !createOnly) {
      const assignResponse = await campaignService.changeGroups(assignments);

      if (!assignResponse.isSuccess) {
        toastService.error(`Failed to assign campaigns: ${assignResponse.message}`);
        finalizeCreate();
        return;
      }
    }

    // FEEDBACK
    const messages = [
      newGroups.length > 0 && `${newGroups.length} new optimization groups created`,
      existingGroupsToUpdate.length > 0 && `${existingGroupsToUpdate.length} existing optimization groups updated`,
      assignments.length > 0 && `${assignments.length} campaigns assigned`,
    ]
      .filter(Boolean)
      .join(', ');

    toastService.success(messages ? `${messages} successfully.` : 'No optimization groups created or updated, and no campaigns assigned.');

    // Invalidate queries
    if (existingGroupsToUpdate.length > 0) {
      invalidateProfile_campaignsWithTimelineQueryKeys(queryClient, activeProfile?.id);
      invalidateProfile_targetingWithTimelineQueryKeys(queryClient, activeProfile?.id);
      invalidateProfile_placementsWithTimelineQueryKeys(queryClient, activeProfile?.id);
    }

    finalizeCreate();
  }

  return {
    onApplyClicked,
  };
};

export default useApply;

function getNewGroups(api: GridApi<CreateImportModel>): NewCampaignGroupDTO[] {
  if (!api) {
    return [];
  }
  const newGroups: NewCampaignGroupDTO[] = [];
  api.forEachNode((node: IRowNode<CreateImportModel>) => {
    if (node.data && node.data.name) {
      if (node.data.isExistingName) {
        return;
      }

      const numberTargetAcos = Number(node.data.tacos);
      const validPrioritizationValue = isEnumValue(OptimizationPreset, node.data.prioritization) ? node.data.prioritization : undefined;

      // TODO: see if validation and assignment can be merged with CreateUpdateCampaignGroup
      newGroups.push({
        name: node.data.name,
        tacos: !isNaN(numberTargetAcos) && !isEmpty(node.data.tacos) ? numberTargetAcos / 100 : undefined, // always in fractions when not displaying
        preset: validPrioritizationValue,
        no_optimize: !node.data?.optimizationEnabled,
        bid_floor_off: node.data?.bidFloorOff,
        bid_ceiling_off: node.data?.bidCeilingOff,

        // bid_ceiling: node.data.bidCeiling,
        // bid_floor: node.data.bidFloor,
        // bid_max_increase: node.data.bidMaxIncrease,
        // bid_max_decrease: node.data.bidMaxDecrease,
        // placement_max_increase: node.data.placementMaxIncrease,
        // placement_max_decrease: node.data.placementMaxDecrease,
        // bid_max_increase_off: node.data.bidMaxIncreaseOff,
        // bid_max_decrease_off: node.data.bidMaxDecreaseOff,
        // placement_max_increase_off: node.data.placementMaxIncreaseOff,
        // placement_max_decrease_off: node.data.placementMaxDecreaseOff,
      });
    }
  });
  return newGroups;
}

function getExistingGroupsToUpdate(api: GridApi<CreateImportModel>): CampaignGroupModel[] {
  if (!api) {
    return [];
  }
  const existingGroups: CampaignGroupModel[] = [];
  api.forEachNode((node: IRowNode<CreateImportModel>) => {
    if (node.data && node.data.name) {
      if (!node.data.isExistingName) {
        return;
      }

      const id = Number(node.data.id);
      if (isNaN(id) || id == 0) {
        return;
      }

      const numberTargetAcos = Number(node.data.tacos);
      const validPrioritizationValue = isEnumValue(OptimizationPreset, node.data.prioritization) ? node.data.prioritization : undefined;

      existingGroups.push(
        new CampaignGroupModel({
          id,
          name: node.data.name,
          tacos: !isNaN(numberTargetAcos) && !isEmpty(node.data.tacos) ? numberTargetAcos / 100 : undefined, // always in fractions when not displaying
          preset: validPrioritizationValue,
          optimizationEnabled: node.data?.optimizationEnabled,
          totalCampaigns: 0,
          bidFloorOff: node.data?.bidFloorOff == null ? node.data?.bidFloorOff : (node.data?.bidFloorOff ?? false),
          bidCeilingOff: node.data?.bidCeilingOff == null ? node.data?.bidCeilingOff : (node.data?.bidCeilingOff ?? false),
          bidMaxIncreaseOff: node.data?.bidMaxIncreaseOff == null ? node.data?.bidMaxIncreaseOff : (node.data?.bidMaxIncreaseOff ?? false),
          bidMaxDecreaseOff: node.data?.bidMaxDecreaseOff == null ? node.data?.bidMaxDecreaseOff : (node.data?.bidMaxDecreaseOff ?? false),
          placementMaxIncreaseOff:
            node.data?.placementMaxIncreaseOff == null ? node.data?.placementMaxIncreaseOff : (node.data?.placementMaxIncreaseOff ?? false),
          placementMaxDecreaseOff:
            node.data?.placementMaxDecreaseOff == null ? node.data?.placementMaxDecreaseOff : (node.data?.placementMaxDecreaseOff ?? false),
        }),
      );
    }
  });
  return existingGroups;
}

function getAssignments(api: GridApi<AssignImportModel>, newlyCreatedGroups: CampaignGroupModel[]): ChangeCampaignGroupDTO[] {
  const groupMap = newlyCreatedGroups.reduce(
    (acc, group) => {
      acc[group.name] = group.id;
      return acc;
    },
    {} as Record<string, number>,
  );

  const assignments: ChangeCampaignGroupDTO[] = [];
  api.forEachNode((node: IRowNode<AssignImportModel>) => {
    if (node.data && node.data.campaignId) {
      const rawGroupId = node.data.isGroupExisting ? node.data.groupId : groupMap[node.data.campaignName];

      if (rawGroupId == null) {
        console.error('Group id is null for campaign: ' + node.data.campaignName);
        return;
      }

      const groupId = Number(rawGroupId);
      if (isNaN(groupId)) {
        console.error('Failed to convert group id to a valid number for campaign: ' + node.data.campaignName);
        return;
      }

      assignments.push({
        campaign_id: node.data.campaignId,
        group_id: groupId,
      });
    }
  });
  return assignments;
}
