import { ApiResponse } from '@/lib/api/api-response';
import { apiClient } from '@/lib/api/base-client';
import { DashboardLinkCreateDTO, DashboardLinkReadDTO } from './dashboard-link.contracts';
import { DashboardLinkModel } from '../../types/DashboardLinkModel';

class DashboardLinkService {
  public basePath = 'dashboards/links';

  async create(linkToCreate: DashboardLinkCreateDTO): Promise<ApiResponse<DashboardLinkModel>> {
    try {
      const applicationResponse = await apiClient.post<DashboardLinkReadDTO>(`${this.basePath}`, linkToCreate);
      return applicationResponse.processPayload((payload) => {
        return new DashboardLinkModel(payload);
      });
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getByDashboardId(dashboardId: number): Promise<ApiResponse<DashboardLinkModel[]>> {
    try {
      const applicationResponse = await apiClient.get<DashboardLinkReadDTO[]>(`dashboards/${dashboardId}/links`);
      return applicationResponse.processPayload((payload) => {
        return payload.map((p) => new DashboardLinkModel(p));
      });
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getByToken(token: string): Promise<ApiResponse<DashboardLinkModel>> {
    try {
      const applicationResponse = await apiClient.get<DashboardLinkReadDTO>(`${this.basePath}/external/${token}`);
      return applicationResponse.processPayload((payload) => new DashboardLinkModel(payload));
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async delete(linkId: number): Promise<ApiResponse<null>> {
    try {
      return await apiClient.post(`${this.basePath}/${linkId}/delete`, null);
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const dashboardLinkService = new DashboardLinkService();
