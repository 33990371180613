import { UserSettingKey, useUserSettingsContext } from '@/modules/users';
import { SettingValue, UserSettingValue } from '@/modules/users/api/user-settings/user-setting.contracts';
import { debounce } from 'lodash-es';
import { useEffect, useState } from 'react';

// Overload signatures
export function useUserSetting<T extends SettingValue>(
  userSettingKey: UserSettingKey,
  initialValue: T,
): {
  settingState: T;
  isLoading: boolean;
  isError: boolean;
  handleSettingStateChange: (newValue: T) => void;
};

export function useUserSetting<T extends SettingValue>(
  userSettingKey: UserSettingKey,
): {
  settingState: T | undefined;
  isLoading: boolean;
  isError: boolean;
  handleSettingStateChange: (newValue: T) => void;
};

// Implementation
export function useUserSetting<T extends SettingValue>(userSettingKey: UserSettingKey, initialValue?: T) {
  const { getUserSettingValueByKey, upsertUserSetting } = useUserSettingsContext();

  // The initial state is either the stored value or the default (if provided)
  const [settingState, setSettingState] = useState<T | undefined>(() => getUserSettingValueByKey<T>(userSettingKey) ?? initialValue);

  useEffect(() => {
    setSettingState(getUserSettingValueByKey<T>(userSettingKey) ?? initialValue);
  }, [userSettingKey]);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  function handleSettingStateChange(newValue: T) {
    setSettingState(newValue);
    debouncedSaveSettingState(newValue);
  }

  const debouncedSaveSettingState = debounce((newValue: T) => {
    saveSettingState(newValue);
  }, 500);

  async function saveSettingState(newValue: T) {
    setIsLoading(true);
    const success = await upsertUserSetting(userSettingKey, newValue as UserSettingValue);
    setIsError(!success);
    setIsLoading(false);
  }

  // If a default value was provided, we can assert the state is defined.
  if (initialValue !== undefined) {
    return {
      settingState: settingState as T,
      isLoading,
      isError,
      handleSettingStateChange,
    };
  } else {
    return { settingState, isLoading, isError, handleSettingStateChange };
  }
}
