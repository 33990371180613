import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { useDeleteConfirmation } from '@/components/modals/delete-confirmation-modal/useDeleteConfirmationModal';
import useFormatting from '@/hooks/useFormatting';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { toastService } from '@/services/toast.service';
import { Delete } from '@mui/icons-material';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { Button, Checkbox, Divider, Tooltip } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import type { GridApi } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import { FunctionComponent, MutableRefObject, useMemo, useRef, useState } from 'react';
import OppositeModeOverlayBar from '../../../components/OppositeModeOverlayBar';
import { campaignMappingService, invalidateProfile_campaignMappingQueryKeys } from '../api/campaign-mapping-service';
import { CampaignMappingModel } from '../models/CampaignMappingModel';
import CampaignMappingBulkEditPopover from './CampaignMappingBulkEditPopover';

interface CampaignMappingActionsBarProps {
  selectedCampaignMappings: CampaignMappingModel[];
  setSelectedCampaignMappings: (selectedCampaignMappings: CampaignMappingModel[]) => void;
  totalMappingsCount: number;
  onDiscardClicked: () => void;
  mappingsTableGridApiRef: MutableRefObject<GridApi | null>;
  filters: AlFilterModel[];
  rowData: CampaignMappingModel[];
}

const CampaignMappingSelectionActionsBar: FunctionComponent<CampaignMappingActionsBarProps> = ({
  selectedCampaignMappings,
  setSelectedCampaignMappings,
  totalMappingsCount,
  onDiscardClicked,
  mappingsTableGridApiRef,
  filters,
  rowData,
}) => {
  const queryClient = useQueryClient();
  const { formatWithThousandsSeparator } = useFormatting();
  const { activeProfile } = useActiveTeamContext();

  // HANDLE SELECTION
  function onDeselectAll() {
    setSelectedCampaignMappings([]);
    if (mappingsTableGridApiRef.current) {
      mappingsTableGridApiRef.current.deselectAll();
    }
  }

  function onSelectAll() {
    if (mappingsTableGridApiRef.current) {
      mappingsTableGridApiRef.current.forEachNodeAfterFilter((node) => {
        node.setSelected(true);
      });
    }
  }

  const selectedMatchesCount = useMemo(() => {
    return selectedCampaignMappings.reduce((total, mapping) => {
      return total + (mapping.selectedMatchTypes ? mapping.selectedMatchTypes.length : 0);
    }, 0);
  }, [selectedCampaignMappings]);

  // Bulk Edit
  const bulkEditButtonRef = useRef<HTMLButtonElement | null>(null);
  const [isBulkEditPopoverOpen, setIsBulkEditPopoverOpen] = useState(false);
  const handleBulkEditPopoverClose = () => setIsBulkEditPopoverOpen(false);

  // Delete
  const { ModalComponent, handleOpenModal: openDeleteConfirmationModal } = useDeleteConfirmation({
    questionText: 'Are you sure you want to delete selected mappings? This action cannot be undone.',
    headerText: `Delete ${selectedCampaignMappings.length} Mapping${selectedCampaignMappings.length > 1 ? 's' : ''}`,
    onDelete: deleteSelectedMappings,
  });

  function isAllExistingMappingsSelected() {
    if (filters.filter((f) => f.isFilterBuilderFilter).length > 0) {
      return false;
    }

    if (selectedCampaignMappings.length !== rowData.length) {
      return false;
    }

    if (!selectedCampaignMappings.every((row) => row.matchTypes.length === row.selectedMatchTypes.length)) {
      return false;
    }

    return true;
  }

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  async function deleteSelectedMappings() {
    if (selectedCampaignMappings.length === 0) return;

    const ids = selectedCampaignMappings.map((row) => row.id).filter((id) => !isNil(id)) as string[]; // ts doesn't understand null removal;
    if (ids.length === 0) return;

    setIsDeleteLoading(true);

    try {
      // When deleting all (no filters applied), deleteAllCampaignMappings is more efficient
      const response = isAllExistingMappingsSelected()
        ? await campaignMappingService.deleteAllCampaignMappings()
        : await campaignMappingService.deleteSelectedCampaignMappings(selectedCampaignMappings);

      if (!response.isSuccess) {
        toastService.error(response.message);
      } else {
        toastService.success(`Campaign Mappings deleted successfully`);
        invalidateProfile_campaignMappingQueryKeys(queryClient, activeProfile?.id);
      }
    } catch (error) {
      console.error(error);
      toastService.error('Failed to delete campaign mapping: ' + error);
    }

    setIsDeleteLoading(false);
  }

  return (
    <>
      <OppositeModeOverlayBar visible={selectedCampaignMappings.length > 0}>
        <div className="flex flex-row items-center gap-4 px-1.5">
          <Tooltip title="Deselect all">
            <div onClick={onDiscardClicked} className="flex flex-row items-center text-sm font-bold hover:cursor-pointer">
              <Checkbox checked={true} name="deselectCheckbox" color="secondary" />
              {formatWithThousandsSeparator(selectedMatchesCount)} selected
            </div>
          </Tooltip>

          <Divider className="my-2" orientation="vertical" flexItem />

          <div className="flex flex-row items-center gap-4 whitespace-nowrap">
            <Button
              ref={bulkEditButtonRef}
              size="small"
              className="whitespace-nowrap"
              onClick={() => setIsBulkEditPopoverOpen(true)}
              variant={isBulkEditPopoverOpen ? 'contained' : 'outlined'}
              startIcon={<AssignmentOutlinedIcon />}
            >
              Bulk Actions
            </Button>

            <Button
              loading={isDeleteLoading}
              disabled={isDeleteLoading}
              variant="outlined"
              size="small"
              color="error"
              onClick={openDeleteConfirmationModal}
              startIcon={<Delete />}
            >
              Delete Selected
            </Button>

            <Divider className="my-2" orientation="vertical" flexItem />
            <Button
              className="whitespace-nowrap"
              onClick={selectedCampaignMappings.length == totalMappingsCount ? onDeselectAll : onSelectAll}
              variant="text"
            >
              {selectedCampaignMappings.length == totalMappingsCount ? 'Deselect All' : 'Select All'}
            </Button>
          </div>
        </div>
      </OppositeModeOverlayBar>
      {isBulkEditPopoverOpen && (
        <CampaignMappingBulkEditPopover
          buttonRef={bulkEditButtonRef}
          isOpen={isBulkEditPopoverOpen}
          onClose={handleBulkEditPopoverClose}
          selectedCampaignMappings={selectedCampaignMappings}
          selectedMatchesCount={selectedMatchesCount}
        />
      )}

      {ModalComponent}
    </>
  );
};

export default CampaignMappingSelectionActionsBar;
