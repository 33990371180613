import { ApiResponse } from '@/lib/api/api-response';
import { apiProfileClient } from '@/lib/api/base-client';
import { BidCeilingType } from '@/modules/optimizer/types/BidCeilingType';
import { BidLimitType, PlacementBidLimitType } from '@/modules/optimizer/types/BidLimitType';
import { SmartBidCeilingType } from '@/modules/optimizer/types/SmartBidCeilingType';
import { OptimizerJobDTO } from '@/modules/profiles/api/profile.contracts';
import { OptimizerJobModel } from '@/modules/profiles/types/OptimizerJobModel';
import { isNil } from 'lodash-es';
import {
  AlFilterModel,
  ComparisonDateFilterModel,
  createQueryKeyFromFilters,
  DateFilterModel,
} from '../../../../../components/filter-builder/models/AlFilterModel';
import { OptimizationApplyData, OptimizationParams } from '../OptimizerConfig';
import { OptimizationModel } from '../models/OptimizationModel';
import {
  BidLimitChangeUnitType,
  EnabledKeywordGroups,
  OptimizationApplyDTO,
  OptimizationRequestDTO,
  OptimizationResponse,
  OptimizationUpdates,
  OptimizedBiddingEntityDTO,
  RevertJobDTO,
  UpsertJobNoteDTO,
} from './optimization-contracts';

class OptimizationService {
  public basePath = 'optimizer';
  private OPTIMIZATION_QUERY_KEY_BASE = 'optimization';

  createOptimizedResultsQueryKey(
    activeProfileId: string | undefined,
    selectedCampaigns: string[],
    filters: AlFilterModel[],
    optimizationParams: OptimizationParams,
  ) {
    return [
      this.OPTIMIZATION_QUERY_KEY_BASE,
      activeProfileId,
      createQueryKeyFromFilters(filters),
      selectedCampaigns,
      JSON.stringify(optimizationParams),
    ];
  }

  async getOptimizedResults(
    selectedCampaigns: string[],
    filters: AlFilterModel[],
    optimizationParams: OptimizationParams,
  ): Promise<ApiResponse<OptimizationModel>> {
    try {
      const datesFilter = filters.find((f) => f instanceof DateFilterModel);
      const compareDatesFilter = filters.find((f) => f instanceof ComparisonDateFilterModel);

      if (!datesFilter || !compareDatesFilter) {
        return ApiResponse.UnknownErrorResponse();
      }

      const keywordGroupMapping: Record<string, EnabledKeywordGroups> = {
        highAcos: EnabledKeywordGroups.HIGH_ACOS,
        highSpend: EnabledKeywordGroups.HIGH_SPEND_NO_SALES,
        lowAcos: EnabledKeywordGroups.LOW_ACOS,
        lowVisibility: EnabledKeywordGroups.LOW_VISIBILITY,
      };

      const enabledKeywordGroups: EnabledKeywordGroups[] = Object.entries(keywordGroupMapping)
        .filter(([key]) => optimizationParams[key as keyof typeof optimizationParams])
        .map(([, value]) => value);

      let bidCeilingValue = 0;
      if (optimizationParams.bidCeilingType == BidCeilingType.MANUAL) {
        bidCeilingValue = Number(optimizationParams.bidCeiling);
      } else if (optimizationParams.bidCeilingType == BidCeilingType.SMART) {
        switch (optimizationParams.smartBidCeilingType) {
          case SmartBidCeilingType.TARGET_CPC_1X:
            bidCeilingValue = 1;
            break;
          case SmartBidCeilingType.TARGET_CPC_2X:
            bidCeilingValue = 2;
            break;
          case SmartBidCeilingType.TARGET_CPC_3X:
            bidCeilingValue = 3;
            break;
        }
      }

      const requestData: OptimizationRequestDTO = {
        dates: {
          start_date: (datesFilter.conditions ? datesFilter.conditions[0].values[0] : null) as string,
          end_date: (datesFilter.conditions ? datesFilter.conditions[1].values[0] : null) as string,
        },
        compare_dates: {
          start_date: (compareDatesFilter.conditions ? compareDatesFilter.conditions[0].values[0] : null) as string,
          end_date: (compareDatesFilter.conditions ? compareDatesFilter.conditions[1].values[0] : null) as string,
        },
        campaign_ids: selectedCampaigns,
        tacos: optimizationParams.tacos / 100,
        preset: optimizationParams.selectedPreset,
        advanced: {
          enabled_keyword_groups: enabledKeywordGroups,
          exclude_no_impressions: !optimizationParams.showZeroImpressions,
          skip_placement_optimization: !optimizationParams.usePlacementOptimization,
          override_group_settings: !optimizationParams.useGroupSettings,

          bid_floor: !isNil(optimizationParams.bidFloor) ? Number(optimizationParams.bidFloor) : undefined,
          bid_ceiling:
            optimizationParams.bidCeilingType != BidCeilingType.OFF
              ? {
                  unit:
                    optimizationParams.bidCeilingType == BidCeilingType.SMART
                      ? BidLimitChangeUnitType.TIMES_CPC
                      : BidLimitChangeUnitType.CURRENCY,
                  value: bidCeilingValue,
                }
              : undefined,
          bid_max_increase:
            optimizationParams.bidMaxIncreaseType != BidLimitType.OFF
              ? {
                  unit:
                    optimizationParams.bidMaxIncreaseType == BidLimitType.PERCENT
                      ? BidLimitChangeUnitType.PERCENT
                      : BidLimitChangeUnitType.CURRENCY,
                  value:
                    optimizationParams.bidMaxIncreaseType == BidLimitType.PERCENT
                      ? Number(optimizationParams.bidMaxIncrease) / 100
                      : Number(optimizationParams.bidMaxIncrease),
                }
              : undefined,
          bid_max_decrease:
            optimizationParams.bidMaxDecreaseType != BidLimitType.OFF
              ? {
                  unit:
                    optimizationParams.bidMaxDecreaseType == BidLimitType.PERCENT
                      ? BidLimitChangeUnitType.PERCENT
                      : BidLimitChangeUnitType.CURRENCY,
                  value:
                    optimizationParams.bidMaxDecreaseType == BidLimitType.PERCENT
                      ? Number(optimizationParams.bidMaxDecrease) / 100
                      : Number(optimizationParams.bidMaxDecrease),
                }
              : undefined,
          placement_max_increase:
            optimizationParams.placementMaxIncreaseType != PlacementBidLimitType.OFF
              ? Number(optimizationParams.placementMaxIncrease) / 100
              : undefined,
          placement_max_decrease:
            optimizationParams.placementMaxDecreaseType != PlacementBidLimitType.OFF
              ? Number(optimizationParams.placementMaxDecrease) / 100
              : undefined,
        },
      };

      const applicationResponse = await apiProfileClient.post<OptimizationResponse>(`${this.basePath}/preview`, requestData);
      return applicationResponse.processPayload(OptimizationModel.fromResponse);
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async applyOptimization(
    jobId: number,
    optimizationPreviewSelection: OptimizationApplyData[],
    note: string,
  ): Promise<ApiResponse<OptimizedBiddingEntityDTO[]>> {
    const convertDataToRequest = (dataArray: OptimizationApplyData[]): OptimizationUpdates[] => {
      return dataArray.map((dataItem) => {
        const { id, ad_type, bidding_entity, match_type, new_value, algo_value, old_value, reasons } = dataItem;

        const requestItem: OptimizationUpdates = {
          id,
          ad_type,
          bidding_entity,
          match_type,
          new_value,
          algo_value,
          old_value,
          reasons,
        };

        return requestItem;
      });
    };

    const updates: OptimizationUpdates[] = convertDataToRequest(optimizationPreviewSelection);

    const requestData: OptimizationApplyDTO = {
      job_id: jobId,
      updates,
      note,
    };

    try {
      const applicationResponse = await apiProfileClient.post<OptimizedBiddingEntityDTO[]>(`${this.basePath}/apply`, requestData);
      return applicationResponse;
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async revertOptimizerJob(jobID: number): Promise<ApiResponse<OptimizedBiddingEntityDTO[]>> {
    const revertJobDTO: RevertJobDTO = {
      job_id: jobID,
    };

    return await apiProfileClient.post<OptimizedBiddingEntityDTO[]>(`/optimizer/revert`, revertJobDTO);
  }

  async getOptimizerJobs(): Promise<ApiResponse<OptimizerJobModel[]>> {
    const applicationResponse = await apiProfileClient.get<OptimizerJobDTO[]>(`${this.basePath}/jobs`);

    return applicationResponse.processPayload((payload) => {
      return payload.map(OptimizerJobModel.fromDTO);
    });
  }

  createGetLogsQueryKey(activeProfileId: string | undefined, jobId: number | undefined) {
    return [this.OPTIMIZATION_QUERY_KEY_BASE, activeProfileId, jobId];
  }

  async getLogs(jobId: number): Promise<ApiResponse<object[]>> {
    const applicationResponse = await apiProfileClient.get<object[]>(`${this.basePath}/logs/${jobId}`);
    return applicationResponse;
  }

  async saveNote(jobId: number, note: string): Promise<ApiResponse<null>> {
    const payload: UpsertJobNoteDTO = {
      job_id: jobId,
      note: note,
    };

    return await apiProfileClient.post(`${this.basePath}/job/note`, payload);
  }
}

export const optimizationService = new OptimizationService();
