import { AutoCompleteCellEditorOption } from '@/components/grid/cells/AutoCompleteCellEditor';
import { CampaignGroupModel } from '@/modules/optimizer/api/campaign/models/CampaignGroupModel';
import { CreateImportModel } from '@/modules/optimizer/api/campaign/models/CreateImportModel';

export function getGroupOptions(
  groupNameToGroupMap: Record<string, CampaignGroupModel>,
  newGroupNameToCreateGroupMap: Record<string, CreateImportModel>,
): AutoCompleteCellEditorOption[] {
  if (!groupNameToGroupMap || Object.keys(groupNameToGroupMap).length === 0 || !newGroupNameToCreateGroupMap) {
    return [];
  }

  const existingOptions: AutoCompleteCellEditorOption[] = Object.values(groupNameToGroupMap).map((group) => ({
    id: group.id.toString(),
    label: group.name,
    canHide: false,
  }));

  const newOptions: AutoCompleteCellEditorOption[] = Object.values(newGroupNameToCreateGroupMap).map((group) => ({
    id: group.id,
    label: group.name,
    canHide: false,
  }));

  return [...existingOptions, ...newOptions].sort((a, b) => a.label.localeCompare(b.label));
}
