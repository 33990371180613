import { useContext } from 'react';
import { ExternalContext } from '../contexts/ExternalContext';

export const useExternalContext = () => {
  const context = useContext(ExternalContext);
  if (!context) {
    throw new Error('useExternalContext must be used within an ExternalProvider');
  }
  return context;
};
