import useCampaignToAdGroupsMappingData from '@/modules/campaign-mapping/hooks/useCampaignToAdGroupsMappingData';
import { AssignImportModel } from '@/modules/optimizer/api/campaign/models/AssignImportModel';
import { CampaignGroupModel, campaignGroupsToCampaignGroupIdToGroupMap } from '@/modules/optimizer/api/campaign/models/CampaignGroupModel';
import { CreateImportModel } from '@/modules/optimizer/api/campaign/models/CreateImportModel';
import { toastService } from '@/services/toast.service';
import type { GridApi, GridReadyEvent } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import { useMemo, useRef } from 'react';
import { CampaignMappingImportDetails } from '../../../types/feedback';
import { CampaignGroupsAssignImportRow, FieldNamesAssign } from '../types';

interface UseAssignDataProps {
  uploadedFileAssignSheet: CampaignGroupsAssignImportRow[];
  importDetails: CampaignMappingImportDetails;
  updateImportDetails: (api: GridApi<CreateImportModel> | GridApi<AssignImportModel>) => void;
  rowDataCreate: CreateImportModel[];
  campaignGroups: CampaignGroupModel[] | undefined;
  groupNameToGroupMap: Record<string, CampaignGroupModel>;
}

const useAssignData = ({
  uploadedFileAssignSheet,
  importDetails,
  updateImportDetails,
  rowDataCreate,
  campaignGroups,
  groupNameToGroupMap,
}: UseAssignDataProps) => {
  const gridApiRef = useRef<GridApi<AssignImportModel> | null>(null);
  const { campaignToAdGroupsMap, getCampaignNameToCampaignIdMap } = useCampaignToAdGroupsMappingData();

  const isNotifiedAboutDuplicatesRef = useRef(false);

  const newGroupNameToCreateGroupMap = useMemo(
    () => getNewGroupNameToCreateGroupMap(rowDataCreate, groupNameToGroupMap),
    [rowDataCreate, groupNameToGroupMap],
  );
  const groupIdToGroupMap = useMemo(() => campaignGroupsToCampaignGroupIdToGroupMap(campaignGroups), [campaignGroups]);
  const newGroupIdToCreateGroupMap = useMemo(
    () => getNewGroupIdToCreateGroupMap(rowDataCreate, groupIdToGroupMap),
    [rowDataCreate, groupIdToGroupMap],
  );

  const rowDataAssign = useMemo(() => {
    if (uploadedFileAssignSheet.length <= 1) return [];
    const importedRows = uploadedFileAssignSheet.slice(1); // remove headers

    const models = importAssignRowsToModels(importedRows);

    return models;
  }, [uploadedFileAssignSheet, campaignGroups, campaignToAdGroupsMap]);

  function getNewGroupNameToCreateGroupMap(
    rowDataCreate: CreateImportModel[],
    groupNameToGroupMap: Record<string, CampaignGroupModel>,
  ): Record<string, CreateImportModel> {
    const newGroupNameToGroupMap: Record<string, CreateImportModel> = {};
    for (const row of rowDataCreate) {
      if (row.name) {
        if (groupNameToGroupMap[row.name]) continue; // skip existing groups that will be modified

        newGroupNameToGroupMap[row.name] = row;
      }
    }
    return newGroupNameToGroupMap;
  }

  function getNewGroupIdToCreateGroupMap(
    rowDataCreate: CreateImportModel[],
    groupIdToGroupMap: Record<string, CampaignGroupModel>,
  ): Record<string, CreateImportModel> {
    const newGroupIdToGroupMap: Record<string, CreateImportModel> = {};
    for (const row of rowDataCreate) {
      if (row.id) {
        if (groupIdToGroupMap[row.id]) continue; // skip existing groups that will be modified
        newGroupIdToGroupMap[row.id] = row;
      }
    }
    return newGroupIdToGroupMap;
  }

  function importAssignRowsToModels(importedRows: CampaignGroupsAssignImportRow[]): AssignImportModel[] {
    const models: AssignImportModel[] = [];
    const uniqueRowIds = new Set();
    let duplicateCount = 0;

    const campaignNameToCampaignIdsMap = getCampaignNameToCampaignIdMap();

    for (const [index, importRow] of importedRows.entries()) {
      // Ignore rows with no CampaignName
      if (isNil(importRow[FieldNamesAssign.CampaignName])) {
        continue;
      }
      // Use a unique identifier for each row to check for duplicates
      const uniqueIdentifier = importRow[FieldNamesAssign.CampaignName];

      if (uniqueRowIds.has(uniqueIdentifier)) {
        // Don't count empty
        if (importRow[FieldNamesAssign.GroupName] !== '') {
          duplicateCount++;
        }
        continue; // Skip processing this row if it's a duplicate
      }

      uniqueRowIds.add(uniqueIdentifier);

      const rowId = index.toString();
      const model = new AssignImportModel({
        id: rowId,
        campaignGroupsAssignImportRow: importRow,
        campaignGroupsAssignMaps: {
          campaignNameToCampaignIdsMap,
          campaignToAdGroupsMap,

          groupNameToGroupMap,
          newGroupNameToCreateGroupMap,
          groupIdToGroupMap,
          newGroupIdToCreateGroupMap,
        },
      });

      models.push(model);
    }

    setTimeout(() => {
      if (!isNotifiedAboutDuplicatesRef.current && duplicateCount > 0) {
        toastService.info(
          `${duplicateCount} duplicate${duplicateCount > 1 ? 's' : ''} row${duplicateCount > 1 ? 's' : ''} removed (by campaign name in assignments)`,
        );
        isNotifiedAboutDuplicatesRef.current = true;
      }
    }, 0);

    return models;
  }

  function onAssignGridReady(params: GridReadyEvent) {
    // Init: Try to gather errors and warnings. On row data setting and on grid ready
    gridApiRef.current = params.api;
    if (gridApiRef.current && importDetails.rowCount == 0) {
      updateImportDetails(gridApiRef.current);
    }
  }

  return { rowDataAssign, onAssignGridReady, assignGridApiRef: gridApiRef, groupNameToGroupMap, newGroupNameToCreateGroupMap };
};

export default useAssignData;
