import InfoIconWithTooltip from '@/components/feedback/InfoIconWithTooltip';
import { Search } from '@mui/icons-material';
import { Checkbox, InputAdornment, ListItem, ListItemButton, TextField, Tooltip, Typography } from '@mui/material';
import { isEmpty } from 'lodash-es';
import React, { FunctionComponent, useState } from 'react';
import { ColumnConfiguration, ColumnConfigurationGroup } from './column-categories';

interface ColumnSelectionListProps {
  columnGroups: ColumnConfigurationGroup[];
  localSelectedColumns: ColumnConfiguration[];
  setLocalSelectedColumns: React.Dispatch<React.SetStateAction<ColumnConfiguration[]>>;
}

const ColumnSelectionList: FunctionComponent<ColumnSelectionListProps> = ({
  columnGroups,
  localSelectedColumns,
  setLocalSelectedColumns,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredGroups = columnGroups.map((group) => ({
    ...group,
    columns: group.columns.filter((column) => column.title.toLowerCase().includes(searchTerm.toLowerCase())),
  }));

  const handleSelectAll = () => {
    const newlySelectedColumns = filteredGroups
      .flatMap((group) => group.columns)
      .filter((column) => !localSelectedColumns.some((col) => col.columnID === column.columnID && col.isSelected))
      .map((column) => ({ ...column, isSelected: true, pinned: false }));

    setLocalSelectedColumns([...localSelectedColumns, ...newlySelectedColumns]);
  };

  const onColumnSelectionToggle = (column: ColumnConfiguration) => {
    const newSelectedColumns = localSelectedColumns.filter((selectedColumn) => selectedColumn.columnID !== column.columnID);
    newSelectedColumns.push({
      ...column,
      isSelected: !localSelectedColumns.some((col) => col.columnID === column.columnID && col.isSelected),
      pinned: false,
    });
    setLocalSelectedColumns(newSelectedColumns);
  };

  return (
    <>
      <Typography className=" flex items-center w-full px-3">Available</Typography>
      <TextField
        className="px-3 mt-1"
        variant="outlined"
        placeholder="Search Columns"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <Search fontSize="inherit" />
              </InputAdornment>
            ),
            endAdornment: searchTerm && (
              <InputAdornment position="end">
                <button
                  onClick={() => setSearchTerm('')}
                  style={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    padding: 0,
                    margin: 0,
                    fontSize: 'inherit',
                  }}
                >
                  ✕
                </button>
              </InputAdornment>
            ),
          },
        }}
      />
      <div
        className="flex items-center border-y border-gray-200 px-3 py-1 mt-2 text-xs text-primary-500 cursor-pointer hover:underline"
        onClick={handleSelectAll}
      >
        Select All
      </div>
      <div className="py-3 flex flex-col flex-1 w-full items-start overflow-y-auto">
        {!filteredGroups.some((g) => g.columns.length > 0) && (
          <div className="text-sm text-gray-500 pl-3">No columns found for term &quot;{searchTerm}&quot;</div>
        )}
        {filteredGroups
          .filter((group) => group.columns.length > 0 && group.columns.some((col) => !col.lockVisible))
          .map((group) => (
            <div key={group.id} className="pb-2 w-full">
              {filteredGroups.length > 1 && <div className="text-sm font-semibold pl-4 text-gray-500 pb-0.5">{group.title}</div>}
              {group.columns
                .filter((column) => !column.lockVisible)
                .map((column) => (
                  <ListItem key={column.columnID} disablePadding>
                    <ListItemButton className="py-0.5" disabled={column.lockVisible} onClick={() => onColumnSelectionToggle(column)}>
                      <Checkbox
                        disabled={column.lockVisible}
                        style={{ padding: 0 }}
                        size="small"
                        checked={localSelectedColumns.some((col) => col.columnID === column.columnID && col.isSelected)}
                      />
                      <div className="flex items-center flex-1">
                        <div className={`pl-2 text-sm pr-1.5`}>{column.title}</div>
                        {!isEmpty(column.headerClassName) && (
                          <div className="flex">
                            <Tooltip title={column.headerTooltip} placement="right">
                              <div className={column.headerClassName + ' flex  bg-transparent'}></div>
                            </Tooltip>
                          </div>
                        )}
                        {column.tooltip && <InfoIconWithTooltip text={column.tooltip} placement="right" />}
                      </div>
                    </ListItemButton>
                  </ListItem>
                ))}
            </div>
          ))}
      </div>
    </>
  );
};

export default ColumnSelectionList;
