import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import useFiltersInGridContext from '@/components/filter-builder/hooks/useFiltersInGridContext';
import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import AlGrid, { DEFAULT_GRID_OPTIONS } from '@/components/grid/AlGrid';
import ChipArrayCellRenderer, { ChipArrayChip, IChipArrayCellRendererParams } from '@/components/grid/cells/ChipArrayCellRenderer';
import IconCellRenderer from '@/components/grid/cells/IconCellRenderer';
import { booleanStateIconConfig } from '@/components/grid/cells/IconCellRendererConfigs';
import ImageWithTitleCellRenderer, { IImageWithTitleCellRendererParams } from '@/components/grid/cells/ImageWithTitleCellRenderer';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import { StringToCount } from '@/components/grid/helpers';
import useAggregators from '@/components/grid/hooks/useAggregators';
import useColumnTypes from '@/components/grid/hooks/useColumnTypes';
import useComparisonMissing from '@/components/grid/hooks/useComparisonMissing';
import useDynamicHeight, { EXPANDED_VISIBLE_ABOVE_PX_ON_SCROLL_DOWN } from '@/components/grid/hooks/useDynamicHeight';
import useMetricColumnAggregates from '@/components/grid/hooks/useMetricColumnAggregates';
import useProfileData from '@/components/grid/hooks/useProfileData';
import useToggles from '@/components/grid/hooks/useToggles';
import {
  AlColDef,
  CELL_CLASS_CONTENTS_RIGHT,
  DEFAULT_GRID_OPTIONS_ROW_GROUPS,
  ExpandedGridContext,
  WithActiveProfileGridContext,
  WithFiltersGridContext,
} from '@/components/grid/types';
import { MetricModel } from '@/components/metrics/models/MetricModel';
import { useLayoutContext } from '@/contexts/LayoutContext';
import { useDataGroups } from '@/hooks/useDataGroups';
import useFormatting from '@/hooks/useFormatting';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { useTranslation } from '@/lib';
import { getProductLink } from '@/modules/application/amazon-utils';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey } from '@/modules/users';
import { BooleanType } from '@/types/boolean.types';
import { AvailabilityColors } from '@/types/colors.enum';
import { MetricsGraphTablePageContext } from '@/types/context-shared';
import { Card } from '@mui/material';
import type {
  BodyScrollEvent,
  CellClickedEvent,
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  SelectionChangedEvent,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { isNil } from 'lodash-es';
import { FunctionComponent, useCallback, useEffect, useMemo, useRef } from 'react';
import { ProductAvailability, ProductsWithTimeline } from '../../api/products-contracts';
import { ProductModel, SelectedProduct } from '../../models/ProductModel';
import { generateProductsTableColumnState } from './products-table.default-column-state';

interface ProductsTableProps {
  withTimeline: ProductsWithTimeline | undefined;
  pageVariables: MetricsGraphTablePageContext;
  isLoading: boolean;
  selectedProducts: SelectedProduct[];
  setSelectedProducts: (selectedProducts: SelectedProduct[]) => void;
  productsLoadingErrorMessage: string;
  isProductsLoadingError: boolean;
  filters: AlFilterModel[];
  noTopBorderRadius?: boolean;
  onGridReadyCallback?: (params: GridReadyEvent) => void;
  isExpanded: boolean;
}

interface ProductsGridContext extends ExpandedGridContext, WithFiltersGridContext, WithActiveProfileGridContext {}

const ProductsTable: FunctionComponent<ProductsTableProps> = ({
  withTimeline,
  pageVariables,
  isLoading,
  selectedProducts,
  setSelectedProducts,
  productsLoadingErrorMessage,
  isProductsLoadingError,
  noTopBorderRadius = false,
  onGridReadyCallback,
  filters,
  isExpanded,
}) => {
  const { activeProfile } = useActiveTeamContext();

  const getSettingsKey = useCallback(() => {
    if (activeProfile?.isSeller) return UserSettingKey.PRODUCTS_TABLE_SELLER_COLUMN_STATE;
    if (activeProfile?.isVendor) return UserSettingKey.PRODUCTS_TABLE_VENDOR_COLUMN_STATE;
    return UserSettingKey.PRODUCTS_TABLE_COLUMN_STATE;
  }, [activeProfile]);

  const { setColumnStateGridApi, handleColumnStateChange, applyStateToDefinitions, setIsAutoSaveEnabled } = useGridColumnState(
    getSettingsKey(),
    generateProductsTableColumnState(),
  );

  const rowData = withTimeline?.products ?? [];
  const isComparisonDataMissing = withTimeline?.isComparisonDataMissing ?? false;

  const gridApiRef = useRef<GridApi<ProductModel>>();
  const gridContextRef = useRef<ProductsGridContext>();
  const { t } = useTranslation();
  const { onSourceScroll } = useLayoutContext();
  const { heightCssValue, onGridReadyCallback: onGridReadyForDynamicHeight } = useDynamicHeight({});

  const {
    metricFieldWithChangePercentage,
    dataGroupColumnType,
    checkboxColumnType,
    salesPreviousDaysType,
    spendPreviousDaysType,
    rankChangeType,
    asinType,
    parentAsinType,
  } = useColumnTypes();
  const { formatCurrency } = useFormatting();

  // Additional data sources
  const { onGridReadyForMetricColumnAggregates, metricColumnAggregates } = useMetricColumnAggregates({
    gridApiRef,
    gridContextRef,
    metricColumnAggregates: withTimeline?.metrics ? MetricModel.arrayToMetricAggregates(withTimeline.metrics) : undefined,
  });

  const { onGridReadyForComparisonMissing } = useComparisonMissing({ gridApiRef, gridContextRef, isComparisonDataMissing });

  const { gridToggles } = pageVariables;
  const { gridToggleParams, activeMetricComparator } = useToggles<ProductModel>({
    gridApiRef,
    gridContextRef,
    gridToggles,
  });

  const { onGridReadyForFiltersInGridContext, filterCurrentStartEndDate } = useFiltersInGridContext({
    filters,
    filterDependentColumns: [ColumnId.RANK_CHANGE],
    gridApiRef,
    gridContextRef,
  });

  const { onGridReadyForProfileData } = useProfileData({ gridApiRef, gridContextRef, profile: activeProfile });

  const {
    enabledPausedArchivedStateAggFunc,
    metricDataWithPreviousDaysAggFunc,
    stringToCountAggFunc,
    targetsAggFunc,
    campaignNameAggFunc,
    adGroupsAggFunc,
    groupNameAggFunc,
    booleanAggFunc,
    metricsDataAggFunc,
    dataGroupAggFunc,
    asinAggFunc,
    parentAsinAggFunc,
  } = useAggregators();

  const { dataGroups, dataItemToInfoMap, groupIdToItemSetMap, isDataGroupsLoading } = useDataGroups([DataGroupType.PRODUCT]);

  const columnDefs: ColDef<ProductModel>[] = useMemo(() => {
    if (isDataGroupsLoading) {
      return [];
    }

    const colDefs: AlColDef<ProductModel>[] = [
      {
        colId: ColumnId.CHECKBOX,
        pinned: 'left',
        lockPosition: 'left',
        type: 'checkboxColumnType',
      },
      {
        colId: ColumnId.ID,
        headerName: 'ID',
        field: 'id',
      },
      {
        colId: ColumnId.TITLE,
        headerName: 'Title',
        field: 'title',
        cellRenderer: ImageWithTitleCellRenderer,
        cellRendererParams: (
          params: ICellRendererParams<ProductModel, unknown, WithActiveProfileGridContext>,
        ): IImageWithTitleCellRendererParams<ProductModel> => {
          const countryCode = params.context?.countryCode;
          return {
            extractImageUrl: (data) => data.imageUrl,
            extractTitle: (data) => data.title,
            extractUrl: (data) => (data?.asin && countryCode ? getProductLink(data.asin, countryCode) : undefined),
          };
        },
      },
      {
        colId: ColumnId.AVAILABILITY,
        headerName: 'Availability',
        field: 'availability',
        enableRowGroup: true,
        aggFunc: 'stringToCountAggFunc',
        cellRenderer: ChipArrayCellRenderer,
        cellRendererParams: (params: ICellRendererParams<ProductModel>): IChipArrayCellRendererParams => {
          const isAutoGroupColumn = params.colDef?.colId?.includes('ag-Grid-AutoColumn') ?? false;

          if (params.node.group && !isAutoGroupColumn) {
            // Expecting stringToCountAggFunc output
            const availabilityToCountRecord = params.value as StringToCount;

            const chipArrayChips: ChipArrayChip[] = [];
            for (const [availability, count] of Object.entries(availabilityToCountRecord).sort(([keyA], [keyB]) =>
              keyA.localeCompare(keyB),
            )) {
              chipArrayChips.push({
                value: `${t(`enums.product_availability.${availability}`)}: ${count}`,
                color: availability in AvailabilityColors ? AvailabilityColors[availability as ProductAvailability] : 'amber',
                tooltip: t(`enums.product_availability_description.${availability}`),
              });
            }

            return { chipArrayChips };
          }

          const value = params.value as ProductAvailability;
          return {
            chipArrayChips: [
              {
                value: t(`enums.product_availability.${value}`),
                color: AvailabilityColors[value],
                tooltip: t(`enums.product_availability_description.${value}`),
              },
            ],
          };
        },
      },
      {
        colId: ColumnId.PRICE_TO_PAY,
        headerName: 'Price',
        field: 'priceToPay',
        valueFormatter: (params: ValueFormatterParams) =>
          formatCurrency(params.value, { customCurrencyCode: params.context?.activeProfileCurrencyCode }),
        cellClass: CELL_CLASS_CONTENTS_RIGHT,
        type: 'numericColumn',
      },
      {
        colId: ColumnId.BEST_SELLER_RANK,
        headerName: 'Best Seller Rank',
        field: 'bestSellerRank',
        width: 95,
        cellRenderer: ChipArrayCellRenderer,
        cellRendererParams: (params: ICellRendererParams<ProductModel>): IChipArrayCellRendererParams => {
          // If agg row
          if (!params.value) {
            return {
              chipArrayChips: [],
            };
          }
          return {
            chipArrayChips: [
              {
                value: `#${params.value}`,
                color: 'sky',
                tooltip: params.data ? `#${params.value} in category "${params.data?.category}"` : undefined,
              },
            ],
          };
        },
      },
      {
        colId: ColumnId.RANK_CHANGE,
        headerName: 'BSR Trend',
        field: 'rankChange',
        type: 'rankChangeType',
      },
      {
        colId: ColumnId.ASIN,
        field: 'asin',
        type: 'asinType',
      },
      {
        colId: ColumnId.SKU,
        headerName: 'SKU',
        field: 'sku',
        enableRowGroup: true,
      },
      {
        colId: ColumnId.CATEGORY,
        headerName: 'Category',
        field: 'category',
        enableRowGroup: true,
      },
      {
        colId: ColumnId.BRAND,
        headerName: 'Brand',
        field: 'brand',
        enableRowGroup: true,
      },
      {
        colId: ColumnId.IMPRESSIONS,
        headerName: 'Ad Impressions',
        field: 'impressions',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.CLICKS,
        headerName: 'Ad Clicks',
        field: 'clicks',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.ORDERS,
        headerName: 'Ad Orders',
        field: 'orders',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.UNITS,
        headerName: 'Ad Units',
        field: 'units',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.SAME_SKU_ORDERS,
        headerName: 'Same SKU Orders',
        field: 'sso',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.SAME_SKU_SALES,
        headerName: 'Same SKU Sales',
        field: 'sss',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.OTHER_SKU_SALES,
        headerName: 'Other SKU Sales',
        field: 'oss',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.CTR,
        headerName: 'Ad CTR',
        field: 'ctr',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.CVR,
        headerName: 'Ad CVR',
        field: 'cvr',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.CPC,
        headerName: 'Ad CPC',
        field: 'cpc',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.SPEND,
        headerName: 'Ad Spend',
        field: 'spend',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.SALES,
        headerName: 'Ad Sales',
        field: 'sales',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.SALES_PREVIOUS_DAYS,
        field: 'sales',
        type: 'salesPreviousDaysType',
      },
      {
        colId: ColumnId.SPEND_PREVIOUS_DAYS,
        field: 'spend',
        type: 'spendPreviousDaysType',
      },
      {
        colId: ColumnId.ACOS,
        headerName: 'ACOS',
        field: 'acos',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.ACTC,
        headerName: 'aCTC',
        field: 'actc',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.ROAS,
        headerName: 'Ad ROAS',
        field: 'roas',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.RPC,
        headerName: 'Ad RPC',
        field: 'rpc',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.CPA,
        headerName: 'Ad CPA',
        field: 'cpa',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.AOV,
        headerName: 'Ad AOV',
        field: 'aov',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.CPM,
        headerName: 'Ad CPM',
        field: 'cpm',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
    ];

    if (activeProfile?.isSeller) {
      colDefs.push(
        {
          colId: ColumnId.SQP_STATE,
          headerName: 'SQP Enabled',
          field: 'sqpEnabled',
          enableRowGroup: true,
          cellClass: 'flex flex-row items-center justify-center',
          aggFunc: 'booleanAggFunc',

          cellRenderer: IconCellRenderer,
          cellRendererParams: (params: ICellRendererParams<ProductModel>) => ({
            state: params.value,
            config: booleanStateIconConfig,
          }),
        },
        {
          colId: ColumnId.PARENT_ASIN,
          field: 'parentAsin',
          enableRowGroup: true,
          type: 'parentAsinType',
        },
        {
          colId: ColumnId.ACOTS,
          headerName: 'TACOS', // Total Ad Cost of Sales was Ad Cost of Total Sales (ACOTS)
          field: 'acots',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
        },
        // {
        //   colId: ColumnId.TOTAL_ACTC,
        //   headerName: 'Total aCTC',
        //   field: 'totalActc',
        //   type: 'metricFieldWithChangePercentage',
        //   comparator: activeMetricComparator,
        // },
        {
          colId: ColumnId.AD_SALES_OF_TOTAL,
          headerName: 'Ad Sales % of Total',
          field: 'adSalesOfTotal',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
        },
        {
          colId: ColumnId.TOTAL_AOV,
          headerName: 'Total AOV',
          field: 'totalAov',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
        },
        {
          colId: ColumnId.ASP,
          headerName: 'ASP',
          field: 'asp',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
        },
        {
          colId: ColumnId.TOTAL_CLICKS,
          headerName: 'Total Sessions',
          field: 'totalClicks',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
        },
        {
          colId: ColumnId.TOTAL_CPA,
          headerName: 'Total CPA',
          field: 'totalCpa',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
        },
        {
          colId: ColumnId.TOTAL_CVR,
          headerName: 'Total CVR',
          field: 'totalCvr',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
        },
        {
          colId: ColumnId.TOTAL_ORDERS,
          headerName: 'Total Orders',
          field: 'totalOrders',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
        },
        {
          colId: ColumnId.ORGANIC_SALES,
          headerName: 'Organic Sales',
          field: 'organicSales',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
        },
        {
          colId: ColumnId.ORGANIC_TRAFFIC,
          headerName: 'Organic Traffic',
          field: 'organicTraffic',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
        },
        {
          colId: ColumnId.TOTAL_ROAS,
          headerName: 'Total ROAS',
          field: 'totalRoas',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
        },

        {
          colId: ColumnId.TOTAL_SALES,
          headerName: 'Total Sales',
          field: 'totalSales',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
        },
        {
          colId: ColumnId.UPS,
          headerName: 'Units Per Session',
          field: 'ups',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
        },
        {
          colId: ColumnId.UNIT_VIEW,
          headerName: 'Unit Pageview %',
          field: 'unitView',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
          width: 111,
        },
        {
          colId: ColumnId.TOTAL_UNITS,
          headerName: 'Total Units',
          field: 'totalUnits',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
        },
        {
          colId: ColumnId.TOTAL_VIEWS,
          headerName: 'Total Page Views',
          field: 'totalViews',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
        },
      );
    }

    if (activeProfile?.isVendor) {
      colDefs.push(
        {
          colId: ColumnId.VENDOR_ACOTS,
          headerName: 'TACOS',
          field: 'vendorAcots',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
        },
        {
          colId: ColumnId.VENDOR_ACOGS,
          headerName: 'ACOGS',
          field: 'vendorAcogs',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
        },
        {
          colId: ColumnId.VENDOR_AD_SALES_OF_TOTAL,
          headerName: 'Ad Sales % of Total',
          field: 'vendorAdSalesOfTotal',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
        },
        {
          colId: ColumnId.CUSTOMER_RETURNS,
          headerName: 'Units Refunded',
          field: 'customerReturns',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
        },
        {
          colId: ColumnId.VENDOR_TOTAL_VIEWS,
          headerName: 'Total Page Views',
          field: 'vendorTotalViews',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
        },
        {
          colId: ColumnId.VENDOR_TOTAL_SALES,
          headerName: 'Total Sales',
          field: 'vendorTotalSales',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
        },
        {
          colId: ColumnId.VENDOR_TOTAL_UNITS,
          headerName: 'Total Units',
          field: 'vendorTotalUnits',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
        },
        {
          colId: ColumnId.VENDOR_ORGANIC_SALES,
          headerName: 'Organic Sales',
          field: 'vendorOrganicSales',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
        },
        {
          colId: ColumnId.RETURN_RATE,
          headerName: 'Refund Rate',
          field: 'vendorReturnRate',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
        },
        {
          colId: ColumnId.VENDOR_TOTAL_ROAS,
          headerName: 'Total ROAS',
          field: 'vendorTotalRoas',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
        },
        {
          colId: ColumnId.SHIPPED_COGS,
          headerName: 'Shipped COGS',
          field: 'vendorShippedCogs',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
        },
        {
          colId: ColumnId.SHIPPED_REVENUE,
          headerName: 'Shipped Revenue',
          field: 'vendorShippedRevenue',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
        },
        {
          colId: ColumnId.SHIPPED_UNITS,
          headerName: 'Shipped Units',
          field: 'vendorShippedUnits',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
        },
        {
          colId: ColumnId.VENDOR_UNIT_VIEW,
          headerName: 'Unit Pageview %',
          field: 'vendorUnitView',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
          width: 111,
        },
        {
          colId: ColumnId.VENDOR_ASP,
          headerName: 'ASP',
          field: 'vendorAsp',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComparator,
        },
      );
    }

    dataGroups
      .filter((group) => group.type === DataGroupType.PRODUCT)
      .forEach((group) => {
        colDefs.push({
          colId: `data_group_${group.id}`,
          headerName: group.name,
          headerClass: 'accented-header data-group-product data-group-base',
          toolPanelClass: 'data-group-base data-group-product',
          headerTooltip: t('product_data_group'),
          valueGetter: (params: ValueGetterParams<ProductModel>) => {
            const itemId = params.data?.productDataItemIds.find((itemId) => groupIdToItemSetMap.get(group.id)?.has(itemId));
            return itemId ? dataItemToInfoMap.get(itemId)?.name : undefined;
          },
          type: 'dataGroupColumnType',
          enableRowGroup: true,
        });
      });

    applyStateToDefinitions(colDefs);
    return colDefs;
  }, [groupIdToItemSetMap, isDataGroupsLoading, activeProfile?.isSeller, activeProfile?.isVendor]);

  // Needed to keep the selection up to date when Tags change on the product and data is refetched
  useEffect(() => {
    if (!gridApiRef.current || !rowData || rowData.length == 0 || selectedProducts.length == 0) return;
    updateSelectedProducts(gridApiRef.current);
  }, [rowData]);

  const updateSelectedProducts = (api: GridApi<ProductModel>) => {
    if (!api || api.isDestroyed()) {
      console.warn('[ProductsTable.tsx] Grid API is not available or has been destroyed.');
      return;
    }

    try {
      const selectedRows = api.getSelectedRows();
      const selectedProductsDTO = selectedRows.map(
        (row: ProductModel): SelectedProduct => ({
          id: row.id,
          sqpEnabled: row.sqpEnabled === BooleanType.TRUE,
          asin: row.asin,
          dataGroupType: DataGroupType.PRODUCT,
          dataItemIds: row.productDataItemIds,
        }),
      );
      setSelectedProducts(selectedProductsDTO);
    } catch (error) {
      console.error('Error updating selected products:', error);
    }
  };

  const handleRowSelection = (event: SelectionChangedEvent<ProductModel>) => {
    updateSelectedProducts(event.api);
  };

  const onCellClicked = useCallback((params: CellClickedEvent<ProductModel>) => {
    if (params.column.getColId() === ColumnId.CHECKBOX) {
      const node = params.node;
      node.setSelected(!node.isSelected());
    }
  }, []);

  const customGridOptions: GridOptions<ProductModel> = useMemo(() => {
    // While values might be undefined, default values are needed so grid knows which fields to expect
    const defaultProductsGridContext: ProductsGridContext = {
      metricColumnAggregates,
      isComparisonDataMissing,
      filterCurrentStartEndDate,
      countryCode: activeProfile?.countryCode,
      ...gridToggleParams,
    };
    return {
      ...DEFAULT_GRID_OPTIONS,
      ...DEFAULT_GRID_OPTIONS_ROW_GROUPS,
      sideBar: false,
      getRowId: (params) => params.data.id.toString(),
      onSelectionChanged: handleRowSelection,
      onCellClicked: onCellClicked,
      context: defaultProductsGridContext,
      maintainColumnOrder: true,
      onBodyScroll: (event: BodyScrollEvent) => onSourceScroll(event.top),
      onColumnMoved: handleColumnStateChange,
      onColumnVisible: handleColumnStateChange,
      onColumnResized: handleColumnStateChange,
      onColumnRowGroupChanged: handleColumnStateChange,
      onSortChanged: handleColumnStateChange,
      onColumnPinned: handleColumnStateChange,
      columnTypes: {
        metricFieldWithChangePercentage,
        dataGroupColumnType,
        checkboxColumnType,
        salesPreviousDaysType,
        spendPreviousDaysType,
        rankChangeType,
        asinType,
        parentAsinType,
      },
      rowHeight: 68,
      aggFuncs: {
        metricsDataAggFunc,
        enabledPausedArchivedStateAggFunc,
        stringToCountAggFunc,
        metricDataWithPreviousDaysAggFunc,
        targetsAggFunc,
        campaignNameAggFunc,
        adGroupsAggFunc,
        groupNameAggFunc,
        booleanAggFunc,
        dataGroupAggFunc,
        asinAggFunc,
        parentAsinAggFunc,
      },
    };
  }, []);

  function onGridReady(params: GridReadyEvent) {
    setColumnStateGridApi(params.api);
    gridApiRef.current = params.api;
    onGridReadyCallback?.(params);
    onGridReadyForDynamicHeight(params);

    // Context
    gridContextRef.current = params.context;

    // Need this because for initial context set the external data sources might have not been set yet
    if (gridContextRef.current) {
      onGridReadyForMetricColumnAggregates();
      onGridReadyForComparisonMissing();
      onGridReadyForFiltersInGridContext();
      onGridReadyForProfileData();
      // Not needed for comparison unit as it isn't fetched and always exists

      gridApiRef.current.refreshHeader();
      gridApiRef.current.refreshCells({ force: true });
    }
  }

  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  useEffect(() => {
    if (gridApiRef.current?.isDestroyed()) {
      return;
    }

    // Don't autosave while colDefs are changing
    setIsAutoSaveEnabled(false);

    // Makes sure the updated colDefs are used and in the given order
    gridApiRef.current?.setGridOption('columnDefs', columnDefs);
    gridApiRef.current?.resetColumnState();

    // Give the grid time to update cols
    setTimeout(() => {
      setIsAutoSaveEnabled(true);
    }, 3000);
  }, [columnDefs]);

  return (
    <>
      {isProductsLoadingError ? (
        <Card className="flex-grow rounded-xl py-0">
          <ErrorLoadingDataAlert details={productsLoadingErrorMessage} />
        </Card>
      ) : (
        <div style={{ height: isExpanded ? `calc(100vh - ${EXPANDED_VISIBLE_ABOVE_PX_ON_SCROLL_DOWN}px)` : heightCssValue }}>
          <AlGrid
            colDefs={!isNil(dataGroups) ? columnDefs : []}
            rowData={rowData}
            gridOptions={customGridOptions}
            isLoading={isLoading}
            onGridReadyCallback={onGridReady}
            noTopBorderRadius={noTopBorderRadius}
            fitToResizeEnabled={false}
            addExtraBottomPadding={true}
          />
        </div>
      )}
    </>
  );
};

export default ProductsTable;
