import { CampaignGroupsCreateImportRow, FieldNamesCreate } from '@/modules/data-management/importers/campaign-groups/types';
import { isEnumValue, isValidNumberInputAndNotNil } from '@/modules/data-management/importers/helpers';
import { HasValidation } from '@/modules/data-management/types/feedback';
import { OptimizationPreset } from '@/modules/optimizer/components/optimization/OptimizerConfig';
import { isEmpty } from 'lodash-es';
import { CampaignGroupModel } from './CampaignGroupModel';

export class CreateImportModel implements HasValidation<CreateImportModel> {
  private campaignGroupsImportRow: CampaignGroupsCreateImportRow;
  public id: string;

  private groupNameToGroupMap: Record<string, CampaignGroupModel>;

  public validationErrors: Partial<Record<keyof CreateImportModel, string>> = {};
  public validationWarnings: Partial<Record<keyof CreateImportModel, string>> = {};

  constructor(id: string, campaignGroupsImportRow: CampaignGroupsCreateImportRow, groupNameToGroupMap: Record<string, CampaignGroupModel>) {
    this.campaignGroupsImportRow = campaignGroupsImportRow;
    this.id = id;
    this.groupNameToGroupMap = groupNameToGroupMap;

    this.validate();
  }

  private get existingGroup(): CampaignGroupModel | undefined {
    return this.groupNameToGroupMap[this.name];
  }

  public get name(): string {
    return this.campaignGroupsImportRow[FieldNamesCreate.GroupName];
  }

  public get isExistingName(): boolean {
    return this.groupNameToGroupMap[this.name] !== undefined;
  }

  public get tacos(): string | undefined {
    return this.campaignGroupsImportRow[FieldNamesCreate.TargetAcos]?.toString() ?? '';
  }

  public get prioritization(): OptimizationPreset | undefined | string {
    return this.campaignGroupsImportRow[FieldNamesCreate.Prioritization] ?? undefined;
  }

  public get optimizationEnabled(): boolean {
    return this.existingGroup?.optimizationEnabled ?? true;
  }

  public get bidFloorOff(): boolean {
    return this.existingGroup?.bidFloorOff ?? false;
  }

  public get bidCeilingOff(): boolean {
    return this.existingGroup?.bidCeilingOff ?? false;
  }

  public get bidMaxIncreaseOff(): boolean {
    return this.existingGroup?.bidMaxIncreaseOff ?? false;
  }

  public get bidMaxDecreaseOff(): boolean {
    return this.existingGroup?.bidMaxDecreaseOff ?? false;
  }

  public get placementMaxIncreaseOff(): boolean {
    return this.existingGroup?.placementMaxIncreaseOff ?? false;
  }

  public get placementMaxDecreaseOff(): boolean {
    return this.existingGroup?.placementMaxDecreaseOff ?? false;
  }

  public set name(name: string) {
    this.campaignGroupsImportRow[FieldNamesCreate.GroupName] = name;
    this.validate();
  }

  public set tacos(tacos: number | undefined) {
    this.campaignGroupsImportRow[FieldNamesCreate.TargetAcos] = tacos;
    this.validate();
  }

  public set prioritization(preset: OptimizationPreset | string) {
    this.campaignGroupsImportRow[FieldNamesCreate.Prioritization] = preset;
    this.validate();
  }

  public validate() {
    this.validationErrors = {};
    this.validationWarnings = {};

    // Group name
    if (isEmpty(this.name)) {
      this.validationErrors.name = 'Group Name is required';
    }

    // target acos
    if (isValidNumberInputAndNotNil(this.tacos)) {
      this.validationErrors.tacos = 'ACOS must be a number';
    }

    // Prioritization
    if (!isEnumValue(OptimizationPreset, this.prioritization)) {
      this.validationErrors.prioritization = 'Invalid prioritization value';
    }
  }
}
