import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import AlGrid, { DEFAULT_GRID_OPTIONS } from '@/components/grid/AlGrid';
import ImageWithTitleCellRenderer, { IImageWithTitleCellRendererParams } from '@/components/grid/cells/ImageWithTitleCellRenderer';
import { TextCellRenderer } from '@/components/grid/cells/TextCellRenderer';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import useAggregators from '@/components/grid/hooks/useAggregators';
import useColumnTypes from '@/components/grid/hooks/useColumnTypes';
import useComparisonMissing from '@/components/grid/hooks/useComparisonMissing';
import useDynamicHeight, { EXPANDED_VISIBLE_ABOVE_PX_ON_SCROLL_DOWN } from '@/components/grid/hooks/useDynamicHeight';
import useMetricColumnAggregates from '@/components/grid/hooks/useMetricColumnAggregates';
import useProfileData from '@/components/grid/hooks/useProfileData';
import useToggles from '@/components/grid/hooks/useToggles';
import { AlColDef, DEFAULT_GRID_OPTIONS_ROW_GROUPS, ExpandedGridContext, WithActiveProfileGridContext } from '@/components/grid/types';
import { MetricModel } from '@/components/metrics/models/MetricModel';
import { useLayoutContext } from '@/contexts/LayoutContext';
import { useDataGroups } from '@/hooks/useDataGroups';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { useTranslation } from '@/lib';
import { getProductLink } from '@/modules/application/amazon-utils';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { AdvertisedProductsWithTimeline } from '@/modules/products/api/products-contracts';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey, useUserContext } from '@/modules/users';
import { MetricsGraphTablePageContext } from '@/types/context-shared';
import { Card } from '@mui/material';
import type {
  BodyScrollEvent,
  CellClickedEvent,
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  SelectionChangedEvent,
  ValueGetterParams,
} from 'ag-grid-community';
import { isNil } from 'lodash-es';
import { FunctionComponent, useCallback, useEffect, useMemo, useRef } from 'react';
import { AdvertisedProductModel, SelectedAdvertisedProduct } from '../../models/AdvertisedProductModel';
import { generateAdvertisedProductsTableColumnState } from './advertised-products-table.default-column-state';

interface AdvertisedProductsTableProps {
  withTimeline: AdvertisedProductsWithTimeline | undefined;
  isLoading: boolean;
  selectedAdvertisedProducts: SelectedAdvertisedProduct[];
  setSelectedAdvertisedProducts: (selectedAdvertisedProducts: SelectedAdvertisedProduct[]) => void;
  advertisedProductsLoadingErrorMessage: string;
  isAdvertisedProductsLoadingError: boolean;
  noTopBorderRadius?: boolean;
  onGridReadyCallback?: (params: GridReadyEvent) => void;
  isExpanded: boolean;
  pageVariables: MetricsGraphTablePageContext;
}

interface AdvertisedProductsGridContext extends ExpandedGridContext, WithActiveProfileGridContext {}

const AdvertisedProductsTable: FunctionComponent<AdvertisedProductsTableProps> = ({
  withTimeline,
  isLoading,
  selectedAdvertisedProducts,
  setSelectedAdvertisedProducts,
  advertisedProductsLoadingErrorMessage,
  isAdvertisedProductsLoadingError,
  noTopBorderRadius = false,
  onGridReadyCallback,
  isExpanded,
  pageVariables,
}) => {
  const { t } = useTranslation();
  const { hasSellerProfiles } = useUserContext();
  const { setColumnStateGridApi, handleColumnStateChange, applyStateToDefinitions, setIsAutoSaveEnabled } = useGridColumnState(
    UserSettingKey.ADVERTISED_PRODUCTS_TABLE_COLUMN_STATE,
    generateAdvertisedProductsTableColumnState(),
  );

  const rowData = withTimeline?.products ?? [];
  const isComparisonDataMissing = withTimeline?.isComparisonDataMissing ?? false;

  const gridApiRef = useRef<GridApi<AdvertisedProductModel>>();
  const gridContextRef = useRef<AdvertisedProductsGridContext>();

  const { onSourceScroll } = useLayoutContext();
  const { heightCssValue, onGridReadyCallback: onGridReadyForDynamicHeight } = useDynamicHeight({});

  const {
    metricFieldWithChangePercentage,
    dataGroupColumnType,
    checkboxColumnType,
    salesPreviousDaysType,
    spendPreviousDaysType,
    rankChangeType,
    asinType,
    parentAsinType,
    enabledPausedArchivedStateColumnType,
  } = useColumnTypes();

  const { onGridReadyForMetricColumnAggregates, metricColumnAggregates } = useMetricColumnAggregates({
    gridApiRef,
    gridContextRef,
    metricColumnAggregates: withTimeline?.metrics ? MetricModel.arrayToMetricAggregates(withTimeline.metrics) : undefined,
  });

  const { onGridReadyForComparisonMissing } = useComparisonMissing({ gridApiRef, gridContextRef, isComparisonDataMissing });

  const { gridToggles } = pageVariables;
  const { gridToggleParams, activeMetricComparator } = useToggles<AdvertisedProductModel>({
    gridApiRef,
    gridContextRef,
    gridToggles,
  });

  const { activeProfile } = useActiveTeamContext();

  const { onGridReadyForProfileData } = useProfileData({ gridApiRef, gridContextRef, profile: activeProfile });

  const {
    enabledPausedArchivedStateAggFunc,
    metricDataWithPreviousDaysAggFunc,
    stringToCountAggFunc,
    targetsAggFunc,
    campaignNameAggFunc,
    adGroupsAggFunc,
    groupNameAggFunc,
    booleanAggFunc,
    metricsDataAggFunc,
    dataGroupAggFunc,
    asinAggFunc,
    parentAsinAggFunc,
  } = useAggregators();

  const { dataGroups, dataItemToInfoMap, groupIdToItemSetMap, isDataGroupsLoading } = useDataGroups([DataGroupType.PRODUCT]);

  const columnDefs: ColDef<AdvertisedProductModel>[] = useMemo(() => {
    if (isDataGroupsLoading) {
      return [];
    }

    const colDefs: AlColDef<AdvertisedProductModel>[] = [
      {
        colId: ColumnId.TITLE,
        headerName: 'Title',
        field: 'title',
        cellRenderer: ImageWithTitleCellRenderer,
        cellRendererParams: (
          params: ICellRendererParams<AdvertisedProductModel, unknown, WithActiveProfileGridContext>,
        ): IImageWithTitleCellRendererParams<AdvertisedProductModel> => {
          const countryCode = params.context?.countryCode;
          return {
            extractImageUrl: (data) => data.imageUrl,
            extractTitle: (data) => data.title,
            extractUrl: (data) => (data?.asin && countryCode ? getProductLink(data.asin, countryCode) : undefined),
          };
        },
        enableRowGroup: true,
      },
      {
        colId: ColumnId.CAMPAIGN_NAME,
        headerName: 'Campaign',
        field: 'campaignName',
        resizable: true,
        enableRowGroup: true,
        aggFunc: 'campaignNameAggFunc',
        cellRenderer: TextCellRenderer,
        width: 150,
        pinned: 'left',
      },
      {
        colId: ColumnId.CAMPAIGN_STATE,
        headerName: 'Campaign State',
        field: 'campaignState',
        type: 'enabledPausedArchivedStateColumnType',
      },
      {
        colId: ColumnId.AD_GROUP,
        headerName: 'Ad Group',
        field: 'adGroupName',
        enableRowGroup: true,
        aggFunc: 'adGroupsAggFunc',
        cellRenderer: TextCellRenderer,
        width: 100,
      },
      {
        colId: ColumnId.AD_GROUP_STATE,
        headerName: 'Ad Group State',
        field: 'adGroupState',
        type: 'enabledPausedArchivedStateColumnType',
      },
      {
        colId: ColumnId.ASIN,
        field: 'asin',
        type: 'asinType',
      },
      {
        colId: ColumnId.SKU,
        headerName: 'SKU',
        field: 'sku',
        enableRowGroup: true,
      },
      {
        colId: ColumnId.IMPRESSIONS,
        headerName: 'Impressions',
        field: 'impressions',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.CLICKS,
        headerName: 'Clicks',
        field: 'clicks',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.ORDERS,
        headerName: 'Orders',
        field: 'orders',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.UNITS,
        headerName: 'Units',
        field: 'units',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.CTR,
        headerName: 'CTR',
        field: 'ctr',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.CVR,
        headerName: 'CVR',
        field: 'cvr',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.CPC,
        headerName: 'CPC',
        field: 'cpc',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.SPEND,
        headerName: 'Spend',
        field: 'spend',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.SALES,
        headerName: 'Sales',
        field: 'sales',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.SALES_PREVIOUS_DAYS,
        field: 'sales',
        type: 'salesPreviousDaysType',
      },
      {
        colId: ColumnId.SPEND_PREVIOUS_DAYS,
        field: 'spend',
        type: 'spendPreviousDaysType',
      },
      {
        colId: ColumnId.SAME_SKU_ORDERS,
        headerName: 'Same SKU Orders',
        field: 'sso',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.SAME_SKU_SALES,
        headerName: 'Same SKU Sales',
        field: 'sss',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.OTHER_SKU_SALES,
        headerName: 'Other SKU Sales',
        field: 'oss',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.ACOS,
        headerName: 'ACOS',
        field: 'acos',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.ACTC,
        headerName: 'aCTC',
        field: 'actc',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.ROAS,
        headerName: 'ROAS',
        field: 'roas',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.RPC,
        headerName: 'RPC',
        field: 'rpc',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.CPA,
        headerName: 'CPA',
        field: 'cpa',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.AOV,
        headerName: 'AOV',
        field: 'aov',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.CPM,
        headerName: 'CPM',
        field: 'cpm',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
    ];

    if (hasSellerProfiles) {
      colDefs.push({
        colId: ColumnId.PARENT_ASIN,
        field: 'parentAsin',
        enableRowGroup: true,
        type: 'parentAsinType',
      });
    }

    dataGroups
      .filter((group) => group.type === DataGroupType.PRODUCT)
      .forEach((group) => {
        colDefs.push({
          colId: `data_group_${group.id}`,
          headerName: group.name,
          headerClass: 'accented-header data-group-product data-group-base',
          toolPanelClass: 'data-group-base data-group-product',
          headerTooltip: t('product_data_group'),
          valueGetter: (params: ValueGetterParams<AdvertisedProductModel>) => {
            const itemId = params.data?.advertisedAdvertisedProductDataItemIds.find((itemId) =>
              groupIdToItemSetMap.get(group.id)?.has(itemId),
            );
            return itemId ? dataItemToInfoMap.get(itemId)?.name : undefined;
          },
          type: 'dataGroupColumnType',
        });
      });

    applyStateToDefinitions(colDefs);

    return colDefs;
  }, [groupIdToItemSetMap, isDataGroupsLoading]);

  // Needed to keep the selection up to date when Tags change on the advertisedAdvertisedProduct and data is refetched
  useEffect(() => {
    if (!gridApiRef.current || !rowData || rowData.length == 0 || selectedAdvertisedProducts.length == 0) return;
    updateSelectedAdvertisedProducts(gridApiRef.current);
  }, [rowData]);

  const updateSelectedAdvertisedProducts = (api: GridApi<AdvertisedProductModel>) => {
    if (!api || api.isDestroyed()) {
      console.warn('[AdvertisedProductsTable.tsx] Grid API is not available or has been destroyed.');
      return;
    }

    try {
      const selectedRows = api.getSelectedRows();
      const selectedAdvertisedProductsDTO = selectedRows.map(
        (row): SelectedAdvertisedProduct => ({
          id: row.id,
          dataGroupType: DataGroupType.PRODUCT,
          dataItemIds: row.advertisedAdvertisedProductDataItemIds,
        }),
      );
      setSelectedAdvertisedProducts(selectedAdvertisedProductsDTO);
    } catch (error) {
      console.error('Error updating selected advertised products:', error);
    }
  };

  const handleRowSelection = (event: SelectionChangedEvent<AdvertisedProductModel>) => {
    updateSelectedAdvertisedProducts(event.api);
  };

  const onCellClicked = useCallback((params: CellClickedEvent<AdvertisedProductModel>) => {
    if (params.column.getColId() === ColumnId.CHECKBOX) {
      const node = params.node;
      node.setSelected(!node.isSelected());
    }
  }, []);

  const customGridOptions: GridOptions<AdvertisedProductModel> = useMemo(() => {
    // While values might be undefined, default values are needed so grid knows which fields to expect
    const defaultAdvertisedProductsGridContext: AdvertisedProductsGridContext = {
      metricColumnAggregates,
      ...gridToggleParams,
      isComparisonDataMissing,
      countryCode: activeProfile?.countryCode,
    };
    return {
      ...DEFAULT_GRID_OPTIONS,
      ...DEFAULT_GRID_OPTIONS_ROW_GROUPS,
      sideBar: false,
      getRowId: (params) => params.data.id,
      onSelectionChanged: handleRowSelection,
      onCellClicked: onCellClicked,
      context: defaultAdvertisedProductsGridContext,
      maintainColumnOrder: true,
      onBodyScroll: (event: BodyScrollEvent) => onSourceScroll(event.top),
      onColumnMoved: handleColumnStateChange,
      onColumnVisible: handleColumnStateChange,
      onColumnResized: handleColumnStateChange,
      onColumnRowGroupChanged: handleColumnStateChange,
      onSortChanged: handleColumnStateChange,
      onColumnPinned: handleColumnStateChange,
      columnTypes: {
        metricFieldWithChangePercentage,
        dataGroupColumnType,
        checkboxColumnType,
        salesPreviousDaysType,
        spendPreviousDaysType,
        rankChangeType,
        asinType,
        parentAsinType,
        enabledPausedArchivedStateColumnType,
      },
      rowHeight: 68,
      aggFuncs: {
        metricsDataAggFunc,
        enabledPausedArchivedStateAggFunc,
        stringToCountAggFunc,
        metricDataWithPreviousDaysAggFunc,
        targetsAggFunc,
        campaignNameAggFunc,
        adGroupsAggFunc,
        groupNameAggFunc,
        booleanAggFunc,
        dataGroupAggFunc,
        asinAggFunc,
        parentAsinAggFunc,
      },
    };
  }, []);

  function onGridReady(params: GridReadyEvent) {
    setColumnStateGridApi(params.api);
    gridApiRef.current = params.api;
    onGridReadyCallback?.(params);
    onGridReadyForDynamicHeight(params);

    // Context
    gridContextRef.current = params.context;

    // Need this because for initial context set the external data sources might have not been set yet
    if (gridContextRef.current) {
      onGridReadyForMetricColumnAggregates();
      onGridReadyForComparisonMissing();
      onGridReadyForProfileData();
      // Not needed for comparison unit as it isn't fetched and always exists

      gridApiRef.current.refreshHeader();
      gridApiRef.current.refreshCells({ force: true });
    }
  }

  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  return (
    <>
      {isAdvertisedProductsLoadingError ? (
        <Card className="flex-grow rounded-xl py-0">
          <ErrorLoadingDataAlert details={advertisedProductsLoadingErrorMessage} />
        </Card>
      ) : (
        <div style={{ height: isExpanded ? `calc(100vh - ${EXPANDED_VISIBLE_ABOVE_PX_ON_SCROLL_DOWN}px)` : heightCssValue }}>
          <AlGrid
            colDefs={!isNil(dataGroups) ? columnDefs : []}
            rowData={rowData}
            gridOptions={customGridOptions}
            isLoading={isLoading}
            onGridReadyCallback={onGridReady}
            noTopBorderRadius={noTopBorderRadius}
            fitToResizeEnabled={false}
            addExtraBottomPadding={true}
          />
        </div>
      )}
    </>
  );
};

export default AdvertisedProductsTable;
