import { Sparkline } from '@/components/chart/charts/Sparkline';
import { DateValuePair, METRIC_PREVIOUS_AMOUNT_OF_DAYS } from '@/components/metrics/api/metrics-contracts';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { Timezone } from '@/modules/users/types/Timezone';
import type { ICellRendererParams } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import { FunctionComponent } from 'react';
import { AlDate, DATE_FORMAT_YYYY_MM_DD } from '../../../lib/date/AlDate';

export interface IAlSparklineCellRendererParams {
  values: (number | null)[] | null;
  emptyFillValue?: number | null; // Null values will account for width, but won't be drawn
  length?: number | null;
  endDate?: string; // default is last 30d ending with yesterday
  color?: 'green' | 'orange' | 'blue';
  valueFormatter?: (value: number) => string;
}

const POINTS_TO_DISPLAY = METRIC_PREVIOUS_AMOUNT_OF_DAYS;

interface ISparklineCellRendererProps extends ICellRendererParams, IAlSparklineCellRendererParams {}

const SparklineCellRenderer: FunctionComponent<ISparklineCellRendererProps> = ({
  values,
  emptyFillValue = 0,
  endDate,
  color,
  valueFormatter,
  length,
}) => {
  if (isNil(length)) {
    length = POINTS_TO_DISPLAY;
  }

  const { activeProfile } = useActiveTeamContext();

  if (isNil(values) || values.length === 0) return null;

  if (values.every((value) => value === 0 || value === null)) return null;
  // Optionally we might want to return just a flat line:
  // return (
  //   <div className="h-full w-full flex">
  //     <div className="flex-1 border-b border-b-gray-400 mb-0.5"></div>
  //   </div>
  // );

  const timezone = activeProfile ? activeProfile.timeZone.toString() : Timezone.AmericaLosAngeles.toString();
  const usedEndDate = endDate
    ? AlDate.parseWithTimezone(endDate, DATE_FORMAT_YYYY_MM_DD, timezone)
    : AlDate.nowWithTimezone(timezone).subtractDays(1);

  const fixedSizeValueArray = unshiftArrayWithValueUntilLen(values, length, emptyFillValue);
  const dataPoints = expandWithDates(fixedSizeValueArray, usedEndDate);

  return <Sparkline data={dataPoints} color={color} valueFormatter={valueFormatter} />;
};

export default SparklineCellRenderer;

function unshiftArrayWithValueUntilLen(myArray: (number | null)[], targetLen: number, fillValue: number | null): (number | null)[] {
  const currentLength = myArray.length;

  if (currentLength < targetLen) {
    // Add fill values to the beginning if the array is shorter than targetLen
    const fillValuesToAdd = targetLen - currentLength;
    myArray.unshift(...Array(fillValuesToAdd).fill(fillValue));
  } else if (currentLength > targetLen) {
    // Remove elements from the beginning if the array is longer than targetLen
    myArray.splice(0, currentLength - targetLen);
  }

  return myArray;
}

function expandWithDates(olderToRecentValues: (number | null)[], endDate: AlDate): DateValuePair[] {
  return olderToRecentValues.map<DateValuePair>((value, index) => {
    // The last value gets the endDate, earlier values get dates going backwards
    const date = endDate.subtractDays(olderToRecentValues.length - 1 - index);
    return {
      date: date.toDefaultFormat(),
      value: value,
    };
  });
}
