export enum BooleanType {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
}

export interface NegativesBools {
  campaignNegativeExact: boolean;
  campaignNegativePhrase: boolean;
  campaignNegativeProductTarget: boolean;
  adGroupNegativeExact: boolean;
  adGroupNegativePhrase: boolean;
  adGroupNegativeProductTarget: boolean;
}
