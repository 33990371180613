import TextLink from '@/components/buttons/TextLink';
import { useAuthUserContext } from '@/modules/auth';
import { useUserContext } from '@/modules/users';
import AccountCircleRounded from '@mui/icons-material/AccountCircleRounded';
import LogoutRounded from '@mui/icons-material/LogoutRounded';
import { Card, CardContent, CardHeader, Divider, Popover, Switch } from '@mui/material';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { ColorModeContext } from '../context/ColorModeContext';
import { MainMenuButton } from './MainMenuButton';

interface UserProfileButtonProps {
  isExpanded: boolean;
}

export const UserProfileButton: FunctionComponent<UserProfileButtonProps> = ({ isExpanded }) => {
  const { mode, toggleColorMode } = useContext(ColorModeContext);
  const { isAdminModeActive } = useUserContext();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const { authUser, logOut } = useAuthUserContext();
  const { user } = useUserContext();
  const [activePathnames /*setActivePathnames*/] = useState(['/user/profile']);
  const [isRouteActive, setIsRouteActive] = useState(false);
  // const navigate = useNavigate();
  const currentLocation = useLocation();
  useEffect(() => {
    if (currentLocation.pathname) {
      setIsRouteActive(activePathnames.some((r) => currentLocation.pathname.startsWith(r)));
    }
  }, [activePathnames, currentLocation.pathname]);

  return (
    <>
      <MainMenuButton
        icon={<AccountCircleRounded></AccountCircleRounded>}
        isActive={isRouteActive}
        isExpanded={isExpanded}
        label={user?.name ?? authUser?.displayName ?? authUser?.email ?? 'Unknown'}
        onClick={handleClick}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Card title={user?.name ?? authUser?.displayName ?? authUser?.email ?? 'Unknown'} className="min-w-72 w-auto">
          <CardHeader
            // avatar={
            //   <Badge color="success" overlap="circular" variant="dot">
            //     {' '}
            //     <Avatar sx={{ bgcolor: blueGrey[500] }} aria-label="recipe">
            //       R
            //     </Avatar>
            //   </Badge>
            // }
            title={user?.name ?? authUser?.displayName ?? authUser?.email ?? 'Unknown'}
            subheader={authUser?.email}
            titleTypographyProps={{ variant: 'body1' }} // Adjust the variant as needed
          />
          <CardContent sx={{ paddingTop: 0, paddingBottom: 1, ':last-child': { paddingBottom: 0 } }}>
            <div className="flex flex-col text-sm">
              {isAdminModeActive && (
                <div className="flex w-full items-center justify-between">
                  <TextLink className={'py-2'} onClick={toggleColorMode}>
                    Dark Mode
                  </TextLink>
                  <Switch
                    className={'py-2'}
                    edge="end"
                    onChange={toggleColorMode}
                    checked={mode === 'dark'}
                    slotProps={{ input: { 'aria-labelledby': 'switch-list-label-wifi' } }}
                  />
                </div>
              )}
              {/* <TextLink className={'py-2'} to={'configuration/profile'}>
                My Settings
              </TextLink> */}
              <Divider flexItem className="my-2" />
              <TextLink
                className={'py-2 mb-2'}
                onClick={logOut}
                leftIcon={<LogoutRounded className="text-red-500" fontSize="inherit"></LogoutRounded>}
              >
                Log out
              </TextLink>
            </div>
          </CardContent>
          {/* <div className="flex w-full bg-gray-200 px-3 py-2 text-xs text-gray-500 dark:bg-gray-700 dark:text-gray-400 ">
            <div>Version 1.24.5</div>
          </div> */}
        </Card>
      </Popover>
    </>
  );
};
