import React from 'react';
import ReactDOM from 'react-dom/client';

// Determine which router to use
const isExternalLink = isOnExternalLocation(window.location);

// CSS Imports

// AG Grid CSS
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-quartz.css'; // Theme CSS
import './index.css';

// React Grid Layout
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

// Libs (Internationalization, Axios, etc..)
import { initLibs } from './lib';
initLibs(isExternalLink);

// Router comes after libs because it uses the libs (mainly Sentry)
// Sentry is before router because it wraps the router
import { RouterProvider } from 'react-router';

// Import both routers
import { router as defaultRouter } from './router/router';
import { externalRouter } from './modules/external/routes/external-routes';
import { isOnExternalLocation } from './router/window-location';

// Determine which router to use
const router = isExternalLink ? externalRouter : defaultRouter;

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
