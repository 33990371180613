import { useTranslation } from '@/lib';
import { Delete, RefreshRounded } from '@mui/icons-material';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import PauseCircleOutlineRoundedIcon from '@mui/icons-material/PauseCircleOutlineRounded';
import { Button, Card, CardContent, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { DaypartingScheduleState } from '../../api/dayparting.contracts';
import { DaypartingScheduleDayModel } from '../../types/DaypartingScheduleModel';
import DaypartingScheduleDaysEditor from '../DaypartingScheduleDaysEditor';
import { DaypartingScheduleFormModel } from './DaypartingScheduleFormModel';
import { daypartingScheduleService } from '../../api/dayparting.service';
import { toastService } from '@/services/toast.service';
import { useQueryClient } from '@tanstack/react-query';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useDeleteConfirmation } from '@/components/modals/delete-confirmation-modal/useDeleteConfirmationModal';
import DaypartingCampaignSelect from '@/modules/dayparting/components/DaypartingCampaignSelect';
import { useConfirmationModal } from '@/components/modals/confirmation-modal/useConfirmationModal';
import { invalidateGetAllDaypartingSchedulesQuery } from '../../api/dayparting.cache';

interface DaypartingScheduleFormDisplayProps {
  form: UseFormReturn<DaypartingScheduleFormModel>;
  onSubmit: (data: DaypartingScheduleFormModel) => Promise<boolean>;
  daypartingScheduleId?: number;
}

const DaypartingScheduleFormDisplay: FunctionComponent<DaypartingScheduleFormDisplayProps> = ({ form, onSubmit, daypartingScheduleId }) => {
  const { formState, handleSubmit, getValues, control, setValue } = form;
  const { errors, isSubmitting } = formState;
  const [isLoadingDeleteDaypartingSchedule, setIsLoadingDeleteDaypartingSchedule] = useState(false);
  const queryClient = useQueryClient();
  const { activeProfile } = useActiveTeamContext();

  const { ModalComponent, handleOpenModal: openDeleteConfirmationModal } = useDeleteConfirmation({
    questionText: 'Are you sure you want to delete this schedule? This action cannot be undone.',
    headerText: `Delete Schedule?`,
    onDelete: deleteSchedule,
  });

  const { ModalComponent: ResetAllModalComponent, handleOpenModal: onResetAllDaysClicked } = useConfirmationModal({
    questionText: 'Are you sure you want to reset all days? This action cannot be undone.',
    questionTitle: 'Reset All Days',
    onConfirm: () => {
      resetDays();
    },
    confirmButtonText: 'Yes, Reset',
    cancelButtonText: 'Cancel',
  });

  const { t } = useTranslation();
  function resetDays() {
    const days = getValues('days');
    const updatedDays = days.map((day) => {
      return new DaypartingScheduleDayModel({
        changes: day.changes.map((change) => {
          return {
            time: change.time,
            change: 0,
          };
        }),
        day: day.day,
      });
    });

    days.forEach((day) => {
      day.changes.forEach((change) => {
        change.change = 0;
      });
    });

    setValue('days', updatedDays);
    handleSubmit(onSubmit)();
  }

  function onDeleteClicked() {
    openDeleteConfirmationModal();
  }

  async function deleteSchedule() {
    if (!daypartingScheduleId) return;

    setIsLoadingDeleteDaypartingSchedule(true);
    const result = await daypartingScheduleService.delete({ schedule_id: daypartingScheduleId });
    if (result.isSuccess) {
      toastService.success('Dayparting Schedule deleted');

      invalidateGetAllDaypartingSchedulesQuery(queryClient, activeProfile?.id);
    }

    setIsLoadingDeleteDaypartingSchedule(false);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid gap-4">
        <Card variant="outlined" className="col-span-2">
          <CardContent>
            <div className="flex justify-between items-center">
              <div className="flex flex-1 gap-x-4 mr-6">
                <div className="flex">
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        className="min-w-60"
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        label={t('name')}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </div>
                <div className="flex">
                  <Controller
                    name="state"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormControl>
                        <InputLabel id="state-label">Status</InputLabel>
                        <Select className="min-w-44" label={'Status'} value={field.value} onChange={field.onChange}>
                          <MenuItem value={DaypartingScheduleState.Enabled}>
                            <div className="flex items-center gap-x-1">
                              <CheckCircleOutlineRoundedIcon color={'success'} fontSize={'small'} />
                              Enabled
                            </div>
                          </MenuItem>
                          <MenuItem value={DaypartingScheduleState.Paused}>
                            <div className="flex items-center gap-x-1">
                              <PauseCircleOutlineRoundedIcon color={'warning'} fontSize={'small'} />
                              Paused
                            </div>
                          </MenuItem>
                        </Select>
                        <FormHelperText>{errors.state?.message} </FormHelperText>
                      </FormControl>
                    )}
                  />
                </div>
                <div className="flex w-full max-w-2xl">
                  <Controller
                    name="campaignIds"
                    control={control}
                    render={({ field }) => (
                      <DaypartingCampaignSelect selectedCampaignIds={field.value} hideIndividualChips={true} onChange={field.onChange} />
                    )}
                  />
                </div>
              </div>

              <div className="flex shrink">
                <div>
                  <Button variant="text" color="secondary" onClick={() => onResetAllDaysClicked()} className="min-w-28 mr-2">
                    <RefreshRounded className="mr-1" />
                    Reset all
                  </Button>
                </div>

                {daypartingScheduleId !== undefined && (
                  <Button
                    startIcon={<Delete />}
                    variant="outlined"
                    color="error"
                    onClick={onDeleteClicked}
                    loading={isLoadingDeleteDaypartingSchedule}
                    className="min-w-28"
                  >
                    {t('delete')}
                  </Button>
                )}
              </div>
            </div>

            <div className="mt-4 flex w-full flex-col gap-y-2">
              <Controller
                name="days"
                control={control}
                render={({ field: { onChange, value } }) => <DaypartingScheduleDaysEditor days={value} onChange={onChange} />}
              />
            </div>
            <div className="flex items-center justify-between  ">
              <div className="flex items-center mt-2">
                <div className="flex w-4 h-4 border-2 bg-primary-600 opacity-75 rounded-full mr-2"></div>
                <div>Current time in profile timezone</div>
              </div>
              <div className="mt-2 h-6">
                <Button type="submit" variant="contained" color="primary" className="min-w-24" disabled={isSubmitting}>
                  {t('save')}
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      {ModalComponent}
      {ResetAllModalComponent}
    </form>
  );
};

export default DaypartingScheduleFormDisplay;
