import { FunctionComponent, useRef } from 'react';
import { Outlet } from 'react-router';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { ActiveTeamProvider } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserModel, UserProvider, UserSettingModel, UserSettingsProvider } from '@/modules/users';
import { ProfileModel } from '@/modules/profiles/types/ProfileModel';
import { TeamModel } from '@/modules/teams/types/TeamModel';
import { OrganizationDTO } from '@/modules/users/api/users/users.contracts';
import { CountryCode } from '@/modules/amazon-constants/types/CountryCode';
import { CurrencyCode } from '@/modules/amazon-constants/types/CurrencyCode';
import { Timezone } from '@/modules/users/types/Timezone';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ExternalProvider } from '../contexts/ExternalProvider';
import { useTheming } from '@/config/theme/useTheming';
import { ProfileType } from '@/modules/users/types/ProfileType';
import { ProfileState } from '@/modules/users/types/ProfileState';
import { PlanPriceLookupKey, PlanType } from '@/modules/plans/types/SubscriptionPlan';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60 * 10, //ms
    },
  },
});

const ExternalLayout: FunctionComponent = () => {
  const { theme } = useTheming();

  const initialUserRef = useRef<UserModel>();
  const initialTeamIdRef = useRef<number>();
  const initialProfileIdRef = useRef<string>();
  const initialUserSettingsRef = useRef<UserSettingModel[]>([]);

  // Create dummy user only once
  if (!initialUserRef.current) {
    // Create a dummy profile
    const dummyProfile = new ProfileModel({
      id: 'dummy-profile-1',
      accountId: 'dummy-account-1',
      name: 'Dummy Profile',
      type: 'dummyType' as ProfileType, // Replace with an appropriate ProfileType if available
      subType: 'dummy',
      countryCode: CountryCode.US,
      currencyCode: CurrencyCode.USD,
      dailyBudget: 100,
      timeZone: Timezone.AmericaLosAngeles,
      marketplaceId: 'dummy-marketplace',
      state: 'ACTIVE' as ProfileState, // Replace with an appropriate ProfileState if available
      createdAt: new Date().toISOString(),
      createSTVReports: false,
      sellerCentralAuthAt: null,
      vendorCentralAuthAt: null,
      adsDataStartDate: null,
      adsDataEndDate: null,
      sellingPartnerDataStartDate: null,
      sellingPartnerDataEndDate: null,
      adsAccountId: '',
      profileDataItemIds: [],
    });

    // Create a dummy organization (minimal fields)
    const dummyOrganization: OrganizationDTO = {
      id: 1,
      name: 'Dummy Organization',
      owner_id: 1,
    };

    // Create a dummy team containing the dummy profile
    const dummyTeam = new TeamModel({
      id: 1,
      name: 'Dummy Team',
      organization: dummyOrganization,
      amazonAccount: undefined,
      preferredUpdateTime: '09:00',
      preferredLastDone: '09:00',
      profiles: [dummyProfile],
      members: [],
      stripeSubscriptionStatus: 'active',
      stripeSubscriptionCancelAtPeriodEnd: false,
      stripeSubscriptionCurrentPeriodEnd: new Date().toISOString(),
      stripeSubscriptionTrialEnd: null,
      stripeSubscriptionDefaultPaymentMethod: null,
      stripeSubscriptionLastStatusChangeAt: null,
      adlabsPlan: 'FREE' as PlanType,
      adlabsPlanPriceLookupKey: 'FREE_MONTHLY' as PlanPriceLookupKey,
      currentValidPlan: 'FREE' as PlanType,
      isCustomer: false,
      isOnLegacyProfilesBasedProPlan: false,
      actualAdSpendPercentagePaid: 0,
      monthlyInvoiceCap: 0,
      fixedPriceInDollars: 0,
      spend: 0,
      estimatedMonthlySpend: 0,
      estimatedCost: 0,
    });

    // Create a dummy user with the dummy team
    const dummyUser = new UserModel({
      id: 1,
      firebaseId: 'dummy-firebase-id',
      email: 'dummy@example.com',
      name: 'Dummy',
      surname: 'User',
      phone: '1234567890',
      teams: [dummyTeam],
      isAdmin: false,
      hash: undefined,
      organizationName: dummyOrganization.name,
      organizationId: dummyOrganization.id,
    });

    initialUserRef.current = dummyUser;
    initialTeamIdRef.current = dummyTeam.id;
    initialProfileIdRef.current = dummyProfile.id;
  }

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <UserProvider initialUserRef={initialUserRef}>
          <UserSettingsProvider initialSettingsRef={initialUserSettingsRef}>
            <ActiveTeamProvider initialActiveTeamIdRef={initialTeamIdRef} initialActiveProfileIdRef={initialProfileIdRef} isExternal>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <ExternalProvider>
                    <CssBaseline />
                    <Outlet />
                  </ExternalProvider>
                </ThemeProvider>
              </StyledEngineProvider>
            </ActiveTeamProvider>
          </UserSettingsProvider>
        </UserProvider>
      </QueryClientProvider>
    </div>
  );
};

export default ExternalLayout;
