import AlGrid, { DEFAULT_GRID_OPTIONS } from '@/components/grid/AlGrid';
import AutoCompleteCellEditor, { AutoCompleteCellEditorOption } from '@/components/grid/cells/AutoCompleteCellEditor';
import { ErrorWarningWrapperCellRenderer } from '@/components/grid/cells/ErrorWarningWrapperCellRenderer';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import { AlColDef } from '@/components/grid/types';
import useCampaignToAdGroupsMappingData from '@/modules/campaign-mapping/hooks/useCampaignToAdGroupsMappingData';
import { CampaignToCampaignDataWithCampaignMappingAdGroupDataType } from '@/modules/campaign-mapping/models/CampaignMappingModel';
import { AssignImportModel } from '@/modules/optimizer/api/campaign/models/AssignImportModel';
import { CampaignGroupModel } from '@/modules/optimizer/api/campaign/models/CampaignGroupModel';
import { CreateImportModel } from '@/modules/optimizer/api/campaign/models/CreateImportModel';
import type {
  CellValueChangedEvent,
  ColDef,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellEditorParams,
  IRowNode,
  ValueFormatterParams,
} from 'ag-grid-community';
import { isEmpty } from 'lodash-es';
import { FunctionComponent, useCallback, useEffect, useMemo, useRef } from 'react';
import { getCellClass, getErrorWarningCellRendererParams } from '../../helpers';
import { getGroupOptions } from './assignHelpers';

const VISIBLE_ABOVE_PX_ON_SCROLL_DOWN = 140;

interface CampaignGroupsImportPreviewTableProps {
  rowData: AssignImportModel[];
  isLoading: boolean;
  onGridDataChanged: (api: GridApi<AssignImportModel>) => void;
  onGridReadyCallback: (params: GridReadyEvent) => void;
  groupNameToGroupMap: Record<string, CampaignGroupModel>;
  newGroupNameToCreateGroupMap: Record<string, CreateImportModel>;
}

interface CampaignGroupsAssignGridContext {
  campaignToAdGroupsMap: CampaignToCampaignDataWithCampaignMappingAdGroupDataType;
  campaignOptions: AutoCompleteCellEditorOption[];
  groupOptions: AutoCompleteCellEditorOption[];
}

const CampaignGroupsAssignPreviewTable: FunctionComponent<CampaignGroupsImportPreviewTableProps> = ({
  rowData: rowData,
  isLoading,
  onGridDataChanged,
  onGridReadyCallback,
  groupNameToGroupMap,
  newGroupNameToCreateGroupMap,
}) => {
  const gridApiRef = useRef<GridApi<AssignImportModel>>();
  const gridContextRef = useRef<CampaignGroupsAssignGridContext>();

  const { campaignToAdGroupsMap, getCampaignMappingCampaignOptions } = useCampaignToAdGroupsMappingData();

  const columnDefs: ColDef<AssignImportModel>[] = useMemo(() => {
    const colDefs: AlColDef<AssignImportModel>[] = [
      {
        colId: ColumnId.CAMPAIGN_NAME,
        headerName: 'Campaign Name',
        field: 'campaignId',
        width: 300,
        cellClass: getCellClass,
        editable: true,
        cellEditor: AutoCompleteCellEditor,
        cellEditorParams: (params: ICellEditorParams<AssignImportModel, string, CampaignGroupsAssignGridContext>) => {
          const field: keyof AssignImportModel = 'campaignId';
          const options = params.context?.campaignOptions ?? [];
          return {
            options: options,
            currentValue: options.find((option) => option.id === params.data?.[field]) ?? null,
            placeHolderText: 'Select a campaign',
          };
        },
        cellRenderer: ErrorWarningWrapperCellRenderer,
        cellRendererParams: getErrorWarningCellRendererParams,
        valueFormatter: (params: ValueFormatterParams<AssignImportModel>) => {
          return params.data?.campaignName ?? '-';
        },
      },
      {
        colId: ColumnId.GROUP_NAME,
        headerName: 'Optimization Group',
        field: 'groupId',
        width: 300,
        cellClass: getCellClass,
        editable: true,
        cellEditor: AutoCompleteCellEditor,
        cellEditorParams: (params: ICellEditorParams<AssignImportModel, string, CampaignGroupsAssignGridContext>) => {
          const field: keyof AssignImportModel = 'groupId';
          const options = params.context?.groupOptions ?? [];

          return {
            options: options,
            currentValue: options.find((option) => option.id === params.data?.[field]) ?? null,
            placeHolderText: 'Select a group',
          };
        },
        cellRenderer: ErrorWarningWrapperCellRenderer,
        cellRendererParams: getErrorWarningCellRendererParams,
        valueFormatter: (params: ValueFormatterParams<AssignImportModel>) => {
          return params.data?.groupName ?? '-';
        },
      },
    ];

    return colDefs;
  }, []);

  const updateDuplicateFlags = useCallback(() => {
    if (!gridApiRef.current) return;
    const counts: Record<string, number> = {};

    gridApiRef.current.forEachNode((node: IRowNode<AssignImportModel>) => {
      if (!node.data || !node.data.campaignName) return;
      const name = node.data.campaignName;
      counts[name] = (counts[name] || 0) + 1;
    });

    gridApiRef.current.forEachNode((node: IRowNode<AssignImportModel>) => {
      if (!node.data || !node.data.campaignName) return;
      node.data.isCampaignNameDuplicate = counts[node.data.campaignName] > 1;
    });

    gridApiRef.current.refreshCells({ columns: [ColumnId.CAMPAIGN_NAME], force: true });
  }, []);

  useEffect(() => {
    if (!gridApiRef.current || !gridContextRef.current || Object.keys(campaignToAdGroupsMap).length === 0) return;
    gridContextRef.current.campaignToAdGroupsMap = campaignToAdGroupsMap;
    gridContextRef.current.campaignOptions = getCampaignMappingCampaignOptions(campaignToAdGroupsMap, true);
    gridContextRef.current.groupOptions = getGroupOptions(groupNameToGroupMap, newGroupNameToCreateGroupMap);
    // No cell refreshing needed because default add new map row is anyway empty and so no data from map is used on load
  }, [campaignToAdGroupsMap, groupNameToGroupMap, newGroupNameToCreateGroupMap]);

  function onGridDataChangedInternal(api: GridApi<AssignImportModel>) {
    onGridDataChanged(api);
  }

  const onCellValueChanged = (event: CellValueChangedEvent<AssignImportModel>) => {
    if (event.colDef.colId === ColumnId.CAMPAIGN_NAME) {
      updateDuplicateFlags();
    }
    onGridDataChangedInternal(event.api);
  };

  function onGridReady(params: GridReadyEvent) {
    gridApiRef.current = params.api;

    gridContextRef.current = params.context;
    onGridReadyCallback(params);

    updateDuplicateFlags();
  }

  const customGridOptions: GridOptions<AssignImportModel> = useMemo(() => {
    const defaultCampaignMappingGridContext: CampaignGroupsAssignGridContext = {
      campaignToAdGroupsMap,
      campaignOptions: getCampaignMappingCampaignOptions(campaignToAdGroupsMap, true),
      groupOptions: getGroupOptions(groupNameToGroupMap, newGroupNameToCreateGroupMap),
    };
    return {
      ...DEFAULT_GRID_OPTIONS,
      context: defaultCampaignMappingGridContext,
      suppressMovableColumns: true,
      getRowId: (params) => params.data.id?.toString() ?? '',
      maintainColumnOrder: true,
      onCellValueChanged: onCellValueChanged,
      onFirstDataRendered: (event: FirstDataRenderedEvent<AssignImportModel>) => onGridDataChangedInternal(event.api),
    };
  }, []);

  return (
    <>
      <div style={{ height: `calc(100vh - ${VISIBLE_ABOVE_PX_ON_SCROLL_DOWN}px)` }}>
        <AlGrid
          colDefs={columnDefs}
          rowData={rowData && !isEmpty(campaignToAdGroupsMap) ? rowData : []}
          gridOptions={customGridOptions}
          isLoading={isLoading}
          onGridReadyCallback={onGridReady}
          noTopBorderRadius={false}
          fitToResizeEnabled={false}
          addExtraBottomPadding={true} // TODO: standardize padding under the table so action bar appears always at the same height relative to the table bottom edge
        />
      </div>
    </>
  );
};

export default CampaignGroupsAssignPreviewTable;
