import MetricSelect from '@/components/form/MetricSelect';
import { METRICS } from '@/components/metrics/MetricsConfig';
import { CommonWithSellerAndVendorMetricField } from '@/components/metrics/types/MetricField';
import { useDashboardContextValue } from '@/modules/dashboards/contexts/DashboardContextProvider';
import { EntityType } from '@/modules/dashboards/types/EntityType';
import { TextField } from '@mui/material';
import { cloneDeep } from 'lodash-es';
import { ChangeEvent, FunctionComponent, useContext, useEffect, useState } from 'react';
import { DashboardWidgetContext } from '../../dashboard-widget/DashboardWidgetContextProvider';
import EntityTypeSelect from '../../forms/EntityTypeSelect';
import ProfileSelect from '../../forms/ProfileSelect';
import { MetricWidgetConfiguration } from './MetricWidgetConfiguration';
import MetricWidgetFilters from './MetricWidgetFilters';

interface MetricWidgetConfigurationFormProps {
  configuration: MetricWidgetConfiguration;
  id: string;
}

const MetricWidgetConfigurationForm: FunctionComponent<MetricWidgetConfigurationFormProps> = ({ configuration }) => {
  const { setModifiedConfiguration } = useContext(DashboardWidgetContext);
  const dashboardProfiles = useDashboardContextValue((context) => context.dashboard?.teamProfiles);

  const [localConfiguration, setLocalConfiguration] = useState<MetricWidgetConfiguration>(cloneDeep(configuration));

  // Update the modified configuration in the context whenever localConfiguration changes
  useEffect(() => {
    localConfiguration.isConfigured = true;
    setModifiedConfiguration(cloneDeep(localConfiguration));
  }, [localConfiguration, setModifiedConfiguration]);

  const onTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLocalConfiguration((prev) => {
      if (!e.currentTarget) return prev;
      const newConfig = cloneDeep(prev);
      newConfig.title = e.currentTarget?.value || '';
      return newConfig;
    });
  };

  const onMetricChange = (metricField: '' | CommonWithSellerAndVendorMetricField) => {
    if (metricField !== '') {
      const configuredMetric = METRICS[metricField];
      setLocalConfiguration((prev) => {
        const newConfig = cloneDeep(prev);
        newConfig.metricField = metricField;
        // Optionally update the title to match the metric's title
        newConfig.title = configuredMetric?.title || prev.title;
        return newConfig;
      });
    }
  };

  const handleEntityTypeChange = (entityType: EntityType | '') => {
    if (entityType === '') return;
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.entityType = entityType;
      return newConfig;
    });
  };

  const onSelectedProfilesChanged = (selectedProfileIds: string[]) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.profilesIds = selectedProfileIds;
      return newConfig;
    });
  };

  return (
    <>
      <EntityTypeSelect
        value={localConfiguration.entityType}
        onChange={handleEntityTypeChange}
        helperText={'Select an Entity Type to filter on.'}
      />

      <TextField label="Name" value={localConfiguration.title} onChange={onTitleChange} />

      {dashboardProfiles && (
        <MetricSelect
          selectedProfiles={
            dashboardProfiles.length == 1
              ? dashboardProfiles
              : dashboardProfiles.filter((p) => localConfiguration.profilesIds.includes(p.profileId))
          }
          entityType={localConfiguration.entityType}
          value={localConfiguration.metricField}
          onChange={onMetricChange}
          helperText={'Select a Metric to display.'}
        />
      )}

      {dashboardProfiles && (
        <ProfileSelect
          profiles={dashboardProfiles}
          selectedProfileIds={localConfiguration.profilesIds}
          setSelectedProfileIds={onSelectedProfilesChanged}
        />
      )}

      <MetricWidgetFilters configuration={localConfiguration} setConfiguration={setLocalConfiguration} />
    </>
  );
};

export default MetricWidgetConfigurationForm;
