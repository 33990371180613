import { Environment } from '@/config/Environment';
import { isPrivatePath } from '@/router/router';
import { Routes } from '@/router/router-paths';
import { FirebaseError } from 'firebase/app';
import {
  AuthErrorCodes,
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  User,
} from 'firebase/auth';
import { isEmpty } from 'lodash-es';
import { useEffect, useState } from 'react';
import { app } from '../lib/firebase';

export function useFirebaseAuth() {
  const [authUser, setAuthUser] = useState<User | null>(null);
  const [authError, setAuthError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth(app);

  // if (Environment.isDev()) {
  //   self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  //   // Debug token =  3f3e153d-ca29-4293-b0ca-80a874fc1fcf
  // }

  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({ prompt: 'select_account' });

  useEffect(() => {
    auth.setPersistence(browserLocalPersistence);

    // const appCheck = initializeAppCheck(app, {
    //   provider: new ReCaptchaEnterpriseProvider('6Lf89ewoAAAAAI8kahDTSHWzf30lXnr7s5WE_wHr'),
    //   isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
    // });
  }, []);

  // Init app check
  // Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
  // site key and pass it to initializeAppCheck().

  const authStateChanged = (authState: User | null) => {
    if (!authState) {
      return;
    }

    setLoading(true);
    setAuthUser(authState);
    setLoading(false);
  };

  async function signIn(email: string, password: string): Promise<User> {
    if (isEmpty(email)) {
      setAuthError('Email cannot be empty');
      throw Error('Login Failed');
    }

    if (isEmpty(password)) {
      setAuthError('Password cannot be empty');
      throw Error('Login Failed');
    }

    try {
      setAuthError(null);
      auth.setPersistence(browserLocalPersistence);
      const authUser = await signInWithEmailAndPassword(auth, email, password);
      if (Environment.isDev()) {
        console.log({ authUser });
      }

      setAuthUser(authUser.user);
      return authUser.user;
    } catch (error) {
      console.log({ LOGIN_ERROR: error, IsFirebaseError: error instanceof FirebaseError });

      if (error instanceof FirebaseError) {
        switch (error.code) {
          case AuthErrorCodes.USER_DELETED:
            setAuthError('User not found');
            break;
          case AuthErrorCodes.INVALID_IDP_RESPONSE:
            setAuthError('Invalid credentials');
            break;
          case AuthErrorCodes.INVALID_PASSWORD:
            setAuthError('Invalid password');
            break;

          default:
            setAuthError('An unknown error occurred');
            break;
        }
      } else {
        setAuthError('An unknown error occurred');
      }
      throw Error('Login Failed');
    }
  }
  function signUp(email: string, password: string) {
    createUserWithEmailAndPassword(auth, email, password);
  }

  async function logOut(): Promise<void> {
    await signOut(auth);

    setAuthUser(null);
    setLoading(false);
    if (isPrivatePath(window.location.pathname) || window.location.pathname === `${Routes.LOGIN}?logOut=true`) {
      // Use Window.location.href instead of navigate to clear all the state
      window.location.href = Routes.LOGIN;
    }
    return;
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, authStateChanged);
    return unsubscribe;
  }, []);

  async function signInWithToken(token: string): Promise<User> {
    const authUser = await signInWithCustomToken(auth, token);
    setAuthUser(authUser.user);
    return authUser.user;
  }

  async function sendPasswordResetEmailViaFirebase(email: string): Promise<void> {
    await sendPasswordResetEmail(auth, email, {
      url: `${window.location.origin}/login?resetPassword=true`,
    });
  }

  const signInWithGoogle = () =>
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        if (credential) {
          // const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;
          // redux action? --> dispatch({ type: SET_USER, user });
          // router.push("/app");
          setAuthUser(user);
        }
      })
      .catch(() => {
        // TODO: handle error
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // The email of the user's account used.
        // const email = error.email;
        // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });

  async function autoLogin(): Promise<User | undefined> {
    await auth.authStateReady();

    if (!auth.currentUser) {
      return undefined;
    }

    setAuthUser(auth.currentUser);
    return auth.currentUser;
  }

  return {
    authUser,
    loading,
    signIn,
    signUp,
    logOut,
    signInWithGoogle,
    autoLogin,
    authError,
    signInWithToken,
    sendPasswordResetEmailViaFirebase,
  };
}
