import FilterBarCard from '@/components/cards/FilterBarCard';
import FilterControls from '@/components/filter-builder/FilterControls';
import { createProfileStatsFilters, ProfileNameFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { SearchBox } from '@/components/filter-builder/SearchBox';
import { FilterKey } from '@/components/filter-builder/types/FilterKey';
import CurrencySelect from '@/components/form/CurrencySelect';
import GridTools from '@/components/grid/components/GridTools';
import { GridToggles } from '@/components/grid/types';
import { AlDate } from '@/lib/date/AlDate';
import useAlFetchCache from '@/modules/al-fetch-cache/useAlFetchCache';
import { CurrencyCode } from '@/modules/amazon-constants/types/CurrencyCode';
import DateRangeButton from '@/modules/application/components/date-range-picker/DateRangeButton';
import ExpandToggleButton from '@/modules/application/components/ExpandToggleButton';
import { IdNameDTO } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { useUserContext } from '@/modules/users';
import { MetricsGraphTablePageContext } from '@/types/context-shared';
import { Tooltip } from '@mui/material';
import type { GridApi } from 'ag-grid-enterprise';
import { Dispatch, FunctionComponent, MutableRefObject, SetStateAction } from 'react';
import { ProfileWithMetricsModel } from '../models/ProfileWithMetricsModel';

interface ProfileStatsFilterBarProps {
  profilesWithMetrics: ProfileWithMetricsModel[] | undefined;
  gridApiRef: MutableRefObject<GridApi<ProfileWithMetricsModel> | undefined>;
  pageVariables: MetricsGraphTablePageContext;
  gridToggles: GridToggles;
  setGridToggles: Dispatch<SetStateAction<GridToggles>>;
  selectedCurrency: CurrencyCode;
  setSelectedCurrency: (currency: CurrencyCode) => void;
  onExpandTable: () => void;
  isExpanded: boolean;
}

const ProfileStatsFilterBar: FunctionComponent<ProfileStatsFilterBarProps> = ({
  profilesWithMetrics,
  gridApiRef,
  pageVariables,
  gridToggles,
  setGridToggles,
  selectedCurrency,
  setSelectedCurrency,
  onExpandTable,
  isExpanded,
}) => {
  const { filters, setFilters, setFilterValue, setFilterValues } = pageVariables;

  const { fetchCache } = useAlFetchCache();

  const teamIdName: IdNameDTO[] =
    profilesWithMetrics?.reduce((acc, profile) => {
      const id = profile.teamId.toString();

      // Check if the ID already exists in the accumulator array
      if (!acc.some((item) => item.id === id)) {
        acc.push({ id, name: profile.teamName });
      }

      return acc;
    }, [] as IdNameDTO[]) ?? [];

  const profileIdName: IdNameDTO[] =
    profilesWithMetrics?.reduce((acc, profile) => {
      const id = profile.id.toString();

      // Check if the ID already exists in the accumulator array
      if (!acc.some((item) => item.id === id)) {
        acc.push({ id, name: profile.nameWithMarket });
      }

      return acc;
    }, [] as IdNameDTO[]) ?? [];

  const availableFilters = createProfileStatsFilters(fetchCache, teamIdName, profileIdName).filter((filter) => filter.isFilterBuilderFilter);
  const defaultFilterKey = FilterKey.TEAM;

  const someProfilesHaveMissingComparison = profilesWithMetrics?.some((profile) => profile.comparisonMissing);
  const comparisonMissingMessage = someProfilesHaveMissingComparison ? 'Some profiles have missing comparison data' : null;

  //Currency
  const usedCurrencies = profilesWithMetrics?.reduce((acc, profile) => {
    if (!acc.includes(profile.currencyCode)) {
      acc.push(profile.currencyCode);
    }
    return acc;
  }, [] as CurrencyCode[]);

  // min date for date range should be earliest profile created at
  const { user } = useUserContext();

  const earliestDataStartDate = user?.teams
    .flatMap((team) => team.profiles || []) // Flatten profiles and handle missing profiles
    .map((profile) => profile.dataStartDate)
    .reduce<AlDate | undefined>((earliest, current) => {
      if (!earliest) {
        return current;
      }

      return current.isBefore(earliest) ? current : earliest;
    }, undefined);

  return (
    <FilterBarCard>
      <SearchBox
        filterKey={FilterKey.PROFILE_NAME}
        newFilterModel={() => new ProfileNameFilterModel()}
        placeholder="Search Profile"
        filters={filters}
        setFilterValue={setFilterValue}
        setFilters={setFilters}
      />

      <FilterControls filters={filters} setFilters={setFilters} availableFilters={availableFilters} defaultFilterKey={defaultFilterKey} />

      <div className="flex flex-grow"></div>
      <div className="flex h-full items-center justify-center gap-x-3">
        <ExpandToggleButton isExpanded={isExpanded} onExpandToggled={onExpandTable} />
        <DateRangeButton
          title="Range"
          filters={filters}
          setFilterValues={setFilterValues}
          tooltip="Date range used to stats"
          comparisonRangeWarning={comparisonMissingMessage}
          minDate={earliestDataStartDate?.toISO() ?? undefined}
        />
      </div>
      <Tooltip placement="left" title={'Display currency'}>
        <span>
          <CurrencySelect
            onCurrencyChange={setSelectedCurrency}
            selectedCurrency={selectedCurrency}
            optionallyAddedCurrencies={usedCurrencies}
          />
        </span>
      </Tooltip>
      <GridTools gridToggles={gridToggles} setGridToggles={setGridToggles} gridApiRef={gridApiRef} />
    </FilterBarCard>
  );
};

export default ProfileStatsFilterBar;
