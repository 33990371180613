export enum CommonMetricField {
  IMPRESSIONS = 'impressions',
  CLICKS = 'clicks',
  ORDERS = 'orders',
  CTR = 'ctr',
  CVR = 'cvr',
  CPC = 'cpc',
  SPEND = 'spend',
  SALES = 'sales',
  SAME_SKU_ORDERS = 'sso',
  SAME_SKU_SALES = 'sss',
  OTHER_SKU_SALES = 'oss',
  ACOS = 'acos',
  ACTC = 'actc',
  ROAS = 'roas',
  RPC = 'rpc',
  CPA = 'cpa',
  AOV = 'aov',
  CPM = 'cpm',
  UNITS = 'units',
}

export enum SellerMetricField {
  SELLER_ACOS = 'seller_acos', // Average Cost Of Total Sales - Total Advertising Spend / Total Sales
  // SELLER_ACTC = 'seller_actc', // Sessions (clicks) / orders
  SELLER_AD_SALES_OF_TOTAL = 'seller_ad_sales_of_total',
  SELLER_AOV = 'seller_aov',
  SELLER_ASP = 'seller_asp', // Total average selling price - Total sales / total units
  SELLER_CLICKS = 'seller_clicks',
  SELLER_CPA = 'seller_cpa',
  SELLER_CVR = 'seller_cvr', // Total conversion rate - Total orders / Total clicks (sessions)
  SELLER_ORDERS = 'seller_orders',
  SELLER_ORG_SALES = 'seller_org_sales',
  SELLER_ORG_TRAFFIC = 'seller_org_traffic',
  SELLER_ROAS = 'seller_roas',
  SELLER_SALES = 'seller_sales',
  SELLER_UNIT_SESS = 'seller_unit_sess', // Units per session (UPS) - total units / total clicks (sessions)
  SELLER_UNIT_VIEW = 'seller_unit_view',
  SELLER_UNITS = 'seller_units',
  SELLER_VIEWS = 'seller_views', // Total Page Views
}

export enum SellerMetricFieldWithoutPrefix {
  ACOS = 'acos', // Average Cost Of Total Sales - Total Advertising Spend / Total Sales
  // ACTC = 'actc', // Sessions (clicks) / orders
  AD_SALES_OF_TOTAL = 'ad_sales_of_total',
  AOV = 'aov',
  ASP = 'asp', // Total average selling price - Total sales / total units
  CLICKS = 'clicks',
  CPA = 'cpa',
  CVR = 'cvr', // Total conversion rate - Total orders / Total clicks (sessions)
  ORDERS = 'orders',
  ORG_SALES = 'org_sales',
  ORG_TRAFFIC = 'org_traffic',
  ROAS = 'roas',
  SALES = 'sales',
  UNIT_SESS = 'unit_sess', // Units per session (UPS) - total units / total clicks (sessions)
  UNIT_VIEW = 'unit_view',
  UNITS = 'units',
  VIEWS = 'views', // Total Page Views
}

export enum VendorMetricField {
  VENDOR_ACOS = 'vendor_acos',
  VENDOR_ACOGS = 'vendor_acogs',
  VENDOR_AD_SALES_OF_TOTAL = 'vendor_ad_sales_of_total',
  VENDOR_ASP = 'vendor_asp',
  VENDOR_CUSTOMER_RETURNS = 'vendor_customer_returns',
  VENDOR_VIEWS = 'vendor_glance_views', //  Changed from "Glance Views" to Total Page Views
  VENDOR_SALES = 'vendor_ordered_revenue', // aka Changed from "Ordered Revenue" to Total Sales
  VENDOR_UNITS = 'vendor_ordered_units', // Changed from "Ordered Units" to Total Units
  VENDOR_ORG_SALES = 'vendor_org_sales', // Ordered Revenue - Total Ad Sales
  VENDOR_RETURN_RATE = 'vendor_return_rate',
  VENDOR_ROAS = 'vendor_roas',
  VENDOR_SHIPPED_COGS = 'vendor_shipped_cogs',
  VENDOR_SHIPPED_REVENUE = 'vendor_shipped_revenue',
  VENDOR_SHIPPED_UNITS = 'vendor_shipped_units',
  VENDOR_UNIT_VIEW = 'vendor_unit_view', // UnitsPerView = units / pageviews
}

export enum VendorMetricFieldWithoutPrefix {
  ACOS = 'acos',
  ACOGS = 'acogs',
  AD_SALES_OF_TOTAL = 'ad_sales_of_total',
  ASP = 'asp',
  CUSTOMER_RETURNS = 'customer_returns',
  VIEWS = 'glance_views', //  Changed from "Glance Views" to Total Page Views
  SALES = 'ordered_revenue', // aka Changed from "Ordered Revenue" to Total Sales
  UNITS = 'ordered_units', // Changed from "Ordered Units" to Total Units
  ORG_SALES = 'org_sales', // Ordered Revenue - Total Ad Sales
  RETURN_RATE = 'return_rate',
  ROAS = 'roas',
  SHIPPED_COGS = 'shipped_cogs',
  SHIPPED_REVENUE = 'shipped_revenue',
  SHIPPED_UNITS = 'shipped_units',
  UNIT_VIEW = 'unit_view', // UnitsPerView = units / pageviews
}

export enum ProfileStatsSellerMetricField {
  SELLER_UNITS_REFUNDED = 'seller_units_refunded',
  SELLER_UNITS_REFUND_RATE = 'seller_units_refund_rate',
}

export enum SearchQueryPerformanceField {
  SEARCH_QUERY_SCORE = 'sqs',
  SEARCH_QUERY_VOLUME = 'sqv',
  TOTAL_QUERY_IMPRESSION_COUNT = 'tqic',
  ASIN_IMPRESSION_COUNT = 'aic',
  ASIN_IMPRESSION_SHARE = 'ais',
  TOTAL_CLICK_COUNT = 'tcc',
  TOTAL_CLICK_RATE = 'tcr',
  ASIN_CLICK_COUNT = 'acc',
  ASIN_CLICK_SHARE = 'acs',
  TOTAL_MEDIAN_CLICK_PRICE = 'tmcp',
  ASIN_MEDIAN_CLICK_PRICE = 'amcp',
  TOTAL_SAME_DAY_SHIPPING_CLICK_COUNT = 'tsdsc',
  TOTAL_ONE_DAY_SHIPPING_CLICK_COUNT = 'todsc',
  TOTAL_TWO_DAY_SHIPPING_CLICK_COUNT = 'ttdsc',
  TOTAL_CART_ADD_COUNT = 'tcac',
  TOTAL_CART_ADD_RATE = 'tcar',
  ASIN_CART_ADD_COUNT = 'acac',
  ASIN_CART_ADD_SHARE = 'acas',
  TOTAL_MEDIAN_CART_ADD_PRICE = 'tmcap',
  ASIN_MEDIAN_CART_ADD_PRICE = 'amcap',
  TOTAL_SAME_DAY_SHIPPING_CART_ADD_COUNT = 'tsdscac',
  TOTAL_ONE_DAY_SHIPPING_CART_ADD_COUNT = 'todscac',
  TOTAL_TWO_DAY_SHIPPING_CART_ADD_COUNT = 'ttdscac',
  TOTAL_PURCHASE_COUNT = 'tpc',
  TOTAL_PURCHASE_RATE = 'tpr',
  ASIN_PURCHASE_COUNT = 'apc',
  ASIN_PURCHASE_SHARE = 'aps',
  TOTAL_MEDIAN_PURCHASE_PRICE = 'tmpp',
  ASIN_MEDIAN_PURCHASE_PRICE = 'ampp',
  TOTAL_SAME_DAY_SHIPPING_PURCHASE_COUNT = 'tsdspc',
  TOTAL_ONE_DAY_SHIPPING_PURCHASE_COUNT = 'todspc',
  TOTAL_TWO_DAY_SHIPPING_PURCHASE_COUNT = 'ttdspc',
  TOTAL_CTR = 'tctr',
  ASIN_CTR = 'actr',
  ASIN_CONVERSION_RATE = 'acor',
  TOTAL_CONVERSION_RATE = 'tcor',
}

export const SQP_METRIC_CARD_FIELDS = [
  SearchQueryPerformanceField.SEARCH_QUERY_VOLUME,
  SearchQueryPerformanceField.TOTAL_QUERY_IMPRESSION_COUNT,
  SearchQueryPerformanceField.ASIN_IMPRESSION_COUNT,
  SearchQueryPerformanceField.ASIN_IMPRESSION_SHARE,
  SearchQueryPerformanceField.TOTAL_CLICK_COUNT,
  SearchQueryPerformanceField.TOTAL_CLICK_RATE,
  SearchQueryPerformanceField.ASIN_CLICK_COUNT,
  SearchQueryPerformanceField.ASIN_CLICK_SHARE,
  SearchQueryPerformanceField.TOTAL_CART_ADD_COUNT,
  SearchQueryPerformanceField.TOTAL_CART_ADD_RATE,
  SearchQueryPerformanceField.ASIN_CART_ADD_COUNT,
  SearchQueryPerformanceField.ASIN_CART_ADD_SHARE,
  SearchQueryPerformanceField.TOTAL_PURCHASE_COUNT,
  SearchQueryPerformanceField.TOTAL_PURCHASE_RATE,
  SearchQueryPerformanceField.ASIN_PURCHASE_COUNT,
  SearchQueryPerformanceField.ASIN_PURCHASE_SHARE,
  SearchQueryPerformanceField.TOTAL_CTR,
  SearchQueryPerformanceField.ASIN_CTR,
  SearchQueryPerformanceField.ASIN_CONVERSION_RATE,
  SearchQueryPerformanceField.TOTAL_CONVERSION_RATE,
];

export type CommonWithSellerAndVendorMetricField = CommonMetricField | SellerMetricField | VendorMetricField;

export type MetricField =
  | CommonMetricField
  | SellerMetricField
  | VendorMetricField
  | ProfileStatsSellerMetricField
  | SearchQueryPerformanceField;

export type MetricAggregates = Record<MetricField, { current: number; previous?: number }>;

export function stringToSellerMetricFieldString(key: string): string {
  return 'seller_' + key;
}

export function stringToVendorMetricFieldString(key: string): string {
  return 'vendor_' + key;
}

export function isValidMetricFieldKey(key: string): boolean {
  return (
    Object.values(CommonMetricField).includes(key as CommonMetricField) ||
    Object.values(SellerMetricField).includes(key as SellerMetricField) ||
    Object.values(VendorMetricField).includes(key as VendorMetricField) ||
    Object.values(ProfileStatsSellerMetricField).includes(key as ProfileStatsSellerMetricField) ||
    Object.values(SearchQueryPerformanceField).includes(key as SearchQueryPerformanceField)
  );
}

export const vendorFieldToSellerFieldMap: Partial<Record<MetricField, SellerMetricField | ProfileStatsSellerMetricField>> = {
  [VendorMetricField.VENDOR_ACOS]: SellerMetricField.SELLER_ACOS,
  [VendorMetricField.VENDOR_AD_SALES_OF_TOTAL]: SellerMetricField.SELLER_AD_SALES_OF_TOTAL,
  [VendorMetricField.VENDOR_ASP]: SellerMetricField.SELLER_ASP,
  [VendorMetricField.VENDOR_ORG_SALES]: SellerMetricField.SELLER_ORG_SALES,
  [VendorMetricField.VENDOR_ROAS]: SellerMetricField.SELLER_ROAS,
  [VendorMetricField.VENDOR_SALES]: SellerMetricField.SELLER_SALES,
  [VendorMetricField.VENDOR_UNITS]: SellerMetricField.SELLER_UNITS,
  [VendorMetricField.VENDOR_VIEWS]: SellerMetricField.SELLER_VIEWS,
  [VendorMetricField.VENDOR_UNIT_VIEW]: SellerMetricField.SELLER_UNIT_VIEW,
  [VendorMetricField.VENDOR_CUSTOMER_RETURNS]: ProfileStatsSellerMetricField.SELLER_UNITS_REFUNDED,
  [VendorMetricField.VENDOR_RETURN_RATE]: ProfileStatsSellerMetricField.SELLER_UNITS_REFUND_RATE,
};
