import InfoMessage from '@/components/feedback/InfoMessage';
import PopoverLikePopper from '@/components/PopoverLikePopper';
import { useHelperComponents } from '@/hooks/useHelperComponents';
import { sleep } from '@/lib/api/api-utils';
import { invalidateAll_productsWithTimelineQueryKeys } from '@/modules/products/api/products-service';
import { SelectedProduct } from '@/modules/products/models/ProductModel';
import { toastService } from '@/services/toast.service';
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { Dispatch, FunctionComponent, RefObject, SetStateAction, useState } from 'react';
import { CurrentAndMaxCount } from '../api/search-query-performance-contracts';
import { searchQueryPerformanceService } from '../api/search-query-performance-service';

interface SearchQueryPerformancePopoverProps {
  buttonRef: RefObject<HTMLButtonElement>;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  selectedItems: SelectedProduct[];
  currentAndMaxCount: CurrentAndMaxCount;
}

export const SearchQueryPerformancePopover: FunctionComponent<SearchQueryPerformancePopoverProps> = ({
  buttonRef,
  isOpen,
  setIsOpen,
  selectedItems,
  currentAndMaxCount,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { getExternalLinkComponent } = useHelperComponents();
  const queryClient = useQueryClient();

  const handlePopoverClose = async () => {
    setIsOpen(false);
    await sleep(200);
  };

  async function onAssignClicked() {
    setIsLoading(true);

    const asins = selectedItems.map((item) => item.asin);
    const res = await searchQueryPerformanceService.addAsinsToSQP(asins);

    if (res.isSuccess) {
      toastService.success(`Assignments added successfully`);
      invalidateAll_productsWithTimelineQueryKeys(queryClient);
    } else {
      toastService.error(`Error adding assignments: '${res.message}'`);
    }

    setIsLoading(false);
    handlePopoverClose();
  }

  async function onRemoveClicked() {
    setIsLoading(true);

    const asins = selectedItems.map((item) => item.asin);
    const res = await searchQueryPerformanceService.removeAsinsFromSQP(asins);

    if (res.isSuccess) {
      toastService.success(`Assignments removed successfully`);
      invalidateAll_productsWithTimelineQueryKeys(queryClient);
    } else {
      toastService.error(`Error removing assignments: '${res.message}'`);
    }

    setIsLoading(false);
    handlePopoverClose();
  }

  const selectedAlreadyAssignedCount = selectedItems.filter((item) => item.sqpEnabled).length;
  const selectedNotAssignedCount = selectedItems.filter((item) => !item.sqpEnabled).length;

  const willBeOverMaxAfterAssign = selectedNotAssignedCount + currentAndMaxCount.currentCount > currentAndMaxCount.maxCount;
  const canAssign = selectedNotAssignedCount > 0 && !willBeOverMaxAfterAssign;

  return (
    <PopoverLikePopper open={isOpen} anchorEl={buttonRef.current} onClose={handlePopoverClose} style={{ width: 500 }}>
      <DialogTitle className="text-md">Manage ASINs in SQP Reports</DialogTitle>
      <DialogContent>
        <Typography className="mb-2">Search Query Performance Reports update weekly on Wednesdays with scheduled reports.</Typography>
        <Typography>
          You can assign up to {currentAndMaxCount.maxCount} ASINs and modify them anytime. When adding a new ASIN, a backfill of 12 weeks
          will take place during your next daily sync.
          {getExternalLinkComponent('https://help.adlabs.app/en/articles/47-search-query-performance-reports', 'Learn more', false)}
        </Typography>

        {willBeOverMaxAfterAssign && (
          <InfoMessage
            content={
              <>
                Cannot assign more than {currentAndMaxCount.maxCount} ASINs. Current count: {currentAndMaxCount.currentCount}
              </>
            }
          />
        )}
        {selectedAlreadyAssignedCount == selectedItems.length && (
          <InfoMessage content={<>All selected items already have an assignment.</>} />
        )}
        <DialogActions className="mt-4">
          <Button onClick={handlePopoverClose} variant={'text'}>
            Cancel
          </Button>

          {selectedAlreadyAssignedCount > 0 && (
            <Button onClick={onRemoveClicked} loadingPosition="start" variant="contained" color="error" disabled={isLoading}>
              Remove from {selectedAlreadyAssignedCount} product{selectedAlreadyAssignedCount > 1 ? 's' : ''}
            </Button>
          )}

          {selectedNotAssignedCount > 0 && (
            <Button onClick={onAssignClicked} loadingPosition="start" variant="contained" disabled={isLoading && canAssign}>
              Assign to {selectedNotAssignedCount} product{selectedNotAssignedCount > 1 ? 's' : ''}
            </Button>
          )}
        </DialogActions>
      </DialogContent>
    </PopoverLikePopper>
  );
};
