import { SecondaryMenu, SecondaryMenuHeader, SecondaryMenuItemGroup, SecondaryMenuLayout } from '@/modules/application';
import { Outlet } from 'react-router';
import { Environment } from '@/config/Environment';
import { DATA_MANAGEMENT_CONFIGURATION_SUB_MENU_ITEMS } from '@/modules/data-management/configuration/data-management-configuration-sub-menu-items';
import { SETTING_CONFIGURATION_SUB_MENU_ITEMS } from '@/modules/settings';
import { BILLING_CONFIGURATION_SUB_MENU_ITEMS } from '@/modules/settings/configuration/setting-configuration-sub-menu-items';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useUserContext } from '@/modules/users';

export function ConfigurationLayout() {
  const { activeTeam } = useActiveTeamContext();
  const { user, isAdminModeActive } = useUserContext();

  const isUserOwnerOfTeam = activeTeam && user && activeTeam?.isUserOwner(user.id);

  return (
    <SecondaryMenuLayout
      secondaryMenu={
        <>
          <SecondaryMenu>
            <SecondaryMenuHeader>Configuration</SecondaryMenuHeader>
            {SETTING_CONFIGURATION_SUB_MENU_ITEMS.map((smi) => {
              return <SecondaryMenuItemGroup header={smi.label} key={smi.label} menuItems={smi.children ?? []} />;
            })}

            {(isUserOwnerOfTeam || isAdminModeActive) &&
              BILLING_CONFIGURATION_SUB_MENU_ITEMS.map((smi) => {
                return <SecondaryMenuItemGroup header={smi.label} key={smi.label} menuItems={smi.children ?? []} />;
              })}

            {Environment.isDev() &&
              DATA_MANAGEMENT_CONFIGURATION_SUB_MENU_ITEMS.map((smi) => {
                return <SecondaryMenuItemGroup header={smi.label} key={smi.label} menuItems={smi.children ?? []} />;
              })}
          </SecondaryMenu>
        </>
      }
    >
      <Outlet />
    </SecondaryMenuLayout>
  );
}
