import { assertUnhandledCase } from '@/modules/application/utils';
import { ImportType } from '../api/importer.contracts';
import { CampaignGroupsImport } from '../importers/campaign-groups/campaign-groups-import';
import { CampaignMappingImport } from '../importers/campaign-mapping/campaign-mapping-import';
import { ImportDefinition } from './import-definition';

export class ImportDefinitionFactory {
  public static create(type: ImportType): ImportDefinition {
    switch (type) {
      case ImportType.CAMPAIGN_MAPPING:
        return new CampaignMappingImport();
      case ImportType.CAMPAIGN_GROUPS:
        return new CampaignGroupsImport();
      default:
        assertUnhandledCase(type);
    }
  }

  public static getAllImportDefinitions(): ImportDefinition[] {
    const definitions: ImportDefinition[] = [];
    for (const type in ImportType) {
      definitions.push(this.create(type as ImportType));
    }

    return definitions;
  }
}
