import { DashboardWidgetType } from '@/modules/dashboards/types/DashboardWidgetType';
import { BarChartRounded } from '@mui/icons-material';
import { v4 } from 'uuid';
import { IDashboardWidgetCatalogItem } from '../../../types/IDashboardWidgetCatalogItem';
import { BarGraphWidgetConfiguration } from './BarGraphWidgetConfiguration';

export const BAR_GRAPH_WIDGET_CATALOG_ITEM: IDashboardWidgetCatalogItem<BarGraphWidgetConfiguration> = {
  title: 'Bar Graph',
  description: 'Shows a Bar Graph of up to 4 Metrics.',
  icon: <BarChartRounded />,
  id: v4(),
  type: DashboardWidgetType.BarGraphWidget,
  configuration: new BarGraphWidgetConfiguration(),
  layout: {
    h: 6,
    w: 11,
    i: v4(),
    x: 0,
    y: 0,
    maxH: 16,
    maxW: 16,
    minH: 4,
    minW: 4,
  },
};
