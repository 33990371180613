import { useQuery } from '@tanstack/react-query';
import { isNil, throttle, isEmpty } from 'lodash-es';
import { useState } from 'react';
import { useDashboardContextValue } from '../contexts/DashboardContextProvider';
import { dashboardDataService } from '../api/dashboard/data/dashboard-data.service';
import { DashboardModel } from '../types/DashboardModel';
import { ApiResponse } from '@/lib/api/api-response';
import { DashboardCalculatedDataModel } from '../types/DashboardCalculatedDataModel';
import { externalDashboardService } from '@/modules/external/api/external-dashboard.service';
import { useExternalContext } from '@/modules/external/hooks/useExternalContext';
import { AlDate } from '@/lib/date/AlDate';

export const generateDashboardQueryKey = (dashboard: DashboardModel | undefined) => {
  return [dashboard ? dashboard.dto.id : 'unknown', 'DASHBOARD_DATA'];
};

// TODO: Fix never use warnings
export function useDashboardQueries() {
  const { dashboardShareToken } = useExternalContext();

  const { dashboard, setWidgetIdsRequestingData, widgetIdsRequestingData, isExternal, dates, comparisonDates } = useDashboardContextValue(
    (context) => ({
      dates: context.dates,
      comparisonDates: context.comparisonDates,
      dashboard: context.dashboard,
      dashboardDateFilters: context.dashboardDateFilters,
      setWidgetIdsRequestingData: context.setWidgetIdsRequestingData,
      widgetIdsRequestingData: context.widgetIdsRequestingData,
      saveDashboard: context.saveDashboard,
      dashboardVersion: context.dashboardVersion,
      isExternal: context.isExternal,
    }),
  );
  const [isLoading, setIsLoading] = useState(true);
  // const previousVersion = useRef(dashboardVersion);

  const { data: dashboardData, refetch } = useQuery({
    queryKey: generateDashboardQueryKey(dashboard),
    queryFn: async () => {
      if (!dashboard) {
        throw new Error('Dashboard not found');
      }

      let result: ApiResponse<DashboardCalculatedDataModel>;
      if (isExternal) {
        const filterStartDateOffsetInDays = Math.round(dates[0].diffRelative(AlDate.now(), 'days'));
        const filterEndDateOffsetInDays = Math.round(dates[1].diffRelative(AlDate.now(), 'days'));
        const filterCompareStartDateOffsetInDays = Math.round(comparisonDates[0].diffRelative(AlDate.now(), 'days'));
        const filterCompareEndDateOffsetInDays = Math.round(comparisonDates[1].diffRelative(AlDate.now(), 'days'));

        if (!isNil(dashboardShareToken) && !isEmpty(dashboardShareToken)) {
          result = await externalDashboardService.getDataByToken(dashboardShareToken, {
            filterStartDateOffsetInDays,
            filterEndDateOffsetInDays,
            filterCompareStartDateOffsetInDays,
            filterCompareEndDateOffsetInDays,
          });

          if (result.httpResponseCode === 404) {
            console.error('External dashboard not found');
            throw new Error('External dashboard not found');
          }
        } else {
          throw new Error('External dashboard token not found');
        }
      } else {
        result = await dashboardDataService.getById(dashboard.dto.id);
      }
      setIsLoading(false);
      setWidgetIdsRequestingData(new Set<string>());

      if (result.isSuccess) {
        return result.payload;
      } else {
        throw new Error('Error loading dashboard data');
      }
    },
    enabled: !isNil(dashboard),
  });

  // Throttle the refetch function
  const throttledRefetchDashboardData = throttle(refetch, 2500, {
    leading: true,
    trailing: false,
  });

  const refetchForWidgetWithId = (widgetId: string) => {
    setWidgetIdsRequestingData((prev) => {
      if (prev.has(widgetId)) {
        return prev;
      } else {
        return new Set([...prev, widgetId]);
      }
    });
    throttledRefetchDashboardData();
  };

  function getDataForWidgetWithId(widgetId: string) {
    return dashboardData?.getWidgetData(widgetId);
  }

  function refetchDashboardData() {
    // set all widgets loading
    setWidgetIdsRequestingData(new Set(dashboard?.dto.widgets.map((w) => w.id) || []));

    throttledRefetchDashboardData();
  }

  return {
    isLoading,
    dashboardData,
    refetchForWidgetWithId,
    throttledRefetchDashboardData,
    widgetIdsRequestingData,
    getDataForWidgetWithId,
    refetchDashboardData,
  };
}
