import i18n from '@/lib/i18n/i18nConfig';
import { AutoAwesomeMotion } from '@mui/icons-material';
import { purple } from 'tailwindcss/colors';
import { ImportType } from '../../api/importer.contracts';
import { ImportDefinition } from '../../types/import-definition';
import { CampaignGroupsImportModal } from './CampaignGroupsImportModal';

export class CampaignGroupsImport implements ImportDefinition {
  title = i18n.t('campaign_groups');
  description = i18n.t('campaign_groups_import_description');
  icon = (<AutoAwesomeMotion sx={{ fontSize: 40 }} />);
  iconColor = purple[500];
  type = ImportType.CAMPAIGN_GROUPS;
  templateFileUrl = '/importers/campaign-groups/campaign_group_bulk_upload_template_v1.xlsx';
  component = CampaignGroupsImportModal;
}
