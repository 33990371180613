import { isBaseWidgetConfigurationEqual } from '@/modules/dashboards/lib/areWidgetsEqual';
import { FunctionComponent, memo, useState } from 'react';
import { IDashboardWidget } from '../../../types/IDashboardWidget';
import DashboardWidgetConfigurationButton from '../../dashboard-widget/DashboardWidgetConfigurationButton';
import DashboardWidgetConfigurationDrawer from '../../dashboard-widget/DashboardWidgetConfigurationDrawer';
import DashboardWidgetContent from '../../dashboard-widget/DashboardWidgetContent';
import DashboardWidgetProvider from '../../dashboard-widget/DashboardWidgetContextProvider';
import { HorizontalDividerWidgetConfiguration } from './HorizontalDividerWidgetConfiguration';
import HorizontalDividerWidgetConfigurationForm from './HorizontalDividerWidgetConfigurationForm';

interface HorizontalDividerWidgetProps extends IDashboardWidget<HorizontalDividerWidgetConfiguration> {
  configurationId: string;
}

const HorizontalDividerWidget: FunctionComponent<HorizontalDividerWidgetProps> = ({ configuration, id }) => {
  const [isMouseOnWidget, setIsMouseOnWidget] = useState(false);

  const fontSizeClass = (fontSize: string) => {
    switch (fontSize) {
      case 'small':
        return 'text-xs';
      case 'medium':
        return 'text-sm';
      case 'large':
        return 'text-lg';
      case 'xlarge':
        return 'text-xl';
      default:
        return 'text-sm';
    }
  };

  const fontWeightClass = (fontIsBold: boolean) => {
    return fontIsBold ? 'font-bold' : '';
  };

  const lineThicknessClass = (lineThickness: number) => {
    switch (lineThickness) {
      case 1:
        return 'border-t';
      case 2:
        return 'border-t-2';
      case 4:
        return 'border-t-4';
      default:
        return 'border-t';
    }
  };

  return (
    <DashboardWidgetProvider widgetId={id ?? 'unknown'} isLoading={false} isConfigured={configuration.isConfigured}>
      <DashboardWidgetContent onMouseChange={setIsMouseOnWidget}>
        <div className="flex h-full items-center justify-center">
          <hr className={`mx-2 flex-grow ${lineThicknessClass(configuration.lineThickness)} border-gray-300`} />
          <span className={`text-center text-gray-700 ${fontSizeClass(configuration.fontSize)} ${fontWeightClass(configuration.fontIsBold)}`}>
            {configuration.title}
          </span>
          <hr className={`mx-2 flex-grow ${lineThicknessClass(configuration.lineThickness)} border-gray-300`} />
        </div>
        <DashboardWidgetConfigurationButton
          isMouseOnWidget={isMouseOnWidget}
          showDeleteButton={false}
          showDuplicateButton={false}
        ></DashboardWidgetConfigurationButton>
      </DashboardWidgetContent>
      <DashboardWidgetConfigurationDrawer
        widgetName={`${configuration.title?.substring(0, 35)} ${configuration.title.length > 35 ? '...' : ''}`}
      >
        <HorizontalDividerWidgetConfigurationForm configuration={configuration} id={id} />
      </DashboardWidgetConfigurationDrawer>
    </DashboardWidgetProvider>
  );
};

export default memo(HorizontalDividerWidget, isBaseWidgetConfigurationEqual);
