import { FunctionComponent, ReactNode } from 'react';
import DashboardWidgetDragHandle from './DashboardWidgetDragHandle';
import { useDynamicHandleSize } from '../../hooks/useDynamicHandleSize';
import { useDashboardContextValue } from '../../contexts/DashboardContextProvider';

interface DashboardWidgetTopBarProps {
  children: ReactNode;
  isMouseOnWidget?: boolean;
}

const DashboardWidgetTopBar: FunctionComponent<DashboardWidgetTopBarProps> = ({ children, isMouseOnWidget }) => {
  const { containerRef, handleSize } = useDynamicHandleSize();
  const { isExternal } = useDashboardContextValue((context) => ({
    isExternal: context.isExternal,
  }));

  return (
    <div ref={containerRef} className="flex justify-between items-center relative">
      {isMouseOnWidget && isExternal === false && <DashboardWidgetDragHandle size={handleSize} />}
      {children}
    </div>
  );
};

export default DashboardWidgetTopBar;
