import { NO_CHANGE } from './no_change';

// Shared with targets
export enum EnabledPausedArchivedState {
  ENABLED = 'ENABLED',
  PAUSED = 'PAUSED',
  ARCHIVED = 'ARCHIVED',
}

export const STATE_OPTIONS = [
  { value: NO_CHANGE, label: 'No change' },
  { value: EnabledPausedArchivedState.ENABLED, label: 'Enabled' },
  { value: EnabledPausedArchivedState.PAUSED, label: 'Paused' },
  { value: EnabledPausedArchivedState.ARCHIVED, label: 'Archived' },
];
