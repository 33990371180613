import { isNil } from 'lodash-es';
import { ApiResponseCode } from './api-response-code';

export class ApiResponse<T> {
  public responseCode: ApiResponseCode;
  public payload: T;
  public message: string;
  public httpResponseCode: number;

  static UnknownErrorResponse<T>(): ApiResponse<T> {
    return new ApiResponse<T>({
      responseCode: ApiResponseCode.CUSTOM_ERROR,
      payload: {} as T,
      httpResponseCode: 0,
    });
  }

  static responseWithPayload<T>(payload: T, httpResponseCode: number): ApiResponse<T> {
    return new ApiResponse<T>({
      responseCode: ApiResponseCode.SUCCESS,
      payload,
      httpResponseCode,
    });
  }

  static responseWithCodeAndMessage<T>({
    message,
    code,
    httpResponseCode = 0,
  }: {
    message: string;
    code: ApiResponseCode;
    httpResponseCode?: number;
  }): ApiResponse<T> {
    return new ApiResponse<T>({
      message: message,
      responseCode: code,
      payload: {} as T,
      httpResponseCode,
    });
  }

  static responseWithCode<T>(code: ApiResponseCode, httpResponseCode = 0): ApiResponse<T> {
    return new ApiResponse<T>({
      responseCode: code,
      payload: {} as T,
      httpResponseCode,
    });
  }

  get isSuccess(): boolean {
    return this.responseCode == ApiResponseCode.SUCCESS;
  }

  constructor({
    payload,
    message,
    responseCode,
    httpResponseCode,
  }: {
    payload: T;
    message?: string;
    responseCode: ApiResponseCode;
    httpResponseCode: number;
  }) {
    this.responseCode = responseCode;
    this.httpResponseCode = httpResponseCode;

    if (!isNil(payload)) {
      this.payload = payload;
    } else {
      this.payload = {} as T;
    }

    if (!isNil(message)) {
      this.message = message;
    } else {
      this.message = '';
    }
  }

  processPayload<T1>(process: (payload: T) => T1): ApiResponse<T1> {
    try {
      if (this.isSuccess) {
        return ApiResponse.responseWithPayload<T1>(process(this.payload), 200);
      } else {
        return ApiResponse.responseWithCodeAndMessage<T1>({
          message: this.message,
          code: this.responseCode,
          httpResponseCode: this.httpResponseCode,
        });
      }
    } catch (error) {
      console.error(error);

      return ApiResponse.UnknownErrorResponse<T1>();
    }
  }
}
