import { AlMultiSelectOptionModel } from '@/components/filter-builder/models/AlMultiSelectOptionModel';
import { AlMultiSelect } from '@/components/form/AlMultiSelect';
import { FunctionComponent } from 'react';
import useOrgProfiles from '../hooks/useOrgProfiles';

interface DataGroupsProfileSelectProps {
  selectedProfileIds: string[];
  setSelectedProfileIds: React.Dispatch<React.SetStateAction<string[]>>;
}

const DataGroupsProfileSelect: FunctionComponent<DataGroupsProfileSelectProps> = ({ selectedProfileIds, setSelectedProfileIds }) => {
  const { orgProfiles, isLoading: isLoadingOrgProfiles, isFetching: isFetchingOrgProfiles } = useOrgProfiles();

  const profileSelectOptions = orgProfiles?.map((p) => {
    return {
      id: p.id,
      name: `${p.name} (${p.countryCode})`,
    } as AlMultiSelectOptionModel;
  });

  return (
    <AlMultiSelect
      width={'100%'}
      options={profileSelectOptions}
      selectedOptionIds={selectedProfileIds}
      setSelectedOptionIds={setSelectedProfileIds}
      label="Profiles"
      placeholderText="Select Profiles"
      isLoading={isLoadingOrgProfiles || isFetchingOrgProfiles}
    />
  );
};

export default DataGroupsProfileSelect;
