import { FilterDTO } from '@/components/filter-builder/api/filters-contracts';
import { CommonMetricField, MetricField } from '@/components/metrics/types/MetricField';
import { EntityType } from '@/modules/dashboards/types/EntityType';
import { GroupByCategory } from '@/modules/dashboards/types/GroupByCategory';
import { GroupByField } from '@/modules/dashboards/types/GroupByField';
import { CampaignAdType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { v4 } from 'uuid';
import { IDashboardWidgetBaseConfiguration } from '../../../types/IDashboardWidgetBaseConfiguration';

export class PieGraphWidgetConfiguration implements IDashboardWidgetBaseConfiguration {
  id: string;
  title = 'Pie Graph';
  isConfigured: boolean = false;
  entityType = EntityType.PROFILE;
  campaignGroupByCategory = GroupByCategory.PROPERTY;
  campaignGroupByValue: number | GroupByField = GroupByField.AD_TYPE;
  campaignAdType = CampaignAdType.BRANDS;
  targetsGroupByCategory = GroupByCategory.PROPERTY;
  targetsGroupByValue: number | GroupByField = GroupByField.AD_TYPE;
  placementGroupByCategory = GroupByCategory.PROPERTY;
  placementGroupByValue: number | GroupByField = GroupByField.PLACEMENT_TYPE;
  productGroupByCategory = GroupByCategory.PROPERTY;
  productGroupByValue: number | GroupByField = GroupByField.ASIN;
  searchTermGroupByValue: number | GroupByField = GroupByField.AD_TYPE;
  searchTermGroupByCategory = GroupByCategory.PROPERTY;
  selectedMetric: MetricField = CommonMetricField.SALES;
  profilesIds: string[] = [];

  campaignFilters: FilterDTO[] = [];
  placementFilters: FilterDTO[] = [];
  targetFilters: FilterDTO[] = [];
  productFilters: FilterDTO[] = [];
  searchTermFilters: FilterDTO[] = [];

  constructor() {
    this.id = v4();
  }
}
