import { useDashboardContextValue } from '@/modules/dashboards/contexts/DashboardContextProvider';
import { EntityType } from '@/modules/dashboards/types/EntityType';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { Typography } from '@mui/material';
import { cloneDeep } from 'lodash-es';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { DashboardWidgetContext } from '../../dashboard-widget/DashboardWidgetContextProvider';
import DashboardWidgetRichTextTitleEditor from '../../dashboard-widget/DashboardWidgetRichTextTitleEditor';
import EntityTypeSelect from '../../forms/EntityTypeSelect';
import GroupBySelect, { TableWidgetGroupByOptionSelectOption } from '../../forms/GroupBySelect';
import ProfileSelect from '../../forms/ProfileSelect';
import { TableWidgetConfiguration } from './TableWidgetConfiguration';
import TableWidgetFilters from './TableWidgetFilters';
import MultipleMetricSelect from '@/components/form/MultipleMetricSelect';
import {
  CommonMetricField,
  CommonWithSellerAndVendorMetricField,
  MetricField,
  SellerMetricField,
  VendorMetricField,
} from '@/components/metrics/types/MetricField';
import { useTranslation } from '@/lib';

interface TableWidgetConfigurationFormProps {
  configuration: TableWidgetConfiguration;
  id: string;
}

const TableWidgetConfigurationForm: FunctionComponent<TableWidgetConfigurationFormProps> = ({ configuration }) => {
  const { setModifiedConfiguration } = useContext(DashboardWidgetContext);
  const dashboardProfiles = useDashboardContextValue((context) => context.dashboard?.teamProfiles);
  const { t } = useTranslation();
  const [localConfiguration, setLocalConfiguration] = useState<TableWidgetConfiguration>(cloneDeep(configuration));

  useEffect(() => {
    setModifiedConfiguration(cloneDeep(localConfiguration));
  }, [localConfiguration]);

  const handleTitleChange = (html: string) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.title = html || '';
      return newConfig;
    });
    setModifiedConfiguration(localConfiguration);
  };

  const handleEntityTypeChange = (entityType: '' | EntityType) => {
    if (entityType === '') return;
    setLocalConfiguration((prev) => {
      prev.entityType = entityType;

      return cloneDeep(prev);
    });
    setModifiedConfiguration(localConfiguration);
  };

  const handleCampaignGroupByChange = (groupByOption: TableWidgetGroupByOptionSelectOption) => {
    setLocalConfiguration((prev) => {
      prev.campaignGroupByValue = groupByOption.value;
      prev.campaignGroupByCategory = groupByOption.category;

      return cloneDeep(prev);
    });
  };

  const handleTargetsGroupByChange = (groupByOption: TableWidgetGroupByOptionSelectOption) => {
    setLocalConfiguration((prev) => {
      prev.targetsGroupByValue = groupByOption.value;
      prev.targetsGroupByCategory = groupByOption.category;

      return cloneDeep(prev);
    });
  };

  const handlePlacementGroupByChange = (groupByOption: TableWidgetGroupByOptionSelectOption) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.placementGroupByValue = groupByOption.value;
      newConfig.placementGroupByCategory = groupByOption.category;
      return newConfig;
    });
  };

  const handleProductGroupByChange = (groupByOption: TableWidgetGroupByOptionSelectOption) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.productGroupByValue = groupByOption.value;
      newConfig.productGroupByCategory = groupByOption.category;
      return newConfig;
    });
  };

  const handleSearchTermGroupByChange = (groupByOption: TableWidgetGroupByOptionSelectOption) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.searchTermGroupByValue = groupByOption.value;
      newConfig.searchTermGroupByCategory = groupByOption.category;
      return newConfig;
    });
  };

  const onSelectedProfilesChanged = (selectedProfileIds: string[]) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.profilesIds = selectedProfileIds;
      return newConfig;
    });
  };

  const handleSelectedMetricsChange = (selectedMetrics: MetricField[]) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.selectedMetrics = selectedMetrics as CommonWithSellerAndVendorMetricField[];
      return newConfig;
    });
  };

  const availableMetrics = new Map<string, MetricField[]>([[t('ad_metrics'), Object.values(CommonMetricField)]]);

  if (localConfiguration.entityType === EntityType.PROFILE || localConfiguration.entityType === EntityType.PRODUCT) {
    if (dashboardProfiles?.some((p) => p.isSellerCentralConnected)) {
      availableMetrics.set(t('total_metrics'), Object.values(SellerMetricField));
    }

    if (dashboardProfiles?.some((p) => p.isVendorCentralConnected)) {
      availableMetrics.set(t('total_metrics'), Object.values(VendorMetricField));
    }
  }

  if (!localConfiguration) return null;

  return (
    <>
      <EntityTypeSelect
        value={localConfiguration.entityType}
        onChange={handleEntityTypeChange}
        helperText={'Select an Entity Type to filter on.'}
      />

      <DashboardWidgetRichTextTitleEditor content={localConfiguration.title} onContentChange={handleTitleChange} />

      {dashboardProfiles && (
        <ProfileSelect
          profiles={dashboardProfiles}
          selectedProfileIds={localConfiguration.profilesIds}
          setSelectedProfileIds={onSelectedProfilesChanged}
        />
      )}

      {localConfiguration.entityType === EntityType.CAMPAIGN && (
        <GroupBySelect
          entityType={localConfiguration.entityType}
          value={localConfiguration.campaignGroupByValue}
          onChange={handleCampaignGroupByChange}
          dataGroupType={DataGroupType.CAMPAIGN}
        />
      )}

      {localConfiguration.entityType === EntityType.TARGET && (
        <GroupBySelect
          entityType={localConfiguration.entityType}
          value={localConfiguration.targetsGroupByValue}
          onChange={handleTargetsGroupByChange}
          dataGroupType={DataGroupType.TARGET}
        />
      )}

      {localConfiguration.entityType === EntityType.PLACEMENT && (
        <GroupBySelect
          entityType={localConfiguration.entityType}
          value={localConfiguration.placementGroupByValue}
          onChange={handlePlacementGroupByChange}
          dataGroupType={DataGroupType.CAMPAIGN}
        />
      )}

      {localConfiguration.entityType === EntityType.PRODUCT && (
        <GroupBySelect
          entityType={localConfiguration.entityType}
          value={localConfiguration.productGroupByValue}
          dataGroupType={DataGroupType.PRODUCT}
          onChange={handleProductGroupByChange}
        />
      )}

      {localConfiguration.entityType === EntityType.SEARCH_TERM && (
        <GroupBySelect
          entityType={localConfiguration.entityType}
          value={localConfiguration.searchTermGroupByValue}
          dataGroupType={DataGroupType.SEARCHTERM}
          onChange={handleSearchTermGroupByChange}
        />
      )}

      <div>
        <div className="text-sm">Metrics</div>
        <Typography variant="caption">Select the metrics to display</Typography>

        <MultipleMetricSelect
          availableMetrics={availableMetrics}
          selectedMetrics={localConfiguration.selectedMetrics}
          onChange={handleSelectedMetricsChange}
          maxSelectedMetrics={100}
        />
      </div>

      <TableWidgetFilters configuration={localConfiguration} setConfiguration={setLocalConfiguration} />
    </>
  );
};

export default TableWidgetConfigurationForm;
