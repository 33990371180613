import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import AlGrid, { DEFAULT_GRID_OPTIONS } from '@/components/grid/AlGrid';
import AutoCompleteCellEditor, { AutoCompleteCellEditorOption } from '@/components/grid/cells/AutoCompleteCellEditor';
import ButtonGroupCellRenderer from '@/components/grid/cells/ButtonGroupCellRenderer';
import EditableCellRenderer from '@/components/grid/cells/EditableCellRenderer';
import { ErrorWarningWrapperCellRenderer, IErrorWarningCellRendererParams } from '@/components/grid/cells/ErrorWarningWrapperCellRenderer';
import MultipleChoiceCellRenderer, {
  MultipleChoiceCellRendererOption,
  MultipleChoiceUpdatedValue,
} from '@/components/grid/cells/MultipleChoiceCellRendererOptions';
import { ITextCellRendererParams, TextCellRenderer } from '@/components/grid/cells/TextCellRenderer';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import RowActionButton from '@/components/grid/components/RowActionButton';
import useColDefsFunctions from '@/components/grid/hooks/useColDefsFunctions';
import { AlColDef } from '@/components/grid/types';
import { TailwindColorVariant } from '@/config/theme/color.type';
import { useLayoutContext } from '@/contexts/LayoutContext';
import useFormatting from '@/hooks/useFormatting';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { useHelperComponents } from '@/hooks/useHelperComponents';
import { useTranslation } from '@/lib/i18n/useTranslate';
import useBidLimits from '@/modules/amazon-constants/hooks/useBidLimits';
import { getErrorMessage } from '@/modules/application/utils';
import { BiddingMethod } from '@/modules/campaign-mapping/api/campaign-mapping-contracts';
import useCampaignToAdGroupsMappingData from '@/modules/campaign-mapping/hooks/useCampaignToAdGroupsMappingData';
import { CampaignToCampaignDataWithCampaignMappingAdGroupDataType } from '@/modules/campaign-mapping/models/CampaignMappingModel';
import { CampaignMappingModelWithValidation } from '@/modules/campaign-mapping/models/CampaignMappingModelWithValidation';
import { CampaignAdType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { TargetEntityExtendedType, TargetEntityType } from '@/modules/targeting/api/targets-contracts';
import { UserSettingKey } from '@/modules/users';
import { toastService } from '@/services/toast.service';
import { COLOR_BG_ERROR, COLOR_BG_WARNING, getErrorWarningCellClass, TargetEntityTypeColors } from '@/types/colors.enum';
import { RemoveCircleOutline } from '@mui/icons-material';
import { Card } from '@mui/material';
import type {
  CellClassParams,
  CellClickedEvent,
  CellValueChangedEvent,
  ColDef,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellEditorParams,
  ICellRendererParams,
  IRowNode,
  ValueFormatterParams,
  ValueParserParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { isEmpty, isNil, isNumber, round } from 'lodash-es';
import { FunctionComponent, useCallback, useEffect, useMemo, useRef } from 'react';
import { generateCampaignMappingImportPreviewTableColumnState } from './campaign-mapping-import-preview-table.default-column-state';

const VISIBLE_ABOVE_PX_ON_SCROLL_DOWN = 140;

interface CampaignMappingImportPreviewTableProps {
  rowData: CampaignMappingModelWithValidation[];
  externalFilters: AlFilterModel[];
  isLoading: boolean;
  onGridDataChanged: (api: GridApi<CampaignMappingModelWithValidation>) => void;
  campaignMappingLoadingErrorMessage: string;
  isCampaignMappingLoadingError: boolean;
  noTopBorderRadius?: boolean;
  onGridReadyCallback?: (params: GridReadyEvent) => void;
}

interface CampaignMappingGridContext {
  campaignToAdGroupsMap: CampaignToCampaignDataWithCampaignMappingAdGroupDataType;
}

const CampaignMappingImportPreviewTable: FunctionComponent<CampaignMappingImportPreviewTableProps> = ({
  rowData: rowData,
  isLoading,
  onGridDataChanged,
  campaignMappingLoadingErrorMessage,
  isCampaignMappingLoadingError,
  noTopBorderRadius = false,
  onGridReadyCallback,
  externalFilters,
}) => {
  const { setColumnStateGridApi, handleColumnStateChange, applyStateToDefinitions, setIsAutoSaveEnabled } = useGridColumnState(
    UserSettingKey.CAMPAIGN_MAPPING_TABLE_COLUMN_STATE,
    generateCampaignMappingImportPreviewTableColumnState(),
  );
  const gridApiRef = useRef<GridApi<CampaignMappingModelWithValidation>>();
  const gridContextRef = useRef<CampaignMappingGridContext>();

  const { onSourceScroll } = useLayoutContext();
  const { t } = useTranslation();
  const { formatCurrency } = useFormatting();
  const { toastWarnWithSetMessages } = useHelperComponents();

  const { getCampaignAdTypeCellRendererParams } = useColDefsFunctions();

  const { campaignToAdGroupsMap, getCampaignMappingCampaignOptions } = useCampaignToAdGroupsMappingData();

  const { getClampedBidWithWarnings } = useBidLimits();

  function updateWithMultipleChoiceValues(
    params: ICellRendererParams<CampaignMappingModelWithValidation>,
    updatedValues: MultipleChoiceUpdatedValue<CampaignMappingModelWithValidation>[],
  ) {
    if (!params.node.data) return;

    const updatedData = params.node.data as CampaignMappingModelWithValidation & Record<string, unknown>;
    for (const updatedValue of updatedValues) {
      if (updatedValue.id in updatedData) {
        updatedData[updatedValue.id as keyof typeof updatedData] = updatedValue.selected;
      }
    }

    params.api.refreshCells({ rowNodes: [params.node] });
    onGridDataChangedInternal(params.api);
  }

  function getCellClass(params: CellClassParams<CampaignMappingModelWithValidation>) {
    const field = params.colDef?.field as keyof CampaignMappingModelWithValidation;

    const isError = !isNil(params.data?.validationErrors[field]);
    const isWarning = !isNil(params.data?.validationWarnings[field]);

    return getErrorWarningCellClass(isError, isWarning);
  }

  function getCellClassMultiField(
    params: CellClassParams<CampaignMappingModelWithValidation>,
    fields: (keyof CampaignMappingModelWithValidation)[],
  ) {
    let cellClasses = 'flex items-center w-full bg-opacity-50 px-1';
    for (const field of fields) {
      if (params.data?.validationErrors?.[field]) {
        cellClasses += ` ${COLOR_BG_ERROR}`;
        break;
      } else if (params.data?.validationWarnings?.[field]) {
        cellClasses += ` ${COLOR_BG_WARNING}`;
        break;
      }
    }

    return cellClasses;
  }

  const emptyCellRenderer = { component: () => <div className="h-full flex items-center pb-1">–</div> };

  const getCampaignOptions = (params: ICellEditorParams<CampaignMappingModelWithValidation>): AutoCompleteCellEditorOption[] => {
    const campaignToAdGroupsMap: CampaignToCampaignDataWithCampaignMappingAdGroupDataType | undefined = params.context?.campaignToAdGroupsMap;
    return getCampaignMappingCampaignOptions(campaignToAdGroupsMap);
  };

  const getAdGroupOptions = (
    params: ICellEditorParams<CampaignMappingModelWithValidation>,
    campaignIdField: 'sourceCampaignId' | 'destinationCampaignId',
  ): AutoCompleteCellEditorOption[] => {
    const campaignToAdGroupsMap: CampaignToCampaignDataWithCampaignMappingAdGroupDataType = params.context?.campaignToAdGroupsMap;

    if (!campaignToAdGroupsMap || Object.keys(campaignToAdGroupsMap).length === 0) {
      return [];
    }

    const selectedCampaignId = params.data?.[campaignIdField];
    const adGroups = selectedCampaignId ? (campaignToAdGroupsMap[selectedCampaignId]?.adGroups ?? []) : [];

    return adGroups
      .map((adGroup) => ({
        id: adGroup.id,
        label: adGroup.isNameDuplicate ? `${adGroup.id} - ${adGroup.name}` : adGroup.name,
        canHide: adGroup.mapCount > 0,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  function getErrorWarningCellRendererParams(params: IErrorWarningCellRendererParams<CampaignMappingModelWithValidation>) {
    const field = params.colDef?.field as keyof CampaignMappingModelWithValidation;
    return {
      CellRenderer: EditableCellRenderer,
      cellRendererParams: params,
      errorMessage: field ? params.data?.validationErrors?.[field] : undefined,
      warningMessage: field ? params.data?.validationWarnings?.[field] : undefined,
    };
  }

  const columnDefs: ColDef<CampaignMappingModelWithValidation>[] = useMemo(() => {
    const colDefs: AlColDef<CampaignMappingModelWithValidation>[] = [
      {
        headerName: 'Source',
        colId: ColumnId.SOURCE_GROUP,
        children: [
          {
            colId: ColumnId.CAMPAIGN_NAME,
            headerName: 'Source Campaign',
            field: 'sourceCampaignId',
            width: 150,
            cellClass: getCellClass,
            editable: true,
            cellEditor: AutoCompleteCellEditor,
            cellEditorParams: (params: ICellEditorParams<CampaignMappingModelWithValidation>) => {
              const field = 'sourceCampaignId'; // TODO: add to all these type keyof CampaignMappingModelWithValidation
              const options = getCampaignOptions(params);
              return {
                options: options,
                currentValue: options.find((option) => option.id === params.data?.[field]) ?? null,
                placeHolderText: 'Select a campaign',
              };
            },
            cellRenderer: ErrorWarningWrapperCellRenderer,
            cellRendererParams: (params: IErrorWarningCellRendererParams<CampaignMappingModelWithValidation>) => {
              return getErrorWarningCellRendererParams(params);
            },
            valueFormatter: (params: ValueFormatterParams<CampaignMappingModelWithValidation>) => {
              return params.data?.sourceCampaignName ?? '-';
            },
          },
          {
            colId: ColumnId.AD_GROUP,
            headerName: 'Source Ad Group',
            field: 'sourceAdGroupId',
            width: 130,
            cellClass: getCellClass,
            cellEditor: AutoCompleteCellEditor,
            cellRenderer: ErrorWarningWrapperCellRenderer,
            editable: true,
            cellEditorParams: (params: ICellEditorParams<CampaignMappingModelWithValidation>) => {
              const field = 'sourceAdGroupId';
              const options = getAdGroupOptions(params, 'sourceCampaignId');
              return {
                options: options,
                currentValue: options.find((option) => option.id === params.data?.[field]) ?? null,
                placeHolderText: 'Select an ad group',
              };
            },
            cellRendererParams: (params: IErrorWarningCellRendererParams<CampaignMappingModelWithValidation>) => {
              return getErrorWarningCellRendererParams(params);
            },
            valueFormatter: (params: ValueFormatterParams<CampaignMappingModelWithValidation>) => {
              return params.data?.sourceAdGroupName ?? '–';
            },
          },
        ],
      },
      {
        headerName: 'Destination',
        colId: ColumnId.DESTINATION_GROUP,
        children: [
          {
            colId: ColumnId.CAMPAIGN_NAME_DESTINATION,
            headerName: 'Destination Campaign',
            field: 'destinationCampaignId',
            cellClass: getCellClass,
            width: 150,
            cellEditor: AutoCompleteCellEditor,
            cellRenderer: ErrorWarningWrapperCellRenderer,
            editable: true,
            cellEditorParams: (params: ICellEditorParams<CampaignMappingModelWithValidation>) => {
              const field = 'destinationCampaignId';
              const options = getCampaignOptions(params);
              return {
                options: options,
                currentValue: options.find((option) => option.id === params.data?.[field]) ?? null,
                placeHolderText: 'Select a campaign',
              };
            },
            cellRendererParams: (params: IErrorWarningCellRendererParams<CampaignMappingModelWithValidation>) => {
              return getErrorWarningCellRendererParams(params);
            },
            valueFormatter: (params: ValueFormatterParams<CampaignMappingModelWithValidation>) => {
              return params.data?.destinationCampaignName ?? '–';
            },
            valueSetter: (params: ValueSetterParams<CampaignMappingModelWithValidation>) => {
              params.data.destinationCampaignId = params.newValue;
              // Refresh the whole row
              if (params.node) {
                params.api.refreshCells({
                  rowNodes: [params.node],
                  force: true,
                });
              }

              // Return true to indicate that the value has been successfully set
              return true;
            },
          },
          {
            colId: ColumnId.AD_GROUP_DESTINATION,
            headerName: 'Destination Ad Group',
            field: 'destinationAdGroupId',
            cellClass: getCellClass,
            width: 130,
            cellEditor: AutoCompleteCellEditor,
            cellRenderer: ErrorWarningWrapperCellRenderer,
            editable: true,
            cellEditorParams: (params: ICellEditorParams<CampaignMappingModelWithValidation>) => {
              const field = 'sourceAdGroupId';
              const options = getAdGroupOptions(params, 'sourceCampaignId');
              return {
                options: getAdGroupOptions(params, 'destinationCampaignId'),
                currentValue: options.find((option) => option.id === params.data?.[field]) ?? null,
                placeHolderText: 'Select an ad group',
              };
            },
            cellRendererParams: (params: IErrorWarningCellRendererParams<CampaignMappingModelWithValidation>) => {
              return getErrorWarningCellRendererParams(params);
            },
            valueFormatter: (params: ValueFormatterParams<CampaignMappingModelWithValidation>) => {
              return params.data?.destinationAdGroupName ?? '–';
            },
          },
          {
            colId: ColumnId.CAMPAIGN_AD_TYPE,
            headerName: 'Campaign Ad Type',
            field: 'destinationCampaignAdType',
            width: 100,
            cellClass: 'bg-slate-400 bg-opacity-10 border-none outline-none',
            cellRenderer: TextCellRenderer,
            cellRendererSelector: (params: ICellRendererParams<CampaignMappingModelWithValidation>) => {
              if (!params.data?.destinationCampaignAdType) {
                return emptyCellRenderer;
              }
              return undefined;
            },
            cellRendererParams: getCampaignAdTypeCellRendererParams,
          },
          {
            colId: ColumnId.ENTITY_TYPE,
            headerName: 'Ad Group Target Type',
            field: 'destinationAdGroupEntityType',
            width: 100,
            cellClass: 'bg-slate-400 bg-opacity-10 border-none outline-none',
            cellRendererSelector: (params: ICellRendererParams<CampaignMappingModelWithValidation>) => {
              if (!params.data?.destinationAdGroupEntityType) {
                return emptyCellRenderer;
              }
              return undefined;
            },
            cellRenderer: TextCellRenderer,
            cellRendererParams: (): ITextCellRendererParams => {
              return {
                valueToString: (key: string) => (key ? t(`enums.bidding_entity_short.${key}`) : '-'),
                valueToColor: (key: string) => TargetEntityTypeColors[key as TargetEntityExtendedType] ?? TailwindColorVariant.GREEN,
                valueToTooltip: (key: string) => t(`enums.bidding_entity.${key}`),
              };
            },
          },
        ],
      },
      {
        headerName: 'Starting Bid',
        colId: ColumnId.STARTING_BID_GROUP,
        children: [
          {
            colId: ColumnId.BID_METHOD,
            headerName: 'Bid Method',
            field: 'biddingMethod',
            cellClass: getCellClass,
            width: 100,
            editable: true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
              values: [BiddingMethod.ADLABS, BiddingMethod.CPC_PLUS, BiddingMethod.CPC_MINUS, BiddingMethod.CPC_TIMES, BiddingMethod.CUSTOM],
            },
            cellRenderer: ErrorWarningWrapperCellRenderer,
            cellRendererParams: (params: IErrorWarningCellRendererParams<CampaignMappingModelWithValidation>) => {
              return getErrorWarningCellRendererParams(params);
            },
            valueFormatter: (params: ValueFormatterParams<CampaignMappingModelWithValidation>) => {
              const hasTranslation = Object.values(BiddingMethod).includes(params.value as BiddingMethod);
              return hasTranslation ? t(`enums.bidding_method.${params.value}`) : params.value;
            },
            valueSetter: (params: ValueSetterParams<CampaignMappingModelWithValidation>) => {
              if (params.newValue == params.oldValue) return false;

              params.data.biddingMethod = params.newValue;
              if (params.newValue != BiddingMethod.ADLABS) {
                toastService.info("Don't forget to check that Bidding Method Value is set correctly");
              }
              if (params.node) {
                params.api.refreshCells({
                  rowNodes: [params.node],
                  force: true,
                });
              }

              return true;
            },
          },
          {
            colId: ColumnId.BID_METHOD_VALUE,
            headerName: 'Custom Value',
            field: 'biddingMethodValue',
            width: 100,
            cellClass: getCellClass,
            editable: (params) => !(isNil(params.data?.biddingMethodValue) && params.data?.biddingMethod == BiddingMethod.ADLABS),
            valueFormatter: (params: ValueFormatterParams<CampaignMappingModelWithValidation>) => {
              if (!params.data) return '';

              const biddingMethod = params.data.biddingMethod;
              const biddingMethodValue = params.data.biddingMethodValue;
              switch (biddingMethod) {
                case BiddingMethod.CPC_MINUS:
                  if (!isNumber(params.value)) return params.value;
                  return formatCurrency(params.value);
                case BiddingMethod.CPC_PLUS:
                  if (!isNumber(params.value)) return params.value;
                  return formatCurrency(params.value);
                case BiddingMethod.CPC_TIMES:
                  return params.value;
                case BiddingMethod.CUSTOM:
                  if (!isNumber(params.value)) return params.value;
                  return formatCurrency(params.value);
                default:
                  return isNumber(biddingMethodValue) ? biddingMethodValue.toString() : (biddingMethodValue ?? '');
              }
            },
            cellRenderer: ErrorWarningWrapperCellRenderer,
            cellRendererParams: (params: IErrorWarningCellRendererParams<CampaignMappingModelWithValidation>) => {
              const field = params.colDef?.field as keyof CampaignMappingModelWithValidation;
              return {
                CellRenderer: EditableCellRenderer,
                cellRendererParams: {
                  ...params,
                  isEditable: !(isNil(params.data?.biddingMethodValue) && params.data?.biddingMethod == BiddingMethod.ADLABS),
                },
                errorMessage: params.data?.validationErrors?.[field],
                warningMessage: params.data?.validationWarnings?.[field],
              };
            },
            valueSetter: (params: ValueSetterParams<CampaignMappingModelWithValidation>) => {
              try {
                if ((params.newValue == '' || isNil(params.newValue)) && params.data.biddingMethod == BiddingMethod.ADLABS) {
                  params.data.biddingMethodValue = null;
                  return true;
                }

                const newValue = Math.abs(parseFloat(params.newValue));
                if (newValue > 0) {
                  params.data.biddingMethodValue = newValue;
                  const nodeData = params.node?.data;
                  if (!params.node || !nodeData) return false; // Something is wrong

                  return true;
                }
              } catch (e) {
                console.error(e);
              }

              toastService.error('Invalid value entered');
              console.error('Invalid value entered: ', params.newValue);

              return false;
            },
          },
          {
            colId: ColumnId.BID_FLOOR,
            headerName: 'Bid Floor',
            field: 'bidFloor',
            width: 100,
            cellClass: getCellClass,
            editable: true,
            valueFormatter: (params: ValueFormatterParams<CampaignMappingModelWithValidation>) => {
              return isNumber(params.value) ? formatCurrency(params.value) : params.value;
            },
            cellRenderer: ErrorWarningWrapperCellRenderer,
            cellRendererParams: (params: IErrorWarningCellRendererParams<CampaignMappingModelWithValidation>) => {
              return getErrorWarningCellRendererParams(params);
            },
            valueParser: (params: ValueParserParams<CampaignMappingModelWithValidation>) => {
              if (!Object.values(CampaignAdType).includes(params.data?.destinationCampaignAdType as CampaignAdType)) {
                toastService.error("Can't set value before valid destination campaign is set");
                return params.oldValue;
              }

              if (params.newValue == '' || isNil(params.newValue)) {
                return null;
              }

              try {
                const newValue = round(parseFloat(params.newValue), 2);

                if (isNaN(newValue)) {
                  toastService.error('Invalid new value entered: ' + params.newValue);
                  return params.oldValue;
                }

                const warnings = new Set<string>();
                const clampedValue = getClampedBidWithWarnings(
                  newValue,
                  params.data?.destinationCampaignAdType as CampaignAdType,
                  params.data?.destinationCampaignIsVideo,
                  params.data?.destinationCampaignBudgetAmount,
                  warnings,
                );

                if (warnings.size > 0) {
                  toastWarnWithSetMessages(warnings);
                  return clampedValue;
                }

                return newValue;
              } catch (e: unknown) {
                toastService.error(getErrorMessage(e));
              }

              // Revert to old when invalid
              return params.oldValue;
            },
          },
          {
            colId: ColumnId.BID_CEILING,
            headerName: 'Bid Ceiling',
            field: 'bidCeiling',
            width: 100,
            cellClass: getCellClass,
            editable: true,
            valueFormatter: (params: ValueFormatterParams<CampaignMappingModelWithValidation>) => {
              return isNumber(params.value) ? formatCurrency(params.value) : params.value;
            },
            cellRenderer: ErrorWarningWrapperCellRenderer,
            cellRendererParams: (params: IErrorWarningCellRendererParams<CampaignMappingModelWithValidation>) => {
              return getErrorWarningCellRendererParams(params);
            },
            valueParser: (params: ValueParserParams<CampaignMappingModelWithValidation>) => {
              if (!Object.values(CampaignAdType).includes(params.data?.destinationCampaignAdType as CampaignAdType)) {
                toastService.error("Can't set value before valid destination campaign is set");
                return params.oldValue;
              }

              if (params.newValue == '' || isNil(params.newValue)) {
                return null;
              }

              try {
                const newValue = round(parseFloat(params.newValue), 2);

                if (isNaN(newValue)) {
                  toastService.error('Invalid new value entered: ' + params.newValue);
                  return params.oldValue;
                }

                const warnings = new Set<string>();
                const clampedValue = getClampedBidWithWarnings(
                  newValue,
                  params.data?.destinationCampaignAdType as CampaignAdType,
                  params.data?.destinationCampaignIsVideo,
                  params.data?.destinationCampaignBudgetAmount,
                  warnings,
                );

                if (warnings.size > 0) {
                  toastWarnWithSetMessages(warnings);
                  return clampedValue;
                }

                return newValue;
              } catch (e: unknown) {
                toastService.error(getErrorMessage(e));
              }

              // Revert to old when invalid
              return params.oldValue;
            },
          },
        ],
      },
      {
        headerName: 'Harvest',
        colId: ColumnId.MATCH_TYPE_GROUP,
        children: [
          {
            colId: ColumnId.MATCH,
            headerName: 'Match Type',
            cellClass: (params: CellClassParams<CampaignMappingModelWithValidation>) =>
              getCellClassMultiField(params, ['exactMatch', 'broadMatch', 'phraseMatch', 'individualProductTarget', 'expandedProductTarget']),
            width: 170,
            cellRendererSelector: (params: ICellRendererParams<CampaignMappingModelWithValidation>) => {
              if (!params.data?.destinationAdGroupEntityType) {
                return emptyCellRenderer;
              }
              return undefined;
            },
            cellRenderer: ErrorWarningWrapperCellRenderer,
            cellRendererParams: (params: IErrorWarningCellRendererParams<CampaignMappingModelWithValidation>) => {
              const keywordOptions: MultipleChoiceCellRendererOption<CampaignMappingModelWithValidation>[] = [
                { id: 'exactMatch', label: 'E', selected: params.data?.exactMatch ?? false, tooltip: 'Exact match' },
                { id: 'broadMatch', label: 'B', selected: params.data?.broadMatch ?? false, tooltip: 'Broad match' },
                { id: 'phraseMatch', label: 'P', selected: params.data?.phraseMatch ?? false, tooltip: 'Phrase match' },
              ];

              const productTargetOptions: MultipleChoiceCellRendererOption<CampaignMappingModelWithValidation>[] = [
                {
                  id: 'individualProductTarget',
                  label: 'PT',
                  selected: params.data?.individualProductTarget ?? false,
                  tooltip: 'Individual Product Target',
                },
                {
                  id: 'expandedProductTarget',
                  label: 'EXP',
                  selected: params.data?.expandedProductTarget ?? false,
                  tooltip: 'Expanded Product Target',
                },
              ];

              const mainOptions =
                params.data?.destinationAdGroupEntityType === TargetEntityType.KEYWORD ? keywordOptions : productTargetOptions;
              const erroredOptions = [];
              if (params.data?.destinationAdGroupEntityType === TargetEntityType.KEYWORD) {
                if (params.data?.individualProductTarget) {
                  erroredOptions.push({
                    id: 'individualProductTarget',
                    label: 'PT',
                    selected: params.data?.individualProductTarget ?? false,
                    tooltip: 'Individual Product Target',
                    isError: true,
                  });
                }

                if (params.data.expandedProductTarget) {
                  erroredOptions.push({
                    id: 'expandedProductTarget',
                    label: 'EXP',
                    selected: params.data?.expandedProductTarget ?? false,
                    tooltip: 'Expanded Product Target',
                    isError: true,
                  });
                }
              } else if (params.data?.destinationAdGroupEntityType === TargetEntityType.PRODUCT_TARGET) {
                if (params.data?.exactMatch) {
                  erroredOptions.push({
                    id: 'exactMatch',
                    label: 'E',
                    selected: params.data?.exactMatch ?? false,
                    tooltip: 'Exact match',
                    isError: true,
                  });
                }

                if (params.data.broadMatch) {
                  erroredOptions.push({
                    id: 'broadMatch',
                    label: 'B',
                    selected: params.data.broadMatch,
                    tooltip: 'Broad match',
                    isError: true,
                  });
                }

                if (params.data.phraseMatch) {
                  erroredOptions.push({
                    id: 'phraseMatch',
                    label: 'P',
                    selected: params.data.phraseMatch,
                    tooltip: 'Phrase match',
                    isError: true,
                  });
                }
              }

              const options = [...mainOptions, ...erroredOptions];

              function updateValues(values: MultipleChoiceUpdatedValue<CampaignMappingModelWithValidation>[]) {
                updateWithMultipleChoiceValues(params, values);
              }

              const validationErrorMessage = [
                params.data?.validationErrors?.exactMatch,
                params.data?.validationErrors?.broadMatch,
                params.data?.validationErrors?.phraseMatch,
                params.data?.validationErrors?.individualProductTarget,
                params.data?.validationErrors?.expandedProductTarget,
              ]
                .filter(Boolean)
                .filter((item, index, self) => self.indexOf(item) === index) // Remove duplicates
                .join(', ');

              const validationWarningMessage = [
                params.data?.validationWarnings?.exactMatch,
                params.data?.validationWarnings?.broadMatch,
                params.data?.validationWarnings?.phraseMatch,
                params.data?.validationWarnings?.individualProductTarget,
                params.data?.validationWarnings?.expandedProductTarget,
              ]
                .filter(Boolean)
                .filter((item, index, self) => self.indexOf(item) === index) // Remove duplicates
                .join(', ');

              return {
                CellRenderer: MultipleChoiceCellRenderer,
                cellRendererParams: { options, updateValues },
                errorMessage: validationErrorMessage,
                warningMessage: validationWarningMessage,
              };
            },
          },
        ],
      },
      {
        headerName: 'Negate from Source',
        colId: ColumnId.NEGATIVES,
        children: [
          {
            colId: ColumnId.NEGATIVE_CAMPAIGN,
            headerName: 'Neg Src Campaign',
            cellClass: (params: CellClassParams<CampaignMappingModelWithValidation>) =>
              getCellClassMultiField(params, ['campaignNegativeExact', 'campaignNegativePhrase', 'campaignNegativeProductTarget']),
            width: 170,
            cellRendererSelector: (params: ICellRendererParams<CampaignMappingModelWithValidation>) => {
              const isDisabled = params.colDef?.colId && params.data && params.data.isCellDisabledMap[params.colDef.colId as ColumnId];
              if (!params.data?.destinationCampaignAdType || isDisabled) {
                return emptyCellRenderer;
              }
              return undefined;
            },
            cellRenderer: ErrorWarningWrapperCellRenderer,
            cellRendererParams: (params: IErrorWarningCellRendererParams<CampaignMappingModelWithValidation>) => {
              const keywordOptions: MultipleChoiceCellRendererOption<CampaignMappingModelWithValidation>[] = [
                {
                  id: 'campaignNegativeExact',
                  label: 'E',
                  selected: params.data?.campaignNegativeExact ?? false,
                  tooltip: 'Campaign Negative Exact',
                },
                {
                  id: 'campaignNegativePhrase',
                  label: 'P',
                  selected: params.data?.campaignNegativePhrase ?? false,
                  tooltip: 'Campaign Negative Phrase',
                },
              ];

              const productTargetOptions: MultipleChoiceCellRendererOption<CampaignMappingModelWithValidation>[] = [
                {
                  id: 'campaignNegativeProductTarget',
                  label: 'PT',
                  selected: params.data?.campaignNegativeProductTarget ?? false,
                  tooltip: 'Campaign Negative Product Target',
                },
              ];

              const mainOptions =
                params.data?.destinationAdGroupEntityType === TargetEntityType.KEYWORD ? keywordOptions : productTargetOptions;
              const erroredOptions = [];

              if (params.data?.destinationAdGroupEntityType === TargetEntityType.KEYWORD) {
                if (params.data?.campaignNegativeProductTarget) {
                  erroredOptions.push({
                    id: 'campaignNegativeProductTarget',
                    label: 'PT',
                    selected: params.data?.campaignNegativeProductTarget ?? false,
                    tooltip: 'Campaign Negative Product Target',
                    isError: true,
                  });
                }
              } else if (params.data?.destinationAdGroupEntityType === TargetEntityType.PRODUCT_TARGET) {
                if (params.data.campaignNegativeExact) {
                  erroredOptions.push({
                    id: 'campaignNegativeExact',
                    label: 'E',
                    selected: params.data?.campaignNegativeExact ?? false,
                    tooltip: 'Campaign Negative Exact',
                    isError: true,
                  });
                }

                if (params.data?.campaignNegativePhrase) {
                  erroredOptions.push({
                    id: 'campaignNegativePhrase',
                    label: 'P',
                    selected: params.data?.campaignNegativePhrase ?? false,
                    tooltip: 'Campaign Negative Phrase',
                    isError: true,
                  });
                }
              }

              const options = [...mainOptions, ...erroredOptions];

              function updateValues(values: MultipleChoiceUpdatedValue<CampaignMappingModelWithValidation>[]) {
                updateWithMultipleChoiceValues(params, values);
              }

              const errorMessage = [
                params.data?.validationErrors?.campaignNegativeExact,
                params.data?.validationErrors?.campaignNegativePhrase,
                params.data?.validationErrors?.campaignNegativeProductTarget,
              ]
                .filter(Boolean)
                .filter((item, index, self) => self.indexOf(item) === index) // Remove duplicates
                .join(', ');

              const warningMessage = [
                params.data?.validationWarnings?.campaignNegativeExact,
                params.data?.validationWarnings?.campaignNegativePhrase,
                params.data?.validationWarnings?.campaignNegativeProductTarget,
              ]
                .filter(Boolean)
                .filter((item, index, self) => self.indexOf(item) === index) // Remove duplicates
                .join(', ');

              return {
                CellRenderer: MultipleChoiceCellRenderer,
                cellRendererParams: { options, updateValues },
                errorMessage: errorMessage,
                warningMessage: warningMessage,
              };
            },
          },
          {
            colId: ColumnId.NEGATIVE_AD_GROUP,
            headerName: 'Neg Src Ad Group',
            cellClass: (params: CellClassParams<CampaignMappingModelWithValidation>) =>
              getCellClassMultiField(params, ['adGroupNegativeExact', 'adGroupNegativePhrase', 'adGroupNegativeProductTarget']),
            width: 170,
            cellRendererSelector: (params: ICellRendererParams<CampaignMappingModelWithValidation>) => {
              if (!params.data?.destinationAdGroupEntityType) {
                return emptyCellRenderer;
              }
              return undefined;
            },
            cellRenderer: ErrorWarningWrapperCellRenderer,
            cellRendererParams: (params: IErrorWarningCellRendererParams<CampaignMappingModelWithValidation>) => {
              const keywordOptions: MultipleChoiceCellRendererOption<CampaignMappingModelWithValidation>[] = [
                {
                  id: 'adGroupNegativeExact',
                  label: 'E',
                  selected: params.data?.adGroupNegativeExact ?? false,
                  tooltip: 'Ad Group Negative Exact',
                },
                {
                  id: 'adGroupNegativePhrase',
                  label: 'P',
                  selected: params.data?.adGroupNegativePhrase ?? false,
                  tooltip: 'Ad Group Negative Phrase',
                },
              ];

              const productTargetOptions: MultipleChoiceCellRendererOption<CampaignMappingModelWithValidation>[] = [
                {
                  id: 'adGroupNegativeProductTarget',
                  label: 'PT',
                  selected: params.data?.adGroupNegativeProductTarget ?? false,
                  tooltip: 'Ad Group Negative Product Target',
                },
              ];

              const mainOptions =
                params.data?.destinationAdGroupEntityType === TargetEntityType.KEYWORD ? keywordOptions : productTargetOptions;
              const erroredOptions = [];

              if (params.data?.destinationAdGroupEntityType === TargetEntityType.KEYWORD) {
                if (params.data?.adGroupNegativeProductTarget) {
                  erroredOptions.push({
                    id: 'adGroupNegativeProductTarget',
                    label: 'PT',
                    selected: params.data?.adGroupNegativeProductTarget ?? false,
                    tooltip: 'Ad Group Negative Product Target',
                    isError: true,
                  });
                }
              } else if (params.data?.destinationAdGroupEntityType === TargetEntityType.PRODUCT_TARGET) {
                if (params.data?.adGroupNegativeExact) {
                  erroredOptions.push({
                    id: 'adGroupNegativeExact',
                    label: 'E',
                    selected: params.data?.adGroupNegativeExact ?? false,
                    tooltip: 'Ad Group Negative Exact',
                    isError: true,
                  });
                }

                if (params.data.adGroupNegativePhrase) {
                  erroredOptions.push({
                    id: 'adGroupNegativePhrase',
                    label: 'P',
                    selected: params.data.adGroupNegativePhrase,
                    tooltip: 'Ad Group Negative Phrase',
                    isError: true,
                  });
                }
              }

              const options = [...mainOptions, ...erroredOptions];
              function updateValues(values: MultipleChoiceUpdatedValue<CampaignMappingModelWithValidation>[]) {
                updateWithMultipleChoiceValues(params, values);
              }

              const validationErrorMessage = [
                params.data?.validationErrors?.adGroupNegativeExact,
                params.data?.validationErrors?.adGroupNegativePhrase,
                params.data?.validationErrors?.adGroupNegativeProductTarget,
              ]
                .filter(Boolean)
                .filter((item, index, self) => self.indexOf(item) === index) // Remove duplicates
                .join(', ');

              const validationWarningMessage = [
                params.data?.validationWarnings?.adGroupNegativeExact,
                params.data?.validationWarnings?.adGroupNegativePhrase,
                params.data?.validationWarnings?.adGroupNegativeProductTarget,
              ]
                .filter(Boolean)
                .filter((item, index, self) => self.indexOf(item) === index) // Remove duplicates
                .join(', ');

              return {
                CellRenderer: MultipleChoiceCellRenderer,
                cellRendererParams: { options, updateValues },
                errorMessage: validationErrorMessage,
                warningMessage: validationWarningMessage,
              };
            },
          },
        ],
      },
      {
        colId: ColumnId.ACTIONS,
        headerName: 'Actions',
        width: 255,
        pinned: 'right',
        cellClass: (params: CellClassParams<CampaignMappingModelWithValidation>) => getCellClassMultiField(params, ['actions']),
        cellRenderer: ErrorWarningWrapperCellRenderer,
        cellRendererParams: (params: IErrorWarningCellRendererParams<CampaignMappingModelWithValidation>) => {
          function deleteRow() {
            if (!params.node.data) return;

            params.api.applyTransaction({ remove: [params.node.data] });
            onGridDataChangedInternal(params.api);
          }

          const buttons = [
            <RowActionButton
              key="remove"
              text="Remove"
              color="red"
              tooltipText={`Remove this row`}
              onClick={deleteRow}
              icon={<RemoveCircleOutline />}
            ></RowActionButton>,
          ];

          return {
            CellRenderer: ButtonGroupCellRenderer,
            cellRendererParams: {
              buttons,
            },
            errorMessage: params.data?.validationErrors?.['actions'],
            warningMessage: params.data?.validationWarnings?.['actions'],
          };
        },
      },
    ];

    applyStateToDefinitions(colDefs);

    return colDefs;
  }, []);

  // FILTERING
  const applyExternalFilters = (filters: AlFilterModel[]) => {
    if (gridApiRef.current) {
      gridApiRef.current.setGridOption('isExternalFilterPresent', () => true);
      gridApiRef.current.setGridOption('doesExternalFilterPass', (node: IRowNode<CampaignMappingModelWithValidation>) => {
        if (!node || !node.data || !filters || filters.length === 0) {
          return true;
        }

        return !filters.some((filter) => {
          if (!node.data) {
            return true;
          }
          return !filter.doesFilterPass(node.data);
        });
      });
      gridApiRef.current.onFilterChanged();
      //setVisibleRowCount(gridApiRef.current.getDisplayedRowCount());
      gridApiRef.current.deselectAll();
    }
  };

  useEffect(() => {
    applyExternalFilters(externalFilters);
  }, [externalFilters]);

  // TODO: move to AlGrid everywhere
  const onCellClicked = useCallback((params: CellClickedEvent<CampaignMappingModelWithValidation>) => {
    if (params.column.getColId() === ColumnId.CHECKBOX) {
      const node = params.node;
      node.setSelected(!node.isSelected());
    }
  }, []);

  useEffect(() => {
    if (!gridApiRef.current || !gridContextRef.current || Object.keys(campaignToAdGroupsMap).length === 0) return;
    gridContextRef.current.campaignToAdGroupsMap = campaignToAdGroupsMap;
    // No cell refreshing needed because default add new map row is anyway empty and so no data from map is used on load
  }, [campaignToAdGroupsMap]);

  function onGridDataChangedInternal(api: GridApi<CampaignMappingModelWithValidation>) {
    applyExternalFilters(externalFilters);
    onGridDataChanged(api);
  }

  const customGridOptions: GridOptions<CampaignMappingModelWithValidation> = useMemo(() => {
    const defaultCampaignMappingGridContext: CampaignMappingGridContext = {
      campaignToAdGroupsMap,
    };
    return {
      ...DEFAULT_GRID_OPTIONS,
      context: defaultCampaignMappingGridContext,
      suppressMovableColumns: true,
      getRowId: (params) => params.data.id?.toString() ?? '',
      onCellClicked: onCellClicked,
      maintainColumnOrder: true,
      onBodyScroll: (event) => onSourceScroll(event.top),
      onColumnMoved: handleColumnStateChange,
      onColumnVisible: handleColumnStateChange,
      onColumnResized: handleColumnStateChange,
      onColumnRowGroupChanged: handleColumnStateChange,
      onSortChanged: handleColumnStateChange,
      onColumnPinned: handleColumnStateChange,
      onCellValueChanged: (event: CellValueChangedEvent<CampaignMappingModelWithValidation>) => onGridDataChangedInternal(event.api),
      onFirstDataRendered: (event: FirstDataRenderedEvent<CampaignMappingModelWithValidation>) => onGridDataChangedInternal(event.api),
    };
  }, []);

  function onGridReady(params: GridReadyEvent) {
    setColumnStateGridApi(params.api);
    gridApiRef.current = params.api;
    onGridReadyCallback?.(params);

    gridContextRef.current = params.context;
  }

  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  return (
    <>
      {isCampaignMappingLoadingError ? (
        <Card className="flex-grow rounded-xl py-0">
          <ErrorLoadingDataAlert details={campaignMappingLoadingErrorMessage} />
        </Card>
      ) : (
        <div style={{ height: `calc(100vh - ${VISIBLE_ABOVE_PX_ON_SCROLL_DOWN}px)` }}>
          <AlGrid
            colDefs={columnDefs}
            rowData={rowData && !isEmpty(campaignToAdGroupsMap) ? rowData : []}
            gridOptions={customGridOptions}
            isLoading={isLoading}
            onGridReadyCallback={onGridReady}
            noTopBorderRadius={noTopBorderRadius}
            fitToResizeEnabled={false}
            addExtraBottomPadding={true} // TODO: standardize padding under the table so action bar appears always at the same height relative to the table bottom edge
          />
        </div>
      )}
    </>
  );
};

export default CampaignMappingImportPreviewTable;
