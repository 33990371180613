export interface CampaignMappingImportDetails {
  rowCount: number;
  errorMessageSamples: string[];
  warningSamples: string[];
  errorCount: number;
  warningCount: number;
  firstErrorRowId: string | null;
  firstWarningRowId: string | null;
}

export const MAX_ERROR_WARNING_SAMPLES = 5;

export enum ImportStep {
  CREATE = 'CREATE',
  ASSIGN = 'ASSIGN',
}

export interface HasValidation<T> {
  id: string | null;
  validationErrors?: Partial<Record<keyof T, string>>;
  validationWarnings?: Partial<Record<keyof T, string>>;
  validate(): void;
}
